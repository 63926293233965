import { Button } from "antd";
import { useEffect } from "react";
import { formDataAppend } from "../../../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getScoreCard } from "../../../../Redux/Actions/Thunk/reports";

const GadsTopChart = (props) => {

    const {
        keyMetrics:{
            key_metrics: {data: KeyMetricsData = []}
        }
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    // Testing Data
    // props.all_input_data.Client = 1572;

    // props.all_input_data.report_date = "2023-08-08";

    // props.all_input_data.report_count = 2;

    useEffect(()=>{
        var data = props?.all_input_data;
        var formData = formDataAppend(data);
        dispatch(getScoreCard(props, formData));
    }, []);
        
    return (
        <>
            <div className="gads-content-single gads-header">
                <div className="gads-header-title">
                    <h4>{props?.all_input_data?.company_name}</h4>
                    <div className="gads-header-title-h2">Google Ads Scorecard</div>
                </div>
                <div className="gads-header-score">
                    <div className="gadshs-single">
                        <h6>Paid Search Score</h6>
                        <h4>Poor Job</h4>
                        <div className="score">
                            <div className="gads-header-title-h2">{KeyMetricsData[0]?.ppc_grader_score}</div>
                        </div>
                    </div>
                    <div className="gadshs-single">
                        <h6>Product Ads Score</h6>
                        <h4>Poor Job</h4>
                        <div className="score">
                            <div className="gads-header-title-h2">{KeyMetricsData[0]?.pla_grader_score}</div>
                        </div>
                    </div>
                </div>
                <div className="gads-header-info">
                    <p>
                        Based on data from <b>{KeyMetricsData[0]?.start_date}</b> to <b>{KeyMetricsData[0]?.end_date}</b> and using <b>{KeyMetricsData[0]?.roas_goal}</b>% as ROAS goal
                    </p>
                    <p>
                        You will receive a monthly refresh via email on the 9th of
                        each month
                    </p>
                </div>
                <div className="gads-imporve-btn">
                    <a href="https://agital.com/ecommerce-analysis/">
                        <Button>Improve my score</Button>
                    </a>
                </div>
            </div>
        </>
    )
}

export default GadsTopChart;