import * as barGraphApi from "../../../Api/barGraphApi";
import * as barGraphAction from "../barGraphAction";
import { FETCH_GRAPH, REQUEST_DATA } from "../types/index";
import { ACTIONS_TYPES } from "../../Reducers/barGraphReducer";

export const getBarGraphData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FETCH_BAR_GRAPH,
      });
      let graph = {};
      const response = await barGraphApi.getBarGraph(props, data);
      if (response?.status === 200) {
        graph = {
          [data.block.block_id]: {
            data: response?.data?.chart_data,
            json_data: response?.data?.json_data,
            xlabels: response?.data?.xlabels,
            tab_options: response?.data?.tab_options,
            overall_organic_report:
              response?.data?.data[0]?.overall_organic_report,
            bar_data: response?.data?.data,
            format: response?.data?.format,
          },
        };
      }

      return dispatch(barGraphAction.getBarGraph(graph));
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const getStackedBarGraphData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FETCH_BAR_GRAPH,
      });
      let graph = {};
      const response = await barGraphApi.getBarGraph(props, data);

      if (response?.status === 200) {
        graph = {
          [data.block.block_id]: {
            data: response?.data?.chart_data,
            page_details: response?.data?.page_details,
            xlabels: response?.data?.xlabels,
            json_data: response?.data?.json_data,
            color_code: response?.data?.color_code_list,
            nlg_txt: response?.data?.nlg_text,
          },
        };
      }

      return dispatch(barGraphAction.getBarGraph(graph));
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const getOrganicSeoData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FETCH_BAR_GRAPH,
      });

      let graph = {};

      const response = await barGraphApi.getBarGraph(props, data);
      if (response?.status === 200) {
        graph = {
          [data.block.block_id]: {
            data: response?.data?.data,
          },
        };
      }
      return dispatch(barGraphAction.getBarGraph(graph));
    } catch (error) {}
  };
};
