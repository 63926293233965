import { DataSourcesApiEndPoints } from "../../../Utils/constant";
import API_BI from "../../../Utils/axios_bi";

const {
  facebookAdsInsightsMCC: {
    getAllFacebookAdsInsightsMCCUrl,
    getFacebookAdsInsightsMCCUrl,
    postFacebookAdsInsightsMCCUrl,
    facebookCredentialsUrl,
    facebookTokenUrl,
  },
} = DataSourcesApiEndPoints;

/**
 * API function to get Google Ads Auth data
 */
export const getAllFacebookAdsInsightsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(getAllFacebookAdsInsightsMCCUrl, {
    //content-type is not required for get request
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * API function to get Google Ads Auth data
 */
export const getFacebookAdsInsightsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getFacebookAdsInsightsMCCUrl, props);
};

/**
 * API function to create Google Ads Auth data
 * @param {object} props - The data to be posted
 * @returns {Promise<response>}
 */

export const createFacebookAdsInsightsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  // API_BI.defaults.headers.common["Content-Type"] = 'application/x-www-form-urlencoded'

  return await API_BI.post(postFacebookAdsInsightsMCCUrl, props);
};

export const updateFacebookAdsInsightsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(postFacebookAdsInsightsMCCUrl, props);
};

export const facebookCredentialsAPI = async () => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(facebookCredentialsUrl, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const facebookTokensAPI = async () => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(facebookTokenUrl, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
