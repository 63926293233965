import { BlockOutlined } from "@ant-design/icons";
import React from "react";
import InputSelect from "../InputControls/InputSelect";
import SelectInputComponent from "../InputControls/SelectInputComponent";
import SelectStatic from "../InputControls/SelectStatic";
import InputRangePicker from "../InputControls/InputRangePicker";
import CustomDate from "../InputControls/CustomDate";
import AutoCompleteSelect from "../InputControls/AutocompleteSelect";
import InputDatePicker from "../InputControls/InputDatePicker";
import MultipleCustomPicker from "../InputControls/MultipleCustomPicker";
import ToggleComponent from "../InputControls/ToggleComponent";
import SelectPaginate from "../InputControls/SelectPaginate";

const Components = {
  daterangepicker: InputRangePicker,
  monthrangepicker: InputRangePicker,
  select: SelectInputComponent,
  selectstatic: SelectStatic,
  customrangepicker: CustomDate,
  autocomplete: AutoCompleteSelect,
  inputDatePicker: InputDatePicker,
  multiplecustomdatepicker: MultipleCustomPicker,
  switch: ToggleComponent,
  SelectPaginate,
};

export default (
  block,
  account_id_data,
  client_id,
  report_key,
  filteredData,
  form
) => {
  if (typeof Components[block.component_type] !== "undefined") {
    return React.createElement(Components[block.component_type], {
      key: block.id,
      block: block,
      client_id: client_id,
      report_key: report_key,
      form,
      filteredData,
    });
  }
  // return React.createElement(
  // 	() => (
  // 		<div className="check">
  // 			The component {block.type} has not been created yet.
  // 		</div>
  // 	),
  // 	{ key: block.id }
  // );
};
