import { Button, Row, Col, Modal, Checkbox, message } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill's CSS
import { FaCalendarAlt } from "react-icons/fa";

const {
	API_BI: { baseURL },
} = config;

const DevNotes = (props) => {
	const [noteId, setNoteId] = useState(0);
	const [visible, setVisible] = useState(false);
	const [internal, setInternal] = useState(false);
	const [content, setContent] = useState("");
	const [devNotes, setDevNotes] = useState([]);
	const [archiveVisibility, setArchiveVisibility] = useState(false);

	const showArchive = () => {
		setArchiveVisibility(true);
	};

	const addNew = () => {
		setNoteId(0);
		setVisible(false);
		setInternal(false);
		setContent("");
	};

	const handleCancel = () => {
		setArchiveVisibility(false);
	};

	const handleEditorChange = (newContent) => {
		setContent(newContent);
	};

	const changeVisibleState = (obj) => {
		setVisible(obj.target.checked);
	};

	const changeInternalState = (obj) => {
		setInternal(obj.target.checked);
	};

	const saveDevNote = () => {
		const postdata = {
			id: noteId,
			notes: content,
			is_visible: visible,
			is_internal: internal,
		};

		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.post(baseURL + "api/v1/save-dev-notes", postdata).then(
			(response) => {
				if (response.status == 200) {
					setNoteId(response.data.data[0].id);
					setDevNotes(response.data.data[0].dev_notes);
					message.success(response.data.message);
				} else {
					message.error("Something went wrong!");
				}
			}
		);
	};

	const dispatch = useDispatch();

	const isLoading = useSelector((state) => state.accounts.isLoading);

	useEffect(() => {
		dispatch({
			type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
		});
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.get(baseURL + "api/v1/dev-notes").then((response) => {
			if (response.status == 200) {
				if (response.data.recent_dev_note.is_internal == 1) {
					setInternal(true);
				}
				if (response.data.recent_dev_note.is_visible == 1) {
					setVisible(true);
				}
				setNoteId(response.data.recent_dev_note.id);
				setContent(response.data.recent_dev_note.notes);
				setDevNotes(response.data.dev_notes);
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
				});
			}
		});
	}, []);

	return (
		<>
			<div className="panel-heading">
				<Row>
					<Col span={8} className="panel-heading-title">
						<h2>Alerts</h2>
					</Col>
					<Col span={16} className="panel-heading-buttons">
						<Button className="alert-old" onClick={showArchive}>
							Alert Archive
						</Button>
						<Button className="alert-new" onClick={addNew}>
							Add New
						</Button>
					</Col>
				</Row>
			</div>
			<div className="panel-body">
				<div className="panel-body-content">
					<div className="alert-wrapper">
						<h5>Current Alert</h5>
						<Row>
							<Col lg={{span: 12}} xs={{span: 24}} className="alert-editor">
								<ReactQuill value={content} onChange={handleEditorChange} />
							</Col>
						</Row>
						<Row>
							<div className="alert-checkbox">
								<Checkbox checked={visible} onChange={changeVisibleState}>
									Display Alert
								</Checkbox>
								<Checkbox checked={internal} onChange={changeInternalState}>
									Internal
								</Checkbox>
							</div>
						</Row>
						<Row>
							<button onClick={saveDevNote} className="ant-btn alert-save">
								Save
							</button>
						</Row>
					</div>
				</div>
			</div>
			<Modal
				className="notes-archieve-modal"
				title="PREVIOUS ALERTS"
				visible={archiveVisibility}
				okButtonProps={{ style: { display: "none" } }}
				okText="Add Selected"
				cancelText="Close"
				onCancel={handleCancel}
				width={700}
			>
				<div className="archive-list-wrapper">
					{devNotes.map((dev_note) => (
						<div className="archive-list" key={"archive-list-" + dev_note.id}>
							<div className="al-date">
								<span>
									<FaCalendarAlt /> {dev_note.updated_at}
								</span>
								<strong>
									{dev_note.is_internal == 1 ? "Internal" : "Public"}
								</strong>
							</div>
							<div
								className="al-details"
								dangerouslySetInnerHTML={{ __html: dev_note.notes }}
							/>
						</div>
					))}
				</div>
			</Modal>
		</>
	);
};

export default DevNotes;
