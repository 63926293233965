import { Col, Row } from "antd";
import StackedBar from "../StackedBar";
import ColumnCharts from "../../../Highcharts/ColumnChart";

const BrandNonBrandPerformace = (props) => {

    return <>

        <div className="gads-content-single gads-brand">
            <div className="gcs-title">
                <h4>Brand vs. Non-Brand Performance</h4>
            </div>
            <div className="gsc-content">
                <Row gutter={32}>
                    <Col span={12}>
                        <StackedBar 
                            {...props.cols.filter((prop) => prop.block.block_id === 508)[0]}
                            format=''
                            all_input_data={props.all_input_data}
                         />
                    </Col>
                    <Col span={12}>
                        <ColumnCharts 
                            {...props.cols.filter((prop) => prop.block.block_id === 496)[0]}
                            format=''
                            all_input_data={props.all_input_data}
                        />
                    </Col>
                </Row>
            </div>
        </div>
        </>
}

export default BrandNonBrandPerformace;