import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, InputNumber, Slider, Select, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSliderData } from "../../Redux/Actions/Thunk/accountReportThunk";
import { formDataAppend, numberWithCommas } from "../../Utils/helpers";
import { event } from "jquery";
import {
  getLineChartData,
  getRevenueRetentionData,
} from "../../Redux/Actions/Thunk/LineChartThunk";

const CLVSlider = (props) => {
  const {
    accountReports: {
      sliderData: { [props?.block?.block_id]: { data: sliderData = [] } = {} },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getSliderData(formData, props));
  }, []);

  const [inputValue, setInputValue] = useState(0);
  const [net_new_val, setNetNewVal] = useState("");
  const [total_paid_val, setTotalPaidVal] = useState("");
  const [defaultValue, setDefaultValue] = useState([
    { "Revenue Impact at 3rd Month": "" },
    { "Revenue Impact at 6th Month": "" },
    { "Revenue Impact at 12 Month": "" },
    { "Net New Customers": "" },
    { "Total Paid Spend": "" },
  ]);
  const [def_val, setDefVal] = useState([]);
  const [updatedValue, setUpdatedValue] = useState(
    { "Revenue Impact": "" },
    { month: "" },
    { "Net New Customers": "" },
    { "Total Paid Spend": "" }
  );

  const [defaultMonthVal, setDefaultMonthVal] = useState("");
  const [monthVal, setMonth] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [netNewDisabled, setNetNewDisabled] = useState(false);
  const [totalPaidDisabled, setTotalPaidDisabled] = useState(false);
  const [applyBtnDisabled, setApplyBtnDisabled] = useState(false);
  const [presentVal, setPresentVal] = useState("");
  const [revenueImpactCaption, setRevenueImpactCaption] = useState("");
  const [resetBtnDisabled, setResetBtnDisabled] = useState(false);
  const [sliderDefaultVal, setSliderDefaultVal] = useState("");
  const [revenueImpactValue, setRevenueImpactValue] = useState("");

  let data = [];
  let defaultData = [];
  const formattedRevenueData = [];
  const formattedRevenueData1 = [];
  const netNewDefaultValue = [];
  const totalSpendDefaultValue = [];

  useEffect(() => {
    setDefaultMonthVal("");
  }, [sliderData]);

  sliderData.map((item) => {
    const { caption, value } = item;
    const formattedValue = value.toLocaleString(); // Format the value with commas

    let formattedCaption = caption;
    if (caption != "Revenue Impact at the") {
      var formattedCaption1 = caption;
      var formattedValue1 = value.toLocaleString();
    }
    if (caption == "Net New Customers") {
      netNewDefaultValue.push(formattedValue);
    }

    if (caption == "Total Paid Spend") {
      totalSpendDefaultValue.push(formattedValue);
    }
    const monthMatch = caption.match(/\d+/); // Extract the month using a regular expression

    if (monthMatch) {
      const month = parseInt(monthMatch[0], 10); // Parse the extracted month as an integer
      formattedCaption = `${caption.split(" at")[0]} at ${month} Month`;
      formattedCaption1 = caption;
    }
    const monthsToExtract = [3, 6, 12];
    if (
      item.caption.includes("Revenue Impact") &&
      monthsToExtract.includes(item.month)
    ) {
      const formattedCaption = `${item.caption}`;
      const month = `${item.month}`;
      const formattedValue = item.value;

      formattedRevenueData.push({
        [formattedCaption]: formattedValue,
        month,
      });
    }
    if (
      item.caption != "Revenue Impact at the" &&
      monthsToExtract.includes(item.month)
    ) {
      const formattedCaption1 = `${item.caption}`;
      const month = `${item.month}`;
      const formattedValue1 = item.value;

      formattedRevenueData1.push({
        [formattedCaption1]: formattedValue1,
        month,
      });
    }
    const entry = {};
    const defaultEntry = {};
    entry[formattedCaption] = formattedValue;
    data.push(entry);
    if (formattedCaption1 != undefined) {
      defaultEntry[formattedCaption1] = formattedValue1;
      defaultData.push(defaultEntry);
    }
  });

  const handleChange = (e, values) => {
    var month_3 = Object.entries(formattedRevenueData[1])[0][1];
    var month_6 = Object.entries(formattedRevenueData[2])[0][1];
    var month_12 = Object.entries(formattedRevenueData[3])[0][1];

    if (e == 3) {
      setDefaultMonthVal(month_3);
      setMonth("3");
      setUpdatedValue([
        { month: "3" },
        { "Revenue Impact": month_3.toLocaleString() },
        { "Net New Customers": netNewDefaultValue[0] },
        { "Total Paid Spend": totalSpendDefaultValue[0] },
      ]);
    } else if (e == 6) {
      setDefaultMonthVal(month_6);
      setMonth("6");
      setUpdatedValue([
        { month: "6" },
        { "Revenue Impact": month_6.toLocaleString() },
        { "Net New Customers": netNewDefaultValue[0] },
        { "Total Paid Spend": totalSpendDefaultValue[0] },
      ]);
    } else {
      setDefaultMonthVal(month_12);
      setMonth("12");
      setUpdatedValue([
        { month: "12" },
        { "Revenue Impact": month_12.toLocaleString() },
        { "Net New Customers": netNewDefaultValue[0] },
        { "Total Paid Spend": totalSpendDefaultValue[0] },
      ]);
    }
  };

  const onChange = (newValue, caption) => {
    var month = Object.entries(formattedRevenueData[1])[1][1];
    if (caption == "Revenue Impact") {
      setDefaultMonthVal(newValue);
      setRevenueImpactCaption(caption);
      setRevenueImpactValue(newValue);
    } else if (caption == "Net New Customers") {
      setNetNewVal(newValue);
    } else if (caption == "Total Paid Spend") {
      setTotalPaidVal(newValue);
    }
    setDefaultValue(data);
    setMonth(monthVal);
    if (monthVal == "") {
      setMonth("3");
    }
    if (caption != "Revenue Impact") {
      setPresentVal(caption);
    }

    if (
      revenueImpactCaption === "Revenue Impact" &&
      presentVal === "Net New Customers" &&
      revenueImpactCaption != "" &&
      presentVal != "Revenue Impact"
    ) {
      //disable Total Paid Spend
      setTotalPaidDisabled(true);
      setDisabled(false);
      setNetNewDisabled(false);
      setUpdatedValue([
        { [caption]: `${newValue.toLocaleString()}` },
        { ["Total Paid Spend"]: defaultValue[5]["Total Paid Spend"] },
        { ["Revenue Impact"]: revenueImpactValue.toLocaleString() },
        { month: monthVal },
      ]);
      setApplyBtnDisabled(false);
      setResetBtnDisabled(false);
    } else if (presentVal === "Net New Customers") {
      //disable Total Paid Spend and revenue impact
      setDisabled(true);
      setTotalPaidDisabled(true);
      setNetNewDisabled(false);
      setUpdatedValue([
        { [caption]: `${newValue.toLocaleString()}` },
        { ["Revenue Impact"]: defaultValue[0]["Revenue Impact at the"] },
        { ["Total Paid Spend"]: defaultValue[5]["Total Paid Spend"] },
        { month: monthVal },
      ]);
      setApplyBtnDisabled(false);
      setResetBtnDisabled(false);
    } else if (
      revenueImpactCaption === "Revenue Impact" &&
      presentVal === "Total Paid Spend"
    ) {
      //disable apply btn
      setApplyBtnDisabled(false);
      setDisabled(false);
      setTotalPaidDisabled(false);
      setNetNewDisabled(true);
      setResetBtnDisabled(false);
    } else if (presentVal === "Total Paid Spend") {
      //disable revenue impact and net new
      setDisabled(true);
      setNetNewDisabled(true);
      setTotalPaidDisabled(false);
      setUpdatedValue([
        { [caption]: `${newValue.toLocaleString()}` },
        { ["Revenue Impact"]: defaultValue[0]["Revenue Impact at the"] },
        { ["Net New Customers"]: defaultValue[4]["Net New Customers"] },
        { month: monthVal },
      ]);
      setApplyBtnDisabled(false);
      setResetBtnDisabled(false);
    } else if (
      revenueImpactCaption === "Revenue Impact" &&
      presentVal != "Net New Customers"
    ) {
      //disable apply btn
      setApplyBtnDisabled(true);
      setDisabled(false);
      setNetNewDisabled(false);
      setResetBtnDisabled(true);
    }
  };

  useEffect(() => {
    setDefaultValue(data);
    setDefVal(defaultData);
    if (formattedRevenueData.length > 0) {
      var month_3 = Object.entries(formattedRevenueData[1])[0][1];
      var month_6 = Object.entries(formattedRevenueData[2])[0][1];
      var month_12 = Object.entries(formattedRevenueData[3])[0][1];
      var month = Object.entries(formattedRevenueData[1])[1][1];
      setMonth(month);
      if (month == 3) {
        setDefaultMonthVal(month_3);
      } else if (month == 6) {
        setDefaultMonthVal(month_6);
      } else {
        setDefaultMonthVal(month_12);
      }
    }
  }, []);

  const updateChart = useCallback(() => {
    setNetNewDisabled(false);
    setDisabled(false);
    setTotalPaidDisabled(false);
    var data = props?.all_input_data;
    data["default_value"] = JSON.stringify(defaultData);
    data["updated_value"] = JSON.stringify(updatedValue);
    var formData = formDataAppend(data);
    dispatch(getSliderData(formData, props));
    var chart = Object.assign(props?.block, {
      block: {
        api_path: "v2/clv/revenue_impact_goal_chart",
        component: "LineChart",
        block_id: 662,
      },
    });

    dispatch(getRevenueRetentionData(formData, chart));
    setDefaultValue([]);
    setUpdatedValue([]);

    setDefaultMonthVal([]);
    setNetNewVal("");
    setTotalPaidVal("");
    setRevenueImpactCaption("");
    setPresentVal("");
  });

  const resetChart = () => {
    setApplyBtnDisabled(false);
    setRevenueImpactCaption("");
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getSliderData(formData, props));
    var chart = Object.assign(props?.block, {
      block: {
        api_path: "v2/clv/revenue_impact_goal_chart",
        component: "LineChart",
        block_id: 662,
      },
    });

    dispatch(getRevenueRetentionData(formData, chart));
    setDefaultValue([]);
    setUpdatedValue([]);
    setNetNewDisabled(false);
    setDisabled(false);
    setTotalPaidDisabled(false);
    setPresentVal("");
    setDefaultMonthVal([]);
    setNetNewVal("");
    setTotalPaidVal("");
  };

  const results = [];

  if (sliderData.length > 0) {
    results.push(
      <>
        <Row className="slider-single">
          <Col
            lg={{ span: 8 }}
            xs={{ span: 24 }}
            className="slider-single-params"
          >
            <div className="slider-single-caption" id="slider">
              <span>Revenue Impact at the</span>
            </div>

            <div className="slider-single-dropdown">
              <Select
                defaultValue={sliderData[0]["month"].toString()}
                style={{
                  width: 120,
                }}
                onChange={(e) => handleChange(e, sliderData)}
                options={[
                  {
                    value: "3",
                    label: "3rd Month",
                  },
                  {
                    value: "6",
                    label: "6th Month",
                  },
                  {
                    value: "12",
                    label: "12th Month",
                  },
                ]}
              />
            </div>
          </Col>
          <Col
            lg={{ span: 16 }}
            xs={{ span: 24 }}
            className="slider-single-input"
          >
            <Row>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <InputNumber
                  prefix={window.currencySymbol}
                  value={
                    defaultMonthVal != "" && defaultMonthVal != 0
                      ? numberWithCommas(defaultMonthVal)
                      : numberWithCommas(sliderData[0]["value"])
                  }
                  min={sliderData[0]["min"]}
                  max={sliderData[0]["max"]}
                  disabled={disabled}
                  onChange={(e) => onChange(e, "Revenue Impact")}
                  controls={false}
                  style={{ minWidth: "150px", padding: "0 10px" }}
                />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Slider
                  className="slider-range"
                  min={sliderData[0]["min"]}
                  max={sliderData[0]["max"]}
                  value={
                    defaultMonthVal != "" && defaultMonthVal != 0
                      ? defaultMonthVal
                      : sliderData[0]["value"]
                  }
                  disabled={disabled}
                  onChange={(e) => onChange(e, "Revenue Impact")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="slider-single">
          <Col
            lg={{ span: 8 }}
            xs={{ span: 24 }}
            className="slider-single-params"
          >
            <div className="slider-single-caption">
              <span>Net New Customers</span>
            </div>
          </Col>
          <Col
            lg={{ span: 16 }}
            xs={{ span: 24 }}
            className="slider-single-input"
          >
            <Row>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <InputNumber
                  min={sliderData[4]["min"]}
                  max={sliderData[4]["max"]}
                  value={
                    net_new_val != undefined && net_new_val != 0
                      ? numberWithCommas(net_new_val)
                      : numberWithCommas(sliderData[4]["value"])
                  }
                  disabled={netNewDisabled}
                  controls={false}
                  onChange={(e) => onChange(e, "Net New Customers")}
                  style={{ minWidth: "150px", padding: "0 10px" }}
                />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Slider
                  className="slider-range"
                  min={sliderData[4]["min"]}
                  max={sliderData[4]["max"]}
                  value={
                    net_new_val != undefined && net_new_val != 0
                      ? net_new_val
                      : sliderData[4]["value"]
                  }
                  disabled={netNewDisabled}
                  onChange={(e) => onChange(e, "Net New Customers")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="slider-single">
          <Col
            lg={{ span: 8 }}
            xs={{ span: 24 }}
            className="slider-single-params"
          >
            <div className="slider-single-caption">
              <span>Total Paid Spend</span>
            </div>
          </Col>
          <Col
            lg={{ span: 16 }}
            xs={{ span: 24 }}
            className="slider-single-input"
          >
            <Row>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <InputNumber
                  min={sliderData[5]["min"]}
                  max={sliderData[5]["max"]}
                  value={
                    total_paid_val != undefined && total_paid_val != 0
                      ? numberWithCommas(total_paid_val)
                      : numberWithCommas(sliderData[5]["value"])
                  }
                  prefix={window.currencySymbol}
                  controls={false}
                  disabled={totalPaidDisabled}
                  onChange={(e) => onChange(e, "Total Paid Spend")}
                  style={{ minWidth: "150px", padding: "0 10px" }}
                />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Slider
                  className="slider-range"
                  min={sliderData[5]["min"]}
                  max={sliderData[5]["max"]}
                  value={
                    total_paid_val != undefined && total_paid_val != 0
                      ? total_paid_val
                      : sliderData[5]["value"]
                  }
                  disabled={totalPaidDisabled}
                  onChange={(e) => onChange(e, "Total Paid Spend")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Spin spinning={isLoading}>
        {results}
        <Row className="revenue_action_buttons">
          <Col span={24}>
            <Button
              htmlType="button"
              onClick={updateChart}
              disabled={applyBtnDisabled}
            >
              Apply
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              onClick={resetChart}
              disabled={resetBtnDisabled}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default CLVSlider;
