import {
  createFacebookAdsInsightsDataSourcesThunk,
  updateFacebookAdsInsightssDataSourcesThunk
} from "../../../Redux/Actions/Thunk/datasources/ds_configs/FacebookAdsInsightsThunkMCC";

const dispatchFacebookAdsInsightsServiceDataSource = (dispatch, payload) => {
  const { dataSourceMetaData, dataSourceDataFromThirdParty } = payload;

  let inputDataFromUser = JSON.parse(payload.dataSourceInputDataByClientInForm);

  // in real life, this data will come from the third party api js gaming
  // dataSourceDataFromThirdParty.profile.data.advertiser_ids = [
  //   "Apple",
  //   "Banana",
  //   "Cat",
  //   "Dog",
  // ];
  const user = inputDataFromUser.user;

  if (dataSourceMetaData.isForUpdate && dataSourceMetaData.id) {
    // update the data source with update dispatch
    dispatch(
      updateFacebookAdsInsightssDataSourcesThunk({
        id: dataSourceMetaData.id,
        user,
        access_token: dataSourceDataFromThirdParty.tokens?.access_token,
      })
    );
  } else {
    //   create the data source with create dispatch
    dispatch(
      createFacebookAdsInsightsDataSourcesThunk({
        user: user,
        access_token: dataSourceDataFromThirdParty.tokens?.access_token,
      })
    );
  }
};

export default dispatchFacebookAdsInsightsServiceDataSource;
