import React from "react";

import Trends from "../ReportBlocks/Trends";
import Scatter from "../Highcharts/Scatter";
import PieChart from "../Highcharts/PieChart";
import BarChart from "../Highcharts/BarChart";
import CLVGoal from "../ReportBlocks/CLVGoal";
import AreaChart from "../Highcharts/AreaChart";
import LineChart1 from "../Highcharts/LineChart1";
import CLVSlider from "../ReportBlocks/CLVSlider";
import KeyMetrics from "../ReportBlocks/KeyMetrics";
import HTMLRender from "../ReportBlocks/HTMLRender";
import DataTable from "../../Pages/Tables/DataTable";
import ColumnCharts from "../Highcharts/ColumnChart";
import NormalTable from "../ReportBlocks/NormalTable";
import StackedChart from "../Highcharts/StackedChart";
import TableSlider from "../ReportBlocks/TableSlider";
import TrendsBreakdown from "../ReportBlocks/TrendsBreakdown";
import PerformanceGraph from "../ReportBlocks/PerformanceGraph";
import ChannelPerformance from "../ReportBlocks/ChannelPerformance";
import StackedBar from "../ReportBlocks/MultiBlockComponents/StackedBar";
import DonutChart from "../ReportBlocks/MultiBlockComponents/DonutChart";
import ColumnChart from "../ReportBlocks/MultiBlockComponents/ColumnChart";
import GroupedEditableTable from "../../Pages/Tables/GroupedEditableTable";
import NormalTableWithColGroup from "../ReportBlocks/NormalTableWithColGroup";
import CustomChannelPerformance from "../ReportBlocks/CustomChannelPerformance";
import DataTableClient from "../../Pages/Tables/DataTableClient/DataTableClient";
import CampaignWithCollapsibleTable from "../ReportBlocks/CampaignWithCollapsibleTable";
import MMMKeyInsights from "../ReportBlocks/MMMKeyInsights";
import ColumnLineChart from "../Highcharts/ColumnLineChart";

const Components = {
  SplineBar: Trends,
  TrendBreakdown: TrendsBreakdown,
  DevicePerformance: PerformanceGraph,
  CollapsibleTable: NormalTable,
  KeyMetrics: KeyMetrics,
  LineChart: LineChart1,
  Slider: CLVSlider,
  DataTable: DataTable,
  DataTableClient: DataTable,
  clientDataTable: DataTableClient,
  StackedBar: StackedBar,
  SEOProjectStatus: ColumnChart,
  ColumnCharts: ColumnCharts,
  StackedChart: StackedChart,
  PieChart: PieChart,
  DonutChart: DonutChart,
  StackAreaChart: AreaChart,
  BarChart: BarChart,
  CampaignPerformance: PerformanceGraph,
  PlacementPerformance: PerformanceGraph,
  ChannelPerformance: ChannelPerformance,
  HTMLRender: HTMLRender,
  TableSlider: TableSlider,
  Scatter: Scatter,
  EditableDataTable: GroupedEditableTable,
  CampaignCollapsibleTable: CampaignWithCollapsibleTable,
  NormalTableWithColGroup: NormalTableWithColGroup,
  CLVGoal: CLVGoal,
  CustomChannelPerformance: CustomChannelPerformance,
  MMMKeyInsights: MMMKeyInsights,
  ColumnLineChart: ColumnLineChart,
};

export default (block, account_id, client_id, jsObj) => {
  if (typeof Components[block.component] !== "undefined") {
    let modifiedJsObj = { ...jsObj };

    if (
      block.component === "DataTable" ||
      block.component === "DataTableClient"
    ) {
      const block_data = JSON.parse(block?.config);
      modifiedJsObj = {
        ...modifiedJsObj,
        limit: block_data["limit"] ? parseInt(block_data["limit"]) : 50,
        offset: 0,
        order_column: block_data["defaultOrderColumn"]
          ? block_data["defaultOrderColumn"]
          : "",
        order_by: block_data["defaultOrder"] ? block_data["defaultOrder"] : "",
        // ...(block_data["defaultOrderColumn"]
        //   ? { order_column: block_data["defaultOrderColumn"] }
        //   : {}),
        // ...(block_data["defaultOrder"]
        //   ? { order_by: block_data["defaultOrder"] }
        //   : {}),
      };
    }
    return React.createElement(Components[block.component], {
      key: block.block_id,
      block: block,
      account_id: account_id,
      client_id: client_id,
      all_input_data: modifiedJsObj,
    });
  }
};
