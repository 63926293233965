import * as ColumnChartApi from "../../../Api/ColumnChartApi";
import * as ColumnChartAction from "../ColumnChartAction";
import { ACTIONS_TYPES } from "../../Reducers/ColumnChartReducer";

export const getSessionData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_COLUMN_CHART,
      });
      let sessionData = {};
      const response = await ColumnChartApi.getSessionData(props, data);
      if (response?.status === 200) {
        sessionData = {
          [data.block.block_id]: {
            chart_data: response?.data?.json_chart_data,
            xAxisCategories: response?.data?.category_data,
            color: response?.data?.color,
          },
        };
      }
      return dispatch(ColumnChartAction.fetchSessionData(sessionData));
    } catch (error) {
      console.error("Error in fetching: " + error);
    } finally {
    }
  };
};

// export const getConversionsData = (props) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: ACTIONS_TYPES.GET_COLUMN_CHART,
//       });
//       let conversionsData = [];
//       const response = await ColumnChartApi.getConversionsData(props);
//       if (response?.status === 200) {
//         conversionsData = {
//           conversions_details: response?.data?.session_details,
//           xAxisCategories: response?.data?.xAxisCategories,
//         };
//       }
//       dispatch({
//         type: ACTIONS_TYPES.FETCH_CONVERSIONS_DATA,
//         payload: conversionsData,
//       });
//       return dispatch(ColumnChartAction.fetchConversionsData(conversionsData));
//     } catch (error) {
//       console.error("Error in fetching: " + error);
//     } finally {
//     }
//   };
// };

// export const getRevenueData = (props) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: ACTIONS_TYPES.GET_COLUMN_CHART,
//       });
//       let revenueData = [];
//       const response = await ColumnChartApi.getRevenueData(props);
//       if (response?.status === 200) {
//         revenueData = {
//           revenue_details: response?.data?.session_details,
//           xAxisCategories: response?.data?.xAxisCategories,
//         };
//       }
//       dispatch({
//         type: ACTIONS_TYPES.FETCH_REVENUE_DATA,
//         payload: revenueData,
//       });
//       return dispatch(ColumnChartAction.fetchRevenueData(revenueData));
//     } catch (error) {
//       console.error("Error in fetching: " + error);
//     } finally {
//     }
//   };
// };

export const getBarChartData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_BAR_CHART_DATA,
      });
      let barChartData = {};
      const response = await ColumnChartApi.getSessionData(props, data);
      if (response?.status === 200) {
        barChartData = {
          [data.block.block_id]: {
            chart_data: response?.data?.json_chart_data,
            xAxisCategories: response?.data?.category_data,
            color: response?.data?.color,
            data_type: response?.data?.data_type,
            insight: response?.data?.insight,
          },
        };
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_BAR_CHART_DATA,
        payload: barChartData,
      });
      return dispatch(ColumnChartAction.fetchBarChartData(barChartData));
    } catch (error) {
      console.error("Error in fetching: " + error);
    } finally {
    }
  };
};
