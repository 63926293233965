import React from "react";
import { BiLineChart } from "react-icons/bi";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import Config from "../../Config";

const {
  API: { baseURL, authKey },
} = Config;

export function columnChartView(chartData, categoriesData) {
  var chart = {
    chart: {
      type: "column",
    },
    title: {
      useHTML: true,
      text: "",
    },
    subtitle: {
      text: "",
    },

    chart: {
      zoomType: "",
      panning: 0,
      panKey: "",
      selectionMarkerFill: "none",
      resetZoomButton: {
        position: {
          x: 0,
          y: -40,
        },
      },
      borderWidth: "",
      borderColor: "",
      spacingBottom: 20,
      spacingTop: 20,
      height: "",
    },
    xAxis: {
      title: {
        text: "",
      },
      categories: categoriesData,
    },
    yAxis: [
      {
        // tickAmount: 5,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            return this.value.toLocaleString() + "%";
          },
        },
      },
      {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            return this.value + "";
          },
        },
        gridLineWidth: 0,
        opposite: true,
      },
    ],
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      reversed: false,
      enabled: 1,
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.x +
          "</b><br />" +
          this.series.name +
          " : " +
          this.y.toLocaleString() +
          "%"
        );
      },
    },
    plotOptions: {
      series: {
        // pointStart: 2010
      },
    },
    series: chartData,
    credits: {
      enabled: false,
    },
    exporting: {
      showTable: false,
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  return chart;
}

export default columnChartView;
