import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { defaultDashboardList } from "../Redux/Actions/Thunk/userThunk";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import Layout from "../Components/ReportLayouts/Layout";
import FreemiumLayout from "../Components/ReportLayouts/FreemiumLayout";
import DataSourceRedirectPage from "../Components/data-source/DataSourceRedirectPage";
import Account from "../Pages/Accounts/Account";
import AccountAdvancedSetting from "../Pages/Accounts/AccountAdvancedSetting";
import AccountContacts from "../Pages/Accounts/AccountContacts";
import AccountDetail from "../Pages/Accounts/AccountDetail";
import AccountEmailReportLogs from "../Pages/Accounts/AccountEmailReportLogs";
import AccountNotifications from "../Pages/Accounts/AccountNotifications";
import AccountReport from "../Pages/Accounts/AccountReport";
import DsGoogleConsole from "../Pages/DsConfigurations/DSGoogleConsole";
import DsGoogleAdsConfiguration from "../Pages/DsConfigurations/DsGoogleAds";
import DsPinterestConfiguration from "../Pages/DsConfigurations/DsPinterest";
import ResetHistoricalDataConfig from "../Pages/DsConfigurations/DsResetHistoricalDataConfig";
import DsUpdateGoogleConsoleConfiguration from "../Pages/DsConfigurations/DsUpdateGoogleConsole";
import UpdatePinterestConfiguration from "../Pages/DsConfigurations/DsUpdatePinterest";
import UpdateGoogleAdsConfiguration from "../Pages/DsConfigurations/UpdateGoogleAds";
import DsBingAds from "../Pages/DsConfigurations/bingAds/DsBingAds";
import DsUpdateBingAdsConfiguration from "../Pages/DsConfigurations/bingAds/DsBingAdsUpdate";
import DsFacebookAdsInsights from "../Pages/DsConfigurations/facebook/DsFacebookAdsInsightsMCC";
import DsFacebookAdsUpdate from "../Pages/DsConfigurations/facebook/DsFacebookAdsUpdate";
import InternalReports from "../Pages/InternalReports";
import NotFound from "../Pages/Layout/NotFound";
import AccessDenied from "../Pages/Layout/AccessDenied";
import NewRole from "../Pages/Roles/NewRole";
import RoleView from "../Pages/Roles/RoleView";
import Role from "../Pages/Roles/Roles";
import UserUpdate from "../Pages/Users/UserUpdate";
import Users from "../Pages/Users/Users";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppSettings from "../Pages/Settings/AppSettings";
import SEOSettings from "../Pages/Settings/SEOSettings";
import Scorecard from "../Pages/Layout/Scorecard";
import MonthlyEmail from "../Pages/Layout/MonthlyEmail";
import DevNotes from "../Pages/Settings/DevNotes";
import GoogleUpdates from "../Pages/Settings/GoogleUpdates";
import { isCustomer, isNonManagedCustomer } from "../Utils/user";
import Login from "../Pages/Auth/Login";
import SSOLogin from "../Pages/Layout/SSOLogin";
import FreemiumAccount from "../Pages/Accounts/FreemiumAccount";
import GoogleAdsScorecard from "../Pages/Layout/GoogleAdsScorecard";
import AgitalFreemiumLayout from "../Components/ReportLayouts/AgitalFreemiumLayout";
import GadsRedirect from "../Pages/Layout/GadsRedirect";
import FreemiumAccountDetail from "../Pages/Accounts/FreemiumAccountDetail";
import KeyWordResearchLog from "../Pages/Tables/KeyWordResearchLog";
import UnsubscribeEmailReport from "../Pages/Users/UnsubscribeEmailReport";
import Unauthorized from "../Pages/Layout/Unauthorized";
import InsightNotification from "../Pages/InsightNotification";
import FacebookPreview from "../Pages/Tables/CustomComponents/FacebookAdPreview";

export default function LoggedInRoute(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    users: { default_dashboard_list: default_dashboard_list = {} },
  } = useSelector((state) => state);
  function Dashboard() {
    useEffect(() => {
      if (Object.keys(default_dashboard_list).length > 0) {
        if (default_dashboard_list.user_dashboard != "") {
          history.push(default_dashboard_list.user_dashboard);
        }
      }
    }, [default_dashboard_list]);

    useEffect(() => {
      dispatch(defaultDashboardList());
    }, []);
    return <div></div>;
  }
  return (
    <>
      <Switch location={location} key={location.pathname}>
        <ProtectedRoute
          path="/data-sources/:service/set-auth-code"
          component={DataSourceRedirectPage}
        />
        <ProtectedRoute
          exact
          path="/"
          component={Dashboard}
          roleAccess={[1, 2, 3, 4]}
        />

        <ProtectedRoute
          exact
          path="/users"
          component={Users}
          roleAccess={[1]}
        />
        <ProtectedRoute
          exact
          path="/users/form"
          component={UserUpdate}
          roleAccess={[1]}
        />
        <ProtectedRoute
          exact
          path="/users/form/:id"
          component={UserUpdate}
          roleAccess={[1]}
        />

        <ProtectedRoute exact path="/roles" component={Role} roleAccess={[1]} />
        <ProtectedRoute
          exact
          path="/roles/add"
          component={NewRole}
          roleAccess={[1]}
        />
        <ProtectedRoute
          exact
          path="/roles/edit/:id"
          component={RoleView}
          roleAccess={[1]}
        />
        <ProtectedRoute
          exact
          path="/roles/view/:id"
          component={RoleView}
          roleAccess={[1]}
        />
        <ProtectedRoute
          exact
          path="/roles/view/:role_id"
          component={RoleView}
          roleAccess={[1]}
        />
        <ProtectedRoute
          exact
          path="/roles/delete/:id"
          component={RoleView}
          roleAccess={[1]}
        />

        <ProtectedRoute
          exact
          path="/accounts"
          component={Account}
          roleAccess={[1, 2, 3]}
        />

        <ProtectedRoute
          exact
          path="/freemium/accounts"
          component={FreemiumAccount}
          roleAccess={[1, 2, 3]}
        />

        <ProtectedRoute
          exact
          path="/freemium-reports/v2/view/:report_key/:account_id"
          component={FreemiumLayout}
          roleAccess={[1, 2, 3]}
        />

        <ProtectedRoute
          exact
          path="/freemium-agital-reports/v2/view/:report_key/:account_id"
          component={AgitalFreemiumLayout}
          roleAccess={[1, 2, 3]}
        />

        <ProtectedRoute
          exact
          path="/account/detail/:account_id/:serviceId?/:service?/:month?/:marketplace_id?/:budget_target_goal?"
          component={AccountDetail}
          roleAccess={[1, 2, 3]}
        />

        <ProtectedRoute
          exact
          path="/freemium/account/detail/:account_id"
          component={FreemiumAccountDetail}
          roleAccess={[1, 2, 3]}
        />

        <ProtectedRoute
          exact
          path="/accounts/advanced-setting/:account_id"
          component={AccountAdvancedSetting}
          roleAccess={[1, 2, 3]}
        />
        <ProtectedRoute
          exact
          path="/accounts/contact/:account_id"
          component={AccountContacts}
          roleAccess={[1, 2, 3]}
        />
        <ProtectedRoute
          exact
          path="/accounts/email-report-logs/:account_id"
          component={AccountEmailReportLogs}
          roleAccess={[1, 2, 3]}
        />
        <ProtectedRoute
          exact
          path="/accounts/notification/:account_id"
          component={AccountNotifications}
          roleAccess={[1, 2, 3]}
        />

        <ProtectedRoute
          path="/account/reports/:account_id/:service_id?"
          component={AccountReport}
          roleAccess={[1, 2, 3, 4]}
        />
        <ProtectedRoute
          exact
          path="/reports"
          component={
            isCustomer() || isNonManagedCustomer()
              ? AccountReport
              : InternalReports
          }
          roleAccess={[1, 2, 3, 4]}
        />
        <ProtectedRoute
          exact
          path="/reports/v2/view/:report_key/:account_id"
          component={Layout}
          roleAccess={[1, 2, 3, 4]}
        />

        <ProtectedRoute
          exact
          path="/reports/v2/view/:report_key"
          component={Layout}
          roleAccess={[1, 2, 3]}
        />
        <ProtectedRoute
          path="/amazon-mws"
          component={DsGoogleAdsConfiguration}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/data-source/bing-ads-configuration"
          component={DsBingAds}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/data-source/bing-ads-configuration/edit/:id"
          component={DsUpdateBingAdsConfiguration}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/data-source/facebook-ads-insights"
          component={DsFacebookAdsInsights}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/data-source/facebook-ads-insights/edit/:id"
          component={DsFacebookAdsUpdate}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/google-ads-configuration"
          component={DsGoogleAdsConfiguration}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/google-ads-configuration/edit/:id"
          component={UpdateGoogleAdsConfiguration}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/data-source/reset-historical-data"
          component={ResetHistoricalDataConfig}
          roleAccess={[1, 2, 3]}
        />
        <ProtectedRoute
          path="/data-source/google-console-configuration"
          component={DsGoogleConsole}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/data-source/google-console-configuration/edit/:id"
          component={DsUpdateGoogleConsoleConfiguration}
          roleAccess={[1, 2, 3]}
        />
        <ProtectedRoute
          path="/data-source/pinterest-ads-insights/"
          component={DsPinterestConfiguration}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/data-source/pinterest-ads-insights/edit/:id"
          component={UpdatePinterestConfiguration}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute
          path="/keyword_research_log/:account_id"
          component={KeyWordResearchLog}
          roleAccess={[1, 2, 3]}
          exact
        />
        <ProtectedRoute path="/server" component={DataSourceRedirectPage} />
        <ProtectedRoute path="/app-settings" component={AppSettings} />
        <ProtectedRoute path="/seo-settings" component={SEOSettings} />
        <ProtectedRoute path="/scorecard" component={Scorecard} />
        <ProtectedRoute
          path="/reports/v2/views/monthly_email_report/:account_id"
          component={MonthlyEmail}
        />
        <ProtectedRoute path="/sso-dashboard" component={SSOLogin} />
        <ProtectedRoute path="/dev-notes" component={DevNotes} />
        <ProtectedRoute path="/google-updates" component={GoogleUpdates} />
        <Route exact path="/access-denied" component={AccessDenied} />
        <ProtectedRoute exact path="/unauthorized" component={Unauthorized} />
        <ProtectedRoute
          path="/googleadsscorecard"
          component={GoogleAdsScorecard}
        />
        <ProtectedRoute
          path="/nlgreport/unsubscribe"
          component={UnsubscribeEmailReport}
        />
        <ProtectedRoute
          path="/facebook-ad-preview/:id"
          component={FacebookPreview}
        />
        {/* <ProtectedRoute
          path="/save-email-notification-log"
          component={InsightNotification}
        /> */}
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
}
