import React from "react";
import { Switch, Tooltip, Input, Tabs, Checkbox } from "antd"; // Import Ant Design components as needed

const { Search } = Input;

function CustomBtnSwitchTabComponents(props) {
  const {
    searching,
    searchPlaceholder,
    onSearch,
    showGoogleAnalyticsBtn,
    showGoogleAdsBtn,
    showAbsBtn,
    showChangeBtn,
    showMetrics,
    showOrganicBtn,
    showAbsButton,
    showChangeButton,
    pla_ppc_roas_target_goal,
    roas_goal,
    acos_goal,
    changeAnalyticsColumns,
    changeAdsColumns,
    changeAbsAndChangeColumns,
    handleMetricChange,
    toggleMetricsChangeColumnVisibility,
    toggleAbsoluteChangeColumnVisibility,
    toggleChangeColumnVisibility,
    columns,
    FaInfoCircle,
    handleTabProductType,
    showProductBrand,
    tabColumns,
    tooltipText,
    showIncludeEmailSMSBtn,
    includeEmailSmsData,
    includeEmailSMS,
  } = props;

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <>
      <div className="datatable-toggle-info">
        {searching === "true" ? (
          <Search
            placeholder={searchPlaceholder}
            onSearch={onSearch}
            enterButton="Search"
            className="datatable-search"
          />
        ) : (
          ""
        )}

        {searching === "true" && searchPlaceholder == "SEARCH TERM" ? (
          <Checkbox onChange={onChange}>
            {" "}
            Apply to all campaigns / ad groups
          </Checkbox>
        ) : (
          ""
        )}

        {showGoogleAnalyticsBtn === "true" ? (
          <span className="datatable-toggle">
            Show Google Analytics
            <Switch
              size="small"
              id="analyticsBtn"
              onChange={(e) => changeAnalyticsColumns(e, columns)}
              defaultChecked
            />
          </span>
        ) : (
          ""
        )}

        {showGoogleAdsBtn === "true" ? (
          <span className="datatable-toggle">
            Show Google Ads
            <Switch
              size="small"
              id="adsBtn"
              onChange={changeAdsColumns}
              defaultChecked
            />
          </span>
        ) : (
          ""
        )}

        {showIncludeEmailSMSBtn === "true" ? (
          <span className="datatable-toggle">
            Include Email/SMS Data
            <Switch
              size="small"
              checked={includeEmailSMS}
              onChange={(e) => includeEmailSmsData(e)}
            />
          </span>
        ) : (
          ""
        )}

        {/* {showAbsBtn === "true" ? (
          <span className="datatable-toggle">
            Show Absolute Change Columns
            <Switch
              size="small"
              onChange={(e) => changeAbsAndChangeColumns(e, "absoluteColumns")}
            />
          </span>
        ) : (
          ""
        )}

        {showChangeBtn === "true" ? (
          <span className="datatable-toggle">
            Show Change Columns
            <Switch
              size="small"
              onChange={(e) => changeAbsAndChangeColumns(e, "changeColumns")}
            />
          </span>
        ) : (
          ""
        )} */}

        {showMetrics === "true" ? (
          <span className="datatable-toggle">
            Show MER/TROAS in Holistic Rows
            <Switch size="small" onChange={(e) => handleMetricChange(e)} />
          </span>
        ) : (
          ""
        )}

        {showOrganicBtn === "true" ? (
          <span className="datatable-toggle">
            Show Organic Metrics Columns
            <Switch
              defaultChecked={true}
              size="small"
              onChange={(e) =>
                toggleMetricsChangeColumnVisibility(
                  e,
                  "orgMetricsChangeColumns"
                )
              }
            />
          </span>
        ) : (
          ""
        )}

        {showAbsBtn === "true" ? (
          <span className="datatable-toggle">
            Show Absolute Change Columns
            <Switch
              size="small"
              onChange={(e) =>
                toggleAbsoluteChangeColumnVisibility(e, "absoluteChangeColumns")
              }
            />
          </span>
        ) : (
          ""
        )}

        {showChangeBtn === "true" ? (
          <span className="datatable-toggle">
            Show Change Columns
            <Switch
              size="small"
              onChange={(e) => toggleChangeColumnVisibility(e, "changeColumns")}
            />
          </span>
        ) : (
          ""
        )}

        {tooltipText != undefined ? (
          <Tooltip trigger="hover" title={tooltipText}>
            <span
              className="table-header-tooltip"
              style={{
                color: "#8dc441",
                cursor: "pointer",
                marginLeft: 0,
                marginRight: "10px",
              }}
            >
              <FaInfoCircle />
            </span>
          </Tooltip>
        ) : (
          ""
        )}

        {showProductBrand === "true" && tabColumns != undefined ? (
          <span className="datatable-toggle">
            <Tabs
              defaultActiveKey={tabColumns[1]}
              onChange={handleTabProductType}
            >
              <Tabs.TabPane
                tab={tabColumns[0]}
                key={tabColumns[0]}
              ></Tabs.TabPane>
              <Tabs.TabPane
                tab={tabColumns[1]}
                key={tabColumns[1]}
              ></Tabs.TabPane>
            </Tabs>
          </span>
        ) : (
          ""
        )}

        <span className="datatable-calc">
          {pla_ppc_roas_target_goal !== undefined ? (
            <span style={{ display: "flex", alignItems: "center" }}>
              ROAS GOAL
              <Tooltip
                title="First value is PLA ROAS Goal, Second value is PPC ROAS Goal"
                overlayClassName="heading-tooltip"
              >
                <FaInfoCircle
                  style={{
                    margin: "0 5px",
                    color: "#8dc441",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              :{pla_ppc_roas_target_goal}
            </span>
          ) : roas_goal !== undefined ? (
            "ROAS GOAL" + " : " + roas_goal + "%"
          ) : acos_goal !== undefined ? (
            "ACOS GOAL" + " : " + acos_goal + "%"
          ) : (
            " "
          )}
        </span>
      </div>
    </>
  );
}

export default CustomBtnSwitchTabComponents;
