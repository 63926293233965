import React, { useState, useEffect } from "react";
import { Form, Switch } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setDefaults } from "../../Redux/Actions/inputControlAction";

const ToggleComponent = (props) => {
  const { form } = props;
  const defaultData = +props?.block.default;
  const [checked, setChecked] = useState(defaultData);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());

  const {
    inputcontrols: { defaults: defaults = [] },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const onChange = (newChecked) => {
    setChecked(newChecked);
    form.setFieldsValue({
      [props?.block.param_name]: Number(newChecked),
    });
  };

  useEffect(() => {
    let defaultValue = props?.block?.default;
    if (urlSearchParams.get(props?.block?.id) != null) {
      defaultValue = params_data[props?.block?.id];
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    } else {
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    }
  }, [window.location.search]);

  useEffect(() => {
    if (defaults.hasOwnProperty(props?.block?.id)) {
      props.form.setFieldsValue({
        [props?.block?.id]: defaults[props?.block?.id],
      });
    }
  }, [defaults]);

  return (
    <div className={"Report-V2-filter-box" + " " + props?.block.param_name}>
      <Form.Item
        name={props?.block.param_name}
        initialValue={defaultData}
        valuePropName="checked"
        //normalize={(value) => (value ? 1 : 0)}
      >
        <Switch onChange={onChange} checked={checked} />
        <span>{props?.block?.label}</span>
      </Form.Item>
    </div>
  );
};
export default ToggleComponent;
