/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */

import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaPencilAlt } from "react-icons/fa";

const SeoPageTypeDrillDown = (props) => {
  var landing_page = ["landing_page_partial"];
  var record = props?.record;
  if (landing_page.includes(props.column.dataIndex)) {
    return (
      <>
        {props.value != null && (
          <>
            <a
              href={
                "https://www." +
                record["website"] +
                record["landing_page_partial"]
              }
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </a>
          </>
        )}
      </>
    );
  }
  return (
    <div>
      <FormattedNumberCell
        value={props.value}
        format={props.column.format}
        metric={props.column.dataIndex}
      />
    </div>
  );
};

export default SeoPageTypeDrillDown;
