import API from "../Utils/axios_bi";
import { ApiEndpointsBI } from "../Utils/constant";

const { LOGIN, GET_MFA, SAVE_MFA_TOKEN, CHECK_MFA_TOKEN, IMPERSONATE } = ApiEndpointsBI;

export const loginSuccess = async (email, password) => {
  return await API.post(LOGIN, { email, password });
};

export const checkDBMFAToken = async (user_id, ip_address, fingerprint) => {
  return await API.post(GET_MFA, { user_id, ip_address, fingerprint });
};

export const saveMFAToken = async (user_id, ip_address, finger_print) => {
  return await API.post(SAVE_MFA_TOKEN, { user_id, ip_address, finger_print });
};

export const checkMFAToken = async (user_id, ip_address, fingerprint, verification_token) => {
  return await API.post(CHECK_MFA_TOKEN, { user_id, ip_address, fingerprint, verification_token });
};

export const impersonate = async (user_id) => {
  return await API.post(IMPERSONATE, { user_id });
};
