import { Button, Col, Row } from "antd";
import StackedBar from "../StackedBar";
import { useEffect } from "react";
import { getBarChartData } from "../../../../Redux/Actions/Thunk/ColumnChartThunk";
import { formDataAppend } from "../../../../Utils/helpers";
import BarChart from "../../../Highcharts/BarChart";
import ColumnCharts from "../../../Highcharts/ColumnChart";
import { getDataTable } from "../../../../Redux/Actions/Thunk/dataTableThunk";
import { useDispatch, useSelector } from "react-redux";
import AdsDataTable from "./AdsDataTable";

const SearchTermPerformance = (props) => {

    const {
        dataTable: {
            table: {
              [props?.cols[2]?.block?.block_id]: {
                  roas_goal
              } = {},
            },
          } = {},
        graph: {
          graph: {
            [props?.cols[0]?.block?.block_id]: {
				nlg_txt : nlg_data={},
			} = {},
          },
        } = {},
      } = useSelector((state) => state);

      const modifiedNlgText = nlg_data?.data?.length ? nlg_data?.data[0]?.nlg.replace(/\$-/g, '-$') : null;

    const format = "";
    return (
        <>
            <div className="gads-content-single gads-search">
                <div className="gcs-title">
                    <h4>Search Term Performance</h4>
                </div>
                <div className="gcs-single">
                    <div className="gcs-title-btn gsctb-first">
                        <span>
                            ROAS GOAL –  <strong>{roas_goal}%</strong>
                        </span>
                    </div>
                    <div className="gsc-content">
                        <Row gutter={32}>
                            <Col span={12}>
                                <StackedBar 
                                    {...props.cols.filter((prop) => prop.block.block_id === 517)[0]}
                                    format={format}
                                    total_tooltip={false}
                                    all_input_data={props.all_input_data}
                                />
                            </Col>
                            <Col span={12}>
                                 <ColumnCharts 
                                    {...props.cols.filter((prop) => prop.block.block_id === 513)[0]}
                                    format={format}
                                    total_tooltip={false}
                                    all_input_data={props.all_input_data}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="gsc-text">
                        <p>
                            <strong dangerouslySetInnerHTML={{__html:modifiedNlgText}}></strong>
                        </p>
                    </div>
                </div>

                <AdsDataTable
                    {...props.cols.filter((prop) => prop.block.block_id === 501)[0]}
                    title='10 Worst Performing Search Terms'
                    total_label = 'Total Waste'
                    all_input_data={props.all_input_data} 
                />

                <AdsDataTable
                    {...props.cols.filter((prop) => prop.block.block_id === 493)[0]}
                    title='10 Best Performing Search Terms'
					total_label = 'Total Good'
                    all_input_data={props.all_input_data} 
                />

                <div className="gads-imporve-btn">
                        <a href="https://agital.com/ecommerce-analysis/">
									<Button>Improve my score</Button>
								</a>
                </div>
            </div>
        </>
    )
}

export default SearchTermPerformance;