import { useDispatch, useSelector } from "react-redux";
import { saveNotificationLog } from "../Redux/Actions/Thunk/userThunk";
import { useCallback, useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import useRemoveToken from "../hooks/useRemoveToken";
import { checkIfTokenHasExpired } from "../Utils";
import Config from "../Config";
import { Token } from "../Utils/helpers/token";
import { LOGOUT } from "../Redux/Actions/types";
import { numberWithCommas } from "../Utils/helpers";
import {
  getServiceSettings,
  saveServiceSettings,
  saveTargetGoal,
} from "../Redux/Actions/Thunk/serviceSettingsThunk";
import moment from "moment";

function InsightNotification(props) {
  const current_url = window.location.href;
  const {
    SSO_LOGIN: { baseURL: ssoBaseURL, api_base_url },
    API_BI: { baseURL: apiBaseURL },
  } = Config;

  const settingsState = useSelector((state) => state.settings);

  const { service_settings: { setting: setting = null } = {} } = settingsState;

  const [isModalOpen, setIsModalOpen] = useState(true);

  const logOut = async () => {
    try {
      // Use 'try' to catch any errors from the 'fetch' call.

      if (!localStorage.getItem("token")) {
        const domain = window.location.href;
        window.location.href = `${ssoBaseURL}login?domain=${domain}`;
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          Token.encryptAuthParams(localStorage.getItem("token"))
        );

        var raw = JSON.stringify({
          token: localStorage.getItem("token"),
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        const response = await fetch(`${api_base_url}logout`, requestOptions);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        dispatch({
          type: LOGOUT,
        });
        const type = "success";
        const message = "Success";
        const description = "Successfully Logout.";
        // openNotificationWithIcon(type, message, description);

        const result = await response.text();

        localStorage.removeItem("token");
        localStorage.removeItem("user");

        const currentPath = window.location.origin + window.location.pathname;
        let currentSearch = window.location.search;

        // Replace the first '?' with '&', if present
        if (currentSearch.indexOf("?") === 0) {
          currentSearch = "&" + currentSearch.slice(1);
        }

        const loginURL = `${ssoBaseURL}login?domain=${currentPath}${currentSearch}`;

        window.location.replace(loginURL);
      }
    } catch (error) {
      const domain = window.location.href;
      window.location.href = `${ssoBaseURL}login?domain=${window.location.href}`;
    }
  };

  // Check token expiry
  useEffect(() => {
    checkIfTokenHasExpired(localStorage.getItem("token"))
      .then((tokenExpired) => {
        // Handle the result of whether the token has expired or not
        if (tokenExpired) {
          // Do something if the token has expired or there was an error
          logOut();
        } else {
          // Do something if the token is still valid
        }
      })
      .catch((error) => {
        // Handle any other errors that occurred during the API request
        console.error("An unexpected error occurred:", error);
      });
  }, [current_url]);
  const queryParams = new URLSearchParams(window.location.search);

  const {
    users: { notification_response, isLoading },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  // Fetch data
  useEffect(() => {
    if (queryParams.get("action_id") != 3) {
      dispatch(saveNotificationLog(queryParams));
    }
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  var account_id = queryParams.get("account_ids");
  const promises = [];
  const handleOk = useCallback(async () => {
    setIsModalOpen(false);
    dispatch(saveNotificationLog(queryParams));
    var account_id = queryParams.get("account_ids");
    var target_goals = queryParams.getAll("target_goals");
    var month = queryParams.get("month");
    var marketplace_id = queryParams.getAll("marketplace_ids");
    for (var i = 0; i < services.length; i++) {
      (function (index) {
        // IIFE to capture the value of i
        var serviceId;
        if (services[index] == "Paid Search (Google/Bing)") {
          serviceId = 3;
        } else if (services[index] == "Product Ads (Google/Bing)") {
          serviceId = 4;
        } else {
          serviceId = 10;
        }

        const promise = dispatch(
          getServiceSettings({
            account_id: account_id,
            service_id: serviceId,
            setting_month: moment(month).format("YYYY-MM"),
            marketplace_id: serviceId == 5 ? marketplace_id[index] : null,
          })
        );

        promises.push(promise);

        promise
          .then((response) => {
            var payload_settings = response.payload.setting;
            var default_settings = response.payload.default_setting;
            var service_info = response.payload.service_info;
            if (payload_settings) {
              var json1 = JSON.parse(payload_settings);
            } else {
              var json1 = JSON.parse(default_settings);
            }
              json1.budget_target_goal = {
                value: target_goals[index],
                caption: "Budget Target Goal",
              };
              var serviceSettingsParams = {};
              for (var key in json1) {
                serviceSettingsParams[key] = json1[key].value;
              }

              var service_start_date = service_info?.service_start_date
                ? moment(service_info?.service_start_date).format("YYYY-MM-DD")
                : null;
              var service_end_date = service_info?.service_end_date
                ? moment(service_info?.service_end_date).format("YYYY-MM-DD")
                : null;
              var billing_reset_date = service_info?.billing_reset_date
                ? moment(service_info?.billing_reset_date).format("YYYY-MM-DD")
                : null;
              var original_service_start_date =
                service_info?.orginal_service_start_date
                  ? moment(service_info?.orginal_service_start_date).format(
                      "YYYY-MM-DD"
                    )
                  : null;
              serviceSettingsParams["account_id"] = account_id;
              serviceSettingsParams["service_id"] = serviceId;
              serviceSettingsParams["setting_month"] =
                moment(month).format("YYYY-MM");
              serviceSettingsParams["service_start_date"] = service_start_date;
              serviceSettingsParams["service_end_date"] = service_end_date;
              serviceSettingsParams["billing_reset_date"] = billing_reset_date;
              serviceSettingsParams["original_service_start_date"] =
                original_service_start_date;
              serviceSettingsParams["marketplace_id"] = serviceId == 5 ? marketplace_id[index] : null

              var json_stringify = JSON.stringify(serviceSettingsParams);
              dispatch(saveTargetGoal(json_stringify));
          })
          .catch((error) => {
            console.error("Error occurred while dispatching API calls:", error);
          });
      })(i); // Pass i to the IIFE
    }
  }, [dispatch, account_id]);

  if (queryParams.get("action_id") == 3) {
    var services = queryParams.getAll("services");
    var target_goals = queryParams.getAll("target_goals");
    var account_name = queryParams.get("account_name");
    if (services.length == 1) {
      var confirmtxt =
        "Are you sure you want to update maximum budgets for " +
        account_name +
        " to the following:" +
        "-  " +
        services[0] +
        " to $" +
        numberWithCommas(target_goals[0]);
    } else if (services.length == 2) {
      var confirmtxt =
        "Are you sure you want to update maximum budgets for " +
        account_name +
        " to the following:" +
        "-  " +
        services[0] +
        " to $" +
        numberWithCommas(target_goals[0]) +
        "<br>" +
        "-  " +
        services[1] +
        " to $" +
        numberWithCommas(target_goals[1]) +
        "";
    } else {
      var confirmtxt =
        "Are you sure you want to update maximum budgets for " +
        account_name +
        " to the following: <br>" +
        "-  " +
        services[0] +
        " to $" +
        numberWithCommas(target_goals[0]) +
        "<br>" +
        "-  " +
        services[1] +
        " to $" +
        numberWithCommas(target_goals[1]) +
        "<br>" +
        "-  " +
        services[2] +
        " to $" +
        numberWithCommas(target_goals[2]) +
        "";
    }
  }

  return (
    <>
      {queryParams.get("action_id") == 3 ? (
        <>
          {" "}
          <Modal
            title=" "
            visible={isModalOpen}
            onOk={handleOk}
            okText="Yes" //TBD
            onCancel={handleCancel}
            cancelText="No"
          >
            {confirmtxt}
          </Modal>
          <Spin spinning={isLoading}>
            <div
              className="insights-notification-wrapper"
              dangerouslySetInnerHTML={{ __html: notification_response?.data }}
            />
          </Spin>{" "}
        </>
      ) : (
        <Spin spinning={isLoading}>
          <div
            className="insights-notification-wrapper"
            dangerouslySetInnerHTML={{ __html: notification_response?.data }}
          />
        </Spin>
      )}
    </>
  );
}
export default InsightNotification;
