import {
  createTiktokThunk,
  updateTiktokThunk,
} from "../../../Redux/Actions/Thunk/datasources/tiktokThunk";
import errorHandler from "../../../Utils/Request/errorHandler";

const dispatchTiktokServiceDataSource = (dispatch, payload) => {
  const { dataSourceMetaData, starTableData, dataSourceDataFromThirdParty } =
    payload;

  let inputDataFromUser = JSON.parse(payload.dataSourceInputDataByClientInForm);

  const advertiserId = inputDataFromUser.advertiser_id;
  // in real life, this data will come from the third party api js gaming
  dataSourceDataFromThirdParty.profile.data.advertiser_ids = [
    "Apple",
    "Banana",
    "Cat",
    "Dog",
  ];
  if (
    dataSourceDataFromThirdParty.profile.data.advertiser_ids.includes(
      inputDataFromUser.advertiser_id
    )
  ) {
    if (dataSourceMetaData.isForUpdate && dataSourceMetaData.id) {
      // update the data source with update dispatch
      dispatch(
        updateTiktokThunk({
          client_info: {
            id: dataSourceMetaData.id,
            advertiser_id: advertiserId,
            access_token:
              dataSourceDataFromThirdParty.profile.data.access_token,
          },
          star_table_data: starTableData,
        })
      );
    } else {
      //   create the data source with create dispatch
      dispatch(
        createTiktokThunk({
          client_info: {
            advertiser_id: advertiserId,
            access_token:
              dataSourceDataFromThirdParty.profile.data.access_token,
          },
          star_table_data: starTableData,
        })
      );
    }
  } else {
    errorHandler({
      response: {
        data: {
          message: "This advertiser id is not associated with this account. ",
        },
        status: 400,
      },
    });
  }
};

export default dispatchTiktokServiceDataSource;
