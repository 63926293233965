export const initialState = {
  //isLoading: false,
  table: {},
};

export const ACTIONS_TYPES = Object.freeze({
  REQUEST_TABLE: "REQUEST_TABLE",
  FETCH_TABLE: "FETCH_TABLE",
  REQUEST_EXPORT_TABLE: "REQUEST_EXPORT_TABLE",
  FETCH_EXPORT_TABLE: "FETCH_EXPORT_TABLE",
  RESET_TABLE: "RESET_TABLE",
});

export function dataTableReducer(state = initialState, action) {
  const { key, payload } = action;
  switch (action.type) {
    case ACTIONS_TYPES.REQUEST_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          [payload]: {
            //...state.table[payload],
            isLoading: true,
          },
        },
      };
    case ACTIONS_TYPES.FETCH_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          [key]: {
            ...state.table[key],
            isLoading: false,
            ...payload,
          },
        },
      };
    case ACTIONS_TYPES.REQUEST_EXPORT_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          [payload]: {
            ...state.table[payload],
            isLoading: true,
          },
        },
      };
    case ACTIONS_TYPES.FETCH_EXPORT_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          [payload]: {
            ...state.table[payload],
            isLoading: false,
          },
        },
      };
    case ACTIONS_TYPES.RESET_TABLE:
      return {
        ...state,
        table: {
          isLoading: true,
          // ...state.table,
          // [key]: {},
        },
      };
    // case ACTIONS_TYPES.RESET_TABLE:
    //   return {
    //     ...state,
    //     table: {
    //       ...state.table,
    //       [key]: {
    //         isLoading: false,
    //       },
    //     },
    //   };

    default:
      return state;
  }
}
