export const initialState = {
  accounts_ds: {},
  single_account_ds: {},
  accounts_website: {},
  accounts_website_data: {},
  error_ds: {},
  isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
  ACCOUNT_DS_REQUEST: "ACCOUNT_DS_REQUEST",
  FETCH_ACCOUNT_DS: "FETCH_ACCOUNT_DS",
  ACCOUNT_WEBSITE_REQUEST: "ACCOUNT_WEBSITE_REQUEST",
  GET_SINGLE_ACCOUNT_DS: "GET_SINGLE_ACCOUNT_DS",
  FETCH_ACCOUNT_WEBSITE: "FETCH_ACCOUNT_WEBSITE",
  FETCH_ACCOUNT_WEBSITE_DATA: "FETCH_ACCOUNT_WEBSITE_DATA",
  UPDATE_ERROR_MESSAGE_DS: "UPDATE_ERROR_MESSAGE_DS",
  FETCH_ACCOUNT_WEBSITE_DATA_FAILED: "FETCH_ACCOUNT_WEBSITE_DATA_FAILED",
});

export function accountDataSourcesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.ACCOUNT_DS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_DS:
      return {
        ...state,
        isLoading: false,
        accounts_ds: action.payload,
      };
    case ACTIONS_TYPES.GET_SINGLE_ACCOUNT_DS:
      return {
        ...state,
        isLoading: false,
        single_account_ds: action.payload,
      };

    case ACTIONS_TYPES.ACCOUNT_WEBSITE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_WEBSITE:
      return {
        ...state,
        isLoading: false,
        accounts_website: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_WEBSITE_DATA:
      return {
        ...state,
        isLoading: false,
        accounts_website_data: action.payload,
      };
    case ACTIONS_TYPES.UPDATE_ERROR_MESSAGE_DS:
      return {
        ...state,
        isLoading: false,
        error_ds: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_WEBSITE_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        accounts_website_data: {},
      };
    default:
      return state;
  }
}
