export const initialState = {
  sessionData: {},
  conversionsData: [],
  revenueData: [],
  barChartData: [],
  isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
  GET_COLUMN_CHART: "GET_COLUMN_CHART",
  FETCH_COLUMN_CHART: "FETCH_COLUMN_CHART",
  FETCH_SESSSION_DATA: "FETCH_SESSION_DATA",
  FETCH_CONVERSIONS_DATA: "FETCH_CONVERSIONS_DATA",
  FETCH_REVENUE_DATA: "FETCH_REVENUE_DATA",
  GET_BAR_CHART_DATA: "GET_BAR_CHART_DATA",
  FETCH_BAR_CHART_DATA: "FETCH_BAR_CHART_DATA",
});

export function ColumnChartReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.GET_COLUMN_CHART:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_SESSSION_DATA:
      return {
        ...state,
        isLoading: false,
        sessionData: { ...state.sessionData, ...action.payload },
      };
    case ACTIONS_TYPES.FETCH_CONVERSIONS_DATA:
      return {
        ...state,
        isLoading: false,
        conversionsData: action.payload,
      };
    case ACTIONS_TYPES.FETCH_REVENUE_DATA:
      return {
        ...state,
        isLoading: false,
        revenueData: action.payload,
      };
    case ACTIONS_TYPES.GET_BAR_CHART_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_BAR_CHART_DATA:
      return {
        ...state,
        isLoading: false,
        barChartData: { ...state.barChartData, ...action.payload },
      };
    default:
      return state;
  }
}
