import Config from "../../Config";
import { facebookCredentialsAPI } from "../../Api/datasources/dsConfig/facebookAdsInsightMccApi";
import { Token } from "../../Utils/helpers/token";
import { StringHelper } from "../../Utils/helpers/string";

const {
  API_BI: { BASE_URL_FOR_BI_APP },
} = Config;

class FacebookConfig {
  constructor() {
    // grant_type={0}&code={1}&client_id={2}&client_secret={3}&redirect_uri={4}
    const environment = process.env.REACT_APP_ENV;
    this.scope = "email"; // "ads_read,ads_management";
    this.facebookCallBackURL = `${BASE_URL_FOR_BI_APP}/data-sources/facebook/set-auth-code/`;
    this.facebookTokenAccessUrl = `https://graph.facebook.com/oauth/access_token?client_id={0}&client_secret={1}&grant_type=client_credentials`;
    this.consineScreenOpenUrl = `https://www.facebook.com/v16.0/dialog/oauth?client_id={0}&state=1234&response_type=code&redirect_uri=${this.facebookCallBackURL}&scope=${this.scope}`; // this is for testing but in product you need ads read and ads management as spope
    this.facebookAppID = undefined;
    this.facebookSecret = undefined;
  }
  async init() {
    if (this.facebookAppID && this.facebookSecret) {
      this.consineScreenOpenUrl = StringHelper.format(
        this.consineScreenOpenUrl,
        this.facebookAppID
      );
      return {
        facebookAppID: this.facebookAppID,
        facebookSecret: this.facebookSecret,
      };
    }

    try {
      const { data } = await facebookCredentialsAPI();
      this.facebookAppID = Token.decryptAuthParams(data.secret.client_id);
      this.facebookSecret = Token.decryptAuthParams(data.secret.client_secret);
      this.consineScreenOpenUrl = StringHelper.format(
        this.consineScreenOpenUrl,
        this.facebookAppID
      );
      return {
        facebookAppID: this.facebookAppID,
        facebookSecret: this.facebookSecret,
      };
    } catch (error) {
      // alert("Something went wrong  . Please refresh the page and try again");
      throw new Error("Missing facebook Secrets");
    }
  }

  async facebookTokenAccess() {
    await this.init();
    const url = this.facebookTokenAccessUrl;
    if (!url) {
      throw new Error("Missing Tokens for fetching access token ");
    }
    const completeUrl = StringHelper.format(
      url,
      this.facebookAppID,
      this.facebookSecret
    );
    
    return completeUrl;
  }
}
export const facebookConfig = new FacebookConfig();
