import { Row, Col, Spin, Button, message } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";

const {
    API_BI: { baseURL },
} = config;

const UnsubscribeEmailReport = (props) => {
    const {
        accounts: {
            isLoading,
        },
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    const onUnsubscribe = () => {
        dispatch({
            type: ACTIONS_TYPES.API_REQUEST_INIT,
        });
        API_BI.defaults.headers.common["Authorization"] =
            localStorage.getItem("token");
        API_BI.post(baseURL + "api/v1/unsubscribe-email-report").then((response) => {
            if (response.status == 200) {
                if (response.data.success) {
                    message.success(response.data.message);
                }
                else {
                    message.error(response.data.message);
                }

                dispatch({
                    type: ACTIONS_TYPES.API_REQUEST_COMPLETE,
                });
            }
        });
    };

    return (
        <>
            <div className="panel-heading">
                <Row>
                    <Col span={12} className="panel-heading-title">
                        <h2>Unsubscribe: Monthly Email Report</h2>
                    </Col>
                </Row>
            </div>
            <div className="panel-body">
                <div className="panel-body-content">
                    <Spin spinning={isLoading}>
                        <h2>You will not get monthly email report if you unsubscribe. Are you sure to unsubscribe?</h2>
                        <Button onClick={onUnsubscribe} className="ant-btn alert-save">
                            UNSUBSCRIBE
                        </Button>
                    </Spin>
                </div>
            </div>
        </>
    );
};

export default UnsubscribeEmailReport;
