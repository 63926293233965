import { useEffect, useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Input, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteGoogleConsoleDataSourcesThunk } from "../../../Redux/Actions/Thunk/datasources/ds_configs/GoogleConsoleThunk";
import { BsCodeSlash } from "react-icons/bs";
import { facebookConfig } from "../../../Config/datasources/facebookConstants";
import { listOfAllDataSources } from "../../../Config/datasources/allDatasources";
import { Token } from "../../../Utils/helpers/token";

const FacebookAdsInsightsTable = () => {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);

  const [visibleModal, setVisibleModal] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const dispatch = useDispatch();
  const {
    account_mccs: { auth_params = [] },
  } = useSelector((state) => state.facebookAdsInsightsMcc);

  const handleOnClickOkay = (data) => {
    setVisibleModal(true);
    setCurrentId(data.id);
  };

  useEffect(() => {
    setDatas(auth_params);
  }, [auth_params.length]);

  const onFinish = (account_ds) => {
    let url = facebookConfig.consineScreenOpenUrl;
    setLoading(true);
    const params = new URL(window.location.href);

    const storageData = {
      dataSourceMetaData: {
        dataSourceName: listOfAllDataSources["facebook"],
        dataSourceUrl: params.pathname,
        isForUpdate: true,
        id: account_ds?.id,
      },
      starTableData: {
        // account_id: currentDataSouceAccountId,
        // bi_data_source_id: `${dsData.bi_data_source_id}`,
      },
      dataSourceInputDataByClientInForm: JSON.stringify({}),
    };
    const encryptedData = Token.encryptAuthParams(
      `${JSON.stringify(storageData)}`
    );

    // set data in local storage
    localStorage.setItem("data_source_information", encryptedData);

    window.location.href = url;
  };

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      ellipsis: false,
      key: "user",
      render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
    },

    {
      title: "Expires  At",
      key: "expires_on",
      render: (_, record) => {
        let dateAfterTwoMonths = new Date(record.updated_at);
        dateAfterTwoMonths.setMonth(dateAfterTwoMonths.getMonth() + 2);

        return <div>{dateAfterTwoMonths.toUTCString()} </div>;
      },
    },

    {
      title: "Updated At",
      render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div
            style={{ display: "flex", gap: "5px", justifyContent: "center" }}
          >
            <Link to={`/data-source/facebook-ads-insights/edit/${record.id}`}>
              <EditOutlined
                style={{
                  height: "20px",
                  width: "20px",
                  backgroundColor: "rgb(141,195,64)",
                  borderRadius: "50%",
                  color: "white",
                  textAlign: "center",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              />
            </Link>

            {/* <Link to={`/data-source/bing-ads-configuration/edit/${record.id}`}> */}
            <BsCodeSlash
              onClick={() => onFinish(record)}
              style={{
                height: "20px",
                width: "20px",
                backgroundColor: "rgb(141,195,64)",
                borderRadius: "50%",
                color: "white",
                textAlign: "center",
                lineHeight: "20px",
                cursor: "pointer",
              }}
            />
            {/* </Link> */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Input
        placeholder="User"
        onChange={(e) => {
          const filteredData = auth_params.filter((item) => {
            return item.user
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          });
          setDatas(filteredData);
        }}
      />
      <Table columns={columns} dataSource={datas} />
    </>
  );
};

export default FacebookAdsInsightsTable;
