export const initialState = {
  users: {},
  isLoading: false,
  roles: [],
  user_detail: {},
  default_dashboard_list: {},
  notification_response: ""
};

export const ACTIONS_TYPES = Object.freeze({
  USER_REQUEST: "USER_REQUEST",
  FETCH_USER: "FETCH_USER",
  GET_USER: "GET_USER",
  FETCH_ROLES: "FETCH_ROLES",
  FETCH_USER_DETAIL: "FETCH_USER_DETAIL",
  DEFAULT_DASHBOARD_LIST: "DEFAULT_DASHBOARD_LIST",
  SAVE_NOTIFICATION_LOG: "SAVE_NOTIFICATION_LOG"
});

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_USER:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ROLES:
      return {
        ...state,
        isLoading: false,
        roles: action.payload,
      };
    case ACTIONS_TYPES.FETCH_USER_DETAIL:
      return {
        ...state,
        isLoading: false,
        user_detail: action.payload,
      };
      case ACTIONS_TYPES.DEFAULT_DASHBOARD_LIST:
        return {
          ...state,
          default_dashboard_list: action.payload,
        };
      case ACTIONS_TYPES.SAVE_NOTIFICATION_LOG:
        return {
          ...state,
          isLoading: false,
          notification_response: action.payload,
        };
    default:
      return state;
  }
}
