import { DataSourcesApiEndPoints } from "../../Utils/constant";
import API_BI from "../../Utils/axios_bi";

const {
  amazonSponsorAds: {
    getAmazonSponsorAds,
    updateAmazonSponsorModel,
    postAmazonSponsorAds,
  },
} = DataSourcesApiEndPoints;

/**
 * @description API function to get Amazon Sponsor Ads data
 * @param {string} region - The region to retrieve data from
 * @returns {Promise<response>}
 */
export const getAmazonSponsorAdsData = async (region, props) => {
  const route = `${getAmazonSponsorAds}/${region.toLowerCase()}/get`;
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(route, props);
};

/**
 * @description API function to create Amazon Sponsor Ads data
 * @param {object} data - The data to be posted
 * @param {string} region - The region to post the data to
 * @returns {Promise<response>}
 */
export const createAmazonSponsorAdsData = async (data, region) => {
  const route = `${postAmazonSponsorAds}/${region.toLowerCase()}/`;
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(route, data);
};

/**
 * @description API function to update Amazon Sponsor Ads data
 * @param {object} data - The data to be updated
 * @param {string} region - The region to update the data in
 * @returns {Promise<response>}
 */
export const updateAmazonSponsorAdsData = async (data, region) => {
  const route = `${postAmazonSponsorAds}/${region.toLowerCase()}/`;
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(route, data);
};

export const updateAmazonSponsorAdsModelData = async (data, region) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(updateAmazonSponsorModel, data);
};
