import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { isLoggedIn } from "../Utils";
import { getUserGroup } from "../Utils/user";

const checkAccess = (roleAccess) =>{
  const user_groups = getUserGroup().map(parseInt);
  //if no role access is set, anyone can access
  if(roleAccess==undefined){
    roleAccess = [1];
  }
  let access_group = user_groups.filter(value => roleAccess.includes(value));
  //console.log(getUserGroup(), user_groups, roleAccess, access_group);
  if(access_group.length>0){
    return true;
  }else{
    return false;
  }
}

const ProtectedRoute = ({ component: Component, roleAccess: roleAccess, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn() ? (
        checkAccess(roleAccess) ? (
        <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/reports",
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default withRouter(ProtectedRoute);
