import { Button, Table, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import { FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const {
	API_BI: { baseURL },
} = config;

const SEOSettings = (props) => {
	const [seoSettingsData, setSeoSettingsData] = useState([]);

	const dispatch = useDispatch();

	const getRowKey = (record) => record.id;

	const isLoading = useSelector((state) => state.accounts.isLoading);

	useEffect(() => {
		dispatch({
			type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
		});
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.get(baseURL + "api/v1/seo-settings").then((response) => {
			if (response.status == 200) {
				setSeoSettingsData(response.data?.data);
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
				});
			}
		});
	}, []);

	const columns = [
		{
			title: "Factor",
			dataIndex: "factor",
			key: "factor",
		},
		{
			title: "Updated At",
			dataIndex: "updated_at",
			key: "updated_at",
		},
		{
			title: "Actions",
			dataIndex: "",
			key: "actions",
			render: (_, record) => (
				<div className="panel-action-button">
					<Link to={`/seo-setting-edit/${record.id}`} style={{ pointerEvents: "none" }}>
						<FaPencilAlt />
					</Link>
				</div>
			),
		},
	];

	return (
		<>
			<div className="panel-heading">
				<Row>
					<Col span={24} className="panel-heading-title">
						<h2>SEO Setting: Client Capacity</h2>
					</Col>
				</Row>
			</div>
			<div className="panel-body">
				<div className="panel-body-content">
					<Table
						rowKey={getRowKey}
						columns={columns}
						dataSource={seoSettingsData}
						loading={isLoading}
						pagination={false}
					/>
				</div>
			</div>
		</>
	);
};

export default SEOSettings;
