import { useDispatch, useSelector } from "react-redux";
import { getOrganicSeoData } from "../../../Redux/Actions/Thunk/barGraphThunk";
import { useEffect } from "react";
import { formDataAppend } from "../../../Utils/helpers";

const StrikingChart = (props) => {

    const {filterData} = props;

    const {
        graph:{
            graph:{
                [props?.block?.block_id]: {
                    data: striking_report
                } = {},
            },
            isLoading,
        } = {},
    } = useSelector((state) => state );
    const dispatch = useDispatch();
    
    useEffect(()=>{
        let data = props?.all_input_data;
        let formData = formDataAppend(data);
        dispatch(getOrganicSeoData(formData, props));
    }, [filterData]);  

    return (
        <>
            <div className="organic-scorecard-striking organic-scorecard-single">
						<div className="os-single-title">
							<h4>Striking distance keywords</h4>
						</div>
						<div className="os-single-header">
							<div className="ossh-single">
								<h4>Keywords in striking distance</h4>
								<h2>{striking_report?.total_striking_keywords.toLocaleString()}</h2>
							</div>
						</div>
						<div className="os-single-content">
							<div className="ossc-table">
								<table>
                                    <thead>
                                        <tr>
											<th>Keyword</th>
											<th>Search Volume</th>
											<th>Rank</th>
											<th>URL</th>
										</tr>
                                    </thead>
									<tbody>
                                    {
                                        striking_report?.top_n_striking_keywords?.map((keywordData, index) => (
                                            
                                            <tr key={index}>
                                                <td>{keywordData.Keyword}</td>
                                                <td>{keywordData.Search_Volume.toLocaleString()}</td>
                                                <td>{keywordData.Rank}</td>
                                                <td>{keywordData.URL}</td>
                                            </tr>
                                        ))
                                    }
									</tbody>
								</table>
							</div>
							<p>
								Striking distance keywords are those that rank between positions <b>5 and 20</b>. 
								Because they are so close to page 1 and the top
								of page 1, any improvement in ranking can have a significant
								effect on organic traffic.
							</p>
							<p>
								Your site has <b>{striking_report?.total_striking_keywords.toLocaleString()}</b> keywords in striking distance, with a sample
								shown above.
							</p>
						</div>
						<div className="os-single-help">
							<h4>How we can help</h4>
							<p>
								Achieving page 1 rank for these keywords is more than creating
								content around exact-match optimization. Learn how we can help
								with on- and off-page signals that target the lowest hanging
								fruit for your site.
							</p>
							<a href="https://agital.com/ecommerce-analysis/"
								target="_blank"
							>
								<button>Get Help Ranking on Page 1</button>
							</a>
						</div>
					</div>
        </>
    )
}

export default StrikingChart;