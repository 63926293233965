/**
 * @fileoverview Reducer for Paid Opportunity Data Sources
 *
 * The reducer handles the state changes for the Google.
 */

// Define the initial state of the reducer
export const initialState = {
    account_ds: {}, // for single account data source
    account_dss: {}, // forall  account data sources . i used dss because of data sources . note "s" at the end, it is plural
    error_ds: {}, // error data sources
    isLoading: false, // loading state
  };
  
  // Define action types to be used in the reducer
  export const ACTIONS_TYPES = Object.freeze({
    ORGANIC_SEARCH_OPPORTUNITY_TRACKER_DS_REQUEST: "ORGANIC_SEARCH_OPPORTUNITY_TRACKER_DS_REQUEST",
    GET_ORGANIC_SEARCH_OPPORTUNITY_TRACKER_DS: "GET_ORGANIC_SEARCH_OPPORTUNITY_TRACKER_DS", // Fetch Account Data Sources
    CREATE_ORGANIC_SEARCH_OPPORTUNITY_TRACKER: "CREATE_ORGANIC_SEARCH_OPPORTUNITY_TRACKER", // Create Account Data Sources
    UPDATE_ORGANIC_SEARCH_OPPORTUNITY_TRACKER: "UPDATE_ORGANIC_SEARCH_OPPORTUNITY_TRACKER", // Update Account Data Sources
    ORGANIC_SEARCH_OPPORTUNITY_TRACKER_ERROR_DS: "ORGANIC_SEARCH_OPPORTUNITY_TRACKER_ERROR_DS", // Error Account Data Sources
    FAILED_ORGANIC_SEARCH_OPPORTUNITY_TRACKER: "FAILED_ORGANIC_SEARCH_OPPORTUNITY_TRACKER",
    RESET_ORGANIC_SEARCH_OPPORTUNITY_TRACKER: "RESET_ORGANIC_SEARCH_OPPORTUNITY_TRACKER",
  });
  
  // Define the reducer function
  export function organicSearchOpportunityReducer(state = initialState, action = {}) {
    const { type, payload } = action;
  
    // Switch case to handle different types of actions
    switch (type) {
      case ACTIONS_TYPES.ORGANIC_SEARCH_OPPORTUNITY_TRACKER_DS_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case ACTIONS_TYPES.GET_ORGANIC_SEARCH_OPPORTUNITY_TRACKER_DS:
        return {
          ...state,
          isLoading: false,
          account_ds: action.payload,
        };
      case ACTIONS_TYPES.GET_ALL_GOOGL_CONSOLES_DS:
        return {
          ...state,
          isLoading: false,
          account_dss: action.payload,
        };
  
      case ACTIONS_TYPES.ORGANIC_SEARCH_OPPORTUNITY_TRACKER_ERROR_DS:
      case ACTIONS_TYPES.FAILED_ORGANIC_SEARCH_OPPORTUNITY_TRACKER:
        return {
          ...state,
          isLoading: false,
          error_ds: payload,
        };
  
      case ACTIONS_TYPES.RESET_ORGANIC_SEARCH_OPPORTUNITY_TRACKER:
        return {
          ...state,
          isLoading: false,
          account_ds: {},
          account_dss: {},
          error_ds: {},
        };
  
      // Return the initial state if no matching action is found
      default:
        return state;
    }
  }
  