import { ACTIONS_TYPES } from "../../Reducers/globalReducer";

export const setLoadingTrue = () => {
  return {
    type: ACTIONS_TYPES.SET_LOADING,
  };
};

export const setLoadingFalse = () => {
  return {
    type: ACTIONS_TYPES.REMOVE_LOADING,
  };
};