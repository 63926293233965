import { Button, Form, Input } from "antd";

const PInterestCreateConfigurationForm = ({
  form,
  onFinish,
  onFinishFailed,
}) => {
  const handleChange = (info) => {
    return info;
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        name="google_ads_form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="User"
          name="user"
          rules={[
            {
              required: true,
              message: "User Email",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input placeholder="User" />
        </Form.Item>
        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add And Generate Token
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default PInterestCreateConfigurationForm;
