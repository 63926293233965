import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col } from "antd";
import { getColumnLineData } from "../../Redux/Actions/Thunk/ColumnLineThunk";
import { formDataAppend } from "../../Utils/helpers";
import { Spin } from "antd";
import TrendsTypeTable from "../ReportBlocks/TrendTypeTable";

const ColumnLineChart = (props) => {
  const {
    columnLineData: {
      columnLineData: {
        [props?.block?.block_id]: {
          chart_data: chartData = [],
          xAxisCategories: xAxisData = [],
          table_data: tableData = [],
          channels_labels: channelLabels = [],
        } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    if (props?.length == 0) {
      dispatch(getColumnLineData(formData, props));
    }
  }, []);

  /*for filter data*/
  const [filter, setFilter] = useState("");
  const { filterData } = props;

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getColumnLineData(formData, props));
  }, [filterData]);

  return (
    <>
      <TrendsTypeTable
        tableData={tableData}
        channelLabels={channelLabels}
        props={props}
      />
      <Row gutter={[16, 24]}>
        {chartData?.map((seriesData, index) => {
          const options = {
            chart: {
              type: "column",
              height: 400,
              width: null,
              zoomType: "x",
              panning: true,
              panKey: "shift",
              selectionMarkerFill: "none",
              resetZoomButton: {
                position: {
                  x: 0,
                  y: -40,
                },
              },
            },
            title: {
              text: `${seriesData[0]?.key ?? ""}`,
              align: "left",
              useHTML: true,
              style: {
                fontWeight: "bold",
              },
            },
            tooltip: {
              formatter: function () {
                for (const [key, value] of Object.entries(seriesData)) {
                  let df = value?.format;
                  let dn = value?.name;
                  if (dn == this.series.name && df == "amount") {
                    var round_off = ["CPL"].includes(this.series?.name) ? 2 : 0;
                    return (
                      "<b>" +
                      this.x +
                      "</b><br />" +
                      this.series.name +
                      ": <b>" +
                      window.currencySymbol +
                      Highcharts.numberFormat(this.y, round_off, ".", ",") +
                      "</b><br/>"
                    );
                  }

                  if (
                    dn == this.series.name &&
                    df == "number" &&
                    this.series.name == "Conversions"
                  ) {
                    return (
                      "<b>" +
                      this.x +
                      "</b><br />" +
                      this.series.name +
                      ": <b>" +
                      Highcharts.numberFormat(this.y, 0, ".", ",") +
                      "</b><br/>"
                    );
                  }

                  if (
                    dn == this.series.name &&
                    df == "number" &&
                    this.series.name == "Average Rating"
                  ) {
                    return this.series.name + ": <b>" + this.y + "</b><br/>";
                  }

                  if (dn == this.series.name && df == "number") {
                    return (
                      this.series.name +
                      ": <b>" +
                      Highcharts.numberFormat(this.y, 0, ".", ",") +
                      "</b><br/>"
                    );
                  }

                  if (
                    dn == this.series.name &&
                    this.series.name == "ROAS" &&
                    df == "percentage"
                  ) {
                    return (
                      this.series.name +
                      ": <b>" +
                      Highcharts.numberFormat(this.y, 0, ".", ",") +
                      "%</b><br/>"
                    );
                  }

                  if (dn == this.series.name && df == "percentage") {
                    var round_off = ["CTR", "Completion Rate"].includes(
                      this.series.name
                    )
                      ? 2
                      : 0;
                    return (
                      "<b>" +
                      this.x +
                      "</b><br />" +
                      this.series.name +
                      ": <b>" +
                      Highcharts.numberFormat(this.y, round_off, ".", ",") +
                      "%</b><br/>"
                    );
                  }
                }
              },
            },
            xAxis: {
              categories: xAxisData,
            },
            yAxis: [
              {
                title: {
                  text: "",
                },
              },
              {
                title: {
                  text: "",
                },
                opposite: true,
                labels: {
                  formatter: function () {
                    return (
                      Highcharts.numberFormat(this.value, 2, ".", ",") + "%"
                    );
                  },
                },
              },
            ],
            series: seriesData,
          };

          return (
            <>
              <Col key={index} xs={24} sm={24} md={8} lg={8} xl={8}>
                <Spin spinning={isLoading}>
                  <div className="trends-breakdown-single">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  </div>
                </Spin>
              </Col>
            </>
          );
        })}
      </Row>
    </>
  );
};

export default ColumnLineChart;
