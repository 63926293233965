import { listOfAllDataSources } from "../../Config/datasources/allDatasources";
import { dataSourceFunction } from "../../Utils/datasources/datasources_info/dataSourceFunction";
import { Token } from "../../Utils/helpers/token";
import errorHandler from "../../Utils/Request/errorHandler";

// *This function is used to deserialize the token and json from the data_source_information from localStorage.
/**
 * This function deserializes and returns data source information from local storage, throwing an error
 * if it is not found or cannot be parsed.
 * @param history - The `history` parameter is an object that represents the browser's session history.
 * It is typically used in React applications with the `react-router-dom` library to navigate between
 * different pages or routes.
 * @returns the parsed data source object if it exists and is valid, and redirects to the "/accounts"
 * page and throws an error if it does not exist or is invalid. If there is an error during the parsing
 * process, it removes the data source information from local storage and redirects to the "/accounts"
 * page.
 */
export const deserializeObject = (history) => {
  const hashedDataSourceData = localStorage.getItem("data_source_information");
  if (!hashedDataSourceData) {
    history.push("/accounts");
    throw new Error("No data source information found");
  }
  try {
    const dataSourceData = Token.decryptAuthParams(hashedDataSourceData);
    const parsedDataSource = JSON.parse(dataSourceData);

    if (!dataSourceData || typeof parsedDataSource !== "object") {
      history.push("/accounts");
    }
    return parsedDataSource;
  } catch (error) {
    localStorage.removeItem("data_source_information");
    history.push("/accounts");
  }
};

// *This function is used to call the api for tokens and profile
/**
 * This function calls a third-party API and retrieves credentials for a given data source, storing
 * them in local storage.
 * @param parsedDataSource - An object containing metadata and data related to a data source. It is
 * parsed from a URL query parameter and is used to call a third-party API to retrieve credentials for
 * accessing the data source.
 * @param currentDataSource - The current data source being used by the user.
 * @param history - The `history` parameter is an object that is used for navigation in React
 * applications. It keeps track of the user's navigation history and allows the application to
 * programmatically navigate to different pages or URLs. It is typically provided by the
 * `react-router-dom` library.
 * @returns The function `callThirdPartyApi` returns a Promise that resolves to the `parsedDataSource`
 * object after making an API call to a third-party data source and updating the
 * `dataSourceDataFromThirdParty` property of the `parsedDataSource` object with the credentials
 * obtained from the API call. If there is an error during the API call, the function returns a
 * rejected Promise and removes the `data_source
 */
export const callThirdPartyApi = async (
  parsedDataSource,
  currentDataSource,
  history
) => {
  const {
    dataSourceMetaData: { dataSourceName, dataSourceRegion, dataSourceUrl },
  } = parsedDataSource;
  // Now call the api for tokens and all that stuff .
  try {
    const dataToPassForApi = {};

    // *If the current datasources is amazon sponsor ads then we need to pass the code and region to the api.
    if (
      (dataSourceName === listOfAllDataSources["amazonSponsorAds"] ||
        dataSourceName === listOfAllDataSources["amazonMarketingService"]) &&
      dataSourceRegion
    ) {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        dataToPassForApi["code"] = code;
      }
      dataToPassForApi["region"] = dataSourceRegion;
    }

    // This is for tiktok
    const servicesThatTakesOnlyCode = [
      listOfAllDataSources["tiktok"],
      listOfAllDataSources["facebook"],
    ];

    if (servicesThatTakesOnlyCode.includes(dataSourceName)) {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        dataToPassForApi["code"] = code;
      }
    }

    // *Getting the corresponding function for the current datasource
    const dataSourceFn = dataSourceFunction[currentDataSource];

    if (!dataSourceFn || typeof dataSourceFn !== "function") {
      errorHandler({
        response: {
          data: {
            message:
              "DataSourceFn must be function else Something went wrong .",
          },
          status: 400,
        },
      });
      history.push(dataSourceUrl);
      return;
    }
    // *Calling the Api to get the user profile or user credentials for validation and get the valid data;
    const credentials = await dataSourceFn(dataToPassForApi);

    // *Fresh credentials returns a user credentials returns from amazon sponsor ads api.
    parsedDataSource["dataSourceDataFromThirdParty"] = credentials;

    // *Storing the data in local storage for future use.
    localStorage.setItem(
      "data_source_information",
      JSON.stringify(parsedDataSource)
    );

    return parsedDataSource;
  } catch (error) {
    errorHandler({
      response: {
        data: {
          message: error.message,
        },
        status: 400,
      },
    });
    localStorage.removeItem("data_source_information");
    history.push(dataSourceUrl);
  }
};
