export function formatColumns(text, record, goal_data, dataIndex) {
  if (goal_data.hasOwnProperty("acos_goal")) {
    var acos_goal = goal_data.acos_goal;
  }

  if (goal_data.hasOwnProperty("cvr_avg")) {
    var cvr_avg = goal_data.cvr_avg;
  }

  if (goal_data.hasOwnProperty("revenue_goal")) {
    var revenue_goal = goal_data.revenue_goal;
  }

  if (goal_data.hasOwnProperty("cpc_avg")) {
    var cpc_avg = goal_data.cpc_avg;
  }

  if (goal_data.hasOwnProperty("roas_goal")) {
    var roas_goal = goal_data.roas_goal;
  }

  if (goal_data.hasOwnProperty("analytics_cvr_avg")) {
    var analytics_cvr_avg = goal_data.analytics_cvr_avg;
  }

  if (goal_data.hasOwnProperty("analytics_revenue_avg")) {
    var analytics_revenue_avg = goal_data.analytics_revenue_avg;
  }

  if (goal_data.hasOwnProperty("analytics_sessions_avg")) {
    var analytics_sessions_avg = goal_data.analytics_sessions_avg;
  }

  //roas
  if (dataIndex == "adwords_roas") {
    if (roas_goal > 0) {
      if (record["adwords_roas"] > roas_goal * 1.5) {
        return "green-column"; //green_cell_fill
      } else if (
        record["adwords_roas"] <= roas_goal * 1.5 &&
        record["adwords_roas"] > roas_goal
      ) {
        return "light-green-column"; //light_green_cell_fill
      } else if (
        record["adwords_roas"] <= roas_goal &&
        record["adwords_roas"] > roas_goal * 0.5
      ) {
        return "light-yellow-column"; //light_yellow_cell_fill
      } else if (
        record["adwords_roas"] <= roas_goal * 0.5 &&
        record["adwords_roas"] > 0
      ) {
        return "light-orange-column"; //light_orange_cell_fill
      } else {
        return "light-red-column"; //light_red_cell_fill
      }
    }
  }

  // var cvr_avg = 200;
  //adwords_cvr
  if (dataIndex == "adwords_cvr") {
    if (record["adwords_cvr"] > cvr_avg * 1.5) {
      return "green-column"; // /green_cell_fill
    } else if (
      record["adwords_cvr"] <= cvr_avg * 1.5 &&
      record["adwords_cvr"] > cvr_avg
    ) {
      return "light-green-column"; //light_green_cell_fill
    } else if (
      record["adwords_cvr"] <= cvr_avg &&
      record["adwords_cvr"] > cvr_avg * 0.5
    ) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      record["adwords_cvr"] <= cvr_avg * 0.5 &&
      record["adwords_cvr"] >= 0
    ) {
      return "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  // var cpc_avg = 100;
  //cpc
  if (dataIndex == "adwords_cpc") {
    if (record["adwords_cpc"] > cpc_avg * 1.5) {
      return "light-red-column"; //light_red_cell_fill
    } else if (
      record["adwords_cpc"] <= cpc_avg * 1.5 &&
      record["adwords_cpc"] > cpc_avg
    ) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      record["adwords_cpc"] <= cpc_avg &&
      record["adwords_cpc"] > cpc_avg * 0.5
    ) {
      return "light-green-column"; //light_green_cell_fill
    } else if (
      record["adwords_cpc"] <= cpc_avg * 0.5 &&
      record["adwords_cpc"] > 0
    ) {
      return "green-column"; //green_cell_fill
    } else {
      //nothing
    }
  }

  //analytics cvr
  if (dataIndex == "analytics_cvr") {
    if (record["analytics_cvr"] > analytics_cvr_avg * 1.5) {
      return "green-column"; //green_cell_fill
    } else if (
      record["analytics_cvr"] <= analytics_cvr_avg * 1.5 &&
      record["analytics_cvr"] > analytics_cvr_avg
    ) {
      return "light-green-column"; //light_green_cell_fill
    } else if (
      record["analytics_cvr"] <= analytics_cvr_avg &&
      record["analytics_cvr"] > analytics_cvr_avg * 0.5
    ) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      record["analytics_cvr"] <= analytics_cvr_avg * 0.5 &&
      record["analytics_cvr"] >= 0
    ) {
      return "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  //analaytics revenue
  if (dataIndex == "analytics_revenue") {
    if (record["analytics_revenue"] > analytics_revenue_avg * 1.5) {
      return "green-column"; //green_cell_fill
    } else if (
      record["analytics_revenue"] <= analytics_revenue_avg * 1.5 &&
      record["analytics_revenue"] > analytics_revenue_avg
    ) {
      return "light-green-column"; //light_green_cell_fill
    } else if (
      record["analytics_revenue"] <= analytics_revenue_avg &&
      record["analytics_revenue"] > analytics_revenue_avg * 0.5
    ) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      record["analytics_revenue"] <= analytics_revenue_avg * 0.5 &&
      record["analytics_revenue"] >= 0
    ) {
      return "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  //analaytics sessions
  if (dataIndex == "analytics_sessions") {
    if (record["analytics_sessions"] > analytics_sessions_avg * 1.5) {
      return "green-column"; //green_cell_fill
    } else if (
      record["analytics_sessions"] <= analytics_sessions_avg * 1.5 &&
      record["analytics_sessions"] > analytics_sessions_avg
    ) {
      return "light-green-column"; //light_green_cell_fill
    } else if (
      record["analytics_sessions"] <= analytics_sessions_avg &&
      record["analytics_sessions"] > analytics_sessions_avg * 0.5
    ) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      record["analytics_sessions"] <= analytics_sessions_avg * 0.5 &&
      record["analytics_sessions"] >= 0
    ) {
      return "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  //acos_goal
  // var acos_goal = 150;
  if (dataIndex == "acos" && acos_goal != undefined) {
    if (acos_goal == "N/A" || acos_goal == "0") {
      //No color coding if acos goal is zero
    } else if (
      parseFloat(record["acos"]) <= acos_goal * 0.6667 &&
      record["acos"] > 0
    ) {
      return "green-column"; //green_cell_fill
    } else if (
      parseFloat(record["acos"]) > acos_goal * 0.6667 &&
      parseFloat(record["acos"]) <= acos_goal
    ) {
      return "light-green-column"; //light_green_cell_fill
    } else if (
      parseFloat(record["acos"]) < acos_goal * 2 &&
      parseFloat(record["acos"]) > acos_goal
    ) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (parseFloat(record["acos"]) >= acos_goal * 2) {
      return "light-orange-column"; //light_orange_cell_fill
    } else if (record["clicks"] < 10 && record["conversions"] == 0) {
      return "light-red-column"; //light_red_cell_fill
    } else if (record["clicks"] >= 10 && record["conversions"] == 0) {
      return "light-red-column"; //light_red_cell_fill
    }
  }

  //cvr
  if (dataIndex == "roas") {
    if (roas_goal == "N/A" || roas_goal == 0 || roas_goal == undefined) {
      //No color if roas goal is zero
    } else if (record["roas"] >= roas_goal * 1.5) {
      return "green-column"; // /green_cell_fill
    } else if (
      record["roas"] < roas_goal * 1.5 &&
      record["roas"] >= roas_goal
    ) {
      return "light-green-column"; //light_green_cell_fill
    } else if (record["roas"] < roas_goal && record["roas"] > roas_goal * 0.5) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (record["roas"] <= roas_goal * 0.5 && record["roas"] > 0) {
      return "light-orange-column"; //light_orange_cell_fill
    } else {
      return "light-red-column"; //light_red_cell_fill
    }
  }

  // var cvr_avg = 2.3;
  //cvr
  if (dataIndex == "cvr") {
    if (record["cvr"] > cvr_avg * 1.5) {
      return "green-column"; // /green_cell_fill
    } else if (record["cvr"] <= cvr_avg * 1.5 && record["cvr"] > cvr_avg) {
      return "light-green-column"; //light_green_cell_fill
    } else if (record["cvr"] <= cvr_avg && record["cvr"] > cvr_avg * 0.5) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (record["cvr"] <= cvr_avg * 0.5 && record["cvr"] >= 0) {
      return "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  // var cpc_avg = 3.5;
  //cpc
  if (dataIndex == "cpc") {
    if (record["cpc"] > cpc_avg * 1.5) {
      return "red-column"; //light_red_cell_fill
    } else if (record["cpc"] <= cpc_avg * 1.5 && record["cpc"] > cpc_avg) {
      return "light-yellow-column"; //light_yellow_cell_fill
    } else if (record["cpc"] <= cpc_avg && record["cpc"] > cpc_avg * 0.5) {
      return "light-green-column"; //light_green_cell_fill
    } else if (record["cpc"] <= cpc_avg * 0.5 && record["cpc"] > 0) {
      return "green-column"; //green_cell_fill
    } else {
      //nothing
    }
  }
}
