import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Layout,
  Col,
  Row,
  Dropdown,
  Drawer,
  Select,
  Button,
  Badge,
  Tooltip,
} from "antd";
import { AppstoreOutlined } from "@ant-design/icons";
import Feedback from "../../Components/Header/Feedback";
import Profile from "../../Components/Header/Profile";
import EndFooter from "./Footer";
import logo from "../../Assets/Images/inform.png";
import LoggedInRoute from "../../Routes/LoggedInRoute";
import { useDispatch, useSelector } from "react-redux";
import decode from "jwt-decode";
import { MFA_SET } from "../../Redux/Actions/types";
import {
  checkDBMFAToken,
  stopImpersonate,
} from "../../Redux/Actions/Thunk/loginThunk";
import { getLocationData, getFingerPrint } from "../../Utils";
import TokenPage from "../Auth/TokenPage";
import { getAccountAndReports } from "../../Redux/Actions/Thunk/reportLayoutThunk";
import Config from "../../Config";
import inform from "../../Assets/Images/inform.png";
import compare from "../../Assets/Images/compare.png";
import visualize from "../../Assets/Images/visualize.png";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Token } from "../../Utils/helpers/token";
import { isCustomer, isNonManagedCustomer } from "../../Utils/user";

const { Header, Content, Footer, Sider } = Layout;

function Dashboard() {
  return <div>Welcome to Dashboard</div>;
}

function MainLayout(props) {
  const [state, setState] = useState({
    account: "",
    account_reports: "",
  });
  const [accountId, setAccountId] = useState("");
  const [reportName, setReportName] = useState("");
  const [open, setOpen] = useState(false);
  const [impersonated, setImpersonated] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const {
    API: { baseURL },
    ADMIN: { baseURL: data_source_url },
    API_BI: { SSO_VISUALIZE_URL },
  } = Config;
  const pathArray = (window?.location?.pathname || "").split("/");
  var report_name = pathArray[4] || null;
  var account_id = pathArray[5] || null;

  const { Option } = Select;

  const {
    login: { impersonateUser: impersonateUser = false },
    reportLayouts: {
      account_reports_data: {
        reports: reportData = [],
        accounts: accountsData = [],
        // input_controls: inputControlsData = [],
      } = {},
    } = {},
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      getAccountAndReports({ account_id: account_id, report_key: report_name })
    );
  }, []);
  const notifications = (
    <div className="header-notifications-wrapper">
      <p>No Notifications</p>
    </div>
  );
  const HeaderLinks = (
    <div className="header-user-details">
      <a href="#" target="_blank">
        <img src={inform} alt="short log" />
        Exclusive Price Intelligence
      </a>
    </div>
  );

  let mfaVerified = useSelector((state) => state.login.mfaVerified);
  const dispatch = useDispatch();

  let currencies = useSelector(
    (state) => state.accounts?.client_id?.currencies
  );
  let service_id = useSelector(
    (state) => state.reportLayouts?.account_reports_data?.service_id
  );

  var currencySymbol = "$";
  if (currencies && currencies.hasOwnProperty(service_id)) {
    currencySymbol = currencies[service_id];
  }
  window.currencySymbol = currencySymbol;
  window.currencies = currencies;

  let mfa_checked = false;

  const checkLocalMFAToken = async () => {
    //return true; // temporarily disabled MFA
    const mfa_token = localStorage.getItem("mfa_token");
    const impersonate = localStorage.getItem("impersonate");
    //if impersonate mode discard MFA
    if (impersonate != null) {
      return true;
    }
    if (mfa_token) {
      //mfa token exists
      const decodedMFAToken = decode(mfa_token);
      const decodedUserToken = decode(localStorage.getItem("token"));
      let current_user = decodedUserToken.user_id;
      //if current user mfa is verified
      if (decodedMFAToken.user_id.includes(current_user)) {
        //user verified in mfa token
        if (decodedUserToken.exp * 1000 < new Date().getTime()) {
          //mfa token expired
          return false;
        } else {
          //mfa token not expired
          return true;
        }
      } else {
        //mfa token user not verified
        return false;
      }
    } else {
      //mfa token doesn't exists
      return false;
    }
  };

  const account = accountsData.find(
    (account) => account.account_id === account_id
  );

  if (account) {
    var account_name = account.account_name;
  } else {
    //
  }

  useEffect(() => {
    checkLocalMFAToken().then(async function (response) {
      if (response) {
        dispatch({
          type: MFA_SET,
        });
        mfa_checked = true;
      } else {
        const user = JSON.parse(localStorage.getItem("user"));
        let user_data = {
          user_id: user.user_id,
          // ip_address: await getLocationData(),
          ip_address: "",
          fingerprint: await getFingerPrint(),
        };
        dispatch(checkDBMFAToken(user_data));
        mfa_checked = true;
      }
    });
  }, []);

  useEffect(() => {
    if (impersonateUser || localStorage.getItem("impersonate") != null) {
      setImpersonated(true);
      window.open("/reports");
    }
  }, [impersonateUser]);

  const loadAccountReport = (val, evt) => {
    const value = val;
    setState({
      ...state,
      [evt]: value,
    });
    var result = { ...state, [evt]: value };
    var account = result.account;
    var report_name = result.account_reports;
    const pathArray = window?.location?.pathname?.split("/");

    if (report_name == "") {
      var report_name = pathArray[4];
    }

    if (account == "") {
      var account = pathArray[5];
    }

    if (report_name == "monthly_email_report") {
      window.open(
        "/reports/v2/views" + "/" + report_name + "/" + account,
        "_self"
      );
    } else {
      window.open(
        "/reports/v2/view" + "/" + report_name + "/" + account,
        "_self"
      );
    }
  };

  const loadReport = (val) => {
    const report_name = val;
    window.open("/reports/v2/view" + "/" + report_name, "_self");
  };
  const stopImpersonation = () => {
    localStorage.setItem("token", localStorage.getItem("impersonate"));
    localStorage.setItem("user", localStorage.getItem("impersonate_user"));
    localStorage.removeItem("impersonate");
    localStorage.removeItem("impersonate_user");
    dispatch(stopImpersonate());
    setImpersonated(false);
    window.open("/");
  };

  const impersonateVal = localStorage.getItem("impersonate");

  const excludedReports = [
    "organic_search_scorecard_v2",
    "google_ads_scorecard",
  ];
  //for default selected account
  const selectedAccount = accountsData.find(
    (data) => data?.account_id === account_id
  );
  const selectedReport = reportData.find(
    (data) => data?.report_key === report_name
  );

  if (mfaVerified) {
    return (
      <Layout>
        <Header>
          <Row className="ant-header-row">
            <Col lg={20} xs={24} className="header-left">
              <div className="header-logo">
                <img src={logo} alt="Logo" />
                <h2>INFORM</h2>
              </div>
              {account_id != undefined &&
              report_name != undefined &&
              !excludedReports.includes(report_name) ? (
                <>
                  <div className="header-dropdown">
                    {!(isCustomer() || isNonManagedCustomer()) ? (
                      <Select
                        dropdownClassName="header-dropdown-menu"
                        dropdownMatchSelectWidth={false}
                        name="account"
                        showSearch
                        mode="single"
                        maxTagCount={1}
                        placeholder="Account Name"
                        optionFilterProp="children"
                        onChange={(e) => loadAccountReport(e, "account")}
                        value={selectedAccount?.account_id || undefined} // defaultValue={selectedAccount?.account_name || undefined}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {accountsData &&
                          accountsData.length > 0 &&
                          accountsData?.map((data) => (
                            <Option
                              key={data?.account_id}
                              value={data?.account_id}
                            >
                              {data?.account_name}
                            </Option>
                          ))}
                      </Select>
                    ) : (
                      ""
                    )}

                    <Select
                      dropdownClassName="header-dropdown-menu"
                      dropdownMatchSelectWidth={false}
                      name="account_reports"
                      showSearch
                      mode="single"
                      maxTagCount={1}
                      placeholder="Report Name"
                      optionFilterProp="children"
                      onChange={(e) => loadAccountReport(e, "account_reports")}
                      value={selectedReport?.report_key || undefined}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {reportData &&
                        reportData.length > 0 &&
                        reportData?.map((data) => (
                          <Option
                            value={data?.report_key}
                            key={data?.report_key}
                          >
                            {data?.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </>
              ) : (
                ""
              )}

              {report_name != undefined &&
              account_id == undefined &&
              report_name.length > 4 ? (
                <div className="header-dropdown">
                  <Select
                    dropdownClassName="header-dropdown-menu"
                    dropdownMatchSelectWidth={false}
                    name="account_reports"
                    style={{ minWidth: 230 }}
                    showSearch
                    mode="single"
                    maxTagCount={1}
                    placeholder="Report Name"
                    optionFilterProp="children"
                    onChange={(e) => loadReport(e, "account_reports")}
                    value={selectedReport?.report_key || undefined}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {reportData &&
                      reportData != undefined &&
                      reportData.length > 0 &&
                      reportData?.map((data) => (
                        <Option value={data.report_key} key={data.report_key}>
                          {data?.name}
                        </Option>
                      ))}
                  </Select>
                </div>
              ) : (
                ""
              )}
            </Col>
            <div
              className="header-impersonate"
              style={{ display: impersonated ? "block" : "none" }}
            >
              <Button
                onClick={() => {
                  stopImpersonation();
                }}
              >
                Stop Impersonate Mode
              </Button>
            </div>
            <Col lg={4} xs={24} className="header-right">
              <ul>
                <li className="header-notification">
                  <Tooltip placement="bottom" title="0 New Notifications">
                    <Dropdown overlay={notifications} trigger={["click"]} arrow>
                      <Badge count={0} overflowCount={10} size="small">
                        <a onClick={(e) => e.preventDefault()}>
                          <IoMdNotificationsOutline />
                        </a>
                      </Badge>
                    </Dropdown>
                  </Tooltip>
                </li>
                <li className="header-feeback">
                  <Feedback />
                </li>
                {/* <li className="header-links">
                  <Dropdown overlay={HeaderLinks} trigger={["click"]} arrow>
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <AppstoreOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </li> */}
                <li className="header-drawer">
                  <Tooltip placement="bottom" title="More Apps">
                    <Button onClick={showDrawer}>
                      <AppstoreOutlined />
                    </Button>
                  </Tooltip>
                </li>
                <li className="header-profile">
                  <Profile />
                </li>
                <Drawer
                  className="header-apps-drawer"
                  title="Apps"
                  placement="right"
                  onClose={onClose}
                  visible={open}
                >
                  <ul>
                    {impersonateVal == null || impersonateVal == undefined ? (
                      <li>
                        <a
                          href="https://impact-compare.agital.com/"
                          target="_blank"
                        >
                          <img src={compare} alt="short logo" />
                          <span>Compare</span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}

                    <li>
                      <a
                        href={`${data_source_url}?token=${
                          localStorage.getItem("token") &&
                          Token.encryptAuthParams(localStorage.getItem("token"))
                        }`}
                        target="_blank"
                      >
                        <img src={inform} alt="datasources" />
                        <span>Admin</span>
                      </a>
                    </li>
                    <li>
                      <a href={SSO_VISUALIZE_URL} target="_blank">
                        <img src={visualize} alt="Visulaize" />
                        <span>Visualize</span>
                      </a>
                    </li>
                  </ul>
                </Drawer>
              </ul>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            minHeight: 280,
          }}
        >
          <LoggedInRoute />
        </Content>
        <EndFooter></EndFooter>
      </Layout>
    );
  } else {
    if (mfaVerified == undefined) {
      return <></>;
    } else {
      return <TokenPage />;
    }
  }
}

export default MainLayout;
