export const initialState = {
  trend: {},
  labels: [],
  isLoading: false,
  trendBreakDown: [],
  devicePerformance: {},
};

export const ACTIONS_TYPES = Object.freeze({
  REQUEST_TREND_DATA: "REQUEST_TREND_DATA",
  GET_TRENDS: "GET_TRENDS",
  FETCH_TRENDS: "FETCH_TRENDS",
  FETCH_TREND_BREAKDOWN: "FETCH_TREND_BREAKDOWN",
  FETCH_TREND_BREAKDOWN_REQUEST: "FETCH_TREND_BREAKDOWN_REQUEST",
  FETCH_DEVICE_REQUEST: "FETCH_DEVICE_REQUEST",
  RESET_TREND: "RESET_TREND",
});

export function trendReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.REQUEST_TREND_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.RESET_TREND:
      return {
        trend: {},
        labels: [],
        isLoading: false,
        trendBreakDown: [],
        devicePerformance: {},
      };
    case ACTIONS_TYPES.FETCH_TRENDS:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.GET_TRENDS:
      return {
        ...state,
        isLoading: false,
        trend: { ...state.trend, ...action.payload },
      };
    case ACTIONS_TYPES.FETCH_TREND_BREAKDOWN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_TREND_BREAKDOWN:
      return {
        ...state,
        isLoading: false,
        trendBreakDown: { ...state.trendBreakDown, ...action.payload },
      };
    case ACTIONS_TYPES.FETCH_DEVICE_REQUEST:
      return {
        ...state,
        isLoading: false,
        devicePerformance: { ...state.devicePerformance, ...action.payload },
      };
    default:
      return state;
  }
}
