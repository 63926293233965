import React, { useEffect, useState } from "react";
import { BiTrendingUp, BiTrendingDown } from "react-icons/bi";
import { getKeyMetrics } from "../../Redux/Actions/Thunk/reports";
import { useDispatch, useSelector } from "react-redux";
import { formDataAppend } from "../../Utils/helpers";
import HtmlParser from "html-react-parser";
import { Spin, Tooltip } from "antd";
import { FaInfoCircle } from "react-icons/fa";

function KeyMetrics(props) {
  const {
    keyMetrics: {
      key_metrics: {
        [props?.block?.block_id]: {
          [props?.block?.block_id]: {
            data: KeyMetricsData = [],
            labels,
            insights,
            ga_insights_data: gaInsightsTopRightData = [],
            last_order_date: clvLastOrderData = [],
          } = {},
          isLoading,
        } = {},
      } = {},
    } = {},
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  useEffect(() => {
    const panelHeadingElement = document.querySelector("#divToPrint");
    if (panelHeadingElement !== null && gaInsightsTopRightData.length > 0) {
      let insightsExists = panelHeadingElement.querySelector(
        ".top-right-insights"
      );
      if (!insightsExists) {
        const newDiv = document.createElement("div");
        newDiv.innerHTML = gaInsightsTopRightData;
        if (props?.block?.block_key === "srp_holistic_key_metrics") {
          newDiv.className = "top-right-insights--holistic";
        } else {
          newDiv.className = "top-right-insights";
        }
        panelHeadingElement.insertBefore(
          newDiv,
          panelHeadingElement.firstChild
        );
      } else {
        insightsExists.innerHTML = gaInsightsTopRightData;
      }
    }
  }, [gaInsightsTopRightData]);

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getKeyMetrics(formData, props));
  }, []);

  const results = [];
  if (labels != undefined && KeyMetricsData != 0) {
    labels.forEach((values, index) => {
      var previousValue = KeyMetricsData[0]["previous_" + values.key];
      var currentValue = KeyMetricsData[0][values.key];
      var changedValue = calculateChangePercentage(currentValue, previousValue);
      var logicType = null;
      var reportKey = props?.block?.report_key;
      var keyMetricsCircle = checkLogicType(
        values.key,
        currentValue,
        logicType,
        reportKey,
        changedValue,
        KeyMetricsData[0]
      );

      var getChangeIconClass = getChangeIconClassByGoalRange(
        changedValue,
        currentValue,
        values.key,
        0,
        0,
        false
      );

      var text = "key-metrics-circle";

      if (keyMetricsCircle != null) {
        var keyMetricsClass = text + " " + keyMetricsCircle;
      }

      results.push(
        <div className={keyMetricsClass}>
          <h5>
            {values.value}
            {values.key === "asin_without_rating" ? (
              <Tooltip
                title="ASINs that come back as unavailable and are not scrapable are not counted"
                overlayClassName="heading-tooltip"
              >
                <span>
                  <FaInfoCircle color="#0e0e0b" />
                </span>
              </Tooltip>
            ) : values.key == "cpa" &&
              reportKey == "customer_lifetime_value" ? (
              <Tooltip
                title="Cost Per Acquisition equals total spend on non-remarketing ads divided by the number of net new customers"
                overlayClassName="heading-tooltip"
              >
                <span>
                  <FaInfoCircle color="#0e0e0b" />
                </span>
              </Tooltip>
            ) : values.key === "cpl" ? (
              <Tooltip
                title="Cost Per Lead (CPL) is the sum of total spends divided by the sum of conversions"
                overlayClassName="heading-tooltip"
              >
                <span>
                  <FaInfoCircle color="#8dc441" />
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </h5>
          <h2>
            {formatData(
              values.format,
              KeyMetricsData[0][values.key],
              values.key
            )}
          </h2>
          <h6 className={getChangeIconClass}>
            {changedValue == "-" ? "" : checkIconType(changedValue)}
            <span>
              {changedValue == "-"
                ? "-"
                : numberWithCommas(parseFloat(changedValue).toFixed(2)) + "%"}
            </span>
          </h6>
        </div>
      );
    });
  } else if (labels != undefined) {
    labels.forEach((values, index) => {
      var changedValue = "-";
      var logicType = null;
      var reportKey = null;
      var keyMetricsCircle = checkLogicType(
        values.key,
        0,
        logicType,
        reportKey,
        0,
        KeyMetricsData
      );

      var text = "key-metrics-circle";

      if (keyMetricsCircle != null) {
        var keyMetricsClass = text + " " + keyMetricsCircle;
      }

      results.push(
        <div className={keyMetricsClass}>
          <h5>{values.value}</h5>
          <h2>{formatData(values.format, 0, values.key)}</h2>
          <h6>{"-"}</h6>
        </div>
      );
    });
  }

  if (insights != undefined && insights.length > 0) {
    var listItems = insights.map((insight) => <li>{HtmlParser(insight)}</li>);
  }

  return (
    <>
      <Spin spinning={isLoading}>
        {/* <div>
          <div dangerouslySetInnerHTML={{ __html: prependedText }}></div>
        </div> */}
        <div className="key-metrics-wrapper">{results}</div>

        <div className="report-v2-panel-footer">
          {insights != undefined && insights.length > 0 ? (
            <div className="insights">
              <h4>Insights</h4>
              <ul>{listItems}</ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </Spin>
    </>
  );
}

function formatData(format, data, key) {
  var metrics = [
    "aov",
    "cpc",
    "cpm",
    "clv",
    "cpa",
    "cpl",
    "cost_per_lead",
    "cpc_cta_spend",
    "spend",
  ];

  if (key == "clv" && data == 0) {
    return "-";
  }
  if (metrics.includes(key)) {
    return (
      window.currencySymbol + numberWithCommas(parseFloat(data).toFixed(2))
    );
  } else if (format == "amount") {
    return window.currencySymbol + numberWithCommas(Math.round(data));
  } else if (format == "percentage" && key == "roas") {
    return numberWithCommas(Math.round(data)) + "%";
  } else if (format == "percentage") {
    return numberWithCommas(parseFloat(data).toFixed(2)) + "%";
  } else {
    return numberWithCommas(Math.round(data));
  }
}

const pathArray = window?.location?.pathname?.split("/");
var report_name = pathArray[4];

function getKeyMetricsClass(number, metrics, keyMetricsData) {
  var costKeys = ["cost", "ad_cost", "spend", "ad_spend"];
  if (keyMetricsData?.length == 0) {
    return "kmc-light-green";
  } else if (!costKeys.includes(metrics) && keyMetricsData[metrics] == null) {
    return "kmc-red";
  }
  var inverseMetrics = [
    "cpc",
    "cpm",
    "cost_per_lead",
    "cpa",
    "bounce_rate",
    "acos",
    "asin_without_rating",
    "cpc_cta_spend",
    "unsubscribe_rate",
  ];

  if (costKeys.includes(metrics)) {
    return "kmc-blue";
  } else if (!inverseMetrics.includes(metrics)) {
    if (metrics == "roas" && report_name == "paid_product_ads_performance") {
      // For ROAS of combined report like PLA_PPC
      return "kmc-red";
    } else {
      if (metrics == "revenue") {
        var minGoal =
          typeof keyMetricsData["revenue_goal"] != undefined
            ? keyMetricsData["revenue_goal"]
            : 0;
        var tarGoal =
          typeof keyMetricsData["revenue_target_goal"] != undefined
            ? keyMetricsData["revenue_target_goal"]
            : 0;
        if (minGoal > 0 && tarGoal > 0) {
          var goal_value = (minGoal + tarGoal) / 2;
        } else if (minGoal > 0 || tarGoal > 0) {
          var goal_value = Math.max(minGoal, tarGoal);
        } else {
          var goal_value = 0;
        }
      }

      number = parseFloat(number);
      if (metrics == "revenue" && goal_value > 0) {
        return "kmc-green";
      } else if (number >= 25) {
        return "kmc-green";
      } else if (number >= 0 && number < 25) {
        return "kmc-light-green";
      } else if (number >= -10 && number < 0) {
        return "kmc-light-green";
      } else if (number >= -25 && number < -10) {
        return "kmc-yellow";
      } else if (number >= -50 && number < -25) {
        return "kmc-orange";
      } else if (number < -50) {
        return "kmc-red";
      } else if (number >= 125) {
        return "kmc-dark-green";
      } else if (number >= 90 && number < 125) {
        return "kmc-light-green";
      } else if (number >= 75 && number < 90) {
        return "kmc-yellow";
      } else if (number >= 50 && number < 75) {
        return "kmc-orange";
      }
    }
  } else {
    //Inverse Metrics Logic
    if (number >= 50) {
      return "kmc-red";
    } else if (number >= 25 && number < 50) {
      return "kmc-orange";
    } else if (number >= 10 && number < 25) {
      return "kmc-yellow";
    } else if (number >= 0 && number < 10) {
      return "kmc-light-green";
    } else if (number >= -25 && number < 0) {
      return "kmc-light-green";
    } else if (number < -25) {
      return "kmc-green";
    } else if (number < 75) {
      return "kmc-dark-green";
    } else if (number >= 75 && number < 110) {
      return "kmc-light-green";
    } else if (number >= 110 && number < 125) {
      return "kmc-yellow";
    } else if (number >= 125 && number < 150) {
      return "kmc-orange";
    } else if (number >= 150) {
      return "kmc-red";
    }
  }
}

function checkIconType(value) {
  value = parseFloat(value);
  if (value === "-") {
    return <BiTrendingDown />;
  }
  // var value = Number(value.replace(/[,\$\%.]/g, ""));
  if (value > 0) {
    return <BiTrendingUp />;
  } else {
    return <BiTrendingDown />;
  }
}

function getChangeIconClassByGoalRange(
  value,
  amount,
  metric,
  min_goal_val = 0,
  target_goal_val = 0,
  arrowClass = false,
  KeyMetricsData
) {
  value = parseFloat(value);

  if (min_goal_val == 0 && target_goal_val == 0) {
    switch (metric) {
      case "cost":
      case "ad_cost":
      case "spend":
        return "blue-graph";
        break;

      //Inverse Metrics logic
      case "cpc":
      case "cost_per_conversion":
      case "cpm":
      case "cpa":
      case "bounce_rate":
      case "cost_per_lead":
      case "acos":
      case "cost_per_purchase":
      case "cost_per_total_conv":
      case "acots":
      case "tacos":
      case "cpc_cta_spend":
      case "unsubscribe_rate":
        if (value < -25) {
          //Dark Green
          return "green-graph";
        } else if (value >= -25 && value < 10) {
          //Light Green
          return "light-green-graph";
        } else if (value >= 25 && value < 50) {
          //yellow
          return "orange-graph";
        } else if (value >= 10 && value < 33) {
          //Blue
          return "yellow-graph";
        } else if (value < 100) {
          //Red
          return "red-graph";
        } else if (value < 75) {
          return "dark-green-graph";
        } else if (value >= 75 && value < 110) {
          return "light-green-graph";
        } else if (value >= 110 && value < 125) {
          return "yellow-graph";
        } else if (value >= 125 && value < 150) {
          return "orange-graph";
        } else if (value >= 150) {
          return "red-graph";
        }
        break;

      //standard metrics logic
      default:
        if (value >= 25) {
          // Dark green
          return "green-graph";
        } else if (value >= -10 && value < 25) {
          //Light Green
          return "light-green-graph";
        } else if (value >= -25 && value < -10) {
          //Blue
          return "yellow-graph";
        } else if (value >= -50 && value < -25) {
          //yellow
          return "orange-graph";
        } else if (value < -50) {
          //red-graph
          return "red-graph";
        } else if (value < 75) {
          return "dark-green-graph";
        } else if (value >= 75 && value < 110) {
          return "light-green-graph";
        } else if (value >= 110 && value < 125) {
          return "yellow-graph";
        } else if (value >= 125 && value < 150) {
          return "orange-graph";
        } else if (value >= 150) {
          return "red-graph";
        }
        break;
    }
  }
  // Both target and minimum goals are present
  else if (min_goal_val > 0 && target_goal_val > 0) {
    var avg_goal_value = (min_goal_val + target_goal_val) / 2;

    switch (metric) {
      //Spend is always blue
      case "cost":
      case "ad_cost":
      case "spend":
      case "ad_spend":
        return "blue-graph";
        break;
      case "revenue":
        if (value >= 25 || (avg_goal_value > 0 && amount >= avg_goal_value)) {
          //Dark Green
          return "green-graph";
        } else if (value >= -10 && value < 25) {
          //Light Green
          return "light-green-graph";
        } else if (value >= -25 && value < -10) {
          //Blue
          return "yellow-graph";
        } else if (value >= -50 && value < -25) {
          //Yellow
          return "orange-graph";
        } else if (value < -50) {
          //Red
          return "red-graph";
        }
        break;
      case "acos":
        if (amount < target_goal_val) {
          //Dark Green
          return "green-graph";
        } else if (amount >= target_goal_val && amount < avg_goal_value) {
          //Light Green
          return "light-green-graph";
        } else if (amount > avg_goal_value && amount <= min_goal_val) {
          //Yellow
          return "yellow-graph";
        } else if (amount <= min_goal_val * 1.1 && amount > min_goal_val) {
          //Orange
          return "orange-graph";
        } else if (amount > min_goal_val * 1.1) {
          //Red
          return "red-graph";
        }
        break;

      case "roas":
        if (amount > target_goal_val) {
          //Dark Green
          return "green-graph";
        } else if (amount <= target_goal_val && amount > avg_goal_value) {
          //Light Green
          return "light-green-graph";
        } else if (amount < avg_goal_value && amount >= min_goal_val) {
          //Yellow
          return "yellow-graph";
        } else if (amount >= min_goal_val * 0.9 && amount < min_goal_val) {
          //Orange
          return "orange-graph";
        } else if (amount < min_goal_val * 0.9) {
          //Red
          return "red-graph";
        }

        break;
    }
  }
  //If only minimum or target is present, assume the same number for both. In which case:
  else {
    if (min_goal_val > 0) {
      var goal_value = min_goal_val;
    } else if (target_goal_val > 0) {
      var goal_value = target_goal_val;
    } else {
      var goal_value = 0;
    }

    switch (metric) {
      //Spend is always blue-graph
      case "cost":
      case "ad_cost":
      case "spend":
      case "ad_spend":
        return "blue-graph";
        break;
      case "revenue":
        if (value >= 25 || (goal_value > 0 && amount >= goal_value)) {
          //Dark Green
          return "green-graph";
        } else if (value >= -10 && value < 25) {
          //Light Green
          return "light-green-graph";
        } else if (value >= -25 && value < -10) {
          //Blue
          return "yellow-graph";
        } else if (value >= -50 && value < -25) {
          //Yellow
          return "orange-graph";
        } else if (value < -50) {
          //Red
          return "red-graph";
        }
        break;
      case "acos":
        if (amount < goal_value) {
          //Dark Green
          return "green-graph";
        } else if (amount == goal_value) {
          //Yellow
          return "yellow-graph";
        } else if (amount <= goal_value * 1.1 && amount > goal_value) {
          //Orange
          return "orange-graph";
        } else if (amount > goal_value * 1.1) {
          //Red
          return "red-graph";
        }
        break;
      case "roas":
        // ROAS
        if (amount > goal_value) {
          //Dark Green
          return "green-graph";
        } else if (amount == goal_value) {
          //Yellow
          return "yellow-graph";
        } else if (amount >= goal_value * 0.9 && amount < goal_value) {
          //Orange
          return "orange-graph";
        } else if (amount < goal_value * 0.9) {
          //Red
          return "red-graph";
        }
        break;

      default:
        if (value >= 125) {
          return "dark-green-graph";
        } else if (value >= 90 && value < 125) {
          return "light-green-graph";
        } else if (value >= 75 && value < 90) {
          return "yellow-graph";
        } else if (value >= 50 && value < 75) {
          return "orange-graph";
        }
        break;
    }
  }
}

function calculateChangePercentage(currentValue, previousValue) {
  var previousValue = parseFloat(previousValue).toFixed(2);
  var currentValue = parseFloat(currentValue).toFixed(2);

  if (previousValue != 0 && currentValue != 0) {
    var val = ((currentValue - previousValue) / previousValue) * 100;
    if (isNaN(val)) {
      return "-";
    } else {
      return parseFloat(val).toFixed(1);
    }
  } else {
    return "-";
  }
}

function getKeyMetricsClassByGoal(
  metric,
  KeyMetricsData,
  logicType,
  reportKey
) {
  var value =
    typeof KeyMetricsData[metric] != undefined ? KeyMetricsData[metric] : null;
  // value = Number(value.replace(/[^\d\.]/g, ""));

  var min_goal_value =
    typeof KeyMetricsData[metric + "_goal"] != undefined
      ? KeyMetricsData[metric + "_goal"]
      : null;
  var target_goal_value =
    typeof KeyMetricsData[metric + "_target_goal"] != undefined
      ? KeyMetricsData[metric + "_target_goal"]
      : null;

  // If only minimum or target is present, assume the same number for both.  In which case:
  if (logicType == "single") {
    var goal_value = null;
    if (min_goal_value && min_goal_value > 0) {
      var goal_value = min_goal_value;
    } else if (target_goal_value && target_goal_value > 0) {
      var goal_value = target_goal_value;
    }

    switch (metric) {
      case "acos":
        if (value < goal_value) {
          //Dark Green
          return "kmc-green";
        } else if (value == goal_value) {
          //Yellow
          return "kmc-yellow";
        } else if (value <= goal_value * 1.1 && value > goal_value) {
          //Orange
          return "kmc-orange";
        } else if (value > goal_value * 1.1) {
          //Red
          return "kmc-red";
        }
        break;
      case "roas":
        // ROAS
        if (reportKey == "paid_product_ads_performance") {
          return "kmc-blue";
        } else {
          if (value > goal_value) {
            //Dark Green
            return "kmc-green";
          } else if (value == goal_value) {
            //Yellow
            return "kmc-yellow";
          } else if (value >= goal_value * 0.9 && value < goal_value) {
            //Orange
            return "kmc-orange";
          } else if (value < goal_value * 0.9) {
            //Red
            return "kmc-red";
          }
        }

        break;

      default:
        break;
    }
  } else {
    // If both target and minimum are present:

    var avg_goal_value = (min_goal_value + target_goal_value) / 2;

    switch (metric) {
      case "acos":
        if (value < target_goal_value) {
          //Dark Green
          return "kmc-green";
        } else if (value >= target_goal_value && value < avg_goal_value) {
          //Light Green
          return "kmc-light-green";
        } else if (value > avg_goal_value && value <= min_goal_value) {
          //Yellow
          return "kmc-yellow";
        } else if (value <= min_goal_value * 1.1 && value > min_goal_value) {
          //Orange
          return "kmc-orange";
        } else if (value > min_goal_value * 1.1) {
          //Red
          return "kmc-red";
        }
        break;

      case "roas":
        if (reportKey == "paid_product_ads_performance") {
          return "kmc-blue";
        } else {
          if (value > target_goal_value) {
            //Dark Green
            return "kmc-green";
          } else if (value <= target_goal_value && value >= avg_goal_value) {
            //Light Green
            return "kmc-light-green";
          } else if (value < avg_goal_value && value >= min_goal_value) {
            //Yellow
            return "kmc-yellow";
          } else if (value >= min_goal_value * 0.9 && value < min_goal_value) {
            //Orange
            return "kmc-orange";
          } else if (value < min_goal_value * 0.9) {
            //Red
            return "kmc-red";
          }
        }

        break;

      default:
        break;
    }
  }
}

function checkLogicType(
  key,
  KeyMetricsDataKey,
  logicType,
  reportKey,
  number,
  KeyMetricsData
) {
  if (
    KeyMetricsData.length > 0 &&
    (key.includes("acos") || key.includes("roas")) &&
    typeof KeyMetricsData[key + "_goal"] != undefined &&
    KeyMetricsData[key + "_goal"] > 0 &&
    typeof KeyMetricsData[key + "_target_goal"] != undefined &&
    KeyMetricsData[key + "_target_goal"] > 0
  ) {
    return getKeyMetricsClassByGoal(
      key,
      KeyMetricsData,
      (logicType = "both"),
      reportKey
    );
  } else if (
    (KeyMetricsData.length > 0 &&
      (key.includes("acos") || key.includes("roas")) &&
      typeof KeyMetricsData[key + "_goal"] != undefined &&
      KeyMetricsData[key + "_goal"] > 0) ||
    (typeof KeyMetricsData[key + "_target_goal"] != undefined &&
      KeyMetricsData[key + "_target_goal"] > 0)
  ) {
    return getKeyMetricsClassByGoal(
      key,
      KeyMetricsData,
      (logicType = "single"),
      reportKey
    );
  } else {
    var number = typeof number == undefined || number == "-" ? 0 : number;
    return getKeyMetricsClass(number, key, KeyMetricsData);
  }
}

function numberWithCommas(number) {
  if (isNaN(number) || number == null) {
    return "0";
  }
  var parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export default KeyMetrics;
