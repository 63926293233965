import { Typography } from "antd";
import React, { useEffect } from "react";

import { Col, Form, Row } from "antd";
import { useDispatch } from "react-redux";
import BingAdsCreateConfigurationForm from "../../../Components/Configuration/bingAds/BingAdsCreateForm";
import GoogleConsoleTable from "../../../Components/Configuration/bingAds/BingAdsTable";
import {
  createBingAdsDataSourcesThunk,
  getAllBingAdsDataSourcesThunk,
} from "../../../Redux/Actions/Thunk/datasources/ds_configs/BingAdsThunk";
const { Title } = Typography;

const DsBingAds = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllBingAdsDataSourcesThunk({}));
  }, [dispatch]);

  const onFinish = (values) => {
    const formData = {
      account: values.account,
      email: values.user_email,
      platform: values.platform,
      key: values.key,
      file_type: values.file_type,
      project_number: values.project_number,
    };

    dispatch(createBingAdsDataSourcesThunk(formData, form));
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div>
      <Title level={2}>Bing Ads </Title>
      <Row wrap={true} gutter={[18, 10]} justify="space-between">
        <Col xs={24} md={14} sm={10}>
          <GoogleConsoleTable />
        </Col>
        <Col xs={24} md={10} sm={10}>
          <Typography
            style={{
              textAlign: "center",
              padding: "10px 0 ",
            }}
          >
            ADD Bing Ads ACCOUNT
          </Typography>
          <BingAdsCreateConfigurationForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DsBingAds;
