import { FaInfoCircle } from "react-icons/fa";
import { Button, Form, Modal, Table, Tooltip } from "antd";
import { FiColumns, FiFilter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import React, { createContext, useCallback, useEffect, useState } from "react";

import { FilterBtns } from "./FilterBtns";
import ShowHideModal from "./ShowHideModal";
import { addSorters } from "./utils/sorters";
import DataTableSummary from "./DataTableSummary";
import { formDataAppend } from "../../../Utils/helpers";
import { checkFormat } from "../../../Utils/checkTableFormat";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import CustomColumnFilter from "../CustomTableComponents/CustomColumnFilter";
import {
  componentMatchReport,
  tableCellHelper,
} from "../../../Utils/helpers/tableHelper";
import ColumnFilterModal from "./ColumnFilterModal";
import ExportClient from "./ExportClient";
import { getDataTable } from "../../../Redux/Actions/Thunk/dataTableThunk";

export const columnDataContext = createContext();

const DataTableClient = (props) => {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState("");
  const [columnData, setColumnData] = useState([]);
  const [format, setFormat] = useState(undefined);
  const [seriesData, setSeriesData] = useState([]);
  const [accountName, setAccountName] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [columnShowHideModal, setColumnShowHideModal] = useState([]);
  const [isShowHideModalOpen, setIsShowHideModalOpen] = useState(false);
  const [isSharePopoverVisible, setIsSharePopoverVisible] = useState(false);

  const dispatch = useDispatch();

  const {
    dataTable: {
      table: {
        [props?.block?.block_id]: {
          [props?.block?.block_id]: {
            abtest_kpi,
            abtest_winner,
            clv_cohorts_val,
            data: tableData = [],
            response_data: responseData = [],
            goal_data: goalData = [],
          } = {},
          // isLoading
        } = {},
      } = {},
    } = {},
    accounts: {
      accounts: { notifications = {}, notification_services = {} } = {},
    },
  } = useSelector((state) => {
    return state;
  });

  var data = props?.all_input_data;
  var formData = formDataAppend(data);

  useEffect(() => {
    let data = props?.all_input_data;
    let formData = formDataAppend(data);
    dispatch(getDataTable(formData, props));
    // if (notification_link) {
    //   dispatch(getAllAccounts({}));
    // }
  }, []);

  // const columnHandler = useCallback((data) =>
  //   setColumnData(data)
  // ,[])

  useEffect(() => {
    if (responseData?.length > 0) {
      // setColumnData(responseData);
      setColumnData((prevData) =>
        prevData !== responseData ? responseData : prevData
      );
    } else {
      // setColumnData(tableData);
      setColumnData((prevData) =>
        prevData !== tableData ? tableData : prevData
      );
    }
  }, [responseData, tableData]);

  const config = JSON.parse(props?.block?.config);

  const tableColumns = config["columns"];

  const setColumnShowHideModalCallback = (newCols) => {
    setColumnShowHideModal(newCols);
  };

  const displayShowHideModal = () => {
    setIsShowHideModalOpen(true);
  };

  const showFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const getColumnSearchProps = (dataIndex, format) => ({
    filterDropdown: ({ confirm }) => (
      <>
        <div
          className="datatable-filter"
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Button
            onClick={() => {
              showFilterModal();
              setFormat(format);
              form.setFieldsValue({
                filter_column: dataIndex,
                filter_condition: "equal",
              });
              confirm({
                closeDropdown: true,
              });
            }}
            icon={<FiFilter />}
            size="small"
          ></Button>
          <Button
            onClick={() => {
              displayShowHideModal();
              confirm({
                closeDropdown: true,
              });
            }}
            size="small"
            icon={<FiColumns />}
          ></Button>
        </div>
      </>
    ),
    filterIcon: (filtered) =>
      form.getFieldsValue()["filter_column"] === dataIndex ? (
        <FilterFilled style={{ color: "#000000" }} />
      ) : (
        <FilterOutlined style={{ color: "#000000" }} />
      ),
  });

  const handleCancelShowHideModal = () => {
    setIsShowHideModalOpen(false);
  };

  if (config.hasOwnProperty("showGoogleAdsBtn")) {
    var showGoogleAdsBtn = config["showGoogleAdsBtn"];
  }

  if (config.hasOwnProperty("showGoogleAnalyticsBtn")) {
    var showGoogleAnalyticsBtn = config["showGoogleAnalyticsBtn"];
  }

  if (config.hasOwnProperty("apiPath")) {
    var drillDownAPiPath = config["apiPath"];
  }

  const [form] = Form.useForm();

  useEffect(() => {
    setColumns((prevData) =>
      prevData !== tableColumns ? tableColumns : prevData
    );
  }, []);

  const getColumnVisibilityInitialState = (columnKeys, config) => {
    const initialVisibility = {};
    if (config !== undefined && columnKeys !== undefined) {
      columnKeys.forEach((columnKey) => {
        if (config["organic_metrics_columns"] !== undefined) {
          initialVisibility[columnKey] =
            config["organic_metrics_columns"]?.includes(columnKey);
        } else {
          initialVisibility[columnKey] = false;
        }
      });
    }

    return initialVisibility;
  };

  const useColumnVisibilityState = (columnKeys, config) => {
    const [visibility, setVisibility] = useState(() =>
      getColumnVisibilityInitialState(columnKeys, config)
    );

    const toggleColumnVisibility = (e) => {
      setVisibility((prevState) => {
        const updatedVisibility = { ...prevState };
        if (config !== undefined && columnKeys !== undefined) {
          columnKeys.forEach((columnKey) => {
            updatedVisibility[columnKey] = e;
          });
        }
        const updatedColumns = tableColumns.filter((col) => {
          return updatedVisibility[col.key] !== false;
        });

        setColumns(updatedColumns);

        return updatedVisibility;
      });
    };

    return [visibility, toggleColumnVisibility];
  };

  const [changeColumnVisibility, toggleChangeColumnVisibility] =
    useColumnVisibilityState(config["change_columns"], config);

  const [absoluteChangeColumnVisibility, toggleAbsoluteChangeColumnVisibility] =
    useColumnVisibilityState(config["absolute_change_columns"], config);

  const [metricsChangeColumnVisibility] = useColumnVisibilityState(
    config["organic_metrics_columns"],
    config
  );

  const [modalChangeColumnVisibility] = useColumnVisibilityState(
    columnShowHideModal,
    columnShowHideModal
  );

  const renderColumns = (
    columns,
    getColumnSearchProps,
    setLoading,
    dataProps,
    clv_cohorts_val,
    goalData,
    notifications,
    notification_services,
    abtest_winner,
    abtest_kpi
  ) => {
    const pathArray = window.location?.pathname?.split("/");
    let report_key_name = pathArray[4];

    return columns
      .map((column, indexCol) => {
        const columnHeaderTooltip = column.tooltip ? (
          <Tooltip title={column.tooltip}>
            <span style={{ color: "#8dc441" }}>
              <FaInfoCircle />
            </span>
          </Tooltip>
        ) : column.modal_data ? (
          <>
            <span style={{ color: "#8dc441" }}>
              <FaInfoCircle />
            </span>

            <Modal
              title="Insights Description"
              // visible={isModalVisible}
              // onOk={closeModal}
              // onCancel={closeModal}
            >
              {
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: column?.modal_data,
                    }}
                  />
                </>
              }
            </Modal>
          </>
        ) : null;

        if (
          column.children &&
          dataProps?.all_input_data?.include_amazon_data === 0 &&
          dataProps?.block?.report_key === "media_mix_modeling_v2"
        ) {
          return {
            ...column,
            children: column.children
              .map((child) => {
                if (
                  (dataProps?.all_input_data?.include_amazon_data === 0 &&
                    child.dataIndex === "asc_spend_perc") ||
                  child.dataIndex === "asc_lift"
                ) {
                  return null;
                }
                return {
                  ...child,
                  render: (text) => {
                    return (
                      <>
                        {
                          <FormattedNumberCell
                            value={text}
                            format={child.format}
                            metric={child.dataIndex}
                          />
                        }
                      </>
                    );
                  },
                };
              })
              .filter(Boolean),
          };
        } else if (column.children) {
          return {
            ...column,
            children: renderColumns(
              column.children,
              getColumnSearchProps,
              setLoading,
              dataProps,
              clv_cohorts_val,
              goalData,
              notifications,
              notification_services,
              abtest_winner,
              abtest_kpi
            ),
            title: <>{column.title}</>,
            render: (text, record) => (
              <span>
                {renderColumns(
                  column.children,
                  getColumnSearchProps,
                  setLoading,
                  dataProps,
                  clv_cohorts_val,
                  goalData,
                  notifications,
                  notification_services,
                  abtest_winner,
                  abtest_kpi
                ).map((nestedColumn) => (
                  <span key={nestedColumn.dataIndex}>
                    {
                      <Tooltip
                        trigger="hover"
                        title={record[nestedColumn.tooltip]}
                      >
                        <span style={{ color: "#8dc441" }}>
                          <FaInfoCircle />
                        </span>
                      </Tooltip>
                    }
                    {nestedColumn.render
                      ? nestedColumn.render(
                          record[nestedColumn.dataIndex],
                          record
                        )
                      : record[nestedColumn.dataIndex]}
                    {" | "}
                  </span>
                ))}
              </span>
            ),
          };
        } else {
          const matchReport = componentMatchReport[report_key_name];
          const columnProps = {
            ...column,
            title: (
              <>
                {column.global_expand_collpase === "true" && (
                  <span
                    style={{ cursor: "pointer" }}
                    // onClick={handleGlobalExpandCollapse}
                  >
                    {/* {expandedAll ? <MinusOutlined /> : <PlusOutlined />} */}
                    {/* {expandedAll ? <PlusOutlined /> : <MinusOutlined />} */}
                  </span>
                )}
                {column.title}
                {columnHeaderTooltip}
              </>
            ),
            sorter: true,
            sortDirections: ["descend", "ascend"],
            fixed: column.fixed != undefined ? column.fixed : "",
            ...(column.filter === "false"
              ? false
              : getColumnSearchProps(column.dataIndex, column.format)),
            render: (text) => (
              <>
                {
                  <FormattedNumberCell
                    value={text}
                    format={column.format}
                    metric={column.dataIndex}
                  />
                }
              </>
            ),
          };

          if (matchReport) {
            return {
              ...columnProps,
              render: (text, record) => {
                const ValidComponent = tableCellHelper(
                  report_key_name,
                  text,
                  record,
                  column,
                  setLoading,
                  dataProps,
                  clv_cohorts_val,
                  goalData,
                  notifications,
                  notification_services,
                  abtest_winner,
                  abtest_kpi
                );
                return (
                  <>
                    {record.hasOwnProperty("children") &&
                    Array.isArray(record.children) ? (
                      <>
                        <strong>{ValidComponent}</strong>
                      </>
                    ) : (
                      <>
                        {/* special case added for amd */}
                        {record?.service_new === "" ? (
                          <strong>{ValidComponent}</strong>
                        ) : (
                          ValidComponent
                        )}
                      </>
                    )}
                  </>
                );
              },
            };
          } else {
            return {
              ...columnProps,
              goalData,
              render: (text) => (
                <>
                  {
                    <FormattedNumberCell
                      value={text}
                      format={column.format}
                      metric={column.dataIndex}
                    />
                  }
                </>
              ),
            };
          }
        }
      })
      .filter((column) => {
        const isVisibleByDefault = CustomColumnFilter({
          column,
          changeColumnVisibility,
          absoluteChangeColumnVisibility,
          metricsChangeColumnVisibility,
          modalChangeColumnVisibility,
          config,
          // columnsTabsToHide,
          columnShowHideModal,
        });
        return isVisibleByDefault;
      });
  };

  const getRenderColumnData = renderColumns(
    columns,
    getColumnSearchProps,
    setLoading,
    props,
    clv_cohorts_val,
    goalData,
    notifications,
    notification_services,
    abtest_winner,
    abtest_kpi
  );

  const colData = checkFormat(
    showGoogleAdsBtn,
    showGoogleAnalyticsBtn,
    getRenderColumnData,
    getColumnSearchProps,
    goalData,
    isSharePopoverVisible,
    setIsSharePopoverVisible,
    seriesData,
    setSeriesData,
    categoriesData,
    setCategoriesData,
    setAccountName,
    accountName,
    drillDownAPiPath,
    props?.all_input_data,
    setLoading,
    props,
    formData,
    dispatch
  );

  const handleFilterModalCancel = () => {
    setIsFilterModalOpen(false);
  };

  const handleFilterColData = (filteredData) => {
    setColumnData(filteredData);
  };

  return (
    <>
      <columnDataContext.Provider
        value={{ setColumnData, columns, setColumns, config }}
      >
        <div className="datatable-options">
          <FilterBtns
            columnsConf={tableColumns}
            toggleChangeColumnVisibility={toggleChangeColumnVisibility}
            toggleAbsoluteChangeColumnVisibility={
              toggleAbsoluteChangeColumnVisibility
            }
          />
          <ExportClient
            dataSource={columnData}
            columnsConf={tableColumns}
            blockName={props?.block?.block_name}
          />
        </div>
        <div className="datatable-content">
          <Table
            bordered
            id="basic-table"
            dataSource={columnData}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: "max-content" }}
            columns={addSorters(colData)}
            summary={() => (
              <DataTableSummary
                config={config}
                blockId={props?.block?.block_id}
                columnData={addSorters(getRenderColumnData)}
              />
            )}
          />
        </div>
        <ShowHideModal
          tableColumns={tableColumns}
          isShowHideModalOpen={isShowHideModalOpen}
          handleCancelShowHideModal={handleCancelShowHideModal}
          setColumnShowHideModalCallback={setColumnShowHideModalCallback}
        />
        <ColumnFilterModal
          form={form}
          format={format}
          setFormat={setFormat}
          dataSource={columnData}
          tableColumns={tableColumns}
          isFilterModalOpen={isFilterModalOpen}
          handleFilterColData={handleFilterColData}
          handleFilterModalCancel={handleFilterModalCancel}
        />
      </columnDataContext.Provider>
    </>
  );
};

export default DataTableClient;
