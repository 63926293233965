/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";

const AccountDataStatus = (props) => {
  const columnIds = [
    "GA_Daily_status",
    "GAds_Daily_status",
    "Bingads_Daily_status",
    "amazon_Daily_status",
    "AMS_Daily_status",
    "amazon_business_Daily_status",
    "GC_Daily_status",
    "FB_Daily_status",
    "BE_Daily_status",
    "afull_Daily_status",
    "seosheet_Daily_status",
    "team_Daily_status",
    "astat_Daily_status",
  ];
  const histColumnIds = [
    "GA_Historical_status",
    "GAds_Historical_status",
    "Bingads_Historical_status",
    "GC_Historical_status",
    "FB_Historical_status",
  ];
  let cellClass = "";
  if (columnIds.includes(props.column.dataIndex)) {
    if (props.value == "Not Configured" || props.value == "Access Issue") {
      cellClass = "light-red-column";
    } else if (props.value == "Configured" || props.value == "Used") {
      cellClass = "green-column";
    } else if (props.value == "Not Used") {
      cellClass = "blue-column";
    }
  } else if (histColumnIds.includes(props.column.dataIndex)) {
    if (props.value == "Present") {
      cellClass = "green-column";
    }
  }
  return (
    <span className={cellClass}>
      <FormattedNumberCell value={props.value} format={props.column.format} />
    </span>
  );
};

export default AccountDataStatus;
