import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, Row, Col, Spin } from "antd";
import { Typography, Layout } from "antd";
import { withRouter, useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loginImg from "../../Style/exclusive.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { loginSuccess } from "../../Redux/Actions/Thunk/loginThunk";
import l1 from "../../Assets/Images/l1.png";
import l2 from "../../Assets/Images/l2.png";
import l3 from "../../Assets/Images/l3.png";
import loginuser from "../../Assets/Images/logo short.png";
import { FiHelpCircle } from "react-icons/fi";
import Config from "../../Config";
const { Content } = Layout;
const { Title, Text } = Typography;

const Login = () => {
  const history = useHistory();
  let location = useLocation();
  const {
    login: { user, isLoading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const {
    SSO_LOGIN: { baseURL },
  } = Config;

  const currentUrl = window.location.pathname + window.location.search;

  useEffect(() => {
    const currentQuery = window.location.search;
    const searchParams = new URLSearchParams(currentQuery);
    const accessToken = searchParams.get("token");

    const fetchUserData = async () => {

      if (accessToken) {
        dispatch(loginSuccess(accessToken));
        history.push(currentUrl);
      } else {
        const currentPath = window.location.origin + window.location.pathname;
        let currentSearch = window.location.search;

        // Replace the first '?' with '&', if present
        if (currentSearch.indexOf("?") === 0) {
          currentSearch = "&" + currentSearch.slice(1);
        }

        window.location.replace(`${baseURL}login?domain=${currentPath}${currentSearch}`);
      }
    };

    setTimeout(() => {
      fetchUserData();
    }, 2000);

  }, [currentUrl]);
  


  
  return (
    <Content className="login-main">
      <Row>
        <Col span={24} className="login-left">
          <div className="login-left-image">
            <img
              src={loginImg}
              width="80%"
              style={{ alignSelf: "right" }}
              alt="login"
            />
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default withRouter(Login);
