import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Donut from "../Highcharts/Donut";
import Column from "../Highcharts/Column";
import { formDataAppend } from "../../Utils/helpers";
import { getDevicePerformance } from "../../Redux/Actions/Thunk/TrendThunk";
import { Spin, Table } from "antd";
import { format_data } from "../../Utils/helpers";
import { FiSquare } from "react-icons/fi";
import HtmlParser from "html-react-parser";
import { Tooltip } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { FormattedNumberCell } from "../../Utils/helpers/formatting";
import isEqual from "lodash/isEqual";

const CampaignWithCollapsibleTable = (props) => {
  const {
    trend: {
      devicePerformance: {
        [props?.block?.block_id]: {
          json_data: jsonData = [],
          data: tbl_data = [],
          table_columns: TableColumns = [],
          labels_new: labelNew = [],
          table_total: tableTotal = [],
          insights_data: insightData = [],
          response_data: response_data = [],
          total_records: totalAllData = [],
        } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getDevicePerformance(formData, props));
  }, []);

  //get table column
  const column = TableColumns ? TableColumns : [];
  const [totalRow, setTotalRow] = useState({});

  //total data
  useEffect(() => {
    // Assuming totalAllData is a prop or state variable
    const totalData = { ...totalAllData }; // Copy the object

    const newObj = {};
    Object.keys(totalData).forEach((data) => {
      newObj[data] = totalData[data];
    });

    // Check if the new object is different from the previous totalRow
    if (!isEqual(newObj, totalRow)) {
      setTotalRow(newObj);
    }
  }, [totalAllData, totalRow]);
  //get table heading data
  const ThData = () => {
    return column?.map((data, i) => {
      return (
        <th key={i}>
          {data}{" "}
          {labelNew?.map((v, idx) =>
            v?.tooltip_data != undefined &&
              v.tooltip_data != "" &&
              data === v?.value ? (
              <Tooltip color="black" title={v?.tooltip_data}>
                <span style={{ color: "#8dc441" }}>
                  <FaInfoCircle />
                </span>
              </Tooltip>
            ) : (
              ""
            )
          )}
        </th>
      );
    });
  };
  // get table row data
  const tdData = () => {
    return tbl_data.map((data, index) => {
      return (
        <tr key={index}>
          {labelNew?.map((v, idx) => {
            return (
              <>
                {idx === 0 ? (
                  <td
                    style={{
                      textTransform: "capitalize",
                    }}
                    className="performance-report-color-icon"
                    key={v.key}
                  >
                    <span>
                      <FiSquare
                        color={jsonData[index]?.data[index]?.color}
                        fill={jsonData[index]?.data[index]?.color}
                      />
                    </span>

                    {data[v.key]}
                  </td>
                ) : (
                  <td key={v.key}>
                    {v?.format == "amount"
                      ? format_data(data[v.key], v?.format, 2, v?.key)
                      : v?.format == "percentage"
                        ? format_data(data[v.key], v?.format, null, v?.key)
                        : v?.format == "number"
                          ? format_data(data[v.key], v?.format)
                          : data[v.key]}
                  </td>
                )}
              </>
            );
          })}
        </tr>
      );
    });
  };

  const columns = [
    {
      title: "Campaign",
      dataIndex: "campaign_type",
      key: "campaign_type",
      render: (text) => (
        <>
          {text === "Sponsored Products" ? (
            <span>
              {text}
              <FiSquare color="#3272b5" fill="#3272b5" />
            </span>
          ) : text === "Sponsored Brands" ? (
            <span>
              {text}
              <FiSquare color="#4CBAF8" fill="#4CBAF8" />
            </span>
          ) : text === "Sponsored Display" ? (
            <span>
              {text}
              <FiSquare color="#50AE42" fill="#50AE42" />
            </span>
          ) : (
            <span>{text}</span>
          )}
        </>
      ),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      format: "number",
      render: (text) => <span>{format_data(text, "number")}</span>,
    },
    {
      title: "Change",
      dataIndex: "change_clicks",
      key: "change_clicks",
      format: "percentage",
      render: (text) => <span>{format_data(text, "percentage")}</span>,
    },
    {
      title: "Ad Spend",
      dataIndex: "ad_cost",
      key: "ad_cost",
      format: "amount",
      render: (text) => <span>{format_data(text, "amount")}</span>,
    },
    {
      title: "Change",
      dataIndex: "change_ad_cost",
      key: "change_ad_cost",
      format: "percentage",
      render: (text) => <span>{format_data(text, "percentage")}</span>,
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      format: "amount",
      render: (text) => <span>{format_data(text, "amount")}</span>,
    },
    {
      title: "Change",
      dataIndex: "change_revenue",
      key: "change_revenue",
      format: "percentage",
      render: (text) => <span>{format_data(text, "percentage")}</span>,
    },
    {
      title: "ACOS",
      dataIndex: "acos",
      key: "acos",
      format: "percentage",
      render: (text) => <span>{format_data(text, "percentage", 2)}</span>,
    },
    {
      title: "Change",
      dataIndex: "change_acos",
      key: "change_acos",
      format: "percentage",
      render: (text) => <span>{format_data(text, "percentage")}</span>,
    },
    {
      title: "Conversion Rate",
      dataIndex: "conversion_rate",
      key: "conversion_rate",
      format: "percentage",
      render: (text) => <span>{format_data(text, "percentage", 2)}</span>,
    },
    {
      title: "Change",
      dataIndex: "change_conversion_rate",
      key: "change_conversion_rate",
      format: "percentage",
      render: (text) => <span>{format_data(text, "percentage")}</span>,
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
      format: "amount",
      render: (text) => <span>{format_data(text, "amount", 2, "cpc")}</span>,
    },
    {
      title: "Change",
      dataIndex: "change_cpc",
      key: "change_cpc",
      format: "percentage",
      render: (text) => <span>{format_data(text, "percentage")}</span>,
    },
  ];

  return (
    <>
      <Spin spinning={isLoading}>
        {jsonData && jsonData.length > 0 ? (
          <>
            <div className="performance-graph-chart-wrapper">
              <Donut data={jsonData} />
              <Column data={jsonData} />
            </div>
          </>
        ) : (
          "No Data Available"
        )}
        <div className="performance-datatable">
          {/* <table id="performance-table"> */}
          <Table
            key={Math.round()}
            id="performance-table"
            columns={columns}
            dataSource={response_data}
            filter={false}
            className="campaign-performance-table"
            pagination={false}
            summary={() => {
              return (
                <>
                  {totalAllData && Object.keys(totalAllData).length > 1 ? (
                    <Table.Summary.Row>
                      {columns.map((column, columnIndex) => (
                        <Table.Summary.Cell
                          key={columnIndex}
                          index={columnIndex}
                        >
                          {columnIndex === 0 ? (
                            "TOTAL"
                          ) : (
                            <FormattedNumberCell
                              value={totalRow[column.dataIndex]}
                              format={column.format}
                              metric={column.dataIndex}
                            />
                          )}
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  ) : (
                    ""
                  )}
                </>
              );
            }}
          />
        </div>
        {insightData && insightData.length > 0 ? (
          <div className="insights">
            <h4>Insights</h4>
            <ul>
              {insightData.map((insight) => (
                <li>{HtmlParser(insight)}</li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </Spin>
    </>
  );
};

export default CampaignWithCollapsibleTable;
