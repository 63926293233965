import { Button, Col, Progress, Row } from "antd";
import { useEffect } from "react";
import { formDataAppend } from "../../../../Utils/helpers";
import { getPerformanceMetrics } from "../../../../Redux/Actions/Thunk/reports";
import { useDispatch, useSelector } from "react-redux";

const PerformanceMetrics = (props) =>{

	const dispatch = useDispatch();

	useEffect(()=> {
		let data = props?.all_input_data;
		let formData = formDataAppend(data);
		dispatch(getPerformanceMetrics(props, formData));
	}, []);

	const {
		keyMetrics:{
			performance_metrics: {data: KeyMetricsData= [], nlg }
		}
	} = useSelector((state) => state);

    return (
        <>
          <div className="gads-content-single gads-performance">
							<div className="gcs-title">
								<h4>Performance Metrics*</h4>
							</div>
							<div className="gads-performance-content">
								<Row gutter={24}>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Ad Spend</h4>
											<h2>${Math.floor(KeyMetricsData[0]?.ad_spend.toFixed(2))?.toLocaleString()}</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Impressions</h4>
											<h2>{KeyMetricsData[0]?.impressions?.toLocaleString()}</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Clicks</h4>
											<h2>{KeyMetricsData[0]?.clicks?.toLocaleString()}</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>CTR</h4>
											<h2>{KeyMetricsData[0]?.ctr}%</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Revenue</h4>
											<h2>${Math.floor(KeyMetricsData[0]?.revenue.toFixed(2))?.toLocaleString()}</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Conversions</h4>
											<h2>{Math.floor(KeyMetricsData[0]?.conversions.toFixed(2))?.toLocaleString()}</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>CPA</h4>
											<h2>${(KeyMetricsData[0]?.cpa.toFixed(2))?.toLocaleString()}</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>ROAS</h4>
											<h2>{Math.floor(KeyMetricsData[0]?.roas)?.toLocaleString()}%</h2>
										</div>
									</Col>
								</Row>
							</div>
							<div className="gads-performance-text">
								<p>*<span dangerouslySetInnerHTML={{__html:nlg}}></span></p>
							</div>
							<div className="gads-imporve-btn">
								<a href="https://agital.com/ecommerce-analysis/">
									<Button>Improve my score</Button>
								</a>
							</div>
						</div>
        </>
    )
}

export default PerformanceMetrics;