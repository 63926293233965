import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { formDataAppend, format_data } from "../../Utils/helpers";
import { getKeyInsights } from "../../Redux/Actions/Thunk/mmmThunk";
import HtmlParser from "html-react-parser";

const MMMKeyInsights = (props) => {
  const {
    key_insights: {
      key_insights: {
        [props?.block?.block_id]: { insights_data: insights_data = [] } = {},
        isLoading,
      },
    } = {},
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  var data = props?.all_input_data;
  var formData = formDataAppend(data);

  // Fetch data
  useEffect(() => {
    dispatch(getKeyInsights(formData, props));
  }, []);

  if (insights_data != undefined) {
    var listItems = insights_data.map((insight) => (
      <li>{HtmlParser(insight)}</li>
    ));
  }

  return (
    <>
      <div className="insights">
        <ul>{listItems}</ul>
      </div>
    </>
  );
};

export default MMMKeyInsights;
