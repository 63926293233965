import { message } from "antd";
import * as FacebookTikTokApi from "../../../Api/FacebookTikTokApi";
import { ACTIONS_TYPES } from "../../Reducers/facebookTikTokReducer";
import * as facebookTikTokAction from "../facebookTikTokAction";

export const getFacebookResponse = (props, params = "") => {
  return async (dispatch) => {
    try {
      var ad_id = props.ad_id;
      dispatch({
        type: ACTIONS_TYPES.GET_FACEBOOK_API,
      });
      let facebook_data = {};
      if (ad_id > 0) {
        const response = await FacebookTikTokApi.getFacebookResponse(props);
        if (response?.status == 200) {
          facebook_data = {
            iframe: response?.data?.iframe,
            src: response?.data?.src,
            ad_id: ad_id
          };
        }
      }
      return dispatch(facebookTikTokAction.fetchFacebookData(facebook_data));
    } catch (error) {
      console.error("Error in fetching data: " + error);
    } finally {
    }
  };
};

export const getTikTokResponse = (props, params = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_TIKTOK_API,
      });
      let tiktok_data = {};
      if (props.ad_id > 0) {
        const response = await FacebookTikTokApi.getTikTokResponse(props);
        if (response?.status == 200) {
          tiktok_data = {
            tiktok_item_id: response?.data?.tiktok_item_id,
            tiktok_ad_id: response?.data?.ad_id
          };
        }
      }
      return dispatch(facebookTikTokAction.fetchTikTokData(tiktok_data));
    } catch (error) {
      console.error("Error in fetching data: " + error);
    } finally {
    }
  };
};
