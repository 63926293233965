import { ACTIONS_TYPES } from "../Reducers/dataTableReducer";

export function getDataTable(key, payload) {
  return {
    type: ACTIONS_TYPES.FETCH_TABLE,
    key,
    payload,
  };
}

export function getExportDataTable(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_EXPORT_TABLE,
    payload,
  };
}
