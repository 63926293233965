import React from "react";
import { Spin } from "antd";

const SpinComponent = () => {
  return (
    <div>
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
          width: "100%",
        }}
        size="large"
      />
    </div>
  );
};

export default SpinComponent;
