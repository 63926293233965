import { Form, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GoogleAdsUpdateConfigurationForm from "../../Components/Configuration/googleAds/googleAdsUpdateForm";
import {
  getGoogleAdsParamDataSourcesThunk,
  updateGoogleAdsParamDataSourcesThunk,
} from "../../Redux/Actions/Thunk/datasources/GoogleAdsThunk";
const { Title } = Typography;
const UpdateGoogleAdsConfiguration = () => {
  const [form] = Form.useForm();

  const history = useHistory();
  const { account_ds: { auth_params = {} } = {} } = useSelector(
    (state) => state.googleAds
  );
  // get id from params
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGoogleAdsParamDataSourcesThunk({ auth_params_id: id }));
  }, [id]);

  const fetchGoogleAdsConfiguration = async (id) => {
    // call to api for now let's say following has been returned
    const googleAdsConfiguration = {
      id: auth_params?.id,
      client_id: auth_params?.client_id,
      client_secret: auth_params?.client_secret,
      user_email: auth_params?.user,
      developer_token: auth_params?.developer_token,
      environment: auth_params?.environment,
      redirect_uri: auth_params?.redirect_uri,
      platform: auth_params?.platform,
      file_type: auth_params?.file_type,
      customer_id: auth_params?.customer_id,
      upload: auth_params?.key_file,
      user_agent: auth_params?.user_agent,
    };
    form.setFieldsValue(googleAdsConfiguration);
  };

  useEffect(() => {
    fetchGoogleAdsConfiguration(id);
  }, [auth_params, id]);

  const handleSubmit = (values) => {
    let file =
      values?.key_file?.target?.files?.length > 0
        ? values.key_file.target.files[0]
        : null;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user", values.user_email);
    formData.append("developer_token", values.developer_token);
    formData.append("client_id", values.client_id);
    formData.append("platform", values.platform);
    formData.append("user_agent", values.user_agent);
    formData.append("file_type", values.file_type);
    formData.append("id", id);
    dispatch(updateGoogleAdsParamDataSourcesThunk(formData, history));
  };

  const handleCancel = () => {
    history.push("/google-ads-configuration");
  };
  return (
    <>
      <Title title="2">Update Google Ads Configuration</Title>
      <div
        style={{
          width: "800px",
        }}
      >
        <GoogleAdsUpdateConfigurationForm
          onFinish={handleSubmit}
          onFinishFailed={handleCancel}
          form={form}
        />
      </div>
    </>
  );
};

export default UpdateGoogleAdsConfiguration;
