import { ACTIONS_TYPES } from "../Reducers/pieReducer";

export function fetchPieChart(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_PIE_CHART_DATA,
    payload,
  };
}

export function getPieChart(payload) {
  return {
    type: ACTIONS_TYPES.GET_PIE_CHART_DATA,
    payload,
  };
}
