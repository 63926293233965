import React from "react";
import { Table, Row, Col, Tabs } from "antd";
import { Link } from "react-router-dom";
import loginImg from "../../Style/exclusive.png";
import monocle from "../../Assets/Images/monLong.png";
import ds from "../../Assets/Images/ds.png";
const columns = [
	,
	{
		title: "reports",
		dataIndex: "reports",
		key: "reports",
		render: (text) => <a>{text}</a>,
	},
	{
		title: "category",
		dataIndex: "category",
		key: "category",
	},

	{
		title: "views",
		dataIndex: "views",
		key: "views",
	},
	{
		title: "users",
		dataIndex: "users",
		key: "users",
	},
];
const data = [
	{
		key: "1",
		reports: "Website Holistic Performance",
		category: "Holistic",
		views: 69,
		users: 12,
	},
	{
		key: "2",
		reports: "Facebook Ads Performance",
		category: "	Paid Social Marketing",
		views: 55,
		users: 23,
	},
	{
		key: "3",
		reports: "Paid and Product Ads Performance",
		category: "Paid Search (Google/Bing)",
		views: 33,
		users: 5,
	},
];

const SSOLogin = () => {
	return (
		<div className="login-dashboard">
			<div className="login-dashboard-title">
				<h2>Welcome to Agital Impact </h2>
			</div>
			<div className="login-dashboard-accounts">
				<h4>ACCOUNTS</h4>
				<Row gutter={32}>
					<Col span={6}>
						<div className="lda-single">
							<Link>
								<img src={loginImg} alt="user" />
								<h4>Business Insights</h4>
							</Link>
						</div>
					</Col>
					<Col span={6}>
						<div className="lda-single">
							<Link>
								<img src={loginImg} alt="user" />
								<h4>Competitive Price Intelligence</h4>
							</Link>
						</div>
					</Col>
					<Col span={6}>
						<div className="lda-single">
							<Link>
								<img src={monocle} alt="user" />
								<h4>Monocle Analytics</h4>
							</Link>
						</div>
					</Col>
					<Col span={6}>
						<div className="lda-single">
							<Link>
								<img src={ds} alt="user" />
								<h4>Data Sources</h4>
							</Link>
						</div>
					</Col>
				</Row>
			</div>
			<div className="login-dashboard-reports">
				<h4>MOST VIEWED REPORTS – CUSTOMERS</h4>
				<Tabs defaultActiveKey="1">
					<Tabs.TabPane tab="All" key="1">
						<Table
							columns={columns}
							dataSource={data}
							className="login-dashboard-table"
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Business Insights" key="2">
						<p>Report table for BI reports </p>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Competitive Price Intelligence" key="3">
						<p>Report table for CPI reports </p>
					</Tabs.TabPane>
				</Tabs>
			</div>
		</div>
	);
};

export default SSOLogin;
