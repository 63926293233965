import { Select } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInputControls,
  getAllInputControls,
} from "../../Redux/Actions/Thunk/reportLayoutThunk";
import { formDataAppend, titleCase } from "../../Utils/helpers";
import { Spin } from "antd";
import { setDefaults } from "../../Redux/Actions/inputControlAction";
import Config from "../../Config";

const { Option } = Select;

const onChange = (value) => {};

const onSearch = (value) => {};
const {
  API: { baseURL, authKey },
} = Config;

const InputSelect = (props) => {
  const targetElement = useRef(null);
  const pathArray = window?.location?.pathname?.split("/");
  var report_name = pathArray[4];
  var account_id = pathArray[5];
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());
  const {
    inputcontrols: { defaults: defaults = [] },
  } = useSelector((state) => state);

  const {
    // reportLayouts: {
    //   layouts: {
    //     data: layoutData = [],
    //     // input_controls: inputControlsData = [],
    //     isLoading,
    //   },
    //   input_controls_data: { data: iData = [] },
    // },
    InputDynamic: {
      items: { data: inputData = [] },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  // Fetch data
  useEffect(() => {
    let inputData = {
      authentication: authKey,
    };
    let dataAppend = formDataAppend(inputData);
    dispatch(getInputControls(dataAppend, props));
  }, []);

  useEffect(() => {
    let defaultValue = props?.block?.default;
    if (urlSearchParams.get(props?.block?.id) != null) {
      defaultValue = params_data[props?.block?.id];
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    } else {
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    }
  }, [window.location.search]);

  useEffect(() => {
    if (defaults.hasOwnProperty(props?.block?.id)) {
      props.form.setFieldsValue({
        [props?.block?.id]: defaults[props?.block?.id],
      });
    }
  }, [defaults]);

  return (
    <>
      <div className="Report-V2-filter-option">
        <div className="Report-V2-filter-box">
          <div className="Report-V2-filter-option-title">
            <span>{props?.block?.label}</span>
          </div>
          <Select
            key={props?.block?.label}
            name={props?.block?.label}
            dropdownStyle={{
              width: "5%",
            }}
            showSearch
            maxTagCount={1}
            placeholder="Select"
            optionFilterProp="children"
            // onChange={onChange}
            // onSearch={onSearchform}
            defaultValue={props.block.default}
            //listHeight={128}
            // open={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {inputData?.map((data) => (
              <Option value={data.id} id={data.id} name={data.id}>
                {titleCase(data?.value)}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};

export default InputSelect;
