import API_BI from "../Utils/axios_bi";
import { ApiEndpointsBI } from "../Utils/constant";

const { GET_INTERNAL_REPORTS } = ApiEndpointsBI;

export const getInternalReports = async (params) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  if (params !== "") {
    return await API_BI.get(`${GET_INTERNAL_REPORTS}?${params}`);
  } else {
    return await API_BI.get(GET_INTERNAL_REPORTS);
  }
};
