import { Button, Col, Row } from "antd";
import StackedBar from "../StackedBar";
import ColumnCharts from "../../../Highcharts/ColumnChart";
import AdsDataTable from "./AdsDataTable";
import { useSelector } from "react-redux";

const ProductPerformance = (props) => {

	const {
        dataTable: {
          table: {
            [props?.cols[2]?.block?.block_id]: {
                roas_goal
			} = {},
          },
        } = {},
		graph: {
			graph: {
			  [props?.cols[0]?.block?.block_id]: {
				nlg_txt : nlg_data={},
			  } = {},
			},
		  } = {},
      } = useSelector((state) => state);

	  const modifiedNlgText = nlg_data?.data?.length && nlg_data?.data[0].nlg.replace(/\$-/g, '-$');
    
    return (
        <>
            <div className="gads-content-single gads-product">
							<div className="gcs-title">
								<h4>Product Performance</h4>
							</div>
							<div className="gcs-single">
								<div className="gcs-title-btn gsctb-first">
									<span>
										ROAS GOAL –  <strong>{roas_goal}%</strong>
									</span>
								</div>
								<div className="gsc-content">
									<Row gutter={32}>
										<Col span={12}>
											<StackedBar 
												{...props.cols.filter((prop) => prop.block.block_id === 515)[0]}
												format=''	
												all_input_data={props.all_input_data}
											/>
										</Col>
										<Col span={12}>
											<ColumnCharts 
												{...props.cols.filter((prop) => prop.block.block_id === 505)[0]}
												all_input_data={props.all_input_data}
											/>
										</Col>
									</Row>
								</div>
								<div className="gsc-text">
									<strong dangerouslySetInnerHTML={{__html:modifiedNlgText}} />
								</div>
							</div>
						
							<AdsDataTable
								{...props.cols.filter((prop) => prop.block.block_id === 516)[0]}
								title='10 WORST PERFORMING PRODUCTS'
								total_label = 'Total Waste'
								all_input_data={props.all_input_data} 
							/>

                            <AdsDataTable
								{...props.cols.filter((prop) => prop.block.block_id === 509)[0]}
								title='10 BEST PERFORMING PRODUCTS'
								total_label = 'Total Good'
								all_input_data={props.all_input_data} 
							/>
							
							<div className="gads-imporve-btn">
							<a href="https://agital.com/ecommerce-analysis/">
									<Button>Improve my score</Button>
								</a>
							</div>
						</div> 
         </>
    )
}

export default ProductPerformance;