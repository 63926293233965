import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Tooltip } from "antd";

const AdwordSearchTermPerformance = (props, text) => {
  let cellClass = "";
  let recordData = props?.record;
  const search_term_type = recordData?.search_term_type;
  const capitalizedSearchTermType =
    search_term_type.charAt(0).toUpperCase() + search_term_type.slice(1);

  if (props?.goalData.hasOwnProperty("cvr_avg")) {
    var cvr_avg = props?.goalData?.cvr_avg;
  }

  if (props?.goalData.hasOwnProperty("cpc_avg")) {
    var cpc_avg = props?.goalData?.cpc_avg;
  }

  if (props?.goalData.hasOwnProperty("roas_goal")) {
    const roas_goal = props?.goalData?.roas_goal;
    if (typeof roas_goal === "string") {
        const pla_ppc_roas_target_goal_array = roas_goal
            .split(",")
            .map((item) => item.replace("%", ""));
        const [pla_roas_target_goal = "N/A", ppc_roas_target_goal = "N/A"] =
            pla_ppc_roas_target_goal_array;
        var pla_roas_target_goalInt = parseInt(pla_roas_target_goal, 10);
        var ppc_roas_target_goalInt = parseInt(ppc_roas_target_goal, 10);
    }
}
  //roas
  if (props?.column?.dataIndex == "roas") {
    if (recordData?.channel_type == "Product Ads") {
      if (pla_roas_target_goalInt == "N/A" || pla_roas_target_goalInt == 0) {
      } else if (recordData?.roas > pla_roas_target_goalInt * 1.5) {
        cellClass = "green-column"; //green_cell_fill
      } else if (
        recordData?.roas <= pla_roas_target_goalInt * 1.5 &&
        recordData?.roas > pla_roas_target_goalInt
      ) {
        cellClass = "light-green-column"; //light_green_cell_fill
      } else if (
        recordData?.roas <= pla_roas_target_goalInt &&
        recordData?.roas > pla_roas_target_goalInt * 0.5
      ) {
        cellClass = "light-yellow-column"; //light_yellow_cell_fill
      } else if (
        recordData?.roas <= pla_roas_target_goalInt * 0.5 &&
        recordData?.roas > 0
      ) {
        cellClass = "light-orange-column"; //light_orange_cell_fill
      } else {
        cellClass = "light-red-column"; //light_red_cell_fill
      }
    } else {
      if (ppc_roas_target_goalInt == "N/A" || ppc_roas_target_goalInt == 0) {
      } else if (recordData?.roas > ppc_roas_target_goalInt * 1.5) {
        cellClass = "green-column"; //green_cell_fill
      } else if (
        recordData?.roas <= ppc_roas_target_goalInt * 1.5 &&
        recordData?.roas > ppc_roas_target_goalInt
      ) {
        cellClass = "light-green-column"; //light_green_cell_fill
      } else if (
        recordData?.roas <= ppc_roas_target_goalInt &&
        recordData?.roas > ppc_roas_target_goalInt * 0.5
      ) {
        cellClass = "light-yellow-column"; //light_yellow_cell_fill
      } else if (
        recordData?.roas <= ppc_roas_target_goalInt * 0.5 &&
        recordData?.roas > 0
      ) {
        cellClass = "light-orange-column"; //light_orange_cell_fill
      } else {
        cellClass = "light-red-column"; //light_red_cell_fill
      }
    }
  }

  //   //adwords_cvr
  if (props?.column?.dataIndex == "cvr") {
    if (recordData?.cvr > cvr_avg * 1.5) {
      cellClass = "green-column"; // /green_cell_fill
    } else if (recordData?.cvr <= cvr_avg * 1.5 && recordData?.cvr > cvr_avg) {
      cellClass = "light-green-column"; //light_green_cell_fill
    } else if (recordData?.cvr <= cvr_avg && recordData?.cvr > cvr_avg * 0.5) {
      cellClass = "light-yellow-column"; //light_yellow_cell_fill
    } else if (recordData?.cvr <= cvr_avg * 0.5 && recordData?.cvr >= 0) {
      cellClass = "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  //   // var cpc_avg = 100;
  //cpc
  if (props?.column?.dataIndex == "cpc") {
    if (recordData?.cpc > cpc_avg * 1.5) {
      cellClass = "light-red-column"; //light_red_cell_fill
    } else if (recordData?.cpc <= cpc_avg * 1.5 && recordData?.cpc > cpc_avg) {
      cellClass = "light-yellow-column"; //light_yellow_cell_fill
    } else if (recordData?.cpc <= cpc_avg && recordData?.cpc > cpc_avg * 0.5) {
      cellClass = "light-green-column"; //light_green_cell_fill
    } else if (recordData?.cpc <= cpc_avg * 0.5 && recordData?.cpc > 0) {
      cellClass = "green-column"; //green_cell_fill
    } else {
      //nothing
    }
  }

  if (props?.column?.dataIndex == "search_term") {
    return (
      <>
        <a
          href={
            "https://trends.google.com/trends/explore?geo=US&q=" + props?.value
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tooltip
            placement="top"
            title={
              <>
                <FormattedNumberCell
                  value={capitalizedSearchTermType}
                  format={props?.column?.format}
                />
              </>
            }
          >
            {" "}
            <span className={cellClass}>
              <FormattedNumberCell
                value={props?.value}
                format={props?.column?.format}
              />
            </span>
          </Tooltip>
        </a>
      </>
    );
  } else {
    return (
      <>
        <span className={cellClass}>
          <FormattedNumberCell
            value={props?.value}
            format={props?.column?.format}
          />
        </span>
      </>
    );
  }
};

export default AdwordSearchTermPerformance;
