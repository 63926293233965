import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Switch,
  List,
  Typography,
  Spin,
  Row,
  Col,
  notification,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getUserDetail,
  createUpdateUser,
} from "../../Redux/Actions/Thunk/userThunk";
import { useDispatch, useSelector } from "react-redux";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiUsers, FiGrid } from "react-icons/fi";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const UserUpdate = (props) => {
  const [status, setStatus] = useState(true);
  const [isDecisionMaker, setIsDecisionMaker] = useState(false);
  const [isPrimaryContact, setIsPrimaryContact] = useState(false);
  const [monthlyEmailSubscribed, setMonthlyEmailSubscribed] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const {
    users: {
      user_detail: {
        data: userData = {},
        roles: allRoles = [],
        services: allServices = [],
        user_roles: userRoles = [],
        user_services: userServices = [],
        user_accounts: userAccounts = [],
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserDetail(id));
  }, [dispatch]);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      user_id: userData.id ? userData.id : 0,
      status: userData.status ? userData.status : 0,
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
      phone: userData.phone,
      mobile: userData.mobile,
      title: userData.title,
      role_id: userRoles,
      services: userServices,
      decision_maker: userData.decision_maker,
      primary_contact: userData.primary_contact,
      nlg_email_subscribed: userData.nlg_email_subscribed,
      send_password: 0,
    });
    setStatus(parseInt(userData.status) == 1);
    setIsDecisionMaker(parseInt(userData.decision_maker) == 1);
    setIsPrimaryContact(parseInt(userData.primary_contact) == 1);
    setMonthlyEmailSubscribed(parseInt(userData.nlg_email_subscribed) == 1);
  }, [userData]);

  const onFinish = (values) => {
    if (
      values.role_id.length > 1 &&
      (values.role_id.includes(6) || values.role_id.includes(9))
    ) {
      notification.error({
        message: "Validation Error",
        description:
          "You can not choose other roles when Customer or Non-managed Customer role is selected.",
      });
      return false;
    } else if (values.role_id.includes(9) && values.services.length == 0) {
      notification.error({
        message: "Validation Error",
        description:
          "At least one service must be selected for Non-Managed Customers.",
      });
      return false;
    } else {
      values = JSON.stringify(values);
      dispatch(createUpdateUser(values));
    }
  };

  const handleStatusChange = () => {
    setStatus((status) => !status);
  };

  const handleDecisionMakerChange = () => {
    setIsDecisionMaker((isDecisionMaker) => !isDecisionMaker);
  };

  const handlePrimaryContactChange = () => {
    setIsPrimaryContact((isPrimaryContact) => !isPrimaryContact);
  };

  const handleMonthlyEmailSubscribedChange = () => {
    setMonthlyEmailSubscribed(
      (monthlyEmailSubscribed) => !monthlyEmailSubscribed
    );
  };

  const handleResetPasswordChange = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({ password: "", confirm_password: "" });
    }
    form.setFieldsValue({ send_password: e.target.checked ? 1 : 0 });
    setResetPassword(e.target.checked);
  };

  var imsAccounts = userAccounts.filter((item) => item.role_id == 4);
  var specialistAccounts = userAccounts.filter((item) => item.role_id == 5);
  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Users", link: "/users", icon: FiUsers },
    {
      text:
        userData.first_name || userData.last_name
          ? userData.first_name + " " + userData.last_name
          : "New User",
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="panel-heading">
        <Row>
          <Col span={24} className="panel-heading-title">
            <h2>
              {userData.first_name || userData.last_name
                ? userData.first_name + " " + userData.last_name
                : "New User"}
            </h2>
          </Col>
        </Row>
      </div>

      <div className="panel-body">
        <div className="panel-body-content user-update-form-wrapper">
          <Spin spinning={isLoading}>
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={props.location.myCustomProps}
              scrollToFirstError
            >
              <Row>
                <Col
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                  className="user-update-single-wrapper"
                >
                  <div className="user-update-single">
                    <Form.Item hidden name="user_id">
                      <Input />
                    </Form.Item>
                    <Form.Item label="User Status" name="status">
                      <Switch
                        name="status"
                        checked={status}
                        onChange={handleStatusChange}
                        disabled={userRoles.includes(1) ? true : false}
                      />
                    </Form.Item>

                    <Form.Item
                      name="first_name"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="last_name"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item name="phone" label="Phone">
                      <Input type="text" />
                    </Form.Item>

                    <Form.Item name="mobile" label="Mobile">
                      <Input type="text" />
                    </Form.Item>
                  </div>
                  <div
                    className="user-update-single"
                    style={{ display: userRoles.includes(1) ? "none" : "" }}
                  >
                    <Form.Item
                      name="send_password"
                      label="Reset password"
                      valuePropName="checked"
                    >
                      <Checkbox
                        checked={resetPassword}
                        onChange={handleResetPasswordChange}
                      />{" "}
                      <strong>Generate password via email</strong>
                      <p>
                        If checked and submitted, an email will be sent to user
                        with a link to help him/her set their password.
                      </p>
                    </Form.Item>
                    <Tooltip title="Please create a password that is a minimum of 8 characters long and includes at least one lowercase letter, one uppercase letter, and one special character for enhanced security.">
                      <Form.Item name="password" label="Password" hasFeedback>
                        <Input.Password
                          autoComplete="off"
                          disabled={resetPassword}
                        />
                      </Form.Item>
                    </Tooltip>
                    <Form.Item
                      name="confirm_password"
                      label="Confirm Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        disabled={resetPassword}
                        autoComplete="off"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                  className="user-update-single-wrapper"
                  style={{ display: userRoles.includes(1) ? "none" : "" }}
                >
                  <div className="user-update-single">
                    <Form.Item
                      name="role_id"
                      label="Role"
                      style={{ display: userRoles.includes(1) ? "none" : "" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select Role!",
                        },
                      ]}
                    >
                      <Checkbox.Group options={allRoles} />
                    </Form.Item>
                  </div>
                  <div
                    className="user-update-single"
                    style={{
                      display:
                        userRoles.includes(6) || userRoles.includes(9)
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item label="Title" name="title">
                      <Input type="text" />
                    </Form.Item>

                    <Form.Item label="Decision Maker" name="decision_maker">
                      <Switch
                        name="decision_maker"
                        checked={isDecisionMaker}
                        onChange={handleDecisionMakerChange}
                      />
                    </Form.Item>
                    <Form.Item label="Primary Contact" name="primary_contact">
                      <Switch
                        name="primary_contact"
                        checked={isPrimaryContact}
                        onChange={handlePrimaryContactChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span>
                          Subscribe to Monthly
                          <br />
                          Email Report
                        </span>
                      }
                      name="nlg_email_subscribed"
                      style={{
                        display:
                          isPrimaryContact &&
                          (userRoles.includes(6) || userRoles.includes(9))
                            ? ""
                            : "none",
                      }}
                    >
                      <Switch
                        name="nlg_email_subscribed"
                        checked={monthlyEmailSubscribed}
                        onChange={handleMonthlyEmailSubscribedChange}
                      />
                    </Form.Item>
                  </div>
                  <div
                    className="user-update-single"
                    style={{
                      display:
                        userRoles.includes(6) || userRoles.includes(9)
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item name="services" label="Services">
                      <Select
                        placeholder="Please select services"
                        mode="multiple"
                      >
                        {allServices.map((service) => (
                          <Option
                            value={service.id}
                            id={service.id}
                            name={service.id}
                            key={service.id}
                          >
                            {service.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div
                    className="user-update-single user-accounts"
                    style={{
                      display:
                        userRoles.includes(4) || userRoles.includes(5)
                          ? ""
                          : "none",
                    }}
                  >
                    <Col xs={12} sm={5} style={{ textAlign: "right" }}>
                      <Typography.Text>Accounts :</Typography.Text>
                    </Col>
                    <Col
                      xs={24}
                      sm={18}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {userRoles.includes(4) && imsAccounts.length > 0 ? (
                        <List
                          header={<div>IMS</div>}
                          dataSource={imsAccounts}
                          renderItem={(account) => (
                            <List.Item>{account.name}</List.Item>
                          )}
                        />
                      ) : (
                        ""
                      )}
                      {userRoles.includes(5) &&
                      specialistAccounts.length > 0 ? (
                        <List
                          header={<div>Specialist</div>}
                          dataSource={specialistAccounts}
                          renderItem={(account) => (
                            <List.Item>{account.name}</List.Item>
                          )}
                        />
                      ) : (
                        ""
                      )}
                      {userAccounts.length == 0 ? (
                        <p>No accounts assigned yet</p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </div>
                </Col>
                <Col span={24} className="user-update-single-wrapper-button">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={userRoles.includes(1) ? true : false}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default UserUpdate;
