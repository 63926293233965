import React from "react";
import { Row, Col } from "antd";
import gradientLogo from "../../Assets/Images/agitalfooter.png";
import { FaFacebook, FaLinkedin, FaRegCopyright } from "react-icons/fa";
import twitter from "../../Assets/Images/twitter.png";

const GadsFooter = () => {
	return (
		<div className="agital-footer">
			<Row>
				<Col span={8}>
					<div className="footer-left">
						<div className="footer-logo">
							<a href="https://agital.com" target="_blank">
								<img src={gradientLogo} alt="footer logo" />
							</a>
						</div>
						<div className="footer-company">
							<h4>Agital</h4>
							<ul className="company-address">
								<li>One Wall Street</li>
								<li>5th Floor</li>
								<li>Burlington, MA, 01803</li>
							</ul>
							<ul className="company-contact">
								<li>
									<a href="tel:(339) 330-8884">(339) 330-8884</a>
								</li>
								<li>
									<a href="tel:hello@agital.com">hello@agital.com</a>
								</li>
							</ul>
							<div className="contact-btn">
								<a href="https://agital.com/contact/">Contact Us</a>
							</div>
							<ul className="company-social">
								<li>
									<a href="https://www.facebook.com/agital.official">
										<FaFacebook />
									</a>
								</li>
								<li>
									<a href="https://twitter.com/agital_official">
										<img src={twitter} alt="" />
									</a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/agital-official/">
										<FaLinkedin />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</Col>
				<Col span={16}>
					<div className="footer-right">
						<Row gutter={[30, 30]}>
							<Col span={8}>
								<div className="footer-navigation">
									<h4>
										<a href="https://agital.com/services/" target="">
											Services
										</a>
									</h4>
									<ul>
										<li>
											<a href="https://agital.com/services/paid-search/">
												Paid Search
											</a>
										</li>
										<li>
											<a href="https://agital.com/services/seo/">SEO</a>
										</li>
										<li>
											<a href="https://agital.com/services/social-media-marketing/">
												Social Media
											</a>
										</li>
										<li>
											<a href="https://agital.com/services/marketplaces/">
												Marketplaces
											</a>
										</li>
										<li>
											<a href="https://agital.com/services/conversion-rate-optimization/">
												Conversion Rate Optimization
											</a>
										</li>
									</ul>
								</div>
							</Col>
							<Col span={8}>
								<div className="footer-navigation">
									<h4>
										<a href="https://agital.com/results/" target="">
											Results
										</a>
									</h4>
									<ul>
										<li>
											<a href="https://agital.com/results/clients/">Clients</a>
										</li>
										<li>
											<a href="https://agital.com/results/case-studies/">
												Case Studies
											</a>
										</li>
										<li>
											<a href="https://agital.com/results/awards/">Awards</a>
										</li>
									</ul>
								</div>
							</Col>
							<Col span={8}>
								<div className="footer-navigation">
									<h4>
										<a href="https://agital.com/technology/" target="">
											Technology
										</a>
									</h4>
									<ul>
										<li>
											<a href="https://agital.com/technology/">Technology</a>
										</li>
									</ul>
								</div>
							</Col>
							<Col span={8}>
								<div className="footer-navigation">
									<h4>
										<a href="https://agital.com/about/" target="">
											About
										</a>
									</h4>
									<ul>
										<li>
											<a href="https://agital.com/about/who-we-are/">
												Who We Are
											</a>
										</li>
										<li>
											<a href="https://agital.com/about/join-our-team/">
												Join Our Team
											</a>
										</li>
										<li>
											<a href="https://agital.com/about/premium-partners/">
												Premium Partners
											</a>
										</li>
										<li>
											<a href="https://agital.com/about/in-the-news/">
												In the News
											</a>
										</li>
									</ul>
								</div>
							</Col>
							<Col span={8}>
								<div className="footer-navigation">
									<h4>
										<a href="https://agital.com/insights/" target="">
											Insights
										</a>
									</h4>
									<ul>
										<li>
											<a href="https://agital.com/insights/blog/">Blog</a>
										</li>
										<li>
											<a href="https://agital.com/insights/webinars/">
												Webinars
											</a>
										</li>
									</ul>
								</div>
							</Col>
							<Col span={8}>
								<div className="footer-navigation">
									<h4>Results</h4>
									<ul>
										<li>
											<a href="https://agital.com/seo-scorecard/">
												SEO Scorecard
											</a>
										</li>
										<li>
											<a href="https://agital.com/google-ads-scorecard/">
												Google Ads Scorecard
											</a>
										</li>
										<li>
											<a href="https://agital.com/ecommerce-analysis/">
												Ecommerce Analysis
											</a>
										</li>
									</ul>
								</div>
							</Col>
						</Row>
					</div>
				</Col>
				<Col span={24}>
					<div className="footer-copyright">
						<div className="footer-copyright-text">
							<p>
								<FaRegCopyright />
								{new Date().getFullYear()} Agital | All rights reserved
							</p>
						</div>
						<div className="footer-copyright-navigation">
							<ul>
								<li>
									<a href="https://agital.com/privacy-policy/">
										Privacy Policy
									</a>
								</li>
								|
								<li>
									<a href="https://agital.com/terms-of-service/">
										Terms of Service
									</a>
								</li>
							</ul>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default GadsFooter;
