import React, { useEffect, useRef, useState } from "react";
import {
	Row,
	Col,
	Tooltip,
	Button,
	Form,
	Switch,
	Modal,
	Spin,
	BackTop,
} from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Config from "../../Config";
import { getSessionData } from "../../Redux/Actions/Thunk/ColumnChartThunk";
import {
	getDevicePerformance,
	getTrendBreakdown,
	getTrendsData,
} from "../../Redux/Actions/Thunk/TrendThunk";
import { getClientId, getFreemiumClientAccount, getFreemiumClientId } from "../../Redux/Actions/Thunk/accountThunk";
import { getBarGraphData, getStackedBarGraphData } from "../../Redux/Actions/Thunk/barGraphThunk";
import { getNormalTable } from "../../Redux/Actions/Thunk/normalTableThunk";
import { getKeyMetrics } from "../../Redux/Actions/Thunk/reports";
import { formDataAppend } from "../../Utils/helpers";
import PerformanceReport from "../ReportBlocks/MultiBlockComponents/PerformanceReport";
import FreemiumComponent from "./FreemiumLayouts/FreemiumComponent";
import InputControlComponent from "./InputControlComponent";

import html2pdf from "html2pdf.js";
import moment from "moment";
import { FaAngleUp } from "react-icons/fa";
import { capitalizeFirstLetter } from "../../Utils/helpers";
import logo from "../../Assets/Images/logo.png";
import { getDataTable } from "../../Redux/Actions/Thunk/dataTableThunk";
import {
	getAllInputControls,
	getReportLayouts,
} from "../../Redux/Actions/Thunk/reportLayoutThunk";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import GlobalExport from "../Common/GlobalExport";
import Help from "../Common/Help";
import ReportComments from "../Common/ReportComments";
const {
	API: { authKey },
} = Config;

const FreemiumLayout = (props) => {
	// const { getPerformance } = useContext(MyContext);
	const [form] = Form.useForm();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [helpData, setHelpData] = useState("");
	const { account_id, report_key } = useParams();

	// const pathArray = window?.location?.pathname?.split("/");
	const [data, setData] = useState([]);
	// var report_name = pathArray[4];
	// var account_id_data = pathArray[5];
	let report_name = report_key === 'organic_search_scorecard' ? 'organic_search_scorecard_v2' : report_key;

	var account_id_data = account_id;
	var setValue = useRef(null);
	const {
		accounts: {
			client_id: { data: clientData = [] },
			accounts: { data: freemiumAcccount = [] },
			isLoading,
		},
		reportLayouts: {
			input_controls: { data_input_controls: inputControlsData = [] },
			layouts: {
				data: layoutData = [],
				data_layout_controls: dataLayout = [],
				layout_controls: controlLayout = [],
				layout_css: custom_report_css = [],
				// input_controls: inputControlsData = [],
			},
		},
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	// Fetch data
	let defaultFilterData = {};

	useEffect(() => {
		Promise.all([
			dispatch(
				getFreemiumClientId(
					{
						account_id: account_id_data, 
						report_key: report_name
					})
			),
			dispatch(
				getFreemiumClientAccount({account_id: account_id_data})
			)
		]);
	}, [account_id_data, report_name]);

	
	inputControlsData.forEach((element, i) => {
		
		defaultFilterData["authentication"] = authKey;
		defaultFilterData["Client"] = freemiumAcccount[0].id;
		defaultFilterData["account_id"] = account_id_data;
		defaultFilterData["company_name"] = freemiumAcccount[0].company;
		defaultFilterData['report_date'] = freemiumAcccount[0].created_at;

	});

	var formDatafilter = "";

	let filterData = {};
	if (Object.keys(data).length > 0) {
		var filteredData = data;
	} else {
		var filteredData = defaultFilterData;
	}


	const cstyle = {
		padding: "8px 0",
	};

	const blockLookup = new Map();
	for (let i = 0; i < dataLayout.length; i++) {
		const { block_id, component, ...rest } = dataLayout[i];
		blockLookup.set(`${block_id}_${component}`, {
			block_id,
			component,
			...rest,
		});
	}
	
	const new_rows = [];
	for (let i = 0; i < controlLayout.length; i++) {
		const { cols, ...rest } = controlLayout[i];
		const new_cols = [];
		for (let j = 0; j < cols.length; j++) {
			const { block_id, component, ...colRest } = cols[j];
			new_cols.push({
				...colRest,
				key: blockLookup.get(`${block_id}_${component}`) || {},
			});
		}
		new_rows.push({ ...rest, cols: new_cols });
	}
	return (
		<>
		{ layoutData.access_status==403 ? <Redirect to="/access-denied" /> : <></> }
		{ layoutData.access_status==undefined ? <></> :
			<>
			<style dangerouslySetInnerHTML={{ __html: custom_report_css }} />
				<Spin spinning={isLoading}>
				{/* block content starts here */}
				<div id="divToPrint">
				<div className="organic-scorecard-wrapper">
						<div className="organic-scorecard-content">
							<div className="organic-scorecard-header">
										<div className="os-logo">
											<img src={logo} alt="Logo" />
										</div>
							</div>
								
					{new_rows.map((row) => (
						<div id={row.id}>
							
							<div className="organic-scorecard-grade">
								<Row
									gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
									key={row.id + "-cols"}
								>
									{row.cols.map((col) => (
										<Col
											className="gutter-row"
											span={parseInt(col?.span)}
											style={{ display: "flex" }}
											id={col.id}
										>
											<div
												className="report-v2-panel-wrapper"
												style={{ width: "100%" }}
											>
												<div className="report-v2-panel-heading">
													<h2>
														{col?.title}
														{col?.tooltip !== undefined ? (
															<Tooltip
																title={col?.tooltip}
																overlayClassName="heading-tooltip"
															>
																<span>
																	<FaInfoCircle />
																</span>
															</Tooltip>
														) : (
															""
														)}
													</h2>
												</div>
												{/* <div className="report-v2-panel-body"> */}
													{FreemiumComponent(
														col.key,
														account_id_data,
														clientData,
														filteredData
													)}
												{/* </div> */}
											</div>
										</Col>
									))}
								</Row>
								</div>
							</div>
					))}
						<div className="organic-scorecard-footer">
						<p>
							<a href="#">Exclusive Concepts </a> | One Wall Street 5th Floor,
							Burlington, MA 01803 | <a href="#">Contact Us</a>
						</p>
					</div>
					</div>

					</div>
				</div>
				</Spin>
			<BackTop>
				<div>
					<FaAngleUp />
				</div>
			</BackTop>
			</>
    	}
		</>
	);
};

export default FreemiumLayout;
