import React from "react";

const Unauthorized = (props) => {
  return (
    <>
      <div className={`unauthorized-layout ${props.className}`}>
        <h2>{props.message}</h2>
      </div>
    </>
  );
};
export default Unauthorized;
