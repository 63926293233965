import React, {useState, useEffect} from "react";
import { Row, Col, Form } from "antd";
import google from "../../Assets/Images/googlelogo.png";
import partner from "../../Assets/Images/partner.png";
import GadsFooter from "./GadsFooter"
//import { GoogleAdsApi } from "google-ads-api";

const GadsRedirect = () => {
	const [customerList, setCustomerList] = useState({});
	let [refreshToken, setRefreshToken] = useState('');
	let [hasClient, setHasClient] = useState(true);
	useEffect(()=>{
		const client_id = process.env.REACT_APP_GADS_CLIENT_ID;
		const redirect_uri = process.env.REACT_APP_GADS_RETURN_URL;
		const client_secret = process.env.REACT_APP_GADS_CLIENT_SECRET;
		const developer_token = process.env.REACT_APP_GADS_DEVELOPER_TOKEN;
		let access_token = "";
		const code = new URLSearchParams(window.location.search).get('code');

		const select_customers = document.getElementById("customers");
		
		let params = {
			"code": code,
			"client_id": client_id,
			"client_secret": client_secret,
			"redirect_uri":redirect_uri,
			"grant_type":"authorization_code"
		}
		params = new URLSearchParams(Object.entries(params)).toString();
		const get_refresh_token = async () => {
			//getting access / refresh token
			const response = await fetch('https://oauth2.googleapis.com/token', {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			  },
			  body: params
			});
			const content = response.json();
			// console.log(content, content.access_token);
			return content;
		}
		get_refresh_token().then( async content => {
			access_token = content.access_token;
			setRefreshToken(content.refresh_token);
			const headers = {
				Authorization: `Bearer ${access_token}`,
				'developer-token': developer_token,
			};
			let api_version = process.env.REACT_APP_GOOGLE_API_VERSION;
			const resources = await fetch('https://googleads.googleapis.com/'+api_version+'/customers:listAccessibleCustomers', {
				method: 'GET',
				headers: headers,
			})
			// console.log(`resources :  ${resources}`);
			const statusCode = resources.status;
			if(statusCode==401){
				setHasClient(false);
				//alert("There is no Google Ads account associated with provided Google account.");
				return false;
			}
			const resources_json = resources.json();
			return resources_json;
		}).then( async resources_json => {
			if(Object.keys(resources_json).length==0){
				setHasClient(false);
				//alert("There is no Google Ads account associated with provided Google account.");
				return false;
			}
			// console.log(resources_json, typeof(resources_json));
			const headers = {
				Authorization: `Bearer ${access_token}`,
				'developer-token': developer_token,
			};
			const customers = resources_json['resourceNames'];
			/*if(customers==undefined){
				setHasClient(false);
				//alert("There is no Google Ads account associated with provided Google account.");
				return false;
			}*/
			customers.forEach( async row => {
				let arr = row.split("/");
				let customer_id = arr[1];
				let query = "Select customer.id, customer.descriptive_name from customer_client"
				let api_version = process.env.REACT_APP_GOOGLE_API_VERSION;
				const customer_detail = await fetch(`https://googleads.googleapis.com/${api_version}/customers/${customer_id}/googleAds:search`, {
					method: 'POST',
					headers: headers,
					body: JSON.stringify({query: query})
				})
				const customer_json = await customer_detail.json();
				// console.log(customer_json, customer_detail);
				customer_json.results.forEach(option => {
					// console.log("PS1", option, option.customer.descriptiveName)
					const newOption = document.createElement("option");
					newOption.text = option.customer.descriptiveName;
					newOption.value = option.customer.id;
					select_customers.appendChild(newOption);
				});
			});
		}).then(async data => {
			/*if(document.getElementById("customers").options.length==0){
				setHasClient(false);
				//alert("There is no Google Ads account associated with provided Google account.");
			}*/
			if(document.getElementById("customers").options.length==1){
				document.getElementById("select_account").click();
			}
		});
		/*
		###### Token Format #####
		{access_token: 'ya29.a0AfB_byApPdIOdRfXDBeJJV3Lk7GPe9NirnVa-6RymiV…xgaCgYKAXkSARASFQHsvYlsor_tA7lqb2Pw6NueCvhd-g0173', expires_in: 3599, refresh_token: '1//0ggDWmkryOs1UCgYIARAAGBASNwF-L9IrE5062R1ggVY7ls…8FOgJYX1tJakW4tTuUlaO8vJZznGgy_hj6JgUKS5j6rImiK4o', scope: 'https://www.googleapis.com/auth/adwords', token_type: 'Bearer'}
		###### Customer List Format #####
		{
			"resourceNames": [
				"customers/5161690284"
			]
		}
		##### Customer Detail Format #####
		{
			"results": [
				{
				"customer": {
					"resourceName": "customers/5161690284",
					"descriptiveName": "Greek Gear"
				},
				"customerClient": {
					"resourceName": "customers/5161690284/customerClients/5161690284"
				}
				}
			],
			"fieldMask": "customer.descriptiveName"
		}*/
	},[]);

	const selectAccountHandler = async () => {
		let e = document.getElementById("customers");
		let customer_id = document.getElementById('customers').value;
	    let customer_name = e.options[e.selectedIndex].text;
		let formData = new FormData();
		formData.append("customer_id", customer_id);
		formData.append("customer_name", customer_name);
		formData.append("refresh_token", refreshToken);
		formData.append("bi_token", localStorage.getItem('gads_access_token_from_bi'));
		formData.append("total_accounts", e.options.length);
		const url = process.env.REACT_APP_GADS_SAVE_REFRESH_TOKEN;
		const success_url = process.env.REACT_APP_GADS_SUCCESS_URL
		let response = await fetch(url,{
			body: formData,
			method: "POST"
		})
		.then( async (data) => {
			let json = await data.json();
			if(json.status=="error"){
				alert("Unable to complete signup. Please try again.");
			}else{
			localStorage.removeItem('gads_access_token_from_bi');
			window.location.replace(success_url);
			}
		});
	}

	return (
		<>
			<div className="gads-signup-wrapper">
				<div className="gadssw-single gadssw-upper">
					<h2>Select your Account for Google Ads Scorecard</h2>
					<p>
						Connect your Google Ads account. We use read-only view to analyze your
						Google Ads and deliver executive-level insights, opportunities, and
						improvements.
					</p>
					{ hasClient ?
					<><select id="customers" name="customers"></select> <button type="button" name="select_account" id="select_account" onClick={selectAccountHandler}>Select Account</button></>
					: <p style={{color:"#C00"}}>There is no Google Ads account associated with provided Google account.</p> }
				</div>
				<div className="gadssw-single gadssw-lower">
					<Row gutter={32}>
						<Col span={18} style={{ display: "flex", alignItems: "center" }}>
							<div className="gl-text">
								<h4>Your Privacy and Security is our Top Priority</h4>
								<p>
									Granting read-only access is always private and secure. For more
									than two decades, AGITAL has been committed to keeping your
									data safe and we reinforce that by being trusted by thousands of
									top ecommerce companies.
								</p>
							</div>
						</Col>
						<Col span={6}>
							<div className="gl-img">
								<img src={partner} alt="" />
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<GadsFooter />
		</>
	);
};

export default GadsRedirect;
