import React from "react";

import { FormattedNumberCell } from "../../Utils/helpers/formatting";
import { Tooltip, Tabs } from "antd";
import { FaInfoCircle } from "react-icons/fa";

const TrendsTypeTable = ({ tableData, channelLabels, props }) => {
  let accountId = props?.account_id;
  let eparams = props?.all_input_data;
  let f_date = eparams?.f_month;
  let t_date = eparams?.t_month;
  let f_date_compare = eparams?.c_f_month;
  let t_date_compare = eparams?.c_t_month;
  let date_range_option = eparams?.date_range_option;
  let date_range_compare_to = eparams?.date_range_compareto;
  let program = eparams?.program;
  let segment = eparams?.segment;
  let channel = eparams?.channel;
  let campaign = eparams?.campaign;
  let objective = eparams?.objective;
  const data = tableData;
  const columns = ["Key", ...channelLabels];
  console.log(columns);
  return (
    <div
      className="trends-datatable trends-datatable--noborder"
      style={{ marginBottom: "20px" }}
    >
      <table id="trends-table">
        <thead>
          <tr>
            {columns?.map((column, index) => {
              if (column === "Key") {
                return <th key={index}></th>;
              } else if (column === "Paid Search") {
                const url = `/reports/v2/view/paid_search_lead_gen_performance/${accountId}?f_month=${f_date}&t_month=${t_date}&date_range_option=${date_range_option}&date_range_compareto=${date_range_compare_to}&c_f_month=${f_date_compare}&c_t_month=${t_date_compare}&segment=${segment}&program=${program}&channel=${channel}&campaign=${campaign}&objective=${objective}`;
                return (
                  <th key={index}>
                    <a
                      href={url}
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                    >
                      {column}
                    </a>
                  </th>
                );
              }
              return <th key={index}>{column}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                {item?.value}
                {item && item?.tooltip != "" ? (
                  <Tooltip color="black" title={item?.tooltip}>
                    <span style={{ color: "#0e0e0b" }}>
                      <FaInfoCircle />
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
              </td>
              {channelLabels?.map((column, colIndex) => {
                const columnData = item?.data?.find((obj) => obj[column]);
                let value = columnData ? columnData[column] : 0;

                return (
                  <td key={colIndex}>
                    {" "}
                    <FormattedNumberCell
                      value={value}
                      format={item?.format}
                      metric={item?.value}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TrendsTypeTable;
