import { DataSourcesApiEndPoints } from "../../../Utils/constant";
import API_BI from "../../../Utils/axios_bi";

const {
  dsResetHistoricalDataConfig: { getDsResetHistoricalDataConfigUrl },
} = DataSourcesApiEndPoints;

/**
 * API function to get Amazon Sponsor Ads data
 */
export const getDsResetHistoricalDataConfigApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getDsResetHistoricalDataConfigUrl, props);
};
