export const initialState = {
  accounts: {},
  account_details: [],
  account_contacts: [],
  account_email_report_logs: [],
  account_notifications: [],
  account_advanced_setting: {},
  isLoading: false,
  reports: {},
  services: {},
  user_accounts: {},
  client_id: {},
  account_feeds_data: {}
};

export const ACTIONS_TYPES = Object.freeze({
  ACCOUNT_REQUEST: "ACCOUNT_REQUEST",
  FREEMIUM_ACCOUNT_REQUEST: "FREEMIUM_ACCOUNT_REQUEST",
  ACCOUNT_REPORTS: "ACCOUNT_REPORTS",
  FETCH_ACCOUNT: "FETCH_ACCOUNT",
  FETCH_IMS_USERS: "FETCH_IMS_USERS",
  FETCH_SPECIALIST_USERS: "FETCH_SPECIALIST_USERS",
  FETCH_SERVICES: "FETCH_SERVICES",
  FETCH_USER_ACCOUNTS: "FETCH_USER_ACCOUNTS",
  FETCH_ACCOUNT_DETAIL: "FETCH_ACCOUNT_DETAIL",
  FETCH_ACCOUNT_CONTACTS: "FETCH_ACCOUNT_CONTACTS",
  FETCH_ACCOUNT_EMAIL_REPORT_LOGS: "FETCH_ACCOUNT_EMAIL_REPORT_LOGS",
  FETCH_ACCOUNT_NOTIFICATIONS: "FETCH_ACCOUNT_NOTIFICATIONS",
  FETCH_ACCOUNT_REPORTS: "FETCH_ACCOUNT_REPORTS",
  FETCH_CLIENT_ID: "FETCH_CLIENT_ID",
  REQUEST_CLIENT_ID: "REQUEST_CLIENT_ID",
  FETCH_ACCOUNT_ADVANCED_SETTING: "FETCH_ACCOUNT_ADVANCED_SETTING",
  ACCOUNT_REPORT_REQUEST: "ACCOUNT_REPORT_REQUEST",
  SAVE_ACCOUNT_ADVANCED_SETTING: "SAVE_ACCOUNT_ADVANCED_SETTING",
  REQUEST_EXPORT: "REQUEST_EXPORT",
  SUCCESS_EXPORT: "SUCCESS_EXPORT",
  SF_IMPORT_REQUEST: "SF_IMPORT_REQUEST",
  SF_IMPORT_COMPLETE: "SF_IMPORT_COMPLETE",
  FETCH_FREEMIUM_SERVICES: "FETCH_FREEMIUM_SERVICES",
  FETCH_FREEMIUM_ACCOUNTS: "FETCH_FREEMIUM_ACCOUNTS",
  API_REQUEST_INIT: "API_REQUEST_INIT",
  API_REQUEST_COMPLETE: "API_REQUEST_COMPLETE",
  RESET_DATA: "RESET_DATA",
  ACCOUNT_SPEND_UPLOAD_REQUEST: "ACCOUNT_SPEND_UPLOAD_REQUEST",
  ACCOUNT_SPEND_UPLOAD_COMPLETE: "ACCOUNT_SPEND_UPLOAD_COMPLETE",
  ACCOUNT_SPEND_IMPORT_REQUEST: "ACCOUNT_SPEND_IMPORT_REQUEST",
  ACCOUNT_SPEND_IMPORT_COMPLETE: "ACCOUNT_SPEND_IMPORT_COMPLETE",
  ACCOUNT_FEEDS_UPLOAD_REQUEST: "ACCOUNT_FEEDS_UPLOAD_REQUEST",
  ACCOUNT_FEEDS_UPLOAD_COMPLETE: "ACCOUNT_FEEDS_UPLOAD_COMPLETE",
  ACCOUNT_FEEDS_URL_UPLOAD_REQUEST: "ACCOUNT_FEEDS_URL_UPLOAD_REQUEST",
  ACCOUNT_FEEDS_URL_UPLOAD_COMPLETE: "ACCOUNT_FEEDS_URL_UPLOAD_COMPLETE",
  ACCOUNT_FETCH_FEEDS_REQUEST: "ACCOUNT_FETCH_FEEDS_REQUEST",
  ACCOUNT_FETCH_FETCH_COMPLETE: "ACCOUNT_FETCH_FETCH_COMPLETE"
});

export function accountReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        accounts: {}
      };
    case ACTIONS_TYPES.FREEMIUM_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        accounts: {}
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT:
    case ACTIONS_TYPES.FETCH_FREEMIUM_ACCOUNTS:
      return {
        ...state,
        isLoading: false,
        accounts: action.payload,
      };
    case ACTIONS_TYPES.FETCH_IMS_USERS:
      return {
        ...state,
        isLoading: false,
        ims_users: action.payload,
      };
    case ACTIONS_TYPES.FETCH_SPECIALIST_USERS:
      return {
        ...state,
        isLoading: false,
        specialist_users: action.payload,
      };
    case ACTIONS_TYPES.FETCH_SERVICES:
      return {
        ...state,
        isLoading: false,
        services: action.payload,
      };
    case ACTIONS_TYPES.FETCH_FREEMIUM_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case ACTIONS_TYPES.FETCH_USER_ACCOUNTS:
      return {
        ...state,
        isLoading: false,
        user_accounts: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_DETAIL:
      return {
        ...state,
        isLoading: false,
        account_details: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_CONTACTS:
      return {
        ...state,
        isLoading: false,
        account_contacts: action.payload,
      };

    case ACTIONS_TYPES.FETCH_ACCOUNT_EMAIL_REPORT_LOGS:
      return {
        ...state,
        isLoading: false,
        account_email_report_logs: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_NOTIFICATIONS:
      return {
        ...state,
        isLoading: false,
        account_notifications: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_ADVANCED_SETTING:
      return {
        ...state,
        isLoading: false,
        account_advanced_setting: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_REPORTS:
      return {
        ...state,
        isLoading: false,
        reports: action.payload,
      };
    case ACTIONS_TYPES.REQUEST_CLIENT_ID:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_CLIENT_ID:
      return {
        ...state,
        isLoading: false,
        client_id: action.payload,
      };
    case ACTIONS_TYPES.ACCOUNT_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.SAVE_ACCOUNT_ADVANCED_SETTING:
      return {
        ...state,
        isLoading: false,
        account_advanced_setting: action.payload,
      };
    case ACTIONS_TYPES.REQUEST_EXPORT:
    case ACTIONS_TYPES.SF_IMPORT_REQUEST:
    case ACTIONS_TYPES.API_REQUEST_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.SUCCESS_EXPORT:
    case ACTIONS_TYPES.SF_IMPORT_COMPLETE:
    case ACTIONS_TYPES.API_REQUEST_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS_TYPES.RESET_DATA:
      return {
        account_details: [],
        isLoading: false,
      };
    case ACTIONS_TYPES.ACCOUNT_FETCH_FEEDS_REQUEST:
        return {
          ...state,
          isLoading: true
        };
    case ACTIONS_TYPES.ACCOUNT_FETCH_FETCH_COMPLETE:
        return {
          ...state,
          isLoading: false,
          account_feeds_data: action.payload,
        };
    default:
      return state;
  }
}
