import { ACTIONS_TYPES } from "../Reducers/accountReportReducer";

export function getSliderData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_SLIDER_DATA,
    payload,
  };
}

export function getHTML(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_HTML,
    payload,
  };
}
