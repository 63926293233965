import { Col, Progress, Row } from "antd";
import { useEffect } from "react";
import { formDataAppend } from "../../../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getBarGraphData } from "../../../../Redux/Actions/Thunk/barGraphThunk";

const AdInsights = (props) => {

	const dispatch = useDispatch();

	const {
		graph:{
			graph:{
				[props?.block?.block_id]: {
					bar_data: bar_data = []
				} = {},
			},
			isLoading,
		} = {},
	} = useSelector((state) => state);

	useEffect(()=>{

		let data = props?.all_input_data;
		let formData = formDataAppend(data);
		dispatch(getBarGraphData(formData, props));
	},[]);


	const top_left =  bar_data[0]?.top_left?.nlg.replace(/\$-/g, '-$');

	const top_right = bar_data[0]?.top_right?.nlg.replace(/\$-/g, '-$');

	const bottom_right = bar_data[0]?.bottom_right.nlg.replace(/\$-/g, '-$');

	return (
		<>
			<div className="gads-waste">
				<Row gutter={24}>
					<Col span={12}>
						<div className="gads-content-single gads-waste-single">
							<h6>SPEND WASTE</h6>
							<h4>Search Terms Ads</h4>
							<h2>{bar_data[0]?.top_left?.perc_waste}% Waste</h2>
							<Progress percent={bar_data[0]?.top_left?.perc_waste} showInfo={false} />
							<span className="progress-text">Spend</span>
							<p>
								<strong>{bar_data[0]?.top_left?.perc_waste}%</strong> <span dangerouslySetInnerHTML={{__html:top_left}}></span>
							</p>
							<p>
								<strong>Opportunity</strong>: {bar_data[0]?.top_left?.opportunity}
							</p>
						</div>
					</Col>
					<Col span={12}>
						<div className="gads-content-single gads-waste-single">
							<h6>SPEND WASTE</h6>
							<h4>Product Ads</h4>
							<h2>{bar_data[0]?.top_right?.perc_waste}% Waste</h2>
							<Progress percent={bar_data[0]?.top_right?.perc_waste} showInfo={false} />
							<span className="progress-text">Spend</span>
							<p>
								<strong>{bar_data[0]?.top_right?.perc_waste}% </strong><span dangerouslySetInnerHTML={{__html:top_right}}></span>
							</p>
							<p>
								<strong>Opportunity</strong>: {bar_data[0]?.top_right?.opportunity}
							</p>	
						</div>
					</Col>
				</Row>
			</div>
			<div className="gads-share">
				<Row gutter={24}>
					<Col span={12}>
						<div className="gads-content-single gads-share-single">
							<h6>PAID SEARCH</h6>
							<h4>Desktop Share</h4>
							<div className="gads-share-progres">
								<Progress percent={bar_data[0]?.bottom_left?.spend} showInfo={false} />
								<h2>{bar_data[0]?.bottom_left?.spend ? `${bar_data[0]?.bottom_left?.spend}%` :  `N/A`}</h2>
							</div>
							<span className="progress-text share-pt">Spend</span>
							<div className="gads-share-progres">
								<Progress percent={bar_data[0]?.bottom_left?.revenue} showInfo={false} />
								<h2>{bar_data[0]?.bottom_left?.revenue ? `${bar_data[0]?.bottom_left?.revenue}%` : `N/A`}</h2>
							</div>
							<span className="progress-text">Revenue</span>
							{bar_data[0]?.bottom_left?.revenue ?
								<div>
								<p>
									On average <strong>{bar_data[0]?.bottom_left?.spend}%</strong> of Google Ads revenue is
									from Brand defense, leaving only <strong>{bar_data[0]?.bottom_left?.revenue}%</strong> for
									Discovery and Conquesting.
								</p>
									<p>
									<strong>Opportunity:</strong> <span dangerouslySetInnerHTML={{__html:bar_data[0]?.bottom_left?.opportunity}}></span>
									</p>
								</div>
							    : <p>There was an error collecting device performance data.  Please connect with our team via the “Improve My Score” button.</p>		
								
							}
						</div>
					</Col>
					<Col span={12}>
						<div className="gads-content-single gads-share-single">
							<h6>BRAND TERM</h6>
							<h4>Share of Budget*</h4>
							<div className="gads-share-progres">
								<Progress percent={bar_data[0]?.bottom_right.spend} showInfo={false} />
								<h2>{bar_data[0]?.bottom_right.spend}%</h2>
							</div>
							<span className="progress-text share-pt">Spend</span>
							<div className="gads-share-progres">
								<Progress percent={bar_data[0]?.bottom_right.revenue} showInfo={false} />
								<h2>{bar_data[0]?.bottom_right.revenue}%</h2>
							</div>
							<span className="progress-text">Revenue</span>
							<p>
								<span dangerouslySetInnerHTML={{__html:bottom_right}}></span>
							</p>
							<p>
								<strong>Opportunity:</strong> <span dangerouslySetInnerHTML={{__html:bar_data[0]?.bottom_right.opportunity}}></span>
							</p>
						</div>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default AdInsights;