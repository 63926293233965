// reducers/tabReducer.js
import {
  SET_ACTIVE_TAB,
  SET_ACTIVE_LINE_CHART_TAB,
  SET_ACTIVE_TABLE,
} from "../Actions/tabAction";

const initialState = {
  activeTab: "Monthly",
  activeLineTab: "Monthly",
  emailSms: 0,
};

const tabReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_ACTIVE_LINE_CHART_TAB:
      return {
        ...state,
        activeLineTab: action.payload,
      };
    case SET_ACTIVE_TABLE:
      return {
        ...state,
        emailSms: action.payload,
      };
    default:
      return state;
  }
};

export default tabReducer;
