import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


function capitalize(string){
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const KlaviyoEmailPerformance = (props, text) => {
  let cellClass = "";
  let color = "";
  let recordData = props?.record;
  let blockId = props?.extraParams?.block?.block_id;
  if(props?.column?.dataIndex == 'segment_type'){
    return (
      <>
        <FormattedNumberCell
          value={ capitalize(props?.value) }
          format={props?.column?.format}
          metric={props?.column?.dataIndex}
        />
      </>
    )
  }else{
    return (
      <>
        <FormattedNumberCell
          value={props?.value}
          format={props?.column?.format}
          metric={props?.column?.dataIndex}
        />
      </>
    )
  }
};

export default KlaviyoEmailPerformance;
