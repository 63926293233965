/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaPencilAlt } from "react-icons/fa";
import { Tooltip } from "antd";
import Popover from "react-popover";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFacebookResponse } from "../../../Redux/Actions/Thunk/facebookTikTokThunk";
import { ACTIONS_TYPES } from "../../../Redux/Reducers/facebookTikTokReducer";

const ServiceCell = (props) => {
  const [getData, setData] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [jsIframe, setJsIframe] = useState("<span>Loading</span>");

  var ad_id = props?.record?.ad_id.toString();

  const {
    fb_tiktok_data: {
      facebook_data: { iframe: iframe = "", src: src = "" },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const content = (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: jsIframe,
        }}
      />
    </div>
  );

  const setPopoverContent = (ad_id) => {
    dispatch(getFacebookResponse({ ad_id }));
  };

  const handleMouseEnter = () => {
    //setPopoverContent(props?.record?.ad_id);
    setIsPopoverOpen(true);
    const version = process.env.REACT_APP_FB_PREVIEW_API_VERSION;
    const access_token = process.env.REACT_APP_FB_PREVIEW_ACCESS_TOKEN;
    fetch(
      `https://graph.facebook.com/${version}/${ad_id}/previews?ad_format=MOBILE_FEED_STANDARD&access_token=${access_token}`
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log("PS",data.data[0].body);
        setJsIframe(data?.data[0]?.body);
      })
      .catch((error) => {
        setJsIframe(
          '<div style="background-color: #fff; padding: 20px; border: 1px solid #ccc; width: 200px;">' +
            "<span>Ad Preview not available</span>" +
            "</div>"
        );

        console.error("Error fetching ad preview:", error);
      });
  };

  const handleMouseLeave = () => {
    setIsPopoverOpen(false);
  };

  const fb_tiktok_state = useSelector(
    (state) => state.fb_tiktok_data.facebook_data
  );

  return (
    <>
      {props.value != null && (
        <>
          <Popover
            isOpen={isPopoverOpen}
            body={content}
            place="right"
            preferPlace="right"
            tipSize={0.01}
            style={{ zIndex: "10" }}
          >
            <Link
              to={{
                pathname: "/facebook-ad-preview/" + ad_id,
              }}
              target="_blank"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
                metric={props.column.dataIndex}
              />
            </Link>
          </Popover>
        </>
      )}
    </>
  );
};

const SocialPerformance = (props) => {
  const columnIds = ["ad"];

  if (columnIds.includes(props.column.dataIndex)) {
    return <ServiceCell {...props} />;
  } else {
    return (
      <>
        <span>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
            metric={props.column.dataIndex}
          />
        </span>
      </>
    );
  }
};

export default SocialPerformance;
