import API_BI from "../Utils/axios_bi";
import API from "../Utils/axios";
import { ApiEndpointsBI } from "../Utils/constant";

const{FREEMIUM_UPLOAD} = ApiEndpointsBI;

export const getFreemiumUpload = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(FREEMIUM_UPLOAD, props);

}