/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { useState } from "react";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Modal, Spin } from "antd";
import { BiLineChart } from "react-icons/bi";
import Config from "../../../Config";
import HighchartsReact from "highcharts-react-official";
import columnChartView from "../../../Components/ReportBlocks/ColumnChartDrillDown";
import Highcharts from "highcharts";

const ServiceCell = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState("");
  const [getSubject, setSubject] = useState("");
  const [seriesData, setSeriesData] = useState("");
  const [categoriesData, setCategoriesData] = useState("");
  const [spinning, setSpinning] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const {
    API: { baseURL: baseUrl_API, authKey: authkey },
  } = Config;

  const getInputData = async (customer_id) => {
    try {
      var formdata = new FormData();
      formdata.append("authentication", authkey);
      formdata.append("service_level", "All");
      formdata.append("tier", "All");
      formdata.append("customer_id", customer_id);
      formdata.append("service", props?.record?.source);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      setSpinning(true);
      const response = await fetch(
        baseUrl_API + "v2/client_impact/pm/account_performance_chart",
        requestOptions
      );
      const result = await response.json();
      setSeriesData(result?.json_chart_data);
      setCategoriesData(result?.categories);
      setSpinning(false);
    } catch (err) {
      // Handle any errors
    } finally {
      // setLoading(false);
    }
  };

  const openChart = (customer_id) => {
    setIsModalOpen(true);
    getInputData(customer_id);
  };

  const chart = columnChartView(seriesData, categoriesData);

  var accountName = props?.record?.account_name;

  return (
    <>
      <span className="datatable-include-chart">
        <FormattedNumberCell value={props.value} format={props.column.format} />
        <span className="datatable-chart-trigger">
          <BiLineChart onClick={() => openChart(props?.record.customer_id)} />
        </span>
        {isModalOpen && (
          <Modal
            className="impact-modals"
            title={`${accountName} -  YEAR-OVER-YEAR`}
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width="80%"
            footer={null}
          >
            {
              <Spin spinning={spinning}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chart}
                ></HighchartsReact>
              </Spin>
            }
          </Modal>
        )}
      </span>
    </>
  );
};

const PaidMarketingClientImpact = (props) => {
  const columnIds = ["revhitgoal", "hitroasgoal", "hitrboth"];
  const chartColumn = ["account_name"];
  let cellClass = "";
  if (columnIds.includes(props.column.dataIndex)) {
    if (props.column.dataIndex == "hitrboth") {
      if (
        props.record.revhitgoal == "Yes" &&
        props.record.hitroasgoal == "Yes"
      ) {
        cellClass = "green-column";
      } else if (
        (props.record.revhitgoal == "No" || props.record.revhitgoal == "N/A") &&
        (props.record.hitroasgoal == "No" || props.record.hitroasgoal == "N/A")
      ) {
        cellClass = "light-red-column";
      } else {
        cellClass = "yellow-column";
      }
    } else {
      if (props.value == "Yes") {
        cellClass = "green-column";
      } else if (props.value == "No") {
        cellClass = "light-red-column";
      }
    }
  }
  if (chartColumn.includes(props.column.dataIndex)) {
    return <ServiceCell {...props} />;
  }
  return (
    <span className={cellClass}>
      <FormattedNumberCell value={props.value} format={props.column.format} />
    </span>
  );
};

export default PaidMarketingClientImpact;
