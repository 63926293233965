

  /**
 * @fileoverview Reducer for Paid Opportunity Data Sources
 *
 * The reducer handles the state changes for the Google.
 */

// Define the initial state of the reducer
export const initialState = {
    account_ds: {}, // for single account data source
    account_dss: {}, // forall  account data sources . i used dss because of data sources . note "s" at the end, it is plural
    error_ds: {}, // error data sources
    isLoading: false, // loading state
  };
  
  // Define action types to be used in the reducer
  export const ACTIONS_TYPES = Object.freeze({
    EMAIL_MARKETING_GOOGLE_SHEET_DS_REQUEST: "EMAIL_MARKETING_GOOGLE_SHEET_DS_REQUEST",
    GET_EMAIL_MARKETING_GOOGLE_SHEET_DS: "GET_EMAIL_MARKETING_GOOGLE_SHEET_DS", // Fetch Account Data Sources
    CREATE_EMAIL_MARKETING_GOOGLE_SHEET: "CREATE_EMAIL_MARKETING_GOOGLE_SHEET", // Create Account Data Sources
    UPDATE_EMAIL_MARKETING_GOOGLE_SHEET: "UPDATE_EMAIL_MARKETING_GOOGLE_SHEET", // Update Account Data Sources
    EMAIL_MARKETING_GOOGLE_SHEET_ERROR_DS: "EMAIL_MARKETING_GOOGLE_SHEET_ERROR_DS", // Error Account Data Sources
    FAILED_EMAIL_MARKETING_GOOGLE_SHEET: "FAILED_EMAIL_MARKETING_GOOGLE_SHEET",
    RESET_EMAIL_MARKETING_GOOGLE_SHEET: "RESET_EMAIL_MARKETING_GOOGLE_SHEET",
  });
  
  // Define the reducer function
  export function emailMarketingGoogleSheetReducer(state = initialState, action = {}) {
    const { type, payload } = action;
  
    // Switch case to handle different types of actions
    switch (type) {
      case ACTIONS_TYPES.EMAIL_MARKETING_GOOGLE_SHEET_DS_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case ACTIONS_TYPES.GET_EMAIL_MARKETING_GOOGLE_SHEET_DS:
        return {
          ...state,
          isLoading: false,
          account_ds: action.payload,
        };
      case ACTIONS_TYPES.GET_ALL_GOOGL_CONSOLES_DS:
        return {
          ...state,
          isLoading: false,
          account_dss: action.payload,
        };
  
      case ACTIONS_TYPES.EMAIL_MARKETING_GOOGLE_SHEET_ERROR_DS:
      case ACTIONS_TYPES.FAILED_EMAIL_MARKETING_GOOGLE_SHEET:
        return {
          ...state,
          isLoading: false,
          error_ds: payload,
        };
  
      case ACTIONS_TYPES.RESET_EMAIL_MARKETING_GOOGLE_SHEET:
        return {
          ...state,
          isLoading: false,
          account_ds: {},
          account_dss: {},
          error_ds: {},
        };
  
      // Return the initial state if no matching action is found
      default:
        return state;
    }
  }
  