import { ACTIONS_TYPES } from "../../../Reducers/datasources/amazonSponserAdsReducer";

import * as AmazonSponsorAdsDataSourcesApi from "../../../../Api/datasources/amazonSponserAdsApi";
import * as amazonSponsorAdsAction from "../../datasources/amazonSponsorAdsAction";
import { setLoadingFalse, setLoadingTrue } from "../../globals/loadingThunk";
import successHandler from "../../../../Utils/Request/successHandler";
import errorHandler from "../../../../Utils/Request/errorHandler";

/**
 * Fetches current account data sources.
 * @param {string} region - The region to fetch data from.
 * @returns {Function} - The redux thunk.
 */
export const getAmazonSponsorAdsAccountDataSources = (region, props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.AMAZON_SPONSOR_ACCOUNT_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      dispatch({
        type: ACTIONS_TYPES.RESET_AMAZON_SPONSOR_ACCOUNT_DS,
      });

      const { data } =
        await AmazonSponsorAdsDataSourcesApi.getAmazonSponsorAdsData(
          region,
          props
        );

      dispatch(
        amazonSponsorAdsAction.getAmazonSponsorAdsDataSource({
          ...data.data,
          region,
        })
      );
    } catch (error) {
      // console.error(`Error in fetching account data sources: ${error}`);
      // return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
};

/**
 * Create a new account data source
 * @param {object} props - a payload to pass to the API
 * @param {string} region - the region for which to create the data source
 * @returns {Function} dispatch
 */
export const createAmazonSponsorAdsDataSource =
  (props, region) => async (dispatch) => {
    try {
      dispatch({ type: ACTIONS_TYPES.AMAZON_SPONSOR_ACCOUNT_DS_REQUEST });
      dispatch(setLoadingTrue());

      const { data } =
        await AmazonSponsorAdsDataSourcesApi.createAmazonSponsorAdsData(
          props,
          region
        );

      // Notify user
      successHandler(
        { data },
        {
          notifyOnSuccess: true,
          notifyOnFailed: true,
        }
      );

      dispatch(
        amazonSponsorAdsAction.createAmazonSponsorAdsDataSource({
          ...data?.data,
          region,
        })
      );
    } catch (error) {
      const errMessage = error?.response?.data?.error?.message;

      dispatch(
        amazonSponsorAdsAction.failedAmazonSponsorAdsDataSource(errMessage)
      );

      return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };

/**
 * Update an Amazon Sponsor Ads data source
 * @param {object} props - a payload to pass to the API
 * @param {string} region - the region to update the data source in
 * @returns {Function} dispatch
 */
export const updateAmazonSponsorAdsDataSource = (props, region) => {
  const { starTableData } = props;
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.AMAZON_SPONSOR_ACCOUNT_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      delete props.starTableData;
      const response =
        await AmazonSponsorAdsDataSourcesApi.updateAmazonSponsorAdsData(
          props,
          region
        );

      // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: false,
      });

      dispatch(
        amazonSponsorAdsAction.updateAmazonSponsorAdsDataSource({
          ...response?.data?.data,
          region,
        })
      );
    } catch (error) {
      const errMessage = error?.response?.data?.error?.message;
      dispatch(
        amazonSponsorAdsAction.failedAmazonSponsorAdsDataSource(errMessage)
      );
      // dispatch(setAlert({ message: errMessage, type: "error" }));
      errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
      dispatch(getAmazonSponsorAdsAccountDataSources(region, starTableData));
    }
  };
};

/**
 * Update an Amazon Sponsor Ads data source
 * @param {object} props - a payload to pass to the API
 * @param {string} region - the region to update the data source in
 * @returns {Function} dispatch
 */
export const updateAmazonModelDataSourceForEUAndFE = (
  props,
  setIsChecked,
  isChecked
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.AMAZON_SPONSOR_ACCOUNT_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      const response =
        await AmazonSponsorAdsDataSourcesApi.updateAmazonSponsorAdsModelData(
          props
        );
      setIsChecked(!isChecked);
      // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: false,
      });
    } catch (error) {
      setIsChecked(isChecked);
      const errMessage = error?.response?.data?.error?.message;
      dispatch(
        amazonSponsorAdsAction.failedAmazonSponsorAdsDataSource(errMessage)
      );
      // dispatch(setAlert({ message: errMessage, type: "error" }));
      errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
      // dispatch(getAmazonSponsorAdsAccountDataSources(region, starTableData));
    }
  };
};
