import { ACTIONS_TYPES } from "../../Reducers/datasources/amazonSponserAdsReducer";

export function getAmazonSponsorAdsDataSource(payload) {
  return {
    type: ACTIONS_TYPES.GET_AMAZON_SPONSOR_ACCOUNT_DS,
    payload,
  };
}

export function createAmazonSponsorAdsDataSource(payload) {
  return {
    type: ACTIONS_TYPES.CREATE_AMAZON_SPONSOR_ACCOUNT_DS,
    payload,
  };
}

export function updateAmazonSponsorAdsDataSource(payload) {
  return {
    type: ACTIONS_TYPES.UPDATE_AMAZON_SPONSOR_ACCOUNT_DS,
    payload,
  };
}

export function failedAmazonSponsorAdsDataSource(payload) {
  return {
    type: ACTIONS_TYPES.FAILED_AMAZON_SPONSOR_ACCOUNT_DS,
    payload,
  };
}
