import * as ChannelPerformanceApi from "../../../Api/ChannelPerformanceApi";
import * as channelPerformanceAction from "../channelPerformanceAction";
import { ACTIONS_TYPES } from "../../Reducers/channelPerformanceReducer";
import Config from "../../../Config";

const {
  API: { baseURL, authKey },
} = Config;

export const getChannelPerformanceData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.RESET_CHANNEL_PERFORMANCE,
        payload: data?.block?.block_id,
      });
      dispatch({
        type: ACTIONS_TYPES.GET_CHANNEL_PERFORMANCE,
      });
      let channel_performance = {};
      const response = await ChannelPerformanceApi.getChannelPerformanceData(props, data);
      if (response?.status === 200) {
        channel_performance = {
          [data?.block?.block_id]: {
            data: response?.data?.response_data,
          },
        };
      }
      return dispatch(channelPerformanceAction.fetchChannelPerformanceData(channel_performance));
    } catch (error) {
      console.error("Error in channel table: " + error);
    } finally {
    }
  };
};
