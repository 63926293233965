import API_BI from "../Utils/axios_bi";
import { ApiEndpointsBI } from "../Utils/constant";
import createJWTToken from "../Utils/helpers/jwt";

const {
  ACCOUNTS,
  GET_ACCOUNT_REPORTS,
  GET_IMS_USERS,
  GET_SPECIALIST_USERS,
  GET_SERVICES,
  ACCOUNT_DETAIL,
  ACCOUNT_CONTACTS,
  ACCOUNT_EMAIL_REPORT_LOG,
  ACCOUNT_NOTIFICATIONS,
  GET_CLIENT_ID,
  ACCOUNT_ADVANCED_SETTING,
  SAVE_ACCOUNT_ADVANCED_SETTING,
  GET_CONTRACTED_SERVICES,
  GET_FREEMIUM_SERVICES,
  GET_FREEMIUM_ACCOUNTS,
  GET_FREEMIUM_ACCOUNT,
  FREEMIUM_ACCOUNT_DETAIL,
  GET_USER_ACCOUNTS,
  ACCOUNT_FEEDS_FETCH,
} = ApiEndpointsBI;

export const getAccounts = async (props, params) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  if (params !== "") {
    return await API_BI.post(`${ACCOUNTS}?${params}`, props);
  } else {
    return await API_BI.post(ACCOUNTS, props);
  }
};

export const getFreemiumAccounts = async (props, params) => {

  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;

  if(params !== ""){
    return await API_BI.post(`${GET_FREEMIUM_ACCOUNTS}?${params}`, props);
  }else {
    return await API_BI.post(GET_FREEMIUM_ACCOUNTS, props);
  }
}

export const getFreemiumAccount = async(params) => {
  
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;

  if(params !== ""){
    return await API_BI.post(`${GET_FREEMIUM_ACCOUNT}`, params);
  }
}

export const getIMSUsers = async () => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(GET_IMS_USERS, {});
};

export const getSpecialistUsers = async () => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(GET_SPECIALIST_USERS, {});
};

export const getServices = async () => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(GET_SERVICES, {});
};

export const getAccountDetail = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(ACCOUNT_DETAIL, props);
};

export const getFreemiumAccountDetail = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(FREEMIUM_ACCOUNT_DETAIL, props);
};

export const getAccountContacts = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(ACCOUNT_CONTACTS, props);
};

export const getAccountEmailReportLogs = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(ACCOUNT_EMAIL_REPORT_LOG, props);
};

export const getAccountNotifications = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(ACCOUNT_NOTIFICATIONS, props);
};

export const getContractedServices = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  if (props !== "") {
    return await API_BI.post(GET_CONTRACTED_SERVICES, props);
  } else {
    return await API_BI.post(GET_CONTRACTED_SERVICES, {});
  }
};

export const getUserAccounts = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  if (props !== "") {
    return await API_BI.post(GET_USER_ACCOUNTS, props);
  } else {
    return await API_BI.post(GET_USER_ACCOUNTS, {});
  }
};

export const getAccountReports = async (props, params) => {
  const data = { account_id: props.account_id, service_id: props.service_id };
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  if (params !== "") {
    return await API_BI.post(`${GET_ACCOUNT_REPORTS}`, props);
  } else {
    return await API_BI.post(GET_ACCOUNT_REPORTS, {
      account_id: props.account_id,
      service_id: props.service_id,
    });
  }
};

export const getClientId = async (props) => {
  let token = localStorage.getItem("token");
  
  if(!token){
    const payload = {
      user_id: 1,
      username: 'public_user',
    };

    const secretKey = process.env.REACT_APP_SECRET_KEY; // Replace with your actual secret key

    // Generate the JWT token
    token = await createJWTToken(payload, secretKey);
  }

  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(GET_CLIENT_ID, props);
};

export const getAccountAdvancedSetting = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(ACCOUNT_ADVANCED_SETTING, props);
};

export const saveAccountAdvancedSetting = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(SAVE_ACCOUNT_ADVANCED_SETTING, props);
};

export const getFreemiumServices = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(GET_FREEMIUM_SERVICES, {});
};

export const getAccountFeedsUrl = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(ACCOUNT_FEEDS_FETCH, props);
};