import { ACTIONS_TYPES } from "../../../Reducers/datasources/ds_config/pinterestMccReducer";

export function getPinterestMCCAction(payload) {
  return {
    type: ACTIONS_TYPES.GET_PINTEREST_MCC_DS,
    payload,
  };
}


export function getAllPinterestMCCAction(payload) {
  return {
    type: ACTIONS_TYPES.GET_ALL_PINTEREST_DS,
    payload,
  };
}