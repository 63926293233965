/**
 * @fileoverview Reducer for Amazon Fullfillment Type
 *
 * The reducer handles the state changes for the Amazon Fullfillment Type.
 */

// Define the initial state of the reducer
export const initialState = {
  accounts_ds: {}, // account data sources
  error_ds: {}, // error data sources
  isLoading: false, // loading state
};




// Define action types to be used in the reducer
export const ACTIONS_TYPES = Object.freeze({
  AMAZON_FULLFILLMENT_TYPE_ACCOUNT_DS_REQUEST:
    "AMAZON_FULLFILLMENT_TYPE_ACCOUNT_DS_REQUEST",
  GET_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
    "GET_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS", // Fetch Account Data Sources
  CREATE_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
    "CREATE_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS", // Create Account Data Sources
  UPDATE_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
    "UPDATE_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS", // Update Account Data Sources
  AMAZON_AMAZON_FULLFILLMENT_TYPE_ERROR_DS:
    "AMAZON_AMAZON_FULLFILLMENT_TYPE_ERROR_DS", // Error Account Data Sources
  FAILED_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
    "FAILED_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS",
  RESET_AMAZON_FULFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
    "RESET_AMAZON_FULFILLMENT_TYPE_SERVICE_ACCOUNT_DS",
});

// Define the reducer function
export function amazonFullfillmentTypeReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;

  // Switch case to handle different types of actions
  switch (type) {
    case ACTIONS_TYPES.AMAZON_FULLFILLMENT_TYPE_ACCOUNT_DS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
    case ACTIONS_TYPES.CREATE_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
    case ACTIONS_TYPES.UPDATE_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
      return {
        ...state,
        isLoading: false,
        accounts_ds: {
          ...state.accounts_ds,
          ...payload,
        },
      };
    case ACTIONS_TYPES.RESET_AMAZON_FULFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
      return {
        ...state,
        isLoading: false,
        accounts_ds: {},
      };

    case ACTIONS_TYPES.AMAZON_AMAZON_FULLFILLMENT_TYPE_ERROR_DS:
    case ACTIONS_TYPES.FAILED_AMAZON_FULLFILLMENT_TYPE_SERVICE_ACCOUNT_DS:
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };

    // Return the initial state if no matching action is found
    default:
      return state;
  }
}
