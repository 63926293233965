/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell, numberWithCommas } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaPencilAlt } from "react-icons/fa";
import { Tooltip } from "antd";

const ServiceCell = (props) => {
  return (
    <>
      {props.value != null && (
        <>
          <strong>
            <FormattedNumberCell
              value={props.value}
              format={props.column.format}
            />
          </strong>
          &nbsp; &nbsp;
          <Link
            to={"/account/detail/" + props.record.custom_id + "?service_id=4"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaPencilAlt />
          </Link>
        </>
      )}
    </>
  );
};

function endsWithDigit(value, digit) {
  return value.toString().endsWith(digit.toString());
}

const ProductAdsDailySpend = (props) => {
  let record_data = props?.record;
  let children_data = props?.record?.children;
  const columnIds = ["name"];
  const column = ["specialistname"];

  if (
    columnIds.includes(props.column.dataIndex) &&
    props?.record?.hasOwnProperty("children")
  ) {
    return <ServiceCell {...props} />;
  } else if (
    column.includes(props.column.dataIndex) &&
    !props?.record?.hasOwnProperty("children")
  ) {
    return (
      <>
        {props.value != null && (
          <>
            <Link
              to={
                "/reports/v2/view/account_manager_dashboard?specialist=" +
                props.record.specialistname
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </Link>
          </>
        )}
      </>
    );
  } else if (
    (props?.value == "No Data" || props?.value == "Critical") &&
    !props?.record?.hasOwnProperty("children")
  ) {
    return (
      <>
        <span style={{ color: "red" }}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </>
    );
  }else if (props?.column?.dataIndex == "budget") {
    return (
      <>
        <Tooltip title={"Ad Spend MTD: " + "$" +numberWithCommas(record_data["spend"])}>
          <span>
            <FormattedNumberCell
              value={props.value}
              format={props.column.format}
            />
          </span>
        </Tooltip>
      </>
    );
  }else if (props?.value == "Yes") {
    return (
      <>
        <Tooltip title={record_data["combined_services_name"]}>
          <span>
            <FormattedNumberCell
              value={props.value}
              format={props.column.format}
            />
          </span>
        </Tooltip>
      </>
    );
  } else {
    return (
      <>
        {props?.record?.hasOwnProperty("children") ? (
          <strong>
            <span>
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </span>
          </strong>
        ) : (
          <span>
            <FormattedNumberCell
              value={props.value}
              format={props.column.format}
            />
          </span>
        )}
      </>
    );
  }
};

export default ProductAdsDailySpend;
