import { useDispatch } from "react-redux";
import semrush from "../../../Assets/Images/semrush.png";
import {
	FaFile,
	FaCubes,
	FaQuoteLeft,
	FaEyeSlash,
	FaDownload,
} from "react-icons/fa";
import { ACTIONS_TYPES } from "../../../Redux/Reducers/accountReducer";
import html2pdf from "html2pdf.js";
import { Button } from "antd";
const OrganicFooterChart = (props) =>{

    const dispatch = useDispatch();

	const filename = window.location.pathname.split('/')[4];

    function generatePdf() {
		dispatch({
			type: ACTIONS_TYPES.REQUEST_EXPORT,
		});

		window.scrollTo(0, 0);

		const divToPrint = document.querySelector("#divToPrint");
		const width = divToPrint.offsetWidth;
		
		const originalHeight = divToPrint.offsetHeight; // Get the original height of the content
		
		const additionalHeight = 3000; // Adjust this value to increase the height

        const height = originalHeight + additionalHeight; // Calculate the new height

		const margin = 40; // Adjust the margin value as needed
		const opt = {
			margin: [40, 40, 40, 40], // Set margin values for top, right, bottom, left
			filename: filename,
			image: { type: "jpeg", quality: 1 },
			html2canvas: {
				scale: 2, // Increase scale value for better quality
				logging: true,
				scrollX: -window.scrollX,
				scrollY: -window.scrollY,
				useCORS: true,
				windowWidth: document.documentElement.offsetWidth,
				windowHeight: height + margin * 2,
			},
			jsPDF: {
				unit: "px",
				format: [width + margin * 2, height + margin * 2],
				orientation: "portrait",
			},
		};

	

		const clonedDivToPrint = divToPrint.cloneNode(true);
		

		html2pdf()
			.set(opt)
			.from(clonedDivToPrint)
			.save()
			.then(() => {
				dispatch({
					type: ACTIONS_TYPES.SUCCESS_EXPORT,
				});
			})
			.catch((error) => {
				console.error("Failed to generate PDF:", error);
			});
	}

    return(
        <>
		{
		(props.block.report_key == 'google_ads_scorecard') 
		  ? <><div className="gads-download">
					<Button onClick={generatePdf}>
						       Download scorecard as a PDF
						</Button>
					</div>

						<div className="gads-privacy">
							<p>
								Agital will only use your personal information and Google Ads
								credentials to analyze your account. We will not share your
								Google Ads data for any reasons{" "}
								<a href="https://agital.com/privacy-policy/" target="_blank">
									(see our Privacy Policy)
								</a>
								. By running this report you agree to our key terms and
								conditions.
							</p>
						</div>
						</>
						: 
						<><div className="organic-scorecard-buttons">
						<button onClick={generatePdf}>
							Download PDF <FaDownload />
						</button>
						<span>
							Powered by <img src={semrush} alt="semrush" />
						</span>
					</div>
					</>
}
        </>
    )
}

export default OrganicFooterChart;