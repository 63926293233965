import * as keyMetricsApi from "../../../Api/keyMetricsApi";
import * as reportAction from "../reportAction";
import {
  FETCH_KEY_METRICS,
  FETCH_LABELS,
  FETCH_PERFORMACE_METRICS,
} from "../types/index";
import { ACTIONS_TYPES } from "../../Reducers/reportReducer";

export const getKeyMetrics = (data, props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.RESET_KEY_METRICS,
        payload: props?.block?.block_id,
      });
      dispatch({
        type: ACTIONS_TYPES.REQUEST_KEY_METRICS,
        payload: props?.block?.block_id,
      });
      let key_metrics = [];
      const response = await keyMetricsApi.getKeyMetrics(props, data);
      if (response?.data?.status == 200) {
        key_metrics = {
          [props?.block?.block_id]: {
            data: response?.data?.data,
            labels: response?.data?.labels,
            insights: response?.data?.insights_data,
            ga_insights_data: response?.data?.ga_insight,
            //last_order_date: response?.data?.last_order_date,
          },
        };
        return dispatch(
          reportAction.getKeyMetrics(props?.block?.block_id, key_metrics)
        );
      }
    } catch (error) {
      console.error("Error in fetching key metrics api " + error);
    } finally {
    }
  };
};

export const  getScoreCard = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.REQUEST_KEY_METRICS,
      });

      let keyMetrics = [];
      const response = await keyMetricsApi.getScoreCardMetrics(data, props);

      if (response?.data?.status == 200) {
        keyMetrics = {
          data: response?.data?.data,
        };
      }

      return dispatch(reportAction.getScoreCardMetrics(keyMetrics));
    } catch (error) {
      console.error("Error in fetching seo key metrics api" + error);
    }
  };
};

export const getPerformanceMetrics = (props, data) => {
  return async (dispatch) => {
    dispatch({
      type: ACTIONS_TYPES.REQUEST_KEY_METRICS,
    });

    let performanceMetrics = [];
    const response = await keyMetricsApi.getKeyMetrics(props, data);

    if (response?.data?.status == 200) {
      performanceMetrics = {
        data: response?.data?.data,
        nlg: response?.data?.nlg,
      };
    }

    return dispatch(reportAction.getPerformanceMetrics(performanceMetrics));
  };
};
