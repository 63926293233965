export const addSorters =(columns) => {
    return columns.map(column => {
      if (!column.children) {
        return {
          ...column,
          sorter: (a, b) => column.format ? a[column.key]- b[column.key] : a[column.key]?.localeCompare(b[column.key])
        };
      }
  
      return {
        ...column,
        children: addSorters(column.children)
      };
    });
  }
  