import * as serviceSettingsApi from "../../../Api/serviceSettingsApi";
import * as serviceSettingsAction from "../serviceSettingsAction";
import { ACTIONS_TYPES } from "../../Reducers/serviceSettingsReducer";
import { notification } from "antd";

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const getServiceSettings = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.REQUEST_SERVICE_SETTINGS,
      });
      let settings = {};
      const response = await serviceSettingsApi.getServiceSettings(props, data);
      if (response?.status == 200) {
        settings = {
          service_info: response?.data?.service_info,
          setting: response?.data?.setting,
          default_setting: response?.data?.default_setting,
          budget_goal_setting: response?.data?.budget_goal_setting,
          budget_goal_master_service:
            response?.data?.budget_goal_master_service,
          budget_goal_combined_services:
            response?.data?.budget_goal_combined_services,
          service_fees_editable: response?.data?.service_fees_editable,
          service_fees_visible: response?.data?.service_fees_visible,
        };
      }
      dispatch({
        type: ACTIONS_TYPES.GET_SERVICE_SETTINGS,
        payload: settings,
      });
      return dispatch(serviceSettingsAction.getServiceSettings(settings));
    } catch (error) {
      console.error("Error in fecthing service settings: " + error);
    } finally {
    }
  };
};

export const saveServiceSettings = (props) => {
  let propsObj = JSON.parse(props);
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.REQUEST_SERVICE_SETTINGS,
      });
      let serviceSettings = {};
      const response = await serviceSettingsApi.saveServiceSettings(propsObj);
      if (response?.data?.data) {
        serviceSettings = { data: response?.data?.data };
      }
      dispatch({
        type: ACTIONS_TYPES.SAVE_SERVICE_SETTINGS,
        payload: serviceSettings,
      });
      return dispatch(
        serviceSettingsAction.saveServiceSettings(serviceSettings)
      );
    } catch (error) {
      console.error("Error in saving service setting: " + error);
    } finally {
      const params = {
        account_id: propsObj.account_id,
        service_id: propsObj.service_id,
        setting_month: propsObj.setting_month,
        marketplace_id: propsObj.marketplace_id,
      };
      const type = "success";
      const message = "Success";
      const description = "Successfully Updated !";
      openNotificationWithIcon(type, message, description);
      return dispatch(getServiceSettings(params));
    }
  };
};

export const saveTargetGoal = (props) => {
  let propsObj = JSON.parse(props);
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.REQUEST_SERVICE_SETTINGS,
      });
      let serviceSettings = {};
      const response = await serviceSettingsApi.saveServiceSettings(propsObj);
      if (response?.data?.data) {
        serviceSettings = { data: response?.data?.data };
      }
      dispatch({
        type: ACTIONS_TYPES.SAVE_SERVICE_SETTINGS,
        payload: serviceSettings,
      });
      return dispatch(
        serviceSettingsAction.saveServiceSettings(serviceSettings)
      );
    } catch (error) {
      console.error("Error in saving service setting: " + error);
    }
  };
};