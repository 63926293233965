export const initialState = {
    isLoading: false,
    key_insights: {}
  };
  
  export const ACTIONS_TYPES = Object.freeze({
    GET_KEY_INSIGHTS: "GET_KEY_INSIGHTS",
    FETCH_KEY_INSIGHTS: "FETCH_KEY_INSIGHTS",
    RESET_KEY_INSIGHTS: "RESET_KEY_INSIGHTS"
  });
  
  export function mmmReducer(state = initialState, action) {
    const { key, payload } = action;
    switch (action.type) {
        case ACTIONS_TYPES.GET_KEY_INSIGHTS:
          return {
            ...state,
            isLoading: true,
          };
          case ACTIONS_TYPES.FETCH_KEY_INSIGHTS:
            return {
              ...state,
              isLoading: false,
              key_insights: { ...state.table_slider_data, ...action.payload },
            };
          case ACTIONS_TYPES.RESET_KEY_INSIGHTS:
              return {
                ...state,
                key_insights: {
                  ...state.key_insights,
                  [payload]: {},
                },
              };  
      default:
        return state;
    }
  }
  