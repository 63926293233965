import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganicSeoData } from "../../../Redux/Actions/Thunk/barGraphThunk";
import { formDataAppend } from "../../../Utils/helpers";

const BackLinkChart = (props) => {

    const {filterData} = props;

    const {
        graph:{
            graph:{
                [props?.block?.block_id]: {
                    data: backlink_report
                } = {},
            },
            isLoading,
        } = {},
    } = useSelector((state) => state );
    const dispatch = useDispatch();

    useEffect(()=>{
        let data = props?.all_input_data;
        let formData = formDataAppend(data);
        dispatch(getOrganicSeoData(formData, props));
    }, [filterData]);

    return(
        <>
        <div className="organic-scorecard-errors organic-scorecard-single">
						<div className="os-single-title">
							<h4>Hidden 404 errors</h4>
							<span>GRADE: {backlink_report?.backlink_404_grade}</span>
						</div>
						<div className="os-single-header">
							<div className="ossh-single">
								<h4>Total Pages</h4>
								<h2>{backlink_report?.total_backlinks.toLocaleString()}</h2>
							</div>
							<div className="ossh-single">
								<h4>Total 404s</h4>
								<h2>{backlink_report?.backlinks_with_404.toLocaleString()}</h2>
							</div>
							<div className="ossh-single">
								<h4>% of 404s</h4>
								<h2>{backlink_report?.backlink_404_percent.toFixed(2)}%</h2>
							</div>
						</div>
						<div className="os-single-content">
							<div className="ossc-table">
								<table>
                                    <tbody>
                                        {
                                            backlink_report?.backlinks_urls?.map((keywordData, index) => (
                                                <tr key={index}>
                                                    <td>#{index}</td>
                                                    <td>{keywordData.backlink_url}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
								</table>
							</div>
							<p>
								Websites across the internet are pointing to your site. These
								occurrences are called backlinks. When checking the top 1,000
								pages to your site, we identified <b>{backlink_report?.total_backlinks.toLocaleString()}</b> with 
								a 404 error that may have link value attached to them.
							</p>
							<p>
								These pages were deleted, without proper 301 redirects. 404
								errors on backlinked pages can contribute to major drops in
								rankings until they are resolved. Resolutions vary based on the
								situation and include using tactics like manual outreach to
								update links and wildcard redirects.
							</p>
						</div>
						<div className="os-single-help">
							<h4>How we can help</h4>
							<p>
								Building links is hard enough – don’t leave link authority on
								the table with 404 errors. If you need assistance managing 404
								errors while maintaining SEO and optimal user experience, speak
								with our SEO experts by scheduling a free ecommerce growth plan.
							</p>
							<a href="https://agital.com/ecommerce-analysis/"
								target="_blank"
							>
								<button>Book a free SEO Analysis</button>
							</a>
						</div>
					</div>
        </>
    )
}

export default BackLinkChart;