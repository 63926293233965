import { Form, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GoogleConsoleUpdateConfigurationForm from "../../Components/Configuration/googleConsole/googleConsoleUpdateForm";
import {
  getGoogleConsoleDataSourcesThunk,
  updateGoogleConsoleDataSourcesThunk,
} from "../../Redux/Actions/Thunk/datasources/ds_configs/GoogleConsoleThunk";
const { Title } = Typography;

const DsUpdateGoogleConsoleConfiguration = () => {
  const [form] = Form.useForm();

  const history = useHistory();
  const { accounts_mcc: { auth_params = {} } = {} } = useSelector(
    (state) => state.googleConsoleMcc
  );

  // get id from params
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGoogleConsoleDataSourcesThunk({ id }));
  }, [id]);

  const fetchGoogleAdsConfiguration = async (id) => {
    // call to api for now let's say following has been returned
    const googleAdsConfiguration = {
      id: auth_params?.id,
      client_id: auth_params?.client_id,
      account: auth_params?.account,
      user_email: auth_params?.user,
      platform: auth_params?.platform,
      file_type: auth_params?.file_type,
      customer_id: auth_params?.customer_id,
      key_file: auth_params?.key_file,
      key: auth_params?.key,
      user_agent: auth_params?.user_agent,
      project_number: auth_params?.project_number,
    };
    form.setFieldsValue(googleAdsConfiguration);
  };

  useEffect(() => {
    fetchGoogleAdsConfiguration(id);
  }, [auth_params, id]);

  const handleSubmit = (values) => {
    let file =
      values?.key_file?.target?.files.length > 0
        ? values.key_file.target.files[0]
        : null;
    const formData = new FormData();
    formData.append("account", values.account);
    formData.append("email", values.user_email);
    formData.append("file", file);
    formData.append("platform", values.platform);
    formData.append("file_type", values.file_type);
    formData.append("project_number", values.project_number);
    formData.append("key", values.key);
    formData.append("id", id);
    dispatch(updateGoogleConsoleDataSourcesThunk(formData, history));
  };

  const handleCancel = () => {
    history.push("/google-ads-configuration");
  };
  return (
    <>
      <Title title="2">Update Google Console Configuration</Title>
      <div
        style={{
          width: "800px",
        }}
      >
        <GoogleConsoleUpdateConfigurationForm
          onFinish={handleSubmit}
          onFinishFailed={handleCancel}
          form={form}
        />
      </div>
    </>
  );
};

export default DsUpdateGoogleConsoleConfiguration;
