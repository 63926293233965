export const initialState = {
    areaChartData: {},
    isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
    GET_AREA_CHART_DATA: "GET_AREA_CHART_DATA",
    FETCH_AREA_CHART_DATA: "FETCH_AREA_CHART_DATA",
});

export function AreaChartReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS_TYPES.GET_AREA_CHART_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case ACTIONS_TYPES.FETCH_AREA_CHART_DATA:
            return {
                ...state,
                isLoading: false,
                areaChartData: { ...state.areaChartData, ...action.payload },
            };
        default:
            return state;
    }
}
