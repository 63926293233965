import * as mmmReducer from "../mmmAction";
import { ACTIONS_TYPES } from "../../Reducers/mmmReducer";
import * as mmmApi from "../../../Api/mmmApi"

export const getKeyInsights = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.RESET_KEY_INSIGHTS,
        payload: data?.block?.block_id,
      });
      dispatch({
        type: ACTIONS_TYPES.GET_KEY_INSIGHTS,
      });
      let key_insights = {};
      const response = await mmmApi.getKeyInsights(props, data);
      if (response?.status === 200) {
        key_insights = {
          [data?.block?.block_id]: {
            insights_data: response?.data?.data,
          },
        };
      }
      return dispatch(
        mmmReducer.fetchKeyInsights(key_insights)
      );
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
