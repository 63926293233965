import * as tableSliderReducer from "../tableSliderAction";
import { ACTIONS_TYPES } from "../../Reducers/tableSliderReducer";
import * as apiPath from "../../../Api/indexApi";
import * as tableSliderAPI from "../../../Api/tableSliderAPi";

export const getTableSliderData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.RESET_TABLE_SLIDER,
        payload: data?.block?.block_id,
      });
      dispatch({
        type: ACTIONS_TYPES.GET_TABLE_SLIDER_DATA,
      });
      let table_slider_data = {};
      const response = await tableSliderAPI.getTableSliderData(props, data);
      if (response?.status === 200) {
        table_slider_data = {
          [data?.block?.block_id]: {
            cpa: response?.data?.cpa,
            insights_data: response?.data?.insights_data,
            data: response?.data?.data,
            ad_spend: response?.data?.ad_spend
          },
        };
      }
      return dispatch(
        tableSliderReducer.fetchTableSliderData(table_slider_data)
      );
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
