import API_BI from "../Utils/axios_bi";
import API from "../Utils/axios";
import { ApiEndpointsBI } from "../Utils/constant";

const { SAVE_ANNOTATIONS_REPLY, UPDATE_ANNOTATIONS_REPLY, DELETE_ANNOTATIONS_REPLY, GET_ANNOTATIONS_REPLY } = ApiEndpointsBI;

export const saveAnnotationsReply = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(SAVE_ANNOTATIONS_REPLY, props);
}
export const updateAnnotationsReply = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(UPDATE_ANNOTATIONS_REPLY, props);
}
export const deleteAnnotationsReply = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(DELETE_ANNOTATIONS_REPLY, props);
}
export const getAnnotationsReply = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(GET_ANNOTATIONS_REPLY, props);
}