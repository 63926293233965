import { Button, Table, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import TagInputBox from "../../Pages/Settings/TagInputBox";

const {
	API_BI: { baseURL },
} = config;

const AppSettings = (props) => {
	const [appSettings, setAppSettings] = useState([]);

	const dispatch = useDispatch();

	const getRowKey = (record) => record.id;

	const isLoading = useSelector((state) => state.accounts.isLoading);

	useEffect(() => {
		dispatch({
			type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
		});
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.get(baseURL + "api/v1/app-settings").then((response) => {
			if (response.status == 200) {
				setAppSettings(response.data?.data);
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
				});
			}
		});
	}, []);

	const columns = [
		{
			title: "#",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Setting",
			dataIndex: "title",
			key: "title",
		},
		{
			title: "Key",
			dataIndex: "slug",
			key: "slug",
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
			render: (values, record) => {
				return <TagInputBox ips={values} id={record.id} />;
			},
		},
		// {
		// 	title: "Action",
		// 	dataIndex: "action",
		// 	key: "action",
		// 	render: () => {
		// 		return (
		// 			<>
		// 				<div className="settings-update">
		// 					<Button>Update</Button>
		// 				</div>
		// 			</>
		// 		);
		// 	},
		// },
	];

	return (
		<>
			<div className="panel-heading">
				<Row>
					<Col lg={{span: 8}} xs={{span: 24}} className="panel-heading-title">
						<h2>App Settings</h2>
					</Col>
				</Row>
			</div>
			<div className="panel-body app-settings">
				<div className="panel-body-content">
					<Table
						rowKey={getRowKey}
						columns={columns}
						dataSource={appSettings}
						loading={isLoading}
						pagination={false}
					/>
				</div>
			</div>
		</>
	);
};

export default AppSettings;
