import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Upload,
  Checkbox,
  Table,
  Tooltip,
  message,
} from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { FaEye, FaArrowAltCircleLeft, FaInfoCircle } from "react-icons/fa";
import Config from "../../Config";

const columns = [
  {
    title: "Type",
    dataIndex: "report_type",
    key: "report_type",
  },
  {
    title: "Data File",
    dataIndex: "report_file",
    key: "report_file",
    render: (text, record) => {
      var download_query_string =
        "?csv_file=search_term/" + record.report_type.toLowerCase().replaceAll(' ','_') + "/" + text;
      return (
        <a href={baseURL + "api/v1/download-url-csv" + download_query_string}>
          {text}
        </a>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

const {
  API_BI: { baseURL },
} = Config;

const LPPModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [modalCsvVisible, setCsvModalVisible] = useState(false);
  const [isFileLoaded, setIsFileLoaded] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showCsvModal = () => {
    if (isFileLoaded == true) {
      setCsvModalVisible(true);
    }else {
      message.warning('Please select file to upload');
    }
  };

  const handleCsvModalOk = () => {
    // onFinish({ url_list: csvData });
    form.submit()
    setCsvModalVisible(false);
    setIsFileLoaded(false);
  };

  const handleCsvModalCancel = () => {
    setCsvModalVisible(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showHistoryModal = () => {
    setIsHistoryModalOpen(true);
    setIsModalOpen(false);
  };
  const handleHistoryOk = () => {
    setIsHistoryModalOpen(false);
  };
  const handleHistoryCancel = () => {
    setIsHistoryModalOpen(false);
  };

  const [form] = Form.useForm();

  const file_props = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    beforeUpload(data_file) {
      const isValidType =
        data_file.type === "text/csv" ||
        data_file.type === "text/tab-separated-values";
      if (!isValidType) {
        form.setFields([
          {
            name: "data_file",
            errors: ["Invalid file type! File type must be csv or tsv."],
          },
        ]);
      }

      const isLt2M = data_file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        form.setFields([
          {
            name: "data_file",
            errors: ["File size must be smaller than 2MB!"],
          },
        ]);
      }

      if (isValidType && isLt2M) {
        form.setFields([
          {
            name: "data_file",
            errors: null,
          },
        ]);
        return false;
      }

      return (isValidType && isLt2M) || Upload.LIST_IGNORE;
    },
  };

  const exceptions_props = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    beforeUpload(data_file) {
      const isValidType = data_file.type === "text/csv";
      if (!isValidType) {
        form.setFields([
          {
            name: "exceptions_file",
            errors: ["Invalid file type! File type must be csv."],
          },
        ]);
      }

      const isLt2M = data_file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        form.setFields([
          {
            name: "exceptions_file",
            errors: ["File size must be smaller than 2MB!"],
          },
        ]);
      }

      if (isValidType && isLt2M) {
        form.setFields([
          {
            name: "exceptions_file",
            errors: null,
          },
        ]);
        return false;
      }

      return (isValidType && isLt2M) || Upload.LIST_IGNORE;
    },
  };

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const bodyData = {
          account_id: props?.account_id,
          report_type: "URLs For Keyword Research",
        };
        var requestOptions = {
          method: "POST",
          redirect: "follow",
          headers: {
            authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        };
        const response = await fetch(
          baseURL + "api/v1/search-term-upload-history",
          requestOptions
        );
        const result = await response.json();
        setData(result?.data);
      } catch (err) {
        // Handle any errors
      } finally {
      }
    };
    fetchApi();
  }, []);

  const onFinish = (values) => {
    const data = new FormData();
    data.append("report_file", values.url_list[0].originFileObj);
    if (values.exceptions_file != undefined) {
      data.append("exceptions_file", values.exceptions_file[0].originFileObj);
    }
    data.append("report_type", "landing_page_performance");
    data.append("account_id", props.account_id);
    data.append("client_id", props.client_id);
    data.append("url_validation", values.url_validation);
    data.append("from_moth", "");
    data.append("to_month", "");

    fetch(baseURL + "api/v1/bulk-upload-urls", {
      method: "POST",
      body: data,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          message.success(json.message);
          alert("File Uploaded Successfully");
        });
      } else {
        response.json().then((json) => {
          message.error(json.message);
        });
      }
      form.resetFields();
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const downloadUrl = baseURL + "api/v1/download-keyword-research-template";
//csv file read for modal
const handleFileChange = (info) => {
  const { fileList } = info;

  if (fileList.length > 0) {
    setIsFileLoaded(true);
    const file = fileList[0].originFileObj;
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = (event) => {
      const csvdata = event.target.result;
      const rowData = csvdata.split('\n').map(row => row.split(','));
      const dataWithoutHeader = rowData.slice(1);
      setCsvData(dataWithoutHeader);
    };
  } else {
    setIsFileLoaded(false);
    alert("Please select a file.");
  }
};
  return (
    <>
      <Button onClick={showModal} type="primary" size="default">
        UPLOAD BULK FILE
      </Button>
      <Modal
        className="import-modals bulk-request-modal"
        title="UPLOAD BULK URLS FOR KEYWORD RESEARCH"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Close"
      >
        <Form
          form={form}
          name="upload_bulk_urls"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="url_list"
            label="URL List"
            valuePropName="fileList"
            style={{ marginBottom: "20px" }}
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Please select file to upload",
              },
            ]}
          >
            <Upload {...file_props} maxCount={1} onChange={handleFileChange}>
              <Button icon={<UploadOutlined />}>Choose a File</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="URL Validation"
            style={{ marginBottom: "20px" }}
            className="url-form"
          >
            <Form.Item noStyle name="url_validation" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Tooltip title="URL Validation should be turned on unless the upload file contains URLs that are not live">
              <FaInfoCircle />
            </Tooltip>
          </Form.Item>
          <Form.Item
            name="upload"
            label=""
            className="import-modals-form-buttons"
          >
            <Button icon={<DownloadOutlined />} href={downloadUrl}>
              Download Template
            </Button>
            <Button icon={<FaEye />} onClick={showHistoryModal}>
              View Upload History
            </Button>
            <Button icon={<UploadOutlined />} onClick={showCsvModal} htmlType="button">
              Upload and Import
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="bulk-history-modal"
        title={
          <>
            <Button
              className="modal-back-btn"
              icon={<FaArrowAltCircleLeft />}
              onClick={() => {
                setIsModalOpen(true);
                setIsHistoryModalOpen(false);
              }}
            >
              Go Back
            </Button>
            BULK URL UPLOAD HISTORY
          </>
        }
        visible={isHistoryModalOpen}
        onOk={handleHistoryOk}
        cancelText="Close"
        onCancel={handleHistoryCancel}
        okButtonProps={{ style: { display: "none" } }}
        width={800}
      >
        <Table columns={columns} dataSource={data} />
      </Modal>
      <Modal
        title="Confirming you’re submitting for these URLs"
        visible={modalCsvVisible}
        onOk={handleCsvModalOk}
        okText="Confirm"
        onCancel={handleCsvModalCancel}
      >
        {/* Display URLs from the CSV file in the modal */}
        <ul>
          {csvData.map((row, index) => (
            <li key={index}>{row[0]}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default LPPModal;
