import { tiktokProfileApi } from "../../Api/datasources/tiktokAPI";
const tiktokApiCall = async (props) => {
  const { queryCode } = props;

  // *Get user profile information and access token from tiktok
  const { data } = await tiktokProfileApi({
    auth_code: queryCode,
  });
  return {
    profile: data.data.profile,
  };
};

export default tiktokApiCall;
