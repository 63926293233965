import { Button, Form, Input, Space, Typography } from "antd";
import { useHistory } from "react-router-dom";

const GoogleAdsUpdateConfigurationForm = ({
  form,
  onFinish,
  onFinishFailed,
}) => {
  const history = useHistory();
  const handleChange = (info) => {
    return info;
  };

  const handleRequest = (values) => {};

  const handleCancel = () => {
    history.push("/google-ads-configuration");
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        name="google_ads_form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="client ID"
          name="client_id"
          rules={[
            {
              required: true,
              message: "Client ID is required",
            },
          ]}
        >
          <Input placeholder="Client Id" />
        </Form.Item>
        <Form.Item name="key_file" label="keyfile" valuePropName="fileList">
          <Input onChange={handleChange} type="file" />
        </Form.Item>

        <Form.Item
          label="Account"
          name="user_email"
          rules={[
            {
              required: true,
              message: "User Email",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Developer Token"
          name="developer_token"
          rules={[
            {
              required: true,
              message: "Developer Token is required",
            },
          ]}
        >
          <Input placeholder="Developer Token" />
        </Form.Item>
        <Form.Item
          label="User agent"
          name="user_agent"
          rules={[
            {
              required: true,
              message: "User Agent is required",
            },
          ]}
        >
          <Input placeholder="User Agent" />
        </Form.Item>

        <Form.Item
          label="Platform"
          name="platform"
          rules={[
            {
              required: true,
              message: "Platform",
            },
          ]}
        >
          <Input placeholder="Platform is required" />
        </Form.Item>

        <Form.Item
          label="File Type"
          name="file_type"
          rules={[
            {
              required: true,
              message: "File Type",
            },
          ]}
        >
          <Input placeholder="Platform" />
        </Form.Item>

        <Space direction="horizontal">
          <Button type="primary" htmlType="submit">
            Update
          </Button>
          <Button type="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Space>

        <Typography style={{ padding: "10px 0" }}>
          Note: Please take precaution while editing the values. It has
          site-wide effects.
        </Typography>
      </Form>
    </>
  );
};
export default GoogleAdsUpdateConfigurationForm;
