import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { getPieChartData } from "../../Redux/Actions/Thunk/pieThunk";
import { formDataAppend } from "../../Utils/helpers";
import { Spin } from "antd";

const PieChart = (props) => {
  const {
    pie: {
      pie: { [props?.block?.block_id]: { data: pieData = [] } = {} },
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    if (props?.length === 0) {
      dispatch(getPieChartData(formData, props));
    }
  }, []);

  /*for filter data*/
  const [filter, setFilter] = useState("");
  const { filterData } = props;
  const config = JSON.parse(props?.block?.config);
  const height = config.chart.height;

  const tooltipStyle = config.tooltipStyle;

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getPieChartData(formData, props));
  }, [filterData]);
  /*end for filter data*/

  const PChart = {
    chart: {
      type: "pie",
      height: height,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      formatter: function () {
        // if (amazon_rating == 0) {
        //   var review_format = `Review${this.y === 1 ? "" : "s"}`;
        // } else {
        //   var review_format = `SKU`;
        // }
        if (tooltipStyle != undefined) {
          return "<b>" + this.point.name + "</b>: " + this.y + " " + tooltipStyle;
        } else {
          return "<b>" + this.point.name + "</b>: " + this.y + "%";
        }
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: null,
        colorByPoint: true,
        data: pieData,
      },
    ],
    legend: config.legend ? config.legend : {},
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <HighchartsReact
          highcharts={Highcharts}
          options={PChart}
        ></HighchartsReact>
      </Spin>
    </>
  );
};

export default PieChart;
