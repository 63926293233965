import { Button, Checkbox, Form, Input, Select, Switch, Spin, Row, Col, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveReports, createNewRole } from "../../Redux/Actions/Thunk/roleThunk";
import { useDispatch, useSelector } from "react-redux";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiList } from "react-icons/fi";

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 12,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 18,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 12,
            offset: 0,
        },
        sm: {
            span: 24,
            offset: 0,
        },
    },
};

const NewRole = (props) => {
    const [status, setStatus] = useState(true);
    const [sfFullStatus, setSfFullStatus] = useState(false);
    const [sfSingleStatus, setSfSingleStatus] = useState(false);
    const [userGroup, setUserGroup] = useState(0);

    const {
        roles: {
            reports: {
                data: activeReports = []
            },
            isLoading,
        },
    } = useSelector((state) => state);
    const dispatch = useDispatch();

    const { id } = useParams();

    useEffect(() => {
        dispatch(getActiveReports());
    }, [dispatch]);

    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            'status': true,
            'sf_full': false,
            'sf_single': false
        });
        setStatus(true);
        setSfFullStatus(false);
        setSfSingleStatus(false);
    }, []);

    const onFinish = (values) => {
        values = JSON.stringify(values);
        dispatch(createNewRole(values));
        message.success("New Role Added Successfully", [1.5]);
    };

    const handleStatusChange = () => {
        setStatus((status) => !status);
    };

    const handleUserGroupChange = (value, event) => {
        setUserGroup(value);
        if (value == 4) {
            setSfFullStatus(false);
            setSfSingleStatus(false);
        }
    };

    const userGroups = [
        { 'id': 2, 'name': 'Internal - Customer Facing' },
        { 'id': 3, 'name': 'Internal - Other' },
        { 'id': 4, 'name': 'Customer' },
    ]
    const breadcrumbItems = [
		{ text: "", link: "/", icon: FiGrid },
		{ text: "Roles", link: "/roles", icon: FiList },
		{
			text: "New Role",
		},
	];
    return (
        <>
            <CustomBreadcrumb items={breadcrumbItems} />
            <div className="panel-heading">
                <Row>
                    <Col span={24} className="panel-heading-title">
                        <h2>New Role</h2>
                    </Col>
                </Row>
            </div>
            <div className="panel-body">
                    <Row>
                        <Col lg={{span: 16}} xs={{span: 24}}>
                            <div className="panel-body-content roles-view-wrapper new-role">
                            <Spin spinning={false}>
                                <Form
                                    {...formItemLayout}
                                    form={form}
                                    name="register"
                                    onFinish={onFinish}
                                    initialValues={props.location.myCustomProps}
                                    scrollToFirstError
                                >
                                    <Form.Item label="Role Status" name="status">
                                        <Switch
                                            name="status"
                                            checked={status}
                                            onChange={handleStatusChange}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="role_name"
                                        label="Role Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input role name!",
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="user_group"
                                        label="User Group"
                                        rules={[
                                            {
                                                required: true,
                                                message: "User Group is required field!",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Please select" onSelect={(value, event) => handleUserGroupChange(value, event)}>
                                            {userGroups.map((userGroup) => (
                                                <Option
                                                    value={userGroup.id}
                                                    id={userGroup.id}
                                                    name={userGroup.id}
                                                    key={userGroup.id}
                                                >
                                                    {userGroup.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="default_dashboard"
                                        label="Default Dashboard"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Default Dashboard is required field!",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Please select">
                                            {activeReports.map((report) => (
                                                <Option
                                                    value={report.id}
                                                    id={report.id}
                                                    name={report.id}
                                                    key={report.id}
                                                >
                                                    {report.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="sf_full"
                                        valuePropName="checked"
                                        style={{ display: userGroup == 2 || userGroup == 3 ? '' : 'none' }}
                                    >
                                        <Checkbox checked={sfFullStatus}>
                                            Full Salesforce Upload
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="sf_single"
                                        valuePropName="checked"
                                        style={{ display: userGroup == 2 || userGroup == 3 ? '' : 'none' }}
                                    >
                                        <Checkbox checked={sfSingleStatus}>
                                            Single Account Salesforce Upload
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item {...tailFormItemLayout} className="new-role-save">
                                        <Button type="primary" htmlType="submit">
                                            Add New Role
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Spin>
                            </div>
                        </Col>
                    </Row>
            </div>
        </>
    );
};

export default NewRole;
