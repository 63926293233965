import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Table,
  Input,
  InputNumber,
  Form,
  Spin,
  Switch,
  Tooltip,
  Button,
  Dropdown,
  Menu,
} from "antd";
import MMMLift from "../../Components/ReportBlocks/MMMLift";
import MMMInsights from "../../Components/ReportBlocks/MMMInsights";
import { useDispatch, useSelector } from "react-redux";
import { formDataAppend, format_data } from "../../Utils/helpers";
import { getDataTable } from "../../Redux/Actions/Thunk/dataTableThunk";
import { FormattedNumberCell } from "../../Utils/helpers/formatting";
import isEqual from "lodash/isEqual";
import {
  FaInfoCircle,
  FaDownload,
  FaFileExcel,
  FaFileCsv,
} from "react-icons/fa";
import { exportData } from "../../Utils/helpers/tableHelper";
import $ from "jquery";

var mmmVal = [];

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const [updatedVal, setUpdatedValue] = useState([]);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  if (dataIndex == "ppc_spend") {
    var column = "PPC";
  } else if (dataIndex == "pla_spend") {
    var column = "PLA";
  } else {
    var column = "PSM";
  }
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
      setUpdatedValue({
        date: record["month"],
        source: dataIndex,
        spend: values[dataIndex],
      });
      mmmVal.push({
        date: record["month"],
        source: column,
        spend: values[dataIndex],
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <InputNumber
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          controls={false}
          size="small"
          style={{ fontSize: "12px", padding: "0px", maxWidth: "70px" }}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const GroupedEditableTable = (props) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [totalRow, setTotalRow] = useState({});

  const [editing, setEditing] = useState(false);
  const [optimizedColumns, setOptimizedColumns] = useState([]);
  const [checked, setChecked] = useState(false);
  const [rsquaredVal, setRSquareVal] = useState("");
  const [accuracyVal, setAccuracyVal] = useState("");
  const [optimizedVal, setOptmizedVal] = useState("");
  const [optimizedMessage, SetOptmizedMessage] = useState("");
  const [show_optimized, setShowOptimized] = useState([]);

  const {
    dataTable: {
      table: {
        [props?.block?.block_id]: {
          [props?.block?.block_id]: {
            data: tableData = [],
            total_all_data: totalAllData = [],
            total_data: totalData = [],
            rsquared: rsquared = "",
            accuracy: accuracy = "",
            optimized: optimized = "",
            insight: insight = "",
          } = {},
          isLoading,
        } = {},
      },
    } = {},
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  var data = props?.all_input_data;
  var formData = formDataAppend(data);
  const config = JSON.parse(props?.block?.config);
  const tableColumns = config["columns"];
  const [dataSource, setDataSource] = useState([]);
  var include_amazon_data = data?.include_amazon_data;

  // Fetch data
  useEffect(() => {
    dispatch(getDataTable(formData, props));
  }, []);

  useEffect(() => {
    if (tableData.length > 0) {
      setDataSource(tableData);
      setOptmizedVal(optimized);
      setRSquareVal(rsquared);
      setAccuracyVal(accuracy);
    }
  }, [tableData]);

  //total data
  useEffect(() => {
    // Assuming totalAllData is a prop or state variable
    const totalData = { ...totalAllData }; // Copy the object

    const newObj = {};
    Object.keys(totalData).forEach((data) => {
      newObj[data] = totalData[data];
    });

    // Check if the new object is different from the previous totalRow
    if (!isEqual(newObj, totalRow)) {
      setTotalRow(newObj);
    }
  }, [totalAllData, totalRow]);

  const cancel = () => {
    setEditingKey("");
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.month === item.month);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  var mergedColumns = exact_columns(tableColumns);

  function exact_columns(cols) {
    return cols.map((col) => {
      if (!col.children) {
        if (col.editable) {
          return {
            ...col,
            onCell: (record) => ({
              record,
              inputType:
                col.format === "amount" || col.format === "percentage"
                  ? "number"
                  : "text",
              dataIndex: col.dataIndex,
              title: col.title,
            }),
          };
        }
        return col;
      }

      return {
        ...col,
        children: col.children
          .map((child) => {
            if (child.editable) {
              if (include_amazon_data === 0 && child.dataIndex == "asc_spend") {
                return null;
              }
              return {
                ...child,
                render: (text, record) =>
                  child.format === "amount"
                    ? `${format_data(text, "amount")}`
                    : child.format === "percentage"
                    ? `${parseFloat(text).toFixed(2)}%`
                    : text,
                onCell: (record) => ({
                  record,
                  inputType:
                    child.format === "amount" || child.format === "percentage"
                      ? "number"
                      : "text",
                  dataIndex: child.dataIndex,

                  editable: child.editable,
                  handleSave,
                }),
              };
            } else {
              return {
                ...child,
                title: (
                  <>
                    {child.dataIndex == "acos" ||
                    child.dataIndex == "acos_yoy" ? (
                      <>
                        {child.title}{" "}
                        <Tooltip
                          trigger="hover"
                          title="MER equals the sum of ad spend across the paid channels divided by total website revenue."
                        >
                          <span
                            className="table-header-tooltip"
                            style={{ color: "#8dc441" }}
                          >
                            <FaInfoCircle />
                          </span>
                        </Tooltip>
                      </>
                    ) : (
                      child.title
                    )}
                  </>
                ),
                render: (text, record) =>
                  child.format === "amount"
                    ? `${format_data(text, "amount")}`
                    : child.format === "percentage"
                    ? `${parseFloat(text).toFixed(2)}%`
                    : text,
              };
            }
            // return child;
          })
          .filter(Boolean),
      };
    });
  }

  const allChildColumns = mergedColumns.reduce((acc, column) => {
    if (column.children) {
      acc.push(...column.children);
    }

    return acc;
  }, []);

  var cols = tableColumns.filter((item, index) => item.dataIndex != undefined);
  const totalColumns = [...cols, ...allChildColumns];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const [tableParamsServer, setTableParamsServer] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });
  data["spend_lift_pla"] = localStorage.getItem("spend_lift_pla");
  data["spend_lift_ppc"] = localStorage.getItem("spend_lift_ppc");
  data["spend_lift_psm"] = localStorage.getItem("spend_lift_psm");
  data["spend_lift_asc"] = localStorage.getItem("spend_lift_asc");

  const changeColumns = (e) => {
    var data = props?.all_input_data;
    setShowOptimized(e);
    if (e == true) {
      data["show_optimized"] = 1;
      var formData = formDataAppend(data);
      dispatch(getDataTable(formData, props));
      // data["show_optimized"] = 1;
      if (data["show_optimized"] === 1) {
        if (optimizedVal == 0) {
          SetOptmizedMessage(
            "Optimization is only available when diminishing returns are present, which the model did not detect in your marketing. You should consider increasing advertising in high-opportunity channels."
          );
          setChecked(e);
        } else if (optimizedVal == 2) {
          SetOptmizedMessage(insight);
          setChecked(e);
        } else {
          // var formData = formDataAppend(data);
          // dispatch(getDataTable(formData, props));
          setChecked(e);
          var cols = [
            {
              dataIndex: "month",
              title: "Month",
              key: "month",
            },
            {
              title: "Optimized Spend",
              className: "header-blue-column",
              children: [
                {
                  dataIndex: "ppc_spend",
                  title: "Paid Search",
                  key: "ppc_spend",
                  format: "amount",
                  render: (text) => format_data(text, "amount"),
                },
                {
                  dataIndex: "pla_spend",
                  title: "Product Ads",
                  key: "pla_spend",
                  format: "amount",
                  render: (text) => format_data(text, "amount"),
                },
                {
                  dataIndex: "psm_spend",
                  title: "Paid Social",
                  key: "psm_spend",
                  format: "amount",
                  render: (text) => format_data(text, "amount"),
                },
                {
                  dataIndex: "asc_spend",
                  title: "Amazon",
                  key: "asc_spend",
                  format: "amount",
                  render: (text) => format_data(text, "amount"),
                },
                {
                  dataIndex: "spend_total",
                  title: "Total",
                  key: "spend_total",
                  format: "amount",
                  render: (text) => format_data(text, "amount"),
                },
              ],
            },
            {
              title: "Optimized Revenue",
              className: "header-green-column",
              children: [
                {
                  dataIndex: "revenue",
                  title: "Revenue",
                  key: "revenue",
                  format: "amount",
                  render: (text) => format_data(text, "amount"),
                },
                {
                  dataIndex: "acos",
                  title: (
                    <>
                      MER
                      <Tooltip
                        trigger="hover"
                        title="MER equals the sum of ad spend across the paid channels divided by total website revenue."
                      >
                        <span
                          className="table-header-tooltip"
                          style={{ color: "#8dc441" }}
                        >
                          <FaInfoCircle />
                        </span>
                      </Tooltip>
                    </>
                  ),
                  format: "percentage",
                  key: "acos",
                  render: (text) => format_data(text, "percentage", 2, "acos"),
                  tooltip:
                    "TACoS equals the sum of ad spend across the padataIndex channels divdataIndexed by total website revenue.",
                },
              ],
            },
            {
              title: "Optimized YOY Lift",
              className: "header-yellow-column",
              children: [
                {
                  dataIndex: "spend_yoy",
                  title: "Spend",
                  key: "spend_yoy",
                  format: "percentage",
                  render: (text) => format_data(text, "percentage", 2, "spend_yoy"),
                },
                {
                  dataIndex: "revenue_yoy",
                  title: "Revenue",
                  key: "revenue_yoy",
                  format: "percentage",
                  render: (text) => format_data(text, "percentage", 2, "revenue_yoy"),
                },
                {
                  dataIndex: "acos_yoy",
                  title: (
                    <>
                      MER
                      <Tooltip
                        trigger="hover"
                        title="MER equals the sum of ad spend across the paid channels divided by total website revenue."
                      >
                        <span
                          className="table-header-tooltip"
                          style={{ color: "#8dc441" }}
                        >
                          <FaInfoCircle />
                        </span>
                      </Tooltip>
                    </>
                  ),
                  key: "acos_yoy",
                  format: "percentage",
                  render: (text) => format_data(text, "percentage", 2, "acos_yoy"),
                  tooltip:
                    "TACoS equals the sum of ad spend across the padataIndex channels divdataIndexed by total website revenue.",
                },
              ],
            },
          ];
          var optimizedColumn = cols.map((col) => {
            if (col.children) {
              col.children.forEach((child) => {
                if (
                  include_amazon_data === 0 &&
                  child.dataIndex === "asc_spend"
                ) {
                  // If include_amazon_data is 1 and dataIndex is "asc_spend", remove the child column
                  col.children = col.children.filter(
                    (c) => c.dataIndex !== "asc_spend"
                  );
                }
              });
            }
            return col;
          });
          setOptimizedColumns(optimizedColumn);
        }
      }
    } else {
      data["show_optimized"] = 0;
      var formData = formDataAppend(data);
      dispatch(getDataTable(formData, props));
      setOptimizedColumns("");
      setChecked(false);
      SetOptmizedMessage("");
      setShowOptimized(false);
    }
  };

  var apiPath = props?.block?.api_path;

  const export_data = useCallback((data_type, props, dispatch, apiPath) => {
    exportData(data_type, props, dispatch, apiPath);
  });

  const export_menu = (
    <Menu
      className="export-dropdown"
      items={[
        {
          label: (
            <Button
              onClick={() => export_data("excel", props, dispatch, apiPath)}
            >
              <FaFileExcel />
              Excel
            </Button>
          ),
          key: "0",
        },
        {
          label: (
            <Button
              onClick={() => export_data("csv", props, dispatch, apiPath)}
            >
              <FaFileCsv />
              CSV
            </Button>
          ),
          key: "1",
        },
      ]}
    />
  );
  return (
    <Form form={form} component={false}>
      <MMMLift
        mmmVal={mmmVal}
        props={props}
        isLoading={isLoading}
        checked={checked}
      />
      <div
        className={`datatable-options ${optimizedMessage == "" ? "" : "mmm-datatable-options"}` }>
        <div className="datatable-toggle-info">
          <span className="datatable-toggle">
            Show Optimized Spends/Revenue
            <Switch
              size="small"
              id="adsBtn"
              checked={checked}
              onChange={changeColumns}
            />
          </span>
        </div>
        <div className="export-button-wrapper">
            <Dropdown overlay={export_menu} trigger={["click"]}>
              <Button type="primary" onClick={(e) => e.preventDefault()}>
                <FaDownload />
                Export
              </Button>
            </Dropdown>
          </div>
      </div>
      {optimizedMessage !== "" ? (
        <p className="optimized-message">- {optimizedMessage}</p>
      ) : (
        <div className="mmm-main-table">
          <Table
            components={components}
            bordered
            loading={isLoading}
            dataSource={dataSource}
            columns={
              optimizedColumns.length > 0 ? optimizedColumns : mergedColumns
            }
            rowClassName="editable-row"
            pagination={
              totalData === null
                ? false
                : {
                    ...tableParamsServer.pagination,
                    total: totalData,
                    showSizeChanger: false,
                  }
            }
            summary={() => {
              return (
                <>
                  {totalAllData && Object.keys(totalAllData).length > 1 ? (
                    <Table.Summary.Row>
                      {totalColumns.map((column, columnIndex) => (
                        <Table.Summary.Cell
                          key={columnIndex}
                          index={columnIndex}
                        >
                          {columnIndex === 0 ? (
                            "TOTAL"
                          ) : (
                            <FormattedNumberCell
                              value={totalRow[column.dataIndex]}
                              format={column.format}
                              metric={column.dataIndex}
                            />
                          )}
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  ) : (
                    ""
                  )}
                </>
              );
            }}
          />
        </div>
      )}

      {optimizedMessage === "" ? (
        <MMMInsights
          props={props}
          isLoading={isLoading}
          rsquared={rsquaredVal}
          accuracy={accuracyVal}
        />
      ) : (
        ""
      )}
    </Form>
  );
};

export default GroupedEditableTable;
