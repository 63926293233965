import { formatColumns } from "../../../Utils/colorLogic";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const BingProductInsights = (props, text) => {
    let cellClass = "";
    let recordData = props?.record;
  
    let blockId = props?.extraParams?.block?.block_id;
    let accountId = props?.extraParams?.account_id;
    let eparams = props?.extraParams?.all_input_data;
    let f_month = eparams?.f_month;
    let t_month = eparams?.t_month;
    let f_month_compare = eparams?.c_f_month;
    let t_month_compare = eparams?.c_t_month;
    let feed_label = recordData?.label_name;
    let brand = recordData?.brand;
  
    if (props?.column?.dataIndex == "feed_label") {
      return (
        <>
          <Link
            to={
              "/reports/v2/view/bing_feed_label_performance/" +
              accountId +
              "?feed_label=" +
              feed_label +
              "&f_month=" +
              f_month +
              "&t_month=" +
              t_month 
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={cellClass}>
              <FormattedNumberCell
                value={props?.value}
                format={props?.column?.format}
              />
            </span>
          </Link>
        </>
      );
    } else if (props?.column?.dataIndex == "brand") {
      return (
        <>
          <Link
            to={
              "/reports/v2/view/bing_product_performance/" +
              accountId +
              "?brand=" +
              brand +
              "&f_month=" +
              f_month +
              "&t_month=" +
              t_month 
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={cellClass}>
              <FormattedNumberCell
                value={props?.value}
                format={props?.column?.format}
              />
            </span>
          </Link>
        </>
      );
    } else {
      return (
        <>
          <span
            className={formatColumns(
              props?.value,
              recordData,
              props?.goalData,
              props?.column?.dataIndex
            )}
          >
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
            />
          </span>
        </>
      );
    }
};

export default BingProductInsights;
