import { Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResetHistoricaDataAccountDataSources } from "../../Redux/Actions/Thunk/datasources/ds_configs/dsResetHistoricalDataThunk";

const ResetHistoricalDataConfig = () => {
  const [dataSouceItem, setDataSouceItem] = useState([]);
  const { accounts_ds: { data = [] } = {} } = useSelector(
    (state) => state.resetHistoricalData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getResetHistoricaDataAccountDataSources({}));
  }, []);
  useEffect(() => {
    const response = data?.map((item, index) => {
      return {
        id: index + 1,
        dataSource: item?.name,
        isAutomatic: item?.is_api,
      };
    });
    setDataSouceItem(response);
  }, [data]);

  const onChangeIsApi = (checked, data) => {
    dispatch(
      getResetHistoricaDataAccountDataSources({
        status: checked ? 1 : 0,
        id: data?.id,
      })
    );
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "DataSource",
      dataIndex: "dataSource",
      key: "dataSource",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Switch
              defaultChecked={record?.isAutomatic}
              onChange={(checked) => onChangeIsApi(checked, record)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table dataSource={dataSouceItem} columns={columns} />
    </>
  );
};

export default ResetHistoricalDataConfig;
