import { notification } from "antd";

import codeMessage from "./codeMessage";

const successHandler = (
  response,
  options = { notifyOnSuccess: false, notifyOnFailed: true }
) => {
  const { data } = response;
  if (data && data.success) {
    const message = data.message;
    const successText = message;

    if (options.notifyOnSuccess) {
      notification.config({
        duration: 5,
      });
      notification.success({
        // message: `Request success`,
        description: successText,
      });
    }
  } else {
    const message = response.data && data.message;
    const errorText = message;
    const { status } = response;
    if (options.notifyOnFailed) {
      notification.config({
        duration: 5,
      });
      notification.error({
        // message: `Request error ${status}`,
        description: errorText,
      });
    }
  }
};

export default successHandler;
