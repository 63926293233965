import React, { useEffect, useState } from "react";
import {
  Input,
  Space,
  Table,
  Button,
  Row,
  Col,
  Switch,
  Spin,
  Radio,
  Checkbox,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getRoleDetails,
  toggleRoleStatus,
  changeRoleType,
  setDefaultDashboard,
  setReportStatus,
  updateSalesforcePermission,
} from "../../Redux/Actions/Thunk/roleThunk";
import { useParams } from "react-router-dom";
import AddUserRole from "../../Components/Modals/AddUserRole";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiList } from "react-icons/fi";
const { Search } = Input;

function RoleView(props) {
  let { id } = useParams();
  let pathname = props.location.pathname;
  const [dataSource, setDataSource] = useState([]);
  const [searchedReportList, setSearchedReportList] = useState([]);
  const [hasSearched, setHasSearched] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterColumn, setFilterColumn] = useState();
  const [value, setValue] = useState(true);
  const [roleStatus, setRoleStatus] = useState(false);
  const [roleType, setRoleType] = useState(0);
  const [importPermissions, setImportPermissions] = useState([]);

  const {
    roles: {
      role_details: {
        data: roleInfo = {},
        reports: reportData = [],
        users: userData = [],
        services: services = [],
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  // Fetch data
  useEffect(() => {
    dispatch(getRoleDetails(`role_id=${id}`));
  }, [dispatch]);

  const sfImportPermissions = [];

  useEffect(() => {
    setRoleStatus(roleInfo.status == 1);
    setRoleType(roleInfo.user_group);
    if (roleInfo.sf_full == 1) {
      sfImportPermissions.push("sf_full");
    }
    if (roleInfo.sf_single == 1) {
      sfImportPermissions.push("sf_single");
    }
    setImportPermissions(sfImportPermissions);
  }, [roleInfo]);

  useEffect(() => {
    setDataSource(reportData);
  }, [reportData]);

  useEffect(() => {
    if (dataSource) {
      setSearchedReportList(dataSource);
    }
  }, [dataSource]);

  const columns = [
    {
      title: "Report Name",
      dataIndex: "report_name",
      key: "1",
      sorter: true,
    },
    {
      title: "Default",
      dataIndex: "default",
      key: "2",
      render: (_, reportData) => (
        <Radio
          checked={reportData.report_id == reportData.default_dashboard_id}
          onChange={() => updateDefaultDashboard(reportData.report_id)}
        ></Radio>
      ),
    },
    {
      title: "View",
      dataIndex: "report_view",
      key: "3",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "4",
    },
    {
      title: "Status",
      dataIndex: "report_id",
      key: "5",
      render: (_, reportData) =>
        reportData.status != null ? (
          <Switch
            defaultChecked
            onChange={() => updateReportStatus(0, reportData.report_id)}
          ></Switch>
        ) : (
          <Switch
            onChange={() => updateReportStatus(1, reportData.report_id)}
          ></Switch>
        ),
    },
  ];

  const userColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    const limit = pagination.pageSize;
    let params = `page=${pagination?.current}`;

    if (sorter.hasOwnProperty("column")) {
      const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
      //params += `&sort_column=${sorter.field}&sort_order=${sortOrder}`;
      dispatch(
        getRoleDetails(`&sort_column=${sorter.field}&sort_order=${sortOrder}`)
      );
    }

    if (searchText) {
      dispatch(getRoleDetails(`search=${searchText}`));
    }
    dispatch(getRoleDetails(`role_id=${id}&${params}&limit=${limit}`));
  };

  const onRoleStatusChange = () => {
    setRoleStatus((roleStatus) => !roleStatus);
    dispatch(toggleRoleStatus({ status: !roleStatus, role_id: id }));
  };

  const onTypeChange = (e) => {
    setRoleType(e.target.value);
    dispatch(changeRoleType({ role_type: e.target.value, role_id: id }));
  };

  const filterReportsByService = (service_id, event) => {
    dispatch(getRoleDetails(`role_id=${id}&service_id=${service_id}`));
  };

  const updateDefaultDashboard = (report_id) => {
    dispatch(setDefaultDashboard({ report_id: report_id, role_id: id }));
  };

  const updateReportStatus = (status, report_id) => {
    dispatch(
      setReportStatus({
        report_status: status,
        report_id: report_id,
        role_id: id,
      })
    );
  };

  const onUpdatePermissions = (checkedValues) => {
    setImportPermissions(checkedValues);
    dispatch(
      updateSalesforcePermission({
        salesforce_permissions: checkedValues,
        role_id: id,
      })
    );
  };
  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Roles", link: "/roles", icon: FiList },
    {
      text: roleInfo.role_name,
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="panel-heading">
        <Row>
          <Col span={24} className="panel-heading-title">
            <h2>{roleInfo.role_name}</h2>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <div className="panel-body-content roles-view-wrapper">
          <Spin spinning={isLoading}>
            <Row className="panel-body-input" gutter={40}>
              <Col
                lg={{ span: 12 }}
                xs={{ span: 24 }}
                className="roles-status-row"
              >
                <div className="role-status-left role-status-wrapper">
                  <label>Role Status</label>
                  <Switch
                    checked={roleStatus}
                    onChange={onRoleStatusChange}
                  ></Switch>
                </div>
              </Col>
              <Col
                lg={{ span: 12 }}
                xs={{ span: 24 }}
                className="roles-status-row"
              >
                <div className="role-status-right role-status-wrapper">
                  <label className="rsr-title">Type</label>
                  <Radio.Group
                    value={roleType}
                    options={[
                      {
                        label: "Internal - Customer Facing",
                        value: 2,
                        key: "internal_customer_facing",
                      },
                      {
                        label: "Internal - Other",
                        value: 3,
                        key: "internal_other",
                      },
                      { label: "Customer", value: 4, key: "customer" },
                    ]}
                    onChange={onTypeChange}
                  />
                  {roleType != 4 ? (
                    <Checkbox.Group
                      options={[
                        {
                          label: "Full Salesforce Upload",
                          value: "sf_full",
                          key: "sf_full",
                        },
                        {
                          label: "Single Account Salesforce Upload",
                          value: "sf_single",
                          key: "sf_single",
                        },
                      ]}
                      value={importPermissions}
                      onChange={onUpdatePermissions}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
            <Row className="panel-body-content" gutter={40}>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <div className="pbc-upper">
                  <h2>Reports and Dashboards</h2>
                  <Select
                    style={{ width: 250 }}
                    onSelect={(value, event) =>
                      filterReportsByService(value, event)
                    }
                    options={services}
                    placeholder="All Services"
                  />
                </div>
                {hasSearched ? (
                  <Table
                    columns={columns}
                    key={(record) => record.primaryKey}
                    dataSource={searchedReportList}
                    onChange={handleChange}
                    loading={isLoading}
                    pagination={false}
                  />
                ) : (
                  <h2 className="text-center no-result">
                    Nothing to display....
                  </h2>
                )}
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <div className="pbc-upper">
                  <h2>Users</h2>
                  <AddUserRole role_id={id} />
                </div>
                {hasSearched ? (
                  <Table
                    columns={userColumns}
                    key={(record) => record.primaryKey}
                    dataSource={userData}
                    onChange={handleChange}
                    loading={isLoading}
                  />
                ) : (
                  <h2 className="text-center no-result">
                    Nothing to display....
                  </h2>
                )}
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    </>
  );
}

export default RoleView;
