import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
const CustomBreadcrumb = ({ items }) => {
	return (
		<Breadcrumb className="main-breadcrumb" separator={<BsChevronRight />}>
			{items.map((item, index) => (
				<Breadcrumb.Item key={index}>
					<Link to={item.link}>
						{item.icon && React.createElement(item.icon)} {item.text}
					</Link>
				</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
};

export default CustomBreadcrumb;
