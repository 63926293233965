import API_BI from "../Utils/axios_bi";
import { ApiEndpointsBI } from "../Utils/constant";

const {
  USERS,
  ALL_ROLES,
  ROLES,
  GET_ACTIVE_REPORTS,
  CREATE_NEW_ROLE,
  ROLESTATUSUPDATE,
  ROLE_DETAILS,
  DELETE_ROLE,
  GET_USER_DETAIL,
  CREATE_UPDATE_USER,
  TOGGLE_USER_STATUS,
  DELETE_USER,
  TOGGLE_ROLE_STATUS,
  CHANGE_ROLE_TYPE,
  DEFAULT_DASHBOARD_LIST,
  SET_PROFILE_DASHBOARD,
  SET_DEFAULT_DASHBOARD,
  SAVE_FEEDBACK,
  SAVE_ALERT_VIEW_LOG,
  SET_REPORT_STATUS,
  UPDATE_SALESFORCE_PERMISSION,
  GET_ACTIVE_USERS,
  ADD_USERS_ROLE,
  SAVE_NOTIFICATION_LOG,
} = ApiEndpointsBI;

export const getUsers = async (params = "") => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(`${USERS}?${params}`);
};

export const getRoles = async (params = "") => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(`${ROLES}?${params}`);
};

export const getActiveReports = async (params = "") => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(`${GET_ACTIVE_REPORTS}?${params}`);
};

export const createNewRole = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(CREATE_NEW_ROLE, props);
};

export const getRolesAfterStatusUpdate = async (role_id, status) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(ROLESTATUSUPDATE, { role_id, status });
};

export const getAllRoles = async (params = "") => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(`${ALL_ROLES}?${params}`);
};

export const getRoleDetails = async (params = "") => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(`${ROLE_DETAILS}?${params}`);
};

export const deleteRole = async (role_id) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(DELETE_ROLE, { role_id });
};

export const getUserDetail = async (user_id) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(GET_USER_DETAIL, { user_id });
};

export const createUpdateUser = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(CREATE_UPDATE_USER, props);
};

export const toggleUserStatus = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(TOGGLE_USER_STATUS, props);
};

export const deleteUser = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(DELETE_USER, props);
};

export const toggleRoleStatus = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(TOGGLE_ROLE_STATUS, props);
};

export const changeRoleType = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(CHANGE_ROLE_TYPE, props);
};

export const getDefaultDashboardList = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(DEFAULT_DASHBOARD_LIST, props);
};

export const setProfileDashboard = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(SET_PROFILE_DASHBOARD, props);
};

export const setDefaultDashboard = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(SET_DEFAULT_DASHBOARD, props);
};

export const saveFeedback = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(SAVE_FEEDBACK, props);
};

export const setAlertViewLog = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(SAVE_ALERT_VIEW_LOG, props);
};

export const setReportStatus = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(SET_REPORT_STATUS, props);
};

export const updateSalesforcePermission = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(UPDATE_SALESFORCE_PERMISSION, props);
};

export const getActiveUsers = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(GET_ACTIVE_USERS, props);
};

export const addUsersToRole = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(ADD_USERS_ROLE, props);
};

export const saveNotificationLog = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(`${SAVE_NOTIFICATION_LOG}?${props}`);
};
