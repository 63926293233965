import * as ColumnLineMixSourcesApi from "../../../Api/ColumnLineMixSourcesApi";
import * as columnLineAction from "../columnLineAction";
import { ACTIONS_TYPES } from "../../Reducers/columnLineReducer";

export const getColumnLineData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_COLUMN_LINE_CHART,
      });
      let columnLineData = {};
      const response = await ColumnLineMixSourcesApi.getColumnLineSources(
        props,
        data
      );
      if (response?.status === 200) {
        columnLineData = {
          [data.block.block_id]: {
            chart_data: response?.data?.json_data_chart,
            xAxisCategories: response?.data?.labels,
            table_data: response?.data?.table_data,
            channels_labels: response?.data?.channels_labels,
          },
        };
      }
      return dispatch(columnLineAction.fetchColumnLineData(columnLineData));
    } catch (error) {
      console.error("Error in fetching: " + error);
    } finally {
    }
  };
};
