import { Form, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getFacebookAdsInsightsDataSourcesThunk,
  getAllFacebookAdsInsightsDataSourcesThunk,
  updateFacebookAdsInsightssDataSourcesThunk,
} from "../../../Redux/Actions/Thunk/datasources/ds_configs/FacebookAdsInsightsThunkMCC";
import FacebookAdsInsightsUpdateConfigurationForm from "../../../Components/Configuration/facebookAdsInsights/FacebookAdsUpdateForm";

const { Title } = Typography;

const DsFacebookAdsUpdate = () => {
  const [form] = Form.useForm();

  const history = useHistory();
  const {
    accounts_mcc: { auth_params },
  } = useSelector((state) => state.facebookAdsInsightsMcc);

  // get id from params
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFacebookAdsInsightsDataSourcesThunk({ id }));
  }, [id]);

  const fetchFacebookAdsData = async (id) => {
    // call to api for now let's say following has been returned
    const googleAdsConfiguration = {
      id: auth_params?.id,
      user: auth_params?.user,
    };
    form.setFieldsValue(googleAdsConfiguration);
  };

  useEffect(() => {
    fetchFacebookAdsData(id);
  }, [id, auth_params]);

  const handleSubmit = (values) => {
    const props = {
      id: auth_params?.id,
      user: values.user,
    };
    dispatch(updateFacebookAdsInsightssDataSourcesThunk(props, history));
  };

  const handleCancel = () => {
    history.push("/google-ads-configuration");
  };
  return (
    <>
      <Title title="2">Update Facebook Ads Insights Configuration</Title>
      <div
        style={{
          width: "800px",
        }}
      >
        <FacebookAdsInsightsUpdateConfigurationForm
          onFinish={handleSubmit}
          onFinishFailed={handleCancel}
          form={form}
        />
      </div>
    </>
  );
};

export default DsFacebookAdsUpdate;
