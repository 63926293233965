import React, { useCallback, useMemo } from 'react'
import { Button, Form, Input, Modal, Select } from 'antd';

const STRING_FILTER_CONDITIONS = {
  equal: 'Equal to',
  not_equal: 'Not Equal to',
  like: 'Contains',
  not_like: 'Does not contains'
}

const INT_FILTER_CONDITIONS = {
  equal: 'Equal to',
  less: 'Less than',
  less_equal: 'Less than or Equal to',
  greater: 'Greater than',
  greater_equal: 'Greater than or Equal to',
  not_equal: 'Not Equal to',
  like: 'Contains',
  not_like: 'Does not contains'
}

const ColumnFilterModal = (props) => {
  const {
    form,
    format,
    setFormat,
    dataSource,
    tableColumns,
    isFilterModalOpen,
    handleFilterColData,
    handleFilterModalCancel,
  } = props
  
  const { Option } = Select;

  const handleSelectChange = useCallback((value) => {
      const selectedCol =  tableColumns.find(obj => obj.dataIndex === value);

      setFormat(selectedCol?.format);
  }, [tableColumns, setFormat])

  const onFinish = () => {
    const filterValue = form.getFieldsValue()['filter_value'];
    
    const filteredColData = dataSource.filter(obj => {
      const value = obj[form.getFieldsValue()['filter_column']];
      const condition = form.getFieldsValue()['filter_condition'];

          if (!format) {
              switch (condition) {
                case 'equal':
                  return value?.toLowerCase() === filterValue?.toLowerCase();
                case 'not_equal':
                  return value?.toLowerCase() !== filterValue?.toLowerCase();
                case 'like':
                  return value?.toString()?.toLowerCase()?.includes(filterValue?.toLowerCase());
                case 'not_like':
                  return !value?.toString()?.toLowerCase()?.includes(filterValue?.toLowerCase());
                default:
                  return true
              }
          } else if (format) {
            const parsedVal = parseInt(value);
            const parsedFilterVal = parseInt(filterValue);

            switch (condition) {
                case 'equal':
                  return parsedVal === parsedFilterVal;
                case 'less':
                  return parsedVal < parsedFilterVal;
                case 'less_equal':
                  return parsedVal <= parsedFilterVal;
                case 'greater':
                  return parsedVal > parsedFilterVal;
                case 'greater_equal':
                  return parsedVal >= parsedFilterVal;
                case 'not_equal':
                  return parsedVal !== parsedFilterVal;
                case 'like':
                  return parsedVal?.toString().includes(parsedFilterVal?.toString());
                case 'not_like':
                  return !parsedVal?.toString().includes(parsedFilterVal?.toString());
                default:
                  return true
            }
          }

          return true; 
      });

      handleFilterColData(filteredColData);
      handleFilterModalCancel();
  }

  const handleFilterClear = () => {
    handleFilterColData(dataSource);
    
    handleFilterModalCancel();

    form.setFieldsValue({
      filter_value: '',
      filter_column: '',
      filter_condition: 'equal'
    });
  }

  const renderOptions = useMemo(() => (optObj) => (
      Object.entries(optObj).map(([optionValue, optionDisplayString]) => 
        <Option value={optionValue}>{optionDisplayString}</Option>
      )
  ),[])

  return (
    <Modal
        title="FILTER COLUMN"
        visible={isFilterModalOpen}
        onCancel={() =>{ 
                          handleFilterModalCancel(false);
                          form.setFieldsValue({filter_column: ''})
                        }
                  }
        onOk={form.submit}
        className="datatable-filter-modal"
        width={700}
        footer={null}
      >
        <Form
          form={form}
          name="form_modal"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <div className="filter-form-options">
            <Form.Item
              label="Field :"
              name="filter_column"
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Select a column"
                onChange={(e) => handleSelectChange(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {tableColumns.length > 0 &&
                  tableColumns?.map((column) => {
                    if (column?.children) {
                      return column.children.map((col) => (
                        <Option value={col.dataIndex} id={col.dataIndex}>
                          {col.title}
                        </Option>
                      ));
                    } else {
                      return (
                        <Option value={column.dataIndex} id={column.dataIndex}>
                          {column.title}
                        </Option>
                      );
                    }
                  })}
              </Select>
            </Form.Item>

            <Form.Item name="filter_condition" label="Type :">
              <Select placeholder="Select a type" optionFilterProp="children">
                {
                  !format ? renderOptions(STRING_FILTER_CONDITIONS) : renderOptions(INT_FILTER_CONDITIONS) 
                }
              </Select>
            </Form.Item>

            <Form.Item name="filter_value" label="Value :">
              <Input placeholder="value to filter" />
            </Form.Item>
          </div>
          <div className="filter-form-buttons">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Filter
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="button"
                onClick={handleFilterClear}
                danger
              >
                Clear Filter
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
  )
}

export default ColumnFilterModal;
