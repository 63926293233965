import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Donut from "../../Highcharts/Donut";
import Column from "../../Highcharts/Column";
import { formDataAppend } from "../../../Utils/helpers";
import { Spin } from "antd";
import Config from "../../../Config";
import $ from "jquery";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getStackedBarGraphData } from "../../../Redux/Actions/Thunk/barGraphThunk";

const {
  API: { baseURL, authKey },
} = Config;

const StackedBar = (props) => {
  const {
    graph: {
      graph: {
        [props?.block?.block_id]: {
          data: barData = [],
          json_data: json_data = [],
          xlabels: xlabels = [],
          page_details: page_details = [],
          color_code: color_code = [],
        } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const backgroundColor = props.backgroundColor || "#FFF";
  /*for filter data*/
  const [filter, setFilter] = useState("");
  const { filterData } = props;

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getStackedBarGraphData(formData, props));
  }, [filterData]);
  /*end for filter data*/

  const result = page_details.map(({ color_code }) => ({ color_code }));
  const pluck = (arr, key) => arr.map((i) => i[key]);
  var color_code_list = pluck(page_details, "color_code");

  const config = JSON.parse(props?.block?.config);

  let height = config["height"];
  let width = config["width"];

  const stackedBarChart = {
    chart: {
      type: "bar",
      height: height,
      width: width,
      backgroundColor: backgroundColor,
    },
    colors: color_code,
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: { categories: xlabels },
    yAxis: {
      reversedStacks: false,
      min: 0,
      max: 100,
      title: {
        text: "",
      },
      labels: {
        format: "{value}%",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        events: {
          legendItemClick: function () {
            $(".tooltip").remove();
          },
        },
      },
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormatter: function () {
        return (
          this.series.name +
          ": " +
          Highcharts.numberFormat(this.percentage, 2, ".", ",") +
          "%" +
          "<br/>Total: 100%"
        );
      },
    },
    series: page_details,
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <HighchartsReact
          highcharts={Highcharts}
          options={stackedBarChart}
        ></HighchartsReact>
      </Spin>
    </>
  );
};

export default StackedBar;
