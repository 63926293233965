import { ACTIONS_TYPES } from "../../../Reducers/datasources/ds_config/dsResetHistoricalDataReducer";

export function getDsResetHistoricalDataConfigServiceDataSource(payload) {
  return {
    type: ACTIONS_TYPES.RESET_HISTORICAL_DATA_CONFIG_SERVICE_ACCOUNT_DS,
    payload,
  };
}

export function failedDsResetHistoricalDataConfigServiceDataSource(payload) {
  return {
    type: ACTIONS_TYPES.FAILED_RESET_HISTORICAL_DATA_SERVICE_ACCOUNT_DS,
    payload,
  };
}
