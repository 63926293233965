import { Button, Form, Input } from "antd";

const BingAdsCreateConfigurationForm = ({ form, onFinish, onFinishFailed }) => {
  const handleChange = (info) => {
    return info;
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        name="google_console_form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Client Id"
          name="client_id"
          rules={[
            {
              required: true,
              message: "Client Id is required",
            },
          ]}
        >
          <Input placeholder="Client Id" />
        </Form.Item>
        <Form.Item
          label="Client Secret"
          name="client_secret"
          rules={[
            {
              required: true,
              message: "Client Secret is required",
            },
          ]}
        >
          <Input placeholder="Client Secret" />
        </Form.Item>

        <Form.Item
          label="User"
          name="user"
          rules={[
            {
              required: true,
              message: "user is required",
            },
          ]}
        >
          <Input placeholder="user email" />
        </Form.Item>
        {/* <Form.Item name="key_file" label="Key File" valuePropName="fileList">
          <Input onChange={handleChange} type="file" />
        </Form.Item> */}

        <Form.Item
          label="Environment"
          name="environment"
          rules={[
            {
              required: true,
              message: "Environment is required",
            },
          ]}
        >
          <Input placeholder="Environment" />
        </Form.Item>
        <Form.Item
          label="Redirect URI"
          name="Redirect_uri"
          rules={[
            {
              required: true,
              message: "Redirect URI is required",
            },
            {
              type: "url",
              message: "Please enter a Valid URI",
            },
          ]}
        >
          <Input placeholder="Redirect URI" />
        </Form.Item>
        <Form.Item
          label="Platform "
          name="platform"
          rules={[
            {
              required: false,
              message: "Platform is required",
            },
          ]}
        >
          <Input placeholder="Platform" />
        </Form.Item>

        <Form.Item
          label="File Type "
          name="key_type"
          rules={[
            {
              required: true,
              message: "File type is required",
            },
          ]}
        >
          <Input placeholder="File Type" />
        </Form.Item>

        <Form.Item label="Customer ID " name="customer_id" type="number">
          <Input placeholder="Customer ID" />
        </Form.Item>

        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default BingAdsCreateConfigurationForm;
