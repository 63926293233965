import FormItem from 'antd/lib/form/FormItem'
import { Button, Form, Input, message } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import React, { useCallback, useMemo, useState } from 'react'

import Config from '../../Config'
import { getUserId } from '../../Utils/user'

const {
  API_BI: { baseURL },
} = Config;

const ChangePassword = ({closeChangePassword, handleCancel}) => {
  const [inChangeProgress, setInChangeProgress] = useState(false);

  const [form] = Form.useForm();
  const userId = useMemo(() => getUserId(),[]);

  const validatePassword = useCallback((_, value) => {
    if (!value || value.length < 8) {
      return Promise.reject(new Error('Password must be at least 8 characters long'));
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(new Error('Password must contain at least one uppercase letter'));
    }
    if (!/[0-9]/.test(value)) {
      return Promise.reject(new Error('Password must contain at least one number'));
    }
    if (!/[@$!%*?&]/.test(value)) {
      return Promise.reject(new Error('Password must contain at least one special character'));
    }
    return Promise.resolve();
  },[]);

  const handleSubmit = useCallback(async (values) => {
    let valuesWithUser = {...values, userId};
    setInChangeProgress(true)
    try {
      const response = await fetch(`${baseURL}api/v1/change-password`, {
        method: "POST",
        body: JSON.stringify(valuesWithUser),
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.message === 'Current password does not match!') {
          setInChangeProgress(false);
          form.setFields([{
            name: 'currentPassword',
            error: [data.message],
          }]);
        } else {
          setInChangeProgress(false);
          message.error(data.message || 'An error occurred');
        }
      } else {
        setInChangeProgress(false);
        message.success(data.message);
        form.resetFields();
        handleCancel();
      }
    } catch (error) {
      setInChangeProgress(false);
      console.error('Fetch error: ', error);
      message.error('An error occurred while changing the password.');
    }
  }, [form, handleCancel, userId]);


  return (
    <>
      <ArrowLeftOutlined onClick={closeChangePassword} />
      <div className='profile-modal-title mt-10 '>
        <h2>Change Password</h2>
      </div>
      <Form
        form={form}
        name='changePassword'
        onFinish={handleSubmit}
        layout='vertical'
      >
        <FormItem
          
          name="currentPassword"
          label="Current Password::"
          rules={[
            {
              required: true,
              message: "Please input your Current Password!",
              whitespace: true,
            }
          ]}
        >
          <Input.Password  placeholder= "Current Password"/>
        </FormItem>
        <FormItem
          name="newPassword"
          label="New Password::"
          rules={[
            {
              required: true,
              message: "Please input your New Password!",
              whitespace: true,
            },
            { validator: validatePassword }
          ]}
        >
          <Input.Password placeholder= "New Password"/>
        </FormItem>
        <FormItem
          name="confirmPassword"
          label="Confirm Password::"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
              whitespace: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder= "Confirm Password"/>
        </FormItem>
        <FormItem>
            <Button type="primary" htmlType="submit" disabled={inChangeProgress}>
              Change Password
            </Button>
        </FormItem>
      </Form>
    </>
  )
}

export default ChangePassword