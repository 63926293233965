import React, { useState }  from 'react'
import { Button, Modal, Form, Upload, Select, Table, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FaEye, FaArrowAltCircleLeft } from "react-icons/fa";

const columns = [
    {
        title:"Type",
        dataIndex: "type",
		key: "type",
    },
	{
		title: "Data File",
		dataIndex: "file",
		key: "file",
		render: (text) => <a>{text}</a>,
	},
	{
		title: "Date",
		dataIndex: "date",
		key: "date",
	},
    {
        title:"Detail",
        dataIndex: "detail",
		key: "detail",
        render:() => {
            return(
                <>
                    <div className="panel-action-button">
                        <Tooltip title="View Data Sources">
								<a
									target="_blank"
									href="#"
								>
									<FaEye />
								</a>
							</Tooltip>
                    </div>
                </>
            )
        }
    }
];
const data = [
	{
		key: "1",
        type:"Organic Search Content Scorecard",
		file: "2022-11-09-06-47-49_goal_account.csv",
		date: "9 Nov 2022 06:47 am",
	},
	{
		key: "2",
         type:"Organic Search Content Scorecard",
		file: "2022-11-09-06-47-49_goal_account.csv",
		date: "9 Nov 2022 06:47 am",
	},
];

const BulkRequest = () => {
const [isModalOpen, setIsModalOpen] = useState(false);
const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
    const showHistoryModal = () => {
    setIsHistoryModalOpen(true);
     setIsModalOpen(false);
  };
  const handleHistoryOk = () => {
    setIsHistoryModalOpen(false);
  };
  const handleHistoryCancel = () => {
    setIsHistoryModalOpen(false);
  };
  return (
    <>
    	<Button onClick={showModal}>
			Bulk Request
		</Button>
        <Modal className="import-modals bulk-request-modal" title="Upload Bulk Request" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={600} okButtonProps={{ style: { display: 'none' } }} cancelText="Close">
			<Form>
            	<Form.Item
						label="Freemium Service"
						style={{ marginBottom: "20px" }}
				>
                <Select
                    default=""
                    placeholder="Select Service"
                    options={[
                        {
                        value: 'Organic Search Content Scorecard',
                        label: 'Organic Search Content Scorecard',
                        },
                    ]}
                    />
					</Form.Item>

				<Form.Item
						name="csv-filename"
						label="Upload File "
						valuePropName="fileList"
						style={{ marginBottom: "20px" }}
				>
				    <Upload name="logo" action="/upload.do">
							<Button icon={<UploadOutlined />}>Choose a File</Button>
						</Upload>
					</Form.Item>
                    <Form.Item
						name="upload"
						label=""
						className="import-modals-form-buttons"
					>
                    <Button icon={<FaEye />} onClick={showHistoryModal}>
							View Upload History
						</Button>
						<Button icon={<UploadOutlined />}>Upload</Button>
					</Form.Item>
				</Form>
        </Modal>
        			<Modal
				className="bulk-history-modal"
				title={
					<>
						<Button
							className="modal-back-btn"
							icon={<FaArrowAltCircleLeft />}
							onClick={() => {
								setIsModalOpen(true);
								setIsHistoryModalOpen(false);
							}}
						>
							Go Back
						</Button>
						BULK REQUEST HISTORY
					</>
				}
				visible={isHistoryModalOpen}
				onOk={handleHistoryOk}
				cancelText="Close"
				onCancel={handleHistoryCancel}
				okButtonProps={{ style: { display: "none" } }}
				width={800}
			>
				<Table columns={columns} dataSource={data} />
			</Modal>
    </>
        
  )
}

export default BulkRequest