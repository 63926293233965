import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import {
  setAlertViewLog,
  setProfileDashboard,
  defaultDashboardList,
} from "../../Redux/Actions/Thunk/userThunk";
import {
  Row,
  Col,
  Form,
  Modal,
  Image,
  Button,
  Upload,
  Select,
  message,
  Tooltip,
} from "antd";

import Config from "../../Config";
import API_BI from "../../Utils/axios_bi";
import { getUserId } from "../../Utils/user";
import ChangePassword from "./ChangePassword";

const {
  API_BI: { baseURL: baseURL },
} = Config;

const Profile = () => {
  const [devNote, setDevNote] = useState("");
  const [options, setOptions] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [avatarUpdated, setAvatarUpdated] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState(0);
  const [ isChangePassword, setIsChangePassword] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showAlertModal = () => {
    setIsAlertVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsChangePassword(false);
  };

  const handleAlertCancel = () => {
    setIsAlertVisible(false);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const {
    users: { default_dashboard_list: default_dashboard_list = {} },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isModalVisible) {
      dispatch(defaultDashboardList());
    }
  }, [isModalVisible]);
  useEffect(() => {
    setOptions([]);
    let dash_list = [{ label: "No Default Report", value: 0 }];
    default_dashboard_list.dashboards?.map((data) => {
      dash_list.push({ label: data.name, value: data.id, key: data.id });
    });
    setOptions(dash_list);
    if (default_dashboard_list.selected >= 0) {
      setSelectedDashboard(default_dashboard_list.selected);
      form.setFieldsValue({ default_dashboard: selectedDashboard });
    }
    if (default_dashboard_list.dev_note?.show == 1) {
      setIsAlertVisible(true);
      setDevNote(default_dashboard_list.dev_note?.note?.notes);
      dispatch(
        setAlertViewLog({ note_id: default_dashboard_list.dev_note?.note?.id })
      );
    }
  }, [default_dashboard_list]);
  useEffect(() => {
    form.setFieldsValue({ default_dashboard: selectedDashboard });
  }, [selectedDashboard]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    const user_id = getUserId();
    const data = new FormData();
    data.append("file", info.file.originFileObj);
    data.append("user_id", user_id);

    fetch(baseURL + "api/v1/user-avatar-upload", {
      method: "POST",
      body: data,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.ok) {
        setAvatarUpdated(true);
        response.json().then((json) => {
          previewAvatar(json.filename);
        });
      }
    });

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
      });
    }
  };

  const previewAvatar = (file_name) => {
    const formData = new FormData();
    formData.append("file_name", file_name);

    API_BI.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    API_BI.post(baseURL + "api/v1/user-avatar-preview", formData).then(
      (response) => {
        setAvatar(response.data);
      }
    );
  };

  const setDefaultDashboard = (data) => {
    setSelectedDashboard(data);
    dispatch(setProfileDashboard({ report_id: data }));
  };

  const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};

  if (isModalVisible && !avatarUpdated && default_dashboard_list?.avatar) {
    previewAvatar(default_dashboard_list.avatar);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const closeChangePassword = () => {
    setIsChangePassword(false);
  }

  return (
    <>
      <Tooltip placement="bottom" title="My Profile">
        <Button onClick={showModal}>
          {userInfo?.first_name.charAt(0)}
          {userInfo?.last_name.charAt(0)}
        </Button>
      </Tooltip>
      <div className="user-profile-wrapper">
        <Modal
          title=""
          visible={isModalVisible}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={handleCancel}
          cancelText="Close"
          className="profile-modal"
        >
          {
            isChangePassword ? 
              <ChangePassword
                closeChangePassword = {closeChangePassword}
                handleCancel = {handleCancel}
              /> 
            :
              <Form name="profile-form" form={form}>
                <Row>
                  <Col lg={{span: 6}} xs={{span: 12}}>
                    <Form.Item>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                      >
                        {avatar ? (
                          <Image
                            alt="Avatar"
                            src={`data:image/jpeg;base64,${avatar}`}
                            placeholder="Avatar"
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col lg={{span: 18}} xs={{span: 12}} className="profile-modal-title">
                    <h2>My Profile</h2>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="First Name">
                      <span className="ant-form-text">{userInfo?.first_name}</span>
                    </Form.Item>
                    <Form.Item label="Last Name">
                      <span className="ant-form-text">{userInfo?.last_name}</span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Phone">
                      <span className="ant-form-text">{userInfo?.mobile}</span>
                    </Form.Item>
                    <Form.Item label="Mobile">
                      <span className="ant-form-text">{userInfo?.phone}</span>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Email Address">
                      <span className="ant-form-text">{userInfo?.email}</span>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="default_dashboard" label="Default Report">
                      <Select onChange={setDefaultDashboard} options={options} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <button className="btn__transparent" onClick={() => setIsChangePassword(true)}>
                      Change Password
                    </button>
                  </Col>
                </Row>
              </Form>
          }
        </Modal>
        <Modal
          title="Alert"
          visible={isAlertVisible}
          okButtonProps={{ style: { display: "none" } }}
          onCancel={handleAlertCancel}
          cancelText="Close"
          className="alert-modal"
        >
          <div dangerouslySetInnerHTML={{ __html: devNote }}></div>
        </Modal>
      </div>
    </>
  );
};

export default Profile;
