import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { FaCog, FaQuestionCircle, FaTimesCircle } from "react-icons/fa";

import {
  Row,
  Col,
  Spin,
  Modal,
  Form,
  Input,
  Radio,
  Select,
  Button,
  Tooltip,
  Checkbox,
  DatePicker,
  Typography,
  InputNumber,
} from "antd";

import { getAccountDetail } from "../../Redux/Actions/Thunk/accountThunk";
import {
  getServiceSettings,
  saveServiceSettings,
} from "../../Redux/Actions/Thunk/serviceSettingsThunk";

const { Title } = Typography;
const numberRegex = /\B(?=(\d{3})+(?!\d))/g;

const AccountServiceSettings = ({
  account_id,
  service_id,
  service_name,
  service_status,
  budget_target_goal,
}) => {
  const location = useLocation();
  const settingsState = useSelector((state) => state.settings);

  const {
    service_settings: {
      setting: setting = null,
      service_info: service_info = [],
      default_setting: default_setting = null,
      budget_goal_setting: budget_goal_setting = null,
      service_fees_editable: service_fees_editable = true,
      budget_goal_master_service: budget_goal_master_service = "",
      budget_goal_combined_services: budget_goal_combined_services = "None",
    } = {},
    isLoading,
  } = settingsState;

  const settingObj = JSON.parse(setting);

  const [espFeeValue, setEspFeeValue] = useState(0);
  const [autoPopulate, setAutoPopulate] = useState(1);
  const [variableFees, setVariableFees] = useState([]);
  const [espFeeChecked, setEspFeeChecked] = useState(0);
  const [booleanOperator, setBooleanOperator] = useState(1);
  const [monthlyFeeValue, setMonthlyFeeValue] = useState(0);
  const [settingMonth, setSettingMonth] = useState(moment());
  const [serviceEndDate, setServiceEndDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [variableFeeOption, setVariableFeeOption] = useState(0);
  const [monthlyFeeChecked, setMonthlyFeeChecked] = useState(0);
  const [serviceStartDate, setServiceStartDate] = useState(null);
  const [billingResetDate, setBillingResetDate] = useState(null);
  const [variableFeeChecked, setVariableFeeChecked] = useState(0);
  const [autoPopulateFlexible, setAutoPopulateFlexible] = useState(0);
  const [additionalFeeChecked, setAdditionalFeeChecked] = useState(0);
  const [marketplaceId, setMarketplaceId] = useState("ATVPDKIKX0DER");
  const [budgetGoalsReadOnly, setBudgetGoalsReadOnly] = useState(false);
  const [monthlyCustomFeeChecked, setMonthlyCustomFeeChecked] = useState(0);

  const onCheckedStatusChange = useCallback(
    (e) => {
      if (e.target.id.includes("monthly_fee")) {
        setMonthlyFeeChecked(e.target.checked);

        form.setFieldsValue({
          monthly_fee: e.target.checked ? 1 : 0,
        });
      } else if (e.target.id.includes("monthly_custom_fee")) {
        setMonthlyCustomFeeChecked(e.target.checked);

        form.setFieldsValue({
          monthly_custom_fee: e.target.checked ? 1 : 0,
        });
      } else if (e.target.id.includes("esp_fee")) {
        setEspFeeChecked(e.target.checked);

        form.setFieldsValue({
          esp_fee: e.target.checked ? 1 : 0,
        });
      } else if (e.target.id.includes("additional_fee")) {
        setAdditionalFeeChecked(e.target.checked);

        form.setFieldsValue({
          additional_fee: e.target.checked ? 1 : 0,
        });
      } else if (e.target.id.includes("variable_fee")) {
        setVariableFeeChecked(e.target.checked);

        form.setFieldsValue({
          variable_fee: e.target.checked ? 1 : 0,
        });

        if (variableFees.length === 0 && e.target.checked) {
          setVariableFees([{ range: "", rate: "" }]);
        } else {
          setVariableFees((prevState) => [...prevState]);
        }
      }
    },
    [variableFees]
  );

  const handleTierValueChange = (index, event) => {
    let data = [...variableFees];
    data[index][event.target.name] = event.target.value;

    setVariableFees(data);

    form.setFieldsValue({
      variable_fees: data,
    });
  };

  const addTier = () => {
    setVariableFees((prevState) => [...prevState, { range: "", rate: "" }]);
  };

  const removeTier = (index) => {
    const newVariableFees = [...variableFees];
    newVariableFees.splice(index, 1);
    setVariableFees(newVariableFees);

    if (newVariableFees.length === 0) {
      setVariableFeeChecked(0);
      form.setFieldsValue({
        variable_fee: 0,
        variable_fees: [],
      });
    }
  };

  const dispatch = useDispatch();

  const showModal = useCallback(() => {
    if (service_id != 5) {
      setMarketplaceId(null);
    }
    dispatch(
      getServiceSettings({
        account_id: account_id,
        service_id: service_id,
        setting_month: moment(settingMonth).format("YYYY-MM"),
        marketplace_id: service_id == 5 ? marketplaceId : null,
      })
    );
    setIsModalVisible(true);
  }, [dispatch, account_id, service_id]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    dispatch(getAccountDetail({ account_id }));
  }, []);

  const onMarketplaceChange = useCallback((marketplace_id) => {
    setMarketplaceId(marketplace_id);
    dispatch(
      getServiceSettings({
        account_id: account_id,
        service_id: service_id,
        marketplace_id: marketplace_id,
        setting_month: moment().format("YYYY-MM"),
      })
    );
  });

  const onSettingMonthChange = useCallback(
    (date) => {
      setSettingMonth(date);
      dispatch(
        getServiceSettings({
          account_id: account_id,
          service_id: service_id,
          setting_month: moment(date).format("YYYY-MM"),
          marketplace_id: service_id === 5 ? marketplaceId : null,
        })
      );
      setIsModalVisible(true);
    },
    [dispatch, marketplaceId, account_id, service_id, setIsModalVisible]
  );

  const onServiceStartDateChange = (date) => {
    setServiceStartDate(date);

    form.setFieldsValue({
      service_start_date: moment(date).format("YYYY-MM-DD"),
    });
  };

  const onServiceEndDateChange = (date) => {
    setServiceEndDate(date);

    form.setFieldsValue({
      service_end_date: date && moment(date).format("YYYY-MM-DD"),
    });
  };

  const onBillingResetDateChange = (date) => {
    setBillingResetDate(date);

    form.setFieldsValue({
      billing_reset_date: moment(date).format("YYYY-MM-DD"),
    });
  };

  const percentSymbol = "%";
  const currencySymbol = "$";
  const [form] = Form.useForm();
  const defaultSettingObj = JSON.parse(default_setting);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (service_id == params.get("service_id")) {
      document.getElementById("btn_" + service_id).click();
    }
  }, []);

  useEffect(() => {
    const {
      monthly_fee: {
        checked: monthlyFeeChecked = 0,
        value: monthlyFeeValue = 0,
      } = {},
      monthly_custom_fee: {
        checked: monthlyCustomFeeChecked = 0,
        value: monthlyCustomFeeValue = 0,
      } = {},
      esp_fee: { checked: espFeeChecked = 0, value: espFeeValue = 0 } = {},
      additional_fee: {
        checked: additionalFeeChecked = 0,
        value: additionalFeeValue = 0,
      } = {},
      variable_fee: {
        checked: variableFeeChecked = 0,
        option_value: variableFeeOptionValue = 0,
      } = {},
      acos_maximum_goal: { value: acosMaximumGoalValue = 0 } = {},
      roas: { value: roasValue = 0 } = {},
      roas_target_goal: { value: roasTargetGoalValue = 0 } = {},
      monthly_budget: { value: monthlyBudgetValue = 0 } = {},
      budget_target_goal: { value: budgetTargetGoalValue = 0 } = {},
      revenue_goal: { value: revenueGoalValue = 0 } = {},
      latency_factor: { value: latencyFactorValue = 0 } = {},
      min_spend: { value: minSpendValue = 0 } = {},
      max_spend: { value: maxSpendValue = 0 } = {},
      service_kpi: { value: serviceKPIValue = 0 } = {},
    } = settingObj || defaultSettingObj || {};

    const espFeeCheckedStatus = espFeeChecked == 1;
    const monthlyFeeCheckedStatus = monthlyFeeChecked == 1;
    const variableFeeCheckedStatus = variableFeeChecked == 1;
    const additionalFeeCheckedStatus = additionalFeeChecked == 1;
    const monthlyCustomFeeCheckedStatus = monthlyCustomFeeChecked == 1;

    setEspFeeValue(espFeeValue);
    setMonthlyFeeValue(monthlyFeeValue);
    setEspFeeChecked(espFeeCheckedStatus);
    setVariableFeeOption(variableFeeOptionValue);
    setMonthlyFeeChecked(monthlyFeeCheckedStatus);
    setVariableFeeChecked(variableFeeCheckedStatus);
    setAdditionalFeeChecked(additionalFeeCheckedStatus);
    setMonthlyCustomFeeChecked(monthlyCustomFeeCheckedStatus);

    form.setFieldsValue({
      account_id,
      service_id,
      roas: roasValue,
      service: service_name,
      esp_fee: espFeeChecked,
      min_spend: minSpendValue,
      max_spend: maxSpendValue,
      esp_fee_value: espFeeValue,
      variable_fees: variableFees,
      service_kpi: serviceKPIValue,
      marketplace_id: marketplaceId,
      monthly_fee: monthlyFeeChecked,
      revenue_goal: revenueGoalValue,
      variable_fee: variableFeeChecked,
      monthly_fee_value: monthlyFeeValue,
      latency_factor: latencyFactorValue,
      monthly_budget: monthlyBudgetValue,
      additional_fee: additionalFeeChecked,
      roas_target_goal: roasTargetGoalValue,
      acos_maximum_goal: acosMaximumGoalValue,
      additional_fee_value: additionalFeeValue,
      budget_target_goal: budget_target_goal
        ? budget_target_goal
        : budgetTargetGoalValue,
      monthly_custom_fee: monthlyCustomFeeChecked,
      monthly_custom_fee_value: monthlyCustomFeeValue,
      setting_month: moment(settingMonth).format("YYYY-MM"),
      variable_fee_value: variableFeeOptionValue == 2 ? 2 : 1,
    });
  }, [account_id, service_id, service_name, service_status, settingsState]);

  useEffect(() => {
    form.setFieldsValue({
      variable_fees: variableFees,
    });
  }, [variableFees]);

  //destructuring the objects and using default values for cases where the object properties are undefined or the objects themselves are undefined. This makes the code more concise and easier to read.
  useEffect(() => {
    const {
      auto_populate: { value: autoPopulateValue } = {},
      auto_populate_flexible_budget_logic: {
        value: autoPopulateFlexibleValue,
      } = {},
      monthly_fee_value: {
        value: monthlyFeeValue,
        checked: monthlyFeeCheckedValue,
      } = {},
      boolean_operator: { option_value: booleanOperatorOptionValue } = {},
      boolean_operator: { value: booleanOperatorValue } = {},
      variable_fee: {
        option_value: variableFeeOptionValue,
        range_value: rangeValues = [],
      } = {},
    } = settingObj || defaultSettingObj || {};

    form.setFieldsValue({ monthly_fee_value: monthlyFeeValue });

    setAutoPopulate(autoPopulateValue);
    setMonthlyFeeChecked(monthlyFeeCheckedValue);
    setAutoPopulateFlexible(autoPopulateFlexibleValue);
    setBooleanOperator(
      booleanOperatorValue ? booleanOperatorValue : booleanOperatorOptionValue
    );
    setVariableFeeOption(variableFeeOptionValue);

    const result = rangeValues.map(([range, separator, rate]) => ({
      range,
      rate,
    }));
    setVariableFees(result);
    if (
      budget_goal_setting != null &&
      service_id != budget_goal_setting?.budget_goals_master_service &&
      budget_goal_setting?.budget_goals_combined_services
        ?.split(",")
        .includes(service_id.toString())
    ) {
      setBudgetGoalsReadOnly(true);
    }
  }, [setting, budget_goal_setting]);

  useEffect(() => {
    if (Object.keys(service_info).length > 0) {
      if (service_info?.service_start_date) {
        setServiceStartDate(moment(service_info?.service_start_date));
      } else {
        setServiceStartDate(null);
      }
      if (service_info?.service_end_date) {
        setServiceEndDate(moment(service_info?.service_end_date));
      } else {
        setServiceEndDate(null);
      }
      if (service_info?.billing_reset_date) {
        setBillingResetDate(moment(service_info?.billing_reset_date));
      } else {
        setBillingResetDate(null);
      }

      form.setFieldsValue({
        term_length: service_info?.term_length,
        service_start_date: service_info?.service_start_date
          ? moment(service_info?.service_start_date).format("YYYY-MM-DD")
          : null,
        service_end_date: service_info?.service_end_date
          ? moment(service_info?.service_end_date).format("YYYY-MM-DD")
          : null,
        billing_reset_date: service_info?.billing_reset_date
          ? moment(service_info?.billing_reset_date).format("YYYY-MM-DD")
          : null,
        original_service_start_date: service_info?.orginal_service_start_date
          ? moment(service_info?.orginal_service_start_date).format(
              "YYYY-MM-DD"
            )
          : null,
      });
    }
  }, [service_info]);

  if (booleanOperator != undefined) {
    form.setFieldsValue({ boolean_operator: parseInt(booleanOperator) });
  }

  if (autoPopulate != undefined) {
    form.setFieldsValue({ auto_populate: parseInt(autoPopulate) });
  }

  if (autoPopulateFlexible != undefined) {
    form.setFieldsValue({
      auto_populate_flexible_budget_logic: parseInt(autoPopulateFlexible),
    });
  }

  useEffect(() => {
    if (variableFeeOption != undefined && parseInt(variableFeeOption) == 2) {
      form.setFieldsValue({ variable_fee_value: 2 });
    } else if (variableFeeOption != undefined) {
      form.setFieldsValue({ variable_fee_value: 1 });
    }
  }, [variableFeeOption]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        values = JSON.stringify(values);
        dispatch(saveServiceSettings(values));
      })
      .catch((errorInfo) => {
        console.error(errorInfo);
      });
  };

  useEffect(() => {
    // Update form initial values when variableFees changes
    const initialRangeValues = variableFees.reduce((acc, tier, index) => {
      acc[`variable_fee_range-${index}`] = tier.range;
      // Add other initial values if needed
      return acc;
    }, {});
    form.setFieldsValue(initialRangeValues);

    const initialRateValues = variableFees.reduce((acc, tier, index) => {
      acc[`variable_fee_rate-${index}`] = tier.rate;
      return acc;
    }, {});
    form.setFieldsValue(initialRateValues);
  }, [form, variableFees]);

  return (
    <>
      <div className={service_status === 0 ? "as-passive" : ""}>
        <Button
          onClick={showModal}
          className={service_status === 0 ? "as-diabled" : ""}
          id={"btn_" + service_id}
        >
          <div className="panel-action-button">
            <Tooltip title="Settings">
              <span>
                <FaCog />
              </span>
            </Tooltip>
          </div>
        </Button>
      </div>
      <div className="service-settings-wrapper">
        <Modal
          title={"Service Settings - " + service_name}
          visible={isModalVisible}
          okText="Save"
          onOk={handleFormSubmit}
          onCancel={handleCancel}
          className="service-setting-form"
          width={700}
        >
          <Spin spinning={isLoading}>
            <Form name="service-settings" form={form}>
              <Form.Item hidden name="account_id">
                <Input type="hidden" value="" />
              </Form.Item>
              <Form.Item hidden name="service_id">
                <Input type="hidden" value="" />
              </Form.Item>
              <Form.Item hidden name="service">
                <Input type="hidden" value="" />
              </Form.Item>
              <Form.Item
                label="Marketplace"
                name="marketplace_id"
                hidden={service_id == 5 ? false : true}
              >
                <Select
                  defaultValue="ATVPDKIKX0DER"
                  style={{ width: 200 }}
                  onChange={onMarketplaceChange}
                  disabled={service_id == 5 ? false : true}
                  options={[
                    {
                      label: "North America",
                      options: [
                        {
                          label: "United States (US)",
                          value: "ATVPDKIKX0DER",
                        },
                        { label: "Canada (CA)", value: "A2EUQ1WTGCTBG2" },
                        { label: "Mexico (MX)", value: "A1AM78C64UM0Y8" },
                      ],
                    },
                    {
                      label: "Europe",
                      options: [
                        {
                          label: "United Kingdom (UK)",
                          value: "A1F83G8C2ARO7P",
                        },
                        { label: "Germany", value: "A1PA6795UKMFR9" },
                        { label: "France", value: "A13V1IB3VIYZZH" },
                        { label: "Spain", value: "A1RKKUPIHCS9HS" },
                        { label: "Italy", value: "APJ6JRA9NG5V4" },
                        { label: "Netherlands", value: "A1805IZSGTT6HS" },
                      ],
                    },
                    {
                      label: "Far East",
                      options: [
                        { label: "Japan", value: "A1VC38T7YXB528" },
                        { label: "Australia", value: "A39IBJ37TRP1C6" },
                      ],
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item label="For the Month" name="setting_month">
                <DatePicker
                  value={settingMonth}
                  onChange={onSettingMonthChange}
                  format="YYYY-MM"
                  picker="month"
                  inputReadOnly={true}
                />{" "}
              </Form.Item>
              <Form.Item
                label="Service Start Date"
                name="service_start_date"
                className="ssf-12"
              >
                <DatePicker
                  value={serviceStartDate?.isValid() && serviceStartDate}
                  onChange={onServiceStartDateChange}
                  format="YYYY-MM-DD"
                  inputReadOnly={true}
                />{" "}
              </Form.Item>
              <Form.Item
                label="Service End Date"
                name="service_end_date"
                className="ssf-12"
              >
                <DatePicker
                  value={serviceEndDate?.isValid() && serviceEndDate}
                  onChange={onServiceEndDateChange}
                  format="YYYY-MM-DD"
                  inputReadOnly={true}
                />{" "}
              </Form.Item>
              <Form.Item
                label="Original Service Start Date"
                name="original_service_start_date"
              >
                <Input type="text" value="" readOnly bordered={false} />
              </Form.Item>
              <Form.Item
                label="Term Length (months)"
                name="term_length"
                className="ssf-12"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9\.]+$/),
                    message: "Please input a valid number",
                  },
                ]}
              >
                <Input type="text" value="" style={{ width: "140px" }} />
              </Form.Item>
              <Form.Item
                label="Billing Reset Date"
                name="billing_reset_date"
                className="ssf-12"
              >
                <DatePicker
                  value={billingResetDate?.isValid() && billingResetDate}
                  onChange={onBillingResetDateChange}
                  format="YYYY-MM-DD"
                  inputReadOnly={true}
                />{" "}
              </Form.Item>

              <div
                className={
                  service_fees_editable
                    ? "service-fee-wrapper"
                    : "service-fee-wrapper service-fee-readonly"
                }
              >
                <Title level={5}>Fees</Title>

                {defaultSettingObj?.monthly_fee ? (
                  <>
                    <Form.Item name="monthly_fee" className="ssf-6">
                      <Checkbox
                        checked={monthlyFeeChecked}
                        onChange={onCheckedStatusChange}
                      >
                        {defaultSettingObj?.monthly_fee?.caption}
                      </Checkbox>
                    </Form.Item>
                    <p className="mf-sym">{currencySymbol}</p>
                    <Form.Item
                      name="monthly_fee_value"
                      className="ssf-6"
                      rules={[
                        {
                          required: monthlyFeeChecked,
                          pattern: new RegExp(/^[0-9\.]+$/),
                          message: "Please input a valid fee",
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(numberRegex, ",")
                        }
                        onChange={(value) =>
                          value > 0
                            ? (setMonthlyFeeChecked(true),
                              form.setFieldsValue({
                                monthly_fee: 1,
                              }))
                            : (setMonthlyFeeChecked(false),
                              form.setFieldsValue({
                                monthly_fee: 0,
                              }))
                        }
                        style={{ width: "140px" }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}

                {defaultSettingObj?.monthly_custom_fee ? (
                  <div className="fee-single-wrapper">
                    <Form.Item name="monthly_custom_fee" className="ssf-6">
                      <Checkbox
                        checked={monthlyCustomFeeChecked}
                        onChange={onCheckedStatusChange}
                      >
                        {defaultSettingObj?.monthly_custom_fee?.caption}
                      </Checkbox>
                    </Form.Item>

                    <p className="mf-sym">{currencySymbol}</p>
                    <Form.Item
                      className="ssf-6"
                      name="monthly_custom_fee_value"
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9\.]+$/),
                          message: "Please input a valid number",
                        },
                      ]}
                    >
                      <Input type="text" value="" style={{ width: "140px" }} />
                    </Form.Item>
                  </div>
                ) : (
                  ""
                )}

                {defaultSettingObj?.esp_fee ? (
                  <div className="fee-single-wrapper">
                    <Form.Item name="esp_fee" className="ssf-6">
                      <Checkbox
                        checked={espFeeChecked}
                        onChange={onCheckedStatusChange}
                      >
                        {" "}
                        {defaultSettingObj?.esp_fee?.caption}
                      </Checkbox>
                    </Form.Item>

                    <p className="mf-sym">{currencySymbol}</p>
                    <Form.Item
                      className="ssf-6"
                      name="esp_fee_value"
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9\.]+$/),
                          message: "Please input a valid number",
                        },
                      ]}
                    >
                      <Input type="text" value="" style={{ width: "140px" }} />
                    </Form.Item>
                    {monthlyFeeValue && espFeeValue ? (
                      <Form.Item>
                        <span>
                          <label>Total Fee:</label> $
                          {(
                            parseFloat(monthlyFeeValue) +
                            parseFloat(espFeeValue)
                          )
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                        </span>
                      </Form.Item>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {defaultSettingObj?.additional_fee ? (
                  <div className="fee-single-wrapper">
                    <Form.Item name="additional_fee">
                      <Checkbox
                        checked={additionalFeeChecked}
                        onChange={onCheckedStatusChange}
                      >
                        {" "}
                        {defaultSettingObj?.additional_fee?.caption}
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      name="additional_fee_value"
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9\.]+$/),
                          message: "Please input a valid number",
                        },
                      ]}
                    >
                      <Input type="text" value="" style={{ width: "140px" }} />
                    </Form.Item>
                  </div>
                ) : (
                  ""
                )}

                {defaultSettingObj?.boolean_operator ? (
                  <Form.Item name="boolean_operator" className="ssf-6">
                    <Radio.Group
                      disabled={variableFeeChecked == 1 ? false : true}
                      options={[
                        { label: "OR", value: 1 },
                        { label: "AND", value: 2 },
                      ]}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}

                {defaultSettingObj?.variable_fee &&
                defaultSettingObj?.variable_fee?.caption != "0" ? (
                  <>
                    <Form.Item name="variable_fee" className="ssf-6">
                      <Checkbox
                        checked={variableFeeChecked == 1}
                        onChange={onCheckedStatusChange}
                      >
                        {" "}
                        {defaultSettingObj?.variable_fee?.caption}
                      </Checkbox>
                    </Form.Item>

                    <Form.Item name="variable_fee_value" className="ssf-18">
                      <Radio.Group
                        disabled={variableFeeChecked == 1 ? false : true}
                        options={[
                          {
                            label: defaultSettingObj?.variable_fee?.option[0],
                            value: 1,
                          },
                          {
                            label: defaultSettingObj?.variable_fee?.option[1],
                            value: 2,
                          },
                        ]}
                      />
                    </Form.Item>
                    {defaultSettingObj?.variable_fee?.range_caption[0] !=
                    "0" ? (
                      <>
                        <Form.Item hidden name="variable_fees">
                          <input type="hidden" value="" />
                        </Form.Item>
                        <Row className="service-variables-title ">
                          <Col className="range-title" span={8}>
                            {defaultSettingObj?.variable_fee?.range_caption[0]}
                          </Col>
                          <Col className="range-rate" span={8}>
                            {defaultSettingObj?.variable_fee?.range_caption[2]}
                          </Col>
                          <Col className="range-action" span={8}></Col>
                        </Row>
                        {variableFees.map((tier, index) => {
                          return (
                            <Row
                              key={index}
                              className="service-variables-input "
                            >
                              <Col lg={{ span: 8 }} xs={{ span: 10 }}>
                                <Form.Item
                                  initialValue={tier.range}
                                  name={`variable_fee_range-${index}`}
                                  rules={[
                                    {
                                      required: variableFeeChecked,
                                      pattern: new RegExp(/^[0-9\.]+$/),
                                      message: "Please input a valid range",
                                    },
                                  ]}
                                >
                                  <input
                                    name="range"
                                    value={tier.range}
                                    disabled={
                                      variableFeeChecked == 1 ? false : true
                                    }
                                    onChange={(event) =>
                                      handleTierValueChange(index, event)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={{ span: 6 }} xs={{ span: 10 }}>
                                <Form.Item
                                  initialValue={tier.rate}
                                  name={`variable_fee_rate-${index}`}
                                  rules={[
                                    {
                                      required: variableFeeChecked,
                                      pattern: new RegExp(/^[0-9\.]+$/),
                                      message: "Please input a valid rate",
                                    },
                                  ]}
                                >
                                  <input
                                    name="rate"
                                    value={tier.rate}
                                    disabled={
                                      variableFeeChecked == 1 ? false : true
                                    }
                                    onChange={(event) =>
                                      handleTierValueChange(index, event)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                lg={{ span: 10 }}
                                xs={{ span: 4 }}
                                className="service-variables-btn"
                              >
                                <Button
                                  className="remove-tier-btn"
                                  disabled={
                                    variableFeeChecked == 1 ? false : true
                                  }
                                  onClick={() => removeTier(index)}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </Col>
                            </Row>
                          );
                        })}
                        <Button
                          className="addTierBtn"
                          type="primary"
                          disabled={variableFeeChecked == 1 ? false : true}
                          onClick={addTier}
                        >
                          Add Tier
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>

              {[2, 3, 4, 5, 8, 10, 14].includes(service_id) ? (
                <Title level={5}>Goals</Title>
              ) : (
                ""
              )}
              {[3, 4, 5, 10, 14].includes(service_id) ? (
                <div className="combined-services-tips">
                  <ul>
                    {budget_goal_setting != null &&
                    budget_goal_setting?.budget_goals_type == 1 ? (
                      <li>Services sharing goals: None</li>
                    ) : (
                      <li>
                        Services sharing goals:{" "}
                        {budget_goal_setting != null &&
                          budget_goal_combined_services}
                      </li>
                    )}
                    {budget_goal_setting != null &&
                    service_id ==
                      budget_goal_setting?.budget_goals_master_service ? (
                      <li>
                        Updates to all fields will be applied to the shared
                        goal, except Target Budget and Latency Factor
                      </li>
                    ) : (
                      ""
                    )}
                    {budget_goal_setting != null &&
                    service_id !=
                      budget_goal_setting?.budget_goals_master_service &&
                    budget_goal_setting?.budget_goals_combined_services
                      ?.split(",")
                      .includes(service_id.toString()) ? (
                      <li>
                        Updates to shared Goals must be made in the{" "}
                        {budget_goal_master_service} service settings
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              ) : (
                ""
              )}

              {defaultSettingObj?.auto_populate ? (
                <Form.Item label="Auto Populate Goals for Future Months">
                  <Form.Item noStyle name="auto_populate">
                    <Radio.Group
                      options={[
                        { label: "Auto", value: 1 },
                        { label: "Manual", value: 0 },
                      ]}
                    />
                  </Form.Item>
                  <Tooltip
                    title={
                      " Only Set Manual if goals are over automatically from the previous month "
                    }
                  >
                    <span style={{ color: "#0E0E0B", cursor: "pointer" }}>
                      <FaQuestionCircle />
                    </span>
                  </Tooltip>
                </Form.Item>
              ) : (
                ""
              )}

              {defaultSettingObj?.auto_populate_flexible_budget_logic ? (
                <Form.Item label="Flexible Budgets Logic">
                  <Form.Item noStyle name="auto_populate_flexible_budget_logic">
                    <Radio.Group
                      options={[
                        { label: "Auto", value: 1 },
                        { label: "Manual", value: 0 },
                      ]}
                    />
                  </Form.Item>
                  <Tooltip
                    title={
                      "  Manual requires all flexible budgets to be updated manually and Auto updates flexible budgets daily based on trends in ad spend  "
                    }
                  >
                    <span style={{ color: "#0E0E0B", cursor: "pointer" }}>
                      <FaQuestionCircle />
                    </span>
                  </Tooltip>
                </Form.Item>
              ) : (
                ""
              )}
              <Row>
                {defaultSettingObj?.acos_maximum_goal ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item
                      label={defaultSettingObj?.acos_maximum_goal?.caption}
                    >
                      <Form.Item
                        noStyle
                        name="acos_maximum_goal"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9\.]+$/),
                            message: "Please input a valid number",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          value=""
                          readOnly={budgetGoalsReadOnly}
                          className={
                            budgetGoalsReadOnly ? "read-only-input" : ""
                          }
                        />
                      </Form.Item>
                      <span style={{ right: "-5px" }}>{percentSymbol}</span>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.roas ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item label={defaultSettingObj?.roas?.caption}>
                      <Form.Item
                        name="roas"
                        noStyle
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9\.]+$/),
                            message: "Please input a valid number",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          value=""
                          readOnly={budgetGoalsReadOnly}
                          className={
                            budgetGoalsReadOnly ? "read-only-input" : ""
                          }
                        />
                      </Form.Item>
                      <span style={{ right: "-5px" }}>{percentSymbol}</span>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.roas_target_goal ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item
                      label={defaultSettingObj?.roas_target_goal?.caption}
                    >
                      <Form.Item
                        noStyle
                        name="roas_target_goal"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9\.]+$/),
                            message: "Please input a valid number",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          disabled={budgetGoalsReadOnly}
                          value=""
                          readOnly={budgetGoalsReadOnly}
                          className={
                            budgetGoalsReadOnly ? "read-only-input" : ""
                          }
                        />
                      </Form.Item>
                      <span style={{ right: "-5px" }}>{percentSymbol}</span>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.monthly_budget ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item
                      label={defaultSettingObj?.monthly_budget?.caption}
                    >
                      <span style={{ right: "95px" }}>{currencySymbol}</span>
                      <Form.Item
                        noStyle
                        name="monthly_budget"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9\.]+$/),
                            message: "Please input a valid number",
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(numberRegex, ",")
                          }
                          value=""
                          disabled={budgetGoalsReadOnly}
                          readOnly={budgetGoalsReadOnly}
                          className={
                            budgetGoalsReadOnly ? "read-only-input" : ""
                          }
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.budget_target_goal ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item
                      label={defaultSettingObj?.budget_target_goal?.caption}
                    >
                      <span style={{ right: "95px" }}>{currencySymbol}</span>
                      <Form.Item
                        noStyle
                        name="budget_target_goal"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9\.]+$/),
                            message: "Please input a valid number",
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(numberRegex, ",")
                          }
                          value=""
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.revenue_goal ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item label={defaultSettingObj?.revenue_goal?.caption}>
                      <span style={{ right: "95px" }}>
                        {defaultSettingObj?.revenue_goal?.caption?.indexOf(
                          "%"
                        ) == -1
                          ? currencySymbol
                          : ""}
                      </span>
                      <Form.Item
                        noStyle
                        name="revenue_goal"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9\.]+$/),
                            message: "Please input a valid number",
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(numberRegex, ",")
                          }
                          value=""
                          disabled={budgetGoalsReadOnly}
                          readOnly={budgetGoalsReadOnly}
                          className={
                            budgetGoalsReadOnly ? "read-only-input" : ""
                          }
                        />
                      </Form.Item>
                      <span style={{ right: "-5px" }}>
                        {defaultSettingObj?.revenue_goal?.caption?.indexOf(
                          "%"
                        ) >= 0
                          ? percentSymbol
                          : ""}
                      </span>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.latency_factor ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item
                      label={defaultSettingObj?.latency_factor?.caption}
                      name="latency_factor"
                    >
                      <Input type="text" value="" />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.min_spend ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item label={defaultSettingObj?.min_spend?.caption}>
                      <span style={{ right: "95px" }}>{currencySymbol}</span>
                      <Form.Item
                        noStyle
                        name="min_spend"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9\.]+$/),
                            message: "Please input a valid number",
                          },
                        ]}
                      >
                        <Input type="text" value="" />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.max_spend ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item label={defaultSettingObj?.max_spend?.caption}>
                      <span style={{ right: "95px" }}>{currencySymbol}</span>
                      <Form.Item
                        noStyle
                        name="max_spend"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9\.]+$/),
                            message: "Please input a valid number",
                          },
                        ]}
                      >
                        <Input type="text" value="" />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {defaultSettingObj?.service_kpi ? (
                  <Col span={12} className="default-form-single">
                    <Form.Item
                      label={defaultSettingObj?.service_kpi?.caption}
                      name="service_kpi"
                    >
                      <Input type="text" value="" />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {moment(settingMonth).format("YYYYMM") <
              moment().format("YYYYMM") ? (
                <div className="fees-alert-message">
                  <strong>Note:</strong> Fees will be recalculated upon Save if
                  changes to fees have been made.
                </div>
              ) : (
                ""
              )}
            </Form>
          </Spin>
        </Modal>
      </div>
    </>
  );
};

export default AccountServiceSettings;
