import { Typography } from "antd";
import React, { useCallback, useEffect } from "react";

import { Col, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import GoogleConsoleTable from "../../../Components/Configuration/bingAds/BingAdsTable";
import FacebookAdsInsightsCreateConfigurationForm from "../../../Components/Configuration/facebookAdsInsights/FacebookAdsInsightsCreateForm";
import { listOfAllDataSources } from "../../../Config/datasources/allDatasources";
import { facebookConfig } from "../../../Config/datasources/facebookConstants";
import { getAllBingAdsDataSourcesThunk } from "../../../Redux/Actions/Thunk/datasources/ds_configs/BingAdsThunk";
import { Token } from "../../../Utils/helpers/token";
import FacebookAdsInsightsTable from "../../../Components/Configuration/facebookAdsInsights/FacebookAdsInsightsTable";
import { getAllFacebookAdsInsightsDataSourcesThunk } from "../../../Redux/Actions/Thunk/datasources/ds_configs/FacebookAdsInsightsThunkMCC";

const { Title } = Typography;

const DsFacebookAdsInsights = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [facebookData, setFacebookData] = React.useState({
    client_key: "",
    redirect_uri: "",
  });

  const {
    facebookAdsInsightsMcc: { account_ds },
  } = useSelector((state) => state);

  //
  useEffect(() => {
    dispatch(getAllFacebookAdsInsightsDataSourcesThunk({}));
  }, [dispatch]);

  const params = new URL(window.location.href);
  const initializeFacebookConfig = useCallback(() => {
    facebookConfig.init();
    // initialize the token
  }, []);

  useEffect(() => {
    initializeFacebookConfig();
  }, [initializeFacebookConfig]);

  useEffect(() => {
    // window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?client_id=YOUR_APP_ID&redirect_uri=YOUR_REDIRECT_URI&scope=email`;
  }, []);

  const onFinish = (values) => {
    let url = facebookConfig.consineScreenOpenUrl;
    setLoading(true);

    const storageData = {
      dataSourceMetaData: {
        dataSourceName: listOfAllDataSources["facebook"],
        dataSourceUrl: params.pathname,
        isForUpdate: false,
        id: account_ds?.id,
      },
      starTableData: {
        // account_id: currentDataSouceAccountId,
        // bi_data_source_id: `${dsData.bi_data_source_id}`,
      },
      dataSourceInputDataByClientInForm: JSON.stringify(values),
    };
    const encryptedData = Token.encryptAuthParams(
      `${JSON.stringify(storageData)}`
    );

    // set data in local storage
    localStorage.setItem("data_source_information", encryptedData);

    window.location.href = url;
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div>
      <Title level={2}>FacebookAds Insights </Title>
      <Row wrap={true} gutter={[18, 10]} justify="space-between">
        <Col xs={24} md={14} sm={10}>
          <FacebookAdsInsightsTable />
        </Col>
        <Col xs={24} md={10} sm={10}>
          <Typography
            style={{
              textAlign: "center",
              padding: "10px 0 ",
            }}
          >
            ADD FacebookAds Insights ACCOUNT
          </Typography>
          <FacebookAdsInsightsCreateConfigurationForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DsFacebookAdsInsights;
