import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const useRemoveToken = () => {
 
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);

  // Remove the "token" parameter
  queryParams.delete('token');

  // Construct the updated query string
  const updatedQueryString = queryParams.toString();

  const currentUrl = window.location.pathname + (updatedQueryString ? '?' + updatedQueryString : '');

  useEffect(() => {
    const currentQuery = window.location.search;
    const searchParams = new URLSearchParams(currentQuery);
    const accessToken = searchParams.get("token");

    if (accessToken) {
      history.push(currentUrl);
    }
  }, []);
  return { currentUrl };
};

export default useRemoveToken;