import { useState, useRef } from "react";
import { DatePicker, Select, Switch, Form } from "antd";
import moment, { months } from "moment";
import { capitalizeFirstLetter } from "../../../src/Utils/helpers";
import React, { useEffect } from "react";

const { Option } = Select;

const CustomDate = (props) => {
  const pickerType = props?.block?.mode_type;
  const { RangePicker } = DatePicker;

  const dateFormat = props?.block?.format;
  const viewType = props?.block?.view_type;
  const defaultDateRange = props?.block?.default_date_range_option;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());
  const component_type = props?.block?.component_type;

  const storedArray = localStorage.getItem("datePersistVal");
  const datePersistVal = JSON.parse(storedArray);

  if (
    defaultDateRange != "this_month" &&
    defaultDateRange != "last_month" &&
    typeof JSON.parse(defaultDateRange) == "object" &&
    JSON.parse(defaultDateRange)[0]["startdate"] === "today"
  ) {
    var subDays = JSON.parse(defaultDateRange)[1]["subDays"];
    var defaultVal = moment().subtract(subDays, "day");
  } else if (defaultDateRange == "last_month") {
    var defaultVal = moment().subtract(1, "month");
  } else {
    var defaultVal = moment();
  }
  if (props?.block?.id == "created_date_range") {
    var defaultVal1 = ["", ""];
  } else {
    var defaultVal1 = [moment().startOf("month"), moment()];
  }

  const [dateRange, setDateRange] = useState(defaultVal1);
  const [dateRange1, setDateRange1] = useState(defaultVal);
  const dateValue = useRef(null);
  const id = props?.block?.id;

  if (pickerType == "month") {
    var customFormat = "MMM, YYYY";
  } else {
    var customFormat = "MMM DD, YYYY";
  }

  const handleDateChange = (value) => {
    dateValue.current = value;
    setDateRange(value);
  };

  const handleDatePickerChange = (value) => {
    dateValue.current = value;
    setDateRange1(value);
  };

  useEffect(() => {
    if (Object.keys(params_data) != 0) {
      if (params_data.hasOwnProperty("f_date")) {
        defaultVal = moment(params_data["f_date"]);
        setDateRange1(moment(params_data["f_date"]));
        if (props?.block?.id != "created_date_range") {
          setDateRange([
            moment(params_data["f_date"]),
            moment(params_data["t_date"]),
          ]);
        }
      } else if (params_data.hasOwnProperty("from_date")) {
        defaultVal = moment(params_data["from_date"]);
        setDateRange1(moment(params_data["from_date"]));
        if (props?.block?.id != "created_date_range") {
          setDateRange([
            moment(params_data["from_date"]),
            moment(params_data["to_date"]),
          ]);
        }
      } else {
        defaultVal = moment(params_data["f_month"]);
        setDateRange1(moment(params_data["f_month"]));
        if (props?.block?.id != "created_date_range") {
          setDateRange([
            moment(params_data["f_month"]),
            moment(params_data["t_month"]),
          ]);
        }
      }
    }
  }, [window.location.search]);

  const [form] = Form.useForm();

  props.form.setFieldsValue({
    ["CustomDateRange"]: dateRange,
    ["dateFormat"]: dateFormat,
    ["customDate"]: viewType != undefined ? viewType : "toDate",
    ["dateRange1"]: dateRange1,
    ["dateFormat"]: dateFormat,
  });

  return (
    <div className="Report-V2-filter-box Report-V2-filter-box-date ">
      <div className="Report-V2-filter-option-title">
        <span>{props?.block?.label}</span>
      </div>
      <div className="Report-V2-filter-box-date-wrapper">
        <div className="main-daterange">
          <Form.Item name="CustomDateRange"></Form.Item>
          <Form.Item name="customDate"></Form.Item>
          <Form.Item name="dateRange1"></Form.Item>
          <Form.Item name="dateFormat"></Form.Item>
          {viewType != undefined && viewType == "rangePicker" ? (
            <RangePicker
              dropdownClassName="main-daterange-custom"
              value={dateRange}
              onCalendarChange={(value) => setDateRange(value)}
              onChange={handleDateChange}
              // open={calendarOpen}
              // onOpenChange={handleCalendarOpenChange}
              format={customFormat}
              // getPopupContainer={getPopupContainer}
              picker={pickerType}
            />
          ) : (
            <DatePicker
              value={dateRange1}
              onCalendarChange={(value) => setDateRange1(value)}
              onChange={handleDatePickerChange}
              // open={calendarOpen}
              // onOpenChange={handleCalendarOpenChange}
              format={customFormat}
              // getPopupContainer={getPopupContainer}
              picker={pickerType}
              showToday={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomDate;
