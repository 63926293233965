import React, { useEffect, useState } from "react";
import { formDataAppend } from "../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Spin } from "antd";
import { getHTML } from "../../Redux/Actions/Thunk/accountReportThunk";

const { Title } = Typography;

function HTMLRender(props) {
    const {
        accountReports: {
            htmlData: {
                data: htmlData = [],
            },
            isLoading,
        } = {},
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    useEffect(() => {
        var data = props?.all_input_data;
        var formData = formDataAppend(data);
        dispatch(getHTML(formData, props));
    }, []);

    return (
        <>
            <Spin spinning={isLoading}>
                <Title level={2}>${htmlData && htmlData[0] && htmlData[0]['revenue'] ? htmlData[0]['revenue'].toLocaleString() : 0}</Title>
            </Spin>
        </>
    );
}

export default HTMLRender;
