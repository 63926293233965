import React, { useEffect, useState } from "react";
import StackedChart from "../../Highcharts/StackedChart";
import { useSelector } from "react-redux";
import { Spin } from "antd";
const OrganicChart = (props) => {
    // const [loading, setLoading] = useState(false);

    // const {
    //     isLoading
    // } = useSelector((state) => state);

	const format = "";
    const {
    graph: {
        graph: {
            [props?.block?.block_id]: {
                overall_organic_report: overall_organic_report
            } = {},
        } = {},
        isLoading,
    } = {},
   } = useSelector((state) => state);


	const getMonthName = (monthDay) =>{
		
		
		let parts = monthDay.split('/');
		let month = parseInt(parts[0], 10);

		let monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December"
		];

		let monthName = monthNames[month - 1];

		return monthName;
   }


    return (
        <>
            <div className="organic-scorecard-keyword organic-scorecard-single">
						<div className="os-single-title">
							<h4>Organic keyword positions</h4>
							<span>GRADE: {overall_organic_report?.grade}</span>
						</div>
						<div className="os-single-header">
							<div className="ossh-single">
								<h4>
									Total <br /> Keywords
								</h4>
								<h2>{overall_organic_report?.keywords.toLocaleString()}</h2>
							</div>
							<div className="ossh-single">
								<h4>
									Peak <br /> Month
								</h4>
								<h2>{overall_organic_report?.peak_month}</h2>
							</div>
							<div className="ossh-single">
								<h4>
									Peak Page 1 <br /> Rankings
								</h4>
								<h2>{overall_organic_report?.peak_seo.toLocaleString()}</h2>
							</div>
							<div className="ossh-single">
								<h4>
									Current Page 1 <br /> Rankings
								</h4>
								<h2>{overall_organic_report?.curr_seo.toLocaleString()}</h2>
							</div>
							<div className="ossh-single">
								<h4>
									Change <br /> Since Peak
								</h4>
								<h2>{overall_organic_report?.decrease && overall_organic_report?.decrease.toFixed(2)}%</h2>
							</div>
						</div>
						<div className="os-single-content">
							<div
								style={{
									textAlign: "center",
									fontSize: "20px",
									margin: "30px 0",
								}}
							>
								<StackedChart {...props} format={format}/>
							</div>
							<p>
								Your peak SEO performance occurred in <b>{overall_organic_report?.peak_month && getMonthName(overall_organic_report?.peak_month)} {overall_organic_report?.peak_year}</b> with <b>{overall_organic_report?.keywords.toLocaleString()}</b> keywords ranking in the top 10 on Google desktop.
								You currently have <b>{overall_organic_report?.curr_seo.toLocaleString()}</b> keywords in the top 10. Your
								current rankings are <b>{Math.abs(overall_organic_report?.decrease && overall_organic_report?.decrease.toFixed(2))}%</b> {overall_organic_report?.decrease < 0 ? "lower" : "higher"} than your peak.
							</p>
						</div>
						<div className="os-single-help">
							<h4>How we can help</h4>
							<p>
								The keywords you rank for are a leading indicator for organic
								traffic. If the number of keywords you rank for has recently
								dropped, we can help you recover your page 1 positions. Contact
								us for an SEO analysis as part of our free ecommerce growth
								plan.
							</p>
							<a href="https://agital.com/ecommerce-analysis/"
								target="_blank"
							>
								<button>Recover your lost rankings</button>
							</a>
						</div>
					</div>
        </>
    )
}

export default OrganicChart;