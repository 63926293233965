import API from "../Utils/axios";
import { ApiEndpoints } from "../Utils/constant";

const { TRENDS, TREND_BREAK_DOWN, DEVICE_PERFORMANCE } = ApiEndpoints;

export const getTrend = async (data, props) => {
  return await API.post(props?.block?.api_path, data);
};

export const getTrendBreakdown = async (data, props) => {
  return await API.post(props?.block?.api_path, data);
};

export const getDevicePerformance = async (data, props) => {
  return await API.post(props?.block?.api_path, data);
};
