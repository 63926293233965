/**
 * @fileoverview Reducer for Google Analytics Data Sources
 *
 * The reducer handles the state changes for the Google.
 */

// Define the initial state of the reducer
export const initialState = {
  account_ds: {}, // for single account data source
  account_dss: {}, // forall  account data sources . i used dss because of data sources . note "s" at the end, it is plural
  error_ds: {}, // error data sources
  isLoading: false, // loading state
};

// Define action types to be used in the reducer
export const ACTIONS_TYPES = Object.freeze({
  GOOGLE_ANALYTICS_DS_REQUEST: "GOOGLE_ANALYTICS_DS_REQUEST",
  GET_GOOGLE_ANALYTICS_DS: "GET_GOOGLE_ANALYTICS_DS", // Fetch Account Data Sources

  CREATE_GOOGLE_ANALYTICS: "CREATE_GOOGLE_ANALYTICS", // Create Account Data Sources
  UPDATE_GOOGLE_ANALYTICS: "UOPDATE_GOOGLE_ANALYTICS", // Update Account Data Sources
  GOOGLE_ANALYTICS_ERROR_DS: "GOOGLE_ANALYTICS_ERROR_DS", // Error Account Data Sources
  FAILED_GOOGLE_ANALYTICS: "FAILED_GOOGLE_ANALYTICS",
  RESET_GOOGLE_ANALYTICS: "RESET_GOOGLE_ANALYTICS",
});

// Define the reducer function
export function googleAnalyticsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  // Switch case to handle different types of actions
  switch (type) {
    case ACTIONS_TYPES.GOOGLE_ANALYTICS_DS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_GOOGLE_ANALYTICS_DS:
      return {
        ...state,
        isLoading: false,
        account_ds: action.payload,
      };
    case ACTIONS_TYPES.GET_ALL_GOOGL_ANALYTICSS_DS:
      return {
        ...state,
        isLoading: false,
        account_dss: action.payload,
      };

    case ACTIONS_TYPES.GOOGLE_ANALYTICS_ERROR_DS:
    case ACTIONS_TYPES.FAILED_GOOGLE_ANALYTICS:
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };

    case ACTIONS_TYPES.RESET_GOOGLE_ANALYTICS:
      return {
        ...state,
        isLoading: false,
        account_ds: {},
        account_dss: {},
        error_ds: {},
      };

    // Return the initial state if no matching action is found
    default:
      return state;
  }
}
