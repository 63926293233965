import { Col, Row, Layout } from "antd";
import { FaRegCopyright } from "react-icons/fa";

const { Footer } = Layout;

const EndFooter = () => {
  return (
    <Footer>
      <Row>
        <Col lg={{span: 12}} xs={{span: 18}} className="footer-left">
          <p>
            Copyright
            <FaRegCopyright />
            {new Date().getFullYear()}. All rights reserved.
          </p>
        </Col>
        <Col lg={{span: 12}} xs={{span: 6}} className="footer-right">
          <p>Version 0.1</p>
        </Col>
      </Row>
    </Footer>
  );
};
export default EndFooter;
