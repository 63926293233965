import React from "react";
import { FaDownload, FaFileExcel, FaFileCsv, FaFilePdf } from "react-icons/fa";
import { Dropdown, Menu, Button, Tooltip } from "antd";
const globalExportMenu = (props) => (
  <Menu
    className="global-export-dropdown"
    items={[
      //   {
      //     label: (
      //       <a href="#">
      //         <FaFileExcel />
      //         Excel
      //       </a>
      //     ),
      //     key: "0",
      //   },
      //   {
      //     label: (
      //       <a href="#">
      //         <FaFileCsv />
      //         CSV
      //       </a>
      //     ),
      //     key: "1",
      //   },
      {
        label: (
          <a href="#" onClick={() => props?.pdf_data()}>
            <FaFilePdf />
            PDF
          </a>
        ),
        key: "3",
      },
    ]}
  />
);

const GlobalExport = (props) => {
  return (
    <>
      <Dropdown overlay={globalExportMenu(props)}>
        <Tooltip title="Export">
          <Button>
            <FaDownload />
          </Button>
        </Tooltip>
      </Dropdown>
    </>
  );
};

export default GlobalExport;
