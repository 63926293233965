import React, { useEffect } from "react";
import { getChannelPerformanceData } from "../../Redux/Actions/Thunk/channelPerformanceThunk";
import { formDataAppend } from "../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Tooltip, Spin } from "antd";
import { format_data } from "../../Utils/helpers";
import ArrowInsights from "../Tables/arrow-insights";
import { FaInfoCircle } from "react-icons/fa";
import Highcharts from "highcharts";
import moment from "moment";

function ChannelPerformance(props) {
  const {
    channel_performance: {
      channel_performance: {
        [props?.block?.block_id]: { data: channelData = [] } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    const data = props?.all_input_data;
    const formData = formDataAppend(data);
    dispatch(getChannelPerformanceData(formData, props));
  }, []);

  const all_input_data = props?.all_input_data;
  const f_date = all_input_data["f_date"];
  const t_date = all_input_data["t_date"];
  const month = all_input_data["month"];

  let append_date;
  if (all_input_data["date_range_compareto"] === "None") {
    append_date = "&date_range_compareto=None";
  } else {
    const c_f_date = all_input_data["c_f_date"];
    const c_t_date = all_input_data["c_t_date"];
    append_date = `&c_f_date=${c_f_date}&c_t_date=${c_t_date}&month=${month}&date_range_option=${all_input_data["date_range_option"]}`;
  }

  if (all_input_data["component_type"] === "monthrangepicker") {
    const formatted_f_date = moment(all_input_data["f_date"]).format(
      "YYYYMMDD"
    );
    const formatted_t_date = moment(all_input_data["t_date"])
      .endOf("month")
      .format("YYYYMMDD");

    if (all_input_data["month_range_compareto"] === "None") {
      append_date = `&date_range_compareto=None&date_range_option=${all_input_data["date_range_option"]}`;
    } else {
      const c_f_date = moment(all_input_data["c_f_date"]).format("YYYYMMDD");
      const c_t_date = moment(all_input_data["c_t_date"])
        .endOf("month")
        .format("YYYYMMDD");
      append_date = `&c_f_date=${c_f_date}&c_t_date=${c_t_date}&month=${month}&date_range_option=${all_input_data["date_range_option"]}`;
    }
  }

  // Define priority mapping for sorting
  const priorityMap = {
    "Paid Social": 2,
    "Organic Social": 3,
  };

  // Sort channelData based on priority
  const sortedChannelData = channelData.sort((a, b) => {
    const aPriority = priorityMap[a.channel_name] || 1;
    const bPriority = priorityMap[b.channel_name] || 1;
    return aPriority - bPriority;
  });

  return (
    <>
      <Spin spinning={isLoading}>
        <Row>
          {!isLoading && sortedChannelData.length === 0 ? (
            <Col span={24}>
              <div style={{ textAlign: "center", padding: "20px" }}>
                <h2>No data available</h2>
              </div>
            </Col>
          ) : (
            sortedChannelData.map((table) => (
              <Col span={24} key={table.channel_name}>
                <div className="channel-performance-table">
                  <table aria-label="">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left", width: "13%" }}>
                          {table?.channel_name === "Email" ||
                          table?.channel_name === "Organic Social" ? (
                            table?.channel_name
                          ) : (
                            <a
                              href={`/reports/v2/view/${table?.report_key}/${props?.account_id}?f_date=${f_date}&t_date=${t_date}${append_date}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {table?.channel_name}
                            </a>
                          )}
                          {table?.channel_name === "Amazon Organic" && (
                            <Tooltip
                              title="Values may be negative if Ad performance exceeds Total Performance"
                              overlayClassName="heading-tooltip"
                            >
                              <span>
                                <FaInfoCircle />
                              </span>
                            </Tooltip>
                          )}
                        </th>
                        {table?.channel_name === "Amazon Organic" ? (
                          <th style={{ textAlign: "left", width: "7%" }}>
                            Buy Box Views
                            <Tooltip
                              title="Organic Buy Box Views = Buy Box Page Views - Ad Clicks (for Sponsored Products & Sponsored Display only)"
                              overlayClassName="heading-tooltip"
                            >
                              <span>
                                <FaInfoCircle />
                              </span>
                            </Tooltip>
                          </th>
                        ) : (
                          <th style={{ textAlign: "center", width: "7%" }}>
                            Sessions
                          </th>
                        )}
                        <th style={{ textAlign: "center", width: "6%" }}>
                          CHANGE
                        </th>
                        {table?.channel_name === "Organic Search" ||
                        table?.channel_name === "Amazon Organic" ||
                        table?.channel_name === "Email" ||
                        table?.channel_name === "Organic Social" ? (
                          <>
                            <th
                              style={{ textAlign: "center", width: "7%" }}
                            ></th>
                            <th
                              style={{ textAlign: "center", width: "7%" }}
                            ></th>
                          </>
                        ) : (
                          <>
                            <th style={{ textAlign: "center", width: "7%" }}>
                              AD SPEND
                            </th>
                            <th style={{ textAlign: "center", width: "7%" }}>
                              CHANGE
                            </th>
                          </>
                        )}
                        <th style={{ textAlign: "center", width: "7%" }}>
                          REVENUE
                        </th>
                        <th style={{ textAlign: "center", width: "7%" }}>
                          CHANGE
                        </th>
                        <th style={{ textAlign: "center", width: "7%" }}>
                          CONVERSIONS
                        </th>
                        <th style={{ textAlign: "center", width: "7%" }}>
                          CHANGE
                        </th>
                        {table?.channel_name === "Organic Search" ||
                        table?.channel_name === "Amazon Organic" ||
                        table?.channel_name === "Email" ||
                        table?.channel_name === "Organic Social" ? (
                          <>
                            <th
                              style={{ textAlign: "center", width: "7%" }}
                            ></th>
                            <th
                              style={{ textAlign: "center", width: "7%" }}
                            ></th>
                          </>
                        ) : (
                          <>
                            <th style={{ textAlign: "center", width: "7%" }}>
                              ROAS
                            </th>
                            <th style={{ textAlign: "center", width: "7%" }}>
                              CHANGE
                            </th>
                          </>
                        )}
                        <th style={{ textAlign: "center", width: "11%" }}>
                          CONVERSION RATE
                        </th>
                        <th style={{ textAlign: "center", width: "7%" }}>
                          CHANGE
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Month to Date</td>
                        <td>{format_data(table.sessions, "number")}</td>
                        <td>
                          <ArrowInsights
                            value={
                              table?.session_change || table?.change_sessions
                            }
                            metric="sessions"
                          />
                          {Highcharts.numberFormat(
                            table.session_change || table.change_sessions,
                            2,
                            ".",
                            ","
                          )}
                          %
                        </td>
                        {table?.channel_name === "Organic Search" ||
                        table?.channel_name === "Amazon Organic" ||
                        table?.channel_name === "Email" ||
                        table?.channel_name === "Organic Social" ? (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        ) : (
                          <>
                            <td>{format_data(table.cost, "amount", 0)}</td>
                            <td>
                              <ArrowInsights
                                value={table?.cost_change}
                                metric="ad_spend"
                              />
                              {Highcharts.numberFormat(
                                table.cost_change,
                                2,
                                ".",
                                ","
                              )}
                              %
                            </td>
                          </>
                        )}
                        <td>{format_data(table.revenue, "amount", 0)}</td>
                        <td>
                          <ArrowInsights
                            value={table?.revenue_change}
                            metric="revenue"
                          />
                          {Highcharts.numberFormat(
                            table.revenue_change,
                            2,
                            ".",
                            ","
                          )}
                          %
                        </td>
                        <td>
                          {format_data(
                            table?.conversions || table?.total_conversions,
                            "number"
                          )}
                        </td>
                        <td>
                          <ArrowInsights
                            value={
                              table?.conversion_change ||
                              table?.change_total_conversions
                            }
                            metric="conversions"
                          />
                          {Highcharts.numberFormat(
                            table.conversion_change ||
                              table.change_total_conversions,
                            2,
                            ".",
                            ","
                          )}
                          %
                        </td>
                        {table?.channel_name === "Organic Search" ||
                        table?.channel_name === "Amazon Organic" ||
                        table?.channel_name === "Email" ||
                        table?.channel_name === "Organic Social" ? (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        ) : (
                          <>
                            <td>{format_data(table.roas, "percentage", 2)}</td>
                            <td>
                              <ArrowInsights
                                value={table?.roas_change}
                                metric="roas"
                              />
                              {Highcharts.numberFormat(
                                table.roas_change,
                                2,
                                ".",
                                ","
                              )}
                              %
                            </td>
                          </>
                        )}
                        <td>
                          {format_data(
                            table.conversion_rate,
                            "percentage",
                            2,
                            "conversion_rate"
                          )}
                        </td>
                        <td>
                          <ArrowInsights
                            value={table?.conversion_rate_change}
                            metric="conversion_rate"
                          />
                          {Highcharts.numberFormat(
                            table.conversion_rate_change,
                            2,
                            ".",
                            ","
                          )}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            ))
          )}
        </Row>
      </Spin>
    </>
  );
}

export default ChannelPerformance;
