export const initialState = {
  pie: {},
  isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
  GET_PIE_CHART_DATA: "GET_PIE_CHART_DATA",
  FETCH_PIE_CHART_DATA: "FETCH_PIE_CHART_DATA",
});

export function pieReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.FETCH_PIE_CHART_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.GET_PIE_CHART_DATA:
      return {
        ...state,
        isLoading: false,
        pie: { ...state.graph, ...action.payload },
      };
    default:
      return state;
  }
}
