import React, { useEffect, useState } from "react";
import {
  Input,
  Table,
  Button,
  Modal,
  Select,
  Breadcrumb,
  Tag,
  Tooltip,
  List,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAccountEmailReportLogs } from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiUsers, FiSettings, FiMail } from "react-icons/fi";

const { confirm } = Modal;

const { Search } = Input;
const { Option } = Select;

function AccountEmailReportLogs(props) {
  let { account_id } = useParams();
  const [accountId, setAccountId] = useState([]);

  const {
    accounts: {
      account_email_report_logs: accountEmailReportLogs = [],
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const datasource = accountEmailReportLogs.data;
  // Fetch data
  useEffect(() => {
    dispatch(getAccountEmailReportLogs({ account_id }));
  }, []);

  const columns = [
    {
      title: "Email Sent Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            {text == 1 ? (
              <Tag color="#87d068">Email Sent</Tag>
            ) : (
              <Tag color="#f50">Failed</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Email Sent To",
      dataIndex: "emailed_to",
      key: "emailed_to",
      sorter: false,
      render: (text, record) => {
        var text = JSON.parse(text);
        if (text == null) {
          return "";
        }
        return (
          <List
            className="email-sent-to"
            bordered
            dataSource={text}
            renderItem={(item) => (
              <List.Item>
                <FiMail />
                <span>{item}</span>
              </List.Item>
            )}
          />
        );
      },
    },
    {
      title: "Month of Report",
      dataIndex: "month_of_report",
      key: "month_of_report",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "email_type",
      key: "email_type",
      sorter: true,
      render: (text, record) => {
        return <>{text == 1 ? "Automatic" : "Manual"}</>;
      },
    },
    {
      title: "Generated By",
      dataIndex: "generated_by",
      key: "generated_by",
      sorter: true,
    },
    {
      title: "Generated On",
      dataIndex: "attempt_datetime",
      key: "attempt_datetime",
      sorter: true,
    },
  ];
  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Accounts", link: "/accounts", icon: FiUsers },
    {
      text: accountEmailReportLogs.account_name,
      link: "/account/detail/" + account_id,
      icon: FiSettings,
    },
    {
      text: "Monthly Email Report Logs",
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="panel-heading">
        <Row>
          <Col span={24} className="panel-heading-title">
            <h2>
              Monthly Email Report Logs - {accountEmailReportLogs.account_name}
            </h2>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <Row className="panel-body-input">
          <Col span={24} className="panel-body-input-right">
            <div className="panel-body-checkbox">
              <Search
                placeholder="search"
                allowClear
                style={{ float: "right", width: 350 }}
              />
            </div>
          </Col>
        </Row>
        <div className="panel-body-content">
          <Table
            columns={columns}
            key={(record) => record.primaryKey}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={datasource}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default AccountEmailReportLogs;
