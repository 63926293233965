import React, { useState } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Layout, notification } from "antd";
import "antd/dist/antd.css";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../../Redux/Actions/types";
import SiderMenu from "./SiderMenu";
import MainLayout from "./MainLayout";
import Config from "../../Config";
import { Token } from "../../Utils/helpers/token";
import useRemoveToken from "../../hooks/useRemoveToken";

function NewDashboard() {

  useRemoveToken();

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const {
    SSO_LOGIN: { baseURL: ssoBaseURL, api_base_url },
    API_BI: { baseURL: apiBaseURL }
  } = Config;

  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);

  const handleOnCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  const logOut = async () => {
    
    try { // Use 'try' to catch any errors from the 'fetch' call.

      if(!localStorage.getItem("token")){
        const domain = window.location.href;
        window.location.href = `${ssoBaseURL}login?domain=${domain}`;
      } else {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", Token.encryptAuthParams(localStorage.getItem("token")));
  
        var raw = JSON.stringify({
          "token": localStorage.getItem("token")
        });
  
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw
        };
  
      
          const response = await fetch(`${api_base_url}logout`, requestOptions);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          dispatch({
            type: LOGOUT,
          });
          const type = "success";
          const message = "Success";
          const description = "Successfully Logout.";
          // openNotificationWithIcon(type, message, description);

          const result = await response.text();

          localStorage.removeItem("token");
          localStorage.removeItem("user");
          
          const currentPath = window.location.origin + window.location.pathname;
          let currentSearch = window.location.search;

          // Replace the first '?' with '&', if present
          if (currentSearch.indexOf('?') === 0) {
            currentSearch = '&' + currentSearch.slice(1);
          }

          const loginURL = `${ssoBaseURL}login?domain=${currentPath}${currentSearch}`;

          window.location.replace(loginURL);
        }
      } catch (error) {
        
        const domain = window.location.href;
        window.location.href = `${ssoBaseURL}login?domain=${window.location.href}`;
      }
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <div className="main-sidebar">
          <SiderMenu
            collapsed={collapsed}
            onCollapse={handleOnCollapse}
            logOut={logOut}
          ></SiderMenu>
        </div>
        <MainLayout />
      </Layout>
    </>
  );
}

export default NewDashboard;
