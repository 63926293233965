import { ACTIONS_TYPES } from "../Reducers/normalTableReducer";

export function getNormalTable(payload, id) {
  return {
    type: ACTIONS_TYPES.FETCH_NORMAL_TABLE,
    payload,
    id: id,
  };
}

export function getDataFromAPI(payload, id) {
  return {
    type: ACTIONS_TYPES.FETCH_API_DATA,
    payload,
    id: id,
  };
}
