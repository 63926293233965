import { Button, Modal, Form, Upload, Table } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { FaEye, FaArrowAltCircleLeft } from "react-icons/fa";

const columns = [
	{
		title: "Data File",
		dataIndex: "file",
		key: "file",
		render: (text) => <a>{text}</a>,
	},
	{
		title: "Upload Date",
		dataIndex: "upload",
		key: "upload",
	},
];
const data = [
	{
		key: "1",
		file: "2022-11-09-06-47-49_goal_account.csv",
		upload: "9 Nov 2022 06:47 am",
	},
	{
		key: "2",
		file: "2022-11-09-06-47-49_goal_account.csv",
		upload: "9 Nov 2022 06:47 am",
	},
];
const ImportGoals = () => {
	const [visible, setVisible] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const showModal = () => {
		setVisible(true);
	};
	const showImportHistoryModal = () => {
		setIsModalOpen(true);
		setVisible(false);
	};

	const handleOk = () => {
		setConfirmLoading(true);
		setTimeout(() => {
			setVisible(false);
			setConfirmLoading(false);
		}, 2000);
	};

	const handleCancel = () => {
		setVisible(false);
	};
	const handleImportOk = () => {
		setIsModalOpen(false);
	};
	const handleImportCancel = () => {
		setIsModalOpen(false);
	};
	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}

		return e?.fileList;
	};

	return (
		<>
			<Button className="account-import" onClick={showModal}>
				Import Account Goal
			</Button>

			<Modal
				className="import-modals"
				title="IMPORT GOALS"
				visible={visible}
				onOk={handleOk}
				cancelText="Close"
				onCancel={handleCancel}
				okButtonProps={{ style: { display: "none" } }}
				confirmLoading={confirmLoading}
				width={700}
			>
				<Form>
					<Form.Item
						name="csv-filename"
						label="Upload Goals from CSV: "
						valuePropName="fileList"
						getValueFromEvent={normFile}
						style={{ marginBottom: "20px" }}
					>
						<Upload name="logo" action="/upload.do">
							<Button icon={<UploadOutlined />}>Choose a File</Button>
						</Upload>
					</Form.Item>
					<Form.Item
						name="upload"
						label=""
						getValueFromEvent={normFile}
						className="import-modals-form-buttons"
					>
						<Button icon={<UploadOutlined />}>Upload and Import</Button>
						<Button icon={<DownloadOutlined />}>Download Template</Button>
						<Button icon={<FaEye />} onClick={showImportHistoryModal}>
							View Upload History
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				className="history-modal"
				title={
					<>
						<Button
							className="modal-back-btn"
							icon={<FaArrowAltCircleLeft />}
							onClick={() => {
								setVisible(true);
								setIsModalOpen(false);
							}}
						>
							Go Back
						</Button>
						GOAL UPLOAD HISTORY
					</>
				}
				visible={isModalOpen}
				onOk={handleImportOk}
				cancelText="Close"
				onCancel={handleImportCancel}
				okButtonProps={{ style: { display: "none" } }}
				width={700}
			>
				<Table columns={columns} dataSource={data} />
			</Modal>
		</>
	);
};

export default ImportGoals;
