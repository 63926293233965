/**
 * @fileoverview Reducer for Facebook ads Config
 *
 * The reducer handles the state changes for the Facebook ads Config.
 */

// Define the initial state of the reducer
export const initialState = {
    accounts_mcc: {}, // account data sources
    account_mccs: {},
    error_ds: {}, // error data sources
    isLoading: false, // loading state
  };
  
  // Define action types to be used in the reducer
  export const ACTIONS_TYPES = Object.freeze({
    FACEBOOK_ADS_INSIGHTS_MCC_REQUEST: "FACEBOOK_ADS_INSIGHTS_MCC_REQUEST",
    FACEBOOK_ADS_INSIGHTS_MCC: "FACEBOOK_ADS_INSIGHTS_MCC", // Fetch Account Data Sources
    FACEBOOK_ADS_INSIGHTS_ALL_MCC: "GOOGLE_ALL_CONSOLE_MCC", // Fetch Account Data Sources
    FAILED_FACEBOOK_ADS_INSIGHTS_MCC: "FAILED_FACEBOOK_ADS_INSIGHTS_MCC",
    RESET_FACEBOOK_ADS_INSIGHTS_MCC: "RESET_FACEBOOK_ADS_INSIGHTS_MCC",
  });
  
  // Define the reducer function
  export function facebookAdsInsightsMCCReducer(state = initialState, action = {}) {
    const { type, payload } = action;
  
    // Switch case to handle different types of actions
    switch (type) {
      case ACTIONS_TYPES.FACEBOOK_ADS_INSIGHTS_MCC_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      // for a single mcc data
      case ACTIONS_TYPES.FACEBOOK_ADS_INSIGHTS_MCC:
        return {
          ...state,
          isLoading: false,
          accounts_mcc: {
            ...state.accounts_mcc,
            ...payload,
          },
        };
  
      // for all mcc data
      case ACTIONS_TYPES.FACEBOOK_ADS_INSIGHTS_ALL_MCC:
        return {
          ...state,
          isLoading: false,
          account_mccs: action.payload,
        };
  
      case ACTIONS_TYPES.RESET_FACEBOOK_ADS_INSIGHTS_MCC:
        return {
          ...state,
          isLoading: false,
          accounts_mcc: {},
        };
  
      case ACTIONS_TYPES.FAILED_FACEBOOK_ADS_INSIGHTS_MCC:
        return {
          ...state,
          isLoading: false,
          error_ds: payload,
        };
  
      // Return the initial state if no matching action is found
      default:
        return state;
    }
  }
  