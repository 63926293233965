import { Input, Button, Modal, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getActiveUsers,
	addUsersToRole,
} from "../../Redux/Actions/Thunk/roleThunk";

const { Search } = Input;

const AddUserRole = (props) => {
	const [visible, setVisible] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [selectedUserIds, setSelectedUserIds] = useState([]);

	const getRowKey = (record) => record.id;

	const {
		roles: {
			active_users: { data: activeUsers = [] },
			isLoading,
		},
	} = useSelector((state) => state);

	const dispatch = useDispatch();

	const showModal = () => {
		setVisible(true);
	};

	const handleOk = () => {
		dispatch(
			addUsersToRole({ user_ids: selectedUserIds, role_id: props.role_id })
		);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedUserIds(selectedRowKeys);
		},
		getCheckboxProps: (record) => ({
			disabled: record.id === 0,
			name: record.name,
		}),
	};

	useEffect(() => {
		if (activeUsers.length > 0) {
			setSearchResults(activeUsers);
		}
	}, [activeUsers]);

	const searchActiveUsers = (searchText) => {
		dispatch(getActiveUsers({ search: searchText }));
	};

	const columns = [
		{
			title: "First Name",
			dataIndex: "first_name",
			key: "first_name",
		},
		{
			title: "Last Name",
			dataIndex: "last_name",
			key: "last_name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Current Role",
			dataIndex: "current_roles",
			key: "current_roles",
		},
	];

	return (
		<>
			<Button className="add-user" onClick={showModal}>
				Add User
			</Button>

			<Modal
				className="add-user-modal"
				title="ADD USER"
				visible={visible}
				onOk={handleOk}
				okText="Add Selected"
				cancelText="Close"
				onCancel={handleCancel}
				width={700}
			>
				<Search
					placeholder="Search Active Users"
					onSearch={searchActiveUsers}
					style={{
						width: 300,
						marginBottom: 20,
					}}
				/>
				<Table
					rowKey={getRowKey}
					rowSelection={{
						type: "checkbox",
						...rowSelection,
					}}
					columns={columns}
					dataSource={searchResults}
					loading={isLoading}
				/>
			</Modal>
		</>
	);
};

export default AddUserRole;
