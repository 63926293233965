import { ACTIONS_TYPES } from "../../../../Reducers/datasources/ds_config/dsResetHistoricalDataReducer";
import * as dsResetHistoricalDataConfig from "../../../../../Api/datasources/dsConfig/resetHistoricalDataConfig";
import errorHandler from "../../../../../Utils/Request/errorHandler";
import successHandler from "../../../../../Utils/Request/successHandler";
import * as dsResetHistoricalDataAction from "../../../datasources/dsConfig/dsResetHistoricalDataConfig";
import { setLoadingFalse, setLoadingTrue } from "../../../globals/loadingThunk";
/**
 *
 * getDsResetHistoricalDataConfigApi
 * Get current reset historical data service  data sources
 * @returns {Function} dispatch
 *
 */

export const getResetHistoricaDataAccountDataSources =
  (props) => async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.RESET_HISTORICAL_DATA_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      // Fetch data from API
      const response =
        await dsResetHistoricalDataConfig.getDsResetHistoricalDataConfigApi(
          props
        );
      const data = response?.data?.data;
      dispatch(
        dsResetHistoricalDataAction.getDsResetHistoricalDataConfigServiceDataSource(
          data
        )
      );
      // successHandler(response, { notifyOnSuccess: true, notifyOnFailed: true });
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      console.error("Error in fetching account ds: ", error);
      errorHandler(error);
      dispatch(
        dsResetHistoricalDataAction.failedDsResetHistoricalDataConfigServiceDataSource(
          errMessage
        )
      );
      // dispatch(setAlert({ message: error?.response?.data?.error?.message, type: "error" }));
    } finally {
      dispatch(setLoadingFalse());
    }
  };
