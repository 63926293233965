import { parse } from "querystring";
import dayjs from "dayjs";
import { BiTrendingUp, BiTrendingDown } from "react-icons/bi";
import ArrowInsights from "../Components/Tables/arrow-insights";
import { func } from "prop-types";

function getPageQuery() {
  parse(window.location.href.split("?")[1]);
}

export function get(obj, key) {
  return key.split(".").reduce(function (o, x) {
    return o === undefined || o === null ? o : o[x];
  }, obj);
}

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  let a = s.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (o !== null) {
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    } else {
      return;
    }
  }
  return o;
};

/* 
 To check only if a property exists, without getting its value. It similer get function.
*/
export function has(obj, key) {
  return key.split(".").every(function (x) {
    if (typeof obj !== "object" || obj === null || x in obj === false)
      /// !x in obj or  x in obj === true *** if you find any bug
      return false;
    obj = obj[x];
    return true;
  });
}

/* 
 convert indexes to properties
*/
export function valueByString(obj, string, devider) {
  if (devider === undefined) {
    devider = "|";
  }
  return string
    .split(devider)
    .map(function (key) {
      return get(obj, key);
    })
    .join(" ");
}

/*
 Submit multi-part form using ajax.
*/
export function toFormData(form) {
  let formData = new FormData();
  const elements = form.querySelectorAll("input, select, textarea");
  for (let i = 0; i < elements.length; ++i) {
    const element = elements[i];
    const name = element.name;

    if (name && element.dataset.disabled !== "true") {
      if (element.type === "file") {
        const file = element.files[0];
        formData.append(name, file);
      } else {
        const value = element.value;
        if (value && value.trim()) {
          formData.append(name, value);
        }
      }
    }
  }

  return formData;
}

/*
 Format Date to display admin
*/
export function formatDate(param) {
  const date = new Date(param);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  const fullDate = `${day}/${month}/${year}`;
  return fullDate;
}

export const isDate = function ({ date, format = "YYYY-MM-DD" }) {
  if (typeof date == "boolean") return false;
  if (typeof date == "number") return false;
  if (dayjs(date, format).isValid()) return true;
  return false;
};
/*
 Format Datetime to display admin
*/
export function formatDatetime(param) {
  let time = new Date(param).toLocaleTimeString();
  return formatDate(param) + " " + time;
}

/*
 Set object value in html
*/
export function bindValue(obj, parentElement) {
  parentElement.querySelectorAll("[data-property]").forEach((element) => {
    const type = element.dataset.type;
    let value = valueByString(obj, element.dataset.property);
    switch (type) {
      case "date":
        value = formatDate(value);
        break;

      case "datetime":
        value = formatDatetime(value);
        break;

      case "currency":
        value = value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        break;

      default:
        break;
    }
    element.innerHTML = value;
  });
}

export function numberCommaSeperator(inputData) {
  if (inputData !== undefined) {
    return inputData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
}

export function formDataAppend(inputData) {
  let formData = new FormData();
  Object.keys(inputData).forEach((fieldName) => {
    formData.append(fieldName, inputData[fieldName]);
  });
  return formData;
}

export function format_data(data, type, roundOff = "0", metric, mom, yoy) {
  if (data == "N/A" || data == "-") {
    return "-";
  } else if (data === null || data === undefined) {
    data = 0;
  }
  // added by prajjwal when value is > 1000 and comma, number was printed as 1
  data = data.toString().replace(",", "");
  if (type === undefined) {
    return data;
  }
  if (roundOff !== undefined) {
    if (type == "amount") {
      if (mom || yoy) {
        return (
          <>
            {metric !== undefined && metric !== null ? (
              <div className="arrow-column-wrapper">
                <ArrowInsights value={data} metric={metric} />
                <span>
                  {" "}
                  {numberWithCommas(parseFloat(data).toFixed(2)) + "%"}
                </span>
              </div>
            ) : (
              <span> {numberWithCommas(Math.abs(data)) + "%"}</span>
            )}
          </>
        );
      }

      metric = metric != undefined ? metric.toLowerCase() : metric;
      if (
        [
          "aov",
          "cpc",
          "cpm",
          "cpv",
          "cpa",
          "adwords_cpc",
          "cost_per_purchase",
          "cost_per_conversion",
          "cost_per_total_conv",
          "product_price",
          "cpl",
          "cost_per_lead",
          "cpc_cta_clicks",
          "spend",
        ].includes(metric)
      ) {
        if (data >= 0) {
          return (
            <span>
              {window.currencySymbol +
                numberWithCommas(parseFloat(data).toFixed(2))}
            </span>
          );
        } else {
          return (
            <span>
              {"-" +
                window.currencySymbol +
                numberWithCommas(Math.abs(parseFloat(data).toFixed(2)))}
            </span>
          );
        }
      } else if (data >= 0) {
        return window.currencySymbol + numberWithCommas(Math.round(data));
      } else {
        return "-" + window.currencySymbol + numberWithCommas(Math.abs(data));
      }
    } else if (type == "percentage") {
      if (mom || yoy) {
        return (
          <>
            {metric !== null ? (
              <div className="arrow-column-wrapper">
                <ArrowInsights value={data} metric={metric} />
                {/* roundOff = 100, change columns in device performance should always be int */}
                <span>
                  {" "}
                  {(roundOff == 100
                    ? numberWithCommas(Math.round(data))
                    : numberWithCommas(parseFloat(data).toFixed(2))) + "%"}
                </span>
              </div>
            ) : (
              <span> {numberWithCommas(data) + "%"}</span>
            )}
          </>
        );
      }
      if (
        [
          "cvr",
          "ctr",
          "mer",
          "acos",
          "acots",
          "tacos",
          "reach",
          "pin_ctr",
          "link_ctr",
          "acos_yoy",
          "spend_yoy",
          "organic_ctr",
          "adwords_cvr",
          "adwords_cpc",
          "revenue_yoy",
          "analytics_cvr",
          "conversion_rate",
          "thisperiodacots",
          "conversions_rate",
          "click_through_rate",
          "buy_box_conversion_rate",
          "search_impression_share",
          "click_rate",
          "open_rate",
          "bounce_rate",
          "unsubscribe_rate",
          "spam_complaint_rate",
          "ctr_cta_clicks",
          "cpc_cta_spend",
        ].includes(metric)
      ) {
        return numberWithCommas(parseFloat(data).toFixed(2)) + "%";
      } else {
        return numberWithCommas(parseFloat(data)) + "%";
      }
    } else if (type == "number") {
      if (mom || yoy) {
        return (
          <>
            {metric !== null ? (
              <div className="arrow-column-wrapper">
                <ArrowInsights value={data} metric={metric} />
                <span>
                  {" "}
                  {numberWithCommas(parseFloat(data).toFixed(2)) + "%"}
                </span>
              </div>
            ) : (
              <span> {numberWithCommas(data) + "%"}</span>
            )}
          </>
        );
      } else {
        return numberWithCommas(parseFloat(data).toFixed(roundOff));
      }
    } else {
      return numberWithCommas(parseFloat(data).toFixed(roundOff));
    }
  }
  if (type == "amount") {
    if (data >= 0) {
      return window.currencySymbol + numberWithCommas(data);
    } else {
      return "-" + window.currencySymbol + numberWithCommas(Math.abs(data));
    }
  } else if (type == "percentage") {
    return (
      <>
        {(metric !== undefined && metric.includes("_change")) ||
        (metric !== undefined && metric.includes("change_")) ? (
          <div className="arrow-column-wrapper">
            <ArrowInsights value={data} metric={metric} />
            <span> {numberWithCommas(data) + "%"}</span>
          </div>
        ) : (
          <span> {numberWithCommas(data) + "%"}</span>
        )}
      </>
    );
  } else if (type == "number") {
    return numberWithCommas(data);
  } else {
    return data;
  }
}

export function numberWithCommas(number) {
  if (isNaN(number) || number == null) {
    return "0";
  }
  var parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function checkIconTypeHelper(value) {
  if (value > 0) {
    return <BiTrendingUp />;
  } else {
    return <BiTrendingDown />;
  }
}

export function groupByKey(data, key) {
  // where data is array and key is to map with group array
  return Object.values(
    data.reduce((res, item) => {
      const value = item[key];
      const existing = res[value] || { [key]: value, data: [] };
      return {
        ...res,
        [value]: {
          ...existing,
          data: [...existing.data, item],
        },
      };
    }, {})
  );
}

export function titleCase(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function arr_diff(a1, a2) {
  var a = [],
    diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
}

export function capitalizeFirstLetter(str) {
  // converting first letter to uppercase
  return str
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");
}

export function toLowerCaseFirstLetter(str) {
  if (str.length === 0) {
    return str; // Handle empty string if necessary
  }

  return str.charAt(0).toLowerCase() + str.slice(1);
}

export default getPageQuery;

export const getReportTitle = (report_key_name) => {
  if (report_key_name) {
    const componentName = report_key_name
      .toLowerCase()
      .split("_")
      .map(function (report_key_part) {
        return report_key_part[0].toUpperCase() + report_key_part.substring(1);
      })
      .join("");
    return componentName;
  } else {
    return "";
  }
};

export function formatTrendBreakDownValue(metric) {
  let calculated_metrics = [
    "AOV",
    "CPC",
    "CPL",
    "COST PER CONVERSION",
    "COST PER TOTAL CONVERSION",
    "COST PER PURCHASE",
    "CPM IMPRESSIONS",
    "CONVERSION RATE",
    "CLICK THROUGH RATE",
    "PIN CLICK THROUGH RATE",
    "LINK CLICK THROUGH RATE",
    "LINK CTR",
    "PIN CTR",
    "ORGANIC CTR",
    "MER",
    "ACOS",
    "ESTIMATED IMPRESSION SHARE",
    "BOUNCE RATE",
    "UNSUBSCRIBE RATE",
    "SPAM COMPLAINT RATE",
    "CTR",
  ];
  return metric?.curr_value &&
    metric?.curr_value !== "-" &&
    calculated_metrics.includes(metric.name.toUpperCase())
    ? parseFloat(metric?.curr_value).toFixed(2)
    : metric?.curr_value;
}

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}
