import { ImArrowDown, ImArrowUp } from "react-icons/im";

export default function ArrowInsights({
  value,
  metric,
  amount,
  min_goal_value = 0,
  target_goal_value = 0,
}) {
  let inverseMetrics = [
    "cpc",
    "cpm",
    "cost_per_lead",
    "cpa",
    "bounce_rate",
    "acos",
    "asin_without_rating",
    "cpc_cta_spend",
  ];
  let ArrowComponent = value < 0 ? ImArrowDown : ImArrowUp;
  let arrowColor = "";

  // added by prajjwal when value is > 1000 and comma, issue in color logic
  if (value != null && typeof value != undefined) {
    value = value.toString().replace(",", "");
  }
  if (min_goal_value == 0 && target_goal_value == 0) {
    value = parseFloat(value);
    if (inverseMetrics.includes(metric)) {
      switch (metric) {
        case "cpc":
        case "cpm":
        case "cost_per_lead":
        case "acos":
        case "asin_without_rating":
        case "cpc_cta_spend":
        case "bounce_rate":
          if (value >= 50) {
            arrowColor = "#DD0000";
          } else if (value >= 25 && value < 50) {
            arrowColor = "#FFA500";
          } else if (value >= 10 && value < 25) {
            arrowColor = "#FFFF00";
          } else if (value >= 0 && value < 10) {
            arrowColor = "#c4e09a";
          } else if (value >= -25 && value < 0) {
            arrowColor = "#c4e09a";
          } else if (value < -25) {
            arrowColor = "#008000";
          } else if (value < 75) {
            arrowColor = "#006400";
          } else if (value >= 75 && value < 110) {
            arrowColor = "#c4e09a";
          } else if (value >= 110 && value < 125) {
            arrowColor = "#FFFF00";
          } else if (value >= 125 && value < 150) {
            arrowColor = "#FFA500";
          } else if (value >= 150) {
            arrowColor = "#ff0000";
          }
          break;
        default:
          break;
      }
    } else {
      if (min_goal_value == 0 && target_goal_value == 0) {
        switch (metric) {
          case "cost":
          case "spend":
          case "ad_cost":
          case "ad_spend":
          case "change_cost":
          case "cost_change":
          case "change_spend":
          case "change_ad_cost":
          case "change_ad_spend":
          case "Ad Spend":
            arrowColor = "#3369D5";
            break;
          case "cpc":
          case "cpm":
          case "CPL":
          case "cpa":
          case "mer":
          case "acos":
          case "acots":
          case "tacos":
          case "bounces":
          case "change_cpc":
          case "change_cpm":
          case "change_cpl":
          case "cpm_change":
          case "change_cpa":
          case "cpa_change":
          case "bounce_rate":
          case "change_acos":
          case "unsubscribes":
          case "cost_per_lead":
          case "unsubscribe_sms":
          case "spam_complaints":
          case "change_sessions":
          case "unsubscribe_rate":
          case "cost_per_purchase":
          case "cost_per_conversion":
          case "cost_per_total_conv":
          case "spam_complaint_rate":
          case "change_conversions_rate":
          case "change_total_conversions":
          case "change_cost_per_conversion":
          case "cpc_cta_clicks":
            if (value < -25) {
              // Dark Green
              arrowColor = "#028502";
            } else if (value >= -25 && value < 10) {
              // Light Green
              arrowColor = "#9FCB5C";
            } else if (value >= 10 && value < 33) {
              // Blue
              //arrowColor = "#3369D5";
              // Yellow -- feedback by Mohit
              arrowColor = "#F2DF3C";
            } else if (value >= 33 && value < 100) {
              // Orange -- feedback by Mohit
              arrowColor = "#FFB74E";
            } else if (value >= 100) {
              // Red
              arrowColor = "#DD0000";
            }
            break;
          default:
            if (value >= 25) {
              // Dark Green
              arrowColor = "#028502";
            } else if (value >= -10 && value < 25) {
              // Light Green
              arrowColor = "#9FCB5C";
            } else if (value >= -25 && value < -10) {
              // Blue
              //arrowColor = "#3369D5";
              // Yellow -- feedback by Mohit
              arrowColor = "#F2DF3C";
            } else if (value >= -50 && value < -25) {
              // Yellow
              arrowColor = "#FFB74E";
            } else if (value < -50) {
              // Red
              arrowColor = "#DD0000";
            } else if (value >= 125) {
              arrowColor = "#013220";
            } else if (value >= 90 && value < 125) {
              arrowColor = "#c4e09a";
            } else if (value >= 75 && value < 90) {
              arrowColor = "#f9e858";
            } else if (value >= 50 && value < 75) {
              arrowColor = "#ffb74e";
            }
            break;
        }
      }
    }
  }

  return (
    <>
      <ArrowComponent
        style={{
          color: arrowColor,
        }}
      />
    </>
  );
}
