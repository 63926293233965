import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formDataAppend } from "../../../../Utils/helpers";
import { getDataTable } from "../../../../Redux/Actions/Thunk/dataTableThunk";
import { FiSquare } from "react-icons/fi";

const AdsDataTable = (props) => {

    const dispatch = useDispatch();

    const {
        dataTable: {
            table: {
                [props?.block?.block_id]: {
                    [props?.block?.block_id]: {
                    data: tableData = [],
                    response_data: responseData = [],
                    goal_data: goalData = [],
                    total_data: totalData = [],
                    total_all_data: totalAllData = [],
                    nlg_text: nlg_text = [],
                    labels: labels = [],
                    roas_goal: roas_goal,
                    nlg_title: nlg_title
                 } = {}
                } = {},
            },
            isLoading,
        } = {},
    } = useSelector((state) => state);

    useEffect(() => {

        var data = props?.all_input_data;
        var formData = formDataAppend(data);
        dispatch(getDataTable(formData, props));
    }, []);


    const table_labels = props.labels || labels;
    
    const modifiedtext = nlg_text?.map(item => <p dangerouslySetInnerHTML={{ __html: item.nlg}}></p>)

    return (
        <>
            <div className="gcs-single">
                <div className="gcs-title-btn">
                    <h4>{props?.title}</h4>
                    {roas_goal &&
                        <span>
                            ROAS GOAL –  <strong>{roas_goal}%</strong>
                        </span>
                    }
                </div>
                <div className="gsc-content">
                    <div className="gsc-table">
                        <table>
                            <thead>
                                <tr>
                                    {table_labels.map((data, index) => (
                                        <th key={index}>{data.value}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((data, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            {table_labels.map((label, colIndex) => (
                                                <td key={colIndex}>
                                                    {
                                                        label.key === props.label_key
                                                            ? (<><FiSquare fill={data['color']} color={data['color']} />&nbsp;</>) : null
                                                    }
                                                    {label.format === "amount"
                                                        ? !isNaN(label.roundoff)
                                                            ? (label.key === 'cpc')
                                                                ? `$${parseFloat(data[label.key]).toFixed(2).toLocaleString()}`
                                                                : `$${Math.round((data[label.key])).toLocaleString()}`
                                                            : data[label.key]
                                                        : label.format === "percentage"
                                                            ? (label.key == 'conversion_rate')
                                                                ? `${parseFloat(data[label.key]).toFixed(2).toLocaleString()}%`
                                                                : `${(Math.round((data[label.key]))).toLocaleString()}%`
                                                            : data[label.key]
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>{props.total_label}</td>
                                    {
                                        table_labels.map((label, colIndex) => {
                                            const updatedLabelKey = label.key === 'conversion_rate' ? 'cvr' : label.key;
                                            const amount = label.format === "amount" ? "$" : "";
                                            const percentage = label.format === "percentage" ? "%" : "";
                                            return totalAllData[updatedLabelKey]
                                                ? (['cpc','conversion_rate']).includes(label.key)
                                                    ? <td key={colIndex}>{amount + (totalAllData[updatedLabelKey]).toLocaleString() + percentage}</td>
                                                    : <td key={colIndex}>{amount + Math.round(totalAllData[updatedLabelKey]).toLocaleString() + percentage}</td>
                                                : label.key == 'item'
                                                    ? <td></td>
                                                    : null
                                        })
                                    }
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="gsc-text">
                    <strong>{nlg_title}</strong> <br /> {modifiedtext}
                </div>
            </div>
        </>
    );
}

export default AdsDataTable;