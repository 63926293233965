import React, { useEffect, useState } from "react";
import { Row, Col, InputNumber, Slider, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTableSliderData } from "../../Redux/Actions/Thunk/tableSliderThunk";
import { formDataAppend, numberWithCommas } from "../../Utils/helpers";

const TableSlider = (props) => {
  const {
    tableSlider: {
      table_slider_data: {
        [props?.block?.block_id]: { data: value = "" } = {},
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getTableSliderData(formData, props));
  }, []);

  const [tdValues, setTdValues] = useState([]);
  const [cpaVal, setCpaVal] = useState("");
  const [clvVal, setCLV] = useState("");

  useEffect(() => {
    setTdValues(updateTable(value));
    setCpaVal(calculateBreakEvenCPA(value, 40));
    setCLV(parseFloat(value).toFixed(2));
  }, [value]);

  const changeCLV = (newValue) => {
    setTdValues(updateTable(newValue));
    setCLV(newValue);
    setCpaVal(calculateBreakEvenCPA(newValue, profitValue));
  };

  const [profitValue, setProfitValue] = useState(40);
  const changeBreakevenCPA = (value) => {
    setCpaVal(calculateBreakEvenCPA(clvVal, value));
    setProfitValue(value);
  };

  function calculateBreakEvenCPA(clv, profit_margin) {
    return numberWithCommas(parseFloat(clv * (profit_margin / 100)).toFixed(2));
  }

  function updateTable(clv) {
    var td_5 = numberWithCommas(parseFloat(clv * 0.05).toFixed(2));
    var td_10 = numberWithCommas(parseFloat(clv * 0.1).toFixed(2));
    var td_15 = numberWithCommas(parseFloat(clv * 0.15).toFixed(2));
    var td_20 = numberWithCommas(parseFloat(clv * 0.2).toFixed(2));
    var td_25 = numberWithCommas(parseFloat(clv * 0.25).toFixed(2));
    var td_30 = numberWithCommas(parseFloat(clv * 0.3).toFixed(2));
    var td_35 = numberWithCommas(parseFloat(clv * 0.35).toFixed(2));
    var td_40 = numberWithCommas(parseFloat(clv * 0.4).toFixed(2));
    var td_45 = numberWithCommas(parseFloat(clv * 0.45).toFixed(2));
    var td_50 = numberWithCommas(parseFloat(clv * 0.5).toFixed(2));

    return [
      td_5,
      td_10,
      td_15,
      td_20,
      td_25,
      td_30,
      td_35,
      td_40,
      td_45,
      td_50,
    ];
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <Row gutter={[16, 24]} style={{ marginBottom: "50px" }}>
          <Col span={24}>
            <div className="table-slider">
              <table id="ntable" className="cpa-datatable" aria-label="">
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Profit Margin
                    </th>
                    <th>5%</th>
                    <th>10%</th>
                    <th>15%</th>
                    <th>20%</th>
                    <th>25%</th>
                    <th>30%</th>
                    <th>35%</th>
                    <th>40%</th>
                    <th>45%</th>
                    <th>50%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Breakeven CPA
                    </td>
                    <td>${tdValues[0]}</td>
                    <td>${tdValues[1]}</td>
                    <td>${tdValues[2]}</td>
                    <td>${tdValues[3]}</td>
                    <td>${tdValues[4]}</td>
                    <td>${tdValues[5]}</td>
                    <td>${tdValues[6]}</td>
                    <td>${tdValues[7]}</td>
                    <td>${tdValues[8]}</td>
                    <td>${tdValues[9]}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row className="slider-single">
          <Col
            className="slider-single-params"
            lg={{ span: 8 }}
            xs={{ span: 24 }}
          >
            <div className="slider-single-caption">
              <span>CLV</span>
            </div>
          </Col>
          <Col
            lg={{ span: 16 }}
            xs={{ span: 24 }}
            className="slider-single-input"
          >
            <Row>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <InputNumber
                  min={0}
                  max={100000}
                  prefix={window.currencySymbol}
                  value={numberWithCommas(clvVal)}
                  onChange={changeCLV}
                  controls={false}
                  style={{ minWidth: "150px", padding: "0 10px" }}
                />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Slider
                  className="slider-range"
                  min={0}
                  max={100000}
                  value={clvVal}
                  onChange={changeCLV}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="slider-single">
          <Col
            className="slider-single-params"
            lg={{ span: 8 }}
            xs={{ span: 24 }}
          >
            <div className="slider-single-caption">
              <span>Profit Margin</span>
            </div>
          </Col>
          <Col
            lg={{ span: 16 }}
            xs={{ span: 24 }}
            className="slider-single-input slider-single-input-profit"
          >
            <Row>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <InputNumber
                  min={10}
                  max={100}
                  prefix="%"
                  value={profitValue}
                  onChange={changeBreakevenCPA}
                  controls={false}
                  style={{ minWidth: "150px", padding: "0 10px" }}
                />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Slider
                  className="slider-range"
                  min={10}
                  max={100}
                  value={typeof profitValue === "number" ? profitValue : 0}
                  onChange={changeBreakevenCPA}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="slider-single">
          <Col className="slider-single-params" span={8}>
            <div className="slider-single-caption">
              <span>Breakeven CPA</span>
            </div>
          </Col>

          <Col
            lg={{ span: 16 }}
            xs={{ span: 24 }}
            className="slider-single-input"
          >
            <Row>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <InputNumber
                  value={cpaVal}
                  prefix={window.currencySymbol}
                  disabled
                  controls={false}
                  style={{ minWidth: "150px", padding: "0 10px" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default TableSlider;
