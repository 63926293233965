import axios from "axios";
import config from "../Config";

const {
  API_BI: { baseURL },
} = config;

export default axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});