import React from "react";
import { Row, Col, Button, Progress } from "antd";
import bg from "../../Assets/Images/agbg.png";
import logo from "../../Assets/Images/aglogo.png";
import { FiSquare } from "react-icons/fi";

const GoogleAdsScorecard = () => {
	return (
		<>
			<div className="gads-wrapper">
				<div className="gads-bg">
					<div className="gads-bg-wallpaper">
						<img src={bg} alt="" />
					</div>
					<div className="gads-bg-logo">
						<img src={logo} alt="" />
					</div>
				</div>
				<div className="gads-content-wrapper">
					<div className="gads-content">
						<div className="gads-content-single gads-header">
							<div className="gads-header-title">
								<h4>Urban Philosophy</h4>
								<h2>Google Ads Scorecard</h2>
							</div>
							<div className="gads-header-score">
								<div className="gadshs-single">
									<h6>Paid Search Score</h6>
									<h4>Poor Job</h4>
									<div className="score">
										<h2>38</h2>
									</div>
								</div>
								<div className="gadshs-single">
									<h6>Product Ads Score</h6>
									<h4>Poor Job</h4>
									<div className="score">
										<h2>24</h2>
									</div>
								</div>
							</div>
							<div className="gads-header-info">
								<p>
									Based on data from April 01, 2023 to July 24, 2023 and using
									1,480% as ROAS goal
								</p>
								<p>
									You will receive a monthly refresh via email on the 9th of
									each month
								</p>
							</div>
							<div className="gads-imporve-btn">
							<a href="https://agital.com/ecommerce-analysis/">
									<Button>Improve my score</Button>
								</a>
							</div>
						</div>
						<div className="gads-waste">
							<Row gutter={24}>
								<Col span={12}>
									<div className="gads-content-single gads-waste-single">
										<h6>SPEND WASTE</h6>
										<h4>Search Terms Ads</h4>
										<h2>29% Waste</h2>
										<Progress percent={29} showInfo={false} />
										<span className="progress-text">Spend</span>
										<p>
											<strong>29%</strong> of your ad spend is wasted on poor
											converting search terms. This amounts to{" "}
											<strong>$0</strong> in projected 12 months waste.
										</p>
										<p>
											<strong>Opportunity</strong>: 0 search terms warrant
											additional ad spend.
										</p>
									</div>
								</Col>
								<Col span={12}>
									<div className="gads-content-single gads-waste-single">
										<h6>SPEND WASTE</h6>
										<h4>Product Ads</h4>
										<h2>44% Waste</h2>
										<Progress percent={44} showInfo={false} />
										<span className="progress-text">Spend</span>
										<p>
											<strong>44% </strong>
											of your ad spend is wasted on poor converting products.
											This amounts to <strong>$358046</strong> in projected{" "}
											<strong>12</strong> months waste.
										</p>
										<p>
											<strong>Opportunity</strong>: 3440 products warrant
											additional ad spend.
										</p>
									</div>
								</Col>
							</Row>
						</div>
						<div className="gads-share">
							<Row gutter={24}>
								<Col span={12}>
									<div className="gads-content-single gads-share-single">
										<h6>PAID SEARCH</h6>
										<h4>Desktop Share</h4>
										<div className="gads-share-progres">
											<Progress percent={32} showInfo={false} />
											<h2>32%</h2>
										</div>
										<span className="progress-text share-pt">Spend</span>
										<div className="gads-share-progres">
											<Progress percent={45} showInfo={false} />
											<h2>45%</h2>
										</div>
										<span className="progress-text">Revenue</span>
										<p>
											On average <strong>55%</strong> of Google Ads revenue is
											from Brand defense, leaving only <strong>45%</strong> for
											Discovery and Conquesting.
										</p>
										<p>
											<strong>Opportunity:</strong> Click "Improve My Score" to
											connect with us and learn how to take full advantage of
											your Brand vs Non-Brand strategy.
										</p>
									</div>
								</Col>
								<Col span={12}>
									<div className="gads-content-single gads-share-single">
										<h6>BRAND TERM</h6>
										<h4>Share of Budget*</h4>
										<div className="gads-share-progres">
											<Progress percent={32} showInfo={false} />
											<h2>32%</h2>
										</div>
										<span className="progress-text share-pt">Spend</span>
										<div className="gads-share-progres">
											<Progress percent={45} showInfo={false} />
											<h2>45%</h2>
										</div>
										<span className="progress-text">Revenue</span>
										<p>
											On average <strong>55%</strong> of Google Ads revenue is
											from Brand defense, leaving only <strong>45%</strong> for
											Discovery and Conquesting.
										</p>
										<p>
											<strong>Opportunity:</strong> Click "Improve My Score" to
											connect with us and learn how to take full advantage of
											your Brand vs Non-Brand strategy.
										</p>
									</div>
								</Col>
							</Row>
						</div>
						<div className="gads-content-single gads-performance">
							<div className="gcs-title">
								<h4>Performance Metrics*</h4>
							</div>
							<div className="gads-performance-content">
								<Row gutter={24}>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Ad Spend</h4>
											<h2>$575,048</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Impressions</h4>
											<h2>281,427,090</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Clicks</h4>
											<h2>850,161</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>CTR</h4>
											<h2>0.30%</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Revenue</h4>
											<h2>$7,400,655</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>Conversions</h4>
											<h2>220,539</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>CPA</h4>
											<h2>$2.61</h2>
										</div>
									</Col>
									<Col span={6}>
										<div className="gpc-single">
											<h4>ROAS</h4>
											<h2>1,287%</h2>
										</div>
									</Col>
								</Row>
							</div>
							<div className="gads-performance-text">
								<p>*Attribution Model: DATA_DRIVEN.</p>
								<p>
									Multiple active conversion actions are being tagged as
									"Purchase". As a result, you may be over-reporting revenue.
								</p>
							</div>
							<div className="gads-imporve-btn">
								<Button>Improve my score</Button>
							</div>
						</div>
						<div className="gads-content-single gads-search">
							<div className="gcs-title">
								<h4>Search Term Performance</h4>
							</div>
							<div className="gcs-single">
								<div className="gcs-title-btn gsctb-first">
									<span>
										ROAS GOAL –  <strong>1480%</strong>
									</span>
								</div>
								<div className="gsc-content">
									<Row gutter={32}>
										<Col span={12}>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
										<Col span={12}>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
									</Row>
								</div>
								<div className="gsc-text">
									<p>
										You have <strong>187%</strong> more search term waste than
										our average client.
									</p>
								</div>
							</div>
							<div className="gcs-single">
								<div className="gcs-title-btn">
									<h4>10 Worst Performing Search Terms</h4>
									<span>
										ROAS GOAL –  <strong>1480%</strong>
									</span>
								</div>
								<div className="gsc-content">
									<div className="gsc-table">
										<table>
											<thead>
												<tr>
													<th>Search Term</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Term #1</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #2</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #3</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #4</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #5</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #6</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td>TOTAL WASTE</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
								<div className="gsc-text">
									<p>
										<strong>Wasted Ad Spend:</strong> <br /> Lorem ipsum dolor
										sit amet consectetur. Varius pulvinar sed bibendum a
										tincidunt. Ornare integer lectus ullamcorper ut varius et
										quis tincidunt. Nibh sit vitae bibendum nisl egestas.
										Tincidunt bibendum nibh elit lorem elementum. Porta lacus
										quam metus mattis sit magna diam urna arcu. Id aliquet
										volutpat feugiat lectus nunc bibendum ut. Quam cursus est
										arcu aliquet urna. Ut pharetra sem ultrices nec porttitor
										mauris non. Lacus in tincidunt rhoncus quisque. Ipsum sapien
										purus consequat semper us et vel et amet.
									</p>
								</div>
							</div>
							<div className="gcs-single">
								<div className="gcs-title-btn">
									<h4>10 Best Performing Search Terms</h4>
									<span>
										ROAS GOAL –  <strong>1480%</strong>
									</span>
								</div>
								<div className="gsc-content">
									<div className="gsc-table">
										<table>
											<thead>
												<tr>
													<th>Search Term</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Term #1</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #2</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #3</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #4</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #5</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #6</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td>TOTAL GOOD</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
								<div className="gsc-text">
									<p>
										<strong>Oppurtunity:</strong> <br /> Lorem ipsum dolor sit
										amet consectetur. Varius pulvinar sed bibendum a tincidunt.
										Ornare integer lectus ullamcorper ut varius et quis
										tincidunt. Nibh sit vitae bibendum nisl egestas. Tincidunt
										bibendum nibh elit lorem elementum. Porta lacus quam metus
										mattis sit magna diam urna arcu. Id aliquet volutpat feugiat
										lectus nunc bibendum ut. Quam cursus est arcu aliquet urna.
										Ut pharetra sem ultrices nec porttitor mauris non. Lacus in
										tincidunt rhoncus quisque. Ipsum sapien purus consequat
										semper us et vel et amet.
									</p>
								</div>
							</div>
							<div className="gads-imporve-btn">
								<Button>Improve my score</Button>
							</div>
						</div>
						<div className="gads-content-single gads-product">
							<div className="gcs-title">
								<h4>Product Performance</h4>
							</div>
							<div className="gcs-single">
								<div className="gcs-title-btn gsctb-first">
									<span>
										ROAS GOAL –  <strong>1480%</strong>
									</span>
								</div>
								<div className="gsc-content">
									<Row gutter={32}>
										<Col span={12}>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
										<Col span={12}>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
									</Row>
								</div>
								<div className="gsc-text">
									<p>
										You have <strong>779%</strong> more product waste than our
										average client.
									</p>
								</div>
							</div>
							<div className="gcs-single">
								<div className="gcs-title-btn">
									<h4>10 Worst Performing Search Terms</h4>
									<span>
										ROAS GOAL –  <strong>1480%</strong>
									</span>
								</div>
								<div className="gsc-content">
									<div className="gsc-table">
										<table>
											<thead>
												<tr>
													<th>Search Term</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Term #1</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #2</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #3</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #4</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #5</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #6</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td>TOTAL WASTE</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
								<div className="gsc-text">
									<p>
										<strong>Wasted Ad Spend:</strong> <br /> Lorem ipsum dolor
										sit amet consectetur. Varius pulvinar sed bibendum a
										tincidunt. Ornare integer lectus ullamcorper ut varius et
										quis tincidunt. Nibh sit vitae bibendum nisl egestas.
										Tincidunt bibendum nibh elit lorem elementum. Porta lacus
										quam metus mattis sit magna diam urna arcu. Id aliquet
										volutpat feugiat lectus nunc bibendum ut. Quam cursus est
										arcu aliquet urna. Ut pharetra sem ultrices nec porttitor
										mauris non. Lacus in tincidunt rhoncus quisque. Ipsum sapien
										purus consequat semper us et vel et amet.
									</p>
								</div>
							</div>
							<div className="gcs-single">
								<div className="gcs-title-btn">
									<h4>10 Best Performing Search Terms</h4>
									<span>
										ROAS GOAL –  <strong>1480%</strong>
									</span>
								</div>
								<div className="gsc-content">
									<div className="gsc-table">
										<table>
											<thead>
												<tr>
													<th>Search Term</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Term #1</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #2</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #3</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #4</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #5</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Term #6</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td>TOTAL GOOD</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
								<div className="gsc-text">
									<p>
										<strong>Oppurtunity:</strong> <br /> Lorem ipsum dolor sit
										amet consectetur. Varius pulvinar sed bibendum a tincidunt.
										Ornare integer lectus ullamcorper ut varius et quis
										tincidunt. Nibh sit vitae bibendum nisl egestas. Tincidunt
										bibendum nibh elit lorem elementum. Porta lacus quam metus
										mattis sit magna diam urna arcu. Id aliquet volutpat feugiat
										lectus nunc bibendum ut. Quam cursus est arcu aliquet urna.
										Ut pharetra sem ultrices nec porttitor mauris non. Lacus in
										tincidunt rhoncus quisque. Ipsum sapien purus consequat
										semper us et vel et amet.
									</p>
								</div>
							</div>
							<div className="gads-imporve-btn">
								<Button>Improve my score</Button>
							</div>
						</div>
						<div className="gads-content-single gads-campaign">
							<div className="gcs-title">
								<h4>Campaign Performance</h4>
							</div>
							<div className="gcs-charts">
								<Row gutter={64}>
									<Col span={12}>
										<h4>Ad Spend</h4>
										{/* Remove this whole div and replace with required chart inside the Col tag */}
										<div
											className="temporary-chart"
											style={{
												minHeight: "200px",
												backgroundColor: "#F0F0E6",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<span style={{ fontSize: "20px" }}>Chart Here</span>
										</div>
									</Col>
									<Col span={12}>
										<h4>Revenue</h4>
										{/* Remove this whole div and replace with required chart inside the Col tag */}
										<div
											className="temporary-chart"
											style={{
												minHeight: "200px",
												backgroundColor: "#F0F0E6",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<span style={{ fontSize: "20px" }}>Chart Here</span>
										</div>
									</Col>
								</Row>
								<Row gutter={48}>
									<Col span={8}>
										<h4>ROAS</h4>
										{/* Remove this whole div and replace with required chart inside the Col tag */}
										<div
											className="temporary-chart"
											style={{
												minHeight: "200px",
												backgroundColor: "#F0F0E6",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<span style={{ fontSize: "20px" }}>Chart Here</span>
										</div>
									</Col>
									<Col span={8}>
										<h4>CPC</h4>
										{/* Remove this whole div and replace with required chart inside the Col tag */}
										<div
											className="temporary-chart"
											style={{
												minHeight: "200px",
												backgroundColor: "#F0F0E6",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<span style={{ fontSize: "20px" }}>Chart Here</span>
										</div>
									</Col>
									<Col span={8}>
										<h4>Conversion Rate</h4>
										{/* Remove this whole div and replace with required chart inside the Col tag */}
										<div
											className="temporary-chart"
											style={{
												minHeight: "200px",
												backgroundColor: "#F0F0E6",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<span style={{ fontSize: "20px" }}>Chart Here</span>
										</div>
									</Col>
								</Row>
							</div>
							<div className="gsc-table">
								<table>
									<thead>
										<tr>
											<th>Campaign</th>
											<th>Ad Spend</th>
											<th>Revenue</th>
											<th>ROAS</th>
											<th>COC</th>
											<th>Conversion Rate</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<span>
													<FiSquare fill="#278EE6" color="#278EE6" />
													Text
												</span>
											</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
										</tr>
										<tr>
											<td>
												<span>
													<FiSquare fill="#974CD9" color="#974CD9" />
													Shopping
												</span>
											</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
										</tr>
										<tr>
											<td>
												<span>
													<FiSquare fill="#DBE610" color="#DBE610" />
													Video
												</span>
											</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
										</tr>
										<tr>
											<td>
												<span>
													<FiSquare fill="#60A512" color="#60A512" />
													Display
												</span>
											</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<td>TOTAL</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
											<td>xx</td>
										</tr>
									</tfoot>
								</table>
							</div>
							<div className="gads-imporve-btn">
								<Button>Improve my score</Button>
							</div>
						</div>
						<div className="gads-content-single gads-brand">
							<div className="gcs-title">
								<h4>Brand vs. Non-Brand Performance</h4>
							</div>
							<div className="gsc-content">
								<Row gutter={32}>
									<Col span={12}>
										{/* Remove this whole div and replace with required chart inside the Col tag */}
										<div
											className="temporary-chart"
											style={{
												minHeight: "200px",
												backgroundColor: "#F0F0E6",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<span style={{ fontSize: "20px" }}>Chart Here</span>
										</div>
									</Col>
									<Col span={12}>
										{/* Remove this whole div and replace with required chart inside the Col tag */}
										<div
											className="temporary-chart"
											style={{
												minHeight: "200px",
												backgroundColor: "#F0F0E6",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<span style={{ fontSize: "20px" }}>Chart Here</span>
										</div>
									</Col>
								</Row>
							</div>
						</div>
						<div className="gads-content-single gads-device">
							<div className="gcs-title">
								<h4>Paid Search Device Performance</h4>
							</div>
							<div className="gcs-single">
								<div className="gcs-charts">
									<Row gutter={64}>
										<Col span={12}>
											<h4>Ad Spend</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
										<Col span={12}>
											<h4>Revenue</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
									</Row>
									<Row gutter={48}>
										<Col span={8}>
											<h4>ROAS</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
										<Col span={8}>
											<h4>CPC</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
										<Col span={8}>
											<h4>Conversion Rate</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
									</Row>
								</div>
								<div className="gsc-table">
									<table>
										<thead>
											<tr>
												<th>Device</th>
												<th>Ad Spend</th>
												<th>Revenue</th>
												<th>ROAS</th>
												<th>COC</th>
												<th>Conversion Rate</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<span>
														<FiSquare fill="#DBE610" color="#DBE610" />
														Desktop
													</span>
												</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
											</tr>
											<tr>
												<td>
													<span>
														<FiSquare fill="#60A512" color="#60A512" />
														Mobile
													</span>
												</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
											</tr>
											<tr>
												<td>
													<span>
														<FiSquare fill="#176F34" color="#176F34" />
														Tablet
													</span>
												</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td>TOTAL</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
											</tr>
										</tfoot>
									</table>
								</div>
								<div className="gsc-text">
									<p>
										Your mobile ROAS is <strong>51% higher</strong> than your
										desktop ROAS, indicating you may be underspending on mobile
										placements.
									</p>
								</div>
							</div>
							<div className="gcs-device-table">
								<h4>Examples of paid search device discrepancies</h4>
								<Row gutter={[16, 24]}>
									<Col span={12}>
										<table>
											<thead>
												<tr>
													<th>Device</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Desktop</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Mobile</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Tablet</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
										</table>
									</Col>{" "}
									<Col span={12}>
										<table>
											<thead>
												<tr>
													<th>Device</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Desktop</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Mobile</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Tablet</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
										</table>
									</Col>{" "}
									<Col span={12}>
										<table>
											<thead>
												<tr>
													<th>Device</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Desktop</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Mobile</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Tablet</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
										</table>
									</Col>{" "}
									<Col span={12}>
										<table>
											<thead>
												<tr>
													<th>Device</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Desktop</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Mobile</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Tablet</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
										</table>
									</Col>
								</Row>
							</div>
							<div className="gads-imporve-btn">
								<Button>Improve my score</Button>
							</div>
						</div>
						<div className="gads-content-single gads-device">
							<div className="gcs-title">
								<h4>Product Ads Device Performance</h4>
							</div>
							<div className="gcs-single">
								<div className="gcs-charts">
									<Row gutter={64}>
										<Col span={12}>
											<h4>Ad Spend</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
										<Col span={12}>
											<h4>Revenue</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
									</Row>
									<Row gutter={48}>
										<Col span={8}>
											<h4>ROAS</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
										<Col span={8}>
											<h4>CPC</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
										<Col span={8}>
											<h4>Conversion Rate</h4>
											{/* Remove this whole div and replace with required chart inside the Col tag */}
											<div
												className="temporary-chart"
												style={{
													minHeight: "200px",
													backgroundColor: "#F0F0E6",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span style={{ fontSize: "20px" }}>Chart Here</span>
											</div>
										</Col>
									</Row>
								</div>
								<div className="gsc-table">
									<table>
										<thead>
											<tr>
												<th>Device</th>
												<th>Ad Spend</th>
												<th>Revenue</th>
												<th>ROAS</th>
												<th>COC</th>
												<th>Conversion Rate</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<span>
														<FiSquare fill="#DBE610" color="#DBE610" />
														Desktop
													</span>
												</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
											</tr>
											<tr>
												<td>
													<span>
														<FiSquare fill="#60A512" color="#60A512" />
														Mobile
													</span>
												</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
											</tr>
											<tr>
												<td>
													<span>
														<FiSquare fill="#176F34" color="#176F34" />
														Tablet
													</span>
												</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td>TOTAL</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
												<td>xx</td>
											</tr>
										</tfoot>
									</table>
								</div>
								<div className="gsc-text">
									<p>
										Your mobile ROAS is <strong>51% higher</strong> than your
										desktop ROAS, indicating you may be underspending on mobile
										placements.
									</p>
								</div>
							</div>
							<div className="gcs-device-table">
								<h4>Examples of product ads device discrepancies</h4>
								<Row gutter={[16, 24]}>
									<Col span={12}>
										<table>
											<thead>
												<tr>
													<th>Device</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Desktop</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Mobile</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Tablet</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
										</table>
									</Col>{" "}
									<Col span={12}>
										<table>
											<thead>
												<tr>
													<th>Device</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Desktop</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Mobile</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Tablet</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
										</table>
									</Col>{" "}
									<Col span={12}>
										<table>
											<thead>
												<tr>
													<th>Device</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Desktop</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Mobile</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Tablet</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
										</table>
									</Col>{" "}
									<Col span={12}>
										<table>
											<thead>
												<tr>
													<th>Device</th>
													<th>Ad Spend</th>
													<th>Revenue</th>
													<th>ROAS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Desktop</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Mobile</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
												<tr>
													<td>Tablet</td>
													<td>xx</td>
													<td>xx</td>
													<td>xx</td>
												</tr>
											</tbody>
										</table>
									</Col>
								</Row>
							</div>
							<div className="gads-imporve-btn">
								<Button>Improve my score</Button>
							</div>
						</div>
						<div className="gads-download">
							<Button>Download scorecard as a PDF</Button>
						</div>
						<div className="gads-privacy">
							<p>
								Agital will only use your personal information and Google Ads
								credentials to analyze your account. We will not share your
								Google Ads data for any reasons{" "}
								<a href="#" target="_blank">
									(see our Privacy Policy)
								</a>
								. By running this report you agree to our key terms and
								conditions.
							</p>
						</div>
					</div>
				</div>
				<div className="gads-footer">
					<p>Copyright © 2023. All rights reserved.</p>
					<p>
						AGITAL | One Wall Street 5th FloorBurlington, MA 01803 |{" "}
						<a href="#" target="_blank">
							Contact Us
						</a>
					</p>
				</div>
			</div>
		</>
	);
};
export default GoogleAdsScorecard;
