import * as indexApi from "../../../Api/indexApi";
import * as normalTableAction from "../normalTableAction";
import { ACTIONS_TYPES } from "../../Reducers/normalTableReducer";

export const getNormalTable = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.REQUEST_NORMAL_TABLE,
      });
      let normal_table = {};
      const response = await indexApi.getApiPath(props, data);
      if (response?.status == 200) {
        normal_table = {
          data: response?.data?.data_list,
          insights: response?.data?.insights,
          tooltip_header: response?.data?.datasource,
        };
      }
      return dispatch(
        normalTableAction.getNormalTable(normal_table, data?.block?.block_id)
      );
    } catch (error) {
      //console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const getDataFromAPI = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.REQUEST_NORMAL_TABLE,
      });
      let api_data = {};
      const response = await indexApi.getApiPath(props, data);
      if (response?.status == 200) {
        api_data = {
          [data.block.block_id]: {
            data: response?.data?.data_list,
            insights: response?.data?.insights,
            tooltip_header: response?.data?.datasource,
            ...(response?.data?.subscribed_accounts
              ? { subscribed_accounts: response?.data?.subscribed_accounts }
              : []),
          },
        };
      }
      return dispatch(
        normalTableAction.getDataFromAPI(api_data, data?.block?.block_id)
      );
    } catch (error) {
      //console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
