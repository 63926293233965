import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, Row, Col, Spin } from "antd";
import {notification} from "antd";
import { Typography, Layout } from "antd";
import { Redirect, withRouter, useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiHelpCircle } from "react-icons/fi";
import axios from "axios";
import decode from "jwt-decode";
import { getLocationData, getFingerPrint } from "../../Utils";
import { mfaCheck } from "../../Redux/Actions/Thunk/loginThunk";

const { Content } = Layout;
const { Title, Text } = Typography;

const TokenPage = () => {
  const history = useHistory();
  let location = useLocation();
  const {
    login: { isLoading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const from = location.state?.from?.pathname || "/";

  const user = JSON.parse(localStorage.getItem("user"));
  const first_name = user.first_name;
  const email = user.email;

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const onFinish = async (values) => {
    let user_data = {
      "user_id" : user.user_id,
      "verification_token" : values.token,
      // "ip_address" : await getLocationData(),
      "ip_address" : "",
      "fingerprint" : await getFingerPrint()
    }
    dispatch(mfaCheck(user_data));
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <Content className="login-main token-main">
      <Row>
        <Col span={24} className="login-right">
          <div className="login-form">
            <h2>Please Verify Your Device</h2>
            <p style={{background: "#cde8f6", border: "1px solid #08a3d6", color: "#08a3d6", marginBottom:30, padding:10}}>Hello {first_name}, Please check your email ({email}) for the verification code and enter it below. You need to do this once per device.</p>
            <Form
              name="basic"
              initialValues={{
                remember: false,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Spin spinning={isLoading}>
                <Form.Item
                  // label="Email"
                  name="token"
                  rules={[
                    {
                      required: true,
                      message: "Please input device verification token",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Enter Token"
                    autoComplete="off"
                    size="large"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
                <Form.Item className="login-forgot-link">
                  <FiHelpCircle />
                  I am not {first_name} - {email}. Go back to <a className="login-form-forgot" href=""> Login.</a>
                </Form.Item>
              </Spin>
            </Form>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default withRouter(TokenPage);
