import React, { useEffect } from "react";
import { Row, Col } from "antd";
import google from "../../Assets/Images/googlelogo.png";
import partner from "../../Assets/Images/partner.png";
import GadsFooter from "./GadsFooter"
const GadsSignup = () => {
	const token = new URLSearchParams(window.location.search).get('token');
	const mode = new URLSearchParams(window.location.search).get('mode');
	useEffect(() => {
		if(token == null){
			return(<>Invalid URL</>);
		}else{
			localStorage.setItem("gads_access_token_from_bi", token);
		}
		if(mode=='auto'){
			document.getElementById('gBtn').click();
		}
	},[]);
	const google_redirect = () => {
		const api_url = "https://accounts.google.com/o/oauth2/v2/auth";
		const redirect_uri = process.env.REACT_APP_GADS_RETURN_URL;
		const client_id = process.env.REACT_APP_GADS_CLIENT_ID;
		const scope = "https://www.googleapis.com/auth/adwords"
		let full_url = api_url+"?redirect_uri="+redirect_uri+"&prompt=consent&response_type=code&client_id="+client_id+"&scope="+scope+"&access_type=offline";
		window.location.replace(full_url);
	}
	return (
		<>
			<div className="gads-signup-wrapper" style={{display: mode=='auto' ? 'none' : 'block'}}>
				<div className="gadssw-single gadssw-upper">
					<h2>Get your Google Ads Scorecard</h2>
					<p>
						Connect your Google Ads account. We use read-only view to analyze your
						Google Ads and deliver executive-level insights, opportunities, and
						improvements.
					</p>
					<a href="#" id="gBtn" onClick={google_redirect}>
						<img src={google} alt="" /> <span>Sign in with Google</span>
					</a>
				</div>
				<div className="gadssw-single gadssw-lower">
					<Row gutter={32}>
						<Col span={18} style={{ display: "flex", alignItems: "center" }}>
							<div className="gl-text">
								<h4>Your Privacy and Security is our Top Priority</h4>
								<p>
									Granting read-only access is always private and secure. For more
									than two decades, EXCLUSIVE has been committed to keeping your
									data safe and we reinforce that by being trusted by thousands of
									top ecommerce companies.
								</p>
							</div>
						</Col>
						<Col span={6}>
							<div className="gl-img">
								<img src={partner} alt="" />
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<GadsFooter />
		</>
	);
};

export default GadsSignup;
