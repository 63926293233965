export const initialState = {
    isLoading: false,
    table_slider_data: {}
  };
  
  export const ACTIONS_TYPES = Object.freeze({
    GET_TABLE_SLIDER_DATA: "GET_TABLE_SLIDER_DATA",
    FETCH_TABLE_SLIDER_DATA: "FETCH_TABLE_SLIDER_DATA",
    RESET_TABLE_SLIDER: "RESET_TABLE_SLIDER"
  });
  
  export function tableSliderReducer(state = initialState, action) {
    const { key, payload } = action;
    switch (action.type) {
        case ACTIONS_TYPES.GET_TABLE_SLIDER_DATA:
          return {
            ...state,
            isLoading: true,
          };
          case ACTIONS_TYPES.FETCH_TABLE_SLIDER_DATA:
            return {
              ...state,
              isLoading: false,
              table_slider_data: { ...state.table_slider_data, ...action.payload },
            };
          case ACTIONS_TYPES.RESET_TABLE_SLIDER:
              return {
                ...state,
                table_slider_data: {
                  ...state.table_slider_data,
                  [payload]: {},
                },
              };  
      default:
        return state;
    }
  }
  