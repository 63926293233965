import { Spin } from "antd";
import { Tooltip } from "antd";
import { Row, Col } from "antd";
import Highcharts from "highcharts";
import { FaInfoCircle } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Chart from "../Highcharts/ChartColumn";
import { numberCommaSeperator } from "../../Utils/helpers";
import { getTrendBreakdown } from "../../Redux/Actions/Thunk/TrendThunk";
import {
  formDataAppend,
  checkIconTypeHelper,
  formatTrendBreakDownValue,
} from "../../Utils/helpers";

const TrendsBreakdown = (props) => {
  const [dataSeries, setDataSeries] = useState([]);

  const {
    trend: {
      trendBreakDown: {
        [props?.block?.block_id]: { json_data: jsonData = [], labels } = {},
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getTrendBreakdown(formData, props));
  }, []);

  useEffect(() => {
    if (jsonData) {
      const newSeries = jsonData.map((values, index) => {
        const trendOptions = {
          chart: {
            type: "column",
            height: 200,
          },
          legend: {
            enabled: false,
          },
          series: [values],
          column: {
            colorByPoint: true,
          },
          title: false,
          tooltip: {
            data: "",
            shared: true,
            pointFormatter: function () {
              if (
                values.name === this.series.name &&
                values.format === "percentage"
              ) {
                return (
                  this.series.name +
                  ": <b>" +
                  Highcharts.numberFormat(this.y, 2, ".", ",") +
                  "%</b><br/>"
                );
              } else if (
                values.name === this.series.name &&
                values.format === "amount"
              ) {
                if (
                  [
                    "aov",
                    "cpc",
                    "cpl",
                    "cpa",
                    "cpm",
                    "cost per conversion",
                    "cost per total conversion",
                    "cost per purchase",
                    "cpm impressions",
                    "cost per conv.",
                    "CPC (CTA Clicks)",
                    "CPM",
                  ].includes(this.series.name.toLowerCase())
                ) {
                  return (
                    this.series.name +
                    ": <b>" +
                    window.currencySymbol +
                    Highcharts.numberFormat(this.y, 2, ".", ",") +
                    "</b><br/>"
                  );
                } else {
                  return (
                    this.series.name +
                    ": <b>" +
                    window.currencySymbol +
                    Highcharts.numberFormat(this.y, 0, ".", ",") +
                    "</b><br/>"
                  );
                }
              } else {
                return (
                  this.series.name +
                  ": <b>" +
                  Highcharts.numberFormat(this.y, 0, ".", ",") +
                  "</b><br/>"
                );
              }
            },
          },
          xAxis: {
            categories: labels,
          },
          yAxis: {
            visible: true,
            title: {
              text: "",
            },
            labels: {
              formatter: function () {
                const formattedValue = Highcharts.numberFormat(
                  this.value,
                  0,
                  ".",
                  ","
                );

                // Scaling for thousands and millions
                // let displayValue;
                // if (this.value >= 1000000) {
                //   displayValue = Math.floor(this.value / 1000000) + "M"; // For millions, no decimal
                // } else if (this.value >= 1000) {
                //   displayValue = Math.floor(this.value / 1000) + "K"; // For thousands, no decimal
                // } else {
                //   displayValue = formattedValue;
                // }
                const currencySymbol = window?.currencySymbol;
                if (values?.format === "amount") {
                  return currencySymbol + formattedValue;
                } else if (values?.format === "percentage") {
                  return this.value + "%";
                } else {
                  return formattedValue;
                }

                //return displayValue;
              },
            },
          },
        };
        return { ...trendOptions };
      });

      setDataSeries(newSeries);
    }
  }, [jsonData]);

  // Filter out duplicates
  const uniqueOptions = [
    ...new Set(dataSeries.map((option) => option.series[0].name)),
  ];
  let color = "";
  const getTrendBreakdownChange = (
    value,
    amount,
    metric,
    min_goal_value = 0,
    target_goal_value = 0
  ) => {
    let changeColor = "";
    //if (min_goal_value == 0 && target_goal_value == 0) {
    metric = metric !== undefined ? metric.toLowerCase() : metric;
    switch (metric) {
      case "cost":
      case "spend":
      case "ad_cost":
      case "ad_spend":
        changeColor = "blue";
        break;
      case "cpc":
      case "cpm":
      case "cpa":
      case "cpl":
      case "acos":
      case "acots":
      case "tacos":
      case "bounces":
      case "bounce_rate":
      case "unsubscribes":
      case "cpm impressions":
      case "unsubscribe_sms":
      case "spam_complaints":
      case "unsubscribe_rate":
      case "cost per purchase":
      case "cost_per_conversion":
      case "cost per conversion":
      case "spam_complaint_rate":
      case "cost per total conversion":
      case "cpc (cta clicks)":
        if (value < -25) {
          // Dark Green
          changeColor = "dark-green";
        } else if (value >= -25 && value < 10) {
          // Light Green
          changeColor = "light-green";
        } else if (value >= 10 && value < 33) {
          // Blue
          //changeColor = "#3369D5";
          // Yellow -- feedback by Mohit
          changeColor = "yellow";
        } else if (value >= 33 && value < 100) {
          // Orange -- feedback by Mohit
          changeColor = "orange";
        } else if (value >= 100) {
          // Red
          changeColor = "red";
        }
        break;
      default:
        if (value >= 25) {
          // Dark Green
          changeColor = "dark-green";
        } else if (value >= -10 && value < 25) {
          // Light Green
          changeColor = "light-green";
        } else if (value >= -25 && value < -10) {
          // Blue
          //changeColor = "#3369D5";
          // Yellow -- feedback by Mohit
          changeColor = "yellow";
        } else if (value >= -50 && value < -25) {
          // Yellow
          changeColor = "orange";
        } else if (value < -50) {
          // Red
          changeColor = "red";
        } else if (value < -50) {
          // Red
          changeColor = "#DD0000";
        } else if (value >= 125) {
          changeColor = "#013220";
        } else if (value >= 90 && value < 125) {
          changeColor = "#c4e09a";
        } else if (value >= 75 && value < 90) {
          changeColor = "#f9e858";
        } else if (value >= 50 && value < 75) {
          changeColor = "#ffb74e";
        }
        break;
    }
    //}
    return changeColor;
  };

  return (
    <>
      <div>
        <Spin spinning={isLoading}>
          <Row gutter={[16, 24]}>
            {uniqueOptions.map((optionName) => {
              const option = dataSeries.find(
                (item) => item.series[0].name === optionName
              );
              {
                color = getTrendBreakdownChange(
                  option?.series[0]?.change,
                  0,
                  optionName
                );
              }
              return (
                <Col lg={{ span: 8 }} xs={{ span: 24 }} key={optionName}>
                  <div className="trends-breakdown-single">
                    <div className="trends-breakdown-info">
                      <div className="trends-breakdown-info-left">
                        <h6>
                          {option?.series[0]?.name}
                          {option?.series[0]?.tooltip_data !== "" ? (
                            <Tooltip
                              color="black"
                              title={option?.series[0]?.tooltip_data}
                            >
                              <span>
                                <FaInfoCircle />
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </h6>

                        {option?.series[0]?.curr_value !== undefined ? (
                          <h4>
                            {option?.series[0]?.format === "amount"
                              ? (option?.series[0]?.curr_value !== "-"
                                  ? window.currencySymbol
                                  : "") +
                                numberCommaSeperator(
                                  formatTrendBreakDownValue(option?.series[0])
                                )
                              : option?.series[0]?.format === "percentage"
                              ? numberCommaSeperator(
                                  formatTrendBreakDownValue(option?.series[0])
                                ) +
                                (option?.series[0]?.curr_value !== "-"
                                  ? "%"
                                  : "")
                              : numberCommaSeperator(
                                  option?.series[0]?.curr_value
                                )}
                          </h4>
                        ) : (
                          <h4></h4>
                        )}
                      </div>
                      <div
                        className={`trends-breakdown-info-right tbir-${color}`}
                      >
                        {option?.series[0]?.change ? (
                          <h6>
                            {option?.series[0]?.change !== "-"
                              ? checkIconTypeHelper(option?.series[0].change)
                              : ""}
                            <span>
                              {option?.series[0]?.change !== "-"
                                ? numberCommaSeperator(
                                    parseFloat(
                                      option?.series[0]?.change
                                    ).toFixed(2)
                                  ) + "%"
                                : "-"}
                            </span>
                          </h6>
                        ) : (
                          <h6>
                            <span style={{ color: "black" }}>-</span>
                          </h6>
                        )}
                      </div>
                    </div>
                    <Chart options={option} loader={isLoading} />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Spin>
      </div>
    </>
  );
};

export default TrendsBreakdown;
