export const initialState = {
    isLoading: false,
    credentials: {
      amazonSponsorAds: {},
      amazonMarketingService: {},
    },
    error: null,
  };
  
  export const ACTIONS_TYPES = Object.freeze({
    ACCOUNT_CREDENTIALS_REQUEST: "ACCOUNT_CREDENTIALS_REQUEST",
    ACCOUNT_CREDENTIALS: "ACCOUNT_CREDENTIALS",
    ACCOUNT_CREDENTIALS_ERROR: "ACCOUNT_CREDENTIALS_ERROR",
  });
  
  export function accountDataSourcesCredentialsReducer(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case ACTIONS_TYPES.ACCOUNT_CREDENTIALS:
        return {
          ...state,
          isLoading: true,
        };
      case ACTIONS_TYPES.ACCOUNT_CREDENTIALS_REQUEST:
        const { dataSourceName, ...remainingPayload } = action.payload || {};
        return {
          ...state,
          isLoading: false,
          credentials: {
            ...state.credentials,
            [dataSourceName]: {
              ...remainingPayload,
            },
          },
        };
      case ACTIONS_TYPES.ACCOUNT_CREDENTIALS_ERROR:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  }
  