import { Button, Col, Row, Spin } from "antd";
import { FiSquare } from "react-icons/fi";
import AdsDataTable from "./AdsDataTable";
import Donut from "../../../Highcharts/Donut";
import useSelection from "antd/lib/table/hooks/useSelection";
import { useSelector } from "react-redux";
import Column from "../../../Highcharts/Column";

const CampaignPerformace = (props) => {

	  const {
        dataTable: {
          table: {
            [props?.block?.block_id]: {
				[props?.block?.block_id]: {
					json_data_chart = [],
				} = {},
				isLoading,
			} = {},
          },
        } = {},
      } = useSelector((state) => state);

	  const pie1 = [json_data_chart[0]];
	  const pie2 = [json_data_chart[1]];
	  const bar1 = [json_data_chart[4]];
	  const bar2 = [json_data_chart[5]];
	  const bar3 = [json_data_chart[6]];
    return (
        <>
		<Spin spinning	={isLoading}>
         <div className="gads-content-single gads-campaign">
							<div className="gcs-title">
								<h4>Campaign Performance</h4>
							</div>
							<div className="gcs-charts">
								<Row gutter={64}>
									<Col span={12}>
										<h4>Ad Spend</h4>
										<div className="gsc-charts-wrapper">
											{json_data_chart?.length ? <Donut data={pie1} /> : null}
										</div>
									</Col>
									<Col span={12}>
										<h4>Revenue</h4>
										<div className="gsc-charts-wrapper">
											{json_data_chart?.length ? <Donut data={pie2} /> : null}
										</div>
									</Col>
								</Row>
								<Row gutter={48}>
									<Col span={8}>
										<h4>ROAS</h4>
										<div className="gsc-charts-wrapper">
											{json_data_chart?.length ? <Column data={bar1} /> : null}
										</div>
									</Col>
									<Col span={8}>
										<h4>CPC</h4>
										<div className="gsc-charts-wrapper">
											{json_data_chart?.length ? <Column data={bar2} /> : null}
										</div>
									</Col>
									<Col span={8}>
										<h4>Conversion Rate</h4>
										<div className="gsc-charts-wrapper">
											{json_data_chart?.length  ? <Column data={bar3} /> : null }
										</div>
									</Col>
								</Row>
							</div>

							<AdsDataTable
								{...props}
								total_label = 'Total'
								all_input_data={props?.all_input_data} 
								labels={[
									{"key": "campaign", "value": "Campaign", "format": "text"},
									{"key": "cost", "value": "Ad Spend", "format": "amount", "roundoff": 0},
									{"key": "revenue", "value": "Revenue", "format": "amount", "roundoff": 0},
									{"key": "roas", "value": "ROAS", "format": "percentage", "roundoff": 0},
									{"key": "cpc", "value": "CPC", "format": "amount", "roundoff": 0},
									{"key": "conversion_rate", "value": "Conversion Rate", "format": "percentage", "roundoff": 0}
								]}
								label_key="campaign"
							/>

							<div className="gads-imporve-btn">
								<a href="https://agital.com/ecommerce-analysis/">
									<Button>Improve my score</Button>
								</a>
							</div>
						</div>
		</Spin>
        </>
    )
}

export default CampaignPerformace;