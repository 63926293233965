import React, { useState, useEffect } from "react";
import { Button, Modal, Tooltip, Form, Switch, Select } from "antd";
import { AiFillSetting } from "react-icons/ai";
import { saveAnnotationsCommentSettings } from "../../Api/annotationsCommentSettingsApi";
import { useSelector, useDispatch } from "react-redux";
import ReportComments from "../Common/ReportComments";
import { getComment } from "../../Redux/Actions/Thunk/annotationsCommentThunk";
const { Option } = Select;

const CommentSettings = ({
  blockIdParam,
  defaultFilterData,
  account_id,
  report_name,
  currentBlockId,
  triggerRerender,
  fromDateChange,
  toDateChange,
  setTriggerRerender,
  accountDataSources,
}) => {
  const [selectedViewOption, setSelectedViewOption] = useState("0");
  const [isViewSwitchChecked, setIsViewSwitchChecked] = useState("1");
  const [isPdfSwitchChecked, setIsPdfSwitchChecked] = useState("1");
  const [selectedPdfOption, setSelectedPdfOption] = useState("0");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [commentData, setCommentData] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const handleViewOptionChange = (value) => {
    setSelectedViewOption(value);
  };
  const handleViewSwitchChange = (checked) => {
    const newValue = checked ? 1 : 0;
    setIsViewSwitchChecked(newValue.toString());
  };
  const handlePdfOptionChange = (value) => {
    setSelectedPdfOption(value);
  };
  const handlePdfSwitchChange = (checked) => {
    const newValue = checked ? 1 : 0;
    setIsPdfSwitchChecked(newValue.toString());
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const showEditor = () => {
    // setIsEditorVisible(true);
    setIsEditorVisible((current) => !current);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { reportLayouts } = useSelector((state) => state);
  const dispatch = useDispatch();
  //refresh comment block
  const report_id = reportLayouts?.layouts?.data?.layout[0].id;
  const storedArray = localStorage.getItem("datePersistVal");
  const datePersistVal = JSON.parse(storedArray);
  let from_date, to_date;
  if (datePersistVal != null) {
    from_date = datePersistVal["from_date"];
    to_date = datePersistVal["to_date"];
  } else {
    from_date = defaultFilterData["from_date"];
    to_date = defaultFilterData["to_date"];
  }

  const handleCommentSettingsGetComment = async () => {
    setLoadingData(true);
    if (from_date) {
      try {
        const blockIdArray = currentBlockId.toString().split(",");

        const responses = [];

        for (const blockId of blockIdArray) {
          const response = await dispatch(
            getComment({
              account_id,
              report_name,
              report_id,
              block_id: blockId,
              from_date,
              to_date,
            })
          );
          responses.push(response.payload.data);
        }

        setCommentData(responses);
        setLoadingData(false);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setCommentData(null);
    }
  };

  //save comment settings
  const handleCommentSettingsSave = async (e) => {
    try {
      const response = await saveAnnotationsCommentSettings({
        account_id: account_id,
        report_id: reportLayouts.layouts.data.layout[0].id,
        view_status: isViewSwitchChecked,
        view_type: selectedViewOption,
        pdf_status: isPdfSwitchChecked,
        pdf_view_type: selectedPdfOption,
      });
      // show success  message
      handleCommentSettingsGetComment();
    } catch (err) {
      // show exception err to client
    }
  };
  return (
    <>
      <Tooltip title="Comment Settings" placement="left">
        <Button
          onClick={showModal}
          style={{ fontSize: "18px", padding: "3px 10px" }}
        >
          <AiFillSetting />
        </Button>
      </Tooltip>
      <Modal
        title="Comment Settings"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Close"
        className="comment-settings-modal"
        footer={null}
      >
        <Form
          labelCol={{
            lg: 8,
            xs: 8,
          }}
          wrapperCol={{
            lg: 16,
            xs: 16,
          }}
        >
          <h4>Report View Comment Settings:</h4>
          <Form.Item name="report_comment" label="Show Comment">
            <Switch
              size="small"
              defaultChecked={isViewSwitchChecked}
              onChange={handleViewSwitchChange}
            />
          </Form.Item>
          <Form.Item name="report_view" label="View">
            <Select
              style={{ width: "150px" }}
              defaultValue={selectedViewOption}
              allowClear
              onChange={handleViewOptionChange}
            >
              <Option value="0">All</Option>
              <Option value="1">Private</Option>
              <Option value="2">Internal</Option>
            </Select>
          </Form.Item>
          <h4>Export to PDF Comment Settings:</h4>
          <Form.Item name="pdf_comment" label="Show Comment">
            <Switch
              size="small"
              defaultChecked={isPdfSwitchChecked}
              onChange={handlePdfSwitchChange}
            />
          </Form.Item>
          <Form.Item name="pdf_view" label="View">
            <Select
              style={{ width: "150px" }}
              defaultValue={selectedPdfOption}
              allowClear
              onChange={handlePdfOptionChange}
            >
              <Option value="0">All</Option>
              <Option value="1">Private</Option>
              <Option value="2">Internal</Option>
            </Select>
          </Form.Item>
          <div className="report-commentsettings-save">
            <Button
              type="primary"
              className="main-primary-btn"
              onClick={(e) => {
                handleCommentSettingsSave(e);
                handleOk();
              }}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CommentSettings;
