/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaPencilAlt } from "react-icons/fa";
import { Tooltip } from "antd";

const ServiceCell = (props) => {
  return (
    <>
      {props.value != null && (
        <>
          <Link
            to={
              "/reports/v2/view/product_ads_performance/" +
              props.record.custom_id
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedNumberCell
              value={props.value}
              format={props.column.format}
            />
          </Link>
        </>
      )}
    </>
  );
};

function endsWithDigit(value, digit) {
  return value.toString().endsWith(digit.toString());
}

const ProductApprovalReport = (props) => {
  let record_data = props?.record;
  let children_data = props?.record?.children;
  const columnIds = ["name"];
  const status = ["seven_days_checked_status1"];
  const previous_total_change = ["previous_total_change"];
  const lastweek_total_change = ["lastweek_total_change"];
  const percent_disapproved = ["percent_disapproved"];
  const percent_disapproved_previous_change = [
    "percent_disapproved_previous_change",
  ];
  const percent_disapproved_lastweek_change = [
    "percent_disapproved_lastweek_change",
  ];

  if (
    props?.record?.status1 === "Critical" ||
    props?.record?.status1 === "Suspended"
  ) {
    var color = "red";
  } else if (props?.record?.status1 === "Attention") {
    var color = "orange";
  }

  if (
    previous_total_change.includes(props.column.dataIndex) &&
    props?.record?.previous_total_change * 100 < 0
  ) {
    var color1 = "red";
  }
  if (
    lastweek_total_change.includes(props.column.dataIndex) &&
    props?.record?.lastweek_total_change * 100 < 0
  ) {
    var color1 = "red";
  }
  if (
    percent_disapproved.includes(props.column.dataIndex) &&
    props?.record?.percent_disapproved * 100 >= 25
  ) {
    var color1 = "red";
  }
  if (
    percent_disapproved_previous_change.includes(props.column.dataIndex) &&
    props?.record?.percent_disapproved_previous_change * 100 >= 25
  ) {
    var color1 = "red";
  }
  if (
    percent_disapproved_lastweek_change.includes(props.column.dataIndex) &&
    props?.record?.percent_disapproved_lastweek_change * 100 >= 25
  ) {
    var color1 = "red";
  }

  if (columnIds.includes(props.column.dataIndex)) {
    return <ServiceCell {...props} />;
  } else if (status.includes(props.column.dataIndex)) {
    return (
      <>
        <span style={{ color: color }}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </>
    );
  } else {
    return (
      <>
        <span style={{ color: color1 }}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </>
    );
  }
};

export default ProductApprovalReport;
