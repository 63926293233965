import { CSVLink } from 'react-csv'
import { Button, Dropdown, Menu } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { exportToExcel } from 'react-json-to-excel'
import { FaDownload, FaFileCsv, FaFileExcel } from 'react-icons/fa'

import { FormattedNumberCell } from '../../../Utils/helpers/formatting'

const ExportClient = ({dataSource, blockName, columnsConf}) => {

  const formattedData = useMemo(() => {
    return dataSource.map(obj => {
      const newObj = {};

      columnsConf.forEach(item => {
          const { dataIndex, format, title } = item;
          if (dataIndex in obj) {
              const value = obj[dataIndex];
              const metric = item.dataIndex;

              newObj[title] = value;

              if (format) {
                newObj[title] = FormattedNumberCell({value, format, metric})
              } else {
                  newObj[title] = value;
              }
          }
      });

      return newObj;
    })
  },[dataSource, columnsConf])

  const exportData = useCallback(() => {
      exportToExcel(formattedData, blockName)
  },[formattedData, blockName])

  const handleExportClick = useCallback((e) => {
    e.preventDefault();
  }, []);

  const exportMenu = (formattedData) => (
    <Menu className="export-dropdown">
      <Menu.Item key="excel">
        <Button onClick={() => exportData(formattedData, blockName)}>
          <FaFileExcel />
          Excel
        </Button>
      </Menu.Item>
      <Menu.Item key="csv">
        <CSVLink
          type="button"
          target="_blank"
          filename={blockName}
          data={formattedData}
          className="ant-btn-default"
        >
          <FaFileCsv />
          CSV
        </CSVLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="export-button-wrapper">
      <Dropdown overlay={exportMenu(formattedData)} trigger={["click"]}>
        <Button type="primary" onClick={handleExportClick}>
          <FaDownload />
            Export
        </Button>
      </Dropdown>
    </div>
  )
}

export default ExportClient;
