import { Button, Form, Input } from "antd";

const GoogleConsoleCreateConfigurationForm = ({
  form,
  onFinish,
  onFinishFailed,
}) => {
  const handleChange = (info) => {
    return info;
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        name="google_console_form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Account"
          name="account"
          rules={[
            {
              required: true,
              message: "Account is required",
            },
          ]}
        >
          <Input placeholder="Account" />
        </Form.Item>
        {/* <Form.Item name="key_file" label="Key File" valuePropName="fileList">
          <Input onChange={handleChange} type="file" />
        </Form.Item> */}

        <Form.Item
          label="Platform"
          name="platform"
          rules={[
            {
              required: true,
              message: "Platform is required",
            },
          ]}
        >
          <Input placeholder="Platform" />
        </Form.Item>
        <Form.Item
          label="User Email"
          name="user_email"
          rules={[
            {
              required: true,
              message: "User Email is required",
            },
            {
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input placeholder="User Email" />
        </Form.Item>
        <Form.Item name="key_file" label="Key File" valuePropName="fileList">
          <Input required={false} onChange={handleChange} type="file" />
        </Form.Item>

        <Form.Item
          label="Key Type "
          name="key_type"
          rules={[
            {
              required: false,
              message: "Key type is required",
            },
          ]}
        >
          <Input placeholder="Key Type" />
        </Form.Item>
        <Form.Item
          label="Key"
          name="key"
          rules={[
            {
              required: false,
              message: "Key is required",
            },
          ]}
        >
          <Input placeholder="Key" />
        </Form.Item>

        <Form.Item
          label="Project Number "
          name="project_number"
          type="number"
          rules={[
            {
              required: true,
              message: "Project Number is required",
            },
          ]}
        >
          <Input placeholder="Project Number" />
        </Form.Item>

        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default GoogleConsoleCreateConfigurationForm;
