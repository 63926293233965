import { Button, Form, Input } from "antd";

const GoogleAdsCreateConfigurationForm = ({
  form,
  onFinish,
  onFinishFailed,
}) => {
  const handleChange = (info) => {
    return info;
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        name="google_ads_form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="client ID"
          name="client_id"
          rules={[
            {
              required: true,
              message: "Client ID is required",
            },
          ]}
        >
          <Input placeholder="client ID" />
        </Form.Item>
        <Form.Item name="key_file" label="Key File" valuePropName="fileList">
          <Input onChange={handleChange} type="file" />
        </Form.Item>

        <Form.Item
          label="Account"
          name="user_email"
          rules={[
            {
              required: true,
              message: "Account is required",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input placeholder="Account" />
        </Form.Item>
        <Form.Item
          label="Developer Token"
          name="developer_token"
          rules={[
            {
              required: true,
              message: "Developer Token is required",
            },
          ]}
        >
          <Input placeholder="Developer Token" />
        </Form.Item>
        <Form.Item
          label="User Agent "
          name="user_agent"
          rules={[
            {
              required: true,
              message: "User Agent is required",
            },
          ]}
        >
          <Input placeholder="User agent" />
        </Form.Item>

        <Form.Item
          label="Platform"
          name="platform"
          rules={[
            {
              required: true,
              message: "Platform",
            },
          ]}
        >
          <Input placeholder="Platform" />
        </Form.Item>

        <Form.Item
          label="File Type"
          name="file_type"
          rules={[
            {
              required: true,
              message: "File Type",
            },
          ]}
        >
          <Input placeholder="File type is required" />
        </Form.Item>

        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
          <Button >Cancel</Button>
        </div>
      </Form>
    </>
  );
};

export default GoogleAdsCreateConfigurationForm;
