import { formatColumns } from "../../../Utils/colorLogic";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Tooltip } from "antd";

const SearchTermsWithNegativeKeywords = (props, text) => {
  let cellClass = "";
  let recordData = props?.record;
  const search_term_key = recordData?.search_term_key;
  const campaignCount = recordData?.campaigns;
  let eparams = props?.extraParams?.all_input_data;
  let f_date = eparams?.f_date;
  let t_date = eparams?.t_date;
  let accountId = props?.extraParams?.account_id;

  if (props?.column?.dataIndex == "search_term" && campaignCount > 0) {
    return (
    <>
        <a
          href={
            "/reports/v2/view/negative_keywords/" +
            accountId +
            "?search_term_query=" +
            search_term_key +
            "&f_date=" +
            f_date +
            "&t_date=" +
            t_date +
            "&search_term_filter=All"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
            />
          </span>
        </a>
        <span className="report-notification-wrapper">
          <span className="noti-red">{campaignCount}</span>
        </span>
      </>
    );
  } else {
    return (
      <>
        <span
          className={formatColumns(
            props?.value,
            recordData,
            props?.goalData,
            props?.column?.dataIndex
          )}
        >
          <FormattedNumberCell
            value={props?.value}
            format={props?.column?.format}
          />
        </span>
      </>
    );
  }
};

export default SearchTermsWithNegativeKeywords;
