import { ACTIONS_TYPES } from "../Reducers/reportReducer";

export function getKeyMetrics(key, payload) {
  return {
    type: ACTIONS_TYPES.FETCH_KEY_METRICS,
    key,
    payload,
  };
}

export function getPerformanceMetrics(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_PERFORMACE_METRICS,
    payload,
  };
}

export function getScoreCardMetrics(payload){
  return {
    type: ACTIONS_TYPES.FETCH_KEY_METRICS_SEO_CARD,
    payload
  }
}
