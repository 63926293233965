import { DataSourcesApiEndPoints } from "../../../Utils/constant";
import API_BI from "../../../Utils/axios_bi";

const {
  bingAdsMCC: { getAllBingAdsMCCUrl, getBingAdsMCCUrl, postBingAdsMCCUrl },
} = DataSourcesApiEndPoints;

/**
 * API function to get Google Ads Auth data
 */
export const getAllBingAdsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(getAllBingAdsMCCUrl, {
    //content-type is not required for get request
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * API function to get Google Ads Auth data
 */
export const getBingAdsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getBingAdsMCCUrl, props);
};

/**
 * API function to create Google Ads Auth data
 * @param {object} props - The data to be posted
 * @returns {Promise<response>}
 */
export const createBingAdsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  // API_BI.defaults.headers.common["Content-Type"] = 'application/x-www-form-urlencoded'

  return await API_BI.post(postBingAdsMCCUrl, props);
};

export const updateBingAdsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(postBingAdsMCCUrl, props);
};

// export const deleteBingAdsParamApi = async (props) => {
//   const token = localStorage.getItem("token");
//   API_BI.defaults.headers.common["Authorization"] = token;
//   return await API_BI.delete(postBingAdsMCCUrl, {
//     data: props,
//   });
// };
