import { Typography } from "antd";
import React, { useEffect, useState } from "react";

import { Col, Form, Row } from "antd";
import { useDispatch } from "react-redux";
import { getPinterestMCCSecretsApi } from "../../Api/datasources/dsConfig/pinterestMCCApi";
import PInterestCreateConfigurationForm from "../../Components/Configuration/pintrest/PintrestCreateForm";
import PInterestTable from "../../Components/Configuration/pintrest/PintrestTable";
import { getAllPinterestMCCDataSourcesThunk } from "../../Redux/Actions/Thunk/datasources/ds_configs/PinterestMccThunk";
import { Token } from "../../Utils/helpers/token";
const { Title } = Typography;

const DsPinterestConfiguration = () => {
  const [pinterestMCCSecrets, setPinterestMCCSecrets] = useState({}); // [
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllPinterestMCCDataSourcesThunk({}));
  }, []);

  useEffect(() => {
    const secrets = getPinterestMCCSecretsApi();
    secrets
      .then((res) => {
        const client_id = Token.decryptAuthParams(res.data?.secret?.client_id);
        const client_secret = Token.decryptAuthParams(
          res.data?.secret?.client_secret
        );


        setPinterestMCCSecrets({
          client_id,
          client_secret,
        });
      })
      .catch((err) => {
      });
  }, []);
  // get pintrest secrets

  const onFinish = (values) => {
    const props = {
      user: values.user,
      url: window.location.href,
    };
    localStorage.setItem("pinterestUser", JSON.stringify(props));

    window.open(
      `https://www.pinterest.com/oauth/?client_id=${pinterestMCCSecrets.client_id}&redirect_uri=${window.location.href}&response_type=code`
    );
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div>
      <Title level={2}>Pinterest</Title>
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} md={12} sm={12}>
          <PInterestTable />
        </Col>
        <Col xs={24} md={12} sm={12}>
          <Typography
            style={{
              textAlign: "center",
              padding: "10px 0 ",
            }}
          >
            ADD PINTEREST ACCOUNT
          </Typography>
          <PInterestCreateConfigurationForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DsPinterestConfiguration;
