import React from "react";
import Highcharts from "highcharts";

import Chart from "../Highcharts/ChartColumn";

const Column = (props) => {
  var newArrayColumn = props.data.filter(function (el) {
    return el && el.chart == "bar";
  });
  const dataSeries = [];

  const backgroundColor = props.backgroundColor || "#FFF";

  if (newArrayColumn) {
    newArrayColumn.forEach((values, index) => {
      const columnOptionsData = {
        chart: {
          height: 300,
          backgroundColor: backgroundColor,
        },
        column: {
          colorByPoint: true,
        },
        title: false,
        legend: {
          enabled: true,
          maxItemWidth: 150,
          itemStyle: {
            width: "150px",
          },
        },
        xAxis: {
          visible: false,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          visible: false,
        },
        tooltip: {
          data: "",
          shared: true,
          pointFormatter: function () {
            if (
              values.metric === this.series.name &&
              values.format === "percentage"
            ) {
              return [
                "cvr",
                "conversion rate",
                "ctr",
                "pin ctr",
                "link ctr",
                "acos",
                "view rate",
              ].includes(this.series.name.toLowerCase())
                ? this.series.name +
                    ": <b>" +
                    Highcharts.numberFormat(this.y, 2, ".", ",") +
                    "%</b><br/>"
                : this.series.name +
                    ": <b>" +
                    Highcharts.numberFormat(this.y, 0, ".", ",") +
                    "%</b><br/>";
            } else if (
              values.metric === this.series.name &&
              values.format === "amount"
            ) {
              return ["cpc", "cpl", "cpa", "cpm", "cpv", "aov"].includes(
                this.series.name.toLowerCase()
              )
                ? this.series.name +
                    ": <b>" +
                    window.currencySymbol +
                    Highcharts.numberFormat(this.y, 2, ".", ",") +
                    "</b><br/>"
                : this.series.name +
                    ": <b>" +
                    window.currencySymbol +
                    Highcharts.numberFormat(this.y, 0, ".", ",") +
                    "</b><br/>";
            } else {
              return (
                this.series.name +
                ": <b>" +
                Highcharts.numberFormat(this.y, 0, ".", ",") +
                "</b><br/>"
              );
            }
          },
        },
        series: [
          {
            name: values.metric,
            data: values.data,
            type: "column",
            cursor: "pointer",
            pointWidth: values?.data?.length <= 2 ? 70 : 39,
            groupPadding: 0,
            pointPadding: 0.1,
            color: "white",
          },
        ],
      };
      dataSeries.push(columnOptionsData);
    });
  }

  return (
    <>
      {dataSeries?.map((option) => (
        <div className="performance-graph-chart">
          <Chart options={option} />
        </div>
      ))}
    </>
  );
};

export default Column;
