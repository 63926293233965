import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaPencilAlt } from "react-icons/fa";
import { Tooltip, notification } from "antd";
import Config from "../../../Config";

const AccountName = (prop) => {
  const {
    ADMIN: { baseURL: data_source_url },
    API_BI: { BASE_URL_FOR_BI_APP: base_url },
  } = Config;
  const low = prop?.notifications?.low ? prop?.notifications.low : 0;
  const medium = prop?.notifications?.medium ? prop?.notifications.medium : 0;
  const critical = prop?.notifications?.critical
    ? prop?.notifications.critical
    : 0;
  const clientId = prop?.notifications?.clientId;
  const clientName = prop?.clientName;
  return (
    <span className="report-notification-wrapper">
      {critical > 0 ? (
        <a
          target="_blank"
          href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${clientId}&customerName=${encodeURIComponent(clientName)}&severity=Critical&status=Open`}
        >
          <span className="noti-red">{critical}</span>
        </a>
      ) : (
        ""
      )}
      {medium > 0 ? (
        <a
          target="_blank"
          href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${clientId}&customerName=${encodeURIComponent(clientName)}&severity=Warning&status=Open`}
        >
          <span className="noti-orange">{medium}</span>
        </a>
      ) : (
        ""
      )}
      {low > 0 ? (
        <a
          target="_blank"
          href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${clientId}&customerName=${encodeURIComponent(clientName)}&severity=Information&status=Open`}
        >
          <span className="noti-blue">{low}</span>
        </a>
      ) : (
        ""
      )}
    </span>
  );
};
const ServiceCell = (props) => {
  return (
    <>
      {props.value != null && (
        <>
          {props.record.service_id === 5 ? (
            <>
              <Link
                to={
                  "/reports/v2/view/amazon_sponsored_ads_performance/" +
                  props.record.account_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </Link>
              &nbsp; &nbsp;
              <Link
                to={
                  "/account/detail/" +
                  props.record.account_id +
                  "?service_id=" +
                  props.record.service_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPencilAlt />
              </Link>
            </>
          ) : props.record.service_id === 4 ? (
            <>
              <Link
                to={
                  "/reports/v2/view/product_ads_performance/" +
                  props.record.account_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </Link>
              &nbsp; &nbsp;
              <Link
                to={
                  "/account/detail/" +
                  props.record.account_id +
                  "?service_id=" +
                  props.record.service_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPencilAlt />
              </Link>
            </>
          ) : props.record.service_id === 3 ? (
            <>
              <Link
                to={
                  "/reports/v2/view/paid_search_performance/" +
                  props.record.account_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </Link>
              &nbsp; &nbsp;
              <Link
                to={
                  "/account/detail/" +
                  props.record.account_id +
                  "?service_id=" +
                  props.record.service_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPencilAlt />
              </Link>
            </>
          ) : props.record.service_id === 10 ? (
            <>
            {props.record.service === "Pinterest Ads" ? (
            <Link
                to={
                  "/reports/v2/view/pinterest_ads_performance/" +
                  props.record.account_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </Link>
              ) : props.record.service === "TikTok Ads" ? (
                <Link
                to={
                  "/reports/v2/view/tiktok_ads_performance/" +
                  props.record.account_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </Link>
              ) : (
                <Link
                to={
                  "/reports/v2/view/social_performance/" +
                  props.record.account_id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </Link>
              )
            }
              &nbsp; &nbsp;
              {props.record.service != "Paid Social Marketing" ? (
                <Link
                  to={
                    "/account/detail/" +
                    props.record.account_id +
                    "?service_id=" +
                    props.record.service_id
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPencilAlt />
                </Link>
              ) : (
                ""
              )}
            </>
          ) : (
            <span className={""}>
              <FormattedNumberCell
                value={props?.value}
                format={props?.column?.format}
              />
            </span>
          )}
        </>
      )}
    </>
  );
};

function endsWithDigit(number, digit) {
  const numStr = number.toString();
  const lastChar = numStr.slice(-1);
  return parseInt(lastChar) === digit;
}
function updateRevenueGoal(obj) {
  if (obj.children && obj.children.length > 0) {
    obj.revenue_goal = obj.revenue_goal > 0 ? obj.revenue_goal : "";
    for (let i = 0; i < obj.children.length; i++) {
      updateRevenueGoal(obj.children[i]); // Recursively update children
    }
  } else {
    // Check if revenue_goal is greater than 0, if yes, keep the value, otherwise set it to ""
    obj.revenue_goal = obj.revenue_goal > 0 ? obj.revenue_goal : "-";
  }
}

// function ROASTargetGoal(obj) {
//   if (obj.children && obj.children.length > 0) {
//     obj.roas_target_goal = obj.roas_target_goal ? obj.roas_target_goal : "";
//     for (let i = 0; i < obj.children.length; i++) {
//       ROASTargetGoal(obj.children[i]); // Recursively update children
//     }
//   } else {
//     // Check if roas_target_goal is greater than 0, if yes, keep the value, otherwise set it to ""
//     obj.roas_target_goal = obj.roas_target_goal;
//   }
// }

// function findAcosTargetGoal(data) {
//   for (const child of data.children) {
//     if (child.service_new === "Product Ads (Amazon Seller Central)") {
//       // Check if acos_target_goal is available
//       if (child.acos_target_goal !== undefined) {
//         return child.acos_target_goal;
//       } else {
//         return "";
//       }
//     }
//   }
//   return "";
// }

const AccountManagerDashboard = (props) => {
  // console.log(props);
  let record_data = props?.record;
  let children_data = props?.record?.children;
  const columnIds = ["service"];
  const cellColumnProjectSpend = ["projected_spend"];
  const cellColumnProjectROAS = ["projected_roas"];
  const cellColumnProjectACOS = ["projected_acos"];
  const cellColumnROASTargetGoal = ["roas_target_goal"];
  const cellColumnACOS = ["acos_target_goal"];
  const cellColumnRevenueTargetGoal = ["revenue_goal"];
  const accountName = ["name"];

  let cellClass = "";
  if (cellColumnProjectSpend.includes(props?.column?.dataIndex)) {
    const adSpendTargetGoal = parseFloat(record_data?.ad_spend_target_goal);
    const projectedSpend = parseFloat(record_data?.projected_spend);
    const projectedROAS = parseFloat(record_data?.projected_roas);
    const roasTargetGoal = parseFloat(record_data?.roas_target_goal);
    const adSpendMinGoal = parseFloat(record_data?.ad_spend_min_goal);

    if (
      (endsWithDigit(adSpendTargetGoal, 9) &&
        projectedSpend > adSpendTargetGoal) ||
      (endsWithDigit(adSpendTargetGoal, 0) &&
        projectedSpend > adSpendTargetGoal &&
        projectedROAS < roasTargetGoal)
    ) {
      cellClass = "light-red-column";
    } else if (projectedSpend < adSpendMinGoal) {
      cellClass = "yellow-column";
    } else {
      cellClass = "light-green-column";
    }
  }

  // Commented by Prajjwal as Santosh added another function
  // if (cellColumnAccountName.includes(props.column.dataIndex)) {
  //   return(
  //     <><span className="report-notification">{record_data?.name}
  //     <AccountNameCell notifications={props.notifications[record_data.account_id]} service_id={record_data.service_id} />
  //     </span></>
  //   )
  // }

  if (cellColumnProjectROAS.includes(props.column.dataIndex)) {
    if (
      parseFloat(record_data?.roas_target_goal) != null &&
      parseFloat(record_data?.roas_min_goal) >
      parseFloat(record_data?.projected_roas)
    ) {
      cellClass = "light-red-column";
    } else if (
      parseFloat(record_data?.projected_roas) >
      parseFloat(record_data?.roas_min_goal) &&
      parseFloat(record_data?.projected_roas) <
      parseFloat(record_data?.roas_target_goal)
    ) {
      cellClass = "yellow-column";
    } else if (
      parseFloat(record_data?.projected_roas) >
      parseFloat(record_data?.roas_target_goal)
    ) {
      cellClass = "light-green-column";
    }
  }

  if (
    cellColumnProjectACOS.includes(props?.column?.dataIndex) &&
    record_data?.children &&
    Array.isArray(record_data.children)
  ) {
    for (const item of record_data.children) {
      if (
        item &&
        [5, 14].includes(item.service_id) &&
        item.projected_acos !== undefined &&
        item.acos_target_goal !== undefined &&
        item.acos_max_goal !== undefined
      ) {
        const projectedACOS = parseFloat(item.projected_acos);
        const targetACOS = parseFloat(item.acos_target_goal);
        const maxACOS = parseFloat(item.acos_max_goal);

        if (!isNaN(targetACOS) && !isNaN(maxACOS) && !isNaN(projectedACOS)) {
          if (targetACOS !== null && maxACOS < projectedACOS) {
            cellClass = "light-red-column";
          } else if (projectedACOS <= maxACOS && projectedACOS > targetACOS) {
            cellClass = "yellow-column";
          } else if (projectedACOS <= targetACOS) {
            cellClass = "light-green-column";
          }
        }
      }
    }
  }

  if (
    accountName.includes(props?.column?.dataIndex) &&
    record_data?.children &&
    Array.isArray(record_data.children)
  ) {
    return (
      <>
        <Link
          to={
            "/reports/v2/view/holistic_performance/" + props?.record?.account_id
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </Link>
        <AccountName
          notifications={props?.notifications[props?.record?.account_id]}
          clientName={props?.record?.name}
        />
      </>
    );
  } else if (
    accountName.includes(props?.column?.dataIndex) &&
    props?.record?.service_new === ""
  ) {
    return (
      <>
        <Link
          to={
            "/reports/v2/view/holistic_performance/" + props?.record?.account_id
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </Link>
        <AccountName
          notifications={props?.notifications[props?.record?.account_id]}
          clientName={props?.record?.name}
        />
      </>
    );
  }
  if (columnIds.includes(props?.column?.dataIndex)) {
    return <ServiceCell {...props} />;
  } else if (cellColumnRevenueTargetGoal.includes(props?.column?.dataIndex)) {
    updateRevenueGoal(record_data);
    return (
      <>
        <span className={cellClass}>
          <FormattedNumberCell
            value={props?.value}
            format={props?.column?.format}
          />
        </span>
      </>
    );
  } else if (cellColumnROASTargetGoal.includes(props?.column?.dataIndex)) {
    // ROASTargetGoal(record_data);
    return (
      <>
        <Tooltip
          placement="top"
          title={
            <>
              {" Minimum Roas Goal: "}
              <FormattedNumberCell
                value={props?.record?.roas_min_goal}
                format={props?.column?.format}
              />
            </>
          }
        >
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
            />
          </span>
        </Tooltip>
      </>
    );
  } else if (cellColumnACOS.includes(props?.column?.dataIndex)) {
    // Call the function to get acos_target_goal
    //const acosTargetGoal = findAcosTargetGoal(record_data);

    // Update the JSON object with acos_target_goal
    //record_data.acos_target_goal = acosTargetGoal;
    return (
      <>
        <Tooltip
          placement="top"
          title={
            <>
              {" Maximum Acos Goal: "}
              <FormattedNumberCell
                value={props?.record?.acos_max_goal}
                format={props?.column?.format}
              />
            </>
          }
        >
          <span className={cellClass}>
            <FormattedNumberCell
              value={props.value}
              format={props.column.format}
            />
          </span>
        </Tooltip>
      </>
    );
  } else {
    return (
      <>
        {updateRevenueGoal(record_data)}
        {props?.value != null && (
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
            />
          </span>
        )}
      </>
    );
  }
};

export default AccountManagerDashboard;
