import { v4 as uuidv4 } from "uuid";
import { ACTIONS_TYPES } from "../../Reducers/globalReducer";

export const setAlert =
  ({ message, type, timeout = 5000 }) =>
  (dispatch) => {
    const id = uuidv4();
    dispatch({
      type: ACTIONS_TYPES.SET_ALERT,
      payload: { message, type, id },
    });

    // *Automatically remove alert after 5 seconds
    // setTimeout(
    //   () => dispatch({ type: ACTIONS_TYPES.REMOVE_ALERT, payload: id }),
    //   timeout
    // );
  };

export const removeAlert = (id) => ({
  type: ACTIONS_TYPES.REMOVE_ALERT,
  payload: id,
});
