export const initialState = {
  columnLineData: {},
  isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
  GET_COLUMN_LINE_CHART: "GET_COLUMN_LINE_CHART",
  FETCH_COLUMN_LINE_CHART: "FETCH_COLUMN_LINE_CHART",
});

export function ColumnLineReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.GET_COLUMN_LINE_CHART:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_COLUMN_LINE_CHART:
      return {
        ...state,
        isLoading: false,
        columnLineData: { ...state.columnLineData, ...action.payload },
      };
    default:
      return state;
  }
}
