export const NumberFormat = new Intl.NumberFormat("en-US", {
  style: "decimal",
});

export const FloatFormat = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const AmountFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const FormattedNumberCell = ({ value, format, metric }) => {
  if (value == null) {
    return "-";
  } else if (format === "number") {
    return numberWithCommas(value);
  } else if (format === "float") {
    return FloatFormat.format(value);
  } else if (format == "amount") {
    if (value === "") {
      return "";
    } else if (value === "-") {
      return "-";
    } else if (value == "N/A") {
      return value;
    }
    if (value >= 0) {
      if (
        [
          "aov",
          "cpc",
          "cpc_last_7d",
          "cpm",
          "cpv",
          "cpa",
          "tpcpl",
          "cost_per_purchase",
          "cost_per_conversion",
          "clv_month_0",
          "clv_month_1",
          "clv_month_2",
          "clv_month_3",
          "clv_month_4",
          "clv_month_5",
          "clv_month_6",
          "clv_month_7",
          "clv_month_8",
          "clv_month_9",
          "clv_month_10",
          "clv_month_11",
          "clv_month_12",
          "adwords_cpc",
          "abs_adwords_cpc_change",
          "Spend",
          "CPM",
          "CPC",
          "tpcpa",
          "tpcpc",
        ].includes(metric)
      ) {
        return (
          window.currencySymbol + numberWithCommas(parseFloat(value).toFixed(2))
        );
      } else {
        return window.currencySymbol + numberWithCommas(Math.round(value));
      }
    } else {
      if (
        [
          "aov",
          "cpc",
          "cpc_last_7d",
          "cpm",
          "cpv",
          "cpa",
          "cost_per_purchase",
          "cost_per_conversion",
          "adwords_cpc",
          "abs_adwords_cpc_change",
        ].includes(metric)
      ) {
        return (
          "-" +
          window.currencySymbol +
          numberWithCommas(Math.abs(parseFloat(value)).toFixed(2))
        );
      } else {
        return "-" + window.currencySymbol + numberWithCommas(Math.abs(value));
      }
    }
    // return "$" + AmountFormat.format(value);
  } else if (format == "percentage") {
    if (value === "") {
      return "";
    } else if (value === "-") {
      return "-";
    } else {
      if (metric != undefined) {
        if (
          [
            "acos",
            "acos_last_7d",
            "cvr",
            "ctr",
            "organic_ctr",
            "link_ctr",
            "tplink_ctr",
            "tpctr",
            "pplink_ctr",
            "thisperiodacots",
            "analytics_cvr",
            "abs_analytics_cvr_change",
            "adwords_cvr",
            "abs_adwords_cvr_change",
            "conversion_rate",
            "conversionrate",
            "conversionsrate",
            "conversionrateyoy_abs",
            "view_rate",
            "bouncerate",
            "c_conversionsrate",
            "conversions_rate",
            "conversions_rateyoy_abs",
            "engagement_rate",
            "revenue_yoy",
            "acos_yoy",
            "spend_yoy",
            "clicked_rate",
            "opened_rate",
            "Completion Rate",
            "CTR",
            "CPC",
            "Frequency",
            "tpconversion_rate",
            "average_positionyoy",
            "conversionsyoy",
            "revenueyoy",
            "clicksyoy",
            "ctryoy",
            "bouncerateyoy_abs",
          ].includes(metric) ||
          metric.substring(0, 7) === "change_" ||
          metric.substring(metric.length - 7) === "_change" ||
          metric.substring(0, 8) === "changed_" ||
          metric.substring(0, 9) === "absolute_" ||
          metric.substring(metric.length - 3) === "mom"
        ) {
          return numberWithCommas(parseFloat(value).toFixed(2)) + "%";
        } else {
          return numberWithCommas(value) + "%";
        }
      } else {
        return numberWithCommas(value) + "%";
      }
    }
  } else {
    return value;
  }
};

// export function numberWithCommas(number) {
//   if (isNaN(number) || number == null) {
//     return "0.00";
//   }

//   // Convert number to string with two decimal places
//   var formattedNumber = parseFloat(number).toFixed(2);

//   var parts = formattedNumber.split(".");
//   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

//   return parts.join(".");
// }

export function numberWithCommas(number) {
  if (isNaN(number) || number == null) {
    return "0";
  }
  var parts = number.toString().split(".");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
