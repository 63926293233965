import React, { useEffect, useState } from "react";
import LineChart1 from "../../Highcharts/LineChart1";
import DataTable from "../../../Pages/Tables/DataTable";
import PerformanceGraph from "../../ReportBlocks/PerformanceGraph";
import TopChart from "../../ReportBlocks/TopChart";
import StackedChart from "../../Highcharts/StackedChart";
import OrganicChart from "../../ReportBlocks/MultiBlockComponents/OrganicChart";
import RelevanceChart from "../../ReportBlocks/MultiBlockComponents/RelevenceChart";
import StrikingChart from "../../ReportBlocks/MultiBlockComponents/StrikingChart";
import BackLinkChart from "../../ReportBlocks/MultiBlockComponents/BackLinkChart";
import OrganicFooterChart from "../../ReportBlocks/MultiBlockComponents/OrganicFooterChart";
import GadsTopChart from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/GadsTopChart";
import AdInsights from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/AdInsights";
import PerformanceMetrics from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/PerformanceMetrics";
import SearchTermPerformance from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/SearchTermPerformance";
import ProductPerformance from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/ProductPerformance";
import CampaignPerformace from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/CampaignPerformace";
import BrandNonBrandPerformace from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/BrandNonBrandPerformace";
import PaidDevicePerformance from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/PaidDevicePerformace";
import ProductAdsDevicePerformace from "../../ReportBlocks/MultiBlockComponents/GoogleAdsComponents/ProductAdsDevicePerformace";

const Components = {

  // Seo Charts
  TopChart: TopChart,
  OrganicChart: OrganicChart,
  RelevanceChart:RelevanceChart,
  StrikingChart:StrikingChart,
  BackLinkChart:BackLinkChart,
  OrganicFooterChart:OrganicFooterChart,

  // Gads Charts
  GadsTopChart:GadsTopChart,
  AdInsights:AdInsights,
  PerformanceMetrics:PerformanceMetrics,
  SearchTermPerformance:SearchTermPerformance,
  StackedBar:ProductPerformance,
  DevicePerformance:CampaignPerformace,
  SEOProjectStatus:BrandNonBrandPerformace,
  DataTable:PaidDevicePerformance,
  NormalTable: ProductAdsDevicePerformace
};

const Element = ({ children }) => {
  return <div className="performance-graph-chart">{children}</div>;
};

export default (block, account_id, client_id, jsObj, clos) => {
  
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: block.block_id,
      block: block,
      account_id: account_id,
      client_id: client_id,
      all_input_data: jsObj,
      cols: clos
    });
  }

  // return React.createElement(
  //   () => <div>The component {block.component} has not been created yet.</div>,
  //   { key: block.block_id }
  // );
};
