export const initialState = {
	user: {},
	loggedIn: false,
	isLoading: false,
	mfaVerified: null,
	impersonateUser: false,
	errorMessage: {},
};

export const ACTIONS_TYPES = Object.freeze({
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILURE: "LOGIN_FAILURE",
	LOGOUT: "LOGOUT",
	LOGIN_REQUEST: "LOGIN_REQUEST",
	MFA_SET : "MFA_SET",
	MFA_SET_FALSE : "MFA_SET_FALSE",
	MFA_CHECK: "MFA_CHECK",
	IMPERSONATE: "IMPERSONATE",
	STOP_IMPERSONATE: "STOP_IMPERSONATE",
});

export function loginReducer(state = initialState, action) {
	switch (action.type) {
		case ACTIONS_TYPES.LOGIN_REQUEST:
			return {
				...state,
				loggedIn: false,
				isLoading: true,
				errorMessage: {},
			};
		case ACTIONS_TYPES.LOGIN_SUCCESS:
			return {
				...state,
				user: action.payload,
				loggedIn: true,
				isLoading: false,
				errorMessage: {},
			};
		case ACTIONS_TYPES.LOGIN_FAILURE:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload,
			};
		case ACTIONS_TYPES.LOGOUT:
			return {
				...state,
				loggedIn: false,
				isLoading: false,
				user: {},
				errorMessage: {},
			};
		case ACTIONS_TYPES.MFA_SET:
			return {
				...state,
				mfaVerified: true,
			};
		case ACTIONS_TYPES.MFA_SET_FALSE:
			return {
				...state,
				mfaVerified: false,
			};
		case ACTIONS_TYPES.IMPERSONATE:
			return {
				...state,
				impersonateUser: true,
			};
		case ACTIONS_TYPES.STOP_IMPERSONATE:
			return {
				...state,
				impersonateUser: false,
			};

		default:
			return state;
	}
}
