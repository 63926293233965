import API from "../Utils/axios";
import { ApiEndpoints } from "../Utils/constant";

const {
  COLUMN_CHART_SESSSION,
  COLUMN_CHART_CONVERSIONS,
  COLUMN_CHART_REVENUE,
} = ApiEndpoints;

export const getSessionData = async (data, props) => {
  return await API.post(props?.block?.api_path, data);
};

// export const getConversionsData = async (data) => {
//   return await API.post(COLUMN_CHART_CONVERSIONS, data);
// };

// export const getRevenueData = async (data) => {
//   return await API.post(COLUMN_CHART_REVENUE, data);
// };
