/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import Popover from "react-popover";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTikTokResponse } from "../../../Redux/Actions/Thunk/facebookTikTokThunk";

const ServiceCell = (props) => {
  const ad_id = props?.record?.ad_id;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const {
    fb_tiktok_data: {
      tiktok_data: { tiktok_item_id = 0, tiktok_ad_id = 0 },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const content = useMemo(
    () => (
      <div>
        <iframe
          src={`https://www.tiktok.com/embed/${tiktok_item_id}`}
          height="400"
        />
      </div>
    ),
    [tiktok_item_id]
  );

  const setPopoverContent = (ad_id) => {
    ad_id = "" + ad_id;
    dispatch(
      getTikTokResponse({
        ad_id: ad_id,
        client_id: props?.extraParams?.client_id,
      })
    );
  };

  const handleMouseEnter = () => {
    setPopoverContent(props?.record?.ad_id);
    setIsPopoverOpen(true);
  };

  const handleMouseLeave = () => {
    setIsPopoverOpen(false);
  };

  return (
    <>
      {props?.record?.ad_id != null && (
        <>
          <Popover
            isOpen={isPopoverOpen && tiktok_ad_id == ad_id}
            body={content}
            place="right"
            preferPlace="right"
            tipSize={0.01}
            style={{ zIndex: "10" }}
          >
            <a
              href={`https://www.tiktok.com/embed/${props?.record?.ad_id}`}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
                metric={props.column.dataIndex}
              />
            </a>
          </Popover>
        </>
      )}
    </>
  );
};

const TikTokAdsPerformance = (props) => {
  const columnIds = ["ad"];
  const blockId = props?.extraParams?.block?.block_id;

  if (columnIds.includes(props.column.dataIndex) && blockId === 720) {
    return <ServiceCell {...props} />;
  } else {
    return (
      <>
        <span>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
            metric={props.column.dataIndex}
          />
        </span>
      </>
    );
  }
};

export default TikTokAdsPerformance;
