import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Button } from "antd";
import { BiTrendingUp, BiTrendingDown } from "react-icons/bi";
import agitalfooter from "../../Assets/Images/agitalfooter.png";
import { FaDownload } from "react-icons/fa";
import { formDataAppend } from "../../Utils/helpers";
import { Spin } from "antd";
import { FormattedNumberCell } from "../../Utils/helpers/formatting";
import { numberWithCommas } from "../../Utils/helpers";
import EmailKeyMetrics from "./EmailReport/EmailKeyMetrics";
import Config from "../../Config";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import html2pdf from "html2pdf.js";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { getAccountAndReports } from "../../Redux/Actions/Thunk/reportLayoutThunk";
import { useDispatch } from "react-redux";

const {
  API: { baseURL, authKey },
  API_BI: { baseURL: BI_BASE_URL },
} = Config;

const MonthlyEmail = () => {
  const servicesApi = [
    {
      name: "Holistic",
      api_path: "v2/holistic_performance/key_metrics",
    },
    {
      name: "Organic Search",
      api_path: "v2/seo_performance/email_report_key_metrics",
    },
    {
      name: "Product Ads (Google/Bing)",
      api_path: "v2/pla/key_metrics",
    },
    {
      name: "Paid Search (Google/Bing)",
      api_path: "v2/ppc_performance/key_metrics",
    },
    {
      name: "Paid Social Marketing",
      api_path: "v2/psm_performance/email_report_key_metrics",
    },
    {
      name: "Product Ads (Amazon Seller Central)",
      api_path: "v2/asc_performance/key_metrics",
    },
    {
      name: "Product Ads (AMS)",
      api_path: "v2/ams_performance/key_metrics",
    },
    {
      name: "Email Marketing",
      api_path: "v2/holistic_performance/email_marketing_key_metrics",
    },
  ];
  const currentDate = new Date();
  const { account_id } = useParams();

  const [apiResponses, setApiResponses] = useState([]);
  const [serviceResponses, setServiceResponses] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [accountName, setAccountName] = useState([]);

  const previousMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const previousMonthYear = previousMonthDate.getFullYear();
  const previousMonthMonth = previousMonthDate.toLocaleString("default", {
    month: "long",
  });
  const dispatch = useDispatch();

  const pathArray = window?.location?.pathname?.split("/");
  var report_name = pathArray[4];

  const fetchClientData = async () => {
    try {
      const url = `${BI_BASE_URL}api/v1/get-client-id`;
      const data = { account_id };
      const headers = {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };

      const response = await axios.post(url, data, { headers });
      setClientId(response?.data?.data?.client_id);
    } catch (error) {
      //console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchClientData();
    dispatch(
      getAccountAndReports({ account_id: account_id, report_key: report_name })
    );
  }, []);

  const getParams = (service_id, byPassCache) => {
    const auth_key = authKey;
    const from_date = new Date();
    from_date.setMonth(from_date.getMonth() - 1);
    from_date.setDate(1);
    const to_date = new Date();
    to_date.setDate(0);

    const from_date_compare = new Date(from_date);
    from_date_compare.setFullYear(from_date.getFullYear() - 1);
    const to_date_compare = new Date(to_date);
    to_date_compare.setFullYear(to_date.getFullYear() - 1);

    const params = {
      authentication: auth_key,
      Client: clientId,
      account_id,
      service_id,
      device_key: -1,
      campaign_type: "All",
      from_date: from_date.toISOString().split("T")[0],
      to_date: to_date.toISOString().split("T")[0],
      from_date_compare: from_date_compare.toISOString().split("T")[0],
      to_date_compare: to_date_compare.toISOString().split("T")[0],
      date_range_option: "Last Month",
      date_range_compareto: "Same Period Last Year",
      attribution: "1d_view_7d_click",
      by_pass_cache: byPassCache,
      f_date: from_date.toISOString().split("T")[0].replace(/-/g, ""),
      t_date: to_date.toISOString().split("T")[0].replace(/-/g, ""),
      c_f_date: from_date_compare.toISOString().split("T")[0].replace(/-/g, ""),
      c_t_date: to_date_compare.toISOString().split("T")[0].replace(/-/g, ""),
      source_account_id: "",
    };

    return params;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${BI_BASE_URL}api/v1/account-detail`;
        const data = { account_id: account_id };
        const headers = {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        };

        const response = await axios.post(url, data, { headers });
        setServiceResponses(response?.data?.data?.services);
        setAccountName(response?.data?.data?.account?.name);
      } catch (error) {
        //console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const activeServices = serviceResponses.filter(
          (service) => service.status === 1 || parseInt(service.status) === 1
        );

        const responses = await Promise.all(
          activeServices.map(async (service) => {
            const matchingApi = servicesApi.find(
              (api) => api.name === service.name
            );
            const service_id = parseInt(service.id);
            const byPassCache = "yes";
            if (matchingApi) {
              const parmsData = getParams(service_id, byPassCache);
              const formappend = formDataAppend(parmsData);
              const apiUrl = baseURL + `${matchingApi.api_path}`;
              const response = await axios.post(apiUrl, formappend);
              // console.log(response);
              return { response: response.data, service: matchingApi.name };
            }
            return null;
          })
        ).then((resp) => {
          const validResponse = resp.filter((el) => el);
          setApiResponses(validResponse);
          setIsLoading(false);
        });
      } catch (error) {
        //console.error("Error:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [serviceResponses]);

  const serviceNames = [
    "Holistic",
    "Organic Search",
    "Paid Social Marketing",
    "Product Ads (Google/Bing)",
    "Paid Search (Google/Bing)",
    "Product Ads (Amazon Seller Central)",
    "Product Ads (AMS)",
    "Email Marketing",
  ];

  const filteredResponses = {};

  try {
    serviceNames.forEach((service) => {
      const filteredArray = apiResponses?.find(
        (response) => response.service === service
      );
      if (filteredArray) {
        filteredResponses[service] = filteredArray;
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
  }

  function generatePdf() {
    setIsLoading(true);

    window.scrollTo(0, 0);

    const divToPrint = document.querySelector("#divToPrint");
    const width = divToPrint.offsetWidth;
    const height = divToPrint.offsetHeight + 1000;

    const margin = 40; // Adjust the margin value as needed

    const opt = {
      margin: [40, 40, 40, 40], // Set margin values for top, right, bottom, left
      filename: "MonthlyEmailReport.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        scale: 4, // Increase scale value for better quality
        logging: true,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        useCORS: true,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: height + margin * 2,
      },
      jsPDF: {
        unit: "px",
        format: [width + margin * 2, height + margin * 2],
        orientation: "portrait",
      },
    };

    const clonedDivToPrint = divToPrint.cloneNode(true);
    clonedDivToPrint.insertBefore(clonedDivToPrint.firstChild, null);

    html2pdf()
      .set(opt)
      .from(clonedDivToPrint)
      .save()
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to generate PDF:", error);
      });
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="monthly-email-export">
          <Button type="primary" onClick={generatePdf}>
            <FaDownload />
            Export PDF
          </Button>
        </div>
        <div id="divToPrint">
          <div className="monthly-email-wrapper">
            <div className="monthly-email-content">
              <div className="mec-title">
                <img src={agitalfooter} alt="agitalfooter" />
                <h2>{accountName}</h2>
              </div>
              <div className="mec-single mec-months">
                <h4>
                  Your {"  "}
                  <span>
                    {previousMonthMonth} {previousMonthYear}
                  </span>
                  {"  "}
                  Metrics
                </h4>
              </div>
              <div className="mec-single mec-total">
                <div className="mec-single-text">
                  <p>
                    If you are having trouble viewing this email, click
                    <a href="#">here</a> to view it in browser
                  </p>
                </div>
                {filteredResponses["Holistic"] &&
                  filteredResponses["Holistic"] != undefined &&
                  filteredResponses["Holistic"].length != 0 && (
                    <>
                      {" "}
                      <div className="mec-total-wrapper">
                        {filteredResponses["Holistic"] && (
                          <ul>
                            <li>
                              <h5>Total Sessions</h5>
                              <h4>
                                {numberWithCommas(
                                  filteredResponses["Holistic"]?.response
                                    ?.data[0]?.sessions
                                )}
                              </h4>
                              <span>
                                {" "}
                                {numberWithCommas(
                                  Math.abs(
                                    filteredResponses["Holistic"]?.response
                                      ?.data[0]?.c_sessions
                                  )
                                )}
                                %{" "}
                                {filteredResponses["Holistic"]?.response
                                  ?.data[0]?.c_sessions > 0
                                  ? "more than last year"
                                  : "less than last year"}
                              </span>
                            </li>
                            <li>
                              <h5>Total Conversions</h5>
                              <h4>
                                {numberWithCommas(
                                  filteredResponses["Holistic"]?.response
                                    ?.data[0]?.conversions
                                )}
                              </h4>
                              <span>
                                {" "}
                                {numberWithCommas(
                                  Math.abs(
                                    filteredResponses["Holistic"]?.response
                                      ?.data[0]?.c_conversions
                                  )
                                )}
                                %{" "}
                                {filteredResponses["Holistic"]?.response
                                  ?.data[0]?.c_conversions > 0
                                  ? "more than last year"
                                  : "less than last year"}
                              </span>
                            </li>
                            <li>
                              <h5>Total Revenue</h5>
                              <h4>
                                $
                                {numberWithCommas(
                                  parseFloat(
                                    filteredResponses["Holistic"]?.response
                                      ?.data[0]?.revenue
                                  ).toFixed(0)
                                )}
                              </h4>
                              <span>
                                {" "}
                                {numberWithCommas(
                                  Math.abs(
                                    filteredResponses["Holistic"]?.response
                                      ?.data[0]?.c_revenue
                                  )
                                )}
                                %{" "}
                                {filteredResponses["Holistic"]?.response
                                  ?.data[0]?.c_revenue > 0
                                  ? "more than last year"
                                  : "less than last year"}
                              </span>
                            </li>
                          </ul>
                        )}
                      </div>
                      <div className="mec-single-link">
                        <Link
                          to={`/reports/v2/view/holistic_performance/${account_id}`}
                          target="_blank"
                        >
                          Open full report in Agital Inform
                        </Link>
                      </div>
                      <div className="mec-single-text">
                        <p>
                          *Conversions and revenue numbers may not be final due
                          to latent orders.
                        </p>
                      </div>
                    </>
                  )}
              </div>
              {filteredResponses["Organic Search"] &&
                filteredResponses["Organic Search"] != undefined &&
                filteredResponses["Organic Search"].length != 0 && (
                  <>
                    <div className="mec-single mec-organic">
                      <h4 className="mec-single-title">Organic Search</h4>
                      <div className="mec-single-list mec-organic-wrapper">
                        <div className="mec-single-list-style mec-organic-wrapper-style">
                          <ul>
                            <li>
                              <h4>
                                <span>
                                  {" "}
                                  {numberWithCommas(
                                    filteredResponses["Organic Search"]
                                      ?.response?.data[0]?.visits
                                  )}
                                </span>{" "}
                                Visits
                              </h4>
                              <p>
                                {numberWithCommas(
                                  Math.abs(
                                    filteredResponses["Organic Search"]
                                      ?.response?.data[0]?.visitsyoy
                                  )
                                )}
                                %{"  "}
                                {filteredResponses["Organic Search"]?.response
                                  ?.data[0]?.visitsyoy > 0
                                  ? "more than last year"
                                  : "less than last year"}
                              </p>
                            </li>
                            <li>
                              <h4>
                                <span>
                                  {" "}
                                  {numberWithCommas(
                                    filteredResponses["Organic Search"]
                                      ?.response?.data[0]?.tppagewithtraffic
                                  )}
                                </span>{" "}
                                Queries on Page 1
                              </h4>
                              <p>
                                {" "}
                                {numberWithCommas(
                                  Math.abs(
                                    filteredResponses["Organic Search"]
                                      ?.response?.data[0]?.pagewithtrafficyoy
                                  )
                                )}
                                %{"  "}
                                {filteredResponses["Organic Search"]?.response
                                  ?.data[0]?.pagewithtrafficyoy > 0
                                  ? "more than last year"
                                  : "less than last year"}
                                {/* {filteredResponses["Organic Search"]?.response
                                  ?.data[0]?.pagewithtrafficyoy > 0
                                  ? "more than last year"
                                  : "less than last year"} */}
                              </p>
                            </li>
                            <li>
                              <h4>
                                <span>
                                  ${" "}
                                  {numberWithCommas(
                                    parseFloat(
                                      filteredResponses["Organic Search"]
                                        ?.response?.data[0]?.revenue
                                    ).toFixed(0)
                                  )}
                                </span>{" "}
                                in Revenue
                              </h4>
                              <p>
                                {" "}
                                {numberWithCommas(
                                  Math.abs(
                                    filteredResponses["Organic Search"]
                                      ?.response?.data[0]?.revenueyoy
                                  )
                                )}
                                %{"  "}
                                {filteredResponses["Organic Search"]?.response
                                  ?.data[0]?.revenueyoy > 0
                                  ? "more than last year"
                                  : "less than last year"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="mec-single-link">
                        <Link
                          to={`/reports/v2/view/organic_search_performance/${account_id}`}
                          target="_blank"
                        >
                          Open full report in Agital Inform
                        </Link>
                      </div>
                    </div>
                  </>
                )}

              {filteredResponses["Product Ads (Google/Bing)"] &&
                filteredResponses["Product Ads (Google/Bing)"] != undefined &&
                filteredResponses["Product Ads (Google/Bing)"].length != 0 && (
                  <>
                    <div className="mec-single mec-product">
                      <h4 className="mec-single-title">Product Ads</h4>
                      <EmailKeyMetrics
                        data={filteredResponses["Product Ads (Google/Bing)"]}
                      ></EmailKeyMetrics>
                      <div className="mec-single-link">
                        <Link
                          to={`/reports/v2/view/product_ads_performance/${account_id}`}
                          target="_blank"
                        >
                          Open full report in Agital Inform
                        </Link>
                      </div>
                    </div>
                  </>
                )}

              {filteredResponses["Paid Search (Google/Bing)"] &&
                filteredResponses["Paid Search (Google/Bing)"] != undefined &&
                filteredResponses["Paid Search (Google/Bing)"].length != 0 && (
                  <>
                    <div className="mec-single mec-paid">
                      <h4 className="mec-single-title">Paid Search</h4>
                      <EmailKeyMetrics
                        data={filteredResponses["Paid Search (Google/Bing)"]}
                      ></EmailKeyMetrics>
                      <div className="mec-single-link">
                        <Link
                          to={`/reports/v2/view/paid_search_performance/${account_id}`}
                          target="_blank"
                        >
                          Open full report in Agital Inform
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              {filteredResponses["Paid Social Marketing"] &&
                filteredResponses["Paid Social Marketing"] != undefined &&
                filteredResponses["Paid Social Marketing"].length != 0 && (
                  <>
                    <div className="mec-single mec-facebook">
                      <h4 className="mec-single-title">Paid Social</h4>
                      <div className="mec-single-list mec-facebook-wrapper">
                        <div className="mec-single-list-style mec-facebook-wrapper-style">
                          <ul>
                            <li>
                              <div className="mfl-single">
                                <h4>
                                  <span>
                                    $
                                    {numberWithCommas(
                                      parseFloat(
                                        filteredResponses[
                                          "Paid Social Marketing"
                                        ]?.response?.data[0]?.cost
                                      ).toFixed(0)
                                    )}
                                  </span>{" "}
                                  in Ad Spend
                                </h4>
                                <p>
                                  {" "}
                                  {numberWithCommas(
                                    Math.abs(
                                      filteredResponses["Paid Social Marketing"]
                                        ?.response?.data[0]?.change_cost
                                    )
                                  )}
                                  %{"  "}
                                  {filteredResponses["Paid Social Marketing"]
                                    ?.response?.data[0]?.change_cost > 0
                                    ? "more than last year"
                                    : "less than last year"}
                                </p>
                              </div>
                              <div className="mfl-single">
                                <h4>
                                  <span>
                                    {" "}
                                    {numberWithCommas(
                                      parseFloat(
                                        filteredResponses[
                                          "Paid Social Marketing"
                                        ]?.response?.data[0]?.conversions
                                      ).toFixed(0)
                                    )}{" "}
                                  </span>{" "}
                                  Conversions
                                </h4>
                                <p>
                                  {" "}
                                  {numberWithCommas(
                                    Math.abs(
                                      filteredResponses["Paid Social Marketing"]
                                        ?.response?.data[0]?.change_conversions
                                    )
                                  )}
                                  %{"  "}
                                  {filteredResponses["Paid Social Marketing"]
                                    ?.response?.data[0]?.change_conversions > 0
                                    ? "more than last year"
                                    : "less than last year"}
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="mfl-single">
                                <h4>
                                  <span>
                                    ${" "}
                                    {numberWithCommas(
                                      parseFloat(
                                        filteredResponses[
                                          "Paid Social Marketing"
                                        ]?.response?.data[0]?.revenue
                                      ).toFixed(0)
                                    )}
                                  </span>{" "}
                                  in Revenue
                                </h4>
                                <p>
                                  {" "}
                                  {numberWithCommas(
                                    Math.abs(
                                      filteredResponses["Paid Social Marketing"]
                                        ?.response?.data[0]?.change_revenue
                                    )
                                  )}
                                  %{"  "}
                                  {filteredResponses["Paid Social Marketing"]
                                    ?.response?.data[0]?.change_revenue > 0
                                    ? "more than last year"
                                    : "less than last year"}
                                </p>
                              </div>
                              <div className="mfl-single">
                                <h4>
                                  <span>
                                    {" "}
                                    {numberWithCommas(
                                      parseFloat(
                                        filteredResponses[
                                          "Paid Social Marketing"
                                        ]?.response?.data[0]?.roas
                                      ).toFixed(0)
                                    )}
                                    %
                                  </span>{" "}
                                  ROAS
                                </h4>
                                <p>
                                  {" "}
                                  {numberWithCommas(
                                    Math.abs(
                                      filteredResponses[
                                        "Paid Social Marketing"
                                      ]?.response?.data[0]?.change_roas.toFixed(
                                        2
                                      )
                                    )
                                  )}
                                  %{"  "}
                                  {filteredResponses["Paid Social Marketing"]
                                    ?.response?.data[0]?.change_roas > 0
                                    ? "more than last year"
                                    : "less than last year"}
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="mfl-single">
                                <h4>
                                  <span>
                                    {" "}
                                    {numberWithCommas(
                                      parseFloat(
                                        filteredResponses[
                                          "Paid Social Marketing"
                                        ]?.response?.data[0]
                                          ?.remarketing_conversions
                                      ).toFixed(0)
                                    )}{" "}
                                  </span>{" "}
                                  Remarketing Audience Conversions
                                </h4>
                                <p>
                                  {" "}
                                  {numberWithCommas(
                                    Math.abs(
                                      filteredResponses["Paid Social Marketing"]
                                        ?.response?.data[0]?.change_remarketing
                                    )
                                  )}
                                  %{"  "}
                                  {filteredResponses["Paid Social Marketing"]
                                    ?.response?.data[0]?.change_remarketing > 0
                                    ? "more than last year"
                                    : "less than last year"}
                                </p>
                              </div>
                              <div className="mfl-single">
                                <h4>
                                  <span>
                                    {" "}
                                    {numberWithCommas(
                                      parseFloat(
                                        filteredResponses[
                                          "Paid Social Marketing"
                                        ]?.response?.data[0]
                                          ?.audience_conversions
                                      ).toFixed(0)
                                    )}{" "}
                                  </span>{" "}
                                  Cold Audience Conversions
                                </h4>
                                <p>
                                  {numberWithCommas(
                                    Math.abs(
                                      filteredResponses["Paid Social Marketing"]
                                        ?.response?.data[0]?.change_cold
                                    )
                                  )}
                                  %{"  "}
                                  {filteredResponses["Paid Social Marketing"]
                                    ?.response?.data[0]?.change_cold > 0
                                    ? "more than last year"
                                    : "less than last year"}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="mec-single-link">
                        <strong>Open full</strong>&nbsp;
                        <Link
                          to={`/reports/v2/view/social_performance/${account_id}`}
                          target="_blank"
                        >
                          Facebook Ads
                        </Link>
                        ,&nbsp;
                        <Link
                          to={`/reports/v2/view/pinterest_ads_performance/${account_id}`}
                          target="_blank"
                        >
                          Pinterest Ads
                        </Link>
                        ,&nbsp;<strong>or</strong>
                        <Link
                          to={`/reports/v2/view/tiktok_ads_performance/${account_id}`}
                          target="_blank"
                        >
                          TikTok Ads
                        </Link>
                        &nbsp;
                        <strong>report in Agital Inform</strong>
                      </div>
                    </div>
                  </>
                )}
              {filteredResponses["Product Ads (Amazon Seller Central)"] &&
                filteredResponses["Product Ads (Amazon Seller Central)"] !=
                  undefined &&
                filteredResponses["Product Ads (Amazon Seller Central)"]
                  .length != 0 && (
                  <>
                    <div className="mec-single mec-amazon">
                      <h4 className="mec-single-title">
                        Amazon Seller Central - United States ($)
                      </h4>
                      <EmailKeyMetrics
                        data={
                          filteredResponses[
                            "Product Ads (Amazon Seller Central)"
                          ]
                        }
                      ></EmailKeyMetrics>
                      <div className="mec-single-link">
                        <Link
                          to={`/reports/v2/view/amazon_sponsored_ads_performance/${account_id}`}
                          target="_blank"
                        >
                          Open full report in Agital Inform
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              {filteredResponses["Product Ads (AMS)"] &&
                filteredResponses["Product Ads (AMS)"] != undefined &&
                filteredResponses["Product Ads (AMS)"].length != 0 && (
                  <>
                    <div className="mec-single mec-amazon">
                      <h4 className="mec-single-title">
                        Amazon Marketing Services
                      </h4>
                      <EmailKeyMetrics
                        data={filteredResponses["Product Ads (AMS)"]}
                      ></EmailKeyMetrics>
                      <div className="mec-single-link">
                        <Link
                          to={`/reports/v2/view/ams_performance/${account_id}`}
                          target="_blank"
                        >
                          Open full report in Agital Inform
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              {filteredResponses["Email Marketing"] &&
                filteredResponses["Email Marketing"] != undefined &&
                filteredResponses["Email Marketing"].length != 0 && (
                  <>
                    <div className="mec-single mec-amazon">
                      <h4 className="mec-single-title">Email Marketing</h4>
                      <EmailKeyMetrics
                        data={filteredResponses["Email Marketing"]}
                      ></EmailKeyMetrics>
                      <div className="mec-single-link">
                        <Link
                          to={`/reports/v2/view/klaviyo_email_sms_marketing/${account_id}`}
                          target="_blank"
                        >
                          Open full report in Agital Inform
                        </Link>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
          <div className="monthly-email-footer">
            <p>Copyright © 2023 Agital.</p>
            <p>One Wall Street 5th Floor, Burlington, MA 01803</p>
            <h6>
              Our mailing address is:
              <a href="mailto:inform@agital.com">inform@agital.com</a>
            </h6>
            <p>
              Please do not reply to this email. You are receiving this email
              because you are an Agital customer. <br />
              If you no longer wish to receive emails, please
              <a href="#">unsubscribe</a> or contact your contact person.
            </p>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default MonthlyEmail;
