import React from "react";

function CustomTableBottomComponents(props) {
  const {
    table_bottom_link,
    account_id,
    all_input_data,
    product_ads_performance_bottom_link,
    subscribedAccounts,
  } = props;
  let eparams = all_input_data;

  let f_month = eparams?.f_month;
  let t_month = eparams?.t_month;
  let f_month_compare = eparams?.c_f_month;
  let t_month_compare = eparams?.c_t_month;
  let device = eparams?.device;
  //specially case for geting month from date
  let from_month = f_month?.slice(0, -2);
  let to_month = t_month?.slice(0, -2);
  let c_from_month = f_month_compare?.slice(0, -2);
  let c_to_month = t_month_compare?.slice(0, -2);

  let f_date = eparams?.f_month;
  let t_date = eparams?.t_month;
  let f_date_compare = eparams?.c_f_month;
  let t_date_compare = eparams?.c_t_month;

  let date_range_option = eparams?.date_range_option;
  let date_range_compare_to = eparams?.date_range_compareto;
  if (date_range_compare_to == "None") {
    var append_date = "&month_range_compareto=None";
  } else {
    var append_date =
      "&c_f_month=" + f_month_compare + "&c_t_month=" + t_month_compare;
  }

  return (
    <>
      {table_bottom_link === "true" && (
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            Click{" "}
            <a
              href={
                "/reports/v2/view/organic_search_performance_drilldown/" +
                account_id +
                "?f_month=" +
                f_month +
                "&t_month=" +
                t_month +
                append_date +
                "&device_key=" +
                device
              }
              target="_blank"
            >
              here
            </a>{" "}
            to view individual query performance
          </div>
          <div>
            Click{" "}
            <a
              href={
                "/reports/v2/view/organic_search_brand_vs_nonbrand_query_performance/" +
                account_id +
                "?f_month=" +
                f_month +
                "&t_month=" +
                t_month +
                append_date +
                "&cvr_calculation=" +
                "seo"
              }
              target="_blank"
            >
              here
            </a>{" "}
            for branded vs non-branded query performance
          </div>
        </div>
      )}

      {product_ads_performance_bottom_link === "true" ? (
        <div className="">
          Click{" "}
          <a
            href={
              "/reports/v2/view/adwords_product_performance/" +
              account_id +
              "?f_month=" +
              f_month +
              "&t_month=" +
              t_month +
              append_date +
              "&device_key=" +
              device
            }
            target="_blank"
          >
            here
          </a>{" "}
          to view Google Ads Product Performance Report
        </div>
      ) : (
        ""
      )}

      {subscribedAccounts && subscribedAccounts.length > 0 ? (
        <div style={{ marginTop: "1%" }}>
          Accounts Represented:
          <span>
            {" "}
            {subscribedAccounts.map((acnts, index) => (
              <span key={acnts.account_id}>
                <a
                  href={
                    "/reports/v2/view/holistic_performance/" +
                    acnts?.salesforce_id +
                    "?f_month=" +
                    f_date +
                    "&t_month=" +
                    t_date +
                    "&date_range_option=" +
                    date_range_option +
                    "&date_range_compareto=" +
                    date_range_compare_to +
                    append_date
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {acnts.account_name}
                </a>
                {index < subscribedAccounts.length - 1 && ", "}
              </span>
            ))}
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default CustomTableBottomComponents;
