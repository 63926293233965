import { listOfAllDataSources } from "../../Config/datasources/allDatasources";
import dispatchAmazonMarketingServiceDataSource from "./datasources/amazonMarketingServiceDispatch";

import dispatchAmazonSponsorAdsDataSource from "./datasources/amazonSponsorAdsDispatch";
import dispatchFacebookAdsInsightsServiceDataSource from "./datasources/facebookMarketingServiceDispatch";
import dispatchTiktokServiceDataSource from "./datasources/tiktokDIspatch";
/**
 *
 * @param {string} dataSourceName
 * @param {ReduxDispatchObject} dispatch
 * @param {object} payload
 */
export const dispatchActionsDataSources = (
  dataSourceName,
  dispatch,
  payload
) => {
  switch (dataSourceName) {
    case listOfAllDataSources["amazonSponsorAds"]:
      return dispatchAmazonSponsorAdsDataSource(dispatch, payload);

    case listOfAllDataSources["amazonMarketingService"]:
      return dispatchAmazonMarketingServiceDataSource(dispatch, payload);
    case listOfAllDataSources["tiktok"]:
      return dispatchTiktokServiceDataSource(dispatch, payload);
    case listOfAllDataSources["facebook"]:
      return dispatchFacebookAdsInsightsServiceDataSource(dispatch, payload);
    default:
      break;
  }
  localStorage.removeItem("data_source_information");
};
