const base64UrlEncode = (input) => {
    const base64 = btoa(input);
    return base64.replace('+', '-').replace('/','_').replace(/=+$/, '');
}

const createJWTToken = async (payload, secretKey) => {
    try {

        //Encode the header and payload as Base64Url
    const encodedHeader = base64UrlEncode(JSON.stringify({alg: 'HS256', typ: 'JWT'}));
    const encodedPayload = base64UrlEncode(JSON.stringify(payload));


    //Create the token body by concatenating the encoded header and payload with a period(.)
    const tokenBody = `${encodedHeader}.${encodedPayload}`;

    // Convert the secert key to an ArrayBuffer
    const encoder = new TextEncoder();
    const keyData = encoder.encode(secretKey);

    // Import the key for HMAC SHA-256
    const key  = await crypto.subtle.importKey(
        'raw',
        keyData,
        {name: 'HMAC', hash: 'SHA-256'},
        false,
        ['sign']
    );

    // Create the signature using HMAC SHA-256
    const encoderSignature = new TextEncoder();
    const data = encoderSignature.encode(tokenBody);
    const signature =  await crypto.subtle.sign('HMAC', key, data);

    // Encode the signature as Base64Url
    const encodedSignature = base64UrlEncode(Array.from(new Uint8Array(signature)).map(byte => String.fromCharCode(byte)).join(''));

    // Final JWT token by concatenating the token body and encoded signature with another period ('.') separator
    const jwtToken = `${tokenBody}.${encodedSignature}`;

    return jwtToken;
        
    } catch (error) {
        console.error('Error creating JWT token:', error);
        return null;
    }
};
    
export default createJWTToken;
