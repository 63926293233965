import { formatColumns } from "../../../Utils/colorLogic";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";

const AmazonProductPerformance = (props) => {
    let recordData = props?.record;

    return(
        <>
            <span
            className={formatColumns(
                props?.value,
                recordData,
                props?.goalData,
                props?.column?.dataIndex
            )}
            >
            <FormattedNumberCell
                value={props?.value}
                format={props?.column?.format}
            />
            </span>
        </>
    )
}

export default AmazonProductPerformance;