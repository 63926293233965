import { Typography } from "antd";
import React, { useEffect } from "react";

import { Col, Form, Row } from "antd";
import { useDispatch } from "react-redux";
import GoogleAdsCreateConfigurationForm from "../../Components/Configuration/googleAds/GoogleAdsCreateForm";
import GoogleAdsTable from "../../Components/Configuration/googleAds/googleAdsTable";
import {
  createGoogleAdsParamDataSourcesThunk,
  getAllGoogleAdsParamDataSourcesThunk,
} from "../../Redux/Actions/Thunk/datasources/GoogleAdsThunk";
const { Title } = Typography;

const DsGoogleAdsConfiguration = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllGoogleAdsParamDataSourcesThunk({}));
  }, []);

  const onFinish = (values) => {
    let file =
      values?.key_file?.target?.files.length > 0
        ? values.key_file.target.files[0]
        : null;
    const formData = new FormData();
    formData.append("client_id", values.client_id);
    formData.append("user", values.user_email);
    formData.append("file", file);
    formData.append("developer_token", values.developer_token);
    formData.append("user_agent", values.user_agent);
    formData.append("platform", values.platform);
    formData.append("file_type", values.file_type);
    dispatch(createGoogleAdsParamDataSourcesThunk(formData, form));
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <div>
      <Title level={2}>Google Ads</Title>
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} md={12} sm={12}>
          <GoogleAdsTable />
        </Col>
        <Col xs={24} md={12} sm={12}>
          <Typography
            style={{
              textAlign: "center",
              padding: "10px 0 ",
            }}
          >
            ADD GOOGLE AD ACCOUNT
          </Typography>
          <GoogleAdsCreateConfigurationForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DsGoogleAdsConfiguration;
