import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Donut from "../../Highcharts/Donut";
import Column from "../../Highcharts/Column";
import { formDataAppend } from "../../../Utils/helpers";
import { Spin } from "antd";
import { titleCase } from "../../../Utils/helpers";
import { format_data } from "../../../Utils/helpers";
import Config from "../../../Config";
import { Loading } from "react-jsx-highcharts";
import Layout from "../../ReportLayouts/Layout";
import { FiSquare } from "react-icons/fi";
const {
	API: { baseURL, authKey },
} = Config;
const MyContext = React.createContext();
const PerformanceReport = (props) => {
	const [loading, setLoading] = useState(false);
	const [tableData, setData] = useState([]);
	const [jsonData, setJsonData] = useState([]);
	const [labelsNew, setLabelsNew] = useState([]);
	const [totalTable, setTableTotal] = useState([]);
	const [TableColumns, setTableColumns] = useState([]);
	useEffect(() => {
		getPerformance();
	}, []);

	const getPerformance = async () => {
		var data = props?.all_input_data;
		var formData = formDataAppend(data);
		var requestOptions = {
			method: "POST",
			body: formData,
			redirect: "follow",
		};
		setLoading(true);
		fetch(baseURL + props?.block?.api_path, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setData(result?.data);
				setJsonData(result?.json_data_chart);
				setLabelsNew(result?.labels_new);
				setTableTotal(result?.table_total);
				setTableColumns(result?.table_columns);
			})
			.catch((err) => {
			})
			.finally(() => {
				setLoading(false);
			});
	};

	//get table column
	// const column = TableColumns ? TableColumns : [];
	//get table heading data
	const ThData = () => {
		return TableColumns?.map((data, i) => {
			return <th key={i}>{data}</th>;
		});
	};
	// get table row data
	const tdData = () => {
		return tableData.map((data, index) => {
			return (
				<tr key={index}>
					{labelsNew?.map((v, idx) => {
						return (
							<>
								{idx === 0 ? (
									<td
										style={{
											textTransform: "capitalize",
										}}
										className="performance-report-color-icon"
										key={v.key}
									>
										<span>
											<FiSquare
												color={jsonData[index].data[index].color}
												fill={jsonData[index].data[index].color}
											/>
										</span>

										{data[v.key]}
									</td>
								) : (
									<td key={v.key}>
										{v?.format == "amount"
											? format_data(data[v.key], v?.format)
											: v?.format == "percentage"
											? format_data(data[v.key], v?.format)
											: v?.format == "number"
											? format_data(data[v.key], v?.format)
											: data[v.key]}
									</td>
								)}
							</>
						);
					})}
				</tr>
			);
		});
	};

	return (
		<>
			<Spin spinning={loading}>
				<div className="performance-graph-chart-wrapper">
					<Donut data={jsonData} />

					<Column data={jsonData} />
				</div>
				<div className="performance-datatable">
					<table id="performance-table">
						<thead>
							<tr>{ThData()}</tr>
						</thead>
						<tbody>{tdData()}</tbody>
						<tfoot>
							<tr>
								<td>Total</td>
								{totalTable?.map(function (total_data, j) {
									return (
										<td key={j}>
											{total_data?.format == "amount"
												? format_data(total_data.data, total_data?.format)
												: total_data?.format == "number"
												? format_data(total_data.data, total_data?.format)
												: total_data?.format == "percentage"
												? format_data(total_data.data, total_data?.format)
												: total_data.data}
										</td>
									);
								})}
							</tr>
						</tfoot>
					</table>
				</div>
			</Spin>
		</>
	);
};

export default PerformanceReport;
