import { ACTIONS_TYPES } from "../Reducers/barGraphReducer";

export function fetchBarGraph(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_BAR_GRAPH,
    payload,
  };
}

export function getBarGraph(payload) {
  return {
    type: ACTIONS_TYPES.GET_BAR_GRAPH,
    payload,
  };
}
