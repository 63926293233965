export const initialState = {
  internalReports: {},
  isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
  GET_INTERNAL_REPORTS: "GET_INTERNAL_REPORTS",
  FETCH_INTERNAL_REPORTS: "FETCH_INTERNAL_REPORTS",
});

export function InternalReportReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.GET_INTERNAL_REPORTS:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_INTERNAL_REPORTS:
      return {
        ...state,
        isLoading: false,
        internalReports: action.payload,
      };
    default:
      return state;
  }
}
