import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Radio,
  Form,
  Input,
  Tabs,
  Select,
  Upload,
  Tooltip,
  message,
  TimePicker,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import { getAccountFeedsUrl } from "../../Redux/Actions/Thunk/accountThunk";
import moment from "moment/moment";
import { Token } from "../../Utils/helpers/token";

const UploadProductCatalog = (props) => {
  const {
    API_BI: { baseURL },
  } = Config;
  const [visible, setVisible] = useState(false);
  const [feedUrl, setFeedUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [secondSelection, setSecondSelection] = useState("");
  const [thirdSelection, setThirdSelection] = useState("");
  const [pullStatus, setPullStatus] = useState("");
  const [openTime, setOpenTime] = useState(false);
  const timePickerRef = useRef(null);
  const dispatch = useDispatch();
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const {
    accounts: {
      account_feeds_data: { data: feeds_data = [] } = {},
      isLoading,
    } = {},
  } = useSelector((state) => state);

  var account_id = props?.account_id;
  // Fetch data
  useEffect(() => {
    dispatch(getAccountFeedsUrl({ account_id }));
  }, []);

  const onFinish = (values) => {
    const data = new FormData();
    data.append("data_file", values.data_file[0].originFileObj);
    data.append("account_id", props.account_id);

    dispatch({
      type: ACTIONS_TYPES.ACCOUNT_FEEDS_UPLOAD_REQUEST,
    });

    fetch(baseURL + "api/v1/upload-feeds-product-catalog", {
      method: "POST",
      body: data,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          message.success(json.message);
        });
      } else {
        response.json().then((json) => {
          message.error(json.message);
        });
      }
      form.resetFields();
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_FEEDS_UPLOAD_COMPLETE,
      });
    });
    setVisible(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (Object.keys(feeds_data).length > 0) {
      form.setFieldsValue({
        url: feeds_data.feed_url,
        username: feeds_data.username,
        password: Token.decryptAuthParams(feeds_data.password),
        frequency: parseInt(feeds_data.frequency_status),
      });
      setPullStatus(feeds_data.status);
      setSelectedOption(feeds_data.frequency_type);
      setSecondSelection(feeds_data.frequency_day);
    }
  }, [feeds_data]);

  const onCreate = (values) => {
    const data = new FormData();

    data.append("account_id", props.account_id);
    data.append("feed_url", values["url"]);
    data.append("username", values["username"] ? values["username"] : "");
    data.append("password", values["password"] ? values["password"] : "");
    data.append("frequency_status", values["frequency"]);
    if (values["frequency"] == 1) {
      data.append("frequency_type", selectedOption);
      if (selectedOption == "Daily") {
        data.append("frequency_day", "");
      } else if (selectedOption == "Monthly") {
        data.append("frequency_day", secondSelection);
      } else {
        data.append("frequency_day", secondSelection);
      }
    } else {
      if (values["frequency"] == 0) {
        data.append("frequency_type", "");
        data.append("frequency_day", "");
      } else {
        data.append("frequency_type", "");
        data.append("frequency_day", "");
      }
    }

    dispatch({
      type: ACTIONS_TYPES.ACCOUNT_FEEDS_URL_UPLOAD_REQUEST,
    });

    fetch(baseURL + "api/v1/upload-url-feeds", {
      method: "POST",
      body: data,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          message.success(json.message);
        });
      } else {
        response.json().then((json) => {
          message.error(json.message);
        });
      }
      // form.resetFields();
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_FEEDS_URL_UPLOAD_COMPLETE,
      });
    });
    setVisible(false);
  };

  const optionsData = {
    Weekly: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    Monthly: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
  };

  const handleFirstSelectChange = (value) => {
    setSelectedOption(value);
    setSecondSelection("");
    setThirdSelection("");
  };

  const handleSecondSelectChange = (value) => {
    setSecondSelection(value);
  };

  const handleThirdSelectChange = (time, timestring) => {
    setThirdSelection(time.format("HH"));
    setOpenTime(false);
  };
  const onOpenChange = (status) => {
    setOpenTime(status);
  };
  const [form] = Form.useForm();

  const file_props = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    beforeUpload(data_file) {
      const isValidType =
        data_file.type === "text/csv" || data_file.type === "text/plain";
      if (!isValidType) {
        form.setFields([
          {
            name: "data_file",
            errors: ["Invalid file type! File type must be csv or text file."],
          },
        ]);
      }

      const isLt5M = data_file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        form.setFields([
          {
            name: "data_file",
            errors: ["File size must be smaller than 5MB!"],
          },
        ]);
      }

      if (isValidType && isLt5M) {
        form.setFields([
          {
            name: "data_file",
            errors: null,
          },
        ]);
        return false;
      }

      return (isValidType && isLt5M) || Upload.LIST_IGNORE;
    },
  };

  const checkUsername = (_, value) => {
    const username = form.getFieldValue("username");
    const password = form.getFieldValue("password");
    if (!username && password) {
      return Promise.reject(
        new Error("Username is required if password is present.")
      );
    }
    return Promise.resolve();
  };

  const checkPassword = (_, value) => {
    const username = form.getFieldValue("username");
    const password = form.getFieldValue("password");
    if (!password && username) {
      return Promise.reject(
        new Error("Password is required if username is present")
      );
    }
    return Promise.resolve();
  };

  const handleFieldChange = () => {
    form.validateFields(["username", "password"]);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Upload Catalog
      </Button>
      <Modal
        className="import-modals upload-product-modal"
        title="Upload Feeds Product Catalog"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="From URL" key="1">
            <Form
              name="upload_catalog_url"
              onFinish={onCreate}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label={
                  <>
                    <span style={{ marginRight: "5px" }}>Feed URL</span>
                  </>
                }
                name="url"
                rules={[
                  {
                    required: true,
                    message: "Please enter your URL!",
                  },
                  {
                    type: "url",
                    warningOnly: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Username"
                name="username"
                rules={[{ validator: checkUsername }]}
              >
                <Input onChange={handleFieldChange} />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ validator: checkPassword }]}
              >
                <Input.Password onChange={handleFieldChange} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span style={{ marginRight: "5px" }}>Frequency</span>
                  </>
                }
                name="frequency"
              >
                <Radio.Group defaultValue={0} value={0}>
                  <Radio value={0}>One-time only</Radio>
                  <Radio value={1}>Recurring</Radio>
                  <Radio value={2}>Never</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className="ant-form-extra"
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.frequency !== currentValues.frequency
                }
              >
                {({ getFieldValue }) => {
                  const frequency = getFieldValue("frequency");
                  return frequency === 1 ? (
                    <div className="ant-form-item ant-form-extra">
                      <p>Set time to change feed:</p>
                      <Select
                        placeholder={
                          !selectedOption ? "Select an option" : undefined
                        }
                        value={selectedOption || undefined}
                        style={{ width: 180 }}
                        onChange={handleFirstSelectChange}
                        options={["Daily", "Weekly", "Monthly"].map(
                          (option) => ({
                            label: option,
                            value: option,
                          })
                        )}
                      />
                      {selectedOption !== "Daily" && (
                        <Select
                          placeholder={
                            !secondSelection ? "Select Day" : undefined
                          }
                          style={{ width: 140 }}
                          value={secondSelection || undefined}
                          onChange={handleSecondSelectChange}
                          options={
                            selectedOption === "Monthly"
                              ? optionsData["Monthly"].map((option) => ({
                                  label: option,
                                  value: option,
                                }))
                              : optionsData["Weekly"].map((option) => ({
                                  label: option,
                                  value: option,
                                }))
                          }
                        />
                      )}
                      {/* <TimePicker
                        showNow={false}
                        ref={timePickerRef}
                        open={openTime}
                        onOpenChange={onOpenChange}
                        popupClassName="create-frequency-time"
                        style={{ width: 120 }}
                        // value={thirdSelection}
                        value={
                          thirdSelection != ""
                            ? moment(thirdSelection, "HH")
                            : ""
                        }
                        onSelect={handleThirdSelectChange}
                        format="HH"
                      /> */}
                    </div>
                  ) : null;
                }}
              </Form.Item>

              <Form.Item className="ant-form-extra">
                <span
                  className={`extra-create ${
                    pullStatus == 1 ? "create-disabled" : ""
                  }`}
                >
                  <Button type="primary" htmlType="submit">
                    Create
                  </Button>
                </span>
                <span className="extra-cancel">
                  <Button onClick={handleCancel}>Cancel</Button>
                </span>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Upload File" key="2">
            <Form
              name="upload_catalog_file"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={
                  <>
                    <span style={{ marginRight: "5px" }}>Filename</span>
                    <Tooltip title="Data from uploaded file will be reflected after 08:00 UTC.">
                      <FaInfoCircle />
                    </Tooltip>
                  </>
                }
                name="data_file"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                style={{ marginBottom: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Please upload a file",
                  },
                ]}
              >
                <Upload {...file_props} maxCount={1}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item className="ant-form-extra">
                <span className="extra-create">
                  <Button type="primary" htmlType="submit">
                    Upload
                  </Button>
                </span>
                <span className="extra-cancel">
                  <Button onClick={handleCancel}>Cancel</Button>
                </span>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default UploadProductCatalog;
