import { Typography } from "antd";
import React, { useEffect } from "react";

import { Col, Form, Row } from "antd";
import { useDispatch } from "react-redux";
import GoogleConsoleCreateConfigurationForm from "../../Components/Configuration/googleConsole/GoogleConsoleCreateForm";
import GoogleConsoleTable from "../../Components/Configuration/googleConsole/googleConsoleTable";
import {
  createGoogleConsoleDataSourcesThunk,
  getAllGoogleConsoleDataSourcesThunk,
} from "../../Redux/Actions/Thunk/datasources/ds_configs/GoogleConsoleThunk";
const { Title } = Typography;

const DsGoogleConsole = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllGoogleConsoleDataSourcesThunk({}));
  }, [dispatch]);

  const onFinish = (values) => {
    let file =
      values?.key_file?.target?.files.length > 0
        ? values.key_file.target.files[0]
        : null;
    const formData = new FormData();
    formData.append("account", values.account);
    formData.append("email", values.user_email);
    formData.append("file", file);
    formData.append("platform", values.platform);
    formData.append("key", values.key);
    formData.append("file_type", values.file_type);
    formData.append("project_number", values.project_number);
    if (!file) {
      formData.delete("file");
    }
    if (!values.key) {
      formData.delete("key");
    }

    if (!values.file_type) {
      formData.delete("file_type");
    }

    dispatch(createGoogleConsoleDataSourcesThunk(formData, form));
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div>
      <Title level={2}>Google Console</Title>
      <Row wrap={true} gutter={[18, 10]} justify="space-between">
        <Col xs={24} md={14} sm={10}>
          <GoogleConsoleTable />
        </Col>
        <Col xs={24} md={10} sm={10}>
          <Typography
            style={{
              textAlign: "center",
              padding: "10px 0 ",
            }}
          >
            ADD GOOGLE CONSOLE ACCOUNT
          </Typography>
          <GoogleConsoleCreateConfigurationForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DsGoogleConsole;
