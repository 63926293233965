import * as LineChartApi from "../../../Api/LineChartApi";
import * as LineChartAction from "../LineChartAction";
import { ACTIONS_TYPES } from "../../Reducers/LineChartReducer";
import * as apiPath from "../../../Api/indexApi";

export const getRevenueRetentionData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.REQUEST_DATA,
        payload: data?.block?.block_id,
      });
      let lineChartData = {};
      const response = await LineChartApi.getLineChartData(props, data);
      if (response?.status === 200) {
        lineChartData = {
          [data.block.block_id]: {
            series_data: response?.data?.series_data,
            categories: response?.data?.categories,
            tab_options: response?.data?.tab_options,
            insight: response?.data?.insight,
            insight_data: response?.data?.insight_data,
          },
        };
      }
      return dispatch(
        LineChartAction.getlineChartData(data?.block?.block_id, lineChartData)
      );
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const getLineChartData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_LINE_CHART_DATA,
      });
      let lineChartData = [];
      const response = await apiPath.getApiPath(props, data);
      if (response?.status === 200) {
        lineChartData = {
          series_data: response?.data?.series_data,
          categories: response?.data?.categories,
        };
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_LINE_CHART_DATA,
        payload: lineChartData,
      });
      return dispatch(LineChartAction.getlineChartData(lineChartData));
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
