/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaInfoCircle, FaPencilAlt } from "react-icons/fa";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Tooltip,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Loading } from "react-jsx-highcharts";
import { formDataAppend, numberWithCommas } from "../../../Utils/helpers";
import Config from "../../../Config";
import { useDispatch } from "react-redux";
import { getDataTable } from "../../../Redux/Actions/Thunk/dataTableThunk";
import { MdOutlineOpenInNew } from "react-icons/md";
import { useState } from "react";
import { element } from "prop-types";
import { Token } from "../../../Utils/helpers/token";
import {
  getServiceSettings,
  saveTargetGoal,
} from "../../../Redux/Actions/Thunk/serviceSettingsThunk";
import moment from "moment/moment";

const {
  ADMIN: { baseURL: data_source_url },
} = Config;

const ServiceCell = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState("");
  const [getSubject, setSubject] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  var notificationLink = getNotificationLink(props?.record, props?.value);
  var record = props?.record;
  var notificationType = record["notification_type"];
  var email_data = record["email_data"];

  if (
    notificationType == "Budget Overspending" ||
    notificationType == "Budget Underspending"
  ) {
    var tooltipTitle =
      "Click Fix it for me to automatically update " +
      record["name"] +
      " 's Maximum Budget for the service to the pacing amount.";
  } else {
    var tooltipTitle =
      "Click Fix it for me to automatically update " +
      record["name"] +
      " 's Maximum Budget for each service to the pacing amount.";
  }
  return (
    <>
      {props.value != null &&
      notificationType != "SEMrush Keyword Research Data Ready" ? (
        <div className="notification-column">
          {notificationType == "API Access Denied" ? (
            <a
              href={notificationLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </a>
          ) : (
            <Link
              to={notificationLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </Link>
          )}

          <span style={{ display: "flex" }}>
            {notificationType == "Budget Overspending" ||
            notificationType == "Budget Underspending" ||
            notificationType == "Spend Capacity Alert" ? (
              <Tooltip trigger="hover" title={tooltipTitle}>
                <span
                  className="table-header-tooltip"
                  style={{ color: "#80B5DE" }}
                >
                  <FaInfoCircle />
                </span>
              </Tooltip>
            ) : (
              ""
            )}
            {email_data != null ? (
              <MdOutlineOpenInNew
                onClick={() =>
                  openPopUp(
                    email_data,
                    setIsModalOpen,
                    setContent,
                    setSubject,
                    props?.record["account_id"],
                    data_source_url
                  )
                }
              />
            ) : (
              ""
            )}
          </span>
          <Modal
            title={getSubject}
            visible={isModalOpen}
            onCancel={handleCancel}
            width={700}
            className="insights-workflow-modal"
            footer={null}
          >
            <div
              className="insights-workflow-text"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </Modal>
        </div>
      ) : (
        <FormattedNumberCell value={props.value} format={props.column.format} />
      )}
    </>
  );
};

const openPopUp = (
  email_data,
  setIsModalOpen,
  setContent,
  setSubject,
  account_id,
  data_source_url
) => {
  setIsModalOpen(true);
  let admin_data_source_url = `${data_source_url}data-sources/`;
  email_data = email_data.replace(/\/data-sources\//g, admin_data_source_url);
  email_data = email_data.replace(/\/accounts\/detail\//g, "/account/detail/");
  var data = JSON.parse(email_data);
  var tables = data["tables"];
  var table1 = data["table1"];
  var table2 = data["table2"];
  var table3 = data["table3"];
  var table4 = data["table4"];
  var table5 = data["table5"];
  var data1 = data["data1"];
  var data2 = data["data2"];
  var data3 = data["data3"];
  var data4 = data["data4"];
  var data5 = data["data5"];
  var subject = data["subject"];
  var paragraph1 = data["paragraph1"];
  var paragraph2 = data["paragraph2"];
  var paragraph3 = data["paragraph3"];
  var paragraph4 = data["paragraph4"];
  var paragraph5 = data["paragraph5"];
  var links = data["links"];
  var date = data["date"];
  var file_path = data["file_path"];
  var table_html = "<div>";
  if (subject != undefined) {
    setSubject(subject);
  }

  if (tables != undefined) {
    if (table1 != undefined && tables.includes(1)) {
      if (paragraph1 != undefined) {
        table_html += "<p>";
        table_html += paragraph1;
        table_html += "</p>";
      }
      table_html += "<table>";
      table_html += "<thead><tr>";
      table1.forEach((element) => {
        table_html += "<th>";
        table_html += element.key.replace(/[^a-zA-Z0-9\s]/g, " ");
        table_html += "</th>";
      });
      table_html += "</tr></thead>";
      table_html += '<tbody id="tbody">';
      data1.forEach((element) => {
        table_html += "<tr>";
        table1.forEach((value, key) => {
          table_html += "<td>";
          table_html += element[value.key];
          table_html += "</td>";
        });
        table_html += "</tr>";
      });
      table_html += "</tbody></table>";
    }

    if (table2 != undefined && tables.includes(2)) {
      if (paragraph2 != undefined) {
        table_html += "<p>";
        table_html += paragraph2;
        table_html += "</p>";
      }
      table_html += "<table>";
      table_html += "<thead><tr>";
      table2.forEach((element) => {
        table_html += "<th>";
        table_html += element.key.replace(/[^a-zA-Z0-9\s]/g, " ");
        table_html += "</th>";
      });
      table_html += "</tr></thead>";
      table_html += '<tbody id="tbody">';
      data2.forEach((element) => {
        table_html += "<tr>";
        table2.forEach((value, key) => {
          table_html += "<td>";
          table_html += element[value.key];
          table_html += "</td>";
        });
        table_html += "</tr>";
      });
      table_html += "</tbody></table>";
    }

    if (table3 != undefined && tables.includes(3)) {
      if (paragraph3 != undefined) {
        table_html += "<p>";
        table_html += paragraph3;
        table_html += "</p>";
      }
      table_html += "<table>";
      table_html += "<thead><tr>";
      table3.forEach((element) => {
        table_html += "<th>";
        table_html += element.key.replace(/[^a-zA-Z0-9\s]/g, " ");
        table_html += "</th>";
      });
      table_html += "</tr></thead>";
      table_html += '<tbody id="tbody">';
      data3.forEach((element) => {
        table_html += "<tr>";
        table3.forEach((value, key) => {
          table_html += "<td>";
          table_html += element[value.key];
          table_html += "</td>";
        });
        table_html += "</tr>";
      });
      table_html += "</tbody></table>";
    }

    if (table4 != undefined && tables.includes(4)) {
      if (paragraph4 != undefined) {
        table_html += "<p>";
        table_html += paragraph4;
        table_html += "</p>";
      }
      table_html += "<table>";
      table_html += "<thead><tr>";
      table4.forEach((element) => {
        table_html += "<th>";
        table_html += element.key.replace(/[^a-zA-Z0-9\s]/g, " ");
        table_html += "</th>";
      });
      table_html += "</tr></thead>";
      table_html += '<tbody id="tbody">';
      data4.forEach((element) => {
        table_html += "<tr>";
        table4.forEach((value, key) => {
          table_html += "<td>";
          table_html += element[value.key];
          table_html += "</td>";
        });
        table_html += "</tr>";
      });
      table_html += "</tbody></table>";
    }

    if (table5 != undefined && tables.includes(5)) {
      if (paragraph5 != undefined) {
        table_html += "<p>";
        table_html += paragraph5;
        table_html += "</p>";
      }
      table_html += "<table>";
      table_html += "<thead><tr>";
      table5.forEach((element) => {
        table_html += "<th>";
        table_html += element.key.replace(/[^a-zA-Z0-9\s]/g, " ");
        table_html += "</th>";
      });
      table_html += "</tr></thead>";
      table_html += '<tbody id="tbody">';
      data5.forEach((element) => {
        table_html += "<tr>";
        table5.forEach((value, key) => {
          table_html += "<td>";
          table_html += element[value.key];
          table_html += "</td>";
        });
        table_html += "</tr>";
      });
      table_html += "</tbody></table>";
    }
  } else {
    if (paragraph1 != undefined) {
      table_html += "<p>";
      table_html += paragraph1;
      table_html += "</p>";
    }
    if (table1 != undefined) {
      table_html += "<table>";
      table_html += "<thead><tr>";
      table1.forEach((element) => {
        table_html += "<th>";
        table_html += element.key.replace(/[^a-zA-Z0-9\s]/g, " ");
        table_html += "</th>";
      });
      table_html += "</tr></thead>";
      table_html += '<tbody id="tbody">';
      data1.forEach((element) => {
        table_html += "<tr>";
        table1.forEach((value, key) => {
          table_html += "<td>";
          table_html += element[value.key];
          table_html += "</td>";
        });
        table_html += "</tr>";
      });
      table_html += "</tbody></table>";
    }
    if (paragraph2 != undefined) {
      table_html += "<p>";
      table_html += paragraph2;
      table_html += "</p>";
    }
    if (table2 != undefined) {
      table_html += "<table>";
      table_html += "<thead><tr>";
      table2.forEach((element) => {
        table_html += "<th>";
        table_html += element.key.replace(/[^a-zA-Z0-9\s]/g, " ");
        table_html += "</th>";
      });
      table_html += "</tr></thead>";
      table_html += '<tbody id="tbody">';
      data2.forEach((element) => {
        table_html += "<tr>";
        table2.forEach((value, key) => {
          table_html += "<td>";
          table_html += element[value.key];
          table_html += "</td>";
        });
        table_html += "</tr>";
      });
      table_html += "</tbody></table>";
    }
    if (paragraph3 != undefined) {
      table_html += "<p>";
      table_html += paragraph3;
      table_html += "</p>";
    }
  }

  if (links != undefined) {
    links.forEach((link) => {
      table_html += "<p>";
      table_html += link["text1"];
      table_html +=
        " <a href=" +
        link["href"] +
        " target='_blank'>" +
        link["link"] +
        " </a>";
      table_html += link["text2"];
      table_html += "</p>";
    });
  }

  if (date != undefined) {
    // table_html += "<br>";
    table_html += "<p>";
    table_html += date;
    table_html += "</p>";
  }

  if (file_path != undefined) {
    //
  }

  setContent(table_html);
};

const getNotificationLink = (record, notification_name) => {
  var dt = new Date();
  var y = dt.getFullYear();
  var m = dt.getMonth();
  var currentMonth = y + "-" + getTwoDigitNumber(m + 1);
  if (
    record["notification_type"] == "Budget Overspending" ||
    record["notification_type"] == "Budget Underspending" ||
    record["notification_type"] == "Up for Renewal"
  ) {
    return (
      "/account/detail/" +
      record["account_id"] +
      "/accountId=" +
      record["account_id"] +
      "&serviceId=" +
      record["service_id"] +
      "&service=" +
      record["sv_name"].split(" ").join("+") +
      "&month=" +
      currentMonth +
      (record["marketplae_id"] !== null
        ? "&marketplace_id=" + record["marketplace_id"]
        : "")
    );
  } else if (record["notification_type"] == "Monthly Email Reports Not Sent") {
    return "/account/detail/" + record["account_id"];
  } else if (record["notification_type"] == "API Access Denied") {
    return (
      `${data_source_url}data-sources/` +
      record["account_id"] +
      "?token=" +
      Token.encryptAuthParams(localStorage.getItem("token"))
    );
  } else if (record["notification_type"] == "Product Approval Alert") {
    return "/reports/v2/view/product_approval_report";
  } else if (record["notification_type"] == "Spend Capacity Alert") {
    return "/reports/v2/view/account_manager_dashboard";
  } else if (record["notification_type"] == "Amazon Payment Issue") {
    if (notification_name.includes("AMS")) {
      return "/reports/v2/view/amazon_marketing_daily_spend";
    } else {
      return "/reports/v2/view/amazon_sponsored_ads_daily_spend";
    }
  } else if (
    record["notification_type"] == "Social Campaigns with High Spend Change"
  ) {
    return "/reports/v2/view/social_performance/" + record["account_id"];
  } else if (
    record["notification_type"] == "Search Terms Percent Spend By Grade"
  ) {
    if (
      notification_name.includes("Amazon") ||
      notification_name.includes("ASC")
    ) {
      return (
        "/reports/v2/view/amazon_search_term_insights/" + record["account_id"]
      );
    } else if (notification_name.includes("AMS")) {
      return (
        "/reports/v2/view/ams_search_term_insights/" + record["account_id"]
      );
    } else if (notification_name.includes("Google")) {
      return (
        "/reports/v2/view/adwords_search_term_insights/" + record["account_id"]
      );
    } else if (notification_name.includes("Bing")) {
      return (
        "/reports/v2/view/bing_search_term_insights/" + record["account_id"]
      );
    }
  } else if (record["notification_type"] == "Landing Page Opportunities") {
    return "/reports/v2/view/landing_page_performance";
  } else if (
    record["notification_type"] == "Task Completed by Client" ||
    record["notification_type"] == "Task Verified by EXCLUSIVE"
  ) {
    if (notification_name.includes("Paid Opportunity")) {
      return "/reports/v2/view/paid_opportunity/" + record["account_id"];
    } else {
      return "/reports/v2/view/organic_search_custom/" + record["account_id"];
    }
  } else if (record["notification_type"] == "Performance Threshold Alert") {
    return "/reports/v2/view/holistic_performance/" + record["account_id"];
  } else if (record["notification_type"] == "Data Quality Alert") {
    var notificationData = JSON.parse(record["notification_data"]);
    var reportKey = notificationData.highest_change["report_key"];
    return "/reports/v2/view/" + reportKey + "/" + record["account_id"];
  } else if (
    record["notification_type"] == "Amazon Campaigns Limited By Budget"
  ) {
    var notificationData1 = JSON.parse(record["notification_data"]);
    var drillReport = notificationData1["drill_report"];
    if (drillReport == "ams_daily_spend") {
      return "/reports/v2/view/amazon_marketing_daily_spend";
    } else {
      return "/reports/v2/view/amazon_sponsored_ads_daily_spend";
    }
  } else if (record["notification_type"] == "ASINs Missing from Campaigns") {
    if (notification_name.includes("ASC")) {
      return (
        "/reports/v2/view/amazon_product_performance/" + record["account_id"]
      );
    } else {
      return (
        "/reports/v2/view/amazon_marketing_product_performance/" +
        record["account_id"]
      );
    }
  } else if (record["notification_type"] == "Negative Keyword Opportunities") {
    return (
      "/account/detail/" +
      record["account_id"] +
      "/accountId=" +
      record["account_id"] +
      "&serviceId=" +
      record["service_id"] +
      "&service=" +
      record["sv_name"].split(" ").join("+") +
      "&month=" +
      currentMonth +
      (record["marketplae_id"] !== null
        ? "&marketplace_id=" + record["marketplace_id"]
        : "")
    );
  }
};

function getTwoDigitNumber(n) {
  return n > 9 ? n : "0" + n;
}

const InsightWorkflowNotification = (props) => {
  const {
    API_BI: { baseURL, authKey },
  } = Config;

  const columnIds = ["notification_name"];
  const action = ["action"];
  const notification_name = ["notification_name"];
  const severity = ["severity"];
  const inputData = props.extraParams.all_input_data;
  const record = props.record;
  const text = props.value;
  const setLoading = props.setLoading;
  const extraParams = props.extraParams;
  var formData = formDataAppend(inputData);
  const dispatch = useDispatch();

  if (severity.includes(props.column.dataIndex)) {
    if (props.value == "Warning") {
      return (
        <span class="report-notification-wrapper">
          <span class="severity-yellow"></span>
        </span>
      );
    } else if (props.value == "Critical") {
      return (
        <span class="report-notification-wrapper">
          <span class="severity-red"></span>
        </span>
      );
    } else {
      return (
        <span class="report-notification-wrapper">
          <span class="severity-blue"></span>
        </span>
      );
    }
  }

  if (columnIds.includes(props.column.dataIndex)) {
    return <ServiceCell {...props} />;
  } else if (action.includes(props.column.dataIndex)) {
    const menu = checkMenuOptions(
      Menu,
      inputData,
      props.record,
      baseURL,
      setLoading,
      extraParams,
      formData,
      dispatch
    );
    return (
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        className="action-dropdown"
        overlayClassName="action-dropdown-wrapper"
      >
        <Button>
          Act <DownOutlined />
        </Button>
      </Dropdown>
    );
  } else {
    return (
      <>
        <span>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </>
    );
  }
};

export function checkMenuOptions(
  Menu,
  inputData,
  record,
  baseURL,
  setLoading,
  props,
  formData,
  dispatch
) {
  if (
    inputData["status"] == "Done last 7 days" ||
    inputData["status"] == "Done last 30 days" ||
    inputData["status"] == "Ignored" ||
    inputData["status"] == "All Done"
  ) {
    return appendMenu(
      Menu,
      "undo",
      baseURL,
      record,
      setLoading,
      props,
      formData,
      dispatch
    );
  } else if (inputData["status"] == "Open") {
    return checkNotificationType(
      Menu,
      record,
      "open",
      baseURL,
      setLoading,
      props,
      formData,
      dispatch
    );
  } else if (inputData["status"] == "All") {
    if (
      record["status"] === "Done" ||
      record["status"] === "Ignore Once" ||
      record["status"] === "All Done"
    ) {
      return appendMenu(
        Menu,
        "undo",
        baseURL,
        record,
        setLoading,
        props,
        formData,
        dispatch
      );
    } else {
      return checkNotificationType(
        Menu,
        record,
        "open",
        baseURL,
        setLoading,
        props,
        formData,
        dispatch
      );
    }
  }
}

function checkNotificationType(
  Menu,
  data,
  status,
  baseURL,
  setLoading,
  props,
  formData,
  dispatch
) {
  if (
    data["notification_type"] == "Budget Underspending" ||
    data["notification_type"] == "Budget Overspending" ||
    data["notification_type"] == "Spend Capacity Alert"
  ) {
    return appendMenu(
      Menu,
      "fixIt",
      baseURL,
      data,
      setLoading,
      props,
      formData,
      dispatch
    );
  } else if (
    data["notification_type"] == "Up for Renewal" ||
    data["notification_type"] == "SEMrush Keyword Research Data Ready"
  ) {
    return appendMenu(
      Menu,
      "unsubscribeAndIgnoreOnceRemove",
      baseURL,
      data,
      setLoading
    );
  } else if (data["is_mandatory"] == 1) {
    return appendMenu(
      Menu,
      "unsubscribeRemove",
      baseURL,
      data,
      setLoading,
      props,
      formData,
      dispatch
    );
  } else {
    if (status == "open") {
      return appendMenu(
        Menu,
        "all",
        baseURL,
        data,
        setLoading,
        props,
        formData,
        dispatch
      );
    } else if (status == "undo") {
      return appendMenu(
        Menu,
        "undo",
        baseURL,
        data,
        setLoading,
        props,
        formData,
        dispatch
      );
    }
  }
}

const saveLog = (
  baseURL,
  actionId,
  type,
  notification_id,
  account_id,
  setLoading,
  props,
  formData,
  dispatch,
  notificationData
) => {
  saveNotificationLog(
    baseURL,
    actionId,
    type,
    notification_id,
    account_id,
    setLoading,
    props,
    formData,
    dispatch
  );
  if (JSON.parse(notificationData)) {
    const promises = [];
    var notificationDatas = JSON.parse(notificationData)["data"];
    var accountName = notificationDatas[0]["account_name"];
    for (let i = 0; i < notificationDatas.length; i++) {
      (function (index) {
        var serviceId = notificationDatas[i].service_id;
        var serviceName = notificationDatas[i].service;
        var budget_target_goal = notificationDatas[i].projected_spend;
        var marketplace_id = notificationDatas[i].marketplace_id;
        var promise = dispatch(
          getServiceSettings({
            account_id: account_id,
            service_id: serviceId,
            setting_month: moment().format("YYYY-MM"),
            marketplace_id: serviceId == 5 ? marketplace_id : null,
          })
        );
        promises.push(promise);

        promise
          .then((response) => {
            var payload_settings = response.payload.setting;
            var default_settings = response.payload.default_setting;
            var service_info = response.payload.service_info;
            if (payload_settings) {
              var json1 = JSON.parse(payload_settings);
            } else {
              var json1 = JSON.parse(default_settings);
            }
            json1.budget_target_goal = {
              value: budget_target_goal.toString(),
              caption: "Budget Target Goal",
            };
            var serviceSettingsParams = {};
            for (var key in json1) {
              serviceSettingsParams[key] = json1[key].value;
            }

            var service_start_date = service_info?.service_start_date
              ? moment(service_info?.service_start_date).format("YYYY-MM-DD")
              : null;
            var service_end_date = service_info?.service_end_date
              ? moment(service_info?.service_end_date).format("YYYY-MM-DD")
              : null;
            var billing_reset_date = service_info?.billing_reset_date
              ? moment(service_info?.billing_reset_date).format("YYYY-MM-DD")
              : null;
            var original_service_start_date =
              service_info?.orginal_service_start_date
                ? moment(service_info?.orginal_service_start_date).format(
                    "YYYY-MM-DD"
                  )
                : null;
            serviceSettingsParams["account_id"] = account_id;
            serviceSettingsParams["service_id"] = serviceId;
            serviceSettingsParams["setting_month"] = moment().format("YYYY-MM");
            serviceSettingsParams["service_start_date"] = service_start_date;
            serviceSettingsParams["service_end_date"] = service_end_date;
            serviceSettingsParams["billing_reset_date"] = billing_reset_date;
            serviceSettingsParams["original_service_start_date"] =
              original_service_start_date;
            serviceSettingsParams["marketplace_id"] = serviceId == 5 ? marketplace_id : null

            var json_stringify = JSON.stringify(serviceSettingsParams);
            dispatch(saveTargetGoal(json_stringify));
          })
          .catch((error) => {
            console.error("Error occurred while dispatching API calls:", error);
          });
      })(i); // Pass i to the IIFE
    }
  }
};

function appendMenu(
  Menu,
  status,
  baseURL,
  record,
  setLoading,
  props,
  formData,
  dispatch
) {
  if (
    record["notification_type"] == "Spend Capacity Alert" &&
    JSON.parse(record["notification_data"])
  ) {
    var notificationDatas = JSON.parse(record["notification_data"])["data"];
    var accountName = notificationDatas[0]["account_name"];
    let serviceObj = [];
    let budgetObj = [];
    for (let i = 0; i < notificationDatas.length; i++) {
      var serviceName = notificationDatas[i].service;
      var budget_target_goal = notificationDatas[i].projected_spend;
      serviceObj.push(serviceName);
      budgetObj.push(budget_target_goal);
    }
    if (serviceObj.length == 1) {
      var confirmText =
        "Are you sure you want to update maximum budgets for " +
        accountName +
        " to the following:" +
        "-  " +
        serviceObj[0] +
        " to $" +
        numberWithCommas(budgetObj[0]);
    } else if (serviceObj.length == 2 && budgetObj.length == 2) {
      var confirmText =
        "Are you sure you want to update maximum budgets for " +
        accountName +
        " to the following: <br>" +
        "-  " +
        serviceObj[0] +
        " to $" +
        numberWithCommas(budgetObj[0]) +
        "<br>" +
        "-  " +
        serviceObj[1] +
        " to $" +
        numberWithCommas(budgetObj[1]) +
        "";
    } else {
      var confirmText =
        "Are you sure you want to update maximum budgets for " +
        accountName +
        " to the following:  <br>" +
        "-  " +
        serviceObj[0] +
        " to $" +
        numberWithCommas(budgetObj[0]) +
        "<br>" +
        "-  " +
        serviceObj[1] +
        " to $" +
        numberWithCommas(budgetObj[1]) +
        "<br>" +
        "-  " +
        serviceObj[2] +
        " to $" +
        numberWithCommas(budgetObj[2]) +
        "";
    }
  }

  if (status == "undo") {
    return (
      <>
        <Menu>
          <Menu.Item key="6">
            <a
              onClick={() => {
                saveNotificationLog(
                  baseURL,
                  6,
                  "Insights Workflow",
                  record["notification_id"],
                  record["account_id"],
                  setLoading,
                  props,
                  formData,
                  dispatch
                );
              }}
            >
              Undo/Reopen
            </a>
          </Menu.Item>
        </Menu>
      </>
    );
  } else if (status == "all") {
    return (
      <Menu>
        <Menu.Item key="2">
          <a
            onClick={() => {
              saveNotificationLog(
                baseURL,
                2,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch
              );
            }}
          >
            Done
          </a>
        </Menu.Item>
        <Menu.Item key="4">
          <a
            onClick={() => {
              saveNotificationLog(
                baseURL,
                4,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch
              );
            }}
          >
            Ignore Once
          </a>
        </Menu.Item>
        <Menu.Item key="5">
          <a
            onClick={() => {
              saveNotificationLog(
                baseURL,
                5,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch
              );
            }}
          >
            Unsubscribe
          </a>
        </Menu.Item>
      </Menu>
    );
  } else if (status == "unsubscribeRemove") {
    return (
      <Menu>
        <Menu.Item key="2">
          <a
            onClick={() => {
              saveNotificationLog(
                baseURL,
                2,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch
              );
            }}
          >
            Done
          </a>
        </Menu.Item>
        <Menu.Item key="4">
          <a
            onClick={() => {
              saveNotificationLog(
                baseURL,
                4,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch
              );
            }}
          >
            Ignore Once
          </a>
        </Menu.Item>
      </Menu>
    );
  } else if (status == "unsubscribeAndIgnoreOnceRemove") {
    return (
      <Menu>
        <Menu.Item key="2">
          <a
            onClick={() => {
              saveNotificationLog(
                baseURL,
                2,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch
              );
            }}
          >
            Done
          </a>
        </Menu.Item>
      </Menu>
    );
  } else if (status == "fixIt") {
    return (
      <Menu>
        <Menu.Item key="2">
          <a
            onClick={() => {
              saveNotificationLog(
                baseURL,
                2,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch
              );
            }}
          >
            Done
          </a>
        </Menu.Item>
        <Menu.Item key="4">
          <a
            onClick={() => {
              saveNotificationLog(
                baseURL,
                4,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch
              );
            }}
          >
            Ignore Once
          </a>
        </Menu.Item>
        <Menu.Item key="1">
          <Popconfirm
            title={confirmText}
            open={true}
            description={confirmText}
            onConfirm={() =>
              saveLog(
                baseURL,
                3,
                "Insights Workflow",
                record["notification_id"],
                record["account_id"],
                setLoading,
                props,
                formData,
                dispatch,
                record["notification_data"]
              )
            }
            onCancel={"cancel"}
            okText="Yes"
            cancelText="No"
          >
            <a
              onClick={() => {
                // saveNotificationLog(
                //   baseURL,
                //   3,
                //   "Insights Workflow",
                //   record["notification_id"],
                //   record["account_id"],
                //   setLoading,
                //   props,
                //   formData,
                //   dispatch
                // );
              }}
            >
              Fix it for me
            </a>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  }
}

const saveNotificationLog = async (
  baseURL,
  actionId,
  report_name,
  notification_id,
  account_id,
  setLoading,
  props,
  formData,
  dispatch
) => {
  try {
    const bodyData = {
      action_id: actionId,
      report_name: report_name,
      notification_id: notification_id,
      account_id: account_id,
    };

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    };
    setLoading(true);
    const response = await fetch(
      baseURL + "api/v1/save-notification-log",
      requestOptions
    );
    const result = await response.json();
  } catch (err) {
    // Handle any errors
  } finally {
    setLoading(false);
    dispatch(getDataTable(formData, props));
  }
};

export default InsightWorkflowNotification;
