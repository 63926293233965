import { amazonRegion } from "../../../Config/datasources/amazon/region";
import * as amazonMarketingServiceDataSourceThunk from "../../../Redux/Actions/Thunk/datasources/amazonMarketingServiceThunk";
import errorHandler from "../../../Utils/Request/errorHandler";

const dispatchAmazonMarketingServiceDataSource = (dispatch, payload) => {
  let marketplaces;
  const { dataSourceMetaData, starTableData, dataSourceDataFromThirdParty } =
    payload;
  let inputDataFromUser = JSON.parse(payload.dataSourceInputDataByClientInForm);
  let brandIdsFromUserInput;
  let listOfSelleridInSelectedMarketPlace;
  // Basically , we are dynamically setting the market place name from the region
  if (amazonRegion[dataSourceMetaData.dataSourceRegion]) {
    brandIdsFromUserInput = inputDataFromUser["brand_entity_id"]
      .split(",")
      .filter((el) => el);
    marketplaces = inputDataFromUser["marketplaces"];
  }

  // * getting the data from the brandEntityId dynamically .
  let marketPlaceData = brandIdsFromUserInput.map((brandEntityId) => {
    return marketplaces.map((el) => {
      // *We are using find as marketplaceId is unique .
      const marketplace = dataSourceDataFromThirdParty.profile.find((data) => {
        return el === data.accountInfo.marketplaceStringId;
      });

      // THIS IS TO LIST OUT ALL THE SELLER ID FROM THE SELECTED MARKET PLACE
      listOfSelleridInSelectedMarketPlace = marketplaces
        .map((el) => {
          return dataSourceDataFromThirdParty.profile.map((data) => {
            if (
              el === data.accountInfo.marketplaceStringId &&
              data.accountInfo.id.startsWith("ENTITY")
            ) {
              return data.accountInfo.id;
            }
          });
        })
        .flat(2)
        .filter((el) => {
          return el;
        });

      const validBrandEntityID = dataSourceDataFromThirdParty.profile.filter(
        (data) => {
          return brandEntityId === data.accountInfo.id;
        }
      );

      return validBrandEntityID.map((validbrandEntityId) => {
        if (
          validbrandEntityId &&
          validbrandEntityId.accountInfo.marketplaceStringId === el
        ) {
          return {
            api_host: dataSourceMetaData?.apiHost,
            client_name: marketplace?.accountInfo?.name,
            market_place_id: marketplace?.accountInfo?.marketplaceStringId,
            profile_id: marketplace?.profileId.toString(),
            // Note: i am not getting brand entity id from third party
            brand_entity_id: validbrandEntityId?.accountInfo?.id,
          };
        }
      });
    });
  });
  marketPlaceData = marketPlaceData.flat(2).filter((el) => el);
  // if currentMarketPlace is found then we have to create the data source for different market place .
  let properPayload = {
    client_auth_params: {
      refresh_token: dataSourceDataFromThirdParty.tokens?.refresh_token,
      client_email: inputDataFromUser["client_email"],
      // status: inputDataFromUser[
      //   `status${region_suffix[TruedataSourceMetaData.dataSourceRegion]}` ? 1 : 0
      // ]
    },
    star_table_data: starTableData,
    client_info: marketPlaceData,
  };

  // properPayload.invalidMarketPlace = inValidMarketPlaces;
  if (!marketPlaceData.length) {
    let message;
    if (!listOfSelleridInSelectedMarketPlace.length) {
      message = `No seller id found in the selected market place`;
    } else {
      const arrayLists = listOfSelleridInSelectedMarketPlace.join(",");
      message = `Please enter valid seller id from the list ${arrayLists}`;
    }
    const error = {
      response: {
        status: 400,
        data: {
          message,
        },
      },
    };
    errorHandler(error, false);
  } else {
    dispatch(
      amazonMarketingServiceDataSourceThunk.createAmazonMarketingServiceDataSource(
        properPayload,
        dataSourceMetaData.dataSourceRegion
      )
    );
  }
};

export default dispatchAmazonMarketingServiceDataSource;
