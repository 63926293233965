import { ACTIONS_TYPES } from "../Reducers/userReducer";

export function getUser(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_USER,
    payload,
  };
}

export function getAllRoles(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ROLES,
    payload,
  };
}

export function getUserDetail(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_USER_DETAIL,
    payload,
  };
}

export function saveNotificationLog(payload) {
  return {
    type: ACTIONS_TYPES.SAVE_NOTIFICATION_LOG,
    payload,
  };
}
