import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInternalReports } from "../Redux/Actions/Thunk/InternalReportThunk";
import { Input, Table, Row, Col, Select, Tooltip } from "antd";
import { FaEye } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { getServices } from "../Redux/Actions/Thunk/accountThunk";

const { Search } = Input;
const { Option } = Select;

const InternalReports = () => {
  const [hasSearched, setHasSearched] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [searchedReportList, setSearchedReportList] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const {
    internalReports: {
      internalReports: { data: InternalData = [], total } = {},
      isLoading,
    },
    accounts: { services: { data: services_data = [] } = {} },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInternalReports());
    dispatch(getServices());
  }, []);

  useEffect(() => {
    setDataSource(InternalData);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: total,
      },
    });
  }, [InternalData, total]);

  useEffect(() => {
    setSearchedReportList(dataSource);
  }, [dataSource]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "1",
      render: (text, record) => {
        return (
          <Link
            // onClick={() => window.location.reload()}
            to={{
              pathname: `/reports/v2/view/${record.report_key}`,
              key: Math.random(),
              myCustomProps: record,
            }}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "View",
      dataIndex: "view",
      key: "2",
    },
    {
      title: "Category",
      dataIndex: "report_service_name",
      key: "3",
    },
    {
      title: "Action",
      key: "4",
      render: (text, record) => {
        return (
          <>
            <div className="panel-action-button">
              <Tooltip title="View Report">
                <Link
                  to={{
                    pathname: `/reports/v2/view/${record.report_key}`,
                    myCustomProps: record,
                  }}
                >
                  <FaEye />
                </Link>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    const limit = pagination.pageSize;
    let params = `page=${pagination?.current}`;
    setTableParams({
      pagination,
    });

    if (sorter.hasOwnProperty("column")) {
      const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
      dispatch(
        getInternalReports(
          `&sort_column=${sorter.field}&sort_order=${sortOrder}`
        )
      );
    }
    dispatch(getInternalReports(`${params}&limit=${limit}`));
  };

  const searchHandler = (enteredValue) => {
    dispatch(getInternalReports(`search=${enteredValue}`));
    setSearchedReportList(InternalData);
    setHasSearched(true);
  };

  const serviceChangeHandler = (selectedValue) => {
    dispatch(getInternalReports(`service_ids=${selectedValue}`));
    setSearchedReportList(InternalData);
    setHasSearched(true);
  };

  return (
    <>
      <div className="panel-heading">
        <Row>
          <Col span={24} className="panel-heading-title">
            <h2>Internal Reports</h2>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <Row className="panel-body-input">
          <Col span={16} className="panel-body-input-left">
            <div className="panel-body-select">
              <Select
                name="service_id"
                style={{ width: 300 }}
                showSearch
                mode="single"
                maxTagCount={1}
                placeholder="All Reports"
                optionFilterProp="children"
                onChange={serviceChangeHandler}
                defaultValue="All Reports"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value="0" id="0" name="All Reports">
                  All Reports
                </Option>
                {services_data?.map((service) => (
                  <Option
                    value={service.service_id}
                    id={service.service_id}
                    name={service.service_id}
                  >
                    {service.service_name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="panel-body-select">
              <Search
                placeholder="Report Name"
                allowClear
                //onSearch={searchHandler}
                onChange={(e) => searchHandler(e.target.value)}
                style={{ width: 250 }}
              />
            </div>
          </Col>
        </Row>
        <div className="panel-body-content">
          {hasSearched ? (
            <Table
              columns={columns}
              key={(record) => record.primaryKey}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={searchedReportList}
              onChange={handleChange}
              loading={isLoading}
              pagination={tableParams.pagination}
            />
          ) : (
            <h2 className="text-center no-result">Nothing to display....</h2>
          )}
        </div>
      </div>
    </>
  );
};

export default InternalReports;
