export const initialState = {
  facebook_data: {},
  tiktok_data: {}
};

export const ACTIONS_TYPES = Object.freeze({
  GET_FACEBOOK_API: "GET_FACEBOOK_API_REQUEST",
  FETCH_FACEBOOK_API: "FETCH_FACEBOOK_API_REQUEST",
  GET_TIKTOK_API: "GET_TIKTOK_API_REQUEST",
  FETCH_TIKTOK_API: "FETCH_TIKTOK_API_REQUEST"
});

export function FacebookTikTokReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.GET_FACEBOOK_API:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_FACEBOOK_API:
      return {
        ...state,
        isLoading: false,
        facebook_data: action.payload,
      };
    case ACTIONS_TYPES.GET_TIKTOK_API:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_TIKTOK_API:
      return {
        ...state,
        isLoading: false,
        tiktok_data: action.payload,
      };
    default:
      return state;
  }
}
