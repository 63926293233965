export const LANDING_PAGE = "LANDING_PAGE";
export const FETCH_LANDING_PAGE = "FETCH_LANDING_PAGE";
export const TOTAL_LANDING_PAGE = "TOTAL_LANDING_PAGE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const USER_REQUEST = "USER_REQUEST";
export const FETCH_USER = "FETCH_USER";
export const FETCH_ROLE = "FETCH_ROLE";
export const GET_ACTIVE_REPORTS = "GET_ACTIVE_REPORTS";
export const TOGGLE_ROLE_STATUS = "TOGGLE_ROLE_STATUS";
export const CHANGE_ROLE_TYPE = "CHANGE_ROLE_TYPE";
export const UPDATE_ROLE_STATUS = "UPDATE_ROLE_STATUS";
export const AFTER_UPDATE_ROLE_STATUS = "AFTER_UPDATE_ROLE_STATUS";
export const LOGOUT = "LOGOUT";
export const ALL_TOTAL = "ALL_TOTAL";
export const FETCH_SOURCE_MEDIUM = "FETCH_SOURCE_MEDIUM";
export const REQUEST_SOURCE_MEDIUM = "REQUEST_SOURCE_MEDIUM";
export const FETCH_PAGE_TYPE = "FETCH_PAGE_TYPE";
export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_ROLE_DETAILS = "FETCH_ROLE_DETAILS";
export const DELETE_ROLE = "DELETE_ROLE";
export const ACCOUNT_REQUEST = "ACCOUNT_REQUEST";
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const REQUEST_DATA = "REQUEST_DATA";
export const FETCH_TRENDS = "FETCH_TRENDS";
export const FETCH_KEY_METRICS = "FETCH_KEY_METRICS";
export const FETCH_LABELS = "FETCH_LABELS";
export const FETCH_IMS_USERS = "FETCH_IMS_USERS";
export const FETCH_SPECIALIST_USERS = "FETCH_SPECIALIST_USERS";
export const FETCH_SERVICES = "FETCH_SERVICES";
export const FETCH_GRAPH = "FECTH_GRAPH";
export const FETCH_SESSION_DATA = "FETCH_SESSION_DATA";
export const FETCH_CONVERSIONS_DATA = "FETCH_CONVERSIONS_DATA";
export const FETCH_REVENUE_DATA = "FETCH_REVENUE_DATA";
export const MFA_SET = "MFA_SET";
export const MFA_SET_FALSE = "MFA_SET_FALSE";
export const MFA_CHECK = "MFA_CHECK";
export const IMPERSONATE = "IMPERSONATE";
export const STOP_IMPERSONATE = "STOP_IMPERSONATE";
export const SET_API_RESPONSE = "SET_API_RESPONSE";
export const DEFAULT_DASHBOARD_LIST = "DEFAULT_DASHBOARD_LIST";
export const SET_DEFAULT_DASHBOARD = "SET_DEFAULT_DASHBOARD";
export const SET_REPORT_STATUS = "SET_REPORT_STATUS";
export const UPDATE_SALESFORCE_PERMISSION = "UPDATE_SALESFORCE_PERMISSION";
export const GET_ACTIVE_USERS = "GET_ACTIVE_USERS";
export const ADD_USERS_ROLE = "ADD_USERS_ROLE"
export const ROLE_REQUEST = "ROLE_REQUEST";
export const FETCH_PERFORMACE_METRICS = "FETCH_PERFORMACE_METRICS";
export const SAVE_NOTIFICATION_LOG = "SAVE_NOTIFICATION_LOG"
