import API_BI from "../../Utils/axios_bi";
import { DataSourcesApiEndPoints } from "../../Utils/constant";
const {
  googleAds: {
    getGoogleAdsParam,
    getGoogleAdsClient,
    getGoogleAdsProfile,
    postGoogleAdsClient,
    getAllGoogleAdsParam,
    postGoogleAdsParam,
    postGoogleAdsDateRange,
  },
} = DataSourcesApiEndPoints;

/**
 * API function to get Google Ads Auth data
 */
export const getAllGoogleAdsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(getAllGoogleAdsParam);
};

/**
 * API function to get Google Ads Auth data
 */
export const getGoogleAdsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getGoogleAdsParam, props);
};

/**
 * API function to create Google Ads Auth data
 * @param {object} props - The data to be posted
 * @returns {Promise<response>}
 */
export const createGoogleAdsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  // API_BI.defaults.headers.common["Content-Type"] = 'application/x-www-form-urlencoded'

  return await API_BI.post(postGoogleAdsParam, props, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * API function to update Google Ads Auth data
 * @param {object} props - The data to be updated
 * @returns {Promise<response>}
 */
export const updateGoogleAdsParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(postGoogleAdsParam, props, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * API function to get Google Ads client data
 */
export const getGoogleAdsClientApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getGoogleAdsClient, props);
};

/**
 * API function to create Google Ads client data
 * @param {object} props - The data to be posted
 * @returns {Promise<response>}
 */
export const createGoogleAdsClientApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(postGoogleAdsClient, props);
};

/**
 * API function to update Google Ads Auth data
 * @param {object} props - The data to be updated
 * @returns {Promise<response>}
 */
export const updateGoogleAdsClientApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(postGoogleAdsClient, props);
};

/**
 * API function to update Google Ads date range
 * @param {object} props - The data to be updated
 * @returns {Promise<response>}
 */
export const getGoogleAdsDsRangeApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(postGoogleAdsClient, props);
};

/**
 * API function to update Google Ads date range data
 * @param {object} props - The data to be updated
 * @returns {Promise<response>}
 */
export const postGoogleAdsDsRangeApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(postGoogleAdsDateRange, props);
};

export const hitGoogleAdsToEc2 = async (props) => {
  // let validUrl = `${getGoogleAdsProfile}/${type}`
  // dotenv.googleAdsEc2Url;
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getGoogleAdsProfile, props);
};
