export const initialState = {
  landingPage: [],
  isLoading: false,
  totalLandingPage: [],
  allTotal: [],
  source_meduim: [],
  page_type: [],
};

export const ACTIONS_TYPES = Object.freeze({
  GET_LANDING_PAGE: "GET_LANDING_PAGE",
  TOTAL_LANDING_PAGE: "TOTAL_LANDING_PAGE",
  FETCH_LANDING_PAGE: "FETCH_LANDING_PAGE",
  ALL_TOTAL: "ALL_TOTAL",
  FETCH_SOURCE_MEDUIM: "FETCH_SOURCE_MEDUIM",
  REQUEST_SOURCE_MEDUIM: "REQUEST_SOURCE_MEDUIM",
  FETCH_PAGE_TYPE: "FETCH_PAGE_TYPE",
});

export function lppReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.FETCH_LANDING_PAGE:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.GET_LANDING_PAGE:
      return {
        ...state,
        isLoading: false,
        landingPage: action.payload,
      };
    case ACTIONS_TYPES.TOTAL_LANDING_PAGE:
      return {
        ...state,
        isLoading: true,
        totalLandingPage: action.payload,
      };
    case ACTIONS_TYPES.ALL_TOTAL:
      return {
        ...state,
        isLoading: true,
        allTotal: action.payload,
      };
    case ACTIONS_TYPES.REQUEST_SOURCE_MEDUIM:
      return {
        ...state,
        isLoading: true,
        source_meduim: action.payload,
      };
    case ACTIONS_TYPES.FETCH_SOURCE_MEDUIM:
      return {
        ...state,
        isLoading: false,
        source_meduim: action.payload,
      };
    case ACTIONS_TYPES.FETCH_PAGE_TYPE:
      return {
        ...state,
        isLoading: false,
        page_type: action.payload,
      };
    default:
      return state;
  }
}
