import { ACTIONS_TYPES } from "../Reducers/facebookTikTokReducer";

export function fetchFacebookData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_FACEBOOK_API,
    payload,
  };
}

export function fetchTikTokData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_TIKTOK_API,
    payload,
  };
}

