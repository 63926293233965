import React, { useEffect, useState } from "react";
import { withRouter,  Route,
  Switch, useLocation } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import LoginNotRequired from "./Routes/LoginNotRequired";
import Dashboard from "./Pages/Layout/Dashboard";
import "./index.css";
import { isLoggedIn } from "./Utils";
import FreemiumLayout from "./Components/ReportLayouts/FreemiumLayout";
import AgitalFreemiumLayout from "./Components/ReportLayouts/AgitalFreemiumLayout";
import PublicRoute from "./Routes/PublicRoute";
import GadsRedirect from "./Pages/Layout/GadsRedirect";
import GadsSignup from "./Pages/Layout/GadsSignup";
import InsightNotification from "./Pages/InsightNotification";
import ProtectedRoute from "./Routes/ProtectedRoute";

function App() {

  const pathArray = window?.location?.pathname?.split("/");
  const excludedReports = ['organic_search_scorecard_v2', 'google_ads_scorecard'];
  const publicRoutes = [
    '/api/v2/freemium/google-redirect', 
    '/freemium/adwords-signup',
    '/save-email-notification-log'
  ];
  const location = useLocation();

  return <>{
    !isLoggedIn() && excludedReports.includes(pathArray[4]) 
      ? 
      <Switch>
        <PublicRoute exact
          path="/freemium-reports/v2/view/:report_key/:account_id"
          component={FreemiumLayout}
          />
        <PublicRoute
					exact
					path="/freemium-agital-reports/v2/view/:report_key/:account_id"
					component={AgitalFreemiumLayout}
				/>
      </Switch>
      : (isLoggedIn() ? 
        (publicRoutes.includes(location.pathname) ?
          <Switch location={location} key={location.pathname}>
            <Route
              exact
              path="/freemium/adwords-signup"
              component={GadsSignup}
            />
            <Route
              exact
              path="/api/v2/freemium/google-redirect"
              component={GadsRedirect}
            />
            <ProtectedRoute
              exact
              path="/save-email-notification-log"
              component={InsightNotification}
            />
          </Switch> :
          <Dashboard></Dashboard>)
        :<LoginNotRequired></LoginNotRequired>)
    }
  </>;
}

export default withRouter(App); 
