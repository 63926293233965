import { useState, useRef } from "react";
import { DatePicker, Select, Switch, Form } from "antd";
import moment, { months } from "moment";
import { capitalizeFirstLetter } from "../../Utils/helpers";
import React, { useEffect } from "react";

const { Option } = Select;

const InputDatePicker = (props) => {
  const buttonRef = useRef(null);
  const selectRef = useRef(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [showAdditionalRanges, setShowAdditionalRanges] = useState(true);
  const [rangeVal, setRangeVal] = useState("");
  const pickerType = props?.block?.mode_type;
  const presetRanges = props?.block?.presetRanges;
  const additionalPresetRanges = props?.block?.additionalPresetRanges;

  const dateFormat = props?.block?.format;
  const hideToggleSwitch = props?.block?.hideToggleSwitch;
  const month_range = props?.block?.month_range;
  const compare_with = props?.block?.compare_with;
  const hideText = props?.block?.hideText;
  const showAdditionalOptions = props?.block?.showAdditionalOptions;
  const dateType = props?.block?.component_type;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());
  const component_type = props?.block?.component_type;

  const storedArray = localStorage.getItem("datePersistVal");
  const datePersistVal = JSON.parse(storedArray);

  var defaultRangeOption =
    props?.block?.default_date_range_option != undefined
      ? props?.block?.default_date_range_option
      : "Last Month";
  var [defaultCRangeOption, setDefaultCRangeOption] = useState(
    props?.block?.default_compare_date_range_option != undefined
      ? props?.block?.default_compare_date_range_option
      : "Same Period Last Year"
  );

  var presetRangesList = {};

  if (presetRanges == undefined) {
    var presetRangesList = {
      Today: moment(),
      "Last 7 Days": moment().subtract(7, "days"),
      "Last 30 Days": moment().subtract(30, "days"),
      "This Month": moment().startOf("month"),
      "Last Month": moment().subtract(1, "month").startOf("month"),
    };

    var additionalPresetRangesList = [
      {
        label: "Previous Period",
        value: moment(),
      },
      {
        label: "Same Period Last Year",
        value: moment().subtract(1, "month").startOf("month"),
      },
      {
        label: "Previous Month",
        value: moment().subtract(1, "month"),
      },
    ];
  }

  if (pickerType == "month") {
    var customFormat = "MMM, YYYY";
  } else {
    var customFormat = "MMM DD, YYYY";
  }

  if (presetRanges != undefined && presetRanges.includes("Yesterday")) {
    presetRangesList.yesterday = moment().subtract(1, "day");
  }

  if (presetRanges != undefined && presetRanges.includes("Today")) {
    presetRangesList.today = moment();
  }

  if (presetRanges != undefined && presetRanges.includes("Last 7 Days")) {
    presetRangesList.last_7_days = moment().subtract(7, "days");
  }

  if (presetRanges != undefined && presetRanges.includes("This Month")) {
    presetRangesList.this_month = moment().startOf("month");
  }

  if (presetRanges != undefined && presetRanges.includes("Last Month")) {
    presetRangesList.last_month = moment()
      .subtract(1, "month")
      .startOf("month");
  }

  if (presetRanges != undefined && presetRanges.includes("Last 30 Days")) {
    presetRangesList.last_30_days = moment().subtract(30, "days");
  }

  if (presetRanges != undefined) {
    var defaultDateRange = presetRangesList[defaultRangeOption];
  } else {
    var defaultDateRange = moment().subtract(1, "month").startOf("month");
  }

  if (
    Object.keys(params_data) != 0 &&
    params_data.hasOwnProperty("date_range_option")
  ) {
    defaultRangeOption = params_data["date_range_option"]
      .toLowerCase()
      .split(" ")
      .join("_");
  }

  if (
    Object.keys(params_data) != 0 &&
    params_data.hasOwnProperty("date_range_compareto")
  ) {
    defaultCRangeOption = params_data["date_range_compareto"];
  }

  if (
    datePersistVal != null &&
    datePersistVal.hasOwnProperty("persist_label") &&
    presetRanges.includes(datePersistVal["date_range_option"]) &&
    component_type == datePersistVal["component_type"] &&
    dateFormat === datePersistVal["dateFormat"]
  ) {
    defaultRangeOption = datePersistVal["persist_label"];
  }

  if (
    datePersistVal != null &&
    additionalPresetRanges.includes(
      datePersistVal["additional_persist_label"]
    ) &&
    component_type == datePersistVal["component_type"] &&
    dateFormat === datePersistVal["dateFormat"]
  ) {
    defaultCRangeOption = datePersistVal["additional_persist_label"];
  }

  if(rangeVal != ""){
    defaultCRangeOption = rangeVal;
  }

  const [dateRange, setDateRange] = useState(defaultDateRange);

  var additionalPresetRangesList = [];
  if (
    additionalPresetRanges != undefined &&
    additionalPresetRanges.includes("Same Period Last Year")
  ) {
    var same_period_obj = {
      label: "Same Period Last Year",
      value: moment(dateRange).subtract(1, "year"),
    };

    additionalPresetRangesList.push(same_period_obj);
  }

  if (
    additionalPresetRanges != undefined &&
    additionalPresetRanges.includes("Same 30 Days Last Year")
  ) {
    var same_30_obj = {
      label: "Same 30 Days Last Year",
      value: moment(dateRange).subtract(1, "year"),
    };
    additionalPresetRangesList.push(same_30_obj);
  }

  if (
    additionalPresetRanges != undefined &&
    additionalPresetRanges.includes("Previous Month")
  ) {
    var previous_month = {
      label: "Previous Month",
      value: moment(dateRange).subtract(1, "month").startOf("month"),
    };
    additionalPresetRangesList.push(previous_month);
  }

  const [dateSRange, setSDateRange] = useState(
    moment(dateRange).subtract("1", "year")
  );

  const selectedVal = useRef(capitalizeFirstLetter(defaultRangeOption));
  const selectedAdditionalVal = useRef(defaultCRangeOption);

  useEffect(() => {
    if (Object.keys(params_data) != 0) {
      if (params_data.hasOwnProperty("f_date")) {
        setDateRange(moment(params_data["f_date"], "YYYY-MM-DD"));
        setSDateRange(moment(params_data["t_date"], "YYYY-MM-DD"));
      } else if (params_data.hasOwnProperty("f_month")) {
        setDateRange(moment(params_data["f_month"], dateFormat));
        setSDateRange(moment(params_data["t_month"], dateFormat));
      }
    } else if (datePersistVal != null) {
      if (
        datePersistVal.hasOwnProperty("date_range_option") &&
        presetRanges.includes(datePersistVal["date_range_option"]) &&
        component_type == datePersistVal["component_type"] &&
        dateFormat === datePersistVal["dateFormat"]
      ) {
        setDateRange(moment(datePersistVal["f_month"]));
      }

      if (
        datePersistVal != null &&
        additionalPresetRanges.includes(
          datePersistVal["additional_persist_label"]
        ) &&
        component_type == datePersistVal["component_type"] &&
        dateFormat === datePersistVal["dateFormat"]
      ) {
        setSDateRange(moment(datePersistVal["t_month"]));
      }
    }
  }, [window.location.search]);

  useEffect(() => {
    if (selectedAdditionalVal.current == "Same Period Last Year") {
      setSDateRange(moment(dateRange).subtract("1", "year"));
      additionalDateValue.current = moment(dateRange).subtract("1", "year");
    } else if (selectedAdditionalVal.current == "Same 30 Days Last Year") {
      setSDateRange(moment(dateRange).subtract("1", "year"));
      additionalDateValue.current = moment(dateRange).subtract("1", "year");
    } else if (selectedAdditionalVal.current == "Previous Month") {
      setSDateRange(moment(dateRange).subtract("1", "month").startOf("month"));
      additionalDateValue.current = moment(dateRange)
        .subtract("1", "month")
        .startOf("month");
    } else {
      // setSDateRange([moment(dateRange[0]).subtract("1", "year"), moment(dateRange[1]).subtract("1", "year")]);
    }
  }, [dateRange, selectedVal]);

  const dateValue = useRef(null);
  const additionalDateValue = useRef(null);

  const handlePresetRangeSelect = (value) => {
    setDateRange(presetRangesList[value]);
    dateValue.current = presetRangesList[value];

    selectedVal.current = value;
  };
  const handleCalendarOpenChange = (open) => {
    if (!open) {
      setCalendarOpen(open);
    }
  };

  const handleToggleCalendar = () => {
    setCalendarOpen(!calendarOpen);

    // dateValue.current =
    // selectedVal.current = "Custom";
  };
  useEffect(() => {
    if (calendarOpen) {
      const buttonPosition = buttonRef.current.getBoundingClientRect();
      const dropdown = document.getElementById("mainCalendarDropdown");

      const top = buttonPosition.top + buttonPosition.height;
      const left = buttonPosition.left;

      dropdown.style.position = "absolute";
      dropdown.style.top = `${top}px`;
      dropdown.style.left = `${left}px`;
    }
  }, [calendarOpen]);
  const getPopupContainer = () => {
    const popupContainer = document.createElement("div");
    popupContainer.id = "mainCalendarDropdown";
    document.body.appendChild(popupContainer);
    return popupContainer;
  };

  //COMPARE DATE RANGE PICKER
  const sbuttonRef = useRef(null);
  const [scalendarOpen, setSCalendarOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const handleSCalendarOpenChange = (open) => {
    if (!open) {
      setSCalendarOpen(open);
    }
  };

  const handleSToggleCalendar = () => {
    setSCalendarOpen(!scalendarOpen);
  };
  useEffect(() => {
    if (scalendarOpen) {
      const sbuttonPosition = sbuttonRef.current.getBoundingClientRect();
      const cdropdown = document.getElementById("compCalendarDropdown");

      const stop = sbuttonPosition.top + sbuttonPosition.height;
      const sleft = sbuttonPosition.left;

      cdropdown.style.position = "absolute";
      cdropdown.style.top = `${stop}px`;
      cdropdown.style.left = `${sleft}px`;
    }
  }, [scalendarOpen]);
  const handleAdditionalRangesSwitchChange = (checked) => {
    setShowAdditionalRanges(checked);
  };

  const getSPopupContainer = () => {
    const popupSContainer = document.createElement("div");
    popupSContainer.id = "compCalendarDropdown";
    document.body.appendChild(popupSContainer);
    return popupSContainer;
  };

  const setAdditionalDateRange = (range, index) => {
    selectRef.current.blur();
    setActiveIndex(index);
    setSDateRange(range.value);
    selectedAdditionalVal.current = range.label;
    additionalDateValue.current = range.value;
    setRangeVal(range.label);
  };

  const handleAdditionalRange = (value) => {
    additionalDateValue.current = value;
    selectedAdditionalVal.current = "Custom";
  };

  const handleDateChange = (value) => {
    setDateRange(value);
    dateValue.current = value;
    selectedVal.current = "Custom";
  };

  const [form] = Form.useForm();
  props.form.setFieldsValue({
    ["dateRange"]: dateRange,
    ["selectedVal"]: selectedVal,
    ["selectedAdditionalVal"]: selectedAdditionalVal,
    ["dateSRange"]: dateSRange,
    ["dateFormat"]: dateFormat,
    ["dateType"]: "",
  });

  return (
    <div className="Report-V2-filter-box Report-V2-filter-box-date ">
      <div className="Report-V2-filter-option-title">
        <span>{props?.block?.label}</span>
      </div>
      <div className="Report-V2-filter-box-date-wrapper">
        <div className="main-daterange">
          <Form.Item name="dateRange"></Form.Item>
          <Form.Item name="selectedVal"></Form.Item>
          <Form.Item name="dateFormat"></Form.Item>
          <Form.Item name="dateType"></Form.Item>
          <DatePicker
            defaultValue={dateRange}
            value={dateRange}
            onCalendarChange={(value) => setDateRange(value)}
            onChange={handleDateChange}
            open={calendarOpen}
            onOpenChange={handleCalendarOpenChange}
            format={customFormat}
            allowClear={false}
            inputReadOnly={true}
            suffixIcon={null}
            getPopupContainer={getPopupContainer}
            picker={pickerType}
            dropdownClassName="inputrange-calendar"
          />
        </div>

        {showAdditionalOptions != "false" ? (
          <div className="compare-daterange">
            <Form.Item name="dateSRange"></Form.Item>
            <Form.Item name="selectedAdditionalVal"></Form.Item>
            <DatePicker
              className="compare-daterange"
              defaultValue={dateSRange}
              value={dateSRange}
              onCalendarChange={(value) => setSDateRange(value)}
              onChange={handleAdditionalRange}
              open={scalendarOpen}
              onOpenChange={handleSCalendarOpenChange}
              format={customFormat}
              allowClear={false}
              inputReadOnly={true}
              suffixIcon={null}
              getPopupContainer={getSPopupContainer}
              picker={pickerType}
              dropdownClassName="inputrange-calendar"
            />
          </div>
        ) : (
          ""
        )}

        <Select
          dropdownClassName="input-dropdown-menu"        
          ref={selectRef}
          defaultValue={defaultRangeOption}
          onChange={handlePresetRangeSelect}
          dropdownRender={(menu) => (
            <div className="daterange-picker-addition">
              {menu}
              <button
                ref={buttonRef}
                onClick={handleToggleCalendar}
                className="daterange-custom-button"
              >
                {month_range ? month_range : "Custom"}
              </button>
              {hideToggleSwitch == "true" ? (
                hideText == "true" ? (
                  ""
                ) : (
                  <div className="daterange-compare-toggle">
                    <span>Compared to:</span>
                  </div>
                )
              ) : (
                <div className="daterange-compare-toggle">
                  <Switch
                    size="small"
                    checked={showAdditionalRanges}
                    onChange={handleAdditionalRangesSwitchChange}
                  />
                  <span>Compare</span>
                </div>
              )}
              {showAdditionalRanges && showAdditionalOptions != "false" && (
                <>
                  <div className="additonal-preset-range-list">
                    {additionalPresetRangesList.map((range, index) => (
                      <div
                        className={`additonal-preset-range ${
                          defaultCRangeOption === range.label ||
                          activeIndex === index
                            ? "apr-active"
                            : ""
                        }`}
                        key={range.label}
                        onClick={() => setAdditionalDateRange(range, index)}
                      >
                        {range.label}
                      </div>
                    ))}
                  </div>
                  <button
                    ref={sbuttonRef}
                    onClick={handleSToggleCalendar}
                    className="daterange-custom-button"
                  >
                    {compare_with ? compare_with : "Custom"}
                  </button>
                </>
              )}
            </div>
          )}
        >
          {Object.keys(presetRangesList).map((range) => (
            <Option key={range} value={range}>
              {capitalizeFirstLetter(range.replaceAll("_", " "))}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default InputDatePicker;
