import { DataSourcesApiEndPoints } from "../../../Utils/constant";
import API_BI from "../../../Utils/axios_bi";

const {
  googleAnalyticsMCC: {
    getGoogleAnalyticsMCCUrl,
    getAllGoogleAnalyticsMCCUrl,
    postGoogleAnalyticsMCCUrl,
  },
} = DataSourcesApiEndPoints;

/**
 * API function to get Google Ads Auth data
 */
export const getAllGoogleConsoleParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(getAllGoogleAnalyticsMCCUrl, {
    //content-type is not required for get request
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * API function to get Google Ads Auth data
 */
export const getGoogleConsoleParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getGoogleAnalyticsMCCUrl, props);
};

/**
 * API function to create Google Ads Auth data
 * @param {object} props - The data to be posted
 * @returns {Promise<response>}
 */
export const createGoogleConsoleParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  // API_BI.defaults.headers.common["Content-Type"] = 'application/x-www-form-urlencoded'

  return await API_BI.post(postGoogleAnalyticsMCCUrl, props, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateGoogleConsoleParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(postGoogleAnalyticsMCCUrl, props, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteGoogleConsoleParamApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.delete(postGoogleAnalyticsMCCUrl, {
    data: props,
  });
};
