import React, { useCallback, useEffect, useState } from "react";
import { Select, Button, Spin } from "antd";
import { formDataAppend } from "../../Utils/helpers";
import { useDispatch } from "react-redux";
import { getDataTable } from "../../Redux/Actions/Thunk/dataTableThunk";
import {
  getLineChartData,
  getRevenueRetentionData,
} from "../../Redux/Actions/Thunk/LineChartThunk";
import { getTrendsData } from "../../Redux/Actions/Thunk/TrendThunk";
import { getBarChartData } from "../../Redux/Actions/Thunk/ColumnChartThunk";
import { getScatterData } from "../../Redux/Actions/Thunk/SactterThunk";
import { getAreaChartData } from "../../Redux/Actions/Thunk/AreaChartThunk";
const MMMLift = (mmmVal, props) => {
  const [disabled, setDisabled] = useState(true);
  const [paidSearchVal, setPaidSearchVal] = useState("0");
  const [productAdsVal, setProductAdsVal] = useState("0");
  const [paidSocialVal, setPaidSocialVal] = useState("0");
  const [amazonVal, setAmazonVal] = useState("0");

  const dispatch = useDispatch();
  var data = mmmVal?.props?.all_input_data;
  var include_amazon_data = data?.include_amazon_data;
  var props = mmmVal["props"];

  const getAmazonVal = (val) => {
    setAmazonVal(val);
  };
  const getPaidSearchVal = (val) => {
    setPaidSearchVal(val);
  };
  const getProductAdsVal = (val) => {
    setProductAdsVal(val);
  };
  const getPaidSocialVal = (val) => {
    setPaidSocialVal(val);
  };

  localStorage.setItem("spend_lift_pla", productAdsVal);
  localStorage.setItem("spend_lift_ppc", paidSearchVal);
  localStorage.setItem("spend_lift_psm", paidSocialVal);
  localStorage.setItem("spend_lift_asc", amazonVal);

  const MyContext = React.createContext();

  const updateForeCasts = useCallback(() => {
    data["spend_lift_pla"] = productAdsVal;
    data["spend_lift_ppc"] = paidSearchVal;
    data["spend_lift_psm"] = paidSocialVal;
    data["spend_lift_asc"] = amazonVal;
    data["initial_load"] = 0;
    if (mmmVal["checked"] == true) {
      data["show_optimized"] = 1;
    } else {
      data["show_optimized"] = 0;
    }

    data["spend_input"] = JSON.stringify(mmmVal["mmmVal"]);
    var formData = formDataAppend(data);

    apiCall(formData, props);
    setDisabled(false);
  });

  const resetLifts = () => {
    data["spend_lift_pla"] = 0;
    data["spend_lift_ppc"] = 0;
    data["spend_lift_psm"] = 0;
    data["spend_lift_asc"] = 0;
    data["initial_load"] = 1;
    data["spend_input"] = "";
    var formData = formDataAppend(data);
    setPaidSearchVal("0");
    setProductAdsVal("0");
    setPaidSocialVal("0");
    setAmazonVal("0");
    localStorage.setItem("spend_lift_pla", 0);
    localStorage.setItem("spend_lift_ppc", 0);
    localStorage.setItem("spend_lift_psm", 0);
    localStorage.setItem("spend_lift_asc", 0);

    apiCall(formData, props);
  };

  const apiCall = (formData, props) => {
    dispatch(getDataTable(formData, props));
  };

  var maxVal = 500;
  var firstVal = -50;
  var options = [];

  for (var i = firstVal; i <= maxVal; ) {
    options.push({ value: i.toString(), label: i + "%" });
    if (i < 100) {
      i += 5;
    } else {
      i += 10;
    }
  }

  return (
    <div className="lift-wrapper">
      <Spin spinning={false}>
        <div className="lift-title">
          <h2>YoY Lift by Channel</h2>
        </div>
        <div className="lift-dropdown">
          <div className="lift-single-dropdown">
            <span className="lift-span">Paid Search:</span>
            <Select
              value={paidSearchVal}
              onChange={getPaidSearchVal}
              style={{
                width: 80,
              }}
              options={options}
            />
          </div>
          <div className="lift-single-dropdown">
            <span className="lift-span">Product Ads :</span>
            <Select
              value={productAdsVal}
              onChange={getProductAdsVal}
              style={{
                width: 80,
              }}
              options={options}
            />
          </div>
          <div className="lift-single-dropdown">
            <span className="lift-span">Paid Social:</span>
            <Select
              value={paidSocialVal}
              onChange={getPaidSocialVal}
              style={{
                width: 80,
              }}
              options={options}
            />
          </div>
          {include_amazon_data === 1 ? (
            <>
              {" "}
              <div className="lift-single-dropdown">
                <span className="lift-span">Amazon:</span>
                <Select
                  value={amazonVal}
                  onChange={getAmazonVal}
                  style={{
                    width: 80,
                  }}
                  options={options}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="lift-single-buttons">
            <Button className="lift-update" onClick={updateForeCasts}>
              Update Forecasts
            </Button>
            <Button
              className="lift-reset"
              onClick={resetLifts}
              disabled={disabled}
            >
              Reset Lifts
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default MMMLift;
