import { ACTIONS_TYPES } from "../Reducers/serviceSettingsReducer";

export function getServiceSettings(payload) {
  return {
    type: ACTIONS_TYPES.GET_SERVICE_SETTINGS,
    payload,
  };
}

export function saveServiceSettings(payload) {
  return {
    type: ACTIONS_TYPES.SAVE_SERVICE_SETTINGS,
    payload,
  };
}
