import React, { useRef, useState } from "react";
import { DatePicker, Form } from "antd";
import moment, { months } from "moment";

const MultipleCustomPicker = (props) => {
  const component_type = props?.block?.component_type;

  const storedArray = localStorage.getItem("datePersistVal");
  const datePersistVal = JSON.parse(storedArray);

  if (
    datePersistVal != null &&
    component_type == datePersistVal["component_type"]
  ) {
    var defaultDate = {
      fromDate: moment(datePersistVal["from_date"]),
      toDate: moment(datePersistVal["to_date"]),
    };
  } else if (
    JSON.parse(props?.block?.default_date_range_option)[2]["toDate"] === "today"
  ) {
    var defaultDate = {
      fromDate: moment().subtract(30, "day"),
      toDate: moment(),
    };
  } else {
    var defaultDate = {
      fromDate: moment().subtract(30, "day"),
      toDate: moment().subtract(1, "day"),
    };
  }
  const [dateRange, setDateRange] = useState(defaultDate);
  var fromDate = useRef(null);
  var toDate = useRef(null);
  const dateFormat = props?.block?.format;

  const handleDateChange = (date, dateType) => {
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [dateType]: date,
    }));
    if (dateType == "fromDate") {
      fromDate.current = date;
    } else {
      toDate.current = date;
    }
  };

  const disabledDate = (current) => {
    return current < fromDate.current;
  };

  const [form] = Form.useForm();
  props.form.setFieldsValue({
    ["dateRange"]: dateRange,
    ["dateFormat"]: dateFormat
  });

  return (
    <div className="multiple-custom-date">
      <div className="Report-V2-filter-box Report-V2-filter-box-date ">
        <div className="Report-V2-filter-option-title">
          <span>From Date</span>
        </div>
        <div className="Report-V2-filter-box-date-wrapper">
          <Form.Item
            name="dateRange"
            initialValue={{ dateRange, fromDate, toDate, dateFormat }}
          ></Form.Item>
          <Form.Item
            name="dateFormat"
            initialValue={{ dateRange, fromDate, toDate, dateFormat }}
          ></Form.Item>
          <div className="main-daterange">
            <DatePicker
              defaultValue={dateRange}
              value={dateRange.fromDate}
              onChange={(date) => handleDateChange(date, "fromDate")}
            />
          </div>
        </div>
      </div>

      <div className="Report-V2-filter-box Report-V2-filter-box-date ">
        <div className="Report-V2-filter-option-title">
          <span>To Date</span>
        </div>
        <div className="Report-V2-filter-box-date-wrapper">
          <div className="main-daterange">
            <DatePicker
              defaultValue={dateRange}
              value={dateRange.toDate}
              disabledDate={disabledDate}
              onChange={(date) => handleDateChange(date, "toDate")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleCustomPicker;
