import decode from "jwt-decode";

export const getUserGroup = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = decode(token);
    //console.log(token,decodedToken);
    //console.log(decodedToken.user_groups);
    return decodedToken.user_groups;
  } else {
    return [];
  }

}

export const isAdmin = () => {
  const token = localStorage.getItem("token");
  // console.log(decode(token));
  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.roles.includes('1')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isExecutive = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.roles.includes('2')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isIMSUser = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.roles.includes('4')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isSpecialist = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.roles.includes('5')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isCustomer = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.roles.includes('6')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isSales = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.roles.includes('8')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isNonManagedCustomer = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.roles.includes('9')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getUserId = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.user_id) {
      return decodedToken.user_id;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};