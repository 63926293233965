import React, { useState, useRef, useEffect, Component, useMemo } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Modal,
  Tooltip,
  Select,
  Switch,
  Input,
  Spin,
} from "antd";
import { BiPlusMedical } from "react-icons/bi";
import { FaEye, FaReply, FaShare, FaPencilAlt } from "react-icons/fa";
import { AiFillSetting, AiFillDelete } from "react-icons/ai";
import ReactQuill from "react-quill";
import 'quill-mention';
import { FiCornerDownRight } from "react-icons/fi";
import "react-quill/dist/quill.snow.css";
// import QuillEditor from './Editor';
import { useDispatch, useSelector } from "react-redux";
import { getClientId } from "../../Redux/Actions/Thunk/accountThunk";
import { getComment } from "../../Redux/Actions/Thunk/annotationsCommentThunk";
import { getReply } from "../../Redux/Actions/Thunk/annotationsReplyThunk";
import {
  saveAnnotationsComment,
  updateAnnotationsComment,
  getAnnotationsComment,
  deleteAnnotationsComment,
  getMentionUsers,
} from "../../Api/annotationsCommentApi";
import {
  saveAnnotationsReply,
  updateAnnotationsReply,
  deleteAnnotationsReply,
} from "../../Api/annotationsReplyApi";
import { getUserId } from "../../Utils/user";
import { getAnnotationsCommentReducer } from "../../";
import { getUsers } from "../../Api/AuthApi";

const { Option } = Select;
let index = 0;
// const atValues = [
//   { id: 1, value: 'test', email: 'test@test.com' },
//   { id: 2, value: 'customer', email: 'customer@customer.com' },
// ];
const ReportComments = ({
  data,
  atValues,
  blockIdParam,
  defaultFilterData,
  account_id,
  report_name,
  currentBlockId,
  triggerRerender,
  fromDateChange,
  toDateChange,
  setTriggerRerender,
  accountDataSources,
  account_name,
}) => {
  // const [items, setItems] = useState(["tag 1", "tag 2", "tag 3"]);
  // const {
  // 	comment: {
  // 		comment: { data: comment = [] },
  // 	  	isLoading,
  // 	},
  //   } = useSelector((state) => state);
  //   console.log('this is the from date changed', fromDateChange)
  //   console.log('this is the to date changed', toDateChange)
  const [name, setName] = useState("");
  const [modalClassName, setModalClassName] = useState("");
  const [annotationsValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("1");
  const [isSwitchChecked, setIsSwitchChecked] = useState(1);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [noteIdSingle, setNoteIdSingle] = useState(null);
  const [noteIdSingleReply, setNoteIdSingleReply] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [replyData, setReplyData] = useState(null);
  const [noteIdReply, setNoteIdReply] = useState(null);
  const options = [
    { value: "0", children: "None", label: "None" },
    { value: "1", children: "Paid Search", label: "Paid Search" },
    { value: "2", children: "Budgets", label: "Budgets" },
    { value: "3", children: "MMM", label: "MMM" },
    { value: "4", children: "Tracking Issue", label: "Tracking Issue" },
    { value: "5", children: "Promotion", label: "Promotion" },
    { value: "6", children: "Product Launch", label: "Product Launch" },
    { value: "7", children: "Budget Change", label: "Budget Change" },
    { value: "8", children: "External Factor", label: "External Factor" },
    { value: "9", children: "Paid Media", label: "Paid Media" },
    { value: "10", children: "Owned Media", label: "Owned Media" },
    { value: "11", children: "Site Migration", label: "Site Migration" },
    { value: "12", children: "Website", label: "Website" },
    { value: "13", children: "Strategy Shift", label: "Strategy Shift" },
    { value: "14", children: "Client Concern", label: "Client Concern" },
  ];
  const [selectedTag, setSelectedTag] = useState(options[0]);
  const setAnnotationValue = (newValue) => {
    // console.log('this is the changed value', newValue);
    setInputValue(newValue);
  };
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };
  const handleTagChange = (value, option) => {
    setSelectedTag(option);
  };
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const handleSwitchChange = (checked) => {
    const newValue = checked ? 1 : 0;
    setIsSwitchChecked(newValue);
  };
  // const addItem = (e) => {
  // 	e.preventDefault();
  // 	setItems([...items, name || `New item ${index++}`]);
  // 	setName("");
  // 	setTimeout(() => {
  // 		inputRef.current?.focus();
  // 	}, 0);
  // };
  const user_id = getUserId();
  // const {
  // 	accounts: {
  // 	  client_id: { data: clientData = [] },
  // 	},
  // 	reportLayouts: {
  // 	  input_controls: { data_input_controls: inputControlsData = [] },
  // 	  ad_spend_data: { data: ad_spend_status = [] },
  // 	  layouts: {
  // 		data: layoutData = [],
  // 		data_layout_controls: dataLayout = [],
  // 		layout_controls: controlLayout = [],
  // 		layout_css: custom_report_css = [],
  // 		// input_controls: inputControlsData = [],
  // 	  },
  // 	},
  //   } = useSelector((state) => state);
  const { reportLayouts } = useSelector((state) => state);
  const dispatch = useDispatch();
  function convertObjectToQueryString(obj) {
    return Object.keys(obj)
      .map((key) => {
        if (Array.isArray(obj[key])) {
          return obj[key]
            .map(
              (val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
            )
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
      })
      .join("&");
  }
  const report_id = reportLayouts?.layouts?.data?.layout[0].id;
  const storedArray = localStorage.getItem("datePersistVal");
  const datePersistVal = JSON.parse(storedArray);
  let from_date, to_date;
  if (datePersistVal != null) {
    from_date = datePersistVal["from_date"];
    to_date = datePersistVal["to_date"];
  } else {
    from_date = defaultFilterData["from_date"];
    to_date = defaultFilterData["to_date"];
  }
  let input_controls_data;
  if (Object.keys(data).length > 0) {
    input_controls_data = convertObjectToQueryString(data);
  } else {
    input_controls_data = convertObjectToQueryString(defaultFilterData);
  }
  // console.log('this is report layout', input_controls_data);
  // const jsonData = JSON.stringify(reportLayouts.layouts.input_controls);
  // console.log('this is the json data', jsonData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
    setModalClassName("save-annotations");
  };
  const showReplyModal = () => {
    setIsModalVisible(true);
    setModalClassName("reply-annotations");
  };
  const showUpdateModal = () => {
    setIsModalVisible(true);
    setModalClassName("update-annotations");
  };
  const showUpdateReplyModal = () => {
    setIsModalVisible(true);
    setModalClassName("update-reply");
  };
  const handleOk = () => {
    setIsModalVisible(false);
    setInputValue(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setInputValue(null);
  };
  const [isSettingModalVisible, setIsSettingModalVisible] = useState(false);

  const showSettingModal = () => {
    setIsSettingModalVisible(true);
  };

  const handleSettingOk = () => {
    setIsSettingModalVisible(false);
  };

  const handleSettingCancel = () => {
    setIsSettingModalVisible(false);
  };
  const [value, setValue] = useState("");

  const handleUpdateComment = async (e) => {
    // hit to api
    setLoadingData(true);
    try {
      const response = await updateAnnotationsComment({
        account_id: account_id,
        report_name: report_name,
        report_id: reportLayouts?.layouts?.data?.layout[0]?.id,
        block_id: currentBlockId.toString(),
        input_controls: input_controls_data,
        from_date: from_date,
        to_date: to_date,
        notes: annotationsValue,
        status: selectedOption,
        is_private: isSwitchChecked,
        tag_id: selectedTag.value,
        tag_name: selectedTag.children,
        account_name: account_name,
        device: "desktop",
        advertising_channel: "test",
        campaign_category: "test",
        campaign_type: "1",
        campaign: "3",
        search_engine: "Google",
        date_range: "2023-7-31",
        id: noteIdSingle,
      });
      // show success  message
      handleGetComment();
    } catch (err) {
      // show exception err to client
    }
  };
  //save annotations comment
  const handleSaveComment = async (e) => {
    // console.log('this is save notes data', annotationsValue);
    setLoadingData(true);
    try {
      const response = await saveAnnotationsComment({
        account_id: account_id,
        report_name: report_name,
        report_id: reportLayouts?.layouts?.data?.layout[0]?.id,
        block_id: currentBlockId.toString(),
        input_controls: input_controls_data,
        from_date: from_date,
        to_date: to_date,
        notes: annotationsValue,
        status: selectedOption,
        is_private: isSwitchChecked,
        tag_id: selectedTag.value,
        tag_name: selectedTag.children,
        account_name: account_name,
        device: "desktop",
        advertising_channel: "test",
        campaign_category: "test",
        campaign_type: "1",
        campaign: "3",
        search_engine: "Google",
        date_range: "2023-7-31",
      });
      // show success  message
      // setLoadingData(false)
      handleGetComment();
    } catch (err) {
      // show exception err to client
    }
  };
  //delete annotations comment
  const handleDeleteComment = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this comment?",
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
        setLoadingData(true);
        try {
          const response = await deleteAnnotationsComment({
            id: id,
          });
          handleGetComment();
        } catch (err) {}
      },
    });
  };
  //handle reply save
  const handleReplySave = async (e) => {
    try {
      const response = await saveAnnotationsReply({
        account_id: account_id,
        report_name: report_name,
        report_id: reportLayouts.layouts.data.layout[0].id,
        block_id: currentBlockId.toString(),
        from_date: from_date,
        to_date: to_date,
        notes: annotationsValue,
        status: selectedOption,
        is_private: isSwitchChecked,
        tag_id: selectedTag.value,
        tag_name: selectedTag.label,
        account_name: account_name,
        notes_id: noteIdSingle,
      });
      // show success  message
      handleGetReply();
    } catch (err) {
      // show exception err to client
    }
  };

  const handleGetComment = async () => {
    if (from_date) {
      try {
        const response = await dispatch(
          getComment({
            account_id,
            report_name,
            report_id,
            block_id: currentBlockId.toString(),
            from_date,
            to_date,
          })
        );

        // Store the response data in state
        setCommentData(response.payload.data);
        setLoadingData(false);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setCommentData(null);
    }
  };
  // const handleGetCommentInputControl = async () => {
  // setLoadingData(true)
  // const from_date = fromDateChange
  // const to_date = toDateChange
  // if (fromDateChange) {
  // 	try {
  // 	  const response = await dispatch(
  // 		getComment({
  // 		  account_id,
  // 		  report_name,
  // 		  report_id,
  // 		  block_id: currentBlockId.toString(),
  // 		  from_date,
  // 		  to_date,
  // 		})
  // 	  );
  // 	  // Store the response data in state
  // 	  setCommentData(response.payload.data);
  // 	  setLoadingData(false);
  // 	  setTriggerRerender(false);
  // 	} catch (error) {
  // 	  console.error('Error:', error);
  // 	}
  //   }
  // };
  useEffect(() => {
    // Check if all required data is available
    if (report_id && from_date && to_date && currentBlockId) {
      setLoading(false);
    }
  }, [report_id, from_date, to_date]);

  // Fetch comment when all required data is available
  //
  useEffect(() => {
    if (!loading) {
      handleGetComment();
      setLoading(true);
    }
  }, [loading]);
  // useEffect(() => {
  // if ((!loading && fromDateChange)) {
  // 	handleGetCommentInputControl()
  // }
  // }, [loading, fromDateChange]);
  //fetch the reply data
  useEffect(() => {
    // Check if commentData exists and is an array
    if (Array.isArray(commentData) && commentData.length > 0) {
      // Iterate through commentData and set noteIdReply individually
      async function setNoteIds() {
        for (const item of commentData) {
          // Check if item is not null
          if (item !== null) {
            await new Promise((resolve) => {
              setNoteIdReply(item.id);
              resolve();
            });
          }
        }
      }
      setNoteIds();
    }
  }, [commentData]);
  const handleGetReply = async () => {
    // Check if noteIdReply is not null
    if (noteIdReply !== null) {
      try {
        const response = await dispatch(
          getReply({
            account_id,
            report_id,
            block_id: currentBlockId.toString(),
            notes_id: noteIdReply,
          })
        );
        // Store the response data in state
        setReplyData(response.payload.data);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.warn(
        "noteIdReply is null or an empty array, skipping API request."
      );
    }
  };
  useEffect(() => {
    if (
      noteIdReply !== null &&
      Array.isArray(commentData) &&
      commentData.length > 0
    ) {
      handleGetReply();
    }
  }, [noteIdReply, commentData]);
  //update reply
  const handleReplyUpdate = async (e) => {
    try {
      const response = await updateAnnotationsReply({
        account_id: account_id,
        report_name: report_name,
        report_id: reportLayouts.layouts.data.layout[0].id,
        block_id: currentBlockId.toString(),
        notes: annotationsValue,
        status: selectedOption,
        is_private: isSwitchChecked,
        tag_id: selectedTag.value,
        tag_name: selectedTag.label,
        account_name: account_name,
        notes_id: noteIdSingle,
        id: noteIdSingleReply,
      });
      // show success  message
      handleGetReply();
    } catch (err) {
      // show exception err to client
    }
  };
  //delete reply
  const handleDeleteReply = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this reply?",
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
        try {
          const response = await deleteAnnotationsReply({
            id: id,
          });
          handleGetReply();
        } catch (err) {}
      },
    });
  };

  //add mention feature
  // const [atValues,setAtValues] = useState([])
  // const [isMountRender, setMountRender] = useState(true);
  // const handleMentionUser = async (e) => {
  //   try {
  //     const response = await getMentionUsers({
  //       account_id: account_id,
  //     });

  //     const mentionUsers = response.data.mention_users;

  //     const formattedMentionUsers = mentionUsers.map((user, index) => ({
  //       id: user.id,
  //       value: user.value,
  //       email: user.email,
  //     }));
  //     setAtValues(formattedMentionUsers);
  //     // setMountRender(false);
  //   } catch (err) {
  //     // Handle exceptions and errors
  //   }
  // }

  // handleMentionUser();
  const editor = useRef(null);
  const modules = useMemo(
    () => ({
      // toolbar: [
      //   ['bold', 'italic', 'underline'],
      //   ['clean'],
      // ],
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        source: function (searchTerm, renderList, mentionChar) {
          let values;

          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length >= 3) {
            // Show suggestions after typing 3 or more characters
            const matches = [];
            for (let i = 0; i < values.length; i++) {
              const item = values[i];
              if (
                ~item.value.toLowerCase().indexOf(searchTerm.toLowerCase()) ||
                (item.email &&
                  ~item.email.toLowerCase().indexOf(searchTerm.toLowerCase()))
              ) {
                matches.push(item);
              }
            }
            renderList(matches, searchTerm);
          }
        },
        renderItem: function (item) {
          return `${item.value} - ${item.email}`;
        },
      },
    }),
    [atValues]
  );

  // useEffect(() => {
  // 	if (isMountRender === true) {
  // 	setMountRender(false)
  // 	handleMentionUser();
  // 	}
  // }, [isMountRender]);
  return (
    <>
      <Spin spinning={loadingData} size="large">
        {commentData ? (
          blockIdParam.map((blockId, index) => {
            // Check if the current blockId matches any blockId from the parent component
            if (blockId === currentBlockId) {
              return (
                <div
                  className={`report-annotation-wrapper visible`}
                  key={index}
                  block-id={blockId}
                >
                  {commentData.map((commentDataSingle, commentIndex) => (
                    <div
                      className="report-annotation-single"
                      key={commentIndex}
                      note-id={commentDataSingle.id}
                    >
                      <div className="ras-title">
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: commentDataSingle.title,
                          }}
                        />
                        <span>
                          {user_id === commentDataSingle.user_id && (
                            <>
                              <Tooltip title="Edit Comment">
                                <Button
                                  onClick={() => {
                                    setNoteIdSingle(commentDataSingle.id);
                                    setInputValue(commentDataSingle.notes);
                                    showUpdateModal();
                                  }}
                                >
                                  <FaPencilAlt />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Delete Comment">
                                <Button
                                  onClick={() => {
                                    handleDeleteComment(commentDataSingle.id);
                                  }}
                                >
                                  <AiFillDelete />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </span>
                      </div>
                      <div className="ras-comment">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: commentDataSingle.notes,
                          }}
                        ></p>
                      </div>
                      <div className="ras-reply">
                        <Button
                          onClick={() => {
                            setNoteIdSingle(commentDataSingle.id);
                            showReplyModal();
                          }}
                        >
                          <FaReply /> Reply
                        </Button>
                      </div>
                      <div className="ras-tags">
                        <strong
                          dangerouslySetInnerHTML={{
                            __html: commentDataSingle.tag,
                          }}
                        ></strong>
                      </div>
                      {replyData ? (
                        <>
                          {replyData.map((reply, replyIndex) => {
                            // console.log(reply.notes_id == commentData[commentIndex].id.toString(),reply.notes_id ,commentData[commentIndex].id.toString(), commentDataSingle.id.toString());
                            if (
                              reply.notes_id == commentDataSingle.id.toString()
                            ) {
                              return (
                                <div key={replyIndex}>
                                  <div
                                    className="report-reply-single"
                                    note-id={reply.id}
                                  >
                                    <div className="ras-title">
                                      <span className="reply-icon">
                                        <FiCornerDownRight />
                                      </span>
                                      <h6
                                        dangerouslySetInnerHTML={{
                                          __html: reply.title,
                                        }}
                                      />
                                      <span>
                                        {user_id ===
                                          commentDataSingle.user_id && (
                                          <>
                                            <Tooltip title="Update Reply">
                                              <Button
                                                onClick={() => {
                                                  setNoteIdSingleReply(
                                                    reply.id
                                                  );
                                                  setNoteIdSingle(
                                                    commentDataSingle.id
                                                  );
                                                  showUpdateReplyModal();
                                                }}
                                              >
                                                <FaPencilAlt />
                                              </Button>
                                            </Tooltip>
                                            <Tooltip title="Delete Reply">
                                              <Button
                                                onClick={() => {
                                                  handleDeleteReply(reply.id);
                                                }}
                                              >
                                                <AiFillDelete />
                                              </Button>
                                            </Tooltip>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                    <div className="ras-comment">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: reply.notes,
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </>
                      ) : (
                        // console.log('not found data')
                        <div className="reply"></div>
                      )}
                    </div>
                  ))}
                  <div className="report-annotation-buttons">
                    <Tooltip title="View Annotation Report" placement="left">
                      <Link
                        to="/reports/v2/view/annotations_report"
                        target="_blank"
                      >
                        <FaEye />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Add Comment" placement="left">
                      <Button
                        onClick={(e) => showModal(currentBlockId)}
                        note-id={currentBlockId}
                      >
                        <BiPlusMedical />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              );
            }

            return null; // Return null if there's no match for this blockId
          })
        ) : (
          <>
            <div className="report-no-annotation">
              <p>There are no comments for the selected date range</p>
            </div>
            <div className="report-annotation-buttons">
              <Tooltip title="Add Comment" placement="left">
                <Button
                  onClick={(e) => showModal(currentBlockId)}
                  note-id={currentBlockId}
                >
                  <BiPlusMedical />
                </Button>
              </Tooltip>
            </div>
          </>
        )}
        <Modal
          title="Report Comments"
          cancelButtonProps={{ style: { display: "none" } }}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Save"
          className={`report-comment-modal ${modalClassName}`}
          width={700}
          footer={null}
        >
          <div className="report-comment-text">
            <div className="report-comment-editor">
              <ReactQuill
                theme="snow"
                value={annotationsValue}
                modules={modules}
                onChange={setAnnotationValue}
              />
            </div>
            <div className="report-comment-buttons">
              <Row gutter={16}>
                <Col lg={{ span: 7 }} xs={{ span: 12 }}>
                  <div className="rcb-left">
                    <span className="rcb-title">View</span>
                    <Select
                      defaultValue={selectedOption}
                      style={{
                        width: 120,
                      }}
                      onChange={handleOptionChange}
                    >
                      <Option value="1">Private</Option>
                      <Option value="2">Internal</Option>
                      <Option value="0">All</Option>
                    </Select>
                  </div>
                </Col>
                <Col lg={{ span: 7 }} xs={{ span: 12 }}>
                  <div className="rcb-middle">
                    <span className="rcb-title">Show Comment</span>
                    <Switch
                      size="small"
                      defaultChecked={isSwitchChecked}
                      onChange={handleSwitchChange}
                    />
                  </div>
                </Col>
                <Col lg={{ span: 10 }} xs={{ span: 24 }}>
                  <div className="rcb-right">
                    <span className="rcb-title">Tags</span>
                    <Select
                      defaultValue={selectedTag}
                      style={{
                        width: 170,
                      }}
                      onChange={handleTagChange}
                    >
                      {options.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div className="report-comment-save">
						<Button className="main-primary-btn">Save</Button>
					</div> */}
            <div className="report-comment-save">
              <Button
                className="main-primary-btn"
                onClick={(e) => {
                  if (modalClassName == "save-annotations") {
                    handleSaveComment(e);
                  } else if (modalClassName == "update-annotations") {
                    // update
                    handleUpdateComment(e);
                  } else if (modalClassName == "reply-annotations") {
                    handleReplySave(e);
                  } else if (modalClassName == "update-reply") {
                    handleReplyUpdate(e);
                  }
                  handleOk();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          title="Greek Gear - Paid Search Performance"
          okButtonProps={{ style: { display: "none" } }}
          visible={isSettingModalVisible}
          onOk={handleSettingOk}
          onCancel={handleSettingCancel}
          cancelText="Close"
          className="report-comment-settings-modal"
          width={600}
        >
          <div className="report-comment-settings">
            <ul>
              <li>Device: null</li>
              <li>Advertising Channel: null</li>
              <li>Campaign Type: All</li>
              <li>Campaign Category: All</li>
              <li>Search Engine: All</li>
              <li>Date Range: August 1, 2023 - August 21, 2023</li>
            </ul>
          </div>
        </Modal>
      </Spin>
    </>
  );
};

export default ReportComments;
