import { ACTIONS_TYPES } from "../Reducers/trendReducer";

export function getTrend(payload) {
  return {
    type: ACTIONS_TYPES.GET_TRENDS,
    payload,
  };
}

export function getTrendBreakdown(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_TREND_BREAKDOWN,
    payload,
  };
}

export function getTrendBreakdownRequest(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_TREND_BREAKDOWN_REQUEST,
  };
}

export function getDevicePerformance(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_DEVICE_REQUEST,
    payload,
  };
}
