/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaPencilAlt } from "react-icons/fa";
import { Tooltip } from "antd";
import Popover from "react-popover";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFacebookResponse } from "../../../Redux/Actions/Thunk/facebookTikTokThunk";
import { ACTIONS_TYPES } from "../../../Redux/Reducers/facebookTikTokReducer";
import $, { data } from "jquery";

const ServiceCell = (props) => {
  const [getData, setData] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [jsIframe, setJsIframe] = useState("<span>Loading....</span>");

  var ad_id = props?.record?.ad_id.toString();
  var roas_goal = props?.goalData?.roas_goal;
  var ctr_avg = props?.goalData?.ctr_avg;
  var ad_spend = props?.record?.ad_spend;
  var ctr = props?.record?.ctr;
  var roas = props?.record?.roas;
  var cellClass = "";

  if (
    ad_spend >= 500 &&
    ctr < ctr_avg &&
    parseFloat(roas) < parseFloat(roas_goal)
  ) {
    cellClass = "light-red-column";
  } else if (
    (ad_spend >= 500 && ctr < ctr_avg) ||
    parseFloat(roas) < parseFloat(roas_goal)
  ) {
    cellClass = "light-yellow-column";
  } else if (ctr > ctr_avg && parseFloat(roas) > parseFloat(roas_goal)) {
    cellClass = "light-green-column";
  }

  const {
    fb_tiktok_data: {
      facebook_data: { iframe: iframe = "", src: src = "" },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const content = (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: jsIframe,
        }}
      />
    </div>
  );

  const setPopoverContent = (ad_id) => {
    dispatch(getFacebookResponse({ ad_id }));
  };

  const handleMouseEnter = () => {
    //setPopoverContent(props?.record?.ad_id);
    setIsPopoverOpen(true);
    const version = process.env.REACT_APP_FB_PREVIEW_API_VERSION;
    const access_token = process.env.REACT_APP_FB_PREVIEW_ACCESS_TOKEN;
    fetch(
      `https://graph.facebook.com/${version}/${ad_id}/previews?ad_format=MOBILE_FEED_STANDARD&access_token=${access_token}`
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log("PS",data.data[0].body);
        setJsIframe(data?.data[0]?.body);
      })
      .catch((error) => {
        setJsIframe(
          '<div style="background-color: #fff; padding: 20px; border: 1px solid #ccc; width: 200px;">' +
            "<span>Ad Preview not available</span>" +
            "</div>"
        );
        console.error("Error fetching ad preview:", error);
      });
  };

  const handleMouseLeave = () => {
    setIsPopoverOpen(false);
  };

  const fb_tiktok_state = useSelector(
    (state) => state.fb_tiktok_data.facebook_data
  );
  return (
    <>
      {props.value != null && (
        <>
          <Popover
            isOpen={isPopoverOpen}
            body={content}
            place="right"
            preferPlace="right"
            tipSize={0.01}
            style={{ zIndex: "10" }}
          >
            {props.value != "Average" ? (
              <span className={cellClass}>
                <a
                  href={src.replace("&amp;", "&")}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <FormattedNumberCell
                    value={props.value}
                    format={props.column.format}
                  />
                </a>
              </span>
            ) : (
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            )}
          </Popover>
        </>
      )}
    </>
  );
};

const PaidSocialAdPerformance = (props) => {
  const columnIds = ["ad_name"];
  const abtest_winner = props?.abtest_winner;
  const record = props?.record;
  const dataIndex = props?.column?.dataIndex;
  const abtest_kpi = props?.abtest_kpi;
  if (abtest_winner != "None" && props?.extraParams?.block?.block_id === 318) {
    // $(".report-v2-panel-body").append("<p>hello</p>");
    const panelHeadingElement = document.querySelector(
      "#row2 .report-v2-panel-body .datatable-options"
    );
    if (
      panelHeadingElement &&
      abtest_winner != "" &&
      abtest_winner != "None" &&
      props?.extraParams?.block?.block_id === 318
    ) {
      if (
        abtest_kpi == dataIndex &&
        abtest_winner == record["ad_id"] &&
        dataIndex != "ad_name" &&
        dataIndex != "campaign_goal"
      ) {
        return (
          <>
            <span className="light-green-column">
              <strong>
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </strong>
            </span>
          </>
        );
      } else if (
        abtest_winner == record["ad_id"] &&
        dataIndex != "ad_name" &&
        dataIndex != "campaign_goal"
      ) {
        return (
          <>
            <span className="light-green-column">
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </span>
          </>
        );
      }
    }
  }

  if (columnIds.includes(props.column.dataIndex)) {
    return <ServiceCell {...props} />;
  } else {
    return (
      <>
        <span>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </>
    );
  }
};

export default PaidSocialAdPerformance;
