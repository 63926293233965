import React, { useEffect, useState, useCallback, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { getAreaChartData } from "../../Redux/Actions/Thunk/AreaChartThunk";
import { formDataAppend } from "../../Utils/helpers";
import { Spin, Tabs } from "antd";

const AreaChart = (props) => {
  const config = JSON.parse(props?.block?.config);
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("Daily");
  const {
    area: {
      areaChartData: {
        [props?.block?.block_id]: {
          series_data: series_data = [],
          categories: categories = [],
          insights: insights = [],
          tab_options: tab_options = [],
        } = {},
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    // if (props?.length == 0) {
    dispatch(getAreaChartData(formData, props));
    // }
  }, []);

  const tabOptions = useMemo(() => tab_options, [tab_options]);

  const handleTabChange = useCallback(
    (key) => {
      setActiveTab(key);
      var data = props?.all_input_data;
      Object.assign(data, { view_mode: key });
      var formData = formDataAppend(data);
      dispatch(getAreaChartData(formData, props));
    },
    [dispatch, props]
  );

  function ViewModes() {
    return (
      <div>
        <Tabs defaultActiveKey={activeTab} onChange={handleTabChange}>
          {tabOptions.map((tab) => (
            <TabPane tab={tab.title} key={tab.key}></TabPane>
          ))}
        </Tabs>
      </div>
    );
  }

  var chart = {
    chart: {
      type: "area",
    },
    title: {
      text: config.title != undefined || config.title != "" ? config.title : "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      title: {
        text: "",
      },
      categories: categories,
    },
    yAxis: [
      {
        // tickAmount: 5,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            return this.value.toLocaleString();
          },
        },
      },
      {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            return this.value + "";
          },
        },
        gridLineWidth: 0,
        opposite: true,
      },
    ],
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      reversed: false,
      enabled: 1,
    },
    tooltip: {
      formatter: function () {
        if (this.series.name == "Seasonality") {
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            " : <b>" +
            this.y.toLocaleString()
          );
        } else {
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            " : <b>" +
            this.y.toLocaleString() +
            "</b><br />Total : <b>" +
            this.total.toLocaleString() +
            "</b>"
          );
        }
      },
    },
    series: series_data,
    credits: {
      enabled: false,
    },
    exporting: {
      showTable: false,
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  return (
    <>
      <Spin spinning={isLoading}>
        {tabOptions.length > 0 && tabOptions ? <ViewModes /> : ""}
        <HighchartsReact
          highcharts={Highcharts}
          options={chart}
        ></HighchartsReact>
        {insights != undefined && insights.length > 0 ? (
          <div className="insights">
            <h4>Insights</h4>
            <div dangerouslySetInnerHTML={{ __html: insights }} />
          </div>
        ) : (
          "-"
        )}
      </Spin>
    </>
  );
};

export default AreaChart;
