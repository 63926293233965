import * as bingAdsMccAPI from "../../../../../Api/datasources/dsConfig/bingAdsMccApi";
import errorHandler from "../../../../../Utils/Request/errorHandler";
import successHandler from "../../../../../Utils/Request/successHandler";
import { ACTIONS_TYPES } from "../../../../Reducers/datasources/ds_config/dsBingAdsReducer";
import { setLoadingFalse, setLoadingTrue } from "../../../globals/loadingThunk";

/**
 * Get single Bing Ads MCC
 * @returns {Function} dispatch
 */
export const getAllBingAdsDataSourcesThunk = (props) => async (dispatch) => {
  try {
    // Dispatch request action and set loading state to true
    dispatch({ type: ACTIONS_TYPES.BING_ADS_MCC_REQUEST });
    dispatch(setLoadingTrue());

    // Fetch data from API
    const response = await bingAdsMccAPI.getAllBingAdsParamApi(props);
    const data = response?.data?.data?.data;

    // setting data to reducer
    dispatch({
      type: ACTIONS_TYPES.BING_ADS_ALL_MCC,
      payload: data,
    });
  } catch (error) {
    let errMessage = error?.response?.data?.error?.message;
    console.error("Error in fetching account ds: ", error);
    errorHandler(error);
    // dispatch(setAlert({ message: error?.response?.data?.error?.message, type: "error" }));
  } finally {
    dispatch(setLoadingFalse());
  }
};

/**
 * Get single Bing Ads   data sources
 * @returns {Function} dispatch
 */
export const getBingAdsDataSourcesThunk = (props) => async (dispatch) => {
  try {
    // Dispatch request action and set loading state to true
    dispatch({ type: ACTIONS_TYPES.BING_ADS_MCC_REQUEST });
    dispatch(setLoadingTrue());

    // Fetch data from API
    const response = await bingAdsMccAPI.getBingAdsParamApi(props);
    const data = response?.data?.data?.data;

    dispatch({
      type: ACTIONS_TYPES.BING_ADS_MCC,
      payload: data,
    });
    // successHandler(response, { notifyOnSuccess: true, notifyOnFailed: true });
  } catch (error) {
    let errMessage = error?.response?.data?.error?.message;
    console.error("Error in fetching account ds: ", error);
    errorHandler(error);
    // dispatch(googleConsoleMCCAction.failedGoogleConsoleMCCAction(errMessage));
    // dispatch(setAlert({ message: error?.response?.data?.error?.message, type: "error" }));
  } finally {
    dispatch(setLoadingFalse());
  }
};

/**
 * Create a new Bing Ads Service account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const createBingAdsDataSourcesThunk = (props, form) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.BING_ADS_MCC_REQUEST,
      });
      dispatch(setLoadingTrue());

      // Call the API to create a new data source
      const response = await bingAdsMccAPI.createBingAdsParamApi(props);
      // Notify user of success
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      form.resetFields();
    } catch (error) {
      // Dispatch failure action with error message
      // let errMessage = error?.response?.data?.error?.message;
      // dispatch(googleConsoleMCCAction.failedGoogleConsoleMCCAction(errMessage));

      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
      dispatch(getAllBingAdsDataSourcesThunk({}));
    }
  };
};

/**
 * Update an Bing Ads Service data source
 * @param {object} props - The payload to pass to the API
 * @returns {Function} - A Redux thunk function that dispatches actions to update the data source and handle errors
 */
export const updateBingAdsDataSourcesThunk = (props, history) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.BING_ADS_MCC_REQUEST,
      });
      dispatch(setLoadingTrue());

      const response = await bingAdsMccAPI.updateBingAdsParamApi(props);

      // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      history.push("/data-source/bing-ad");
    } catch (error) {
      // let errMessage = error?.response?.data?.error?.message;
      return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
      dispatch(getAllBingAdsDataSourcesThunk({}));
    }
  };
};

// /**
//  * Update an Bing Ads Service data source
//  * @param {object} props - The payload to pass to the API
//  * @returns {Function} - A Redux thunk function that dispatches actions to update the data source and handle errors
//  */
// export const deleteBingAdsDataSourcesThunk = (props) => {
//   alert(props.id);
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: ACTIONS_TYPES.BING_ADS_MCC_REQUEST,
//       });
//       dispatch(setLoadingTrue());

//       const response = await bingAdsMccAPI.dele(props);

//       // Notify user
//       successHandler(response, {
//         notifyOnSuccess: true,
//         notifyOnFailed: true,
//       });
//     } catch (error) {
//       return errorHandler(error);
//     } finally {
//       dispatch(setLoadingFalse());
//       dispatch(getAllBingAdsDataSourcesThunk({}));
//     }
//   };
// };
