/**
 * This Token class is used encrypt and decrypt the token
 */
export class Token {
  // *Converts base64 to normal string
  static decryptAuthParams(str) {
    try {
      if(str == ""){
        return "";
      }
      let arr = atob(str).split("!!&!!");
      let str1 = atob(arr[0]) + atob(arr[1]);
      return str1;
    } catch (e) {
      throw new Error("Unable to decrypt the string");
    }
  }
  // *Convert normal String to Base64
  static encryptAuthParams(str) {
    try {
      if(str==""){
        return "";
      }
      let token_length = str.length;
      let data = str.split("");
      let whole_tok_data = token_length / 2;
      let tok_data_length = Math.floor(whole_tok_data);
      let first_half = data.slice(0, tok_data_length);
      let second_half = data.slice(tok_data_length, token_length);
      let first_restring = first_half.join("");
      let second_restring = second_half.join("");
      let str1 = btoa(first_restring) + "!!&!!" + btoa(second_restring);
      return btoa(str1);
    } catch (e) {
      return "";
    }
  }

  static isBase64(str) {
    return str.length % 4 == 0 && /^[A-Za-z0-9+/]+[=]{0,3}$/.test(str);
  }
}

const res = Token.decryptAuthParams(
  "WVcxNmJqRXVZWEJ3YkdsallYUnBiMjR0YjJFeUxXTnNhV1Z1ZEM0MiEhJiEhTVRCbVl6bG1aRFExWVRRME56VmxPVFUxTkRaaE1qTTNaR00wTkRsaU5RPT0="
);
