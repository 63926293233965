import React from "react";
import { Tooltip, Modal } from "antd";
import { FaInfoCircle } from "react-icons/fa";

import Config from "../Config";
import { format_data } from "../Utils/helpers";
import { formatColumns } from "../Utils/colorLogic";
import { FormattedNumberCell } from "../Utils/helpers/formatting";

import {
  extractPlaceholders,
  extractAndReplaceParams,
  extractAndReplaceAccountId,
  extractAndReplaceAbsoluteParams,
  extractAndReplaceDatePlaceholders,
  extractAndReplaceCompareDatePlaceholders,
  extractAndReplaceFromAndToDatePlaceholders,
} from "../Utils/helpers/tableHelper";

export function checkFormat(
  showGoogleAdsBtn,
  showGoogleAnalyticsBtn,
  columns,
  getColumnSearchProps,
  getGoalData,
  isSharePopoverVisible,
  setIsSharePopoverVisible,
  seriesData,
  setSeriesData,
  categoriesData,
  setCategoriesData,
  setAccountName,
  accountName,
  drillDownAPiPath,
  inputData,
  setLoading,
  props,
  formData,
  dispatch
) {
  const hide = () => {
    setIsSharePopoverVisible(!isSharePopoverVisible);
  };

  const handlePopoverVisibleChange = () => {
    setIsSharePopoverVisible(!isSharePopoverVisible);
  };

  const handleOk = () => {
    setIsSharePopoverVisible(false);
  };

  const handleCancel = () => {
    setIsSharePopoverVisible(false);
  };

  const showModal = () => {
    setIsSharePopoverVisible(true);
  };

  if (showGoogleAdsBtn != "true" && showGoogleAnalyticsBtn != "true") {
    const cols = columns.map((item, index) =>
      item.tooltip == "click"
        ? {
            title: (
              <>
                <Modal
                  className="table-tooltip-modal"
                  title={item.tooltipTitle}
                  visible={isSharePopoverVisible}
                  onOk={null}
                  okText="Download ASC Search Term" //TBD
                  okButtonProps={{ style: { display: "none" } }}
                  onCancel={handleCancel}
                  cancelText="Close"
                >
                  <div dangerouslySetInnerHTML={{ __html: item.value }} />
                </Modal>
                {item.title}
                <Tooltip trigger="click" onClick={showModal}>
                  <span
                    className="table-header-tooltip"
                    style={{ color: "#8dc441", cursor: "pointer" }}
                  >
                    <FaInfoCircle />
                  </span>
                </Tooltip>
              </>
            ),
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            fixed: item.fixed != undefined ? item.fixed : (index == 0 ? "left" : ""),
            sorter:
              item.sorter != undefined && item.sorter == "false" ? false : true,
            format: item.format,
            tooltip: item.tooltip,
            ...getColumnSearchProps(item.dataIndex, item.format),
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex,
                isSharePopoverVisible,
                setIsSharePopoverVisible,
                seriesData,
                setSeriesData,
                categoriesData,
                setCategoriesData,
                setAccountName,
                accountName,
                drillDownAPiPath,
                item,
                inputData,
                setLoading,
                props,
                formData,
                dispatch
              );
            },
          }
        : item.tooltip == "hover"
        ? {
            title: (
              <>
                {item.title}
                <Tooltip trigger="hover" title={item.value}>
                  <span
                    className="table-header-tooltip"
                    style={{ color: "#8dc441" }}
                  >
                    <FaInfoCircle />
                  </span>
                </Tooltip>
              </>
            ),
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            fixed: item.fixed != undefined ? item.fixed : (index == 0 ? "left" : ""),
            sorter:
              item.sorter != undefined && item.sorter == "false" ? false : true,
            format: item.format,
            tooltip: item.tooltip,
            ...getColumnSearchProps(item.dataIndex, item.format),
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex,
                isSharePopoverVisible,
                setIsSharePopoverVisible,
                seriesData,
                setSeriesData,
                categoriesData,
                setCategoriesData,
                setAccountName,
                accountName,
                drillDownAPiPath,
                item,
                inputData,
                setLoading,
                props,
                formData,
                dispatch
              );
            },
          }
        : item.filter != undefined && item.filter == "false"
        ? {
            title:
              item.title_link != undefined ? (
                <a href={"/" + item.title_link + "?" + "from_date=" + inputData["from_date"] + "&to_date=" + inputData["to_date"]} target="_blank">
                  {item.title}
                </a>
              ) : (
                item.title
              ),
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
           fixed: item.fixed != undefined ? item.fixed : (index == 0 ? "left" : ""),
            sorter:
              item.sorter != undefined && item.sorter == "false" ? false : true,
            sortDirections: ['descend', 'ascend'],
            format: item.format,
            href: item.href,
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex,
                isSharePopoverVisible,
                setIsSharePopoverVisible,
                seriesData,
                setSeriesData,
                categoriesData,
                setCategoriesData,
                setAccountName,
                accountName,
                drillDownAPiPath,
                item,
                inputData,
                setLoading,
                props,
                formData,
                dispatch
              );
            },
          }
        : {
            title:
              item.title_link != undefined ? (
                <a href={"/" + item.title_link}>{item.title}</a>
              ) : (
                item.title
              ),
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            fixed: item.fixed != undefined ? item.fixed : (index == 0 ? "left" : ""),
            ...getColumnSearchProps(item.dataIndex, item.format),
            sorter:
              item.sorter != undefined && item.sorter == "false" ? false : true,
            sortDirections: ['descend', 'ascend'],
            format: item.format,
            href: item.href,
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex,
                isSharePopoverVisible,
                setIsSharePopoverVisible,
                seriesData,
                setSeriesData,
                categoriesData,
                setCategoriesData,
                setAccountName,
                accountName,
                drillDownAPiPath,
                item,
                inputData,
                setLoading,
                props,
                formData,
                dispatch
              );
            },
          }
    );
    columns = cols;
    return columns;
  } else {
    const col5 = columns[5]["children"].map((item, index) =>
      item.format != undefined
        ? {
            title: item.title,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            format: item.format,
            // className: "green-column",
            ...getColumnSearchProps(item.dataIndex, item.format),
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex
              );
            },
          }
        : {
            title: item.title,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            format: item.format,
            ...getColumnSearchProps(item.dataIndex, item.format),
          }
    );

    const col6 = columns[6]["children"].map((item, index) =>
      item.format != undefined
        ? {
            title: item.title,
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            format: item.format,
            // className: "green-column",
            ...getColumnSearchProps(item.dataIndex, item.format),
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex,
                isSharePopoverVisible,
                setIsSharePopoverVisible,
                seriesData,
                setSeriesData,
                categoriesData,
                setCategoriesData,
                setAccountName,
                accountName,
                drillDownAPiPath,
                item,
                inputData,
                setLoading,
                props,
                formData,
                dispatch
              );
            },
          }
        : {
            title: item.title,
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            ...getColumnSearchProps(item.dataIndex, item.format),
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            format: item.format,
          }
    );
    // columns = cols;

    const cols = columns.map((item, index) =>
      item.tooltip == "click" && !item.hasOwnProperty("children")
        ? {
            title: (
              <>
                <Modal
                  title={item.tooltipTitle}
                  visible={isSharePopoverVisible}
                  onOk={handleOk}
                  okText="Download ASC Search Term"
                  onCancel={handleCancel}
                >
                  <p>{item.value}</p>
                </Modal>
                {item.title}
                <Tooltip trigger="click" onClick={showModal}>
                  <span style={{ color: "#8dc441" }}>
                    <FaInfoCircle />
                  </span>
                </Tooltip>
              </>
            ),
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
             fixed: item.fixed != undefined ? item.fixed : (index == 0 ? "left" : ""),
            format: item.format,
            tooltip: item.tooltip,
            ...getColumnSearchProps(item.dataIndex, item.format),
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex,
                isSharePopoverVisible,
                setIsSharePopoverVisible,
                seriesData,
                setSeriesData,
                categoriesData,
                setCategoriesData,
                setAccountName,
                accountName,
                drillDownAPiPath,
                item,
                inputData,
                setLoading,
                props,
                formData,
                dispatch
              );
            },
          }
        : item.tooltip == "hover" && !item.hasOwnProperty("children")
        ? {
            title: (
              <>
                {item.title}
                <Tooltip trigger="hover" title={item.value}>
                  <span style={{ color: "#8dc441" }}>
                    <FaInfoCircle />
                  </span>
                </Tooltip>
              </>
            ),
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            format: item.format,
            tooltip: item.tooltip,
            ...getColumnSearchProps(item.dataIndex, item.format),
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex,
                isSharePopoverVisible,
                setIsSharePopoverVisible,
                seriesData,
                setSeriesData,
                categoriesData,
                setCategoriesData,
                setAccountName,
                accountName,
                drillDownAPiPath,
                item,
                inputData,
                setLoading,
                props,
                formData,
                dispatch
              );
            },
          }
        : {
            title: item.title,
            className: item.className,
            dataIndex: item.dataIndex,
            key: item.key,
            hidden: item.hidden,
            ...getColumnSearchProps(item.dataIndex, item.format),
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            format: item.format,
            render(text, record) {
              return displayRenderFunction(
                text,
                record,
                getGoalData,
                item.format,
                0,
                item.dataIndex,
                isSharePopoverVisible,
                setIsSharePopoverVisible,
                seriesData,
                setSeriesData,
                categoriesData,
                setCategoriesData,
                setAccountName,
                accountName,
                drillDownAPiPath,
                item,
                inputData,
                setLoading,
                props,
                formData,
                dispatch
              );
            },
          }
    );
    columns = cols;

    columns[5]["children"] = col5;
    columns[6]["children"] = col6;
  }

  return columns;
}

function displayRenderFunction(
  text,
  record,
  getGoalData,
  type,
  roundoff,
  dataIndex,
  isSharePopoverVisible,
  setIsSharePopoverVisible,
  seriesData,
  setSeriesData,
  categoriesData,
  setCategoriesData,
  setAccountName,
  accountName,
  drillDownAPiPath,
  item,
  inputData,
  setLoading,
  props,
  formData,
  dispatch
) {
  const {
    API_BI: { BASE_URL_FOR_BI_APP },
  } = Config;

  if (
    item != undefined &&
    item.hasOwnProperty("absolute_link") &&
    item["absolute_link"] != undefined
  ) {
    var link = item.absolute_link;
    const placeholder = extractPlaceholders(link);
    if (placeholder.length > 0) {
      var link = extractAndReplaceAbsoluteParams(link, record);
    }

    return (
      <a href={link} target="_blank">
        {text}
      </a>
    );
  }

  if (
    item != undefined &&
    item.hasOwnProperty("link") &&
    item["link"] != undefined
  ) {
    var fromDate = inputData["f_date"];
    var toDate = inputData["t_date"];
    var fromCompare = inputData["c_f_date"];
    var toCompare = inputData["c_t_date"];
    var accountId = inputData["account_id"];
    var link = item.link;
    var from_date = inputData["from_date"];
    var to_date = inputData["to_date"];

    const placeholder = extractPlaceholders(link);

    if (
      placeholder.includes("account_id") ||
      placeholder.includes("custom_id")
    ) {
      var link1 = extractAndReplaceAccountId(link, accountId, record);
    } else {
      var link1 = link;
    }

    const link2 = extractAndReplaceParams(link1, record);

    if (
      placeholder.includes("fromDate") &&
      placeholder.includes("toDate") &&
      placeholder.includes("fromCompare") &&
      placeholder.includes("toCompare")
    ) {
      var url = extractAndReplaceCompareDatePlaceholders(
        link2,
        fromDate,
        toDate,
        fromCompare,
        toCompare
      );
    } else if (
      placeholder.includes("fromDate") ||
      placeholder.includes("toDate")
    ) {
      var url = extractAndReplaceDatePlaceholders(link2, fromDate, toDate);
    } else if (
      placeholder.includes("from_date") ||
      placeholder.includes("to_date")
    ) {
      var url = extractAndReplaceFromAndToDatePlaceholders(
        link2,
        from_date,
        to_date
      );
    } else {
      var url = link2;
    }

    return (
      <a href={`${BASE_URL_FOR_BI_APP}${url}`} target="_blank">
        {text}
      </a>
    );
  }

  if (dataIndex == "insight" || dataIndex == "notes") {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  } else if (Object.keys(getGoalData).length > 0) {
    return {
      props: {
        className: formatColumns(text, record, getGoalData, dataIndex),
      },
      children: <div>{format_data(text, type, 0, dataIndex)}</div>,
    };
  } else if (record) {
    return (
      <>
        {" "}
        {
          <FormattedNumberCell
            value={text}
            format={item.format}
            metric={dataIndex}
          />
        }
      </>
    );
  }
  if (record["tooltip"] != undefined && record["tooltip"] != "" && text != "") {
    return (
      <span>
        {text}
        <Tooltip title={record["tooltip"]}>
          <span style={{ color: "#8dc441" }}>
            <FaInfoCircle />
          </span>
        </Tooltip>
      </span>
    );
  }
}
