export const initialState = {
  graph: {},
  isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
  GET_BAR_GRAPH: "GET_BAR_GRAPH",
  FETCH_BAR_GRAPH: "FETCH_BAR_GRAPH",
});

export function barGraphReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.FETCH_BAR_GRAPH:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.GET_BAR_GRAPH:
      return {
        ...state,
        isLoading: false,
        graph: { ...state.graph, ...action.payload },
      };
    default:
      return state;
  }
}
