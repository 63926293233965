import { ACTIONS_TYPES } from "../../../Reducers/datasources/GoogleAdsReducer";

import * as GoogleAdsDataSourceApi from "../../../../Api/datasources/googleAdsApi";
import errorHandler from "../../../../Utils/Request/errorHandler";
import successHandler from "../../../../Utils/Request/successHandler";
import * as googleAdsAction from "../../datasources/googleAdsAction";
import { setAlert } from "../../globals/alertAction";
import { setLoadingFalse, setLoadingTrue } from "../../globals/loadingThunk";

/**
 * Get single google ads   data sources
 * @returns {Function} dispatch
 */
export const getAllGoogleAdsParamDataSourcesThunk =
  (props) => async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({ type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST });
      dispatch(setLoadingTrue());

      // Fetch data from API
      const response = await GoogleAdsDataSourceApi.getAllGoogleAdsParamApi(
        props
      );
      const data = response?.data?.data?.data;


      dispatch(googleAdsAction.getAllGoogleAdsDataSourcesAction(data));
      // successHandler(response, { notifyOnSuccess: true, notifyOnFailed: true });
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      console.error("Error in fetching account ds: ", error);
      errorHandler(error);
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));
      // dispatch(setAlert({ message: error?.response?.data?.error?.message, type: "error" }));
    } finally {
      dispatch(setLoadingFalse());
    }
  };

/**
 * Get single google ads   data sources
 * @returns {Function} dispatch
 */
export const getGoogleAdsParamDataSourcesThunk =
  (props) => async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({ type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST });
      dispatch(setLoadingTrue());

      // Fetch data from API
      const response = await GoogleAdsDataSourceApi.getGoogleAdsParamApi(props);
      const data = response?.data?.data?.data;

      dispatch(googleAdsAction.getGoogleAdsDataSourcesAction(data));
      // successHandler(response, { notifyOnSuccess: true, notifyOnFailed: true });
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      console.error("Error in fetching account ds: ", error);
      errorHandler(error);
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));
      // dispatch(setAlert({ message: error?.response?.data?.error?.message, type: "error" }));
    } finally {
      dispatch(setLoadingFalse());
    }
  };

/**
 * Create a new Google Ads Service account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const createGoogleAdsParamDataSourcesThunk = (props, form) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      // Call the API to create a new data source
      const response = await GoogleAdsDataSourceApi.createGoogleAdsParamApi(
        props
      );
      // Notify user of success
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      form.resetFields();

      // Dispatch success action
      // dispatcuh(
      //   googleAdsAction.createGoogleAdsDataSourcesAction({
      //     ...response?.data?.data,
      //   })
      // );
    } catch (error) {
      // Dispatch failure action with error message
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));

      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
      dispatch(getAllGoogleAdsParamDataSourcesThunk({}));
    }
  };
};

/**
 * Update an Google Ads Service data source
 * @param {object} props - The payload to pass to the API
 * @returns {Function} - A Redux thunk function that dispatches actions to update the data source and handle errors
 */
export const updateGoogleAdsParamDataSourcesThunk = (props, history) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      const response = await GoogleAdsDataSourceApi.updateGoogleAdsParamApi(
        props
      );

      // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      history.push("/google-ads-configuration");
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));
      dispatch(setAlert({ message: errMessage, type: "error" }));
      return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
      dispatch(getAllGoogleAdsParamDataSourcesThunk({}));
    }
  };
};

/**
 * Get Google Ads Service account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const getSingleGoogleAdsClientDataSourcesThunk = (props) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST,
      });
      dispatch({
        type: ACTIONS_TYPES.RESET_GOOGLE_ADS_DS,
      });
      dispatch(setLoadingTrue());
      // Call the API to create a new data source
      const response = await GoogleAdsDataSourceApi.getGoogleAdsClientApi(
        props
      );

      // Dispatch success action
      dispatch(
        googleAdsAction.getGoogleAdsDataSourcesAction({
          ...response?.data?.data,
        })
      );
    } catch (error) {
      // Dispatch failure action with error message
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));

      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      // return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
    }
  };
};

/**
 * Create a new Google Ads Service account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const createGoogleAdsClientDataSourcesThunk = (props) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      // Call the API to create a new data source
      const response = await GoogleAdsDataSourceApi.createGoogleAdsClientApi(
        props
      );

      // Notify user of success
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });

      // Dispatch success action
      dispatch(
        googleAdsAction.createGoogleAdsDataSourcesAction({
          ...response?.data?.data,
        })
      );
    } catch (error) {
      // Dispatch failure action with error message
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));

      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
    }
  };
};

/**
 * Update an Google Ads Service data source
 * @param {object} props - The payload to pass to the API
 * @returns {Function} - A Redux thunk function that dispatches actions to update the data source and handle errors
 */
export const updateGoogleAdsClientSourcesThunk = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST,
      });
      dispatch(setLoadingTrue());
      delete props.starTableData;
      const response = await GoogleAdsDataSourceApi.updateGoogleAdsClientApi(
        props
      );
      // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });

      dispatch(
        googleAdsAction.updateGoogleAdsDataSourcesAction({
          ...response?.data?.data,
        })
      );
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));
      dispatch(setAlert({ message: errMessage, type: "error" }));
      return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
};

/**
 * Get Google Ads Service account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const getSingleGoogleAdsDateRangeDataSourcesThunk = (props) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST,
      });
      dispatch(setLoadingTrue());
      // Call the API to create a new data source
      const response = await GoogleAdsDataSourceApi.getGoogleAdsDsRangeApi(
        props
      );
      // Notify user of success
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });

      // Dispatch success action
      dispatch(
        googleAdsAction.getGoogleAdsDateRangeDataSourcesAction({
          ...response?.data?.data,
        })
      );
    } catch (error) {
      // Dispatch failure action with error message
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));

      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
    }
  };
};

/**
 * Get Google Ads Service account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const postSingleGoogleAdsDateRangeDataSourcesThunk = (props) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST,
      });
      dispatch(setLoadingTrue());
      // Call the API to create a new data source
      const response = await GoogleAdsDataSourceApi.postGoogleAdsDsRangeApi(
        props
      );
      // Notify user of success
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
    } catch (error) {
      // Dispatch failure action with error message
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleAdsAction.failedGoogleAdsDataSourcesAction(errMessage));

      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
    }
  };
};
