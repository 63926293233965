import { ACTIONS_TYPES } from "../../../Reducers/datasources/amazonMarketingServiceReducer";

import * as AmazonMarketingServiceDataSourcesApi from "../../../../Api/datasources/amazonMarketingServiceApi";
import errorHandler from "../../../../Utils/Request/errorHandler";
import successHandler from "../../../../Utils/Request/successHandler";
import * as amazonMarketingServiceAction from "../../datasources/amazonMarketingServiceAction";
import { setLoadingFalse, setLoadingTrue } from "../../globals/loadingThunk";
/**
 * Get current amazon marketing service  data sources
 * @returns {Function} dispatch
 */
export const getAmazonMarketingServiceAccountDataSources =
  (props) => async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.RESET_AMAZON_MARKETING_SERVICE_ACCOUNT_DS,
      });

      dispatch({
        type: ACTIONS_TYPES.AMAZON_MARKETING_SERVICE_ACCOUNT_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      // Fetch data from API
      const response =
        await AmazonMarketingServiceDataSourcesApi.getAccountDataSourcesData(
          props
        );
      const data = response?.data?.data;

      dispatch(
        amazonMarketingServiceAction.getAmazonMarketingServiceDataSource(data)
      );
      // successHandler(response, { notifyOnSuccess: true, notifyOnFailed: true });
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      console.error("Error in fetching account ds: ", error);
      // errorHandler(error);
      dispatch(
        amazonMarketingServiceAction.failedAmazonMarketingServiceDataSource(
          errMessage
        )
      );
      dispatch({
        type: ACTIONS_TYPES.RESET_AMAZON_MARKETING_SERVICE_ACCOUNT_DS,
      });

      // dispatch(setAlert({ message: error?.response?.data?.error?.message, type: "error" }));
    } finally {
      dispatch(setLoadingFalse());
    }
  };

/**
 * Create a new Amazon Marketing Service account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const createAmazonMarketingServiceDataSource = (props) => {
  return async (dispatch) => {
    // Extract invalid marketplaces from props
    // const invalidMarketPlace = [...props.invalidMarketPlace];

    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.AMAZON_MARKETING_SERVICE_ACCOUNT_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      // Call the API to create a new data source
      const response =
        await AmazonMarketingServiceDataSourcesApi.createAccountDataSourcesData(
          props
        );

      // // Notify user of success
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: false,
      });

      // Dispatch success action
      dispatch(
        amazonMarketingServiceAction.createAmazonMarketingServiceDataSource({
          ...response?.data?.data,
        })
      );
    } catch (error) {
      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
    }
  };
};

/**
 * Update an Amazon Marketing Service data source
 * @param {object} props - The payload to pass to the API
 * @returns {Function} - A Redux thunk function that dispatches actions to update the data source and handle errors
 */
export const updateAmazonMarketingServiceDataSource = (props) => {
  const { star_table_data } = props;
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.AMAZON_MARKETING_SERVICE_ACCOUNT_DS_REQUEST,
      });
      dispatch(setLoadingTrue());
      const response =
        await AmazonMarketingServiceDataSourcesApi.updateAccountDataSourcesData(
          props
        );

      // // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: false,
      });

      dispatch(
        amazonMarketingServiceAction.updateAmazonMarketingServiceDataSource({
          ...response?.data?.data,
        })
      );

      alert("thisis awoms");
    } catch (error) {
      // return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
      dispatch(getAmazonMarketingServiceAccountDataSources(star_table_data));
    }
  };
};
