// Importing the amazonRegion constant from a configuration file
import { amazonRegion } from "../../../Config/datasources/amazon/region";

// Initializing the initial state of the reducer
export const initialState = {
  accounts_ds: {
    [amazonRegion.NA]: {},  // Initializing North America region with empty object
    [amazonRegion.EU]: {},  // Initializing Europe region with empty object
    [amazonRegion.FE]: {},  // Initializing Far East region with empty object
  },
  error_ds: {},  // Initializing error_ds with null
  isLoading: false,  // Initializing isLoading with false
};

// Defining the action types for the reducer
export const ACTIONS_TYPES = Object.freeze({
  AMAZON_SPONSOR_ACCOUNT_DS_REQUEST: "AMAZON_SPONSOR_ACCOUNT_DS_REQUEST",
  GET_AMAZON_SPONSOR_ACCOUNT_DS: "GET_AMAZON_SPONSOR_ACCOUNT_DS",
  CREATE_AMAZON_SPONSOR_ACCOUNT_DS: "CREATE_AMAZON_SPONSOR_ACCOUNT_DS",
  UPDATE_AMAZON_SPONSOR_ACCOUNT_DS: "UPDATE_AMAZON_SPONSOR_ACCOUNT_DS",
  AMAZON_SPONSOR_ERROR_DS: "AMAZON_SPONSOR_ERROR_DS",
  UPDATE_DATA_SOURCE_MODEL: "UPDATE_DATA_SOURCE_MODEL",
  FAILED_AMAZON_SPONSOR_ACCOUNT_DS: "FAILED_AMAZON_SPONSOR_ACCOUNT_DS",
  RESET_AMAZON_SPONSOR_ACCOUNT_DS: "RESET_AMAZON_SPONSOR_ACCOUNT_DS",
});


// Defining the reducer function
export function amazonSponsorAdsReducer(state = initialState, action) {
  const { type, payload } = action;  // Destructuring the action object to get type and payload

  switch (type) {
    case ACTIONS_TYPES.AMAZON_SPONSOR_ACCOUNT_DS_REQUEST:
      // Setting isLoading to true when requesting account data sources
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_AMAZON_SPONSOR_ACCOUNT_DS:
    case ACTIONS_TYPES.CREATE_AMAZON_SPONSOR_ACCOUNT_DS:
    case ACTIONS_TYPES.UPDATE_AMAZON_SPONSOR_ACCOUNT_DS:
      // Extracting region and remaining payload from payload object or assigning default values
      const { region, ...remainingPayload } = payload || {};
      
      // Updating account data sources with the payload data
      return {
        ...state,
        isLoading: false,
        accounts_ds: {
          ...state.accounts_ds,
          [region || amazonRegion.NA]: {  // Using NA as default region if region is not defined
            ...remainingPayload,
          },
        },
      };

    case ACTIONS_TYPES.AMAZON_SPONSOR_ERROR_DS:
    case ACTIONS_TYPES.FAILED_AMAZON_SPONSOR_ACCOUNT_DS:
      // Setting error_ds when an error occurs
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };
    case ACTIONS_TYPES.RESET_AMAZON_SPONSOR_ACCOUNT_DS:
      // Resetting the state to initial state when resetting account data sources
      return initialState;

    default:
      return state;  // Returning the initial or default state if no action type matches
  }
}
