/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const UsageAllUsers = (props) => {
  const columnIds = ["login_status"];
  let cellClass = "";
  let eparams = props?.extraParams?.all_input_data;
  let t_month = eparams?.t_month;
  let f_month_compare = eparams?.c_f_month;

  if (props?.column?.dataIndex == "name") {
    return (
      <>
        <Link
          to={
            "/reports/v2/view/usage_individual_user/" +
            "?bi_user=" +
            props?.record?.name +
            "&f_date=" +
            f_month_compare +
            "&t_date=" +
            t_month
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
              metric={props?.column?.dataIndex}
            />
          </span>
        </Link>
      </>
    );
  } else {
    return (
      <span className={cellClass}>
        <FormattedNumberCell value={props.value} format={props.column.format} />
      </span>
    );
  }
};

export default UsageAllUsers;
