import { ACTIONS_TYPES } from "../../Reducers/datasources/amazonMarketingServiceReducer";

export function getAmazonMarketingServiceDataSource(payload) {
  return {
    type: ACTIONS_TYPES.GET_AMAZON_MARKETING_SERVICE_ACCOUNT_DS,
    payload,
  };
}

export function createAmazonMarketingServiceDataSource(payload) {
  return {
    type: ACTIONS_TYPES.CREATE_AMAZON_MARKETING_SERVICE_ACCOUNT_DS,
    payload,
  };
}

export function updateAmazonMarketingServiceDataSource(payload) {
  return {
    type: ACTIONS_TYPES.UPDATE_AMAZON_MARKETING_SERVICE_ACCOUNT_DS,
    payload,
  };
}

export function failedAmazonMarketingServiceDataSource(payload) {
  return {
    type: ACTIONS_TYPES.FAILED_AMAZON_MARKETING_SERVICE_ACCOUNT_DS,
    payload,
  };
};