import React, { useEffect, useRef, useState } from "react";
import { Form, Spin, BackTop } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Config from "../../Config";
import {
  getClientId,
  getFreemiumClientAccount,
  getFreemiumClientId,
} from "../../Redux/Actions/Thunk/accountThunk";
import FreemiumComponent from "./FreemiumLayouts/FreemiumComponent";

import { FaAngleUp } from "react-icons/fa";

import bg from "../../Assets/Images/agbg.png";
import logo from "../../Assets/Images/aglogo.png";

import axios from "axios";
import { Token } from "../../Utils/helpers/token";
const {
  SSO_LOGIN: { api_base_url },
} = Config;

const AgitalFreemiumLayout = (props) => {
  // const { getPerformance } = useContext(MyContext);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helpData, setHelpData] = useState("");
  const { account_id, report_key } = useParams();
  const [tokenData, setTokenData] = useState(null);

  const query = new URLSearchParams(props.location.search);

  const run_count = query.get("report_count");

  // const pathArray = window?.location?.pathname?.split("/");
  const [data, setData] = useState([]);
  // var report_name = pathArray[4];
  // var account_id_data = pathArray[5];
  let report_name =
    report_key === "organic_search_scorecard"
      ? "organic_search_scorecard_v2"
      : report_key;

  var account_id_data = account_id;
  var setValue = useRef(null);
  const {
    accounts: {
      client_id: { data: clientData = [] },
      accounts: { data: freemiumAcccount = [] },
      isLoading,
    },
    reportLayouts: {
      input_controls: { data_input_controls: inputControlsData = [] },
      layouts: {
        data: layoutData = [],
        data_layout_controls: dataLayout = [],
        layout_controls: controlLayout = [],
        layout_css: custom_report_css = [],
        // input_controls: inputControlsData = [],
      },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  // Fetch data
  let defaultFilterData = {};

  const fetchData = () => {
    const data = {};

    const config = {
      method: "post",
      SSO_LOGIN: { api_base_url },
      url: api_base_url + "get-access-token",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // Handle successful response
        setTokenData(response?.data?.access_token); // Set response data in state if needed
      })
      .catch(function (error) {
        // Handle error
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    Promise.all([
      dispatch(
        getFreemiumClientId({
          account_id: account_id_data,
          report_key: report_name,
        })
      ),
      dispatch(getFreemiumClientAccount({ account_id: account_id_data })),
    ]);
  }, [account_id_data, report_name]);

  inputControlsData.forEach((element, i) => {
    defaultFilterData["authentication"] = Token.encryptAuthParams(tokenData);
    defaultFilterData["Client"] = freemiumAcccount[0].id;
    defaultFilterData["account_id"] = account_id_data;
    defaultFilterData["company_name"] = freemiumAcccount[0].company;
    defaultFilterData["report_date"] = freemiumAcccount[0].created_at;
    defaultFilterData["report_count"] =
      run_count ?? freemiumAcccount[0].run_count;
    defaultFilterData["by_pass_cache"] = "yes";
  });

  var formDatafilter = "";

  let filterData = {};
  if (Object.keys(data).length > 0) {
    var filteredData = data;
  } else {
    var filteredData = defaultFilterData;
  }

  const cstyle = {
    padding: "8px 0",
  };

  const blockLookup = new Map();
  for (let i = 0; i < dataLayout.length; i++) {
    const { block_id, component, ...rest } = dataLayout[i];
    blockLookup.set(`${block_id}_${component}`, {
      block_id,
      component,
      ...rest,
    });
  }

  const new_rows = [];
  for (let i = 0; i < controlLayout.length; i++) {
    const { cols, ...rest } = controlLayout[i];
    const new_cols = [];
    for (let j = 0; j < cols.length; j++) {
      const { block_id, component, cols: innerCols, ...colRest } = cols[j];
      /**
       *  inner column logic.
       */

      const inner_cols = [];

      if (innerCols) {
        for (let k = 0; k < innerCols.length; k++) {
          const { block_id, component, ...colRest } = innerCols[k];

          inner_cols.push({
            block: {
              ...colRest,
              ...(blockLookup.get(`${block_id}_${component}`) || {}),
            },
          });
        }
      }

      new_cols.push({
        ...colRest,
        key: blockLookup.get(`${block_id}_${component}`) || component,
        cols: inner_cols,
      });
    }
    new_rows.push({ ...rest, cols: new_cols });
  }

  return (
    <>
      {layoutData.access_status == 403 ? (
        <Redirect to="/access-denied" />
      ) : (
        <></>
      )}
      {layoutData.access_status == undefined ? (
        <></>
      ) : (
        <>
          <style dangerouslySetInnerHTML={{ __html: custom_report_css }} />
          <Spin spinning={isLoading}>
            {/* block content starts here */}
            <div id="divToPrint">
              <div className="gads-wrapper">
                <div className="gads-bg">
                  <div className="gads-bg-wallpaper">
                    <img src={bg} alt="" />
                  </div>
                  <div className="gads-bg-logo">
                    <img src={logo} alt="" />
                  </div>
                </div>

                <div className="gads-content-wrapper">
                  <div className="gads-content">
                    {new_rows.map((row) => (
                      <div key={row.id}>
                        {row.cols.map((col) => (
                          <div key={col.id}>
                            {FreemiumComponent(
                              col.key,
                              account_id_data,
                              clientData,
                              filteredData,
                              col.cols
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="gads-footer">
                  <p>
                    Copyright &#169;{new Date().getFullYear()}. All rights
                    reserved.
                  </p>
                  <p>
                    <a href="https://agital.com/" target="_blank">
                      AGITAL{" "}
                    </a>{" "}
                    | One Wall Street 5th Floor, Burlington, MA 01803 |{" "}
                    <a href="https://agital.com/contact/" target="_blank">
                      Contact Us
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Spin>
          <BackTop>
            <div>
              <FaAngleUp />
            </div>
          </BackTop>
        </>
      )}
    </>
  );
};

export default AgitalFreemiumLayout;
