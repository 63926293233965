/**
 * @fileoverview Reducer for Google Console Config
 *
 * The reducer handles the state changes for the Google Console Config.
 */

// Define the initial state of the reducer
export const initialState = {
  accounts_mcc: {}, // account data sources
  account_mccs:{},
  error_ds: {}, // error data sources
  isLoading: false, // loading state
};

// Define action types to be used in the reducer
export const ACTIONS_TYPES = Object.freeze({
  GOOGLE_CONSOLE_MCC_REQUEST: "GOOGLE_CONSOLE_MCC_REQUEST",
  GOOGLE_CONSOLE_MCC: "GOOGLE_CONSOLE_MCC", // Fetch Account Data Sources
  GOOGLE_CONSOLE_ALL_MCC: "GOOGLE_ALL_CONSOLE_MCC", // Fetch Account Data Sources
  FAILED_GOOGLE_CONSOLE_MCC: "FAILED_GOOGLE_CONSOLE_MCC",
  RESET_GOOGLE_CONSOLE_MCC: "RESET_GOOGLE_CONSOLE_MCC",
});

// Define the reducer function
export function googleConsoleMCCReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  // Switch case to handle different types of actions
  switch (type) {
    case ACTIONS_TYPES.GOOGLE_CONSOLE_MCC_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GOOGLE_CONSOLE_MCC:
      return {
        ...state,
        isLoading: false,
        accounts_mcc: {
          ...state.accounts_mcc,
          ...payload,
        },
      };
    case ACTIONS_TYPES.GOOGLE_CONSOLE_ALL_MCC:
      return {
        ...state,
        isLoading: false,
        account_mccs: action.payload,
      };

    case ACTIONS_TYPES.RESET_GOOGLE_CONSOLE_MCC:
      return {
        ...state,
        isLoading: false,
        accounts_mcc: {},
      };

    case ACTIONS_TYPES.FAILED_GOOGLE_CONSOLE_MCC:
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };

    // Return the initial state if no matching action is found
    default:
      return state;
  }
}
