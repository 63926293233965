import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaPencilAlt } from "react-icons/fa";
import { Tooltip } from "antd";
import moment from "moment";

const ServiceCell = (props) => {
  var accountId = props?.record?.custom_id;
  var month = moment().format("YYYY-MM");
  return (
    <>
      {props.value != null && (
        <>
          <Link
            to={"/reports/v2/view/amazon_marketing_performance/" + accountId}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedNumberCell
              value={props.value}
              format={props.column.format}
            />
          </Link>
          &nbsp; &nbsp;
          <Link
            to={
              "/account/detail/" +
              accountId +
              "?account_id=" +
              accountId +
              "&service_id=14&month=" +
              month
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaPencilAlt />
          </Link>
        </>
      )}
    </>
  );
};

function endsWithDigit(value, digit) {
  return value.toString().endsWith(digit.toString());
}

const AmazonMarketingDailySpend = (props) => {
  let record_data = props?.record;
  let children_data = props?.record?.children;
  const columnIds = ["name"];
  const specialistColumn = ["specialistname"];
  const source = ["source"]

  if (columnIds.includes(props.column.dataIndex)) {
    return <ServiceCell {...props} />;
  } else if (specialistColumn.includes(props.column.dataIndex)) {
    return (
      <>
        {props.value != null && (
          <>
            <Link
              to={"/reports/v2/view/account_manager_dashboard?specialist=" + props?.record?.specialist_id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </Link>
          </>
        )}
      </>
    );
  } else if (source.includes(props.column.dataIndex)) {
    return (
      <>
        {props.value != null && (
          <>
            <a
              href="https://ams.amazon.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </a>
          </>
        )}
      </>
    );
  } else if (props?.value == "No Data" || props?.value == "Critical") {
    return (
      <>
        <span style={{ color: "red" }}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </>
    );
  } else {
    return (
      <>
        <span>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </>
    );
  }
};

export default AmazonMarketingDailySpend;
