import axios from "axios";
import { amazonProfileApi } from "../../Api/datasources/amazon/amazonProfile";
import { amazonSponsorAdsConfig } from "../../Config/datasources";
import { amazonRegion } from "../../Config/datasources/amazon/region";
import { Token } from "../helpers/token";
const amazonMarkeingServiceApiCall = async (props) => {
  const { queryCode } = props;

  // *Get url from amazon according to region
  let url = await amazonSponsorAdsConfig.amazonTokenAccess(
    amazonRegion["NA"],
    queryCode
  );
  // *Get tokens from amazon
  const { data: tokens } = await axios.post(url);

  // *Get user profile from amazon

  const { data } = await amazonProfileApi({
    client_id: amazonSponsorAdsConfig.amazonClientId,
    access_token: tokens.access_token,
  });
  let profile;
  if (Array.isArray(data.data.data)) {
    profile = data.data.data;
  }


  return {
    tokens,
    profile,
  };
};

export default amazonMarkeingServiceApiCall;
