import * as AreaChartApi from "../../../Api/AreaChartApi";
import * as AreaChartAction from "../AreaChartAction";
import { ACTIONS_TYPES } from "../../Reducers/AreaChartReducer";

export const getAreaChartData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_AREA_CHART_DATA,
      });
      let areaChartData = {};
      const response = await AreaChartApi.getAreaChartData(props, data);
      if (response?.status === 200) {
        areaChartData = {
          [data.block.block_id]: {
            series_data: response?.data?.series_data,
            categories: response?.data?.categories,
            insights: response?.data?.insight_data,
            tab_options: response?.data?.tab_options,
          },
        };
      }
      return dispatch(AreaChartAction.getAreaChartData(areaChartData));
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
