import React, { useEffect, useState } from "react";
import {
  Input,
  Table,
  Button,
  Modal,
  Select,
  Breadcrumb,
  Tag,
  Switch,
  Badge,
  Row,
  Col,
  Tooltip,
  Checkbox,
  notification,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAccountNotifications } from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiUsers, FiSettings, FiMail } from "react-icons/fi";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
const {
  API_BI: { baseURL },
} = config;

const { confirm } = Modal;

const { Search } = Input;
const { Option } = Select;

function AccountNotifications(props) {
  let { account_id } = useParams();
  const [accountId, setAccountId] = useState([]);

  const {
    accounts: {
      account_notifications: accountNotifications = [],
      total,
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const datasource = accountNotifications.data;

  // Fetch data
  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: total,
      },
    });
    dispatch(getAccountNotifications({ account_id }));
  }, []);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const handleStatusChange = (notification_id, checked_status) => {
    let status = checked_status ? 1 : 0;
    let params = {
      account_id: account_id,
      notification_id: notification_id,
      status: status,
    };
    dispatch({
      type: ACTIONS_TYPES.API_REQUEST_INIT,
    });
    API_BI.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    API_BI.post(
      baseURL + "api/v1/update-notification-subscribed-status",
      params
    ).then((response) => {
      if (response?.status == 200) {
        message.success(response.data?.message);
      } else {
        message.error(response.data?.message);
      }
      dispatch(getAccountNotifications({ account_id }));
    });
  };

  const handleEmailStatusChange = (notification_id, checked_status) => {
    let status = checked_status ? 1 : 0;
    let params = {
      account_id: account_id,
      notification_id: notification_id,
      status: status,
    };
    dispatch({
      type: ACTIONS_TYPES.API_REQUEST_INIT,
    });
    API_BI.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    API_BI.post(
      baseURL + "api/v1/update-notification-alert-status",
      params
    ).then((response) => {
      if (response?.status == 200) {
        message.success(response.data?.message);
      } else {
        message.error(response.data?.message);
      }
      dispatch(getAccountNotifications({ account_id }));
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    const limit = pagination.pageSize;
    let params = `page=${pagination?.current}`;
    setTableParams({
      pagination,
    });

    if (sorter.hasOwnProperty("column")) {
      const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
      dispatch(
        getAccountNotifications(
          `&sort_column=${sorter.field}&sort_order=${sortOrder}`,
          { account_id }
        )
      );
    }

    dispatch(
      getAccountNotifications(`${params}&limit=${limit}`, { account_id })
    );
  };

  const columns = [
    {
      title: "Subscribed",
      dataIndex: "is_subscribed",
      key: "is_subscribed",
      sorter: true,
      render: (text, record) => {
        var is_mandatory = record.is_mandatory;
        return (
          <>
            <div className="account-notification-sub">
              {text == 1 && is_mandatory == 1 ? (
                <Checkbox defaultChecked disabled />
              ) : text == 1 ? (
                <Checkbox
                  defaultChecked
                  onChange={(e) =>
                    handleStatusChange(record.id, e.target.checked)
                  }
                />
              ) : (
                <Checkbox
                  onChange={(e) =>
                    handleStatusChange(record.id, e.target.checked)
                  }
                />
              )}
              {text == 1 && is_mandatory == 1 ? (
                <Tooltip
                  placement="right"
                  title="A mandatory notification cannot be unsubscribed"
                >
                  <Tag color="#87d068">Subscribed</Tag>
                </Tooltip>
              ) : text == 1 ? (
                <Tag color="#87d068">Subscribed</Tag>
              ) : (
                <Tag color="#f50">Not Subscribed</Tag>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Notification",
      dataIndex: "notification",
      key: "notification",
      sorter: true,
      render: (text, help) => {
        return (
          <>
            <Tooltip placement="right" title={help.help_text}>
              {text}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            {text == "Information" ? (
              <Tooltip placement="right" title="Information">
                <Badge color="blue" size="large" />
              </Tooltip>
            ) : text == "Warning" ? (
              <Tooltip placement="right" title="Warning">
                <Badge color="yellow" />
              </Tooltip>
            ) : (
              <Tooltip placement="right" title="Critical">
                <Badge color="red" />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      sorter: false,
    },
    {
      title: "Email",
      dataIndex: "send_alert",
      key: "send_alert",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            {text == 1 ? (
              <Switch
                defaultChecked
                onChange={(checked) =>
                  handleEmailStatusChange(record.id, checked)
                }
              />
            ) : (
              <Switch
                onChange={(checked) =>
                  handleEmailStatusChange(record.id, checked)
                }
              />
            )}
          </>
        );
      },
    },
  ];
  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Accounts", link: "/accounts", icon: FiUsers },
    {
      text: accountNotifications.account_name,
      link: "/account/detail/" + account_id,
      icon: FiSettings,
    },
    {
      text: "Account Notifications",
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="panel-heading">
        <Row>
          <Col span={24} className="panel-heading-title">
            <h2>Account Notifications - {accountNotifications.account_name}</h2>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <div className="panel-body-content panel-body-content-acc-notification">
          <Table
            columns={columns}
            key={(record) => record.primaryKey}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={datasource}
            loading={isLoading}
            onChange={handleChange}
            pagination={tableParams.pagination}
          />
        </div>
      </div>
    </>
  );
}

export default AccountNotifications;
