import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	useHistory,
} from "react-router-dom";
import PublicRoute from "./PublicRoute";
import Login from "../Pages/Auth/Login";
import NotFound from "../Pages/Layout/NotFound";
import GadsRedirect from "../Pages/Layout/GadsRedirect";
import GadsSignup from "../Pages/Layout/GadsSignup";

export default function LoginNotRequired(props) {
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	let customers = useState("")
	return (
		<>
			<Switch location={location} key={location.pathname}>
				<Route
					exact
					path="/freemium/adwords-signup"
					component={GadsSignup}
				/>
				<Route
					exact
					path="/api/v2/freemium/google-redirect"
					component={GadsRedirect}
				/>
				<PublicRoute path="/" component={Login} />
			</Switch>
		</>
	);
}
