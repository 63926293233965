export const initialState = {
  keyMetrics: [],
  isLoading: false,
  labels: [],
  insights: [],
  key_metrics: {},
  performance_metrics: {},
};

export const ACTIONS_TYPES = Object.freeze({
  REQUEST_KEY_METRICS: "REQUEST_KEY_METRICS",
  FETCH_KEY_METRICS: "FETCH_KEY_METRICS",
  FETCH_PERFORMACE_METRICS: "FETCH_PERFORMACE_METRICS",
  RESET_KEY_METRICS: "RESET_KEY_METRICS",
  FETCH_KEY_METRICS_SEO_CARD: "FETCH_KEY_METRICS_SEO_CARD",
});

export function reportReducer(state = initialState, action) {
  const { key, payload } = action;
  switch (action.type) {
    // case ACTIONS_TYPES.REQUEST_KEY_METRICS1:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // case ACTIONS_TYPES.RESET_KEY_METRICS1:
    //   return {
    //     keyMetrics: [],
    //     labels: [],
    //     insights: [],
    //     key_metrics: [],
    //     performance_metrics: {},
    //     isLoading: true,
    //   };
    case ACTIONS_TYPES.RESET_KEY_METRICS:
      return {
        ...state,
        key_metrics: {
          ...state.key_metrics,
          [payload]: {},
        },
      };
    case ACTIONS_TYPES.REQUEST_KEY_METRICS:
      return {
        ...state,
        key_metrics: {
          ...state.key_metrics,
          [payload]: {
            ...state.key_metrics[payload],
            isLoading: true,
          },
        },
      };
    case ACTIONS_TYPES.FETCH_KEY_METRICS:
      return {
        ...state,
        key_metrics: {
          ...state.key_metrics,
          [key]: {
            ...state.key_metrics[key],
            isLoading: false,
            ...payload,
          },
        },
      };
    case ACTIONS_TYPES.FETCH_KEY_METRICS_SEO_CARD:
      return {
        ...state,
        isLoading: false,
        key_metrics: { ...state.key_metrics, ...action.payload },
      };
    case ACTIONS_TYPES.FETCH_PERFORMACE_METRICS:
      return {
        ...state,
        isLoading: false,
        performance_metrics: action.payload,
      };
    default:
      return state;
  }
}
