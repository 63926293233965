import { ACTIONS_TYPES } from "../../../../Reducers/datasources/ds_config/googleConsoleReducer";
import * as GoogleConsoleApi from "../../../../../Api/datasources/dsConfig/googleConsoleMCCApi";
import errorHandler from "../../../../../Utils/Request/errorHandler";
import successHandler from "../../../../../Utils/Request/successHandler";
import * as googleConsoleMCCAction from "../../../datasources/dsConfig/dsGoogleConsoleThunk";
import { setAlert } from "../../../globals/alertAction";
import { setLoadingFalse, setLoadingTrue } from "../../../globals/loadingThunk";

/**
 * Get single Google Console MCC
 * @returns {Function} dispatch
 */
export const getAllGoogleConsoleDataSourcesThunk =
  (props) => async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({ type: ACTIONS_TYPES.GOOGLE_CONSOLE_MCC_REQUEST });
      dispatch(setLoadingTrue());

      // Fetch data from API
      const response = await GoogleConsoleApi.getAllGoogleConsoleParamApi(
        props
      );
      const data = response?.data?.data?.data;
      dispatch(googleConsoleMCCAction.getAllGoogleConsoleMCCAction(data));
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      console.error("Error in fetching account ds: ", error);
      errorHandler(error);
      dispatch(googleConsoleMCCAction.failedGoogleConsoleMCCAction(errMessage));
      // dispatch(setAlert({ message: error?.response?.data?.error?.message, type: "error" }));
    } finally {
      dispatch(setLoadingFalse());
    }
  };

/**
 * Get single google ads   data sources
 * @returns {Function} dispatch
 */
export const getGoogleConsoleDataSourcesThunk = (props) => async (dispatch) => {
  try {
    // Dispatch request action and set loading state to true
    dispatch({ type: ACTIONS_TYPES.GOOGLE_CONSOLE_MCC_REQUEST });
    dispatch(setLoadingTrue());

    // Fetch data from API
    const response = await GoogleConsoleApi.getGoogleConsoleParamApi(props);
    const data = response?.data?.data?.data;

    dispatch(googleConsoleMCCAction.getGoogleConsoleMCCAction(data));
    // successHandler(response, { notifyOnSuccess: true, notifyOnFailed: true });
  } catch (error) {
    let errMessage = error?.response?.data?.error?.message;
    console.error("Error in fetching account ds: ", error);
    errorHandler(error);
    dispatch(googleConsoleMCCAction.failedGoogleConsoleMCCAction(errMessage));
    // dispatch(setAlert({ message: error?.response?.data?.error?.message, type: "error" }));
  } finally {
    dispatch(setLoadingFalse());
  }
};

/**
 * Create a new Google Ads Service account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const createGoogleConsoleDataSourcesThunk = (props, form) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_CONSOLE_MCC_REQUEST,
      });
      dispatch(setLoadingTrue());

      // Call the API to create a new data source
      const response = await GoogleConsoleApi.createGoogleConsoleParamApi(
        props
      );
      // Notify user of success
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      form.resetFields();
    } catch (error) {
      // Dispatch failure action with error message
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleConsoleMCCAction.failedGoogleConsoleMCCAction(errMessage));

      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
      dispatch(getAllGoogleConsoleDataSourcesThunk({}));
    }
  };
};

/**
 * Update an Google Ads Service data source
 * @param {object} props - The payload to pass to the API
 * @returns {Function} - A Redux thunk function that dispatches actions to update the data source and handle errors
 */
export const updateGoogleConsoleDataSourcesThunk = (props, history) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_CONSOLE_MCC_REQUEST,
      });
      dispatch(setLoadingTrue());

      const response = await GoogleConsoleApi.updateGoogleConsoleParamApi(
        props
      );

      // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      history.push("/data-source/google-console-configuration");
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleConsoleMCCAction.failedGoogleConsoleMCCAction(errMessage));
      dispatch(setAlert({ message: errMessage, type: "error" }));
      return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
      dispatch(getAllGoogleConsoleDataSourcesThunk({}));
    }
  };
};

/**
 * Update an Google Ads Service data source
 * @param {object} props - The payload to pass to the API
 * @returns {Function} - A Redux thunk function that dispatches actions to update the data source and handle errors
 */
export const deleteGoogleConsoleDataSourcesThunk = (props) => {
  alert(props.id);
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GOOGLE_CONSOLE_MCC_REQUEST,
      });
      dispatch(setLoadingTrue());

      const response = await GoogleConsoleApi.deleteGoogleConsoleParamApi(
        props
      );

      // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
    } catch (error) {
      let errMessage = error?.response?.data?.error?.message;
      dispatch(googleConsoleMCCAction.failedGoogleConsoleMCCAction(errMessage));
      dispatch(setAlert({ message: errMessage, type: "error" }));
      return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
      dispatch(getAllGoogleConsoleDataSourcesThunk({}));
    }
  };
};
