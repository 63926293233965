export const initialState = {
  isLoading: false,
  layouts: {},
  input_controls: {},
  input_controls_data: {},
  account_reports_data: {},
  ad_spend_data: {},
  filtered_data: {},
};

export const ACTIONS_TYPES = Object.freeze({
  LAYOUT_REQUEST: "LAYOUT_REQUEST",
  FETCH_LAYOUT: "FETCH_LAYOUT",
  REQUEST_INPUT_CONTROLS: "REQUEST_INPUT_CONTROLS",
  FETCH_INPUT_CONTROLS: "FETCH_INPUT_CONTROLS",
  FETCH_INPUT_CONTROLS_DATA: "FETCH_INPUT_CONTROLS_DATA",
  device_key: "device_key",
  FETCH_ACCOUNT_AND_REPORTS: "FETCH_ACCOUNT_AND_REPORTS",
  FETCH_AD_SPEND: "FETCH_AD_SPEND",
  RESET_STATE: "RESET_STATE",
  FILTERED_DATA: "FILTERED_DATA"
});

export function reportLayoutReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.LAYOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_LAYOUT:
      return {
        ...state,
        isLoading: false,
        layouts: action.payload,
      };
    case ACTIONS_TYPES.REQUEST_INPUT_CONTROLS:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_INPUT_CONTROLS:
      return {
        ...state,
        isLoading: false,
        input_controls: action.payload,
      };
    case ACTIONS_TYPES.FETCH_INPUT_CONTROLS_DATA:
      return {
        ...state,
        isLoading: false,
        input_controls_data: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ACCOUNT_AND_REPORTS:
      return {
        ...state,
        isLoading: false,
        account_reports_data: action.payload,
      };
    case ACTIONS_TYPES.FETCH_AD_SPEND:
      return {
        ...state,
        isLoading: false,
        ad_spend_data: action.payload,
      };
    case ACTIONS_TYPES.RESET_STATE:
      return {
        layouts: {},
        input_controls: {},
        input_controls_data: {},
        account_reports_data: {},
        ad_spend_data: {},
        isLoading: true,
      };
    case ACTIONS_TYPES.FILTERED_DATA:
      return {
        ...state,
        filtered_data:action.payload,
      };
    default:
      return state;
  }
}
