import API_BI from "../Utils/axios_bi";
import API from "../Utils/axios";
import { ApiEndpointsBI } from "../Utils/constant";

const { SAVE_ANNOTATIONS_COMMENT_SETTINGS } = ApiEndpointsBI;

export const saveAnnotationsCommentSettings = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(SAVE_ANNOTATIONS_COMMENT_SETTINGS, props);
}