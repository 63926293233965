import { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const splineOptions = (labels, jsonData, y_axis, title, tooltip) => ({
  title: false,
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
  },

  xAxis: {
    categories: labels,
  },
  yAxis: [
    {
      title: {
        text: y_axis?.left_text ?? "",
      },

      labels: {
        formatter: function () {
          for (const [key, value] of Object.entries(jsonData)) {
            if (value.format == "amount") {
              return (
                window.currencySymbol +
                Highcharts.numberFormat(this.value, 0, ".", ",")
              );
            } else if (value.format == "percentage") {
              return Highcharts.numberFormat(this.value, 0, ".", ",") + "%";
            } else {
              return Highcharts.numberFormat(this.value, 0, ".", ",");
            }
          }
        },
      },
    },
    {
      opposite: "true",
      title: {
        text: y_axis?.right_text ?? "",
      },
      labels: {
        formatter: function () {
          return y_axis?.right_text == "ROAS" ||
            y_axis?.right_text == "ACOS" ||
            y_axis?.right_text == "ACoTS" ||
            y_axis?.right_text == "Open Rate" ||
            y_axis?.right_text == "Click Rate" ||
            y_axis?.right_text == "Conversion Rate" ||
            y_axis?.right_text == "MER"
            ? Highcharts.numberFormat(this.value, 0, ".", ",") + "%"
            : y_axis?.right_text == "Average Rating" ||
              y_axis?.right_text == "Total Orders"
            ? this.value
            : y_axis?.right_text == "Views"
            ? Highcharts.numberFormat(this.value, 0, ".", ",")
            : y_axis?.right_text == "CPL"
            ? "$" + Highcharts.numberFormat(this.value, 0, ".", ",")
            : y_axis?.right_text == "Impressions,CTR"
            ? Highcharts.numberFormat(this.value, 2, ".", ",") + "%"
            : Highcharts.numberFormat(this.value, 0, ".", ",");
        },
      },
    },
  ],
  chart: {
    zoomType: "x",
    panning: true,
    panKey: "shift",
    selectionMarkerFill: "none",
    resetZoomButton: {
      position: {
        x: 0,
        y: -40,
      },
    },
  },
  title: {
    // useHTML: true, uncomment it to use tooltip in the title
    text: title,
  },
  subtitle: { text: "Click and drag in the plot area to zoom in." },
  series: jsonData,
  tooltip: {
    formatter: function () {
      for (const [key, value] of Object.entries(jsonData)) {
        let df = value.format;
        let dn = value.name;
        if (dn == this.series.name && df == "amount") {
          var round_off = ["CPL"].includes(this.series.name) ? 2 : 0;
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            ": <b>" +
            window.currencySymbol +
            Highcharts.numberFormat(this.y, round_off, ".", ",") +
            "</b><br/>"
          );
        }

        if (
          (dn == this.series.name &&
            df == "number" &&
            this.series.name == "Conversions") ||
          this.series.name == "Clicks"
        ) {
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            ": <b>" +
            Highcharts.numberFormat(this.y, 0, ".", ",") +
            "</b><br/>"
          );
        }

        if (
          dn == this.series.name &&
          df == "number" &&
          this.series.name == "Average Rating"
        ) {
          return this.series.name + ": <b>" + this.y + "</b><br/>";
        }

        if (dn == this.series.name && df == "number") {
          return (
            this.series.name +
            ": <b>" +
            Highcharts.numberFormat(this.y, 0, ".", ",") +
            "</b><br/>"
          );
        }

        if (
          dn == this.series.name &&
          this.series.name == "ROAS" &&
          df == "percentage"
        ) {
          return (
            this.series.name +
            ": <b>" +
            Highcharts.numberFormat(this.y, 0, ".", ",") +
            "%</b><br/>"
          );
        }

        if (dn == this.series.name && df == "percentage") {
          var round_off = [
            "ACoTS",
            "Lift",
            "Forecasted Lift",
            "MER",
            "ACOS",
            "Open Rate",
            "Click Rate",
            "Conversion Rate",
            "CTR",
          ].includes(this.series.name)
            ? 2
            : 0;
          return (
            "<b>" +
            this.x +
            "</b><br />" +
            this.series.name +
            ": <b>" +
            Highcharts.numberFormat(this.y, round_off, ".", ",") +
            "%</b><br/>"
          );
        }
      }
    },
  },
});

const SplineBar = ({ jsonData, labels, y_axis, title, tooltip }) => {
  const options = useMemo(
    () => splineOptions(labels, jsonData, y_axis, title, tooltip),
    [labels, jsonData, y_axis, title, tooltip]
  );

  return (
    <>
      {jsonData === undefined || jsonData.length === 0 ? (
        ""
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </>
  );
};

export default SplineBar;
