import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Donut from "../../Highcharts/Donut";
import { formDataAppend } from "../../../Utils/helpers";
import { getDonutChartData } from "../../../Redux/Actions/Thunk/DonutThunk";
import { Spin } from "antd";

const DonutChart = (props) => {
  const {
    donut: {
      donutChartData: {
        [props?.block?.block_id]: { data: jsonData = [] } = {},
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getDonutChartData(formData, props));
  }, []);

  return (
    <>
      <Spin spinning={isLoading}>
        {jsonData && jsonData.length > 0 ? (
          <>
            <div className="donut-graph-chart-wrapper">
              <Donut data={jsonData} props={props} />
            </div>
          </>
        ) : (
          "No Data Available"
        )}
      </Spin>
    </>
  );
};

export default DonutChart;
