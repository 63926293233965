import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { getSessionData } from "../../Redux/Actions/Thunk/ColumnChartThunk";
import { formDataAppend, numberWithCommas } from "../../Utils/helpers";
import { Spin } from "antd";

const ColumnCharts = (props) => {
  const {
    sessionData: {
      sessionData: {
        [props?.block?.block_id]: {
          chart_data: chartData = [],
          xAxisCategories: xAxisData = [],
          color: colorData = [],
        } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    if (props?.length == 0) {
      dispatch(getSessionData(formData, props));
    }
  }, []);

  /*for filter data*/
  const [filter, setFilter] = useState("");
  const { filterData } = props;

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getSessionData(formData, props));
  }, [filterData]);
  /*end for filter data*/
  const config = JSON.parse(props?.block?.config);
  var pointWidth = config["pointWidth"];
  let width = config["width"];
  if (config?.hasOwnProperty("settings")) {
    var height = config?.settings?.chart?.height;
    var data_type = config?.settings?.tooltip?.formattter;
  } else {
    var height = "200px";
    var data_type = "percentage";
  }

  var chart = {
    chart: {
      type: "column",
      height: height,
      width: width,
      zoomType: "",
      panning: 0,
      panKey: "",
      selectionMarkerFill: "none",
      resetZoomButton: {
        position: {
          x: 0,
          y: -40,
        },
      },
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: xAxisData,
      crosshair: true,
      labels: {
        enabled: true,
        align: "right",
        x: 30,
      },
      visible: true,
    },
    tooltip: {
      formatter: function () {
        return this.points.reduce(function (s, point) {
          if ((props?.block?.block_key).indexOf('acos') != -1 || (props?.block?.block_key).indexOf('cvr') != -1 || (props?.block?.block_key).indexOf('cpc') != -1) {
            var y_value = (point.y).toFixed(2);
          }
          else {
            var y_value = Math.floor(point.y);
          }
          if (data_type === "percentage") {
            return point.x + ": " + y_value + "%";
          }
          else {
            return point.x + ": " + window.currencySymbol + y_value;
          }
        }, "<b>" + this.x + "</b>");
      },
      shared: true,
    },
    plotOptions: {
      series: {
        pointWidth: pointWidth,
        colorByPoint: true,
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
          color: "#666666",
          style: {
            fontSize: "12px",
          },
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        enabled: true,
        format: data_type === "percentage" ? "{value}%" : window.currencySymbol + "{value}",
        rotation: 0,
        formatter: function() {
          if(data_type == "percentage"){
            return numberWithCommas(this.value)+"%";
          }else{
            return window.currencySymbol + numberWithCommas(this.value);
          }
        }
      },
      max: null,
      //tickInterval: 0.1,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
      align: "center",
      verticalAlign: "middle",
      layout: "horizontal",
      symbolRadius: "1",
    },
    colors: colorData,
    series: chartData,
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chart}
        ></HighchartsReact>
      </Spin>
    </>
  );
};

export default ColumnCharts;
