import * as accountReportReducer from "../accountReportAction";
import { ACTIONS_TYPES } from "../../Reducers/accountReportReducer";
import * as apiPath from "../../../Api/indexApi";

export const getSliderData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.RESET_SLIDER_DATA,
        payload: data?.block?.block_id,
      });
      dispatch({
        type: ACTIONS_TYPES.GET_SLIDER_DATA,
      });
      let slider_data = {};
      const response = await apiPath.getApiPath(props, data);
      if (response?.status === 200) {
        slider_data = {
          [data?.block?.block_id]: {
            data: response?.data?.data,
          },
        };
      }
      return dispatch(accountReportReducer.getSliderData(slider_data));
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const getHTML = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_HTML,
      });
      let html_data = {};
      const response = await apiPath.getApiPath(props, data);
      if (response?.status === 200) {
        html_data = {
          data: response?.data?.data,
        };
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_HTML,
        payload: html_data,
      });
      return dispatch(accountReportReducer.getHTML(html_data));
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
