/**
 * @fileoverview Reducer for Google Ads Data Sources
 *
 * The reducer handles the state changes for the Google.
 */

// Define the initial state of the reducer
export const initialState = {
  account_ds: {}, // for single account data source
  account_dss: {}, // forall  account data sources . i used dss because of data sources . note "s" at the end, it is plural
  dsRange: {}, // for date range
  error_ds: {}, // error data sources
  isLoading: false, // loading state
};

// Define action types to be used in the reducer
export const ACTIONS_TYPES = Object.freeze({
  GOOGLE_ADS_DS_REQUEST: "GOOGLE_ADS_DS_REQUEST",
  GET_GOOGLE_ADS_DS: "GET_GOOGLE_ADS_DS", // Fetch Account Data Sources
  GET_ALL_GOOGLE_ADS_DS: "GET_ALL_GOOGLE_ADS_DS", // Fetch All Account Data Sources

  DS_RANGE_GOOGLE_ADS: "DS_RANGE_GOOGLE_ADS", // Fetch Date Range
  CREATE_GOOGLE_ADS_DS: "CREATE_GOOGLE_ADS_DS", // Create Account Data Sources
  UPDATE_GOOGLE_ADS_DS: "UPDATE_GOOGLE_ADS_DS", // Update Account Data Sources
  GOOGLE_ADS_ERROR_DS: "GOOGLE_ADS_ERROR_DS", // Error Account Data Sources
  FAILED_GOOGLE_ADS_DS: "FAILED_GOOGLE_ADS_DS",
  RESET_GOOGLE_ADS_DS: "RESET_GOOGLE_ADS_DS",
});

// Define the reducer function
export function googleAdsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  // Switch case to handle different types of actions
  switch (type) {
    case ACTIONS_TYPES.GOOGLE_ADS_DS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_GOOGLE_ADS_DS:
      return {
        ...state,
        isLoading: false,
        account_ds: action.payload,
      };
    case ACTIONS_TYPES.GET_ALL_GOOGLE_ADS_DS:
      return {
        ...state,
        isLoading: false,
        account_dss: action.payload,
      };

    case ACTIONS_TYPES.DS_RANGE_GOOGLE_ADS:
      return {
        ...state,
        isLoading: false,
        dsRange: action.payload,
      };

    case ACTIONS_TYPES.RESET_GOOGLE_ADS_DS:
      return {
        ...state,
        loading: false,
        account_ds: {},
        account_dss: {},
        dsRange: {},
      };
    case ACTIONS_TYPES.GOOGLE_ADS_ERROR_DS:
    case ACTIONS_TYPES.FAILED_GOOGLE_ADS_DS:
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };

    // Return the initial state if no matching action is found
    default:
      return state;
  }
}
