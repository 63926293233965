import { facebookTokensAPI } from "../../Api/datasources/dsConfig/facebookAdsInsightMccApi";
const facebookApiCallForDataAPI = async (props) => {
  const { queryCode } = props;

  // *Get url from amazon according to region
  // let url = await facebookConfig.facebookTokenAccess();
  // alert(url, "url");
  const tokenResponse = await facebookTokensAPI();
  
  return {
    tokens: tokenResponse?.data.data?.token,
  };
};

export default facebookApiCallForDataAPI;
