/**
 * @fileoverview Reducer for Amazon Organic Ranking
 *
 * The reducer handles the state changes for the Amazon Organic Ranking.
 */

// Define the initial state of the reducer
export const initialState = {
  accounts_ds: {}, // account data sources
  error_ds: {}, // error data sources
  isLoading: false, // loading state
};

// Define action types to be used in the reducer
export const ACTIONS_TYPES = Object.freeze({
  AMAZON_ORGANIC_RANKING_ACCOUNT_DS_REQUEST:
    "AMAZON_ORGANIC_RANKING_ACCOUNT_DS_REQUEST",
  GET_AMAZON_ORGANIC_RANKING_ACCOUNT_SERVICE_ACCOUNT_DS:
    "GET_AMAZON_ORGANIC_RANKING_ACCOUNT_SERVICE_ACCOUNT_DS", // Fetch Account Data Sources
  CREATE_AMAZON_ORGANIC_RANKING_ACCOUNT_DS:
    "CREATE_AMAZON_ORGANIC_RANKING_ACCOUNT_DS", // Create Account Data Sources
  UPDATE_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS:
    "UPDATE_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS", // Update Account Data Sources
  AMAZON_ORGANIC_RANKNG_ERROR_DS: "AMAZON_ORGANIC_RANKING_ERROR_DS", // Error Account Data Sources
  FAILED_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS:
    "FAILED_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS",
  RESET_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS:
    "RESET_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS",
});

// Define the reducer function
export function amazonOrganicRankingReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;

  // Switch case to handle different types of actions
  switch (type) {
    case ACTIONS_TYPES.AMAZON_ORGANIC_RANKING_ACCOUNT_DS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_AMAZON_ORGANIC_RANKING_ACCOUNT_SERVICE_ACCOUNT_DS:
    case ACTIONS_TYPES.CREATE_AMAZON_ORGANIC_RANKING_ACCOUNT_DS:
    case ACTIONS_TYPES.UPDATE_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS:
      return {
        ...state,
        isLoading: false,
        accounts_ds: action.payload
      };
    case ACTIONS_TYPES.RESET_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS:
      return {
        ...state,
        isLoading: false,
        accounts_ds: {},
      };

    case ACTIONS_TYPES.AMAZON_ORGANIC_RANKNG_ERROR_DS:
    case ACTIONS_TYPES.FAILED_AMAZON_ORGANIC_RANKING_SERVICE_ACCOUNT_DS:
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };

    // Return the initial state if no matching action is found
    default:
      return state;
  }
}
