import { Button, Form, Input, Space, Typography } from "antd";
import { useHistory } from "react-router-dom";

const BingAdsUpdateConfigurationForm = ({ form, onFinish, onFinishFailed }) => {
  const history = useHistory();
  const handleChange = (info) => {
    return info;
  };

  const handleRequest = (values) => {};

  const handleCancel = () => {
    history.push("/data-source/google-console-configuration");
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        name="google_console_form"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Account"
          name="account"
          rules={[
            {
              required: true,
              message: "Account is required",
            },
          ]}
        >
          <Input placeholder="Account" />
        </Form.Item>
        <Form.Item name="key_file" label="Key File" valuePropName="fileList">
          <Input required={false} onChange={handleChange} type="file" />
        </Form.Item>
        <Form.Item label="file type" name="file_type" required={false}>
          <Input required={false} placeholder="File Type" />
        </Form.Item>
        <Form.Item label="Key" name="key" required={false}>
          <Input required={false} placeholder="Key" />
        </Form.Item>

        <Form.Item
          label="Platform"
          name="platform"
          rules={[
            {
              required: true,
              message: "Platform is required",
            },
          ]}
        >
          <Input placeholder="Platform" />
        </Form.Item>
        <Form.Item
          label="User Email"
          name="user_email"
          rules={[
            {
              required: true,
              message: "User Email is required",
            },
          ]}
        >
          <Input placeholder="User Email" />
        </Form.Item>

        <Form.Item
          label="Project Number"
          name="project_number"
          rules={[
            {
              required: true,
              message: "Project Number is required",
            },
          ]}
        >
          <Input placeholder="Project Number" />
        </Form.Item>

        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>

            <Button
              style={{
                marginLeft: "1rem",
              }}
              type="primary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default BingAdsUpdateConfigurationForm;
