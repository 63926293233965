import { listOfAllDataSources } from "../../../Config/datasources/allDatasources";
import amazonSponsorAdsApiCall from "../amazonSponsorAds";
import amazonMarkeingServiceApiCall from "../amazonMarketingService";
import tiktokApiCall from "../tiktokApiCallForProfile";
import facebookApiCallForDataAPI from "../facebookApiCallForData";

/*
These functions are used to call external APIs for  different data sources:

*/

export const dataSourceFunction = {
  // *Function will call to the outside api for the tokens and profiles.
  [`${listOfAllDataSources["amazonSponsorAds"]}`]: async (parameters) => {
    const response = await amazonSponsorAdsApiCall({
      region: parameters.region,
      queryCode: parameters.code,
    });
    return response;
  },
  // *Function will call to the outside api for the tokens and profiles.
  [`${listOfAllDataSources["amazonMarketingService"]}`]: async (parameters) => {
    const response = await amazonMarkeingServiceApiCall({
      queryCode: parameters.code,
    });
    return response;
  },
  // *Function will call to the outside api for the tokens and profiles.
  [`${listOfAllDataSources["tiktok"]}`]: async (parameters) => {
    const response = await tiktokApiCall({
      queryCode: parameters.code,
    });
    return response;
  },
  // *Function will call to the outside api for the tokens and profiles.
  [`${listOfAllDataSources["facebook"]}`]: async (parameters) => {
    const response = await facebookApiCallForDataAPI({
      queryCode: parameters.code,
    });
    return response;
  },
};
