/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";

const UserLoginLogs = (props) => {
  const columnIds = ["login_status"];
  let cellClass = "";
  if (columnIds.includes(props.column.dataIndex)) {
    if (props.value == "Successful") {
      cellClass = "success-column";
    }
  }
  return (
    <span className={cellClass}>
      <FormattedNumberCell value={props.value} format={props.column.format} />
    </span>
  );
};

export default UserLoginLogs;
