import React, { useEffect, useState } from "react";
import { Input, Tooltip, Table, Row, Col, Modal, Select, Button } from "antd";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountReports,
  getContractedServices,
  getAccountDetail,
  getUserAccounts,
} from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiUsers } from "react-icons/fi";
import { isCustomer, isNonManagedCustomer } from "../../Utils/user";
import Config from "../../Config";
const { confirm } = Modal;

const { Search } = Input;
const { Option } = Select;

function KeyWordResearchLog(props) {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  let accountId = useParams().account_id;
  const [searchText, setSearchText] = useState("");

  const {
    API_BI: { baseURL: baseUrl_API },
  } = Config;

  const handleDownloadClick = () => {
    // Replace with the URL of the file you want to download
    const fileUrl = "https://example.com/path-to-your-file.pdf";

    // Create a hidden anchor element and trigger a click event to initiate the download
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = "downloaded-file.pdf"; // Specify the desired file name
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "1",
      sorter: true,
    },
    {
      title: "Keyword Research Data",
      dataIndex: "",
      key: "2",
      render: (_, record) => (
        // <Button type="primary">
          <a href={baseUrl_API + 'api/v1/download-keyword-research-data?id='+record.id}>Download</a>
        // </Button>
      ),
    },
    {
      title: "Date Sent",
      dataIndex: "email_time",
      key: "3",
    },
    {
      title: "Email Sent To",
      dataIndex: "email",
      key: "4",
    },
    {
      title: "Generated By",
      dataIndex: "full_name",
      key: "5",
    },
    {
      title: "Generated On",
      dataIndex: "created_at",
      key: "6",
    },
  ];

  useEffect(() => {
    const fetchApi = async (searchText) => {
      try {
        const bodyData = {
          account_id: accountId,
          search: searchText,
        };
        var requestOptions = {
          method: "POST",
          redirect: "follow",
          headers: {
            authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        };
        setLoading(true);
        const response = await fetch(
          baseUrl_API + "api/v1/keyword-research-log-data",
          requestOptions
        );
        const result = await response.json();
        setData(result?.data);
        setLoading(false);
      } catch (err) {
        // Handle any errors
      } finally {
      }
    };
    fetchApi(searchText);
  }, [searchText]);

  const handleSearch = (val) => {
    setSearchText(val);
  };

  return (
    <>
      <div className="panel-heading">
        <Row>
          <Col span={16} className="panel-heading-title">
            <h2></h2>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <Row className="panel-body-input">
          <Col span={24} className="panel-body-input-left">
            <div className="panel-body-select">
              <Search
                onChange={(e) => handleSearch(e.target.value)}
                value={searchText}
                allowClear
                style={{ minWidth: 300 }}
              />
            </div>
          </Col>
        </Row>

        <Row className="panel-body-content">
          <Table
            columns={columns}
            loading={isLoading}
            key={(record) => record.primaryKey}
            dataSource={data}
            pagination={{ pageSize: 50 }}
          />
        </Row>
      </div>
    </>
  );
}

export default KeyWordResearchLog;
