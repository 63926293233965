import * as AuthApi from "../../../Api/AuthApi";
import * as userAction from "../userAction";
import { SAVE_NOTIFICATION_LOG, USER_REQUEST } from "../types/index";
import { ACTIONS_TYPES } from "../../Reducers/userReducer";
import { message, notification } from "antd";

export const getAllUsers = (params = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.USER_REQUEST,
      });
      let users = {};
      const response = await AuthApi.getUsers(params);
      if (response?.status == 200) {
        users = {
          data: response?.data?.data,
          total: response?.data?.total_records,
        };
      }
      return dispatch(userAction.getUser(users));
    } catch (error) {
      console.error("Error in fetching users data: " + error);
    } finally {
    }
  };
};

export const getAllRoles = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_REQUEST,
      });
      let roles = {};
      const response = await AuthApi.getAllRoles();
      if (response?.status == 200) {
        roles = response?.data?.data;
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_ROLES,
        payload: roles,
      });
      return dispatch(userAction.getAllRoles(roles));
    } catch (error) {
      console.error("Error in fetching roles data: " + error);
    } finally {
    }
  };
};

export const getUserDetail = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_REQUEST,
      });
      let userDetail = [];
      const response = await AuthApi.getUserDetail(props);
      if (response?.status == 200) {
        userDetail = {
          data: response?.data?.data,
          roles: response?.data?.roles,
          services: response?.data?.services,
          user_roles: response?.data?.user_roles,
          user_services: response?.data?.user_services,
          user_accounts: response?.data?.user_accounts,
        };
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_USER_DETAIL,
        payload: userDetail,
      });
      return dispatch(userAction.getUserDetail(userDetail));
    } catch (error) {
      console.error("Error in fetching user detail: " + error);
    } finally {
    }
  };
};

export const createUpdateUser = (props) => {
  let propsObj = JSON.parse(props);
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_REQUEST,
      });

      const response = await AuthApi.createUpdateUser(propsObj);
      if (response.status == 200) {
        notification["success"]({
          message: "Success",
          description: "User information saved successfully.",
          duration: 20,
        });
      } else {
        notification["error"]({
          message: "Error",
          description: response.data?.message,
          duration: 20,
        });
      }
    } catch (error) {
      console.error("Error in saving user data: " + error);
    } finally {
      return dispatch(getUserDetail(propsObj.user_id));
    }
  };
};

export const toggleUserStatus = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_REQUEST,
      });
      await AuthApi.toggleUserStatus(props);
    } catch (error) {
      console.error("Error in updating user status: " + error);
    } finally {
      return dispatch(getAllUsers());
    }
  };
};

export const deleteUser = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_REQUEST,
      });
      await AuthApi.deleteUser(props);
    } catch (error) {
      console.error("Error in deleting user: " + error);
    } finally {
      return dispatch(getAllUsers());
    }
  };
};

export const defaultDashboardList = () => {
  return async (dispatch) => {
    try {
      let dashboards = [];
      const response = await AuthApi.getDefaultDashboardList();
      if (response?.status == 200) {
        dashboards = {
          data: response?.data,
        };
      }
      dispatch({
        type: ACTIONS_TYPES.DEFAULT_DASHBOARD_LIST,
        payload: dashboards.data,
      });
    } catch (error) {
      console.error("Error in fetching default dashboard: " + error);
    } finally {
    }
  };
};

export const setProfileDashboard = (props) => {
  return async () => {
    try {
      await AuthApi.setProfileDashboard(props);
      message.success("Updated Default Dashboard Successfully.");
    } catch (error) {
      console.error("Error while updating profile dashboard: " + error);
    } finally {
    }
  };
};

export const saveFeedback = (props) => {
  return async (dispatch) => {
    try {
      let feedback = [];
      const response = await AuthApi.saveFeedback(props);
      if (response?.status == 200) {
        feedback = {
          data: response?.data,
        };
      }
      if (response?.data?.status == "success") {
        notification["success"]({
          message: "Success",
          description: "Feedback sent successfully.",
        });
      } else if (response?.data?.status == "error") {
        // console.log(response?.data?.validation);
        let validation_message = "";
        let validation = response?.data?.validation;
        Object.keys(validation).forEach(function (key) {
          validation_message += validation[key];
        });
        notification["error"]({
          message: "Validation Error",
          description: (
            <>
              Please fix following:
              <br />
              {validation_message}
            </>
          ),
        });
      }
    } catch (error) {
      //console.error("Error while saving feedback: " + error);
      notification["error"]({
        message: "Error",
        description: "Error while sending feedback.",
      });
    } finally {
    }
  };
};

export const setAlertViewLog = (props) => {
  return async () => {
    try {
      await AuthApi.setAlertViewLog(props);
      message.success("Updated Default Dashboard Successfully.");
    } catch (error) {
      console.error("Error while updating profile dashboard: " + error);
    } finally {
    }
  };
};

export const saveNotificationLog = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.USER_REQUEST,
      });
      let notification_response = ""
      const response = await AuthApi.saveNotificationLog(props);
      if (response?.status == 200) {
        notification_response = {
          data: response["data"],
        };
      }
      dispatch({
        type: ACTIONS_TYPES.SAVE_NOTIFICATION_LOG,
        payload: notification_response,
      });
      return dispatch(userAction.saveNotificationLog(notification_response));
    } catch (error) {
      console.error("Error in saving notification: " + error);
    }
  };
};
