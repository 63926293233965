import { Form, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PInterestUpdateConfigurationForm from "../../Components/Configuration/pintrest/PinterestUpdateForm";
const { Title } = Typography;
const UpdatePinterestConfiguration = () => {
  const [form] = Form.useForm();

  const history = useHistory();
  const { account_ds: { auth_params = {} } = {} } = useSelector(
    (state) => state.googleAds
  );

  // get id from params
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // get id from params
  }, [id]);

  const fetchGoogleAdsConfiguration = async (id) => {
    // call to api for now let's say following has been returned
    // set values in form
    form.setFieldsValue();
  };

  useEffect(() => {
    fetchGoogleAdsConfiguration(id);
  }, [auth_params, id]);

  const handleSubmit = (values) => {};

  const handleCancel = () => {
    history.push("/google-ads-configuration");
  };
  return (
    <>
      <Title title="2">Update Pinterest</Title>
      <div
        style={{
          width: "800px",
        }}
      >
        <PInterestUpdateConfigurationForm
          onFinish={handleSubmit}
          onFinishFailed={handleCancel}
          form={form}
        />
      </div>
    </>
  );
};

export default UpdatePinterestConfiguration;
