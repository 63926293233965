import { ACTIONS_TYPES } from "../Reducers/ColumnChartReducer";

export function fetchSessionData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_SESSSION_DATA,
    payload,
  };
}

export function fetchConversionsData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_CONVERSIONS_DATA,
    payload,
  };
}

export function fetchRevenueData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_REVENUE_DATA,
    payload,
  };
}

export function fetchBarChartData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_BAR_CHART_DATA,
    payload,
  };
}
