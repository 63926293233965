import React, { useMemo, useRef, useState, useEffect } from "react";
import { Select, Spin, AutoComplete, Form } from "antd";
import debounce from "lodash/debounce";
import Config from "../../Config";
import { titleCase } from "../../Utils/helpers";

const {
  API: { baseURL, authKey },
} = Config;

const { Option } = Select;

const AutoCompleteSelect = (props) => {
  const [value, setValue] = useState([]);
  const [getVal, setAsinVal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const asinData = useRef([]);
  const [options, setOptions] = useState([]);
  const asinValue = useRef(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());

  const id = props?.block?.id;
  const param_name = props?.block?.param_name;
  const label = props?.block?.label;
  const option_url = props?.block?.option_url;
  const option_field = props?.block?.option_field;

  const handleSearch = async (data) => {
    let uniqueRes = [];
    if (data && data.length === 0) {
      setValue([]);
      setOptions([]);
    }
    if (data && data.length > 2) {
      let res = new Set();
      var formdata = new FormData();
      formdata.append("authentication", authKey);
      formdata.append("search_term_query", data);

      if (props?.client_id && props?.client_id !== "") {
        formdata.append("Client", props?.client_id);
      }
      if (props?.block?.hasOwnProperty("search_term_source")) {
        formdata.append("search_term_source", props?.block?.search_term_source);
      }

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      setLoading(true);

      try {
        const response = await fetch(
          baseURL + props?.block?.option_url,
          requestOptions
        );
        const result = await response.json();

        if (result && result.data) {
          setDataSource(result.data);
          var resultData = result.data.splice(0, 1);
          result.data.forEach((item) => {
            res.add(item.value);
          });
          const uniqueRes = Array.from(res).map((value) => ({
            value,
            label: `${value}`,
          }));
          if (uniqueRes && uniqueRes.length > 0) {
            setOptions(uniqueRes);
          }
        } else {
          setDataSource([]);
          setOptions([]);
        }
      } catch (err) {
        setDataSource([]);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    } else {
      setDataSource([]);
      setOptions([]);
    }
  };

  return (
    <>
      <div className="Report-V2-filter-box Report-V2-filter-box-autocomplete">
        <Spin spinning={loading}>
          <div className="Report-V2-filter-option-title">
            <span>{label}</span>
          </div>
          <Form.Item name={param_name}>
            <AutoComplete
              key={param_name}
              name={param_name}
              dropdownStyle={{
                width: "5%",
              }}
              dropdownClassName="input-dropdown-menu"
              mode=""
              maxTagCount={1}
              autoFocus={true}
              allowClear={true}
              placeholder="Please enter 3 or more characters"
              optionFilterProp="children"
              showSearch
              //onSearch={handleSearch}
              onChange={handleSearch}
              defaultValue={
                (urlSearchParams.get(props?.block?.id) != null) ? params_data[props?.block?.id] : ""
              }
              options={options}
            ></AutoComplete>
          </Form.Item>
        </Spin>
      </div>
    </>
  );
};
export default AutoCompleteSelect;
