const initialState = {
  isLoading: false,
  alert: [],
};

export const ACTIONS_TYPES = Object.freeze({
  // loading 
  SET_LOADING: "SET_LOADING",
  REMOVE_LOADING: "REMOVE_LOADING",
  //Alert
  SET_ALERT: "SET_ALERT",
  REMOVE_ALERT: "REMOVE_ALERT",
});

function globalReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.REMOVE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ACTIONS_TYPES.SET_ALERT:
      return  {
        ...state,
        alert: [...state.alert, payload],
      }
    case ACTIONS_TYPES.REMOVE_ALERT:
      return {
        ...state,
       alert: state.alert.filter((alert) => alert.id !== payload)
      }
    default:
      return state;
  }
}

export default globalReducer;
