import * as reportLayoutApi from "../../../Api/reportLayoutApi";
import * as reportLayoutAction from "../reportLayoutAction";
import { ACTIONS_TYPES } from "../../Reducers/reportLayoutReducer";
import * as inputDynamicAction from "../inputDynamicAction";
import { ACTIONS_TYPES_INPUT } from "../../Reducers/inputDynamicReducer";

let state;
export const getReportLayouts = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.LAYOUT_REQUEST,
      });
      let report_layouts = {};
      const response = await reportLayoutApi.getReportLayouts(props);
      var check = response?.data?.input_controls[0];
      var inputControls = JSON.parse(check?.input_controls);
      if (response?.status == 200) {
        report_layouts = {
          data: response?.data,
          access_status: response?.data?.access_status,
          input_controls: inputControls,
          data_layout_controls: response?.data?.layout_block,
          layout_controls: JSON.parse(response?.data?.layout[0]?.layout),
          layout_css: response?.data?.layout[0]?.custom_css,
        };
      }
      return dispatch(reportLayoutAction.getReportLayouts(report_layouts));
    } catch (error) {
      //console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const getInputControls = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: data.block.label + "_" + ACTIONS_TYPES_INPUT.PENDING,
      });
      let input_controls = {};
      const response = await reportLayoutApi.getInputControls(props, data);
      if (response?.status == 200) {
        input_controls = {
          data: response?.data.data,
        };
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_INPUT_CONTROLS_DATA,
        payload: input_controls,
      });
      // return dispatch(
      //   inputDynamicAction.entities(state, {
      //     type: data.block.label + "_" + ACTIONS_TYPES_INPUT.FETCHED,
      //     name: data.block.label,
      //     payload: input_controls,
      //   })
      // );
      return dispatch(reportLayoutAction.getInputControls(input_controls));
    } catch (error) {
      //console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const getAllInputControls = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.LAYOUT_REQUEST,
      });
      let report_layouts = {};
      const response = await reportLayoutApi.getAllInputControls(props);
      var check = response?.data?.input_controls[0];
      var inputControls = JSON.parse(check?.input_controls);
      if (response?.status == 200) {
        report_layouts = {
          data_input_controls: inputControls,
        };
      }
      return dispatch(reportLayoutAction.getAllInputControls(report_layouts));
    } catch (error) {
      //console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const getAccountAndReports = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.LAYOUT_REQUEST,
      });
      const account_id = props.account_id;
      const report_key = props.report_key;
      let account_reports = {};
      const response = await reportLayoutApi.getAccountAndReports(
        account_id,
        report_key
      );
      if (response?.status == 200) {
        account_reports = {
          // data_account_reports: response?.data?.data,
          accounts: response?.data?.accounts,
          reports: response?.data?.reports,
          service_id: response?.data?.report_service_id,
        };
      }
      return dispatch(reportLayoutAction.getAccountAndReports(account_reports));
    } catch (error) {
      //console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};

export const setFilteredData = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FILTERED_DATA,
      });
      return dispatch(reportLayoutAction.setFilteredData(props));
    } catch (error) {
      //console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
