import { ACTIONS_TYPES } from "../Reducers/mmmReducer";

export function getKeyInsights(payload) {
  return {
    type: ACTIONS_TYPES.GET_KEY_INSIGHTS,
    payload,
  };
}

export function fetchKeyInsights(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_KEY_INSIGHTS,
    payload,
  };
}