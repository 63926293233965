/**
 * @fileoverview Reducer for Bridge Edge
 *
 * The reducer handles the state changes for the Bridge Edge.
 */

// Define the initial state of the reducer
export const initialState = {
    accounts_ds: {}, // account data sources
    error_ds: {}, // error data sources
    isLoading: false, // loading state
  };
  
  // Define action types to be used in the reducer
  export const ACTIONS_TYPES = Object.freeze({
    ESP_KALVIYO_REQUEST: "ESP_KALVIYO_REQUEST",
    CREATE_ESP_KALVIYO_ACCOUNT_DS: "CREATE_ESP_KALVIYO_ACCOUNT_DS", // Create Account Data Sources
    ESP_KALVIYO_ACCOUNT_DS:
      "ESP_KALVIYO_ACCOUNT_DS", // Error Account Data Sources
    FAILED_ESP_KALVIYO_ACCOUNT_DS: "FAILED_ESP_KALVIYO_ACCOUNT_DS",
    RESET_ESP_KALVIYO_ACCOUNT_DS:
      "RESET_ADWORDS_SEARCHH_AUCTION_INSIGHTS_ACCOUNT_DS",
  });
  
  // Define the reducer function
  export function espKlaviyoReducer(state = initialState, action = {}) {
    const { type, payload } = action;
  
    // Switch case to handle different types of actions
    switch (type) {
      case ACTIONS_TYPES.ESP_KALVIYO_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case ACTIONS_TYPES.CREATE_ESP_KALVIYO_ACCOUNT_DS:
        return {
          ...state,
          isLoading: false,
          accounts_ds: {
            ...state.accounts_ds,
            ...payload,
          },
        };
      case ACTIONS_TYPES.RESET_ESP_KALVIYO_ACCOUNT_DS:
        return {
          ...state,
          isLoading: false,
          accounts_ds: {},
        };
  
      case ACTIONS_TYPES.FAILED_ESP_KALVIYO_ACCOUNT_DS:
        return {
          ...state,
          isLoading: false,
          error_ds: payload,
        };
  
      // Return the initial state if no matching action is found
      default:
        return state;
    }
  }
  