import API_BI from "../Utils/axios_bi";
import API from "../Utils/axios";
import { ApiEndpointsBI } from "../Utils/constant";

const { SAVE_ANNOTATIONS_COMMENT, UPDATE_ANNOTATIONS_COMMENT, DELETE_ANNOTATIONS_COMMENT,GET_ANNOTATIONS_COMMENT, GET_MENTION_USERS } = ApiEndpointsBI;

export const getAnnotationsComment = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(GET_ANNOTATIONS_COMMENT, props);

}

export const saveAnnotationsComment = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(SAVE_ANNOTATIONS_COMMENT, props);
}

export const updateAnnotationsComment = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(UPDATE_ANNOTATIONS_COMMENT, props);
}

export const deleteAnnotationsComment = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(DELETE_ANNOTATIONS_COMMENT, props);
}

export const getMentionUsers = async (props) => {
    const token = localStorage.getItem("token");
    API_BI.defaults.headers.common["Authorization"] = token;
    return await API_BI.post(GET_MENTION_USERS, props);
}