import { getAmazonSecrets } from "../../../Api/amazonSecrets";
import Config from "../../../Config";
import { StringHelper } from "../../../Utils/helpers/string";
import { Token } from "../../../Utils/helpers/token";

const {
  API_BI: { BASE_URL_FOR_BI_APP },
} = Config;

class AmazonSponsorAdsConfig {
  constructor() {
    this.amazonOAuthAccessUrlForDifferentRegions = {
      NA: "https://www.amazon.com/ap/oa?client_id={0}&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri={1}",
      EU: "https://eu.account.amazon.com/ap/oa?client_id={0}&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri={1}",
      FE: "https://apac.account.amazon.com/ap/oa?client_id={0}&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri={1}",
    };
    this.amazonTokenAccessUrlForDifferentRegions = {
      NA: "https://api.amazon.com/auth/o2/token?grant_type={0}&code={1}&client_id={2}&client_secret={3}&redirect_uri={4}",
      EU: "https://api.amazon.co.uk/auth/o2/token?grant_type={0}&code={1}&client_id={2}&client_secret={3}&redirect_uri={4}",
      FE: "https://api.amazon.co.jp/auth/o2/token?grant_type={0}&code={1}&client_id={2}&client_secret={3}&redirect_uri={4}",
    };
    this.amazonClientId = undefined;
    this.amazonClientSecret = undefined;
    this.amazonGenerateTokensURL = "https://api.amazon.com/auth/o2/token";
    this.amazonCallBackURL = `${BASE_URL_FOR_BI_APP}/data-sources/amazon-sponsored-ad/set-auth-code `;
    this.amazonAccessProfileURL =
      "https://advertising-api.amazon.com/v2/profiles";
  }

  async init() {
    if (this.amazonClientId && this.amazonClientSecret)
      return {
        amazonClientId: this.amazonClientId,
        amazonClientSecret: this.amazonClientSecret,
      };
    try {
      const { data } = await getAmazonSecrets();
      this.amazonClientId = Token.decryptAuthParams(data.secret.client_id);
      this.amazonClientSecret = Token.decryptAuthParams(
        data.secret.client_secret
      );

      return {
        amazonClientId: this.amazonClientId,
        amazonClientSecret: this.amazonClientSecret,
      };
    } catch (error) {
      throw new Error("Missing Amazon Secrets");
    }
  }

  // // make getting and setter for amazonClientId and amazonClientSecret
  // get amazonClientId() {
  //   this.init()
  //   return this.amazonClientId;
  // }

  // get amazonClientSecret() {
  //   this.init()
  //   return this.amazonClientSecret;
  // }

  amazonOAuthAccessUrl(region) {
    return this.amazonOAuthAccessUrlForDifferentRegions[region];
  }

  amazonTokenAccessUrl(region) {
    return this.amazonTokenAccessUrlForDifferentRegions[region];
  }

  async amazonUrlForOAuth(region, amazonSecrets) {
    await this.init();
    const url = this.amazonOAuthAccessUrl(region);
    if (!url) {
      throw new Error("Missing REACT_APP_AMAZON_URL_FOR_OAUTH");
    }

    const completeUrl = StringHelper.format(
      url,
      this?.amazonClientId || amazonSecrets?.amazonClientId,
      this?.amazonCallBackURL || amazonSecrets?.amazonCallBackURL
    );
    return completeUrl;
  }

  async amazonTokenAccess(region, code) {
    await this.init();
    const url = this.amazonTokenAccessUrl(region);
    if (!url) {
      throw new Error("Missing REACT_APP_AMAZON_TOKEN_ACCESS_URL");
    }
    const completeUrl = StringHelper.format(
      url,
      "authorization_code",
      code,
      this.amazonClientId,
      this.amazonClientSecret,
      this.amazonCallBackURL
    );

    return completeUrl;
  }
}

export const amazonSponsorAdsConfig = new AmazonSponsorAdsConfig();
