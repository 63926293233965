import { ACTIONS_TYPES } from "../Reducers/tableSliderReducer";

export function getTableSliderData(payload) {
  return {
    type: ACTIONS_TYPES.GET_TABLE_SLIDER_DATA,
    payload,
  };
}

export function fetchTableSliderData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_TABLE_SLIDER_DATA,
    payload,
  };
}