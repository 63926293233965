import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Input,
  Space,
  Table,
  Button,
  Row,
  Col,
  Switch,
  message,
  Alert,
  Modal,
  Select,
  Tooltip,
  Popover,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccounts,
  getIMSUsers,
  getSpecialistUsers,
  getServices,
} from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";
import { FaEye, FaDatabase, FaInfoCircle } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";

import UpdateFromSalesforce from "../../Components/Modals/UpdateFromSalesforce";
import ImportGoals from "../../Components/Modals/ImportGoals";
import { Token } from "../../Utils/helpers/token";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Config from "../../Config";
import { Loading } from "react-jsx-highcharts";
import { getUserId, isIMSUser, isSpecialist } from "../../Utils/user";
import ImportAccountSpends from "../../Components/Modals/ImportAccountSpends";

const { confirm } = Modal;

const { Search } = Input;
const { Option } = Select;

function Account(props) {
  let { id } = useParams();
  const [timer, setTimer] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [searchedAccount, setSearchedAccount] = useState([]);
  const [hasSearched, setHasSearched] = useState(true);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [ims_ids, setImsIds] = useState([]);
  const [specialist_ids, setSpecialistIds] = useState([]);
  const [secondarySpecialist, setSecondarySpecialist] = useState(false);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });
  const {
    accounts: {
      accounts: { data: accountData = [], total, notifications = {} } = {},
      isLoading,
      ims_users,
      specialist_users,
      services: { data: services_data = [] } = {},
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const [accountFilter, setAccountFilter] = useState({
    ims: 0,
    specialist: 0,
    service: 0,
    account_status: 1,
    renewal_status: 0,
    account_type: "",
    client_type: "",
    search: "",
    show_secondary_specialist: 0,
  });

  useEffect(() => {
    if (ims_users != undefined) {
      var ims_id = ims_users.map((user) => user.ims_id);
      setImsIds(ims_id);
      setAccountFilter({
        ...accountFilter,
        ims:
          isIMSUser() && ims_id.length > 0 && ims_id.includes(getUserId())
            ? getUserId()
            : 0,
      });
    }

    if (specialist_users != undefined) {
      var specialist_id = specialist_users.map((user) => user.specialist_id);
      setSpecialistIds(specialist_id);
      setAccountFilter({
        ...accountFilter,
        specialist:
          isSpecialist() &&
          specialist_id.length > 0 &&
          specialist_id.includes(getUserId())
            ? getUserId()
            : 0,
      });
    }
  }, [ims_users, specialist_users]);

  const {
    ADMIN: { baseURL: data_source_url },
    API_BI: { BASE_URL_FOR_BI_APP: base_url },
  } = Config;

  // Fetch data
  useEffect(() => {
    dispatch(getIMSUsers());
    dispatch(getSpecialistUsers());
    dispatch(getServices());
  }, []);

  useEffect(() => {
    const userId = getUserId();
    const isSpecialistUser = isSpecialist();
    const isImsUser = isIMSUser();
    let filters = { ...accountFilter };
    if (!ims_ids.length || !specialist_ids.length) {
      return filters;
    }

    const imsUserInList = isImsUser && ims_ids.includes(userId);

    const specialistUserInList =
      isSpecialistUser && specialist_ids.includes(userId);
    filters = {
      ...filters,
      ims: imsUserInList ? userId : 0,
      specialist: specialistUserInList ? userId : 0,
    };

    dispatch(getAllAccounts(filters));
    return filters;
  }, [ims_ids.length, specialist_ids.length]);

  useEffect(() => {
    setDataSource(accountData);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: total,
      },
    });
  }, [accountData, total]);

  useEffect(() => {
    setSearchedAccount(dataSource);
  }, [dataSource]);

  const handleSwitchChange = (checked) => {
    setSecondarySpecialist(checked);
    let status = checked == true ? 1 : 0;
    setAccountFilter({
      ...accountFilter,
      show_secondary_specialist: status,
    });
    dispatch(
      getAllAccounts({ ...accountFilter, show_secondary_specialist: status })
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      className: "report-notification",
      key: "1",
      sorter: false,
      render: (text, record) => {
        return (
          <>
            <Link
              to={{
                pathname: `/account/detail/${record.id}`,
                myCustomProps: record,
              }}
            >
              {text}
            </Link>
            <span className="report-notification-wrapper">
              {notifications[record.id]?.critical > 0 ? (
                <a
                  target="_blank"
                  href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${
                    notifications[record.id]?.clientId
                  }&customerName=${record.name}&severity=Critical&status=Open`}
                >
                  <span className="noti-red">
                    {notifications[record.id]?.critical}
                  </span>
                </a>
              ) : (
                ""
              )}
              {notifications[record.id]?.medium > 0 ? (
                <a
                  target="_blank"
                  href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${
                    notifications[record.id]?.clientId
                  }&customerName=${record.name}&severity=Warning&status=Open`}
                >
                  <span className="noti-orange">
                    {notifications[record.id]?.medium}
                  </span>
                </a>
              ) : (
                ""
              )}
              {notifications[record.id]?.low > 0 ? (
                <a
                  target="_blank"
                  href={`${base_url}reports/v2/view/insight_workflow_notification?customer=${
                    notifications[record.id]?.clientId
                  }&customerName=${
                    record.name
                  }&severity=Information&status=Open`}
                >
                  <span className="noti-blue">
                    {notifications[record.id]?.low}
                  </span>
                </a>
              ) : (
                ""
              )}
            </span>
          </>
        );
      },
    },
    {
      title: "Reports",
      key: "2",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <div className="panel-action-button">
              <Tooltip title="View Reports">
                <Link
                  to={{
                    pathname: `/account/reports/${record.id}`,
                    myCustomProps: record,
                  }}
                >
                  <FaEye />
                </Link>
              </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      title: "Data Sources",
      align: "center",
      key: "3",
      render: (text, record) => {
        return (
          <>
            <div className="panel-action-button">
              <Tooltip title="View Data Sources">
                <a
                  target="_blank"
                  href={`${data_source_url}data-sources/${
                    record.id
                  }?token=${Token.encryptAuthParams(
                    localStorage.getItem("token")
                  )}`}
                >
                  <FaDatabase />
                </a>
              </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      // title: "Up For Renewal",
      title: () => (
        <div className="table-tooltip">
          Up For Renewal
          <Tooltip
            overlayClassName="table-tooltip-content"
            title="A red icon indicates at least one service for a client is 45 days away from their renewal date. A gray icon indicates a service’s Term Length is 1 or 2 months, which mean this service is effectively always up for renewal."
          >
            <span>
              <FaInfoCircle />
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "up_for_renewal",
      align: "center",
      key: "4",
      render: (_, record) => {
        return (
          <div className="renewal-button">
            {record.up_for_renewal_gray_services ? (
              <Popover
                content={
                  "Services Up for Renewal: " +
                  record.up_for_renewal_gray_services
                }
                overlayClassName="renewal-popover"
              >
                <HiRefresh />
              </Popover>
            ) : (
              ""
            )}

            {record.up_for_renewal_red_services ? (
              <Popover
                content={
                  "Services Up for Renewal: " +
                  record.up_for_renewal_red_services
                }
                overlayClassName="renewal-popover"
              >
                <HiRefresh color="#ff3032" />
              </Popover>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "Automated Reports",
      dataIndex: "automated_reports",
      align: "center",
      key: "5",
    },
    {
      title: "E-Commerce Platform",
      dataIndex: "platform",
      align: "center",
      key: "6",
    },
    {
      title: "Tier",
      dataIndex: "primary_tier",
      align: "center",
      key: "7",
    },
    {
      title: "Service Level",
      dataIndex: "service_level",
      align: "center",
      key: "8",
    },
    {
      title: "#Services",
      dataIndex: "service_count",
      align: "center",
      key: "9",
    },
    {
      title: "IMS",
      dataIndex: "ims",
      align: "left",
      key: "10",
    },
  ];

  const imsChangeHandler = (selectedValue) => {
    selectedValue = parseInt(selectedValue);
    setAccountFilter({
      ...accountFilter,
      ims: selectedValue,
    });
    dispatch(
      getAllAccounts({
        ...accountFilter,
        ims: selectedValue,
      })
    );
    setSearchedAccount(accountData);
    setHasSearched(true);
  };

  const specialistsChangeHandler = (selectedValue) => {
    selectedValue = parseInt(selectedValue);
    setAccountFilter({
      ...accountFilter,
      specialist: selectedValue,
    });
    dispatch(
      getAllAccounts({
        ...accountFilter,
        specialist: selectedValue,
      })
    );
    setSearchedAccount(accountData);
    setHasSearched(true);
  };

  const serviceChangeHandler = (selectedValue) => {
    selectedValue = parseInt(selectedValue);
    setAccountFilter({
      ...accountFilter,
      service: selectedValue,
    });
    dispatch(getAllAccounts({ ...accountFilter, service: selectedValue }));
    setSearchedAccount(accountData);
    setHasSearched(true);
  };

  const accountStatusChangeHandler = (selectedValue) => {
    selectedValue = parseInt(selectedValue);
    setAccountFilter({
      ...accountFilter,
      account_status: selectedValue,
    });

    if (selectedValue === 7) {
      history.push("freemium/accounts");
    } else {
      dispatch(
        getAllAccounts({ ...accountFilter, account_status: selectedValue })
      );
      setSearchedAccount(accountData);
      setHasSearched(true);
    }
  };

  const renewalStatusChangeHandler = (selectedValue) => {
    selectedValue = parseInt(selectedValue);
    setAccountFilter({
      ...accountFilter,
      renewal_status: selectedValue,
    });
    dispatch(
      getAllAccounts({ ...accountFilter, renewal_status: selectedValue })
    );
    setSearchedAccount(accountData);
    setHasSearched(true);
  };

  const accountTypeChangeHandler = (selectedValue) => {
    selectedValue = selectedValue;
    setAccountFilter({
      ...accountFilter,
      account_type: selectedValue,
    });
    dispatch(getAllAccounts({ ...accountFilter, account_type: selectedValue }));
    setSearchedAccount(accountData);
    setHasSearched(true);
  };

  const clientTypeChangeHandler = (selectedValue) => {
    setAccountFilter({
      ...accountFilter,
      client_type: selectedValue,
    });
    dispatch(getAllAccounts({ ...accountFilter, client_type: selectedValue }));
    setSearchedAccount(accountData);
    setHasSearched(true);
  };

  const searchHandler = (enteredValue) => {
    setAccountFilter({
      ...accountFilter,
      search: enteredValue,
    });
    dispatch(getAllAccounts({ ...accountFilter, search: enteredValue }));
    setSearchedAccount(accountData);
    setHasSearched(true);
  };

  const timerRef = useRef(null);
  // avoiding unnecessary re-renders when the timer changes.
  const handleSearch = (value) => {
    setAccountFilter((prevFilter) => ({
      ...prevFilter,
      search: value,
    }));
    setHasSearched(true);
    setSearchText(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (value.trim() !== "" && value.length > 3) {
        dispatch(getAllAccounts({ ...accountFilter, search: value }));
      } else if (value.trim() === "") {
        dispatch(getAllAccounts({ ...accountFilter, search: value }));
      }
    }, 500);
  };

  const handleChange = (pagination, filters, sorter) => {
    const limit = pagination.pageSize;
    let params = `page=${pagination?.current}`;
    setTableParams({
      pagination,
    });

    if (sorter.hasOwnProperty("column")) {
      const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
      dispatch(
        getAllAccounts(
          accountFilter,
          `&sort_column=${sorter.field}&sort_order=${sortOrder}`
        )
      );
    } else {
      dispatch(getAllAccounts(accountFilter, `${params}&limit=${limit}`));
    }
  };

  const { length: searchedAccountLength } = searchedAccount;
  const { pagination } = tableParams;

  const memoizedAccountTable = useMemo(
    () => (
      <Table
        columns={columns}
        key={(record) => record.primaryKey}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        dataSource={searchedAccount}
        onChange={handleChange}
        loading={isLoading}
        pagination={pagination}
      />
    ),
    [columns, searchedAccount, handleChange, isLoading, pagination]
  );

  return (
    <>
      <div className="panel-heading">
        <Row>
          <Col
            lg={{ span: 8 }}
            xs={{ span: 24 }}
            className="panel-heading-title"
          >
            <h2>Accounts</h2>
          </Col>
          <Col
            lg={{ span: 16 }}
            xs={{ span: 24 }}
            className="panel-heading-buttons"
          >
            <UpdateFromSalesforce />
            <ImportGoals />
            <ImportAccountSpends />
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <Row className="panel-body-input">
          <Col span={24} className="panel-body-input-left">
            {ims_ids.length > 0 && specialist_ids.length > 0 ? (
              <>
                {" "}
                <div className="panel-body-select">
                  <Select
                    dropdownClassName="input-dropdown-menu"
                    name="ims"
                    style={{ minWidth: 180 }}
                    showSearch
                    mode="single"
                    maxTagCount={1}
                    placeholder="Select IMS"
                    optionFilterProp="children"
                    onChange={imsChangeHandler}
                    defaultValue={
                      isIMSUser() && ims_ids.includes(getUserId())
                        ? getUserId()
                        : "0"
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value="0">All IMS</Option>
                    <Option value="-1">No IMS</Option>
                    {ims_users?.map((ims) => (
                      <Option
                        value={ims?.ims_id}
                        id={ims?.ims_id}
                        name={ims?.ims_id}
                      >
                        {ims?.ims_name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="panel-body-select">
                  <Select
                    dropdownClassName="input-dropdown-menu"
                    name="specialists"
                    style={{ minWidth: 180 }}
                    showSearch
                    mode="single"
                    maxTagCount={1}
                    placeholder="Select Specialists"
                    optionFilterProp="children"
                    onChange={specialistsChangeHandler}
                    defaultValue={
                      isSpecialist() && specialist_ids.includes(getUserId())
                        ? getUserId()
                        : "0"
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{
                      minWidth: "230px",
                    }}
                    dropdownRender={(menu) => (
                      <div className="toggle-select-dropdown">
                        {menu}
                        <div className="toggle-handler">
                          Show Secondary Accounts
                          <Switch
                            size="small"
                            checked={secondarySpecialist}
                            onChange={handleSwitchChange}
                          />
                        </div>
                      </div>
                    )}
                  >
                    <Option value="0">All Specialists</Option>
                    {specialist_users?.map((specialist) => (
                      <Option
                        value={specialist.specialist_id}
                        id={specialist.specialist_id}
                        name={specialist.specialist_id}
                      >
                        {specialist.specialist_name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="panel-body-select">
                  <Select
                    dropdownClassName="input-dropdown-menu"
                    name="service_id"
                    style={{ minWidth: 180 }}
                    showSearch
                    mode="single"
                    maxTagCount={1}
                    placeholder="All Services"
                    optionFilterProp="children"
                    onChange={serviceChangeHandler}
                    defaultValue="All Services"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value="0" id="0" name="0">
                      All Services
                    </Option>
                    {services_data?.map((service) => (
                      <Option
                        value={service.service_id}
                        id={service.service_id}
                        name={service.service_id}
                      >
                        {service.service_name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="panel-body-select">
                  <Select
                    dropdownClassName="input-dropdown-menu"
                    name="account_status"
                    style={{ minWidth: 180 }}
                    showSearch
                    mode="single"
                    maxTagCount={1}
                    placeholder="Select Account Status"
                    optionFilterProp="children"
                    onChange={accountStatusChangeHandler}
                    defaultValue="1"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value="1">Current</Option>
                    <Option value="3">Former</Option>
                    <Option value="4">Prospect</Option>
                    <Option value="7">Freemium Prospect</Option>
                  </Select>
                </div>
                <div className="panel-body-select">
                  <Select
                    dropdownClassName="input-dropdown-menu"
                    name="renewal_status"
                    style={{ minWidth: 180 }}
                    showSearch
                    mode="single"
                    maxTagCount={1}
                    placeholder="Select Renewal Status"
                    optionFilterProp="children"
                    onChange={renewalStatusChangeHandler}
                    defaultValue="0"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value="0">All Renewal Statuses</Option>
                    <Option value="1">Up For Renewal - All</Option>
                    <Option value="2">Up For Renewal - Red</Option>
                    <Option value="3">Up For Renewal - Gray</Option>
                  </Select>
                </div>
                <div className="panel-body-select">
                  <Select
                    dropdownClassName="input-dropdown-menu"
                    name="account_type"
                    style={{ minWidth: 180 }}
                    showSearch
                    mode="single"
                    maxTagCount={1}
                    placeholder="Select Account Type"
                    optionFilterProp="children"
                    onChange={accountTypeChangeHandler}
                    defaultValue=""
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value="">All Account Type</Option>
                    <Option value="Managed">Managed</Option>
                    <Option value="Consulting">Consulting</Option>
                  </Select>
                </div>
                <div className="panel-body-select">
                  <Select
                    dropdownClassName="input-dropdown-menu"
                    name="client_type"
                    style={{ minWidth: 180 }}
                    showSearch
                    mode="single"
                    maxTagCount={1}
                    placeholder="Select Client Type"
                    optionFilterProp="children"
                    onChange={clientTypeChangeHandler}
                    defaultValue=""
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value="">All Client Type</Option>
                    <Option value="eci">Exclusive</Option>
                    <Option value="Highnoon">Highnoon</Option>
                    <Option value="REQ">REQ</Option>
                    <Option value="Go Fish Digital">Go Fish Digital</Option>
                  </Select>
                </div>
                <div className="panel-body-select pbs-account-search">
                  <Search
                    placeholder="Account Name"
                    onSearch={searchHandler}
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchText}
                    allowClear
                    style={{ minWidth: 300 }}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="panel-body-content">
          {hasSearched ? memoizedAccountTable : ""}
        </Row>
      </div>
    </>
  );
}

export default Account;
