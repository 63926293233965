import API_BI from "../Utils/axios_bi";
import { ApiEndpointsBI } from "../Utils/constant";

const { GET_FACEBOOK_API, GET_TIKTOK_API } = ApiEndpointsBI;

export const getFacebookResponse = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(GET_FACEBOOK_API, props);
};

export const getTikTokResponse = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(GET_TIKTOK_API, props);
};
