import { Button, Col, Row } from "antd";
import { FiSquare } from "react-icons/fi";
import AdsDataTable from "./AdsDataTable";
import { useDispatch, useSelector } from "react-redux";
import Donut from "../../../Highcharts/Donut";
import Column from "../../../Highcharts/Column";
import { useEffect } from "react";
import { formDataAppend } from "../../../../Utils/helpers";
import { getDataTable } from "../../../../Redux/Actions/Thunk/dataTableThunk";

const ProductAdsDevicePerformace = (props) => {
	
	const {
        dataTable: {
          table: {
            [props?.cols[0]?.block?.block_id]: {
				[props?.cols[0]?.block?.block_id]: {
					json_data_chart = [],
					roas_goal,
					data: campaignData = []

				}={}
			} = {},
			[props?.block?.block_id]: {
				// data: campaignData = []
			} = {}
          },
        } = {},
      } = useSelector((state) => state);

	  const pie1 = [json_data_chart[0]];
	  const pie2 = [json_data_chart[1]];
	  const bar1 = [json_data_chart[4]];
	  const bar2 = [json_data_chart[5]];
	  const bar3 = [json_data_chart[6]];

	  const dispatch = useDispatch();

	  useEffect(() => {
		let data = props?.all_input_data;
		let formData = formDataAppend(data);
		dispatch(getDataTable(formData, props));
	  }, []); 

	  const campaignGroups = {};


	  campaignData.forEach((campaign) => {
		if(!campaignGroups[campaign.campaign_name]){
			campaignGroups[campaign.campaign_name] = [];
		}

		campaignGroups[campaign.campaign_name].push(campaign);
	  });

    return <>
			<div className="gads-content-single gads-device">
							<div className="gcs-title">
								<h4>Product Ads Device Performance</h4>
							</div>
							<div className="">
								<div className="gcs-charts">
									<Row gutter={64}>
										<Col span={12}>
											<h4>Ad Spend</h4>
											<div className="gsc-charts-wrapper"
												>{json_data_chart?.length ? <Donut data={pie1} /> : null}
											</div>
										</Col>
										<Col span={12}>
											<h4>Revenue</h4>
											<div className="gsc-charts-wrapper"
												>{json_data_chart?.length ? <Donut data={pie2} /> : null}
											</div>
										</Col>
									</Row>
									<Row gutter={48}>
										<Col span={8}>
											<h4>ROAS</h4>
											<div className="gsc-charts-wrapper">
												{json_data_chart?.length ? <Column data={bar1} /> : null}
											</div>
										</Col>
										<Col span={8}>
											<h4>CPC</h4>
											<div className="gsc-charts-wrapper">
												{json_data_chart?.length ? <Column data={bar2} /> : null}
											</div>
										</Col>
										<Col span={8}>
											<h4>Conversion Rate</h4>
											<div className="gsc-charts-wrapper">
												{json_data_chart?.length ? <Column data={bar3} /> : null}
											</div>
										</Col>
									</Row>
								</div>
								
                                <AdsDataTable
								{...props.cols.filter((prop) => prop?.block.block_id === 503)[0]}
								total_label = 'Total'
								all_input_data={props.all_input_data} 
								labels={[
									{"key": "device", "value": "Device", "format": "text"},
									{"key": "cost", "value": "Ad Spend", "format": "amount", "roundoff":0},
									{"key": "revenue", "value": "Revenue", "format": "amount", "roundoff":0},
									{"key": "roas", "value": "ROAS", "format": "percentage", "roundoff":0},
									{"key": "cpc", "value": "CPC", "format": "amount", "roundoff":0},
									{"key": "conversion_rate", "value": "Conversion Rate", "format": "percentage", "roundoff":0}
								]}
								label_key="device"
							/>

								<div className="gcs-device-table">
									{Object.keys(campaignGroups).length != 0 && <h4>Examples of product search device discrepancies</h4>}
									<Row gutter={[16, 24]}>
									{
											Object.keys(campaignGroups).map((campaign, index) => (
												<Col span={12}>
														<table key={index}>
															<thead>
																<tr>
																	<th>Device</th>
																	<th>Ad Spend</th>
																	<th>Revenue</th>
																	<th>ROAS</th>
																</tr>
															</thead>
															<tbody >
													{campaignGroups[campaign].map((campaign,index)=>(
																<tr key={index}>
																	<td>{campaign.device}</td>
																	<td>${Math.round(campaign.cost).toLocaleString()}</td>
																	<td>${Math.round(campaign.revenue).toLocaleString()}</td>
																	<td>${Math.round(campaign.roas).toLocaleString()}%</td>
																</tr>
													))}
															</tbody>
														</table>
												</Col>
											))
										}
									</Row>
								</div>

								{/* <div className="gsc-text">
									<p>
										Your mobile ROAS is <strong>{roas_goal}% higher</strong> than your
										desktop ROAS, indicating you may be underspending on mobile
										placements.
									</p>
								</div> */}
							</div>
							<div className="gads-imporve-btn">
								<a href="https://agital.com/ecommerce-analysis/">
									<Button>Improve my score</Button>
								</a>
							</div>
						</div>
    </>
}

export default ProductAdsDevicePerformace;