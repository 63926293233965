import React, { useState } from "react";
import { Button, Modal, Tooltip, Select, Switch } from "antd";
import { FaQuestionCircle } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FiEdit } from "react-icons/fi";

const Help = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [helpData, SetHelpData] = useState("");
  const showModal = () => {
    SetHelpData(props?.data?.layout[0]?.help_insights);
    setIsModalVisible(true);
  };
  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const showEditor = () => {
    setIsEditorVisible((current) => !current);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [value, setValue] = useState("");

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  return (
    <>
      <Tooltip title="Help">
        <Button onClick={showModal}>
          <FaQuestionCircle />
        </Button>
      </Tooltip>
      <Modal
        title={
          <>
            <span className="help-modal-title">
              <FaQuestionCircle />
              Help
            </span>
            <Button className="help-modal-title-btn" onClick={showEditor}>
              <FiEdit />
            </Button>
          </>
        }
        cancelButtonProps={{ style: { display: "none" } }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="report-help-modal"
        width={1000}
      >
        {isEditorVisible && (
          <div className="report-help-text">
            <div className="report-help-editor">
              <ReactQuill
                theme="snow"
                value={helpData}
                onChange={setValue}
                visible={isEditorVisible}
              />
            </div>
          </div>
        )}
        {<div>{renderHTML(helpData)}</div>}
      </Modal>
    </>
  );
};

export default Help;
