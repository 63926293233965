import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Tag, Input, message } from 'antd';
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";

const {
    API_BI: { baseURL },
} = config;

const TagInputBox = (props) => {
    const [id, setId] = useState(0);
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.id) {
            setId(props.id)
        }
        if (props.ips) {
            setTags(props.ips.split(','))
        }
    }, []);

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        const ipAddressPattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        if (inputValue && ipAddressPattern.test(inputValue) && !tags.includes(inputValue)) {
            setTags([...tags, inputValue]);
        }
        setInputValue('');
        setInputVisible(false);
        saveAppSettings(id, [...tags, inputValue]);
    };

    const handleTagClose = removedTag => {
        const updatedTags = tags.filter(tag => tag !== removedTag);
        setTags(updatedTags);
        saveAppSettings(id, updatedTags);
    };

    const saveAppSettings = (id, ips) => {
        dispatch({
            type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
        });
        API_BI.defaults.headers.common["Authorization"] =
            localStorage.getItem("token");
        API_BI.post(baseURL + "api/v1/save-app-settings", { id: id, ips: ips }).then((response) => {
            if (response.status == 200) {
                dispatch({
                    type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
                });
                message.success(response.data.message);
            }
        });
    }

    return (
        <div>
            {tags.map(tag => (
                <Tag
                    key={tag}
                    closable
                    onClose={() => handleTagClose(tag)}
                >
                    {tag}
                </Tag>
            ))}
            {inputVisible && (
                <Input
                    type="text"
                    size="small"
                    value={inputValue}
                    placeholder="IP Address"
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    style={{ width: 120 }}
                />
            )}
            {!inputVisible && (
                <Tag onClick={showInput} style={{ background: '#fff', borderStyle: 'dashed', cursor: 'pointer' }}>
                    + New IP
                </Tag>
            )}
        </div>
    );
};

export default TagInputBox;