import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Input,
  Space,
  Table,
  Button,
  Row,
  Col,
  Select,
  Switch,
  Badge,
  Tag,
  Modal,
  Checkbox,
  Tooltip,
  Spin,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FaEye, FaPencilAlt, FaInfoCircle } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  getAllRoles,
  toggleUserStatus,
  deleteUser,
} from "../../Redux/Actions/Thunk/userThunk";
import InputSelect from "../../Components/InputControls/InputSelect";
import InputDate from "../../Components/InputControls/InputDate";
import UserUpdate from "./UserUpdate";
import { Link } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;

function Users(props) {
  const [dataSource, setDataSource] = useState([]);
  const [searchedUserList, setSearchedUserList] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filterColumn, setFilterColumn] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modaldata, setmodaldata] = useState([]);
  const [userData, setUserData] = useState([]);
  const [id, setId] = useState(null);
  const [hasRender, setRender] = useState(false);
  const [hideInactive, setHideInactive] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const showModal = (record) => {
    setmodaldata(record);
    setIsModalVisible(true);
  };

  const editData = (record) => { };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    users: {
      users: { data: UserData = [], total },
      isLoading,
      roles,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  // Fetch data
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllRoles());
  }, [dispatch]);

  useEffect(() => {
    setDataSource(UserData);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: total,
      },
    });
  }, [UserData, total]);

  useEffect(() => {
    setSearchedUserList(dataSource);
  }, [dataSource]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "2",
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname: `/users/form/${record.user_id}`,
              myCustomProps: record,
            }}
          >
            {text}
          </Link>
        );
      },
      filterDropdown: ({ confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={filterColumn}
              onChange={(e) => {
                setFilterColumn(e.target.value);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                dispatch(
                  getAllUsers(
                    `page=1&filter_column=first_name&filter_value=${filterColumn}`
                  )
                );
                confirm();
              }}
              onBlur={() => {
                getAllUsers(
                  10,
                  `page=1&filter_column=first_name&filter_value=${filterColumn}`
                );
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                dispatch(
                  getAllUsers(
                    `page=1&filter_column=first_name&filter_value=${filterColumn}`
                  )
                );
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setFilterColumn("");
                dispatch(getAllUsers(`page=1`));
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "3",
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname: `/users/form/${record.user_id}`,
              myCustomProps: record,
            }}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "1",
      sorter: true,
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname: `/users/form/${record.user_id}`,
              myCustomProps: record,
            }}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "Roles",
      dataIndex: "role_name",
      key: "4",
    },
    {
      title: "Status",
      dataIndex: "user_status",
      key: "5",
      render: (status, record, index) => {
        const onToggle = (checked) => {
          status = checked;
          handleSwitchChange(status, record.user_id);
        };
        return (
          <Space
            style={{
              display: record.role_name == "Administrator" ? "none" : "",
            }}
          >
            <Switch defaultChecked={status} onChange={onToggle} />
          </Space>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "6",
    },
    {
      title: "User Status",
      key: "user_status",
      dataIndex: "user_status",
      render: (text, record) => {
        if (record.password_exist == 1 && record.user_status == 1) {
          return (
            <Tag color="#8dc441" key={text}>
              Active
            </Tag>
          );
        } else {
          return (
            <Tag color="#dd4b39" key={text}>
              Not set up
            </Tag>
          );
        }
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "7",
      render: (text, record) => {
        if (record.password_exist == 0) {
          return (
            <span className="user-note">
              <Tooltip title="Password Not Set">
                <FaInfoCircle />
              </Tooltip>
            </span>
          );
        } else {
          return (
            <span className="user-note">
              <Tooltip
                title={"Last Logged in at " + record.last_login_at}
                overlayInnerStyle={{ textAlign: "center" }}
              >
                <FaInfoCircle />
              </Tooltip>
            </span>
          );
        }
      },
    },
    {
      key: "8",
      title: "Actions",
      render: (text, record) => {
        return (
          <>
            <div className="panel-action-button">
              <Tooltip title="View User">
                <span>
                  <FaEye onClick={() => showModal(record)} />
                </span>
              </Tooltip>
              <Tooltip title="Edit User">
                <Link
                  style={{
                    display: record.role_name == "Administrator" ? "none" : "",
                  }}
                  to={{
                    pathname: `/users/form/${record.user_id}`,
                    myCustomProps: record,
                  }}
                >
                  <FaPencilAlt />
                </Link>
              </Tooltip>
              <Tooltip title="Delete User">
                <span
                  style={{
                    display: record.role_name == "Administrator" ? "none" : "",
                  }}
                >
                  <AiFillDelete
                    onClick={() => {
                      onDelete(record.user_id);
                    }}
                  />
                </span>
              </Tooltip>

              {/* <Link to={`/update-user/${record.user_id}`}>
              <EditOutlined style={{ color: "#1890ff", marginLeft: "1px" }} />
            </Link> */}
            </div>
          </>
        );
      },
    },
  ];

  const handleSwitchChange = useCallback((status, user_id) => {
    if (status == false) {
      Modal.confirm({
        title:
          "Are you sure you want to deactivate the user? Note: Inactive users don't get to login.",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          dispatch(toggleUserStatus({ user_id: user_id, status: 0 }));
        },
      });
    } else {
      dispatch(toggleUserStatus({ user_id: user_id, status: 1 }));
    }
  }, []);

  const timerRef = useRef(null);
  const searchHandler = async (enteredValue) => {
    setSearchText(enteredValue);
    if (enteredValue) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        if (enteredValue.trim() !== "" && enteredValue.length > 2) {
          dispatch(
            getAllUsers(
              `page=${1}&search=${enteredValue}&hide_inactive=${hideInactive}`
            )
          );
        }
      }, 500);
    } else {
      dispatch(
        getAllUsers(`page=${1}&search=All&hide_inactive=${hideInactive}`)
      );
    }
    setSearchedUserList(UserData);
  };

  const handleChange = (pagination, filters, sorter) => {
    const limit = pagination.pageSize;
    let params = `page=${pagination?.current}`;
    setTableParams({
      pagination,
    });

    if (sorter.hasOwnProperty("column") && !searchText) {
      const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
      //params += `&sort_column=${sorter.field}&sort_order=${sortOrder}`;
      dispatch(
        getAllUsers(
          `&sort_column=${sorter.field}&sort_order=${sortOrder}&hide_inactive=${hideInactive}`
        )
      );
    }
    else if (searchText) {
      dispatch(
        getAllUsers(`${params}&limit=${limit}&search=${searchText}&hide_inactive=${hideInactive}`)
      );
    }
    else {
      dispatch(
        getAllUsers(`${params}&limit=${limit}&hide_inactive=${hideInactive}`)
      );
    }
  };

  const onChangeValue = (enteredValue) => {
    setSearchText(enteredValue);
    if (enteredValue) {
      dispatch(
        getAllUsers(
          `page=${1}&search=${enteredValue}&hide_inactive=${hideInactive}`
        )
      );
      setSearchedUserList(UserData);
    } else {
      dispatch(getAllUsers(`page=${1}&hide_inactive=${hideInactive}`));
      setSearchedUserList(UserData);
    }
  };

  const onDelete = useCallback((user_id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this user?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(deleteUser({ user_id: user_id }));
      },
    });
  }, []);

  const hideInactiveUsers = useCallback((e) => {
    let hide_inactive = e.target.checked ? 1 : 0;
    setHideInactive(hide_inactive);
    dispatch(
      getAllUsers(`hide_inactive=${hide_inactive}&search=${searchText}`)
    );
  }, [searchText]);

  return (
    <>
      <div className="panel-heading">
        <Row>
          <Col span={8} className="panel-heading-title">
            <h2>Users</h2>
          </Col>
          <Col span={16} className="panel-heading-buttons">
            <Link to={{ pathname: `/users/form` }}>
              <Button className="user-new">New User</Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        {/* <div className="nant-memberlist-search-field">
            <div className="row">
              <div className="col col-12 col-sm-6">
                <div className="nant-search-field text-right">
                  <Space direction="vertical">
                    <Search
                      placeholder="Enter name"
                      enterButton="Search"
                      size="large"
                      allowClear
                      onSearch={searchHandler}
                      style={{ float: 'right', width: 350 }}
                    />
                  </Space>
                </div>
              </div>
            </div>
          </div> */}
        <Row className="panel-body-input">
          <Col
            lg={{ span: 16 }}
            xs={{ span: 24 }}
            className="panel-body-input-left"
          >
            <div className="panel-body-select">
              <Select
                name="page_type"
                style={{ width: 150 }}
                showSearch
                mode="single"
                maxTagCount={1}
                placeholder="Select"
                optionFilterProp="children"
                onChange={onChangeValue}
                // onSearch={onSearchform}
                defaultValue="All"
                // open={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value="All">All Roles</Option>
                {roles.map((data) => (
                  <Option value={data.id} id={data.id} name={data.id}>
                    {data.display_name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="panel-body-select">
              <Search
                placeholder="User"
                //onSearch={searchHandler}
                onChange={(e) => searchHandler(e.target.value)}
                allowClear
                style={{ float: "right", width: 350 }}
              />
            </div>
          </Col>
          <Col
            lg={{ span: 8 }}
            xs={{ span: 24 }}
            className="panel-body-input-right"
          >
            <div className="panel-body-checkbox">
              <Checkbox onChange={hideInactiveUsers}>
                {" "}
                Hide Inactive Users
              </Checkbox>
            </div>
          </Col>
        </Row>
        {/* <Row className="input-filters">
            <Col span={20} className="input-filters-controls">
              <InputDate />
              <InputSelect />
            </Col>
          </Row> */}
        {/* <Row>
            <Col>
              <Search
                placeholder="User"
                onSearch={searchHandler}
                allowClear
                style={{ float: "right", width: 350 }}
              />
            </Col>
          </Row> */}
        {/* {loading ? <Loader /> : ""} */}
        <div className="panel-body-content">
          {searchedUserList.length ? (
            <Table
              columns={columns}
              key={(record) => record.primaryKey}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={searchedUserList}
              onChange={handleChange}
              loading={isLoading}
              pagination={tableParams.pagination}
            />
          ) : (
            ""
          )}

          <Modal
            className="user-details-modal"
            title="USER DETAILS"
            visible={isModalVisible}
            onOk={handleOk}
            okText="Close"
            onCancel={handleCancel}
            cancelButtonProps={{ style: { display: "none" } }}
            width={700}
          >
            <table className="user-details-table">
              <tr>
                <td>Name</td>
                <td>
                  {modaldata.first_name} {modaldata.last_name}
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{modaldata.email}</td>
              </tr>
              <tr>
                <td>Role</td>
                <td>{modaldata.role_name}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{modaldata.user_status == 1 ? "Active" : "InActive"}</td>
              </tr>
              <tr>
                <td>Last Modified Date</td>
                <td>{modaldata.updated_at}</td>
              </tr>
            </table>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Users;
