import * as AuthApi from "../../../Api/AuthApi";
import * as roleAction from "../roleAction";
import { message } from "antd";
import {
  GET_ACTIVE_REPORTS,
  FETCH_ROLE,
  UPDATE_ROLE_STATUS,
  FETCH_ROLE_DETAILS,
  DELETE_ROLE,
  TOGGLE_ROLE_STATUS,
  CHANGE_ROLE_TYPE,
  SET_DEFAULT_DASHBOARD,
  SET_REPORT_STATUS,
  UPDATE_SALESFORCE_PERMISSION,
  GET_ACTIVE_USERS,
  ADD_USERS_ROLE,
  ROLE_REQUEST
} from "../types/index";

export const getAllRoles = (params = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let roles = {};
      const response = await AuthApi.getRoles(params);
      if (response?.status == 200) {
        roles = {
          data: response?.data?.data,
          total: response?.data?.total_records,
        };
      }
      return dispatch(roleAction.getRole(roles));
    } catch (error) {
      console.error("Error in fetching users data: " + error);
    } finally {
    }
  };
};

export const getActiveReports = (params = "") => {
  return async (dispatch) => {
    try {
      let reports = {};
      const response = await AuthApi.getActiveReports(params);
      if (response?.status == 200) {
        reports = {
          data: response?.data?.data
        };
      }
      return dispatch(roleAction.getActiveReports(reports));
    } catch (error) {
      console.error("Error in fetching reports: " + error);
    } finally {
    }
  };
};

export const createNewRole = (props) => {
  let propsObj = JSON.parse(props);
  return async (dispatch) => {
    try {
      const response = await AuthApi.createNewRole(propsObj);
    } catch (error) {
      console.error("Error in creating new role: " + error);
    } finally {
    }
  };
};

export const updateRoleStatus = (props) => {
  const role_id = props.role_id;
  const status = props.status;
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let roles = {};
      const response = await AuthApi.getRolesAfterStatusUpdate(role_id, status);
      if (response?.status == 200) {
        roles = {
          data: response?.data?.data,
          message: response?.data?.message
        };
        showMessage('success', response?.data?.message);
      }
      else {
        showMessage('error', response?.data?.message);
      }
      return dispatch(roleAction.updateRoleStatus(roles));
    } catch (error) {
      console.error("Error in updating roles: " + error);
    } finally {
      return dispatch(getAllRoles());
    }
  };
};

export const getRoleDetails = (params = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let role_details = {};
      const response = await AuthApi.getRoleDetails(params);
      if (response?.status == 200) {
        role_details = {
          data: response?.data?.data,
          reports: response?.data?.reports,
          users: response?.data?.users,
          services: response?.data?.services
        };
      }
      return dispatch(roleAction.getRoleDetails(role_details));
    } catch (error) {
      console.error("Error in fetching role details: " + error);
    } finally {
    }
  };
};

export const deleteRole = (props) => {
  const role_id = props.role_id;
  // const status = props.status;
  return async (dispatch) => {
    try {
      let roles = {};
      const response = await AuthApi.deleteRole(role_id);
      if (response?.status == 200) {
        roles = {
          data: response?.data?.data,
          message: response?.data?.message,
        };
      }
      return dispatch(roleAction.deleteRole(roles));
    } catch (error) {
      console.error("Error in deleting roles: " + error);
    } finally {
    }
  };
};

export const toggleRoleStatus = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let roles = {};
      const response = await AuthApi.toggleRoleStatus(props);
      if (response?.status == 200) {
        roles = {
          data: response?.data?.data,
          message: response?.data?.message,
        };
        showMessage('success', response?.data?.message);
      }
      else {
        showMessage('error', response?.data?.message);
      }
      return dispatch(roleAction.toggleRoleStatus(roles));
    } catch (error) {
      console.error("Error in updating role status: " + error);
    } finally {
    }
  };
};

export const changeRoleType = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let roles = {};
      const response = await AuthApi.changeRoleType(props);
      if (response?.status == 200) {
        roles = {
          data: response?.data?.data,
          message: response?.data?.message,
        };
        showMessage('success', response?.data?.message);
      }
      else {
        showMessage('error', response?.data?.message);
      }
      return dispatch(roleAction.changeRoleType(roles));
    } catch (error) {
      console.error("Error in updating role type: " + error);
    } finally {
    }
  };
};

export const setDefaultDashboard = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let roles = {};
      const response = await AuthApi.setDefaultDashboard(props);
      if (response?.status == 200) {
        roles = {
          data: response?.data?.data,
          message: response?.data?.message,
        };
        showMessage('success', response?.data?.message);
      }
      else {
        showMessage('error', response?.data?.message);
      }
      return dispatch(roleAction.setDefaultDashboard(roles));
    } catch (error) {
      console.error("Error in updating default dashboard: " + error);
    } finally {
      return dispatch(getRoleDetails(`role_id=${props.role_id}`));
    }
  };
};

export const setReportStatus = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let roles = {};
      const response = await AuthApi.setReportStatus(props);
      if (response?.status == 200) {
        roles = {
          data: response?.data?.data,
          message: response?.data?.message,
        };
        showMessage('success', response?.data?.message);
      }
      else {
        showMessage('error', response?.data?.message);
      }
      return dispatch(roleAction.setReportStatus(roles));
    } catch (error) {
      console.error("Error in updating report status: " + error);
    } finally {
      return dispatch(getRoleDetails(`role_id=${props.role_id}`));
    }
  };
};

export const updateSalesforcePermission = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let roles = {};
      const response = await AuthApi.updateSalesforcePermission(props);
      if (response?.status == 200) {
        roles = {
          data: response?.data?.data,
          message: response?.data?.message,
        };
        showMessage('success', response?.data?.message);
      }
      else {
        showMessage('error', response?.data?.message);
      }
      return dispatch(roleAction.updateSalesforcePermission(roles));
    } catch (error) {
      console.error("Error in updating salesforce permission: " + error);
    } finally {
    }
  };
};

export const getActiveUsers = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let users = {};
      const response = await AuthApi.getActiveUsers(props);
      if (response?.status == 200) {
        users = {
          data: response?.data?.data
        };
      }
      return dispatch(roleAction.getActiveUsers(users));
    } catch (error) {
      console.error("Error in fetching active users: " + error);
    } finally {
    }
  };
};

export const addUsersToRole = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ROLE_REQUEST
      });
      let messages = {};
      const response = await AuthApi.addUsersToRole(props);
      if (response?.status == 200) {
        messages = {
          data: response?.data?.data,
          message: response?.data?.message,
        };
        showMessage('success', response?.data?.message);
      }
      else {
        showMessage('error', response?.data?.message);
      }
      return dispatch(roleAction.addUsersToRole(messages));
    } catch (error) {
      console.error("Error in adding users to role: " + error);
    } finally {
    }
  };
};

const showMessage = (message_type, message_text) => {
  if (message_type == 'success') {
    message.success(message_text);
  }
  else {
    message.error(message_text);
  }
};
