import { Button, Modal, Form, Alert, Table, Spin, Descriptions, message } from "antd";
import { Link } from "react-router-dom";
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
import { VscAccount } from "react-icons/vsc";
import { MdOutlinePermContactCalendar, MdOutlineCancel } from "react-icons/md";
import { FaEye, FaArrowAltCircleLeft } from "react-icons/fa";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";

const {
	API_BI: { baseURL },
} = config;

const UpdateFromSalesforce = (props) => {
	const [visible, setVisible] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [successDescription, setSuccessDescription] = useState("");
	const [successVisible, setSuccessVisible] = useState(false);
	const [errorDescription, setErrorDescription] = useState("");
	const [errorVisible, setErrorVisible] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModal2Open, setIsModal2Open] = useState(false);
	const [importHistory, setImportHistory] = useState([]);
	const [activitiesData, setActivitiesData] = useState([]);
	const [feedData, setFeedData] = useState([]);

	const dispatch = useDispatch();

	const showModal = () => {
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const handleImportOk = () => {
		setIsModalOpen(false);
	};

	const handleImportCancel = () => {
		setIsModalOpen(false);
	};

	const showImportDetailModal = (feed_id) => {
		setIsModal2Open(true);
		setIsModalOpen(false);
		const params = { feed_id: feed_id };
		dispatch({
			type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
		});
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.post(baseURL + "api/v1/import-history-detail", params).then(
			(response) => {
				if (response.status == 200) {
					setActivitiesData(response.data?.data?.feed_activities);
					setFeedData(response.data?.data?.feed_data);
				}
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
				});
			}
		);
	};

	const handleImportDetailOk = () => {
		setIsModal2Open(false);
	};

	const handleImportDetailCancel = () => {
		setIsModal2Open(false);
	};

	const displayAlert = (response) => {
		if (response.status == 200) {
			setSuccessDescription(response.data.message);
			setSuccessVisible(true);
			setErrorVisible(false);
		} else {
			setErrorDescription(response.data.message);
			setSuccessVisible(false);
			setErrorVisible(true);
		}
	};

	const importAccounts = useCallback((e) => {
		var params = {};
		if (props.account_id) {
			params = props;

			dispatch({
				type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
			});
		}
		else {
			setVisible(false);
			message.info("Salesforce account import is initiated and will take a few minutes to complete. Please check the import history section for the status. Thank you!", 30);
		}
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.post(baseURL + "api/v1/import-accounts", params).then((response) => {
			if (props.account_id) {
				displayAlert(response);
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
				});
			}
		});
	});

	const importContacts = useCallback((e) => {
		var params = {};
		if (props.account_id) {
			params = props;

			dispatch({
				type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
			});
		}
		else {
			setVisible(false);
			message.info("Salesforce contact import is initiated and will take a few minutes to complete. Please check the import history section for the status. Thank you!", 30);
		}
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.post(baseURL + "api/v1/import-contacts", params).then((response) => {
			if (props.account_id) {
				displayAlert(response);
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
				});
			}
		});
	});

	const cancelSFImport = useCallback((e) => {
		Modal.confirm({
			title: 'Confirmation',
			content: 'Are you sure to cancel the SF import?',
			onOk: () => {
				var params = {};
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
				});
				API_BI.defaults.headers.common["Authorization"] =
					localStorage.getItem("token");
				API_BI.post(baseURL + "api/v1/cancel-salesforce-import", params).then((response) => {
					displayAlert(response);
					dispatch({
						type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
					});
				});
			},
			onCancel: () => {
				// Handle cancellation if needed
			},
		});

	});

	const viewImportHistory = useCallback((e) => {
		setIsModalOpen(true);
		setVisible(false);
		var params = {};
		if (props.account_id) {
			params = props;
		}
		dispatch({
			type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
		});
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.post(baseURL + "api/v1/import-history", params).then((response) => {
			if (response.status == 200) {
				setImportHistory(response.data?.data);
			}
			dispatch({
				type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
			});
		});
	});

	const columns = [
		{
			title: "Type",
			dataIndex: "feed_type",
			key: "feed_type",
		},
		{
			title: "Data File",
			dataIndex: "file_name",
			key: "file_name",
			render: (text, record) => {
				var download_query_string = "?csv_file=csv/" + record.feed_type.toLowerCase() + "/" + text
				return <a href={baseURL + "api/v1/download-csv" + download_query_string}>{text}</a>;
			},
		},
		{
			title: "Date",
			dataIndex: "feed_date",
			key: "feed_date",
		},
		{
			title: "Status",
			dataIndex: "feed_status",
			key: "feed_status",
		},
		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			render: (_, record) => {
				return (
					<Button
						onClick={() => showImportDetailModal(record.id)}
						className="upload-modal-btn"
					>
						<FaEye />
					</Button>
				);
			},
		},
		{
			title: "Source",
			dataIndex: "source",
			key: "source",
		},
	];

	const activitiesColumns = [
		{
			title: "Event Code",
			dataIndex: "event_code",
			key: "event_code",
		},
		{
			title: "Description",
			dataIndex: "log_message",
			key: "log_message",
			render: (text) => (
				<span
					className="log-message"
					dangerouslySetInnerHTML={{ __html: text }}
				/>
			),
		},
		{
			title: "Date",
			dataIndex: "updated_at",
			key: "updated_at",
		},
	];

	const isLoading = useSelector((state) => state.accounts.isLoading);

	return (
		<>
			<Button className="account-update" onClick={showModal}>
				Update From Salesforce
			</Button>

			<Modal
				className="import-modals"
				title="SALESFORCE DATA"
				visible={visible}
				confirmLoading={confirmLoading}
				cancelText="Close"
				onCancel={handleCancel}
				okButtonProps={{ style: { display: "none" } }}
				width={800}
			>
				<Spin spinning={isLoading}>
					<Form className="import-modals-form-buttons">
						<Form.Item name="accounts">
							<Button onClick={importAccounts} icon={<VscAccount />}>
								SF Import Accounts
							</Button>
						</Form.Item>
						<Form.Item name="contacts">
							<Button
								onClick={importContacts}
								icon={<MdOutlinePermContactCalendar />}
							>
								SF Import Contacts
							</Button>
						</Form.Item>
						<Form.Item name="cancel">
							<Button
								onClick={cancelSFImport}
								icon={<MdOutlineCancel />}
							>
								Cancel SF Import
							</Button>
						</Form.Item>
						<Form.Item name="history">
							<Button icon={<FaEye />} onClick={viewImportHistory}>View Import History</Button>
						</Form.Item>
					</Form>
				</Spin>
				<Alert
					message="Success"
					description={successDescription}
					type="success"
					showIcon
					closable
					style={{ display: successVisible ? "flex" : "none" }}
				/>
				<Alert
					message="Error"
					description={errorDescription}
					type="error"
					showIcon
					closable
					style={{ display: errorVisible ? "flex" : "none" }}
				/>
			</Modal>
			<Modal
				className="history-modal"
				title={
					<>
						<Button
							className="modal-back-btn"
							icon={<FaArrowAltCircleLeft />}
							onClick={() => {
								setVisible(true);
								setIsModalOpen(false);
							}}
						>
							Go Back
						</Button>
						SALESFORCE IMPORT HISTORY
					</>
				}
				visible={isModalOpen}
				onOk={handleImportOk}
				cancelText="Close"
				onCancel={handleImportCancel}
				okButtonProps={{ style: { display: "none" } }}
				width={1200}
			>
				<Table
					columns={columns}
					dataSource={importHistory}
					loading={isLoading}
				/>
			</Modal>
			<Modal
				className="history-modal"
				title={
					<>
						<Button
							className="modal-back-btn"
							icon={<FaArrowAltCircleLeft />}
							onClick={() => {
								setIsModalOpen(true);
								setIsModal2Open(false);
							}}
						>
							Go Back
						</Button>
						SALESFORCE IMPORT DETAILS
					</>
				}
				visible={isModal2Open}
				onOk={handleImportDetailOk}
				cancelText="Close"
				onCancel={handleImportDetailCancel}
				okButtonProps={{ style: { display: "none" } }}
				width={1200}
			>
				<h4 className="activities-title">Feed Information</h4>
				<Descriptions bordered>
					<Descriptions.Item
						label="Store"
						style={{ display: feedData.feed_scope == "global" ? "none" : "" }}
						span={3}
					>
						{feedData.name}
					</Descriptions.Item>
					<Descriptions.Item label="Upload Date" span={3}>
						{feedData.timestamp}
					</Descriptions.Item>
					<Descriptions.Item label="Data File" span={3}>
						{feedData.file_name}
					</Descriptions.Item>
					<Descriptions.Item label="Type" span={3}>
						{feedData?.feed_type}
					</Descriptions.Item>
					<Descriptions.Item label="Status" span={3}>
						{feedData.status}
					</Descriptions.Item>
					<Descriptions.Item label="Log Message" span={3}>
						<div dangerouslySetInnerHTML={{ __html: feedData.log_message }} />
					</Descriptions.Item>
				</Descriptions>

				<h4 className="activities-title">Activities</h4>
				<Table
					columns={activitiesColumns}
					dataSource={activitiesData}
					pagination={false}
				/>
			</Modal>
		</>
	);
};

export default UpdateFromSalesforce;
