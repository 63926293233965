import React, { useEffect, useState } from "react";
import {
  Input,
  Table,
  Button,
  Modal,
  Select,
  Breadcrumb,
  Tag,
  Tooltip,
  Row,
  Col,
} from "antd";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAccountContacts } from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiUsers, FiSettings } from "react-icons/fi";
import { impersonate } from "../../Redux/Actions/Thunk/loginThunk";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { confirm } = Modal;

const { Search } = Input;
const { Option } = Select;

function AccountContacts(props) {
  let { account_id } = useParams();
  const [accountId, setAccountId] = useState([]);
  const [impersonated, setImpersonated] = useState(false);

  const {
    login: { impersonateUser: impersonateUser = false } = {},
    accounts: { account_contacts: accountContacts = [], isLoading } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const datasource = accountContacts.data;
  // Fetch data
  useEffect(() => {
    dispatch(getAccountContacts({ account_id }));
  }, []);
  useEffect(() => {
    if (impersonateUser) {
      setImpersonated(true);
      history.push("/");
    }
  }, [impersonateUser]);

  const doImpersonate = (id) => {
    dispatch(impersonate({ id }));
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: false,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: false,
    },
    {
      title: "Decision Maker",
      dataIndex: "decision_maker",
      key: "decision_maker",
      sorter: true,
      render: (text, record) => {
        return <>{text == 1 ? "Yes" : "No"}</>;
      },
    },
    {
      title: "Primary Contact",
      dataIndex: "primary_contact",
      key: "primary_contact",
      sorter: true,
      render: (text, record) => {
        return <>{text == 1 ? "Yes" : "No"}</>;
      },
    },
    {
      title: "User Status",
      dataIndex: "status",
      key: "status",
      render: (text, status) => {
        return (
          <>
            {status.password_length == 0 ? (
              <Tag color="#dd4b39">Not Setup</Tag>
            ) : (status.password_length && status.last_logged_in == null) ||
              status.password_sent_at ? (
              <Tag color="#f39c12">Passive</Tag>
            ) : (
              <Tag color="#8dc340 ">Active</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "",
      key: "note",
      render: (text, note) => {
        return (
          <>
            {note.password_length == 0 ? (
              <Tooltip title="Password not set.">
                <span className="contact-note">
                  <FaInfoCircle color="#0E0E0B" />
                </span>
              </Tooltip>
            ) : (note.password_length && note.last_logged_in == null) ||
              note.password_sent_at ? (
              <Tooltip title="Not logged in yet.">
                <span className="contact-note">
                  <FaInfoCircle color="#0E0E0B" />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={"Last Logged in " + note.last_logged_in}>
                <span className="contact-note">
                  <FaInfoCircle color="#0E0E0B" />
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Impersonate",
      dataIndex: "",
      key: "impersonate",
      render: (text, impersonate) => {
        return (
          <>
            {impersonate.password_length == 0 ? (
              /*<Button disabled className="impersonate-start-btn" title="Start">
								Start
							</Button>*/
              <Button
                className="impersonate-start-btn"
                title="Start"
                onClick={() => doImpersonate(impersonate.id)}
              >
                Start
              </Button>
            ) : (
              <Button
                className="impersonate-start-btn"
                title="Start"
                onClick={() => doImpersonate(impersonate.id)}
              >
                Start
              </Button>
            )}
          </>
        );
      },
    },
  ];
  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Accounts", link: "/accounts", icon: FiUsers },
    {
      text: accountContacts.account_name,
      link: "/account/detail/" + account_id,
      icon: FiSettings,
    },
    {
      text: "Account Contact",
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="panel-heading">
        <Row>
          <Col span={24} className="panel-heading-title">
            <h2>Account Contacts - {accountContacts.account_name}</h2>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <div className="panel-body-content">
          <Table
            columns={columns}
            key={(record) => record.primaryKey}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={datasource}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default AccountContacts;
