import Config from "../../Config";
import {
  getTiktokApi,
  tiktokCredentialsAPI,
} from "../../Api/datasources/tiktokAPI";
import { Token } from "../../Utils/helpers/token";
import { StringHelper } from "../../Utils/helpers/string";

const {
  API_BI: { BASE_URL_FOR_BI_APP },
} = Config;

class TiktokConfig {
  constructor() {
    // grant_type={0}&code={1}&client_id={2}&client_secret={3}&redirect_uri={4}
    const environment = process.env.REACT_APP_ENV;
    this.tiktokCallBackURL = `https://biapp.exclusiveconcepts.com/data-sources/tiktok/set-auth-code/`;
    this.productionUrl = `https://ads.tiktok.com/marketing_api/auth?app_id={0}&redirect_uri=${this.tiktokCallBackURL}`;
    this.sandBoxUrl = `https://sandbox-ads.tiktok.com/open_api/?app_id={0}&redirect_uri=${this.tiktokCallBackURL}&response_type=code`;
    this.tiktokTokenAccessUrl =
      "https://business-api.tiktok.com/open_api/v1.3/oauth2/access_token/";
    this.consineScreenOpenUrl = this.productionUrl; //nOTE: I HAVE SET SANDBOX URL FOR NOW BUT IN PRODUCTION YOU MUST HAVE TO DYNAMICALLY SET PRODUCTION URL
    this.tiktokAppID = undefined;
    this.tiktokSecret = undefined;
  }
  async init() {
    if (this.tiktokAppID && this.tiktokSecret) {
      this.consineScreenOpenUrl = StringHelper.format(
        this.consineScreenOpenUrl,
        this.tiktokAppID
      );
      return {
        tiktokAppID: this.tiktokAppID,
        tiktokSecret: this.tiktokSecret,
      };
    }

    try {
      const { data } = await tiktokCredentialsAPI();
      this.tiktokAppID = Token.decryptAuthParams(data.secret.app_id);
      this.tiktokSecret = Token.decryptAuthParams(data.secret.secret);
      this.consineScreenOpenUrl = StringHelper.format(
        this.consineScreenOpenUrl,
        this.tiktokAppID
      );
      return {
        tiktokAppID: this.tiktokAppID,
        tiktokSecret: this.tiktokSecret,
      };
    } catch (error) {
      // alert("Something went wrong  . Please refresh the page and try again");
      throw new Error("Missing tiktok Secrets");
    }
  }

  async tiktokTokenAccess() {
    await this.init();
    return {
      tiktokAppId: this.tiktokAppID,
      tiktokSecret: this.tiktokSecret,
    };
  }
}
export const tiktokConfig = new TiktokConfig();
