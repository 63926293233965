/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { useState } from "react";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Modal, Spin, Switch, Button } from "antd";
import Config from "../../../Config";
import { Table } from "antd";
import axios from "axios";
import { BiTable } from "react-icons/bi";
import { formDataAppend } from "../../../Utils/helpers";

const ServiceCell = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seriesData, setSeriesData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [showChangeColumns, setShowChangeColumns] = useState(false);
  const [showAbsoluteChangeColumns, setShowAbsoluteChangeColumns] =
    useState(false);

  const handleToggleChangeColumns = (checked) => {
    setShowChangeColumns(checked);
  };

  const handleToggleAbsoluteChangeColumns = (checked) => {
    setShowAbsoluteChangeColumns(checked);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const {
    API: { baseURL: baseUrl_API },
  } = Config;

  const fetchData = async (url, data) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Something went wrong");
    }
  };

  const getInputData = async (props) => {
    try {
      let obj = props?.props?.extraParams?.all_input_data;
      Object.assign(obj, {
        country: props?.props?.record?.parent_country,
        region: props?.props?.record?.country,
      });
      let inputData = props?.props?.extraParams?.all_input_data;
      let formData = formDataAppend(inputData);
      const formdata = formData;

      setSpinning(true);

      const result = await fetchData(
        baseUrl_API + "v2/seo_performance/location_performance_city",
        formdata
      );
      setSeriesData(result?.data);
    } catch (error) {
      // Handle errors
      console.error(error.message);
    } finally {
      setSpinning(false);
    }
  };

  const openChart = (props) => {
    setIsModalOpen(true);
    getInputData(props);
  };

  var accountName = props?.props?.record?.country;

  const columns = [
    {
      dataIndex: "city",
      title: "City",
      key: "city",
      sorter: false,
    },
    {
      dataIndex: "sessions",
      title: "sessions",
      key: "sessions",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell value={record?.sessions} format="number" />
          </>
        );
      },
    },
    {
      dataIndex: "change_sessions",
      title: "change",
      key: "change_sessions",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell
              value={record?.change_sessions}
              format="percentage"
              metric={"change_sessions"}
            />
          </>
        );
      },
    },

    {
      dataIndex: "conversions",
      title: "conversions",
      key: "conversions",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell value={record?.conversions} format="number" />
          </>
        );
      },
    },
    {
      dataIndex: "change_conversions",
      title: "change",
      key: "change_conversions",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell
              value={record?.change_conversions}
              format="percentage"
              metric={"change_conversions"}
            />
          </>
        );
      },
    },
    {
      dataIndex: "revenue",
      title: "Revenue",
      key: "revenue",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell
              value={record?.revenue}
              format="amount"
              metric={"revenue"}
            />
          </>
        );
      },
    },
    {
      dataIndex: "change_revenue",
      title: "change",
      key: "change_revenue",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell
              value={record?.change_revenue}
              format="percentage"
              metric={"change_revenue"}
            />
          </>
        );
      },
    },
    {
      dataIndex: "conversion_rate",
      title: "Conversion Rate",
      key: "conversion_rate",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell
              value={record?.conversion_rate}
              format="percentage"
              metric={"conversion_rate"}
            />
          </>
        );
      },
    },
    {
      dataIndex: "change_conversion_rate",
      title: "Change",
      key: "change_conversion_rate",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell
              value={record?.change_conversion_rate}
              format="percentage"
              metric={"change_conversion_rate"}
            />
          </>
        );
      },
    },

    {
      dataIndex: "engagement_rate",
      title: "engagement rate",
      key: "engagement_rate",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell
              value={record?.engagement_rate}
              format="percentage"
              metric={"engagement_rate"}
            />
          </>
        );
      },
    },
    {
      dataIndex: "change_engagement_rate",
      title: "change",
      key: "change_engagement_rate",
      sorter: false,
      filter: "false",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <FormattedNumberCell
              value={record?.change_engagement_rate}
              format="percentage"
              metric={"change_engagement_rate"}
            />
          </>
        );
      },
    },
  ];
  const change_columns = columns
    .filter((column) => column.dataIndex.startsWith("change_"))
    .map((column) => column.key);
  const absolute_change_columns = columns
    .filter((column) => column.dataIndex.startsWith("absolute_change_"))
    .map((column) => column.key);

  const filteredColumns = columns.filter((column) => {
    if (!showChangeColumns && change_columns.includes(column.dataIndex)) {
      return false;
    }
    if (
      !showAbsoluteChangeColumns &&
      absolute_change_columns.includes(column.dataIndex)
    ) {
      return false;
    }
    return true;
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <span>
          <FormattedNumberCell
            value={props?.props?.value}
            format={props?.column?.format}
          />
        </span>
        <span style={{ cursor: "pointer" }}>
          <BiTable onClick={() => openChart(props)} />
        </span>

        {isModalOpen && (
          <Modal
            className="impact-modals"
            title={` Region - ${accountName} `}
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width="80%"
            footer={null}
          >
            {
              <Spin spinning={spinning}>
                <div
                  style={{
                    paddingBottom: "24px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <span className="datatable-toggle">
                    Show Absolute Change Column
                    <Switch
                      size="small"
                      checked={showAbsoluteChangeColumns}
                      onChange={handleToggleAbsoluteChangeColumns}
                    />
                  </span> */}
                  <span className="datatable-toggle">
                    Show Change Columns
                    <Switch
                      size="small"
                      checked={showChangeColumns}
                      onChange={handleToggleChangeColumns}
                    />
                  </span>
                </div>

                <Table
                  dataSource={seriesData}
                  columns={filteredColumns}
                  scroll={{ x: 400 }}
                  pagination={{
                    showSizeChanger: false,
                  }}
                />
              </Spin>
            }
          </Modal>
        )}
      </div>
    </>
  );
};

const OrganicSearchPerformance = (props) => {
  let cellClass = "";
  let blockId = props?.extraParams?.block?.block_id;
  const tblColumn = ["country"];
  if (blockId == 867) {
    let record_data = props?.record;
    if (!record_data.children && tblColumn.includes(props.column.dataIndex)) {
      return <ServiceCell props={props} />;
    } else {
      return (
        <span className={cellClass}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
            metric={props.column.dataIndex}
          />
        </span>
      );
    }
  }

  return (
    <span className={cellClass}>
      <FormattedNumberCell
        value={props.value}
        format={props.column.format}
        metric={props.column.dataIndex}
      />
    </span>
  );
};

export default OrganicSearchPerformance;
