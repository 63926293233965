/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { FaStore, FaAmazon } from "react-icons/fa";

const AmazonSponsoredAdsFulfillmentInsights = (props) => {
  console.log(props);
  const columnIds = ["fulfillment_channel"];
  if (columnIds.includes(props.column.dataIndex)) {
    if (props.value == "Merchant") {
      return (
        <FaStore title="Merchant" />
      );
    }
    if (props.value == "Amazon") {
      return (
        <FaAmazon title="Amazon" />
      );
    }
  }
  else {

    return (
      <span>
        <FormattedNumberCell value={props.value} format={props.column.format} metric={props.column.dataIndex} />
      </span>
    );
  }
};

export default AmazonSponsoredAdsFulfillmentInsights;
