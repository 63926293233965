import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Tooltip } from "antd";
import { formatColumns } from "../../../Utils/colorLogic";

const AmazonSearchTermPerformance = (props, text) => {
  let cellClass = "";
  let recordData = props?.record;
  const search_term_type = recordData?.search_term_type;
  const capitalizedSearchTermType =
    search_term_type.charAt(0).toUpperCase() + search_term_type.slice(1);

  if (props?.column?.dataIndex == "search_term") {
    return (
      <>
        <a
          href={"https://www.amazon.com/s?k=" + props?.value}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
            />
          </span>
        </a>
      </>
    );
  } else {
    return (
      <>
        <span
          className={formatColumns(
            props?.value,
            recordData,
            props?.goalData,
            props?.column?.dataIndex
          )}
        >
          <FormattedNumberCell
            value={props?.value}
            format={props?.column?.format}
          />
        </span>
      </>
    );
  }
};

export default AmazonSearchTermPerformance;
