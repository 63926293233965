import { ACTIONS_TYPES } from "../../Reducers/datasources/GoogleAdsReducer";

export function getAllGoogleAdsDataSourcesAction(payload) {
  return {
    type: ACTIONS_TYPES.GET_ALL_GOOGLE_ADS_DS,
    payload,
  };
}

export function getGoogleAdsDataSourcesAction(payload) {
  return {
    type: ACTIONS_TYPES.GET_GOOGLE_ADS_DS,
    payload,
  };
}

export function getGoogleAdsDateRangeDataSourcesAction(payload) {
  return {
    type: ACTIONS_TYPES.GET_GOOGLE_ADS_DS,
    payload,
  };
}

export function createGoogleAdsDataSourcesAction(payload) {
  return {
    type: ACTIONS_TYPES.CREATE_GOOGLE_ADS_DS,
    payload,
  };
}

export function updateGoogleAdsDataSourcesAction(payload) {
  return {
    type: ACTIONS_TYPES.UPDATE_GOOGLE_ADS_DS,
    payload,
  };
}

export function failedGoogleAdsDataSourcesAction(payload) {
  return {
    type: ACTIONS_TYPES.FAILED_GOOGLE_ADS_DS,
    payload,
  };
}
