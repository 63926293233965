import React, { useEffect, useState, useCallback, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { getScatterData } from "../../Redux/Actions/Thunk/SactterThunk";
import { formDataAppend, numberWithCommas } from "../../Utils/helpers";
import { Spin, Tabs } from "antd";

const Scatter = (props) => {
  const {
    scatter_data: {
      scatter_data: {
        [props?.block?.block_id]: { series_data: series_data = [], insight: insights } = {},
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    // if (props?.length == 0) {
    dispatch(getScatterData(formData, props));
    // }
  }, []);

  var config = JSON.parse(props?.block?.config);
  var labelsFormat = config?.yaxis?.labelsFormat;

  var chart = {
    chart: {
      type: "scatter",
      zoomType: "xy",
      renderTo: "block-row4col1",
      panning: true,
      panKey: "shift",
      selectionMarkerFill: "none",
      resetZoomButton: {
        position: {
          x: 0,
          y: -40,
        },
      },
    },
    title: {
      text: props?.block?.block_name,
    },
    xAxis: {
      title: {
        enabled: false,
        text: "",
      },
      labels: {
        format: window.currencySymbol + "{value}",
        formatter: function () {
          if (this.value > 1000000) {
            return window.currencySymbol + Highcharts.numberFormat(this.value / 1000000, 0) + "M";
          } else if (this.value > 1000) {
            return window.currencySymbol + Highcharts.numberFormat(this.value / 1000, 0) + "K";
          } else {
            return window.currencySymbol + this.value;
          }
        },
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        format: window.currencySymbol + "{value}",
        formatter: function () {
          if (labelsFormat === "${value}") {
            if (this.value > 1000000) {
              return (
                window.currencySymbol + Highcharts.numberFormat(this.value / 1000000, 0) + "M"
              );
            } else if (this.value > 1000) {
              return window.currencySymbol + Highcharts.numberFormat(this.value / 1000, 0) + "K";
            } else {
              return window.currencySymbol + this.value;
            }
          } else if (labelsFormat === "{value}%") {
            if (this.value > 1000000) {
              return Highcharts.numberFormat(this.value / 1000000, 0) + "M%";
            } else if (this.value > 1000) {
              return Highcharts.numberFormat(this.value / 1000, 0) + "K%";
            } else {
              return this.value + "%";
            }
          } else {
            if (this.value > 1000000) {
              return Highcharts.numberFormat(this.value / 1000000, 0) + "M";
            } else if (this.value > 1000) {
              return Highcharts.numberFormat(this.value / 1000, 0) + "K";
            } else {
              return this.value;
            }
          }
        },
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      reversed: false,
      enabled: true,
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
      },
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormatter: function () {
            return labelsFormat === "${value}"
              ? window.currencySymbol + numberWithCommas(this.y)
              : Highcharts.numberFormat(this.y, 2) + "%";
          },
        },
      },
    },
    series: series_data,
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chart}
        ></HighchartsReact>
        {insights != undefined && insights.length > 0 ? (
          <div className="insights">
            <h4>Insights</h4>
            <div dangerouslySetInnerHTML={{ __html: insights }} />
          </div>
        ) : (
          "-"
        )}
      </Spin>
    </>
  );
};

export default Scatter;
