import { Button, Modal, Form, Upload, Table, message, Select } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import React, { useCallback, useState } from "react";
import { FaEye, FaArrowAltCircleLeft } from "react-icons/fa";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import { useDispatch, useSelector } from "react-redux";
import API_BI from "../../Utils/axios_bi";
import Config from "../../Config";
const {
  API_BI: { baseURL },
} = Config;

const columns = [
  {
    title: "Upload Type",
    dataIndex: "upload_type",
    key: "upload_type",
  },
  {
    title: "Data File",
    dataIndex: "upload_file",
    key: "file",
    render: (text, record) => {
      var download_query_string =
        "?upload_type=" + record.upload_type.replaceAll(" ", "_").toLowerCase() + "/" + text;
      return (
        <a href={baseURL + "api/v1/download-spend-csv" + download_query_string}>
          {text}
        </a>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

const ImportAccountSpends = () => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [upload_type, setUploadType] = useState("");
  const [spendDataHistoryData, setSpendDataHistory] = useState([]);

  const { Option } = Select;

  const showModal = () => {
    setVisible(true);
  };
  const showImportHistoryModal = () => {
    setIsModalOpen(true);
    setVisible(false);
  };

  const [form] = Form.useForm();

  const file_props = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    beforeUpload(data_file) {
      const isValidType =
        data_file.type === "text/csv" ||
        data_file.type === "text/tab-separated-values";
      if (!isValidType) {
        form.setFields([
          {
            name: "data_file",
            errors: ["Invalid file type! File type must be csv or tsv."],
          },
        ]);
      }

      const isLt5M = data_file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        form.setFields([
          {
            name: "data_file",
            errors: ["File size must be smaller than 5MB!"],
          },
        ]);
      }

      if (isValidType && isLt5M) {
        form.setFields([
          {
            name: "data_file",
            errors: null,
          },
        ]);
        return false;
      }

      return (isValidType && isLt5M) || Upload.LIST_IGNORE;
    },
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const handleImportOk = () => {
    setIsModalOpen(false);
  };
  const handleImportCancel = () => {
    setIsModalOpen(false);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.accounts.isLoading);

  const onFinish = (values) => {
    const data = new FormData();
    data.append("data_file", values.data_file[0].originFileObj);
    data.append("upload_type", values.upload_type);

    dispatch({
      type: ACTIONS_TYPES.ACCOUNT_SPEND_UPLOAD_REQUEST,
    });

    fetch(baseURL + "api/v1/import-account-spends", {
      method: "POST",
      body: data,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          message.success(json.message);
        });
      } else {
        response.json().then((json) => {
          message.error(json.message);
        });
      }
      form.resetFields();
      dispatch({
        type: ACTIONS_TYPES.ACCOUNT_SPEND_UPLOAD_COMPLETE,
      });
    });
  };

  const upload_type_change = useCallback((upload_type) => {
    setUploadType(upload_type);
  });

  const downloadUrl = baseURL + "api/v1/download-spend-template";

  const viewSpendImportHistory = () => {
    setIsModalOpen(true);
    setVisible(false);
    dispatch({
      type: ACTIONS_TYPES.ACCOUNT_SPEND_IMPORT_REQUEST,
    });
    API_BI.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    API_BI.post(baseURL + "api/v1/view-spends-upload-history").then(
      (response) => {
        if (response.status == 200) {
          setSpendDataHistory(response.data?.data);
        }
        dispatch({
          type: ACTIONS_TYPES.ACCOUNT_SPEND_IMPORT_COMPLETE,
        });
      }
    );
  };

  return (
    <>
      <Button className="account-import" onClick={showModal}>
        Import Account Spends
      </Button>

      <Modal
        className="import-modals import-modals-spend"
        title="IMPORT SPENDS"
        visible={visible}
        onOk={handleOk}
        cancelText="Close"
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        confirmLoading={confirmLoading}
        width={700}
      >
        <Form
          form={form}
          name="upload_search_term_data"
          onFinish={onFinish}
          autoComplete="off"
          //   disabled={isLoading}
        >
          <Form.Item
            name="data_file"
            label="Import Spends from CSV: "
            valuePropName="fileList"
            getValueFromEvent={normFile}
            style={{ marginBottom: "20px" }}
            rules={[
              {
                required: true,
                message: "Please select file to upload",
              },
            ]}
          >
            <Upload {...file_props} maxCount={1}>
              <Button icon={<UploadOutlined />}>Choose a File</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="upload_type"
            label="Upload Type"
            style={{ marginBottom: "20px" }}
            initialValue={upload_type}
            rules={[
              {
                required: true,
                message: "Please select upload type",
              },
            ]}
          >
            <Select onChange={upload_type_change}>
              <Option value="">Please select</Option>
              <Option value="Weekly">Weekly</Option>
              <Option value="Monthly">Monthly</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="upload"
            label=""
            getValueFromEvent={normFile}
            className="import-modals-form-buttons"
          >
            <Button htmlType="submit" icon={<UploadOutlined />}>
              Upload and Import
            </Button>
            <Button icon={<DownloadOutlined />} href={downloadUrl}>
              Download Template
            </Button>
            <Button icon={<FaEye />} onClick={viewSpendImportHistory}>
              View Upload History
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="history-modal"
        title={
          <>
            <Button
              className="modal-back-btn"
              onClick={() => {
                setVisible(true);
                setIsModalOpen(false);
              }}
              icon={<FaArrowAltCircleLeft />}
            >
              Go Back
            </Button>
            Account Spends Upload History
          </>
        }
        visible={isModalOpen}
        onOk={handleImportOk}
        cancelText="Close"
        onCancel={handleImportCancel}
        okButtonProps={{ style: { display: "none" } }}
        width={700}
      >
        <Table
          columns={columns}
          dataSource={spendDataHistoryData}
          loading={isLoading}
        />
      </Modal>
    </>
  );
};

export default ImportAccountSpends;
