import { formatColumns } from "../../../Utils/colorLogic";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DataAnamolyDuplicate = (props, text) => {
  let cellClass = "";
  let recordData = props?.record;

  let blockId = props?.extraParams?.block?.block_id;
  let accountId = props?.extraParams?.account_id;
  let eparams = props?.extraParams?.all_input_data;
  let f_month = eparams?.f_month;
  let t_month = eparams?.t_month;
  let f_month_compare = eparams?.c_f_month;
  let t_month_compare = eparams?.c_t_month;
  let report = recordData?.report;
  let tbl = recordData?.table_name;
  let channel = props?.column?.key;
  let table = props?.value;

  if (props?.column?.dataIndex == "report") {
    return (
      <>
        <Link
          to={
            "/reports/v2/view/data_anomaly_duplicate_drill/" +
            "?source=" +
            report +
            "&table=" +
            tbl
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
            />
          </span>
        </Link>
      </>
    );
  } else {
    return (
      <>
        <span
          className={formatColumns(
            props?.value,
            recordData,
            props?.goalData,
            props?.column?.dataIndex
          )}
        >
          <FormattedNumberCell
            value={props?.value}
            format={props?.column?.format}
          />
        </span>
      </>
    );
  }
};

export default DataAnamolyDuplicate;
