import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Switch,
  List,
  Badge,
  Tooltip,
  Popover,
} from "antd";
import { FaEye } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountDetail,
  getAccountReports,
  getFreemiumAccountDetail,
} from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";

import AccountServiceSettings from "../../Pages/Accounts/AccountServiceSettings";
import UpdateFromSalesforce from "../../Components/Modals/UpdateFromSalesforce";
import UploadSearchTermData from "../../Components/Modals/UploadSearchTermData";
import PageTypeCategory from "../../Components/Modals/PageTypeCategory";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiUsers } from "react-icons/fi";
import Config from "../../Config";

const {
  ADMIN: { baseURL: data_source_url },
} = Config;

function FreemiumAccountDetail(props) {
  let { account_id } = useParams();

  const [accountId, setAccountId] = useState("");
  const [ims, setIms] = useState("");
  const [services, setServices] = useState([]);

  const {
    accounts: { account_details },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  // Fetch data
  useEffect(() => {
    setAccountId({ account_id });
    dispatch(getFreemiumAccountDetail({ account_id }));
  }, []);

  const account_details_service = Array.isArray(account_details?.services)
    ? account_details?.services
    : [account_details?.services];

  const adwords = [
    {
      index: "Monthly Advertising Budget",
      value:
        account_details?.adwords?.monthly_adspend == null ||
        account_details?.adwords?.monthly_adspend == ""
          ? "-"
          : account_details?.adwords?.monthly_adspend,
    },
    {
      index: "ROAS",
      value:
        account_details?.adwords?.roas == null ||
        account_details?.adwords?.roas == ""
          ? "-"
          : account_details?.adwords?.roas + "%",
    },
  ];

  const seo = [
    {
      index: "Data Source Verified",
      value:
        account_details?.seo?.customer_id ||
        account_details?.adwords?.customer_id
          ? "Yes"
          : "No",
    },
    {
      index: "Sign Up Date",
      value: account_details?.detail?.created_at,
    },
  ];

  const report_index = [];

  // const reports = (account_details?.adwordsReport?.length ? account_details?.adwordsReport : account_details?.seoReport);
  const reports = [
    ...(account_details?.adwordsReport || []),
    ...(account_details?.seoReport || []),
  ];
  const runCountArray = reports?.map((item) => ({
    run_count: item.run_count,
    run_date: item.run_date,
  }));

  runCountArray?.forEach((element, index) => {
    const reportItem = {};
    reportItem["run_date"] = element.run_date;
    reportItem["route"] =
      account_details?.services == 3
        ? "organic_search_scorecard_v2"
        : "google_ads_scorecard";
    reportItem["token"] = account_details?.detail?.token;
    reportItem["run_count"] = element.run_count;

    report_index.push(reportItem);
  });

  const columns = [
    {
      dataIndex: "index",
      key: "index",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <strong>{text}</strong>
        </div>
      ),
    },
    {
      dataIndex: "value",
      key: "value",
      render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
    },
  ];

  const report_columns = [
    {
      title: "REPORT GENERATED AT",
      dataIndex: "run_date",
      key: "run_date",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <strong>{text}</strong>
        </div>
      ),
    },
    {
      dataIndex: "value",
      key: "value",
      render: (text, record) => (
        <Button type="primary" shape="round" size="medium">
          {/* <Link
            to={`/freemium-agital-reports/v2/view/google_ads_scorecard/${record.token}?report_count=${record.run_count}`}
          > */}
          <Link
            to={`/freemium-agital-reports/v2/view/google_ads_scorecard/${record.token}`} target="_blank"
          >
            View
          </Link>
        </Button>
      ),
    },
  ];
  const seo_report_columns = [
    {
      title: "REPORT GENERATED AT",
      dataIndex: "run_date",
      key: "run_date",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <strong>{text}</strong>
        </div>
      ),
    },
    {
      dataIndex: "value",
      key: "value",
      render: (text, record) => (
        <Button type="primary" shape="round" size="medium">
          {/* <Link
            to={`/freemium-agital-reports/v2/view/organic_search_scorecard_v2/${record.token}?report_count=${record.run_count}`}
          > */}
          <Link
            to={`/freemium-agital-reports/v2/view/organic_search_scorecard_v2/${record.token}`} target="_blank"
          >
            View
          </Link>
        </Button>
      ),
    },
  ];

  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Accounts", link: "/accounts", icon: FiUsers },
    {
      text: account_details?.data?.account?.name,
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="panel-heading">
        <Row>
          <Col span={8} className="panel-heading-title">
            <h2>Account - {account_details?.detail?.company}</h2>
          </Col>
          <Col span={24} className="panel-heading-buttons">
            <UploadSearchTermData account_id={account_id} />
          </Col>
        </Row>
      </div>
      <div className="panel-body-content">
        <Row className="account-details-upper">
          <Col span={9}>
            <div className="account-details-info">
              <ul>
                <li key="account_name">
                  <span>Name</span>
                  <span>{account_details?.detail?.company}</span>
                </li>
                <li key="account_ims">
                  <span>Full Name</span>
                  <span>
                    {" "}
                    {account_details?.user?.first_name}{" "}
                    {account_details?.user?.last_name}
                  </span>
                </li>
                <li key="account_platform">
                  <span>Email</span>
                  <span>{account_details?.user?.email}</span>
                </li>
                <li key="account_website">
                  <span>Website</span>
                  <span>{account_details?.detail?.website}</span>
                </li>
              </ul>
            </div>
          </Col>
          <Col span={9}>
            <div className="account-details-info">
              <ul>
                <li key="account_primary_tier">
                  <span>Industry</span>
                  <span>{account_details?.detail?.industry}</span>
                </li>
                <li key="account_service_level">
                  <span>Sign Up Date</span>
                  <span>{account_details?.detail?.created_at}</span>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        {account_details_service.includes(1) && (
        <Row className="account-details-lower">
          <Col span={10}>
              <>
                <h2>GOOGLE ADS SCORECARD</h2>
                <Table
                  columns={columns}
                  dataSource={adwords.concat(seo)}
                  pagination={false}
                />
              </>
          </Col>
          <Col span={8} offset={2}>
            <Table
              columns={report_columns}
              dataSource={report_index}
              pagination={false}
            />
          </Col>
        </Row>
        )}
        {account_details_service.includes(3) && (
        <Row className="account-details-lower">
          <Col span={10}>
              <>
                <h2>ORGANIC SEARCH CONTENT SCORECARD</h2>
                <Table columns={columns} dataSource={seo} pagination={false} />
              </>
          </Col>
          <Col span={8} offset={2}>
            <Table
              columns={seo_report_columns}
              dataSource={report_index}
              pagination={false}
            />
          </Col>
        </Row>
        )}
      </div>
    </>
  );
}

export default FreemiumAccountDetail;
