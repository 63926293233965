import React, { useState } from "react";
import { Input, Button, Modal, Spin } from "antd";
import { FaInfoCircle } from "react-icons/fa";

const MMMInsights = (props) => {
  var rsquared = props?.rsquared;
  var accuracy = Math.abs(parseFloat(props?.accuracy).toFixed(2));
  var variance = Math.round(rsquared * 100);
  const [isModal1Open, setIsModal1Open] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const showModalInfluence = () => {
    setIsModal1Open(true);
  };
  const showModalAccuracy = () => {
    setIsModal2Open(true);
  };
  const handleCancel = () => {
    setIsModal1Open(false);
    setIsModal2Open(false);
  };

  return (
    <>
      <div className="forecast-insights">
        <Spin spinning={props?.isLoading}>
          {!isNaN(accuracy) ? (
            <div className="insights-wrapper">
              <p className="insights-single insights-actions">
                <strong className="insights-title">Influence</strong>
                <FaInfoCircle onClick={showModalInfluence} />
                <strong className="insights-value">{rsquared}</strong>
                <p className="rsquare-insights">
                  <span
                    className={`remarks remarks-${rsquareColorLogicButton(
                      rsquared
                    )}`}
                  >
                    {" "}
                    {rsquareColorLogic(rsquared)}
                  </span>
                  {rsquareInsightLogic(rsquared)}
                </p>
              </p>
              <p className="insights-single insights-actions">
                <strong className="insights-title">Accuracy </strong>
                <FaInfoCircle onClick={showModalAccuracy} />
                <strong className="insights-value">{accuracy}%</strong>
                <p className="accuracy-insights">
                  <span
                    className={`remarks remarks-${accuracyColorLogicButton(
                      accuracy
                    )}`}
                  >
                    {accuracyColorLogic(accuracy)}
                  </span>
                  {accuracyInsightLogic(accuracy)}
                </p>
              </p>
              <p className="insights-single insights-variance">
                Monthly changes in spend and seasonality account for
                <strong>{variance}% of the variance</strong>
                in monthly total website revenue over the last two years.
              </p>

              <p className="insights-single insights-text">
                This tool aims to help with guidance around expectations related
                to forecasting. The model is not a guarantee for actual results.
              </p>
            </div>
          ) : (
            ""
          )}
        </Spin>
      </div>
      <Modal
        title=""
        className="insights-modal"
        visible={isModal1Open}
        onOk={null}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        cancelText="Close"
        width={600}
      >
        <p>
          Influence is the r-squared of the model, which represents the strength
          of the relationship between the model and total revenue
        </p>
        <ul>
          <li>An R-squared greater than 0.8 is considered “Great”</li>
          <li>An R-squared between 0.65 and 0.8 is considered “Good”</li>
          <li>An R-squared between 0.4 and 0.65 is considered “Weak”</li>
          <li>An R-squared less than 0.4 is considered “Bad”</li>
        </ul>
      </Modal>
      <Modal
        title=""
        className="insights-modal"
        visible={isModal2Open}
        onOk={null}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        cancelText="Close"
        width={600}
      >
        <p>
          Accuracy represents the model's ability to correctly forecast total
          revenue
        </p>
        <ul>
          <li>
            Accuracy with an absolute value of less than 5% is considered “Very
            Good”
          </li>
          <li>
            Accuracy with an absolute value between 5% and 15% is considered
            “Good”
          </li>
          <li>
            Accuracy with an absolute value between 15% and 35% is considered
            “Weak”
          </li>
          <li>
            Accuracy with an absolute value greater than 35% is considered
            “Poor”
          </li>
        </ul>
      </Modal>
    </>
  );
};

function rsquareColorLogic(rsquared) {
  if (rsquared <= 0.4) {
    return "Bad!";
  } else if (0.4 < rsquared && rsquared < 0.65) {
    return "Weak!";
  } else if (0.65 <= rsquared && rsquared < 0.8) {
    return "Good!";
  } else {
    return "Great!";
  }
}

function rsquareColorLogicButton(rsquared) {
  if (rsquared < 0.4) {
    return "bad";
  } else if (0.4 < rsquared && rsquared < 0.65) {
    return "weak";
  } else if (0.65 < rsquared && rsquared < 0.8) {
    return "good";
  } else if (rsquared >= 0.8) {
    return "great";
  }
}

const rsquareInsightLogic = (rsquared) => {
  if (rsquared <= 0.4) {
    return "This model is not accurate. There is too much variance or missing information to accurately forecast revenue.";
  } else if (0.4 < rsquared && rsquared < 0.65) {
    return "There may be other marketing channels we need to include. Consultation work is needed to improve forecasting.";
  } else if (0.65 <= rsquared && rsquared < 0.8) {
    return "This model is accurate enough to model forecasting.";
  } else {
    return "This tool will provide a confident forecast. Majority of variance in revenue is explained by ad spend and seasonality.";
  }
};

const accuracyColorLogic = (accuracy) => {
  if (accuracy >= 35) {
    return "Poor!";
  } else if (accuracy > 15 && accuracy < 35) {
    return "Weak!";
  } else if (accuracy > 5 && accuracy < 15) {
    return "Good!";
  } else if (accuracy < 5) {
    return "Very Good!";
  }
};

const accuracyColorLogicButton = (accuracy) => {
  if (accuracy >= 35) {
    return "poor";
  } else if (accuracy > 15 && accuracy < 35) {
    return "weak";
  } else if (accuracy > 5 && accuracy <= 15) {
    return "good";
  } else {
    return "very-good";
  }
};

const accuracyInsightLogic = (accuracy) => {
  if (accuracy == 0) {
    return "The model is neither overestimating or underestimating revenue the last 24 months.";
  } else if (accuracy > 0) {
    return (
      "The model is overestimating revenue by " +
      accuracy +
      "% the last 24 months."
    );
  } else {
    return (
      "The model is underestimating revenue by " +
      accuracy +
      "% the last 24 months."
    );
  }
};

export default MMMInsights;
