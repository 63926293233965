import $ from "jquery";
import { Link } from "react-router-dom";
import { GoDashboard } from "react-icons/go";
import { Layout, Menu, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { AiOutlinePieChart } from "react-icons/ai";
import { RiListSettingsLine, RiSettings2Line } from "react-icons/ri";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import {
  FiLogOut,
  FiList,
  FiUsers,
  FiFile,
  FiAlertTriangle,
  FiSearch,
} from "react-icons/fi";

import { getUserGroup } from "../../Utils/user";
import { checkIfTokenHasExpired } from "../../Utils";
import impactLogo from "../../Assets/Images/gradientAgital.svg";

const { Sider } = Layout;

function SiderMenu({ logOut }) {
  const menuAdjust = () => {
    $(
      ".ant-layout-footer, .ant-layout-content, .ant-layout-header"
      ).toggleClass("sider-length");
      $(".main-sidebar").toggleClass("mobile-menu-adjust");
    };
    
  const user_groups = getUserGroup();
  const rootSubmenuKeys = ["6", "7"];
  const current_url = window.location.href;
  const [openKeys, setOpenKeys] = useState([""]);
  const [isMobile, setIsMobile] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const handleOnCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  // Check token expiry
  useEffect(() => {
    checkIfTokenHasExpired(localStorage.getItem("token"))
      .then((tokenExpired) => {
        if (tokenExpired) {
          logOut();
        } 
      })
      .catch((error) => {
        console.error("An unexpected error occurred:", error);
      });
  }, [current_url]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  React.useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);

    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const handleMenuClick = () => {
    if (isMobile) {
      setCollapsed(!collapsed);
      menuAdjust();
    }
  };

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={handleOnCollapse}
        logOut={logOut}
      >
        <div className="sider-toggle">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              id: "menuToggle",
              className: "trigger",
              onClick: () => {
                setCollapsed(!collapsed);
                menuAdjust();
              },
            }
          )}
        </div>
        <div className="sider-logo">
            <img src={impactLogo} alt="newagitalLogo" />
        </div>
        <Menu
          theme="light"
          defaultSelectedKeys={["1"]}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={handleMenuClick}
        >
          <Menu.Item key="1" icon={<GoDashboard />}>
            <span>Dashboard</span>
            <Link to="/" />
          </Menu.Item>
          {user_groups.includes("1") ? (
            <Menu.Item key="2" icon={<FiList />}>
              <span>Roles</span>
              <Link to="/roles" />
            </Menu.Item>
          ) : (
            ""
          )}
          {user_groups.includes("1") ? (
            <Menu.Item key="3" icon={<FiUsers />}>
              <span>Users</span>
              <Link to="/users" />
            </Menu.Item>
          ) : (
            ""
          )}
          {user_groups.includes("1") ||
          user_groups.includes("2") ||
          user_groups.includes("3") ? (
            <Menu.Item key="8" icon={<FiFile />}>
              <span>Accounts</span>
              <Link to="/accounts" />
            </Menu.Item>
          ) : (
            ""
          )}
          <Menu.Item key="5" icon={<AiOutlinePieChart />}>
            <span>Internal Reports</span>
            <Link to="/reports" />
          </Menu.Item>
          {user_groups.includes("1") ? (
            <>
              <Menu.SubMenu
                key="7"
                title="Admin Tools"
                icon={<RiListSettingsLine />}
              >
                <Menu.Item key="7.1" icon={<FiAlertTriangle />}>
                  <span>Alerts</span>
                  <Link to="/dev-notes" />
                </Menu.Item>
                <Menu.Item key="7.3" icon={<RiSettings2Line />}>
                  <span>App Settings</span>
                  <Link to="/app-settings" />
                </Menu.Item>
                <Menu.Item key="7.4" icon={<FiSearch />}>
                  <span>SEO Settings</span>
                  <Link to="/seo-settings" />
                </Menu.Item>
              </Menu.SubMenu>
            </>
          ) : (
            ""
          )}
        </Menu>

        <div className="main-sidebar-footer">
          <ul>
            <li>
              <Tooltip placement="top" title="Logout">
                <a onClick={logOut}>
                  <FiLogOut /> <span> Logout </span>
                </a>
              </Tooltip>
            </li>
          </ul>
        </div>
      </Sider>
    </>
  );
}

export default SiderMenu;
