import { Button, Modal, Form, Input, Select, Space, Tooltip, Spin, Alert } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
	MdOutlineRemoveCircleOutline,
	MdOutlineAddCircleOutline,
} from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import API_BI from "../../Utils/axios_bi";

const { Option } = Select;
const {
	API_BI: { baseURL },
} = config;

const PageTypeCategory = (props) => {
	const [visible, setVisible] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [pageTypeCategories, setPageTypeCategories] = useState([{ page_type: "", includes: "", excludes: "" }]);
	const [successDescription, setSuccessDescription] = useState("");
	const [successVisible, setSuccessVisible] = useState(false);
	const [errorDescription, setErrorDescription] = useState("");
	const [errorVisible, setErrorVisible] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (visible) {
			const params = props;
			dispatch({
				type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
			});
			API_BI.defaults.headers.common["Authorization"] =
				localStorage.getItem("token");
			API_BI.post(baseURL + "api/v1/page-type-categories", params).then((response) => {
				if (response.status == 200 && response.data?.data?.settings) {
					setPageTypeCategories(JSON.parse(response.data?.data?.settings));
				}
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
				});
			});
		}
	}, [visible]);

	const showModal = () => {
		setVisible(true);
	};

	const handleOk = () => {
		setConfirmLoading(true);
		setTimeout(() => {
			setVisible(false);
			setConfirmLoading(false);
		}, 2000);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const addRow = () => {
		setPageTypeCategories((prevState) => [...prevState, { page_type: "", includes: "", excludes: "" }]);
	};

	const removeRow = (index) => {
		setPageTypeCategories((prevState) => prevState.filter((category, i) => i !== index));
	};

	const handlePageTypeChange = (index, page_type_val) => {
		let data = [...pageTypeCategories];
		data[index]['page_type'] = page_type_val;
		setPageTypeCategories(data);
	};

	const handlePageTypeCategoryChange = (index, event) => {
		let data = [...pageTypeCategories];
		data[index][event.target.name] = event.target.value;
		setPageTypeCategories(data);
	};

	const onFinish = () => {
		var params = props;
		params = { ...params, page_type_categories: pageTypeCategories };
		dispatch({
			type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
		});
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.post(baseURL + "api/v1/save-page-type-categories", params).then((response) => {
			if (response.status == 200) {
				setPageTypeCategories(JSON.parse(response.data?.data?.settings));
			}
			displayAlert(response);
			dispatch({
				type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
			});
		});
	};

	const displayAlert = (response) => {
		if (response.status == 200) {
			setSuccessDescription(response.data.message);
			setSuccessVisible(true);
			setErrorVisible(false);
		} else {
			setErrorDescription(response.data.message);
			setSuccessVisible(false);
			setErrorVisible(true);
		}
	};

	const isLoading = useSelector((state) => state.accounts.isLoading);

	return (
		<>
			<Button className="account-import" onClick={showModal}>
				Page Type Category
			</Button>

			<Modal
				title={
					<>
						Page Type Category
						<Tooltip
							placement="right"
							title="When adding multiple strings of text to include/exclude logic, separate strings with a double pipe symbol: ||. In practice, this will look like this: Category Page: Include: /cat/||/collection/||/collections"
						>
							<FaInfoCircle style={{ marginLeft: 8 }} />
						</Tooltip>
					</>
				}
				className=" page-type-category"
				visible={visible}
				onOk={handleOk}
				cancelText="Close"
				onCancel={handleCancel}
				okButtonProps={{ style: { display: "none" } }}
				confirmLoading={confirmLoading}
				width={1000}
			>
				<Spin spinning={isLoading}>
					<Form layout="vertical" className="ptc-modal-form" onFinish={onFinish}>
						<Form.List name="page_type_categories">
							{() => (
								<>
									{pageTypeCategories.map((ptype, index) => (
										<Space
											className="ptc-lower"
											key={index}
											style={{
												display: "flex",
												marginBottom: 8,
											}}
											align="baseline"
										>
											<Form.Item label="Page Type">
												<Select name="page_type" value={ptype.page_type} onChange={(event) => handlePageTypeChange(index, event)}>
													<Option value="">Please select</Option>
													<Option value="Home Page">Home Page</Option>
													<Option value="Product Page">Product Page</Option>
													<Option value="Category Page">Category Page</Option>
													<Option value="Cart/Checkout Page">
														Cart/Checkout Page
													</Option>
													<Option value="Search Page">Search Page</Option>
													<Option value="Blog Page">Blog Page</Option>
												</Select>
											</Form.Item>
											<Form.Item label="Includes">
												<Input name="includes" type="text" value={ptype.includes}
													onChange={(event) =>
														handlePageTypeCategoryChange(index, event)
													} />
											</Form.Item>
											<Form.Item label="Excludes">
												<Input name="excludes" type="text" value={ptype.excludes}
													onChange={(event) =>
														handlePageTypeCategoryChange(index, event)
													} />
											</Form.Item>

											<Button
												className="ptc-button ptc-remove-btn"
												icon={<MdOutlineRemoveCircleOutline />}
												onClick={() => removeRow(index)}
											></Button>
										</Space>
									))}
									<Form.Item className="ptc-add">
										<Button
											className="ptc-button ptc-add-btn"
											icon={<MdOutlineAddCircleOutline />}
											onClick={() => addRow()}
										></Button>
									</Form.Item>
								</>
							)}
						</Form.List>
						<Form.Item
							className="import-modals-form-buttons"
							style={{ margin: "0px" }}
						>
							<Button htmlType="submit">Save</Button>
						</Form.Item>
					</Form>
					<Alert
						message="Success"
						description={successDescription}
						type="success"
						showIcon
						closable
						style={{ display: successVisible ? "flex" : "none", marginTop: '20px' }}
					/>
					<Alert
						message="Error"
						description={errorDescription}
						type="error"
						showIcon
						closable
						style={{ display: errorVisible ? "flex" : "none", marginTop: '20px' }}
					/>
				</Spin>
			</Modal>
		</>
	);
};

export default PageTypeCategory;
