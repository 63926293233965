import API_BI from "../../../Utils/axios_bi";
import { DataSourcesApiEndPoints } from "../../../Utils/constant";

const { amazonProfile } = DataSourcesApiEndPoints;

/**
 * Api Function to create Amazon profile data
 * @param {object} data - The Amazon profile data to be created
 * @returns {Promise<object>} - The created Amazon profile data
 */
export const amazonProfileApi = async (data) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  const response = await API_BI.post(amazonProfile, data);
  return response;
};
