// const PENDING = "PENDING";
// const REJECTED = "REJECTED";
// const FETCHED = "FETCHED";

// const reducer = (state = { isLoading: false }, action) => {
//   const { name, type } = action;
//   switch (type) {
//     case name + "_" + PENDING:
//       return { ...state, isLoading: false };
//     case name + "_" + FETCHED:
//       return { ...state, items: action.payload, isLoading: false };
//     case name + "_" + REJECTED:
//       return { ...state, isLoading: false, err: action.payload };
//     default:
//       return state;
//   }
// };

// const entities = (state = {}, action) => {
//   if (action.name) {
//     const name = action.name.toLowerCase();
//     return { ...state, [name]: reducer(state[name], action) };
//   } else {
//     return state;
//   }
// };

export const initialState = {
  items: {},
  isLoading: false,
};

export const ACTIONS_TYPES_INPUT = Object.freeze({
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  FETCHED: "FETCHED",
});

export function InputDynamicReducer(state = initialState, action) {
  const { name, type } = action;
  switch (type) {
    case name + "_" + ACTIONS_TYPES_INPUT.PENDING:
      return { ...state, isLoading: false };
    case name + "_" + ACTIONS_TYPES_INPUT.FETCHED:
      return { ...state, items: action.payload, isLoading: false };
    case name + "_" + ACTIONS_TYPES_INPUT.REJECTED:
      return { ...state, isLoading: false, err: action.payload };
    default:
      return state;
  }
}

// export function entities(state = {}, action) {
//   if (action.name) {
//     const name = action.name;
//     return { ...state, [name]: InputDynamicReducer(state[name], action) };
//   } else {
//     return state;
//   }
// }

// let state;
// state = entities(state, {
//   type: "COMPANIES_FETCHED",
//   name: "COMPANIES",
//   payload: [1, 2, 3, 4],
// });
// state = entities(state, {
//   type: "MESSAGES_FETCHED",
//   name: "MESSAGES",
//   payload: [1, 2, 3, 4],
// });

