import { useSelector } from "react-redux";
import TimelineChart from "../TimelineChart";

const RelevanceChart = (props) => {

    const {
        graph: {
            graph: {
                [props?.block?.block_id]: {
                    data: overall_relevance_report = []
                } = {},
            } = {},
            isLoading,
        } = {},
       } = useSelector((state) => state);
    return (
        <>
        <div className="organic-scorecard-relevance organic-scorecard-single">
						<div className="os-single-title">
							<h4>Relevance differential</h4>
							<span>GRADE: {overall_relevance_report[0]?.relevance_differential_grade}</span>
						</div>
						<div className="os-single-header">
							<div className="ossh-single">
								<h4>Total ranking keywords</h4>
								<h2>{overall_relevance_report[0]?.total_ranking_keywords.toLocaleString()}</h2>
							</div>
							<div className="ossh-single">
								<h4>Number on pg. 1</h4>
								<h2>{overall_relevance_report[0]?.keywords_at_page_1.toLocaleString()}</h2>
							</div>
							<div className="ossh-single">
								<h4>Percent on pg. 1</h4>
								<h2>{overall_relevance_report[0]?.relevance_differential.toFixed(2)}%</h2>
							</div>
						</div>
						<div className="os-single-content">
							<div
								style={{
									textAlign: "center",
									fontSize: "20px",
									margin: "30px 0",
								}}
							>
                                <TimelineChart {...props}/>
                            </div>
								<div className="insights-relevance">
									
								<p>
									Your site currently ranks for <b>{overall_relevance_report[0]?.total_ranking_keywords.toLocaleString()}</b> keywords on Google
									desktop – and <b>{overall_relevance_report[0]?.keywords_at_page_1.toLocaleString()} ({overall_relevance_report[0]?.relevance_differential.toFixed(2)}%)</b> of those keywords are on
									page 1.
								</p>
								<p>
									At AGITAL, the percent of keywords on page 1 are called the
									“relevance differential” and a healthy content program produces
									a number in the range 21%-30%.
								</p>
								<p>
									Low relevance differential (20%) is caused by poor use of
									content – either non-existent, too sparse, duplicated, poorly
									written, or over-optimized.
								</p>
								</div>
							</div>
							<div className="os-single-help">
								<h4>How we can help</h4>
								<p>
									At AGITAL we can utilize the relevance differential formula
									to discover your site’s biggest possible gains in traffic. If
									you are below the 21-30% threshold for keywords that rank on
									page 1, book a free analysis to identify the exact cause. Or if
									you’re within the healthy range, our free ecommerce growth plan
									can show you additional areas to improve.
								</p>
								<a href="https://agital.com/ecommerce-analysis/"
									target="_blank"
								>
									<button>Book a free SEO Analysis</button>
								</a>
                        </div>
					</div>
        </>
    )
}

export default RelevanceChart;