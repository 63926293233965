import { ACTIONS_TYPES } from "../Reducers/reportLayoutReducer";

export function getReportLayouts(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_LAYOUT,
    payload,
  };
}

export function getInputControls(payload, props) {
  return {
    type: props?.block?.param_name,
    payload,
    props,
  };
}

export function getAllInputControls(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_INPUT_CONTROLS,
    payload,
  };
}

export function getAccountAndReports(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ACCOUNT_AND_REPORTS,
    payload,
  };
}

export function getAdSpend(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_AD_SPEND,
    payload,
  };
}

export function setFilteredData(payload) {
  return {
    type: ACTIONS_TYPES.FILTERED_DATA,
    payload,
  };
}
