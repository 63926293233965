/**
 * @fileoverview Reducer for Pinterest Status Data Sources
 *
 * The reducer handles the state changes for the Pinterest.
 */

// Define the initial state of the reducer
export const initialState = {
  account_ds: {}, // for single account data source
  error_ds: {}, // error data sources
  isLoading: false, // loading state
};

// Define action types to be used in the reducer
export const ACTIONS_TYPES = Object.freeze({
  TIKTOK_DS_REQUEST: "TIKTOK_DS_REQUEST",
  GET_TIKTOK_DS: "GET_TIKTOK_DS", // Fetch Account Data Sources
  TIKTOK_ERROR_DS: "TIKTOK_ERROR_DS", // Error Account Data Sources
  FAILED_TIKTOK: "FAILED_TIKTOK",
  RESET_TIKTOK_DS: "RESET_TIKTOK_DS",
});

// Define the reducer function
export function tiktokReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  // Switch case to handle different types of actions
  switch (type) {
    case ACTIONS_TYPES.TIKTOK_DS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_TIKTOK_DS:
      return {
        ...state,
        isLoading: false,
        account_ds: action.payload,
      };
    case ACTIONS_TYPES.TIKTOK_ERROR_DS:
    case ACTIONS_TYPES.FAILED_TIKTOK:
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };

    case ACTIONS_TYPES.RESET_TIKTOK_DS:
      return {
        ...state,
        isLoading: false,
        account_ds: {},
        account_dss: {},
        error_ds: {},
      };

    // Return the initial state if no matching action is found
    default:
      return state;
  }
}
