/* This code is defining a constant variable named `listOfAllDataSources` that contains a list of data
sources. Each data source is represented by a key-value pair, where the key is a string representing
the name of the data source and the value is also a string representing the same name. */
export const listOfAllDataSources = {
  AccountWebsite: "AccountWebsite",
  amazonSponsorAds: "amazonSponsorAds",
  amazonMarketingService: "amazonMarketingService",
  tiktok: "tiktok",
  pinterest: "pinterest",
  facebook:"facebook"
};
