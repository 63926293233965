// import { ACTIONS_TYPES } from "../Reducers/accountReducer";

export const initialState = {
    isLoading: true,
    reply: {}
  };
  
  
  
  export const ACTIONS_TYPES = {
    GET_REPLY:"GET_REPLY",
    GET_ANNOTATIONS_REPLY:"GET_ANNOTATIONS_REPLY",
  
    
  }
  
  export const getAnnotationsCommentReducer = (state = initialState, action) => {
    switch (action.type) {
      case ACTIONS_TYPES.GET_REPLY:
        return {
          ...state,
          isLoading: true, 
        };
      case ACTIONS_TYPES.GET_REPLY:
        return {
          ...state,
          reply: action.payload,
          isLoading: false, 
        };
      case ACTIONS_TYPES.GET_ANNOTATIONS_REPLY:
        // const comments = action.payload;
        // const data = comments ? [...state,...comments] : [...state,comments]
        return {
          ...state,
          reply:action.payload,
          isLoading: false, 
        };
      default:
        return state;
    }
  };
  