import { ACTIONS_TYPES } from "../Reducers/channelPerformanceReducer";

export function getChannelPerformanceData(payload) {
    return {
      type: ACTIONS_TYPES.GET_CHANNEL_PERFORMANCE,
      payload,
    };
  }

export function fetchChannelPerformanceData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_CHANNEL_PERFORMANCE,
    payload,
  };
}