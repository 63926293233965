import * as indexApi from "../../../Api/indexApi";
import * as dataTableAction from "../dataTableAction";
import { ACTIONS_TYPES } from "../../Reducers/dataTableReducer";
import { animObject } from "highcharts";

export const getDataTable = (props, data) => {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: ACTIONS_TYPES.RESET_TABLE,
      //   payload: data?.block?.block_id,
      // });
      dispatch({
        type: ACTIONS_TYPES.REQUEST_TABLE,
        payload: data?.block?.block_id,
      });
      let table = {};
      const response = await indexApi.getApiPath(props, data);

      if (response?.status === 200) {
        table = {
          [data?.block?.block_id]: {
            data: response?.data?.data,
            response_data: response?.data?.response_data,
            goal_data: response?.data?.goal_data,
            ...(response?.data?.total_records
              ? { total_data: response?.data?.total_records[0]?.total }
              : []),
            ...(response?.data?.total_records
              ? { total_all_data: response?.data?.total_records[0] }
              : []),
            ...(response?.data?.nlg_text
              ? { nlg_text: response?.data?.nlg_text }
              : []),
            ...(response?.data?.labels
              ? { labels: response?.data?.labels }
              : []),
            ...(response?.data?.roas_goal
              ? { roas_goal: response?.data?.roas_goal }
              : []),
            ...(response?.data?.nlg_title
              ? { nlg_title: response?.data?.nlg_title }
              : []),
            ...(response?.data?.is_display
              ? { is_display: response?.data?.is_display }
              : []),
            ...(response?.data?.json_data_chart
              ? { json_data_chart: response?.data?.json_data_chart }
              : []),
            ...(response?.data?.clv_cohorts_val
              ? { clv_cohorts_val: response?.data?.clv_cohorts_val }
              : []),
            ...(response?.data?.hasOwnProperty("accuracy")
              ? { accuracy: response?.data?.accuracy }
              : []),
            ...(response?.data?.hasOwnProperty("rsquared")
              ? { rsquared: response?.data?.rsquared }
              : []),
            ...(response?.data?.optimized !== false
              ? { optimized: response?.data?.optimized }
              : []),
            ...(response?.data?.insight_data
              ? { insight: response?.data?.insight_data }
              : []),
            ...(response?.data?.total_all
              ? { total_all: response?.data?.total_all[0] }
              : []),
            ...(response?.data?.ga_insight
              ? { ga_insights_data: response?.data?.ga_insight }
              : []),
            ...(response?.data?.hasOwnProperty("organic_table_status")
              ? { organic_table_status: response?.data?.organic_table_status }
              : []),
            ...(response?.data?.hasOwnProperty("api_units_usage")
              ? { api_units_usage: response?.data?.api_units_usage }
              : []),
            ...(response?.data?.hasOwnProperty("abtest_winner")
              ? { abtest_winner: response?.data?.abtest_winner }
              : []),
            ...(response?.data?.hasOwnProperty("abtest_kpi")
              ? { abtest_kpi: response?.data?.abtest_kpi }
              : []),
            ...(response?.data?.subscribed_accounts
              ? {
                  subscribed_accounts: response?.data?.subscribed_accounts,
                }
              : []),
            ...(response?.data?.columns
              ? {
                  columns: response?.data?.columns,
                }
              : []),
          },
        };
        return dispatch(
          dataTableAction.getDataTable(data?.block?.block_id, table)
        );
      }
    } catch (error) {
      //console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
