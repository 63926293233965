import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleRedirect } from "../../Pages/DataSources/utils/handleRedirect";
import * as amazonSponsorAdsRedirectHandler from "../../services/utils/dataSource";

import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Redux/Actions/globals/loadingThunk";

const DataSourceRedirectPage = () => {
  // *Get amazon sponsor ads data from redux store
  const {
    global: { isLoading },
  } = useSelector((state) => state);

  const history = useHistory();
  const dispatch = useDispatch();

  // *Calls the backend apis for set data source using thunks
  // *Basically we are validating data_sources configuration and then we are calling the api to get the tokens and all that stuff.
  useEffect(() => {
    try {
      dispatch(setLoadingTrue());
      const parsedDataSource =
        amazonSponsorAdsRedirectHandler.deserializeObject();
      const {
        dataSourceMetaData: { dataSourceUrl },
      } = parsedDataSource;
      handleRedirect(history, dispatch).then((data) => {
        // *Render the the  page where user was redirected from
        dispatch(setLoadingFalse());
        const redirectUrl = `${dataSourceUrl}?is_redirect=true`;
        history.push(redirectUrl);
      });
    } catch (error) {
      history.push("/accounts");
    } finally {
      dispatch(setLoadingFalse());
    }
  }, []);

  return (
    <>
      <Spin
        size="large"
        spinning={true}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        Loading
      </Spin>
    </>
  );
};

export default DataSourceRedirectPage;
