import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Space,
  Table,
  Button,
  Row,
  Col,
  Switch,
  message,
  Alert,
  Modal,
  Select,
  Tooltip,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccounts,
  getIMSUsers,
  getSpecialistUsers,
  getServices,
  getFreemiumServices,
  getFreemiumAccounts,
} from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";
import { FaEye, FaCloudUploadAlt, FaCloud } from "react-icons/fa";
import BulkRequest from "../../Components/Modals/BulkRequest";
import { listOfMicroserviceAppURL } from "../../Config/constants/microServiceAppURL";
import { Token } from "../../Utils/helpers/token";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { debounce } from "lodash";
import {
  getFreemiumUpload,
} from "../../Api/freemiumUploadApi";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const { Search } = Input;
const { Option } = Select;

function FreemiumAccount(props) {
  let { id } = useParams();
  let rowIndex = 0;
  let optionIndex = 0;
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [accountFilter, setAccountFilter] = useState({
    ims: 0,
    specialist: 0,
    service: 0,
    account_status: 7,
    renewal_status: 0,
    account_type: "",
    search: "",
    account_service: 0,
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });

  const {
    accounts: {
      accounts: { data: accountData = [], total } = {},
      isLoading,
      ims_users,
      specialist_users,
      services: { data: services_data = [] } = {},
    },
  } = useSelector((state) => state);
  
  const dispatch = useDispatch();
  const history = useHistory();

  // Fetch data
  useEffect(() => {
    dispatch(getFreemiumServices());
    dispatch(getFreemiumAccounts(accountFilter));
  }, []);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: total,
      },
    });
  }, [accountData, total]);

  useEffect(() => {
    if (searchText.trim() !== "" && searchText.length > 3) {
      dispatch(getFreemiumAccounts(accountFilter));
    } else {
    }
  }, [searchText]);

  const convertToReportKey = (freemium_service) => {
    return freemium_service.toLowerCase().replaceAll(" ", "_");
  };

  const prev_bi_app = process.env.REACT_APP_BI_APP_URL;

  //handle upload freemium with upload
  const handleUploadFreemium = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const serviceId = e.currentTarget.getAttribute('service-id');
    try {
      const response = await getFreemiumUpload({
        freemium_id: dataId,
        service_id: serviceId,
        update_sf: 1,
      });
      // show success  message
      alert('Report update and salesforce update has been scheduled for tomorrow.')
    } catch (err) {
      // show exception err to client
      console.log('not ok')
    }
  };

//handle upload freemium wuthout upload  
  const handleUploadFreemiumNonUpload = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const serviceId = e.currentTarget.getAttribute('service-id');
    try {
      const response = await getFreemiumUpload({
        freemium_id: dataId,
        service_id: serviceId,
        update_sf: 3,
      });
      // show success  message
      alert('Report update has been scheduled for tomorrow.')
    } catch (err) {
      // show exception err to client
      console.log('not ok')
    }
  };
  const columns = [
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "1",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <Link
              to={{
                pathname: `/freemium/account/detail/${record.id}`,
                myCustomProps: record,
              }}
            >
              {text}
            </Link>
          </>
        );
      },
    },
    {
      title: "FULL NAME",
      key: "2",
      dataIndex: "full_name",
    },
    {
      title: "EMAIL",
      key: "3",
      dataIndex: "email",
    },
    {
      title: "SERVICE",
      dataIndex: "freemium_service",
      key: "4",
    },
    {
      title: "DATA SOURCE VERIFIED",
      dataIndex: "data_auth",
      align: "center",
      key: "5",
    },
    {
      title: "LEAD CREATED",
      dataIndex: "lead_id",
      align: "center",
      key: "6",
    },
    {
      title: "REPORT GENERATED AT",
      dataIndex: "report_date",
      align: "center",
      key: "7",
      sorter: true,
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      align: "center",
      key: "8",
      sorter: true,
    },
    {
      title: "ACTIONS",
      dataIndex: "service_count",
      align: "center",
      key: "9",
      render: (text, record) => {
        return (
          <>
            {record.report_date && (
              <div className="freemium-action">
                <div className="panel-action-button">
                  <Tooltip title="View Report">
                    {record.monthly_adspend !== "Agital" ? (
                      <a
                        target="_blank"
                        href={`${prev_bi_app}/freemium-reports/v2/view/${record.freemium_service
                          ?.toLowerCase()
                          .replace(/ /g, "_")}/${record.token}`}
                      >
                        <FaEye />
                      </a>
                    ) : (
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/freemium-agital-reports/v2/view/${record.freemium_service
                            ?.toLowerCase()
                            .replace(/ /g, "_")}/${record.token}`,
                          myCustomProps: record,
                        }}
                      >
                        <FaEye />
                      </Link>
                    )}
                  </Tooltip>
                  <Tooltip title="Update and Send to Salesforce">
                    <a href="javascript:void(0)" onClick={handleUploadFreemium} data-id={record.id} service-id={record.service_id}>
                      <FaCloudUploadAlt />
                    </a>
                  </Tooltip>
                  <Tooltip title="Update and Don't Send to Salesforce">
                    <a href="javascript:void(0)" onClick={handleUploadFreemiumNonUpload} data-id={record.id} service-id={record.service_id}>
                      <FaCloud />
                    </a>
                  </Tooltip>
                </div>
                <Switch className="freemium-switch" defaultChecked />
              </div>
            )}
          </>
        );
      },
    },
  ];

  const serviceChangeHandler = (selectedValue) => {
    selectedValue = parseInt(selectedValue);
    setAccountFilter({
      ...accountFilter,
      account_service: selectedValue,
    });

    dispatch(
      getFreemiumAccounts({ ...accountFilter, account_service: selectedValue })
    );
  };

  const timerRef = useRef(null);
  const searchHandler = (enteredValue) => {
    setSearchText(enteredValue);
    if (enteredValue) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        if (enteredValue.trim() !== "" && enteredValue.length > 2) {
          setAccountFilter({
            ...accountFilter,
            search: enteredValue,
          });
          dispatch(getFreemiumAccounts({ ...accountFilter, search: enteredValue }));
        }
      }, 500);
    }
    else {
      setAccountFilter({
        ...accountFilter,
        search: '',
      });
      dispatch(getFreemiumAccounts({ ...accountFilter, search: '' }));
    }


  };

  // const handleSearch = (value) => {
  // 	setAccountFilter({
  // 		...accountFilter,
  // 		search: value,
  // 	});
  // 	setSearchText(value);
  // };

  const handleChange = (pagination, filters, sorter) => {
    const limit = pagination.pageSize;
    let params = `page=${pagination?.current}`;
    setTableParams({
      pagination,
    });

    if (sorter.hasOwnProperty("column")) {
      const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
      dispatch(
        getFreemiumAccounts(
          accountFilter,
          `&sort_column=${sorter.field}&sort_order=${sortOrder}&${params}&limit=${limit}`
        )
      );

      return;
    }

    dispatch(getFreemiumAccounts(accountFilter, `${params}&limit=${limit}`));
  };

  return (
    <>
      {/* <Spin spinning={isLoading}> */}
      <div className="panel-heading">
        <Row>
          <Col span={8} className="panel-heading-title">
            <h2>Freemium Accounts</h2>
          </Col>
          <Col span={16} className="panel-heading-buttons">
            <BulkRequest />
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <Row className="panel-body-input">
          <Col span={24} className="panel-body-input-left">
            <div className="panel-body-select pbs-account-search">
              <Search
                placeholder="Search by Company, Fullname"
                onChange={(e) => searchHandler(e.target.value)}
                value={searchText}
                allowClear
                style={{ minWidth: 300 }}
              />
            </div>
            <div className="panel-body-select">
              <Select
                name="service_id"
                style={{ minWidth: 180 }}
                showSearch
                mode="single"
                maxTagCount={1}
                placeholder="All Services"
                optionFilterProp="children"
                onChange={serviceChangeHandler}
                defaultValue="0"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value="0">All Services</Option>
                {services_data?.map((service) => (
                  <Option
                    key={() => optionIndex++}
                    value={service.service_id}
                    id={service.service_id}
                    name={service.service_id}
                  >
                    {service.service_name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row className="panel-body-content">
          {
            <Table
              columns={columns}
              rowKey={() => rowIndex++}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={accountData}
              onChange={handleChange}
              loading={isLoading}
              pagination={tableParams?.pagination}
            />
          }
        </Row>
      </div>
      {/* </Spin> */}
    </>
  );
}

export default FreemiumAccount;
