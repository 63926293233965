import { ACTIONS_TYPES } from "../Reducers/ScatterReducer";

export function getScatterData(payload) {
  return {
    type: ACTIONS_TYPES.GET_SCATTER_DATA,
    payload,
  };
}

export function fetchScatterData(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_SCATTER_DATA,
    payload,
  };
}