import * as trendApi from "../../../Api/trendApi";
import * as trendAction from "../trendAction";
import { FETCH_TRENDS, REQUEST_DATA } from "../types/index";
import { ACTIONS_TYPES } from "../../Reducers/trendReducer";
import Config from "../../../Config";
import { formDataAppend } from "../../../Utils/helpers";

const {
  API: { baseURL, authKey },
} = Config;

export const getTrendsData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.RESET_TREND,
      });
      dispatch({
        type: ACTIONS_TYPES.REQUEST_TREND_DATA,
      });
      let trend = {};
      const response = await trendApi.getTrend(props, data);
      if (response?.status === 200) {
        trend = {
          [data?.block?.block_id]: {
            data: response?.data?.data,
            json_data: response?.data?.json_data_chart,
            labels: response?.data?.category_data,
            y_axis: response?.data?.y_axis,
            table_data: response?.data?.table_data,
            metrics: response?.data?.row_to_hide,
            tab_data: response?.data?.tab_data,
            table_status: response?.data?.table_status,
            trend_insights: response?.data?.insights_data,
            mom_yoy_header: response?.data?.mom_yoy_header,
            labels_category: response?.data?.category_data_new,
            ...(response?.data?.subscribed_accounts
              ? { subscribed_accounts: response?.data?.subscribed_accounts }
              : []),
          },
        };
        return dispatch(trendAction.getTrend(trend));
      }
    } catch (error) {
      console.error("Error in trends: " + error);
    } finally {
    }
  };
};

export const getTrendBreakdown = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FETCH_TREND_BREAKDOWN_REQUEST,
      });
      let trend_break = [];
      const response = await trendApi.getTrendBreakdown(props, data);
      if (response?.status === 200) {
        trend_break = {
          [data?.block?.block_id]: {
            data: response?.data?.data,
            json_data: response?.data?.trend_data_chart,
            labels: response?.data?.labels_data,
          },
          // y_axis: response?.data?.y_axis,
        };
      }
      return dispatch(trendAction.getTrendBreakdown(trend_break));
    } catch (error) {
      console.error("Error in trends break down: " + error);
    } finally {
    }
  };
};

export const getDevicePerformance = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FETCH_TREND_BREAKDOWN_REQUEST,
      });
      let device_performance = [];
      const response = await trendApi.getDevicePerformance(props, data);
      if (response?.status === 200) {
        device_performance = {
          [data?.block?.block_id]: {
            data: response?.data?.data,
            json_data: response?.data?.json_data_chart,
            table_columns: response?.data?.table_columns,
            labels_new: response?.data?.labels_new,
            table_total: response?.data?.table_total,
            insights_data: response?.data?.insights_data,
            response_data: response?.data?.response_data,
            total_records: response?.data?.total_records,
          },
        };
      }
      return dispatch(trendAction.getDevicePerformance(device_performance));
    } catch (error) {
      console.error("Error in device down: " + error);
    } finally {
    }
  };
};

export const getPerformance = (props, data) => {
  var data = props?.all_input_data;
  var formData = formDataAppend(data);
  var requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };
  fetch(baseURL + props?.block?.api_path, requestOptions)
    .then((response) => response.json())
    .catch((err) => {});
};
