import { Switch } from 'antd'
import React, { useContext } from 'react'

import { columnDataContext } from './DataTableClient'

const toggleButtonTypes = {
    SHOW_ABS_BTN: 'showAbsBtn',
    SHOW_METRICS: 'showMetrics',
    SHOW_CHANGE_BTN: 'showChangeBtn',
    SHOW_ABS_BUTTON: 'showAbsButton',
    SHOW_ORGANIC_BTN:'showOrganicBtn',
    SHOW_GOOGLE_ADS_BTN:'showGoogleAdsBtn',
    SHOW_CHANGE_BUTTON: 'showChangeButton',
    SHOW_GOOGLE_ANALYTICS_BTN: 'showGoogleAnalyticsBtn',
    SHOW_INCLUDE_EMAIL_SMS_BTN: 'showIncludeEmailSMSBtn'
}

const toggleButtonLabels = {
    showGoogleAdsBtn: 'Show Google Ads',
    showChangeBtn: 'Show Change Columns',
    showChangeButton: 'Show Change Columns',
    showAbsBtn: 'Show Absolute Change Columns',
    showAbsButton: 'Show Absolute Change Columns',
    showMetrics: 'Show MER/TROAS in Holistic Rows',
    showOrganicBtn: 'Show Organic Metrics Columns',
    showGoogleAnalyticsBtn: 'Show Google Analytics',
    showIncludeEmailSMSBtn: 'Include Email/SMS Data'
}


const ToggleFilter = ({size, toggleAbsoluteChangeColumnVisibility, toggleChangeColumnVisibility, defaultChecked, id}) => {

  const { config } = useContext(columnDataContext);

  const toggleButtonFns = {
    SHOW_CHANGE_BTN: (e) => {toggleChangeColumnVisibility(e, "changeColumns")},
    SHOW_CHANGE_BUTTON: (e) => {toggleChangeColumnVisibility(e, "changeColumns")},
    SHOW_METRICS: 'showMetrics',
    SHOW_ABS_BTN: (e) => {toggleAbsoluteChangeColumnVisibility(e,"absoluteChangeColumns")},
    SHOW_ABS_BUTTON: (e) => {toggleAbsoluteChangeColumnVisibility(e,"absoluteChangeColumns")},
    SHOW_ORGANIC_BTN:'showOrganicBtn',
    SHOW_GOOGLE_ADS_BTN:'showGoogleAdsBtn',
    SHOW_GOOGLE_ANALYTICS_BTN: 'showGoogleAnalyticsBtn',
    SHOW_INCLUDE_EMAIL_SMS_BTN: 'showIncludeEmailSMSBtn'
}


  return (
    <>
        {
            Object.entries(toggleButtonTypes).map(([type, configKey]) => {
                const isButtonEnabled = config.hasOwnProperty(configKey);
                
                return (
                    isButtonEnabled && 
                    <span className="datatable-toggle">
                        {toggleButtonLabels[configKey] || ''}
                        <Switch
                            id= {toggleButtonLabels[configKey] || ''}
                            size={size || 'small'}
                            defaultChecked={defaultChecked}
                            onChange={(e) => toggleButtonFns[type](e)}
                        />
                    </span>
                )
            })
        }

    </>
  )
}

export default ToggleFilter;
