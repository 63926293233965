import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PerformanceMaxInsightsReport = (props, text) => {
  let cellClass = "";
  let color = "";
  let recordData = props?.record;
  let blockId = props?.extraParams?.block?.block_id;

  if (blockId == 734) {
    if (props?.column?.dataIndex == "product" && recordData?.only_curr_ten != "") {
      if (recordData?.only_curr_ten == "spend") {
        color = "#008bff";
      } else if (recordData?.only_curr_ten == "revenue") {
        color = "orange";
      } else if (recordData?.only_curr_ten == "both") {
      }
      return (
        <>
          <span className={cellClass}>
            <strong style={{ color: color }}>
              <FormattedNumberCell
                value={props?.value}
                format={props?.column?.format}
                metric={props?.column?.dataIndex}
              />
            </strong>
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
              metric={props?.column?.dataIndex}
            />
          </span>
        </>
      );
    }
  } else {
    return (
      <>
        <span className={cellClass}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
            metric={props?.column?.dataIndex}
          />
        </span>
      </>
    );
  }
};

export default PerformanceMaxInsightsReport;
