export const initialState = {
  revenueRetentionData: [],
  lineChartData: {},
  isLoading: false,
  activeLineTab: {},
};

export const ACTIONS_TYPES = Object.freeze({
  FETCH_REVENUE_RETENTION_DATA: "FETCH_REVENUE_RETENTION_DATA",
  GET_REVENUE_RETENTION_DATA: "GET_REVENUE_RETENTION_DATA",
  GET_LINE_CHART_DATA: "GET_LINE_CHART_DATA",
  FETCH_LINE_CHART_DATA: "FETCH_LINE_CHART_DATA",
  REQUEST_DATA: "REQUEST_DATA",
  SET_ACTIVE_LINE_TAB: "SET_ACTIVE_LINE_TAB",
});

export function LineChartReducer(state = initialState, action) {
  const { key, payload } = action;
  switch (action.type) {
    case ACTIONS_TYPES.GET_REVENUE_RETENTION_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_REVENUE_RETENTION_DATA:
      return {
        ...state,
        isLoading: false,
        revenueRetentionData: action.payload,
      };
    case ACTIONS_TYPES.GET_LINE_CHART_DATA:
      return {
        ...state,
        isLoading: true,
      };
    // case ACTIONS_TYPES.FETCH_LINE_CHART_DATA:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     lineChartData: { ...state.lineChartData, ...action.payload },
    //   };
    case ACTIONS_TYPES.REQUEST_DATA:
      return {
        ...state,
        lineChartData: {
          // case ACTIONS_TYPES.SET_ACTIVE_LINE_TAB:
          //   return {
          //     ...state,
          //     activeLineTab: {
          //       ...state.activeLineTab,
          //       [action.payload.block_key]: action.payload.key,
          //     },
          //   };
          ...state.lineChartData,
          [payload]: {
            isLoading: true,
          },
        },
      };
    case ACTIONS_TYPES.FETCH_LINE_CHART_DATA:
      return {
        ...state,
        lineChartData: {
          ...state.lineChartData,
          [key]: {
            ...state.lineChartData[key],
            isLoading: false,
            ...payload,
          },
        },
      };
    case ACTIONS_TYPES.SET_ACTIVE_LINE_TAB:
      return {
        ...state,
        activeLineTab: {
          ...state.activeLineTab,
          [payload.block_key]: payload.tabKey,
        },
      };
    default:
      return state;
  }
}
