import { Button, Form, Input, Space, Typography } from "antd";
import { useHistory } from "react-router-dom";

const FacebookAdsInsightsUpdateConfigurationForm = ({
  form,
  onFinish,
  onFinishFailed,
}) => {
  const history = useHistory();
  const handleChange = (info) => {
    return info;
  };

  const handleRequest = (values) => {};

  const handleCancel = () => {
    history.push("/data-source/facebook-ads-insights");
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        name="google_console_form"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="User"
          name="user"
          rules={[
            {
              required: true,
              message: "User is required",
            },
          ]}
        >
          <Input placeholder="User" />
        </Form.Item>

        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>

            <Button
              style={{
                marginLeft: "1rem",
              }}
              type="primary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default FacebookAdsInsightsUpdateConfigurationForm;
