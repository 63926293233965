import React, { useEffect } from "react";
import { Row, Col, Spin } from "antd";
import logo from "../../Assets/Images/logo.png";
import semrush from "../../Assets/Images/semrush.png";
import {
	FaFile,
	FaCubes,
	FaQuoteLeft,
	FaEyeSlash,
	FaDownload,
} from "react-icons/fa";
import { formDataAppend } from "../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getScoreCard } from "../../Redux/Actions/Thunk/reports";

const TopChart = (props) => {

	const {
		keyMetrics:{	
			key_metrics: { data: KeyMetricsData = []},
			isLoading
		}
	} = useSelector((state) => state);

  const dispatch = useDispatch();

// testing Data
//   props.all_input_data.Client = 1616;
//   props.all_input_data.report_date = "2023-08-21";

  useEffect(()=>{
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getScoreCard(props, formData));

  }, []);

    return (
      <>
		<Spin spinning={isLoading}>
        <div className="organic-scorecard-grade">
						<Row gutter={32}>
							<Col span={12}>
								<div className="osg-left">
									<span>Overall Grade</span>
									<h2>{KeyMetricsData.overall_grade}</h2>
									<h4>SEO Scorecard</h4>
									<p>{props?.all_input_data?.company_name}</p>
								</div>
							</Col>
							<Col span={12}>
								<div className="osg-right">
									<ul>
										<li>
											<FaFile />
											Organic keyword positions
											<span>{KeyMetricsData.seo_grade}</span>
										</li> 
										<li>
											<FaCubes />
											Relevance differential
											<span>{KeyMetricsData.relevance_differential_grade}</span>
										</li>
										<li>
											<FaQuoteLeft />
											Striking distance keywords
											<span>{KeyMetricsData.striking_distance_total}</span>
										</li>
										<li>
											<FaEyeSlash />
											Hidden 404 errors
											<span>{KeyMetricsData.backlink_404_grade}</span>
										</li>
									</ul>
								</div>
							</Col>
						</Row>
					</div>
					</Spin>

      </>
    );
}

export default TopChart;