/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */

import { FormattedNumberCell } from "../../../Utils/helpers/formatting";

const CustomerLifetimeValue = (props) => {
  const columnIds = [
    "clv_month_0",
    "clv_month_1",
    "clv_month_2",
    "clv_month_3",
    "clv_month_4",
    "clv_month_5",
    "clv_month_6",
    "clv_month_7",
    "clv_month_8",
    "clv_month_9",
    "clv_month_10",
    "clv_month_11",
    "clv_month_12",
  ];

  var clv_array = [];
  var min_value = props.clv_cohorts_val.min_value;
  var max_value = props.clv_cohorts_val.max_value;
  var diff_value = props.clv_cohorts_val.diff_value;
  var second_min_value = props.clv_cohorts_val.second_min_value;

  let cellClass = "";
  if (columnIds.includes(props.column.dataIndex)) {
    if (props.value == 0) {
      var opacity_val = 0;
      return "-";
    } else if (props.value == min_value) {
      var opacity_val_min = ((second_min_value - min_value) / diff_value) * 100;
      var opacity_val = (opacity_val_min * 0.75).toFixed(2);
    } else {
      var opacity_val = (
        ((props.value - min_value) / diff_value) *
        100
      ).toFixed(2);
    }

    if (props.record[props.column.dataIndex] !== null) {
      if (opacity_val == 0) {
        var style = { backgroundColor: "rgba(0, 100, 0, 0)" };
      } else if (opacity_val <= 10) {
        var style = { backgroundColor: "rgba(0, 100, 0, 0.25)" };
      } else if (opacity_val >= 10 && opacity_val <= 20) {
        var style = { "background-color": "rgba(0, 100, 0, 0.3)" };
      } else if (opacity_val >= 20 && opacity_val <= 30) {
        var style = { "background-color": "rgba(0, 100, 0, 0.4)" };
      } else if (opacity_val >= 30 && opacity_val <= 40) {
        var style = { "background-color": "rgba(0, 100, 0, 0.45)" };
      } else if (opacity_val >= 40 && opacity_val <= 50) {
        var style = { "background-color": "rgba(0, 100, 0, 0.5)" };
      } else if (opacity_val >= 50 && opacity_val <= 60) {
        var style = { "background-color": "rgba(0, 100, 0, 0.55)" };
      } else if (opacity_val >= 60 && opacity_val <= 70) {
        var style = { "background-color": "rgba(0, 100, 0, 0.6)" };
      } else if (opacity_val >= 70 && opacity_val <= 80) {
        var style = { "background-color": "rgba(0, 100, 0, 0.65)" };
      } else if (opacity_val >= 80 && opacity_val <= 90) {
        var style = { "background-color": "rgba(0, 100, 0, 0.7)" };
      } else if (opacity_val >= 90 && opacity_val <= 100) {
        var style = { "background-color": "rgba(0, 100, 0, 0.75)" };
      }
    }
  }

  return (
    <div className={cellClass} style={style}>
      <FormattedNumberCell
        value={props.value}
        format={props.column.format}
        metric={props.column.dataIndex}
      />
    </div>
  );
};

export default CustomerLifetimeValue;
