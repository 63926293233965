import { ACTIONS_TYPES } from "../Reducers/roleReducer";

export function getRole(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ROLE,
    payload,
  };
}

export function getActiveReports(payload) {
  return {
    type: ACTIONS_TYPES.GET_ACTIVE_REPORTS,
    payload,
  };
}

export function updateRoleStatus(payload) {
  return {
    type: ACTIONS_TYPES.UPDATE_ROLE_STATUS,
    payload,
  };
}

export function afterUpdateRoleStatus(payload) {
  return {
    type: ACTIONS_TYPES.AFTER_UPDATE_ROLE_STATUS,
    payload,
  };
}

export function getRoleDetails(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ROLE_DETAILS,
    payload,
  };
}

export function deleteRole(payload) {
  return {
    type: ACTIONS_TYPES.DELETE_ROLE,
    payload,
  };
}

export function toggleRoleStatus(payload) {
  return {
    type: ACTIONS_TYPES.TOGGLE_ROLE_STATUS,
    payload,
  };
}

export function changeRoleType(payload) {
  return {
    type: ACTIONS_TYPES.CHANGE_ROLE_TYPE,
    payload,
  };
}

export function setDefaultDashboard(payload) {
  return {
    type: ACTIONS_TYPES.SET_DEFAULT_DASHBOARD,
    payload,
  };
}

export function setReportStatus(payload) {
  return {
    type: ACTIONS_TYPES.SET_REPORT_STATUS,
    payload,
  };
}

export function updateSalesforcePermission(payload) {
  return {
    type: ACTIONS_TYPES.UPDATE_SALESFORCE_PERMISSION,
    payload,
  };
}

export function getActiveUsers(payload) {
  return {
    type: ACTIONS_TYPES.GET_ACTIVE_USERS,
    payload,
  };
}

export function addUsersToRole(payload) {
  return {
    type: ACTIONS_TYPES.ADD_USERS_ROLE,
    payload,
  };
}
