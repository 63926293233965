import API_BI from "../Utils/axios_bi";
import API from "../Utils/axios";
import { ApiEndpointsBI } from "../Utils/constant";

const { GET_REPORTS_LAYOUTS, GET_ACCOUNT_AND_REPORTS, GET_AD_SPEND } = ApiEndpointsBI;

export const getReportLayouts = async (props) => {
  return await API_BI.post(GET_REPORTS_LAYOUTS, props);
};

export const getInputControls = async (props, data) => {
  return await API.post(data?.block?.option_url, props);
};

export const getAllInputControls = async (props, data) => {
  return await API_BI.post("api/v1/fetch-input-controls", props);
};

export const getAccountAndReports = async (account_id, report_key) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(GET_ACCOUNT_AND_REPORTS, { account_id, report_key });
};

export const getAdSpend = async(props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(GET_AD_SPEND,  props);
}