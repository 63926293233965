import API_BI from "../Utils/axios_bi";
import { ApiEndpointsBI } from "../Utils/constant";

const { GET_SERVICE_SETTINGS, SAVE_SERVICE_SETTINGS} = ApiEndpointsBI;

export const getServiceSettings = async (data, props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(GET_SERVICE_SETTINGS, data);
};

export const saveServiceSettings = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(SAVE_SERVICE_SETTINGS, props);
};
