import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Upload,
  Image,
  Breadcrumb,
  Form,
  Select,
  Checkbox,
  Input,
  Radio,
  Space,
  Spin,
  DatePicker,
} from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountAdvancedSetting,
  saveAccountAdvancedSetting,
} from "../../Redux/Actions/Thunk/accountThunk";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import API_BI from "../../Utils/axios_bi";

import config from "../../Config";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import { FiGrid, FiUsers, FiSettings } from "react-icons/fi";

const {
  API_BI: { baseURL },
} = config;

const { TextArea } = Input;

function AccountAdvancedSetting(props) {
  let { account_id } = useParams();
  const monthFormat = "YYYY-MM";
  const [accountId, setAccountId] = useState("");
  const [accountAdvancedSetting, setAccountAdvancedSetting] = useState("");
  const [showAdSpendDataStatus, setShowAdSpendDataStatus] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [csvIcon, setCsvIcon] = useState(null);
  const [defaultCombinedFeesServices, setDefaultCombinedFeesServices] =
    useState([]);
  const [
    budgetGoalsCombinedServicesDisabled,
    setBudgetGoalsCombinedServicesDisabled,
  ] = useState(false);
  const [
    budgetGoalsMasterServiceDisabled,
    setBudgetGoalsMasterServiceDisabled,
  ] = useState(false);
  const [form] = Form.useForm();

  const {
    accounts: {
      account_advanced_setting: {
        data: advanceSetting = [],
        services: BIServices = [],
        currencies: currencies = [],
        custom_reports: custom_reports = [],
        active_services: active_services = [],
        service_currency_values: service_currencies = [],
        combined_fees_setting: combined_fees_setting = [],
        account_data: account_information = [],
      } = {},
      isLoading,
    } = {},
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  // Fetch data
  useEffect(() => {
    setAccountId({ account_id });
    dispatch(getAccountAdvancedSetting({ account_id }));
  }, []);

  useEffect(() => {
    let isTrue = advanceSetting?.ad_spend == 1 ? true : false;
    setShowAdSpendDataStatus(isTrue);
  }, [advanceSetting]);

  var cOptions = [];
  var cDefault = [];
  custom_reports.map((c_report) => {
    cOptions.push({ label: c_report.name, value: c_report.id });
    if (c_report.account_id) {
      cDefault.push(c_report.id);
    }
  });

  var activeServices = [];
  active_services.map((active_service) => {
    activeServices.push({
      label: active_service.name,
      value: active_service.service_id,
    });
  });

  const style = {
    width: "100%",
  };

  const onChangeCombinedFeesServices = (checkedValues) => {
    setDefaultCombinedFeesServices(checkedValues);
  };

  const previewLogo = (file_name) => {
    const formData = new FormData();
    formData.append("file_type", "logo");
    formData.append("file_name", file_name);

    API_BI.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    API_BI.post(baseURL + "api/v1/account-file-preview", formData).then(
      (response) => {
        setLogoImage(response.data);
      }
    );
  };

  const logo_props = {
    name: "logo_file",
    action: "",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onChange(info) {
      const data = new FormData();
      data.append("file", info.file.originFileObj);
      data.append("upload_type", "logo");
      data.append("account_id", accountId.account_id);

      fetch(baseURL + "api/v1/account-file-upload", {
        method: "POST",
        body: data,
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            previewLogo(json.filename);
          });
        }
      });
    },
    beforeUpload(logo_file) {
      const isImage =
        logo_file.type === "image/jpeg" ||
        logo_file.type === "image/png" ||
        logo_file.type === "image/gif" ||
        logo_file.type === "image/apng" ||
        logo_file.type === "image/avif" ||
        logo_file.type === "image/webp";
      if (!isImage) {
        form.setFields([
          {
            name: "logo_file",
            errors: ["Invalid image type !"],
          },
        ]);
      }

      const isLt2M = logo_file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        form.setFields([
          {
            name: "logo_file",
            errors: ["Image must be smaller than 2MB !"],
          },
        ]);
      }

      if (isImage && isLt2M) {
        form.setFields([
          {
            name: "logo_file",
            errors: null,
          },
        ]);
        return true;
      }

      return (isImage && isLt2M) || Upload.LIST_IGNORE;
    },
    customRequest(logo_file) {},
  };

  const csv_props = {
    name: "query_categories_file",
    action: "",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onChange(info) {
      const formData = new FormData();
      formData.append("file", info.file.originFileObj);
      formData.append("upload_type", "csv");
      formData.append("account_id", accountId.account_id);

      API_BI.defaults.headers.common["Authorization"] =
        localStorage.getItem("token");
      API_BI.post(baseURL + "api/v1/account-file-upload", formData).then(
        (response) => {
          if (response.status == 200) {
            form.setFields([
              {
                name: "query_categories_file",
                errors: [response.data.message],
              },
            ]);
          }
        }
      );
    },
    beforeUpload(csv_file) {
      const isCSV =
        csv_file.type === "text/csv" ||
        csv_file.type === "text/tab-separated-values";
      if (!isCSV) {
        form.setFields([
          {
            name: "query_categories_file",
            errors: ["Invalid csv file !"],
          },
        ]);
      }

      const isLt2M = csv_file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        form.setFields([
          {
            name: "query_categories_file",
            errors: ["CSV must be smaller than 2MB !"],
          },
        ]);
      }

      if (isCSV && isLt2M) {
        form.setFields([
          {
            name: "query_categories_file",
            errors: null,
          },
        ]);
        return true;
      }

      return (isCSV && isLt2M) || Upload.LIST_IGNORE;
    },
    customRequest(csv_file) {},
  };

  useEffect(() => {
    let monthly_email_type = 1;
    const default_date = "2023-07-15";
    const dateObject = new Date(account_information.account_creation_date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const created_date = `${year}-${month}-${day}`;
    console.log(default_date, created_date);
    if (advanceSetting?.monthly_email_type == undefined) {
      if (created_date >= default_date) {
        monthly_email_type = 2;
      } else {
        monthly_email_type = 1;
      }
    } else {
      monthly_email_type = advanceSetting?.monthly_email_type;
    }
    form.setFieldsValue({
      account_id: accountId.account_id,
      combined_fees_month: combined_fees_setting?.month,
      combined_fees_month_value: moment(combined_fees_setting?.month).format(
        monthFormat
      ),
      combined_fees_services: combined_fees_setting?.active_service_ids,
      combined_fees_master_service: combined_fees_setting?.master_service_id,
      budget_goals_type: advanceSetting?.budget_goals_type == 2 ? 2 : 1,
      budget_goals_combined_services:
        advanceSetting?.budget_goals_combined_services,
      budget_goals_master_service: advanceSetting?.budget_goals_master_service,
      notes: advanceSetting?.notes,
      show_ad_spend_data: advanceSetting?.ad_spend == 1 ? true : false,
      include_amazon_data: advanceSetting?.include_amazon_data == 1 ? 1 : 0,
      custom_reports: cDefault,
      monthly_email_type: monthly_email_type,
      send_to_ims_specialists_only:
        advanceSetting?.send_to_ims_specialists_only == 1 ? 1 : 0,
      send_to_parent_account_users:
        advanceSetting?.send_to_parent_account == 1 ? 1 : 0,
      report_comparision_period:
        advanceSetting?.monthly_report_comparision_period == 2 ? 2 : 1,
    });
    setDefaultCombinedFeesServices(
      combined_fees_setting?.active_service_ids?.split()
    );
    if (
      advanceSetting == null ||
      (advanceSetting && advanceSetting?.budget_goals_type == 1)
    ) {
      setBudgetGoalsCombinedServicesDisabled(true);
      setBudgetGoalsMasterServiceDisabled(true);
    } else {
      setBudgetGoalsCombinedServicesDisabled(false);
      setBudgetGoalsMasterServiceDisabled(false);
    }
  }, [combined_fees_setting, advanceSetting]);

  useEffect(() => {
    let logo_name = advanceSetting?.logo;
    if (logo_name != undefined) {
      previewLogo(logo_name);
    }
    if (advanceSetting?.query_categories_file != undefined) {
      setCsvIcon(advanceSetting?.query_categories_file);
    }
  }, [advanceSetting]);

  const onFinish = (values) => {
    let selectedBudgetGoalsType = values.budget_goals_type;
    let selectedCombinedServices = values.budget_goals_combined_services;
    let selectedMasterService = values.budget_goals_master_service;
    let errors = null;
    if (
      selectedBudgetGoalsType == 2 &&
      typeof selectedCombinedServices == "object"
    ) {
      if (selectedCombinedServices.length < 2) {
        errors = [
          "At least two services must be selected when set to combined services",
        ];
      } else if (selectedMasterService == null) {
        errors = ["Please select the master service"];
      } else if (
        selectedCombinedServices.indexOf(selectedMasterService) == -1
      ) {
        errors = [
          "Invalid master service! Please pick the service that is selected as combined services.",
        ];
      }
      form.setFields([
        {
          name: "budget_goals_type",
          errors: errors,
        },
      ]);
      if (errors !== null) {
        return false;
      }
    }

    values = JSON.stringify(values);
    dispatch(saveAccountAdvancedSetting(values));
  };

  const onChangeData = (date, dateString) => {
    form.setFieldsValue({ combined_fees_month_value: dateString });
  };

  const budgetGoalsTypeChangeHandler = (e) => {
    if (e.target.value == 1) {
      form.setFieldsValue({ budget_goals_combined_services: [] });
      form.setFieldsValue({ budget_goals_master_service: null });
      setBudgetGoalsCombinedServicesDisabled(true);
      setBudgetGoalsMasterServiceDisabled(true);
    } else {
      setBudgetGoalsCombinedServicesDisabled(false);
      setBudgetGoalsMasterServiceDisabled(false);
    }
  };
  const breadcrumbItems = [
    { text: "", link: "/", icon: FiGrid },
    { text: "Accounts", link: "/accounts", icon: FiUsers },
    {
      text: account_information?.name,
      link: "/account/detail/" + account_id,
      icon: FiSettings,
    },
    {
      text: "Advanced Settings",
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="panel-heading">
        <Row>
          <Col span={24} className="panel-heading-title">
            <h2>Account - Advanced Settings - {account_information?.name}</h2>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <div className="panel-body-content account-advanced-setting-form-wrapper">
          <Spin spinning={isLoading}>
            <Form
              name="advanced-settings"
              form={form}
              layout="horizontal"
              onFinish={onFinish}
            >
              <Form.Item hidden name="account_id">
                <Input type="hidden" value={accountId.account_id} />
              </Form.Item>
              <Form.Item hidden name="combined_fees_month_value">
                <Input type="hidden" />
              </Form.Item>
              <Row>
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single">
                    <h2> Combined Services- Fees</h2>
                    <div className="asfs-wrapper">
                      <Form.Item
                        label="Services"
                        name="combined_fees_services"
                        initialValue={defaultCombinedFeesServices}
                      >
                        <Checkbox.Group
                          options={activeServices}
                          onChange={onChangeCombinedFeesServices}
                          value={defaultCombinedFeesServices}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Fee Master"
                        name="combined_fees_master_service"
                      >
                        <Select style={{ width: 250 }}>
                          {activeServices.map((activeService) => (
                            <Select.Option value={activeService.value}>
                              {activeService.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="For the Month"
                        name="combined_fees_month"
                      >
                        <DatePicker
                          defaultValue={moment(combined_fees_setting?.month)}
                          onChange={onChangeData}
                          format={monthFormat}
                          picker="month"
                          inputReadOnly={true}
                        />{" "}
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single adv-set-form-budget">
                    <h2> Budget/Goals</h2>
                    <div className="asfs-wrapper">
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="budget_goals_type"
                            onChange={budgetGoalsTypeChangeHandler}
                          >
                            <Radio.Group
                              options={[
                                { label: "By Individual Service", value: 1 },
                                { label: "Combined Services", value: 2 },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <div className="budget_goals_combined_services-wrapper">
                            <span className="budget-goals-title">Master</span>
                            <Form.Item name="budget_goals_combined_services">
                              <Checkbox.Group
                                disabled={budgetGoalsCombinedServicesDisabled}
                                options={[
                                  {
                                    label: "Paid Search (Google/Bing)",
                                    value: "3",
                                    key: "3",
                                  },
                                  {
                                    label: "Product Ads (Google/Bing)",
                                    value: "4",
                                    key: "4",
                                  },
                                  {
                                    label: "Paid Social Marketing",
                                    value: "10",
                                    key: "10",
                                  },
                                  {
                                    label:
                                      "Product Ads (Amazon Seller Central)",
                                    value: "5",
                                    key: "5",
                                  },
                                  {
                                    label: "Product Ads (AMS)",
                                    value: "14",
                                    key: "14",
                                  },
                                ]}
                              />
                            </Form.Item>
                            <Form.Item name="budget_goals_master_service">
                              <Radio.Group
                                disabled={budgetGoalsMasterServiceDisabled}
                              >
                                <Space direction="vertical">
                                  <Radio value="3"></Radio>
                                  <Radio value="4"></Radio>
                                  <Radio value="10"></Radio>
                                  <Radio value="5"></Radio>
                                  <Radio value="14"></Radio>
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single">
                    <h2> Notes</h2>
                    <div className="asfs-wrapper">
                      <Form.Item name="notes">
                        <TextArea rows={2} placeholder="" maxLength={1000} />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                {/* <Col className="adv-set-form-wrapper" span={6}>
									<div className="adv-set-form-single adv-set-form-single-upload">
										<h2> Logo (Max. Width: 144px)</h2>
										<div className="asfs-wrapper">
											<Form.Item name="logo_file">
												<Upload {...logo_props}>
													<Button icon={<UploadOutlined />}>
														Click to Upload
													</Button>
												</Upload>
												{logoImage ? (
													<div className="logo-preview">
														<Image
															alt="Logo"
															src={`data:image/jpeg;base64,${logoImage}`}
															placeholder="Logo"
														/>
													</div>
												) : ""}
											</Form.Item>
										</div>
									</div>
								</Col> */}
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single adv-set-form-single-upload">
                    <h2> Query Categories (CSV file)</h2>
                    <div className="asfs-wrapper">
                      <Form.Item name="query_categories_file">
                        <Upload {...csv_props}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                        {csvIcon ? (
                          <div className="csv-preview">
                            <strong>Uploaded File:</strong>{" "}
                            {csvIcon ? csvIcon : "N/A"}
                          </div>
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single">
                    <h2> Holistic Performance Reports</h2>
                    <div className="asfs-wrapper">
                      <Form.Item
                        name="show_ad_spend_data"
                        valuePropName="checked"
                      >
                        <Checkbox checked={showAdSpendDataStatus}>
                          {" "}
                          Show Ad Spend Data
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single">
                    <h2> Media Mix Modeling</h2>
                    <div className="asfs-wrapper">
                      <Form.Item
                        label="Include Amazon in Modeling"
                        name="include_amazon_data"
                      >
                        <Radio.Group
                          options={[
                            { label: "Yes", value: 1 },
                            { label: "No", value: 0 },
                          ]}
                        />
                      </Form.Item>
                    </div>
                    <div className="adv-set-note">
                      <p>
                        Note: AMS is not supported by the model because Amazon
                        Business Reports are not available for AMS
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single">
                    <h2> Custom Reports</h2>
                    <div className="asfs-wrapper">
                      <Form.Item name="custom_reports">
                        <Checkbox.Group options={cOptions} />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single">
                    <h2> Monthly Email Report</h2>
                    <div className="asfs-wrapper">
                      <Form.Item
                        label="Send Monthly Email Report"
                        name="monthly_email_type"
                      >
                        <Radio.Group
                          options={[
                            { label: "Manual", value: 1 },
                            { label: "Automatic", value: 2 },
                            { label: "Never", value: 3 },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Send To"
                        name="send_to_ims_specialists_only"
                      >
                        <Radio.Group
                          options={[
                            { label: "All", value: 0 },
                            { label: "IMS / Specialist Only", value: 1 },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Send To Parent Account Users"
                        name="send_to_parent_account_users"
                      >
                        <Radio.Group
                          options={[
                            { label: "Yes", value: 1 },
                            { label: "No", value: 0 },
                          ]}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Comparision Period"
                        name="report_comparision_period"
                      >
                        <Radio.Group
                          options={[
                            { label: "YoY", value: 1 },
                            { label: "MoM", value: 2 },
                          ]}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col
                  className="adv-set-form-wrapper"
                  lg={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="adv-set-form-single">
                    <h2>Currency Settings</h2>
                    <Row>
                      {BIServices?.map((service) => (
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                          <div className="asfs-wrapper currency-single">
                            <span className="currency-single-title">
                              {service.name == "All"
                                ? "Holistic"
                                : service.name}
                            </span>
                            <Form.Item
                              key={service.id}
                              name={"service_currency_" + service.id}
                              initialValue={
                                service_currencies.length > 0
                                  ? service_currencies[
                                      service_currencies.findIndex(
                                        (item) => item.service_id === service.id
                                      )
                                    ].currency_id
                                  : 147
                              }
                            >
                              <Select>
                                {currencies.map((currency) => (
                                  <Select.Option value={currency.id}>
                                    {"(" +
                                      currency.code +
                                      ") - " +
                                      currency.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <div className="adv-set-note">
                        <p>
                          There is no currency setting for Product Ads (Amazon
                          Seller Central) since the currency can be changed via
                          the Marketplace filter in reports
                        </p>
                      </div>
                    </Row>
                  </div>
                </Col>
                <Col
                  className="adv-set-form-wrapper adv-set-form-wrapper-button"
                  span={24}
                >
                  <Form.Item>
                    <Button htmlType="submit">Save</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    </>
  );
}

export default AccountAdvancedSetting;
