import React, { useEffect, useState, useRef, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { getSessionData } from "../../../Redux/Actions/Thunk/ColumnChartThunk";
import { formDataAppend } from "../../../Utils/helpers";
import { Row, Col, Spin } from "antd";
import Chart from "../../Highcharts/ChartColumn";

const ColumnChart = (props) => {
	const config = JSON.parse(props?.block?.config);

	const pointWidth = config["settings"]["plotOptions"]["series"]["pointWidth"];

	const {
		sessionData: {
			sessionData: {
				[props?.block?.block_id]: {
					chart_data: columnData = [],
					xAxisCategories: categories = [],
					color: colorData = [],
				} = {},
			},
			isLoading,
		} = {},
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	useEffect(() => {
		var data = props?.all_input_data;
		var formData = formDataAppend(data);
		if (props?.length == 0) {
			dispatch(getSessionData(formData, props));
		}
	}, []);

	/*for filter data*/
	const [filter, setFilter] = useState("");
	const { filterData } = props;

	useEffect(() => {
		var data = props?.all_input_data;
		var formData = formDataAppend(data);
		dispatch(getSessionData(formData, props));
	}, [filterData]);
	/*end for filter data*/

	// const chartData = useMemo(() => {
	const chartData = columnData.map((values, index) => {
		const columnData = {
			chart: {
				type: "column",
				height: "200px",
				zoomType: "",
				panning: 0,
				panKey: "",
				selectionMarkerFill: "none",
				resetZoomButton: {
					position: {
						x: 0,
						y: -40,
					},
				},
			},
			title: {
				text: "",
			},
			subtitle: {
				text: "",
			},
			xAxis: {
				categories: categories,
				crosshair: true,
				labels: {
					enabled: true,
					align: "right",
					x: -5,
				},
				visible: true,
			},
			tooltip: {
				formatter: function () {
					return this.points.reduce(function (s, point) {
						if (values.name === 'cpc') {
							return point.x + ": " + window.currencySymbol + point.y.toFixed(2);
						}
						else {
							return point.x + ": " + point.y.toFixed(2) + "%";
						}
					}, "<b>" + this.x + "</b>");
				},
				shared: true,
			},
			plotOptions: {
				series: {
					pointWidth: pointWidth,
					colorByPoint: true,
					allowPointSelect: false,
					dataLabels: {
						enabled: false,
						color: "#666666",
						style: {
							fontSize: "12px",
						},
					},
				},
			},
			yAxis: {
				title: {
					text: "",
				},
				labels: {
					enabled: true,
					format: values.name === 'cpc' ? window.currencySymbol + "{value}" : "{value}%",
					rotation: 0,
				},
				max: null,
				//tickInterval: 0.1,
			},
			credits: {
				enabled: false,
			},
			exporting: {
				enabled: false,
			},
			legend: {
				enabled: false,
				align: "center",
				verticalAlign: "middle",
				layout: "horizontal",
				symbolRadius: "1",
			},
			colors: colorData,
			series: [
				{
					data: values.data,
					name: values.name,
					color: colorData,
				},
			],
		};
		return columnData;
	});
	// }, [props?.data]);

	return (
		<>
			<Spin spinning={isLoading}>
				<Row gutter={32}>
					{chartData.length > 0 &&
						chartData?.map((option, index) => (
							<Col span={6}>
								<div className="performance-graph-charts" key={index}>
									<h2>{option.series[0].name}</h2>
									<Chart options={option} />
								</div>
							</Col>
						))}
				</Row>
			</Spin>
		</>
	);
};

export default ColumnChart;
