import React, { useState, useEffect, useCallback } from "react";
import { Select, Button, Spin, Form } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Config from "../../Config";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { setDefaults } from "../../Redux/Actions/inputControlAction";

const { Option } = Select;

const onChange = (value) => {};

const PaginatedSelect = ({ pageSize = 50, props }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());
  const [selectedValues, setSelectedValues] = useState(
    props.form.getFieldValue(props.block.id) || []
  );
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [searchQuery, setSearchQuery] = useState("All");

  const {
    inputcontrols: { defaults: defaults = [] },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    let defaultValue = props?.block?.default;
    if (urlSearchParams.get(props?.block?.id) != null) {
      defaultValue = params_data[props?.block?.id];
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    } else {
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    }
  }, [window.location.search]);

  useEffect(() => {
    if (defaults.hasOwnProperty(props?.block?.id)) {
      props.form.setFieldsValue({
        [props?.block?.id]: defaults[props?.block?.id],
      });
    }
  }, [defaults]);

  const {
    API: { baseURL },
  } = Config;

  useEffect(() => {
    getInputData(1, searchQuery);
  }, [searchQuery]);

  const getInputData = async (page, query) => {
    try {
      var formdata = new FormData();
      formdata.append("authentication", props?.filteredData?.authentication);
      formdata.append("offset", (page - 1) * pageSize);
      formdata.append("limit", pageSize);
      formdata.append("f_date", props?.filteredData?.f_date);
      formdata.append("t_date", props?.filteredData?.t_date);
      formdata.append("by_pass_cache", "yes");
      formdata.append("campaign_input_control", query || "All");
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      setLoading(true);
      const response = await fetch(
        baseURL + props?.block?.option_url,
        requestOptions
      );
      const result = await response.json();
      if (page === 1) {
        setDataSource(result?.data);
      } else {
        setDataSource((prevData) => [...prevData, ...result?.data]);
      }
    } catch (err) {
      // Handle any errors
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setLoading(true);
    getInputData(page + 1, searchQuery);
    setTimeout(() => {
      setPage(page + 1);
      setLoading(false);
    }, 1000);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value || "All");
      setPage(1);
    }, 1000),
    []
  );

  const handleSearch = (value) => {
    debouncedSearch(value);
  };

  const allFields = ["-1", "All"];
  const handleSelect = (items) => {
    if (items.length === 0 && props?.block?.default === "-1") {
      items = ["-1"];
      setSelectedValues("-1");
    }
    if (items.length === 0 && props?.block?.default === "All") {
      items = ["All"];
      setSelectedValues("All");
    }
    const latestValue = items[items.length - 1];

    if (allFields.includes(latestValue)) {
      items = [latestValue];
    } else {
      const findIndexMinusOne = items.indexOf("-1");
      if (findIndexMinusOne > -1) {
        items.splice(findIndexMinusOne, 1);
      }

      const findIndexAll = items.indexOf("All");
      if (findIndexAll > -1) {
        items.splice(findIndexAll, 1);
      }
    }

    if (Array.isArray(items)) {
      setSelectedValues(items);
      props.form.setFieldsValue({ [props.block.id]: items });
    } else {
      setSelectedValues([]);
      props.form.setFieldsValue({ [props.block.id]: [] });
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Form.Item name={props?.block.id}>
          <Select
            showSearch
            style={{ width: 300 }}
            placeholder="All"
            value={selectedValues}
            optionFilterProp="children"
            mode="multiple"
            onChange={handleSelect}
            onSearch={handleSearch}
            filterOption={false}
            dropdownStyle={{
              maxWidth: "500px",
            }}
            dropdownRender={(menu) => (
              <div>
                {menu}
                {dataSource?.length > 48 && (
                  <div style={{ textAlign: "center", padding: 8 }}>
                    <Button
                      type="link"
                      onClick={loadMore}
                      loading={loading}
                      icon={<DownOutlined />}
                      style={{ color: "#3cb400" }}
                    >
                      Show More
                    </Button>
                  </div>
                )}
              </div>
            )}
          >
            {dataSource?.map((data) => (
              <Option
                value={
                  data?.id == "-1" || data?.id == "All"
                    ? props?.block?.default
                    : data?.id
                }
                id={data.id}
                name={data.id}
                key={data.value}
              >
                {typeof data.value == "object"
                  ? data.value
                  : String(data.value)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Spin>
    </div>
  );
};

const SelectPaginate = (props) => (
  <div
    className={
      "Report-V2-filter-box select_filter" + " " + props?.block.param_name
    }
  >
    <div className="Report-V2-filter-option-title">
      <span>{props?.block?.label}</span>
    </div>
    <PaginatedSelect props={props} />
  </div>
);

export default SelectPaginate;
