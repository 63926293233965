import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { getBarChartData } from "../../Redux/Actions/Thunk/ColumnChartThunk";
import { formDataAppend } from "../../Utils/helpers";
import { Spin } from "antd";

const BarChart = (props) => {
  const {
    sessionData: {
      barChartData: {
        [props?.block?.block_id]: {
          chart_data: chartData = [],
          xAxisCategories: xAxisData = [],
          color: colorData = [],
          data_type: data_type = "number",
          insight: insight = [],
        } = {},
      },
      isLoading,
    } = {},
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    if (props?.length == 0) {
      dispatch(getBarChartData(formData, props));
    }
  }, []);
  /*for filter data*/
  const [filter, setFilter] = useState("");
  const { filterData } = props;

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getBarChartData(formData, props));
  }, [filterData]);
  /*end for filter data*/
  const config = JSON.parse(props?.block?.config);

  var height = config["height"];

  var showDataLabels = false;
  if (config.settings.plotOptions.series.datalabelsStatus) {
    var datalabelsStatus = config.settings.plotOptions.series.datalabelsStatus;
    if (datalabelsStatus == "true") {
      showDataLabels = true;
    }
  }

  var chart = {
    chart: {
      type: "bar",
      height: height,
      zoomType: "",
      panning: 0,
      panKey: "",
      selectionMarkerFill: "none",
      resetZoomButton: {
        position: {
          x: 0,
          y: -40,
        },
      },
    },
    title: {
      text: config.title != undefined || config.title != "" ? config.title : "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: xAxisData,
      crosshair: true,
      labels: {
        enabled: true,
        align: "right",
        x: -5,
      },
      visible: true,
    },
    tooltip: {
      formatter: function () {
        return this.points.reduce(function (s, point) {
          if (data_type == "percentage") {
            return "<b>" + point.x + "</b>" + ": " + point.y + "%";
          } else {
            return "<b>" + point.x + "</b>" + ": " + point.y;
          }
        }, "<b>" + this.x + "</b>");
      },
      shared: true,
    },
    plotOptions: {
      series: {
        pointWidth: 40,
        colorByPoint: true,
        allowPointSelect: false,
        dataLabels: {
          enabled: showDataLabels,
          color: "#666666",
          style: {
            fontSize: "12px",
          },
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        enabled: true,
        format: data_type == "percentage" ? "{value}%" : "{value}",
        rotation: 0,
      },
      max: data_type == "percentage" ? 100 : null,
      //tickInterval: 0.1,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
      align: "center",
      verticalAlign: "middle",
      layout: "horizontal",
      symbolRadius: "1",
    },
    colors: colorData,
    series: chartData,
  };

  return (
    <>
      <Spin spinning={isLoading}>
        {chartData.length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={chart}
          ></HighchartsReact>
        ) : (
          ""
        )}
        {insight != undefined && insight.length > 0 ? (
          <div className="insights">
            <div dangerouslySetInnerHTML={{ __html: insight }} />
          </div>
        ) : (
          "-"
        )}
      </Spin>
    </>
  );
};

export default BarChart;
