import { Token } from "./Utils/helpers/token";

const config = {
  local: {
    // This is for data layer api
    API: {
      baseURL: "http://0.0.0.0:5000/",
      authKey: Token.encryptAuthParams(window.localStorage.getItem("token")),
    },
    API_BI: {
      baseURL: "http://127.0.0.1:4444/",
      BASE_URL_FOR_BI_APP: "http://localhost:3000/",
      SSO_VISUALIZE_URL:
        "https://uat-impact-login.agital.com/login?domain=https://uat-impact-visualize.agital.com",
    },
    SSO_LOGIN: {
      baseURL: "https://uat-impact-login.agital.com/",
      api_base_url: "https://uat-impact-login-api.agital.com/",
    },
    ADMIN: {
      baseURL: "https://datasourceconfig.exclusiveconcepts.com/",
      api_base_url: "https://api-datasourceconfig.exclusiveconcepts.com/",
    },
    BI_APP: {
      baseURL: "http://localhost:8000/",
    },
  },
  dev: {
    API: {
      baseURL: "https://v2uat.exclusiveconcepts.com:5000/",
      authKey: Token.encryptAuthParams(window.localStorage.getItem("token")),
    },
    API_BI: {
      baseURL: "https://dev-impact-inform-api.agital.com/",
      BASE_URL_FOR_BI_APP: "https://biapp.exclusiveconcepts.com",
    },
    SSO_LOGIN: {
      baseURL: "https://uat-impact-login.agital.com/",
      api_base_url: "https://uat-impact-login-api.agital.com/",
    },
    ADMIN: {
      baseURL: "https://datasourceconfig.exclusiveconcepts.com/",
      api_base_url: "https://api-datasourceconfig.exclusiveconcepts.com/",
    },
    BI_APP: {
      baseURL: "https://bitest.exclusiveconcepts.com/",
    },
  },

  uat: {
    API: {
      baseURL: "https://v2uat.exclusiveconcepts.com:5000/",
      authKey: Token.encryptAuthParams(window.localStorage.getItem("token")),
    },
    API_BI: {
      baseURL: "https://uat-impact-inform-api.agital.com/",
      // baseURL: "http://0.0.0.0:4444/",
      BASE_URL_FOR_BI_APP: "https://uat-impact-inform.agital.com/",
      SSO_VISUALIZE_URL:
        "https://uat-impact-login.agital.com/login?domain=https://uat-impact-visualize.agital.com",
    },
    SSO_LOGIN: {
      baseURL: "https://uat-impact-login.agital.com/",
      api_base_url: "https://uat-impact-login-api.agital.com/",
    },
    ADMIN: {
      baseURL: "https://uat-impact-admin.agital.com/",
      api_base_url: "https://uat-impact-admin-api.agital.com/",
    },
    BI_APP: {
      baseURL: "https://v2uat.exclusiveconcepts.com/",
    },
  },

  prod: {
    API: {
      baseURL: "https://datalayer.agital.com/",
      authKey: Token.encryptAuthParams(window.localStorage.getItem("token")),
    },
    API_BI: {
      baseURL: "https://impact-inform-api.agital.com/",
      BASE_URL_FOR_BI_APP: "https://impact-inform.agital.com/",
      SSO_VISUALIZE_URL:
        "https://impact-login.agital.com/login?domain=https://impact-visualize.agital.com",
    },
    SSO_LOGIN: {
      baseURL: "https://impact-login.agital.com/",
      api_base_url: "https://impact-login-api.agital.com/",
    },
    ADMIN: {
      baseURL: "https://impact-admin.agital.com/",
      api_base_url: "https://impact-admin-api.agital.com/",
    },
    BI_APP: {
      baseURL: "https://impact-inform.agital.com/",
    },
  },

  default: {
    API: {
      baseURL: "",
    },
  },
};

const react_script_env = process.env.REACT_APP_ENV;

function getEnvironment() {
  var env;
  switch (react_script_env) {
    case "LOCAL":
      env = "local";
      break;
    case "DEV":
      env = "dev";
      break;
    case "PROD":
      env = "prod";
      break;
    case "UAT":
      env = "uat";
      break;
    default:
      env = "default";
  }
  return env;
}

const env = getEnvironment();
export default config[env];
