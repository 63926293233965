import {
	Button,
	Table,
	Row,
	Col,
	Tooltip,
	Modal,
	Form,
	Input,
	message,
	Upload,
	Avatar,
	Checkbox,
	Select,
	DatePicker,
} from "antd";
import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";
import TagInputBox from "../../Pages/Settings/TagInputBox";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
const {
	API_BI: { baseURL },
} = config;

const GoogleUpdates = (props) => {
	const [categories, setCategories] = useState([]);
	const [updates, setUpdates] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
	const [avatarFile, setAvatarFile] = useState(null);

	const handleFileChange = (info) => {
		if (info.file.status === "done") {
			// Update the avatar file state with the uploaded file
			setAvatarFile(info.file.originFileObj);
			message.success(`${info.file.name} uploaded successfully.`);
		} else if (info.file.status === "error") {
			message.error(`${info.file.name} upload failed.`);
		}
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const showUpdateModal = () => {
		setIsUpdateModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		setIsUpdateModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setIsUpdateModalOpen(false);
	};
	const dispatch = useDispatch();

	const getRowKey = (record) => record.id;

	const isLoading = useSelector((state) => state.accounts.isLoading);

	useEffect(() => {
		dispatch({
			type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
		});
		API_BI.defaults.headers.common["Authorization"] =
			localStorage.getItem("token");
		API_BI.get(baseURL + "api/v1/google-updates").then((response) => {
			if (response.status == 200) {
				setCategories(response.data?.categories);
				setUpdates(response.data?.updates);
				dispatch({
					type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
				});
			}
		});
	}, []);

	const columns = [
		{
			title: "Category",
			dataIndex: "name",
			key: "name",
			render: (text) => <a>{text}</a>,
		},
		{
			title: "Actions",
			dataIndex: "",
			key: "actions",
			render: (values, record) => {
				return (
					<>
						<div className="panel-action-button">
							<Tooltip title="Edit Category">
								{/* <Link to={`/edit/${categories.id}`}>
									<FaPencilAlt />
								</Link> */}
								<span onClick={showModal}>
									<FaPencilAlt />
								</span>
							</Tooltip>
							<Tooltip title="Delete Category">
								<span>
									<AiFillDelete onClick={() => openConfirm(categories)} />
								</span>
							</Tooltip>
						</div>
					</>
				);
			},
		},
	];

	const updateColumns = [
		{
			title: "Update Category",
			dataIndex: "name",
			key: "category_name",
			render: (text) => <a>{text}</a>,
		},
		{
			title: "Update Title",
			dataIndex: "update_title",
			key: "update_title",
		},
		{
			title: "Date",
			dataIndex: "updated_at",
			key: "updated_at",
		},
		{
			title: "Actions",
			dataIndex: "",
			key: "actions",
			render: (values, record) => {
				return (
					<>
						<div className="panel-action-button">
							<Tooltip title="Edit Record">
								{/* <Link to={`/edit/${updates.id}`}>
									<FaPencilAlt />
								</Link> */}
								<span onClick={showUpdateModal}>
									<FaPencilAlt />
								</span>
							</Tooltip>
							<Tooltip title="Delete Record">
								<span>
									<AiFillDelete onClick={() => openConfirm(updates)} />
								</span>
							</Tooltip>
						</div>
					</>
				);
			},
		},
	];

	const openConfirm = (selectedRows) => {
		// return confirm({
		//     title: "Are you sure you want to delete this category?",
		//     icon: <ExclamationCircleOutlined />,
		//     okText: "Yes",
		//     okType: "danger",
		//     cancelText: "Cancel",
		//     onOk() {
		//         message.success("Category Deleted Successfully", [1.5]);
		//     },
		//     onCancel() {
		//     },
		// });
	};

	return (
		<>
			<div className="panel-heading">
				<Row>
					<Col span={8} className="panel-heading-title">
						<h2>Google Updates</h2>
					</Col>
				</Row>
			</div>
			<div className="panel-body google-updates">
				<Row>
					<Col span={16}>
						<div className="panel-body-content">
							<div className="pbc-title">
								<h2>Categories</h2>
								<Button className="ant-btn">Add New</Button>
							</div>

							<Table
								rowKey={getRowKey}
								columns={columns}
								dataSource={categories}
								loading={isLoading}
								pagination={false}
							/>
						</div>
						<div className="panel-body-content">
							<div className="pbc-title">
								<h2>Updates</h2>
								<Button className="ant-btn">Add New</Button>
							</div>

							<Table
								rowKey={getRowKey}
								columns={updateColumns}
								dataSource={updates}
								loading={isLoading}
								pagination={false}
							/>
							<Modal
								className="google-update-modal"
								title="Edit Category"
								visible={isModalOpen}
								onOk={handleOk}
								onCancel={handleCancel}
								footer={null}
							>
								<Form
									name="editCategory"
									initialValues={{
										remember: true,
									}}
									autoComplete="off"
								>
									<Form.Item
										label="Name"
										name="username"
										rules={[
											{
												required: true,
												message: "Please input your username!",
											},
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item
										label="Logo"
										name="logo"
										rules={[
											{
												required: true,
												message: "Please input your logo!",
											},
										]}
									>
										<Avatar
											size={96}
											icon={<UserOutlined />}
											src={avatarFile && URL.createObjectURL(avatarFile)}
										/>
										<Upload
											accept="image/*"
											action="http://localhost:3000/upload-avatar"
											showUploadList={false}
											onChange={handleFileChange}
										>
											<Button icon={<UploadOutlined />}>Browse</Button>
										</Upload>
										<Checkbox>Remove Logo</Checkbox>
									</Form.Item>

									<Form.Item className="google-edit-submit">
										<Button type="primary" htmlType="submit">
											Save
										</Button>
									</Form.Item>
								</Form>
							</Modal>
							<Modal
								className="google-update-modal"
								title="Edit Update"
								visible={isUpdateModalOpen}
								onOk={handleOk}
								onCancel={handleCancel}
								footer={null}
								width={700}
							>
								<Form
									name="editUpdate"
									initialValues={{
										remember: true,
									}}
									autoComplete="off"
								>
									<Form.Item name="category" label="Category">
										<Select
											name="category"
											defaultValue="Other"
											options={[
												{
													value: "1",
													label: "Please select",
												},
												{
													value: "2",
													label: "Panda",
												},
												{
													value: "3",
													label: "Penguin",
												},
												{
													value: "4",
													label: "Mobile",
												},
												{
													value: "5",
													label: "Pirate",
												},
												{
													value: "6",
													label: "Core",
												},
												{
													value: "7",
													label: "Other",
												},
												{
													value: "8",
													label: "Local",
												},
											]}
										></Select>
									</Form.Item>
									<Form.Item label="Date" name="date">
										<DatePicker showToday={false} />
									</Form.Item>
									<Form.Item label="Update Title" name="title">
										<Input />
									</Form.Item>
									<Form.Item className="editUpdate-editor">
										<Editor />
									</Form.Item>
									<Form.Item className="google-edit-submit">
										<Button type="primary" htmlType="submit">
											Save
										</Button>
									</Form.Item>
								</Form>
							</Modal>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default GoogleUpdates;
