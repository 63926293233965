export const initialState = {
  isLoading: false,
  normal_table: {},
  api_data: {},
  id: {},
};

export const ACTIONS_TYPES = Object.freeze({
  REQUEST_NORMAL_TABLE: "REQUEST_NORMAL_TABLE",
  FETCH_NORMAL_TABLE: "FETCH_NORMAL_TABLE",
  FETCH_API_DATA: "FETCH_API_DATA",
});

export function normalTableReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.REQUEST_NORMAL_TABLE:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_NORMAL_TABLE:
      return {
        ...state,
        isLoading: false,
        normal_table: action.payload,
        id: action.id,
      };
    case ACTIONS_TYPES.FETCH_API_DATA:
      return {
        ...state,
        isLoading: false,
        api_data: { ...state.api_data, ...action.payload },
        id: action.id,
      };
    default:
      return state;
  }
}
