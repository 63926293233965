import { amazonRegion } from "../../../Config/datasources/amazon/region";
import * as amazonSponsorAdsDataSourceThunk from "../../../Redux/Actions/Thunk/datasources/amazonSponsorAdsThunk";
import errorHandler from "../../../Utils/Request/errorHandler";

const dispatchAmazonSponsorAdsDataSource = (dispatch, payload) => {
  let region_suffix = {
    NA: "_na",
    EU: "_eu",
    FE: "_fe",
  };
  let amazonRegionProperty = {
    NA: "marketplaces_na",
    EU: "marketplaces_eu",
    FE: "marketplaces_fe",
  };
  let marketplaces;
  const { dataSourceMetaData, starTableData, dataSourceDataFromThirdParty } =
    payload;
  let inputDataFromUser = JSON.parse(payload.dataSourceInputDataByClientInForm);

  let sellerIdsFromUserInput;
  let listOfSelleridInSelectedMarketPlace;
  // Basically , we are dynamically setting the market place name from the region
  if (amazonRegion[dataSourceMetaData.dataSourceRegion]) {
    sellerIdsFromUserInput = inputDataFromUser[
      `seller_id${region_suffix[dataSourceMetaData.dataSourceRegion]}`
    ]
      .split(",")
      .filter((el) => el);
    let marketPlaceName = `${
      amazonRegionProperty[dataSourceMetaData.dataSourceRegion]
    }`;
    marketplaces = inputDataFromUser[`${marketPlaceName}`];
  }

  // * getting the data from the sellerId dynamically .
  let marketPlaceData = sellerIdsFromUserInput.map((sellerId) => {
    return marketplaces.map((el) => {
      // *We are using find as marketplaceId is unique .
      const marketplace = dataSourceDataFromThirdParty.profile.find((data) => {
        return el === data.accountInfo.marketplaceStringId;
      });

      // THIS IS TO LIST OUT ALL THE SELLER ID FROM THE SELECTED MARKET PLACE
      listOfSelleridInSelectedMarketPlace = marketplaces
        .map((el) => {
          return dataSourceDataFromThirdParty.profile.map((data) => {
            if (
              el === data.accountInfo.marketplaceStringId &&
              data.accountInfo.id.startsWith("A")
            ) {
              return data.accountInfo.id;
            }
          });
        })
        .flat(2)
        .filter((el) => {
          return el;
        });

      const validSellerIds = dataSourceDataFromThirdParty.profile.filter(
        (data) => {
          return sellerId === data.accountInfo.id;
        }
      );

      return validSellerIds.map((validSellerId) => {
        if (
          validSellerId &&
          validSellerId.accountInfo.marketplaceStringId === el
        ) {
          return {
            api_host: dataSourceMetaData?.apiHost,
            client_name: marketplace?.accountInfo?.name,
            market_place_id: marketplace?.accountInfo?.marketplaceStringId,
            profile_id: marketplace?.profileId.toString(),
            // Note: i am not getting seller id from third party
            seller_id: validSellerId?.accountInfo?.id,
          };
        }
      });
    });
  });
  marketPlaceData = marketPlaceData.flat(2).filter((el) => el);

  // if currentMarketPlace is found then we have to create the data source for different market place .
  let properPayload = {
    client_auth_params: {
      refresh_token: dataSourceDataFromThirdParty.tokens?.refresh_token,
      client_email:
        inputDataFromUser[
          `client_email${region_suffix[dataSourceMetaData.dataSourceRegion]}`
        ],
      account_id:
        dataSourceMetaData.dataSourceRegion === "NA"
          ? undefined
          : starTableData?.account_id,
      // status: inputDataFromUser[
      //   `status${region_suffix[dataSourceMetaData.dataSourceRegion]}` ? 1 : 0
      // ]
    },

    star_table_data:
      dataSourceMetaData.dataSourceRegion === "NA" ? starTableData : undefined,
    client_info: marketPlaceData,
  };

  if (!marketPlaceData.length) {
    let message;
    if (!listOfSelleridInSelectedMarketPlace.length) {
      message = `No seller id found in the selected market place`;
    } else {
      const arrayLists = listOfSelleridInSelectedMarketPlace.join(",");
      message = `Please enter valid seller id from the list ${arrayLists}`;
    }
    const error = {
      response: {
        status: 400,
        data: {
          message,
        },
      },
    };
    errorHandler(error, false);
  } else {
    dispatch(
      amazonSponsorAdsDataSourceThunk.createAmazonSponsorAdsDataSource(
        properPayload,
        dataSourceMetaData.dataSourceRegion
      )
    );
  }
};

export default dispatchAmazonSponsorAdsDataSource;
