import API_BI from "../../Utils/axios_bi";
import { DataSourcesApiEndPoints } from "../../Utils/constant";


const {
  amazonMarketingService: { getAmazonMarketingAds, postAmazonMarketingAds },
} = DataSourcesApiEndPoints;

/**
 * API function to get Amazon Sponsor Ads data
 */
export const getAccountDataSourcesData = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getAmazonMarketingAds, props);
};

/**
 * API function to create Amazon Sponsor Ads data
 * @param {object} props - The data to be posted
 * @returns {Promise<response>}
 */
export const createAccountDataSourcesData = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(postAmazonMarketingAds, props);
};

/**
 * API function to update Amazon Sponsor Ads data
 * @param {object} props - The data to be updated
 * @returns {Promise<response>}
 */
export const updateAccountDataSourcesData = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(postAmazonMarketingAds, props);
};
