import * as internalReportApi from "../../../Api/InternalReportApi";
import * as InternalReportAction from "../InternalReportAction";
import { ACTIONS_TYPES } from "../../Reducers/InternalReportReducer";

export const getInternalReports = (params = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_INTERNAL_REPORTS,
      });
      let internal_reports = {};
      const response = await internalReportApi.getInternalReports(params);
      if (response?.status === 200) {
        internal_reports = {
          data: response?.data?.data,
          total: response?.data?.total,
        };
      }
      return dispatch(
        InternalReportAction.fetchInternalReports(internal_reports)
      );
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
