import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowUp } from "react-icons/fa";
import { Row, Col, Tooltip } from "antd";
import { format_data } from "../../Utils/helpers";
import { FaInfoCircle } from "react-icons/fa";
import { groupByKey } from "../../Utils/helpers";
import { formDataAppend } from "../../Utils/helpers";
import { getNormalTable } from "../../Redux/Actions/Thunk/normalTableThunk";
import { Spin } from "antd";
import HtmlParser from "html-react-parser";
import ArrowInsights from "../Tables/arrow-insights";
import { normalTableReducer } from "../../Redux/Reducers/normalTableReducer";

const NormalTable = (props) => {
	const test = useSelector((state) => state.normalTable);
	const {
		normalTable: {
			normal_table: {
				data: tableList = [],
				insights: insightsData = [],
				tooltip_header: tooltipHeader = [],
			},
			isLoading,
		},
	} = useSelector((state) => state);
	const dispatch = useDispatch();

	useEffect(() => {
		var data = props?.all_input_data;
		var formData = formDataAppend(data);
		dispatch(getNormalTable(formData, props));
	}, []);

	const getValueDataByKey = (insights, key) => {
		const matchingInsight = insights.find(
			(insight) => Object.keys(insight)[0] === key
		);
		return matchingInsight ? matchingInsight[key] : [];
	};
	return (
		<>
			<Spin spinning={isLoading}>
				<Row gutter={[16, 24]}>
					{tableList?.map((table) => {
						return (
							<Col lg={{span: 12}} xs={{span: 24}}>
								<div className="">
									<table id="ntable" aria-label="">
										<thead>
											<tr>
												<th
													className="ntable-first"
													style={{
														textAlign: "left",
														display: "flex",
													}}
												>
													{table[0]?.datasource}
													<Tooltip
														title={
															tooltipHeader.length > 0
																? tooltipHeader.filter(
																	(item) =>
																		item.value === table[0]?.datasource
																)[0].tooltip_data
																: []
														}
													>
														<span style={{ color: "#8dc441" }}>
															<FaInfoCircle />
														</span>
													</Tooltip>
												</th>
												<th
													style={{
														backgroundColor: "#c9f1cb",
														textAlign: "center",
													}}
												>
													CURRENT
												</th>
												<th
													style={{
														backgroundColor: "#ffcc80",
														textAlign: "center",
													}}
												>
													PREVIOUS
												</th>
												<th
													style={{
														textAlign: "center",
													}}
												>
													CHANGE
												</th>
											</tr>
										</thead>
										<tbody>
											{table?.map((item, index) => (
												<tr key={index}>
													<td>{item?.metric}</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{item?.format == "amount"
															? format_data(
																item?.curr,
																item?.format,
																item?.round_off,
																item?.metric.toLowerCase()
															)
															: item?.format == "percentage"
																? format_data(
																	item?.curr,
																	item?.format,
																	item?.round_off,
																	item?.metric.toLowerCase().replace(' ', '_')
																)
																: item?.format == "number"
																	? format_data(
																		item?.curr,
																		item?.format,
																		item?.round_off,
																		item?.metric
																	)
																	: ""}
													</td>
													<td
														style={{
															textAlign: "center",
														}}
													>
														{item?.format == "amount"
															? format_data(
																item?.prev,
																item?.format,
																item?.round_off,
																item?.metric.toLowerCase()
															)
															: item?.format == "percentage"
																? format_data(
																	item?.prev,
																	item?.format,
																	item?.round_off,
																	item?.metric.toLowerCase().replace(' ', '_')
																)
																: item?.format == "number"
																	? format_data(
																		item?.prev,
																		item?.format,
																		item?.round_off,
																		item?.metric
																	)
																	: ""}
													</td>
													<td
														className="arrow-column"
														style={{
															textAlign: "center",
														}}
													>
														{/* <ArrowInsights
															metric={item?.metric}
															value={item?.change}
														/> */}
														{item?.change
															? format_data(item?.change, "percentage", undefined, item?.metric, true)
															: "0%"}
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<div className="insights">
										<h4>Insights</h4>
										<ul>
											{getValueDataByKey(
												insightsData,
												table[0]?.datasource
											).map((insight) => (
												<li>{HtmlParser(insight)}</li>
											))}
										</ul>
									</div>
								</div>
							</Col>
						);
					})}
				</Row>
			</Spin>
		</>
	);
};

export default NormalTable;
