import { Button, Form, Input, Space, Typography } from "antd";
import { useHistory } from "react-router-dom";

const PInterestUpdateConfigurationForm = ({
  form,
  onFinish,
  onFinishFailed,
}) => {
  const history = useHistory();
  const handleChange = (info) => {
    return info;
  };

  const handleRequest = (values) => {};

  const handleCancel = () => {
    history.push("/google-ads-configuration");
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        name="google_ads_form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="User"
          name="user"
          rules={[
            {
              required: true,
              message: "User Email",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input placeholder="User" />
        </Form.Item>

        <Space direction="horizontal">
          <Button type="primary" htmlType="submit">
            Update
          </Button>
          <Button type="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Space>

        <Typography style={{ padding: "10px 0" }}>
          Note: Please take precaution while editing the values. It has
          site-wide effects.
        </Typography>
      </Form>
    </>
  );
};
export default PInterestUpdateConfigurationForm;
