import React from "react";
import { Row, Col } from "antd";
import logo from "../../Assets/Images/logo.png";
import semrush from "../../Assets/Images/semrush.png";
import {
	FaFile,
	FaCubes,
	FaQuoteLeft,
	FaEyeSlash,
	FaDownload,
} from "react-icons/fa";

const Scorecard = () => {
	return (
		<>
			<div className="organic-scorecard-wrapper">
				<div className="organic-scorecard-content">
					<div className="organic-scorecard-header">
						<div className="os-logo">
							<img src={logo} alt="Logo" />
						</div>
					</div>
					<div className="organic-scorecard-grade">
						<Row gutter={32}>
							<Col span={12}>
								<div className="osg-left">
									<span>Overall Grade</span>
									<h2>B</h2>
									<h4>SEO Scorecard</h4>
									<p>Online Stores Inc</p>
								</div>
							</Col>
							<Col span={12}>
								<div className="osg-right">
									<ul>
										<li>
											<FaFile />
											Organic keyword positions
											<span>C</span>
										</li>
										<li>
											<FaCubes />
											Relevance differential
											<span>A</span>
										</li>
										<li>
											<FaQuoteLeft />
											Striking distance keywords
											<span>7091</span>
										</li>
										<li>
											<FaEyeSlash />
											Hidden 404 errors
											<span>B</span>
										</li>
									</ul>
								</div>
							</Col>
						</Row>
					</div>
					<div className="organic-scorecard-keyword organic-scorecard-single">
						<div className="os-single-title">
							<h4>Organic keyword positions</h4>
							<span>GRADE: C</span>
						</div>
						<div className="os-single-header">
							<div className="ossh-single">
								<h4>
									Total <br /> Keywords
								</h4>
								<h2>41,063</h2>
							</div>
							<div className="ossh-single">
								<h4>
									Peak <br /> Month
								</h4>
								<h2>09/22</h2>
							</div>
							<div className="ossh-single">
								<h4>
									Peak Page 1 <br /> Rankings
								</h4>
								<h2>14,789</h2>
							</div>
							<div className="ossh-single">
								<h4>
									Current Page 1 <br /> Rankings
								</h4>
								<h2>10,651</h2>
							</div>
							<div className="ossh-single">
								<h4>
									Change <br /> Since Peak
								</h4>
								<h2>-27.98%</h2>
							</div>
						</div>
						<div className="os-single-content">
							<div
								style={{
									textAlign: "center",
									fontSize: "20px",
									margin: "30px 0",
								}}
							>
								(Column Chart Here)
							</div>
							<p>
								Your peak SEO performance occurred in <b>September 2022</b> with
								<b>14,789</b> keywords ranking in the top 10 on Google desktop.
								You currently have <b>10,651</b> keywords in the top 10. Your
								current rankings are <b>27.98%</b> lower than your peak.
							</p>
						</div>
						<div className="os-single-help">
							<h4>How can we help ?</h4>
							<p>
								The keywords you rank for are a leading indicator for organic
								traffic. If the number of keywords you rank for has recently
								dropped, we can help you recover your page 1 positions. Contact
								us for an SEO analysis as part of our free ecommerce growth
								plan.
							</p>
							<button>Request a Free Ecommerce Growth Plan</button>
						</div>
					</div>
					<div className="organic-scorecard-relevance organic-scorecard-single">
						<div className="os-single-title">
							<h4>Relevance differential</h4>
							<span>GRADE: A</span>
						</div>
						<div className="os-single-header">
							<div className="ossh-single">
								<h4>Total ranking keywords</h4>
								<h2>41,063</h2>
							</div>
							<div className="ossh-single">
								<h4>Number on pg. 1</h4>
								<h2>10,651</h2>
							</div>
							<div className="ossh-single">
								<h4>Percent on pg. 1</h4>
								<h2>26.63%</h2>
							</div>
						</div>
						<div className="os-single-content">
							<div
								style={{
									textAlign: "center",
									fontSize: "20px",
									margin: "30px 0",
								}}
							>
								(Line Chart Here)
							</div>
							<p>
								Your site currently ranks for <b>41,063</b> keywords on Google
								desktop – and <b>10,651 (26.63%)</b> of those keywords are on
								page 1.
							</p>
							<p>
								At AGITAL, the percent of keywords on page 1 are called the
								“relevance differential” and a healthy content program produces
								a number in the range 21%-30%.
							</p>
							<p>
								Low relevance differential (20%) is caused by poor use of
								content – either non-existent, too sparse, duplicated, poorly
								written, or over-optimized.
							</p>
						</div>
						<div className="os-single-help">
							<h4>How can we help ?</h4>
							<p>
								At AGITAL we can utilize the relevance differential formula
								to discover your site’s biggest possible gains in traffic. If
								you are below the 21-30% threshold for keywords that rank on
								page 1, book a free analysis to identify the exact cause. Or if
								you’re within the healthy range, our free ecommerce growth plan
								can show you additional areas to improve.
							</p>
							<button>Request a Free Ecommerce Growth Plan</button>
						</div>
					</div>
					<div className="organic-scorecard-striking organic-scorecard-single">
						<div className="os-single-title">
							<h4>Striking distance keywords</h4>
						</div>
						<div className="os-single-header">
							<div className="ossh-single">
								<h4>Keywords in striking distance</h4>
								<h2>7,091</h2>
							</div>
						</div>
						<div className="os-single-content">
							<div className="ossc-table">
								<table>
									<tbody>
										<tr>
											<th>Keyword</th>
											<th>Search Volume</th>
											<th>Rank</th>
											<th>URL</th>
										</tr>
									</tbody>
									<tr>
										<td>bi flag</td>
										<td>135,000</td>
										<td>18</td>
										<td>
											https://www.united-states-flag.com/bisexual-flags.html
										</td>
									</tr>
									<tr>
										<td>bisexual flag</td>
										<td>110,000</td>
										<td>17</td>
										<td>
											https://www.united-states-flag.com/bisexual-flags.html
										</td>
									</tr>
									<tr>
										<td>german flag</td>
										<td>90,500</td>
										<td>5</td>
										<td>
											https://www.united-states-flag.com/world-germany.html
										</td>
									</tr>
									<tr>
										<td>pansexual flag</td>
										<td>90,500</td>
										<td>11</td>
										<td>
											https://www.united-states-flag.com/pansexual-flags.html
										</td>
									</tr>
									<tr>
										<td>british flag</td>
										<td>74,000</td>
										<td>7</td>
										<td>
											https://www.united-states-flag.com/world-british.html
										</td>
									</tr>
									<tr>
										<td>argentina flag</td>
										<td>60,500</td>
										<td>5</td>
										<td>https://www.united-states-flag.com/argentina.html</td>
									</tr>
									<tr>
										<td>england flag</td>
										<td>60,500</td>
										<td>15</td>
										<td>
											https://www.united-states-flag.com/world-british.html
										</td>
									</tr>
									<tr>
										<td>puerto rico flag</td>
										<td>60,500</td>
										<td>8</td>
										<td>
											https://www.united-states-flag.com/state-territories-puerto-rico.html
										</td>
									</tr>
									<tr>
										<td>pan flag</td>
										<td>60,500</td>
										<td>15</td>
										<td>
											https://www.united-states-flag.com/pansexual-flags.html
										</td>
									</tr>
									<tr>
										<td>asexual flag</td>
										<td>60,500</td>
										<td>19</td>
										<td>
											https://www.united-states-flag.com/asexual-flags.html
										</td>
									</tr>
								</table>
							</div>
							<p>
								Striking distance keywords are those that rank between positions
								<b>5 and 20</b>. Because they are so close to page 1 and the top
								of page 1, any improvement in ranking can have a significant
								effect on organic traffic.
							</p>
							<p>
								Your site has 7,091 keywords in striking distance, with a sample
								shown above.
							</p>
						</div>
						<div className="os-single-help">
							<h4>How can we help ?</h4>
							<p>
								Achieving page 1 rank for these keywords is more than creating
								content around exact-match optimization. Learn how we can help
								with on- and off-page signals that target the lowest hanging
								fruit for your site.
							</p>
							<button>Request a Free Ecommerce Growth Plan</button>
						</div>
					</div>
					<div className="organic-scorecard-errors organic-scorecard-single">
						<div className="os-single-title">
							<h4>Hidden 404 errors</h4>
							<span>GRADE: B</span>
						</div>
						<div className="os-single-header">
							<div className="ossh-single">
								<h4>Total Pages</h4>
								<h2>1,000</h2>
							</div>
							<div className="ossh-single">
								<h4>Total 404s</h4>
								<h2>80</h2>
							</div>
							<div className="ossh-single">
								<h4>% of 404s</h4>
								<h2>8 %</h2>
							</div>
						</div>
						<div className="os-single-content">
							<div className="ossc-table">
								<table>
									<tr>
										<td>#1</td>
										<td>
											https://www.united-states-flag.com/world-british.html/united-kingdom-flag-history.htm
										</td>
									</tr>
									<tr>
										<td>#2</td>
										<td>http://www.united-states-flag.com/usa9senyfl3f.html</td>
									</tr>
									<tr>
										<td>#3</td>
										<td>
											https://www.united-states-flag.com/support-our-troops-magnet.html
										</td>
									</tr>
									<tr>
										<td>#4</td>
										<td>
											https://www.united-states-flag.com/nfl-flags-banners.html
										</td>
									</tr>
									<tr>
										<td>#5</td>
										<td>
											https://www.united-states-flag.com/adjustable-aluminum-flagpole-bracket.html
										</td>
									</tr>
								</table>
							</div>
							<p>
								Websites across the internet are pointing to your site. These
								occurrences are called backlinks. When checking the top 1,000
								pages to your site, we identified <b>80</b> with a 404 error
								that may have link value attached to them.
							</p>
							<p>
								These pages were deleted, without proper 301 redirects. 404
								errors on backlinked pages can contribute to major drops in
								rankings until they are resolved. Resolutions vary based on the
								situation and include using tactics like manual outreach to
								update links and wildcard redirects.
							</p>
						</div>
						<div className="os-single-help">
							<h4>How can we help ?</h4>
							<p>
								Building links is hard enough – don’t leave link authority on
								the table with 404 errors. If you need assistance managing 404
								errors while maintaining SEO and optimal user experience, speak
								with our SEO experts by scheduling a free ecommerce growth plan.
							</p>
							<button>Request a Free Ecommerce Growth Plan</button>
						</div>
					</div>
					<div className="organic-scorecard-buttons">
						<button>
							Download PDF <FaDownload />
						</button>
						<span>
							Data from semrush <img src={semrush} alt="semrush" />
						</span>
					</div>
					<div className="organic-scorecard-footer">
						<p>
							<a href="#">Exclusive Concepts </a> | One Wall Street 5th Floor,
							Burlington, MA 01803 | <a href="#">Contact Us</a>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};
export default Scorecard;
