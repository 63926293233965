import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaPencilAlt } from "react-icons/fa";
import { Tooltip } from "antd";

const AmazonRatings = (props, text) => {
  let record_data = props?.record;

  const one_star_pc = record_data["one_star_pc"];
  const two_star_pc = record_data["two_star_pc"];
  const three_star_pc = record_data["three_star_pc"];
  const four_star_pc = record_data["four_star_pc"];
  const five_star_pc = record_data["five_star_pc"];

  const columnIds = ["name"];
  const column = ["one_star_pc"];
  const brand_name = ["brand_name"];
  const total_ratings = ["total_ratings"];
  const total_ratings_change = ["total_ratings_change"];
  const sku = ["sku"];
  const accountId = props?.extraParams?.account_id;

  const columnList = [
    "one_star_pc",
    "two_star_pc",
    "three_star_pc",
    "four_star_pc",
    "five_star_pc",
  ];
  if (column.includes(props.column.dataIndex)) {
    return (
      <div class="amazon-rating-wrapper">
        {one_star_pc !== null ? (
          <div
            class="rating-single one_star_pc"
            style={{ width: one_star_pc + "%" }}
          >
            {one_star_pc}%
          </div>
        ) : (
          ""
        )}
        {two_star_pc !== null ? (
          <div
            class="rating-single two_star_pc"
            style={{ width: two_star_pc + "%" }}
          >
            {two_star_pc}%
          </div>
        ) : (
          ""
        )}
        {three_star_pc !== null ? (
          <div
            class="rating-single three_star_pc"
            style={{ width: three_star_pc + "%" }}
          >
            {three_star_pc}%
          </div>
        ) : (
          ""
        )}
        {four_star_pc !== null ? (
          <div
            class="rating-single four_star_pc"
            style={{ width: four_star_pc + "%" }}
          >
            {four_star_pc}%
          </div>
        ) : (
          ""
        )}
        {five_star_pc !== null ? (
          <div
            class="rating-single five_star_pc"
            style={{ width: five_star_pc + "%" }}
          >
            {five_star_pc}%
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  if (brand_name.includes(props.column.dataIndex)) {
    return (
      <>
        {props.value != null && (
          <>
            <a
              href={"https://www.amazon.com" + props.record.brand_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </a>
          </>
        )}
      </>
    );
  } else if (total_ratings.includes(props.column.dataIndex)) {
    return (
      <>
        {props.value != null && (
          <>
            <a
              href={
                "https://www.amazon.com/product-reviews/" +
                props.record.asin +
                "/reviewerType=all_reviews&filterByStar=all_stars"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </a>
          </>
        )}
      </>
    );
  } else if (total_ratings_change.includes(props.column.dataIndex)) {
    return (
      <>
        {props.value != null && (
          <>
            <a
              href={
                "https://www.amazon.com/product-reviews/" +
                props.record.asin +
                "/reviewerType=all_reviews&filterByStar=all_stars/ref=cm_cr_arp_d_viewopt_srt?sortBy=recent&pageNumber=1"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedNumberCell
                value={props.value}
                format={props.column.format}
              />
            </a>
          </>
        )}
      </>
    );
  } else if (sku.includes(props.column.dataIndex)) {
    return (
      <>
        {props.value != null && (
          <>
            {record_data["asin_source"] == "ASC" ? (
              <a
                href={
                  "/reports/v2/view/amazon_product_performance/" +
                  accountId
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </a>
            ) : (
              <a
                href={
                  "/reports/v2/view/amazon_marketing_product_performance/" +
                  accountId
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedNumberCell
                  value={props.value}
                  format={props.column.format}
                />
              </a>
            )}
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <span>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      </>
    );
  }
};

export default AmazonRatings;
