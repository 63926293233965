import * as annotationsReplyApi from "../../../Api/annotationsReplyApi";
import * as annotationsReplyAction from "../annotationsReplyAction";
import { message } from "antd";
import { ACTIONS_TYPES } from "../../Reducers/annotationsReplyReducer";
import * as getAnnotationsReplyAction from "../annotationsReplyAction";

export const getReply = (props, params = "") => {
  return async (dispatch) => {
    try {
      const response = await annotationsReplyApi.getAnnotationsReply(props, params);
      
      let reply ={
      }
      if (response?.status === 200) {
        reply = {data:response?.data?.reply};
      }
    return dispatch(getAnnotationsReplyAction.getAnnotationsReplyAction(reply));
    } catch (error) {
      console.error("Error in fetching accounts data: " + error);
      throw error;
    } finally {

    }
  };
};
