import * as donutApi from "../../../Api/donutApi";
import * as donutAction from "../donutAction";
import { ACTIONS_TYPES } from "../../Reducers/donutReducer";

export const getDonutChartData = (props, data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS_TYPES.FETCH_DONUT_CHART_DATA,
            });
            let donutChartData = {};
            const response = await donutApi.getDonutChart(props, data);
            if (response?.status === 200) {
                donutChartData = {
                    [data.block.block_id]: {
                        data: response?.data?.data,
                    },
                };
            }
            return dispatch(donutAction.getDonutChart(donutChartData));
        } catch (error) {
            console.error("Error in fecthing: " + error);
        } finally {
        }
    };
};
