/**
 * @fileoverview Reducer for Pinterest Status Data Sources
 *
 * The reducer handles the state changes for the Pinterest.
 */

// Define the initial state of the reducer
export const initialState = {
  account_ds: {}, // for single account data source
  account_dss: {}, // forall  account data sources . i used dss because of data sources . note "s" at the end, it is plural
  error_ds: {}, // error data sources
  isLoading: false, // loading state
};

// Define action types to be used in the reducer
export const ACTIONS_TYPES = Object.freeze({
  PINTEREST_CLIENT_DS_REQUEST: "PINTEREST_CLIENT_DS_REQUEST",
  GET_PINTEREST_CLIENT_DS: "GET_PINTEREST_CLIENT_DS", // Fetch Account Data Sources
  CREATE_PINTEREST_CLIENT: "CREATE_PINTEREST_CLIENT", // Create Account Data Sources
  UPDATE_PINTEREST_CLIENT: "UPDATE_PINTEREST_CLIENT", // Update Account Data Sources
  PINTEREST_CLIENT_ERROR_DS: "PINTEREST_CLIENT_ERROR_DS", // Error Account Data Sources
  FAILED_PINTEREST_CLIENT: "FAILED_PINTEREST_CLIENT",
  RESET_PINTEREST_CLIENT: "RESET_PINTEREST_CLIENT",
});

// Define the reducer function
export function googlePinterestClientReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;

  // Switch case to handle different types of actions
  switch (type) {
    case ACTIONS_TYPES.PINTEREST_CLIENT_DS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_PINTEREST_CLIENT_DS:
      return {
        ...state,
        isLoading: false,
        account_ds: action.payload,
      };
    case ACTIONS_TYPES.GET_ALL_GOOGL_CONSOLES_DS:
      return {
        ...state,
        isLoading: false,
        account_dss: action.payload,
      };

    case ACTIONS_TYPES.PINTEREST_CLIENT_ERROR_DS:
    case ACTIONS_TYPES.FAILED_PINTEREST_CLIENT:
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };

    case ACTIONS_TYPES.RESET_PINTEREST_CLIENT:
      return {
        ...state,
        isLoading: false,
        account_ds: {},
        account_dss: {},
        error_ds: {},
      };

    // Return the initial state if no matching action is found
    default:
      return state;
  }
}
