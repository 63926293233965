import * as annotationsCommentApi from "../../../Api/annotationsCommentApi";
import * as annotationsCommentAction from "../annotationsCommentAction";
import { message } from "antd";
import { ACTIONS_TYPES } from "../../Reducers/annotationsCommentReducer";
import * as getAnnotationsCommentAction from "../annotationsCommentAction";

export const getComment = (props, params = "") => {
  return async (dispatch) => {
    try {
      const response = await annotationsCommentApi.getAnnotationsComment(props, params);
      
      let comment ={
      }
      if (response?.status === 200) {
        comment = {data:response?.data?.comment};
      }
    return dispatch(getAnnotationsCommentAction.getAnnotationsCommentAction(comment));
    } catch (error) {
      console.error("Error in fetching accounts data: " + error);
      throw error;
    } finally {

    }
  };
};
