import { Button, Checkbox, List, Modal } from 'antd'
import React, { useState } from 'react'

const ShowHideModal = (props) => {
  const {
    tableColumns, 
    isShowHideModalOpen, 
    handleCancelShowHideModal,
    setColumnShowHideModalCallback
  } = props
  const [isHiddenColumns, setHiddenColumns] = useState([]);
  
  const onChange = (e, columnName) => {
    const isChecked = e.target.checked;

    // Create a new array based on the checked state
    let newArray = isChecked
      ? [...isHiddenColumns, columnName]
      : isHiddenColumns.filter((col) => col !== columnName);

    // Update the state variables
    setColumnShowHideModalCallback(newArray);
    setHiddenColumns(newArray);
  };

  return (
    <Modal
          title="Show Hide Column Modal"
          visible={isShowHideModalOpen}
          onCancel={handleCancelShowHideModal}
          className="datatable-toggle-modal"
          width={450}
          footer={[
            <Button key="close" onClick={handleCancelShowHideModal}>
              Close
            </Button>,
          ]}
        >
          <div className="toggle-form-options">
            <List> 
              {tableColumns?.map((column) =>
                column?.children ? (
                  column.children.map((col) => (
                    <List.Item key={col.dataIndex}>
                      <Checkbox
                        onChange={(e) => onChange(e, col.dataIndex, column)}
                      >
                        {col.title}
                      </Checkbox>
                    </List.Item>
                  ))
                ) : (
                  <List.Item key={column.dataIndex}>
                    <Checkbox
                      onChange={(e) => onChange(e, column.dataIndex)}
                    >
                      {column.title}
                    </Checkbox>
                  </List.Item>
                )
              )}
            </List>
          </div>
        </Modal>
  )
}

export default ShowHideModal
