import { EditOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const GoogleAdsTable = () => {
  const [datas, setDatas] = useState([]);
  const {
    account_dss: { auth_params = [] },
  } = useSelector((state) => state.googleAds);


  useEffect(() => {
   setDatas(auth_params)
  }, [auth_params.length]);



  const columns = [
    {
      title: "Account",
      dataIndex: "user",
      key: "email",
      render: (text) => text,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    // {
    //   title: "Refresh Token",
    //   dataIndex: "refreshToken",
    //   key: "refreshToken",
    // },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={`/google-ads-configuration/edit/${record.id}`}>
              <EditOutlined
                style={{
                  height: "20px",
                  width: "20px",
                  backgroundColor: "rgb(141,195,64)",
                  borderRadius: "50%",
                  color: "white",
                  textAlign: "center",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Input
      onChange={(e) => {
        const { value } = e.target;
        const filteredData = auth_params.filter((item) => {
          return item.user.toLowerCase().includes(value.toLowerCase());
        });
        setDatas(filteredData);
      }
      }
       placeholder="Account" />
      <Table columns={columns} dataSource={datas} />
    </>
  );
};

export default GoogleAdsTable;
