import * as tiktokAPI from "../../../../Api/datasources/tiktokAPI";
import errorHandler from "../../../../Utils/Request/errorHandler";
import successHandler from "../../../../Utils/Request/successHandler";
import { ACTIONS_TYPES } from "../../../Reducers/datasources/tiktokReducer";
import { setLoadingFalse, setLoadingTrue } from "../../globals/loadingThunk";

/**
 * Get Tiktok account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const getSingleTiktokThunk = (props) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.TIKTOK_DS_REQUEST,
      });
      dispatch({
        type: ACTIONS_TYPES.RESET_TIKTOK_DS,
      });

      dispatch(setLoadingTrue());
      // Call the API to create a new data source
      const response = await tiktokAPI.getTiktokApi(props);

      dispatch({
        type: ACTIONS_TYPES.GET_TIKTOK_DS,
        payload: response.data.data,
      });
    } catch (error) {
      // Log error message to console and return error handler
      console.error("Error in fetching account data source " + error);
      // return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
    }
  };
};

/**
 * Create a new Tiktok account data source
 * @param {object} props - a payload to pass to the API
 * @returns {Function} dispatch
 */
export const createTiktokThunk = (props) => {
  return async (dispatch) => {
    try {
      // Dispatch request action and set loading state to true
      dispatch({
        type: ACTIONS_TYPES.TIKTOK_DS_REQUEST,
      });
      dispatch(setLoadingTrue());

      // Call the API to create a new data source
      const response = await tiktokAPI.createTiktokApi(props);

      // Notify user of success
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
    } catch (error) {
      return errorHandler(error);
    } finally {
      // Set loading state to false
      dispatch(setLoadingFalse());
    }
  };
};

/**
 * Update an Tiktok data source
 * @param {object} props - The payload to pass to the API
 * @returns {Function} - A Redux thunk function that dispatches actions to update the data source and handle errors
 */
export const updateTiktokThunk = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.TIKTOK_DS_REQUEST,
      });
      dispatch(setLoadingTrue());
      delete props.starTableData;
      const response = await tiktokAPI.updateTiktokApi(props);
      // Notify user
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
    } catch (error) {
      return errorHandler(error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
};
