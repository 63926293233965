import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const FacebookPreview = () => {
  const { id } = useParams();
  const [jsIframe, setJsIframe] = useState("<span>Loading...</span>");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const ad_id = id;

  const getPreviewData = () => {
    const version = process.env.REACT_APP_FB_PREVIEW_API_VERSION;
    const access_token = process.env.REACT_APP_FB_PREVIEW_ACCESS_TOKEN;

    fetch(
      `https://graph.facebook.com/${version}/${ad_id}/previews?ad_format=MOBILE_FEED_STANDARD&access_token=${access_token}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setJsIframe(data?.data[0]?.body || "<span>No preview available</span>");
        setLoading(false);
      })
      .catch((error) => {
        setJsIframe(
          '<div style="background-color: #fff; padding: 20px; border: 1px solid #ccc; width: 200px;">' +
            "<span>Ad Preview not available</span>" +
            "</div>"
        );
        setError(error);
        setLoading(false);
        console.error("Error fetching ad preview:", error);
      });
  };

  useEffect(() => {
    getPreviewData();
  }, [ad_id]);

  return (
    <div style={{ textAlign: "center" }}>
      {/* <h1>Ad Details</h1> */}
      {loading ? (
        <p>Loading...</p>
      ) : ad_id ? (
        <div
          style={{
            display: "inline-block",
            minWidth: "300px",
            minHeight: "400px",
            border: "1px solid #ccc",
            padding: "20px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: jsIframe,
            }}
          />
        </div>
      ) : (
        <p>No data available.</p>
      )}
      {error && <p style={{ color: "red" }}>Error: {error.message}</p>}
    </div>
  );
};

export default FacebookPreview;
