import * as ScatterReducer from "../ScatterAction";
import { ACTIONS_TYPES } from "../../Reducers/ScatterReducer";
import * as ScatterApi from "../../../Api/ScatterApi";

export const getScatterData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.GET_SCATTER_DATA,
      });
      let scatter_data = {};
      const response = await ScatterApi.getScatterData(props, data);
      if (response?.status === 200) {
        scatter_data = {
          [data?.block?.block_id]: {
            series_data: response?.data?.series_data,
            insight: response?.data?.insight,
          },
        };
      }
      dispatch({
        type: ACTIONS_TYPES.FETCH_SCATTER_DATA,
        payload: scatter_data,
      });
      return dispatch(
        ScatterReducer.fetchScatterData(scatter_data)
      );
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
