import { ACTIONS_TYPES } from "../Reducers/accountReducer";

export function getAccount(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ACCOUNT,
    payload,
  };
}

export function getFreemiumAccounts(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_FREEMIUM_ACCOUNTS,
    payload,
  };
}

export function getIMSUsers(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_IMS_USERS,
    payload,
  };
}

export function getSpecialistUsers(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_SPECIALIST_USERS,
    payload,
  };
}

export function getServices(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_SERVICES,
    payload,
  };
}

export function getUserAccounts(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_USER_ACCOUNTS,
    payload,
  };
}

export function getAccountDetail(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ACCOUNT_DETAIL,
    payload,
  };
}

export function getAccountContacts(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ACCOUNT_CONTACTS,
    payload,
  };
}

export function getAccountEmailReportLogs(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ACCOUNT_EMAIL_REPORT_LOGS,
    payload,
  };
}

export function getAccountNotifications(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ACCOUNT_NOTIFICATIONS,
    payload,
  };
}

export function getAccountReports(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ACCOUNT_REPORTS,
    payload,
  };
}

export function getClientId(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_CLIENT_ID,
    payload,
  };
}
export function getAccountAdvancedSetting(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_ACCOUNT_ADVANCED_SETTING,
    payload,
  };
}

export function saveAccountAdvancedSetting(payload) {
  return {
    type: ACTIONS_TYPES.SAVE_ACCOUNT_ADVANCED_SETTING,
    payload,
  };
}

export function getFreemiumServices(payload){
  return {
    type: ACTIONS_TYPES.FETCH_FREEMIUM_SERVICES,
    payload,
  }
}

export function getAccountFeedsUrl(payload) {
  return {
    type: ACTIONS_TYPES.ACCOUNT_FETCH_FETCH_COMPLETE,
    payload,
  };
}