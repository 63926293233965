import React, { useMemo } from "react";
import Highcharts from "highcharts";
import Chart from "../Highcharts/ChartColumn";

const Donut = (props) => {
  var config = props?.props?.block?.config ? JSON.parse(props?.props?.block?.config) : null;
  var innerSize = config?.donutSettings?.series[0].innerSize ?? '60%';
  var size = config?.donutSettings?.series[0].size ?? '100%';
  var verticalAlign = config?.donutSettings?.title?.verticalAlign ?? "middle";
  
const backgroundColor = props.backgroundColor || "#FFF";

  const dataSeries = useMemo(() => {
    return props?.data
      .filter((el) => el.chart === "pie")
      .map((values, index) => {
        const pieOptions = {
          chart: {
            type: "pie",
            height: 250,
            margin: [0, 0, 0, 0],
            backgroundColor: backgroundColor
          },
          title: {
            verticalAlign: verticalAlign,
            floating: true,
            text: values.metric,
          },
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            padding: -10,
            symbolRadius: 0,
            itemMarginTop: 5,
            itemMarginBottom: 5,
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false,
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: false,
              },
              showInLegend: false,
            },
          },
          tooltip: {
            formatter: function () {
              return (
                "<b>" +
                this.point?.name +
                ": </br>" +
                Highcharts.numberFormat(this.y, 0) +
                "%"
              );
            },
          },
          series: [
            {
              data: values.data,
              size: size,
              innerSize: innerSize,
            },
          ],
        };
        return pieOptions;
      });
  }, [props?.data]);

  return (
    <>
      {dataSeries?.map((option, index) => (
        <div className="performance-graph-chart" key={index}>
          <Chart options={option} />
        </div>
      ))}
    </>
  );
};

export default Donut;
