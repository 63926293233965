export const initialState = {
  service_settings: {},
  isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
  REQUEST_SERVICE_SETTINGS: "REQUEST_SERVICE_SETTINGS",
  GET_SERVICE_SETTINGS: "GET_SERVICE_SETTINGS",
  SAVE_SERVICE_SETTINGS: "SAVE_SERVICE_SETTINGS",
});

export function serviceSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.REQUEST_SERVICE_SETTINGS:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.GET_SERVICE_SETTINGS:
      return {
        ...state,
        isLoading: false,
        service_settings: action.payload,
      };
    case ACTIONS_TYPES.SAVE_SERVICE_SETTINGS:
      return {
        ...state,
        isLoading: false,
        service_settings: action.payload,
      };
    default:
      return state;
  }
}
