import * as pieApi from "../../../Api/pieApi";
import * as pieAction from "../pieAction";
import { FETCH_GRAPH, REQUEST_DATA } from "../types/index";
import { ACTIONS_TYPES } from "../../Reducers/pieReducer";

export const getPieChartData = (props, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS_TYPES.FETCH_PIE_CHART_DATA,
      });
      let pie = {};
      const response = await pieApi.getPieChart(props, data);
      if (response?.status === 200) {
        pie = {
          [data.block.block_id]: {
            data: response?.data?.response_pie_data,
          },
        };
      }
      return dispatch(pieAction.getPieChart(pie));
    } catch (error) {
      console.error("Error in fecthing: " + error);
    } finally {
    }
  };
};
