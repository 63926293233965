import React from "react";
import SearchFilter from "./SearchFilter";
import ToggleFilter from "./ToggleFilter";

export const FilterBtns = ({
  toggleChangeColumnVisibility,
  toggleAbsoluteChangeColumnVisibility,
}) => {
  const onChangeHandler = () => {
    console.log();
  };

  return (
    <>
      {/* <SearchFilter placeholder={'Search Here!'}/> */}
      <ToggleFilter
        defaultChecked={false}
        label="Show Change Columns"
        onChangeFn={onChangeHandler}
        toggleChangeColumnVisibility={toggleChangeColumnVisibility}
        toggleAbsoluteChangeColumnVisibility={
          toggleAbsoluteChangeColumnVisibility
        }
      />
    </>
  );
};
