/**
 *
 * @param {value, record and column} props
 * @returns "Table Cell with Class"
 */
import { useState } from "react";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Modal, Spin } from "antd";
import Config from "../../../Config";
import { Table, Button } from "antd";
import axios from "axios";
import { BiLineChart } from "react-icons/bi";

const ServiceCell = (props) => {
  let eparams = props?.extraParams?.all_input_data;
  let f_month = eparams?.f_month;
  let t_month = eparams?.t_month;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seriesData, setSeriesData] = useState([]);
  const [spinning, setSpinning] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const {
    API: { baseURL: baseUrl_API, authKey: authkey },
  } = Config;

  const fetchData = async (url, data) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Something went wrong");
    }
  };

  const getInputData = async (ds_key) => {
    try {
      const formdata = new FormData();
      formdata.append("authentication", authkey);
      formdata.append("from_date", f_month);
      formdata.append("to_date", t_month);
      formdata.append("account_id", props?.record?.account_id);
      formdata.append("data_source_key", ds_key);

      setSpinning(true);

      const result = await fetchData(
        baseUrl_API + "v2/internal/account_setup_status/pull_details",
        formdata
      );
      setSeriesData(result?.data);
    } catch (error) {
      // Handle errors
      console.error(error.message);
    } finally {
      setSpinning(false);
    }
  };

  const openChart = (props) => {
    setIsModalOpen(true);
    getInputData(props?.record?.data_source_id);
  };

  var accountName = props?.record?.account_name;

  const dataSource = [
    {
      start_date: "1",
      end_date: "2024-01-01",
      status: 32,
      pull_duration: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Pull Duration",
      dataIndex: "pull_duration",
      key: "pull_duration",
    },
  ];

  return (
    <>
      <span className="datatable-include-chart">
        <FormattedNumberCell value={props.value} format={props.column.format} />
        <span style={{ marginLeft: "10%" }}>
          <BiLineChart onClick={() => openChart(props)} />
          {/* <Button type="primary" size="small" onClick={() => openChart(props)}>
            Pull Details
          </Button> */}
        </span>
        {isModalOpen && (
          <Modal
            className="impact-modals"
            title={`${accountName} -  Logs`}
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width="80%"
            footer={null}
          >
            {
              <Spin spinning={spinning}>
                <Table dataSource={seriesData} columns={columns} />
              </Spin>
            }
          </Modal>
        )}
      </span>
    </>
  );
};

const MonocleAccountSetupStatus = (props) => {
  let cellClass = "";
  let blockId = props?.extraParams?.block?.block_id;

  const tblColumn = ["account_name"];
  if (blockId == 778) {
    if (tblColumn.includes(props.column.dataIndex)) {
      return <ServiceCell {...props} />;
    } else {
      return (
        <span className={cellClass}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
          />
        </span>
      );
    }
  } else {
    return (
      <span className={cellClass}>
        <FormattedNumberCell value={props.value} format={props.column.format} />
      </span>
    );
  }
};

export default MonocleAccountSetupStatus;
