import { ACTIONS_TYPES } from "../Reducers/loginReducer";

export function loginSuccess(payload) {
  return {
    type: ACTIONS_TYPES.LOGIN_SUCCESS,
    payload,
  };
}
export function loginFailure(payload) {
  return {
    type: ACTIONS_TYPES.LOGIN_FAILURE,
    payload,
  };
}
export function logout(payload) {
  return {
    type: ACTIONS_TYPES.LOGOUT,
    payload,
  };
}
export function checkMFA(payload) {
  return {
    type: ACTIONS_TYPES.CHECK_MFA,
    payload,
  };
}
export function impersonate(payload) {
  return {
    type: ACTIONS_TYPES.IMPERSONATE,
    payload,
  };
}
export function stopImpersonate(payload) {
  return {
    type: ACTIONS_TYPES.IMPERSONATE,
    payload,
  };
}
