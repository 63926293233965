import { Row, Col, InputNumber, Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formDataAppend, numberWithCommas } from "../../Utils/helpers";
import { getTableSliderData } from "../../Redux/Actions/Thunk/tableSliderThunk";
import HtmlParser from "html-react-parser";

const CLVGoal = (props) => {
  const {
    tableSlider: {
      table_slider_data: {
        [props?.block?.block_id]: {
          cpa: value = "",
          insights_data: insights = [],
          data: tableData = [],
          ad_spend: ad_spend = "",
        } = {},
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [cpaVal, setCpaVal] = useState("");
  const [calculatedNetVal, setCalculatedNetVal] = useState("");
  const [calculatedAdSpendVal, setCalculatedAdSpendVal] = useState("");
  const [profitMarginVal, setProfitMargin] = useState("");
  const [merVal, setMerVal] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [profitInsight, setProfitInsight] = useState("");
  const [shortterm, setShortTerm] = useState("");
  const [wellbalanced, setWellbalanced] = useState("");
  const [longterm, setLongTerm] = useState("");
  const [adSpendVal, setAdSpendVal] = useState("");
  const [netNewVal, setNetNewVal] = useState("");
  const [handleCpaVal, setHandleCpaVal] = useState("");
  const [adSpend, setAdSpend] = useState("");
  const [hasGoalParams, SetGoalParams] = useState(false);
  const [cpaInput, setCpaInput] = useState("");
  const [originalFormattedValue, setOriginalFormattedValue] = useState("");

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getTableSliderData(formData, props));
  }, []);

  useEffect(() => {
    setCpaVal(value);
    setCpaInput(value);
  }, [value]);

  useEffect(() => {
    setAdSpend(ad_spend);
  }, [ad_spend]);

  useEffect(() => {
    if (
      localStorage.getItem("goal_params") != undefined &&
      localStorage.getItem("goal_params") == "false"
    ) {
      setCalculatedNetVal("");
      setCalculatedAdSpendVal("");
      setAdSpendVal("");
      setProfitMargin("");
      setMerVal("");
      setNetNewVal("");
    }
  }, [localStorage.getItem("goal_params")]);

  const profit_margin_change = (value) => {
    setProfitMargin(value);
    if (value < 10) {
      setShortTerm("5%");
      setWellbalanced("6%");
      setLongTerm("7%");
    } else if (value >= 10 && value < 20) {
      setShortTerm("10%");
      setWellbalanced("12%");
      setLongTerm("14%");
    } else if (value >= 20 && value < 30) {
      setShortTerm("15%");
      setWellbalanced("18%");
      setLongTerm("21%");
    } else if (value >= 30 && value < 40) {
      setShortTerm("20%");
      setWellbalanced("24%");
      setLongTerm("28%");
    } else if (value >= 40 && value < 50) {
      setShortTerm("25%");
      setWellbalanced("30%");
      setLongTerm("35%");
    } else if (value >= 50 && value < 60) {
      setShortTerm("30%");
      setWellbalanced("36%");
      setLongTerm("42%");
    } else if (value >= 60 && value < 70) {
      setShortTerm("35%");
      setWellbalanced("42%");
      setLongTerm("49%");
    } else if (value >= 70 && value < 80) {
      setShortTerm("40%");
      setWellbalanced("48%");
      setLongTerm("56%");
    } else if (value >= 80) {
      setShortTerm("45%");
      setWellbalanced("54%");
      setLongTerm("63%");
    }
  };

  var insight_text =
    "Suggested MER goals based on your profit margin and growth target: Short Term=" +
    shortterm +
    ", Well Balanced=" +
    wellbalanced +
    ", Long Term=" +
    longterm;

  const calculateVal = (value) => {
    if (value == "" || value == null) {
      setNetNewVal("");
      setCalculatedAdSpendVal("");
      setCalculatedNetVal("");
      setAdSpendVal("");
    } else {
      setNetNewVal(parseFloat(value).toFixed(0));
      setCalculatedNetVal(parseFloat(value).toFixed(0));
      setAdSpendVal(parseFloat(value * cpaVal).toFixed(2));
      setCalculatedAdSpendVal(
        parseFloat(value * cpaVal).toFixed(2)
      );
    }
  };

  const calculateNetVal = (value) => {
    if (value == "" || value == null) {
      setNetNewVal("");
      setCalculatedAdSpendVal("");
      setAdSpendVal("");
      setCalculatedNetVal("");
    } else {
      setAdSpendVal(value);
      setCalculatedAdSpendVal(parseFloat(value).toFixed(2));
      setCalculatedNetVal(parseFloat(value / cpaVal).toFixed(0));
    }
  };

  const cpa_val_change = (cpa_change) => {
    SetGoalParams(false);
    if (cpa_change == null || cpa_change == "") {
      setHandleCpaVal(null);
      setCalculatedAdSpendVal("");
      setAdSpendVal("");
      setCalculatedNetVal("");
      setNetNewVal("");
    } else {
      setCpaVal(parseFloat(cpa_change));
      setCpaInput(cpa_change);
      setHandleCpaVal(parseFloat(cpa_change));
    }
    if (netNewVal == null || netNewVal == "") {
      setCalculatedAdSpendVal("");
      setAdSpendVal("");
      setCalculatedNetVal("");
    } else if (cpa_change == null || cpa_change == "") {
      setCalculatedAdSpendVal("");
      setCalculatedNetVal("");
      setAdSpendVal("");
    } else {
      setAdSpendVal(parseFloat(netNewVal * cpa_change).toFixed(2));
      setCalculatedAdSpendVal(parseFloat(netNewVal * cpa_change).toFixed(2));
    }
  };
  const mer_goal_change = (value) => {
    setMerVal(value);
  };

  const indexes = ["CLV", "Gross Revenue", "Target CPA"];
  const splitIndex = ["Ideal CPA Range"];
  const grossRevenue = ["Gross Revenue"];

  var cols = [
    {
      dataIndex: "index",
      title: "",
      key: "index",
    },
    {
      dataIndex: "month0",
      title: "Month 0",
      key: "month0",
      width: "30%",
      render: (text, record) => {
        if (grossRevenue.includes(record.index)) {
          if (text < 0) {
            return `-$${parseFloat(Math.abs(text)).toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}`;
          } else {
            return `$${parseFloat(text).toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}`;
          }
        } else if (indexes.includes(record.index)) {
          return `$${numberWithCommas(parseFloat(text).toFixed(2))}`;
        } else if (
          splitIndex.includes(record.index) &&
          String(text).startsWith(">")
        ) {
          return `>$${numberWithCommas(parseFloat(text.slice(1)).toFixed(2))}`;
        } else if (
          splitIndex.includes(record.index) &&
          String(text).startsWith("<")
        ) {
          return `< $${numberWithCommas(parseFloat(text.slice(1)).toFixed(2))}`;
        } else if (splitIndex.includes(record.index)) {
          const range = text.split("-");
          return `$${numberWithCommas(
            parseFloat(range[0]).toFixed(2)
          )}  - $${numberWithCommas(parseFloat(range[1]).toFixed(2))}`;
        } else {
          return text;
        }
      },
    },
    {
      dataIndex: "month6",
      title: "Month 6",
      key: "month6",
      width: "30%",
      render: (text, record) => {
        if (grossRevenue.includes(record.index)) {
          if (text < 0) {
            return `-$${parseFloat(Math.abs(text)).toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}`;
          } else {
            return `$${parseFloat(text).toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}`;
          }
        } else if (indexes.includes(record.index)) {
          return `$${numberWithCommas(parseFloat(text).toFixed(2))}`;
        } else if (
          splitIndex.includes(record.index) &&
          String(text).startsWith(">")
        ) {
          return `>$${numberWithCommas(parseFloat(text.slice(1)).toFixed(2))}`;
        } else if (
          splitIndex.includes(record.index) &&
          String(text).startsWith("<")
        ) {
          return `< $${numberWithCommas(parseFloat(text.slice(1)).toFixed(2))}`;
        } else if (splitIndex.includes(record.index)) {
          const range = text.split("-");
          return `$${numberWithCommas(
            parseFloat(range[0]).toFixed(2)
          )}  - $${numberWithCommas(parseFloat(range[1]).toFixed(2))}`;
        } else {
          return text;
        }
      },
    },
    {
      dataIndex: "month12",
      title: "Month 12",
      key: "month12",
      width: "30%",
      render: (text, record) => {
        if (grossRevenue.includes(record.index)) {
          if (text < 0) {
            return `-$${parseFloat(Math.abs(text)).toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}`;
          } else {
            return `$${parseFloat(text).toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}`;
          }
        } else if (indexes.includes(record.index)) {
          return `$${numberWithCommas(parseFloat(text).toFixed(2))}`;
        } else if (String(text).startsWith(">")) {
          return `>$${numberWithCommas(parseFloat(text.slice(1)).toFixed(2))}`;
        } else if (String(text).startsWith("<")) {
          return `< $${numberWithCommas(parseFloat(text.slice(1)).toFixed(2))}`;
        } else if (splitIndex.includes(record.index)) {
          const range = text.split("-");
          return `$${numberWithCommas(
            parseFloat(range[0]).toFixed(2)
          )}  - $${numberWithCommas(parseFloat(range[1]).toFixed(2))}`;
        } else {
          return text;
        }
      },
    },
  ];

  useEffect(() => {
    setDataSource(tableData);
  }, [tableData.length > 0]);

  const on_apply = () => {
    var updated_val = {
      cpa: parseFloat(cpaVal).toFixed(2),
      net_new_customers: calculatedNetVal,
      ad_spend: calculatedAdSpendVal,
      profit_margin: profitMarginVal,
      mer_goal: merVal,
    };
    SetGoalParams(true);
    localStorage.setItem("goal_params", true);
    var data = props?.all_input_data;
    data["goal_setting_params"] = JSON.stringify(updated_val);
    var formData = formDataAppend(data);
    dispatch(getTableSliderData(formData, props));
  };

  const on_reset = () => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    setCalculatedAdSpendVal("");
    setCalculatedNetVal("");
    setProfitMargin("");
    setMerVal("");
    setNetNewVal("");
    SetGoalParams(false);
    setAdSpendVal("");
    localStorage.setItem("goal_params", false);
    dispatch(getTableSliderData(formData, props));
  };

  const customLocale = {
    emptyText: "Please complete steps 1-4 and click Apply",
  };

  if (insights != undefined && insights.length > 0) {
    var listItems = insights.map((insight) => <li>{HtmlParser(insight)}</li>);
  }

  const handlePressEnter = () => {
    if (handleCpaVal == null) {
      setCpaVal(value);
      setCpaInput(numberWithCommas(parseFloat(value).toFixed(2)));
    } else {
      setCpaInput(numberWithCommas(parseFloat(cpaInput).toFixed(2)));
    }
  };

  const handleBlur = (e) => {
    if (handleCpaVal == null) {
      setCpaVal(cpaVal);
      setCpaInput(numberWithCommas(parseFloat(cpaVal).toFixed(2)));
    } else {
      if (cpaInput.toString().includes(",")) {
        setCpaInput(cpaInput);
      } else {
        setCpaInput(
          parseFloat(cpaInput)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      }
    }
  };

  const handleAdSpendBlur = (e) => {
    if (calculatedAdSpendVal == "" || calculatedAdSpendVal == null) {
      //
    } else {
      if (adSpendVal.toString().includes(",")) {
        setCalculatedNetVal("");
        setCalculatedAdSpendVal("");
        setAdSpendVal("");
        setNetNewVal("");
      } else {
        setAdSpendVal(
          parseFloat(adSpendVal)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      }
    }
  };

  const parseInput = (value) => {
    if (value == "" && hasGoalParams == false) {
      setCalculatedNetVal("");
      setNetNewVal("");
      setCalculatedAdSpendVal("");
      setAdSpendVal("");
    } else {
      return value.replace(/\$\s?|(,*)/g, "");
    }
  };

  const handleFocus = () => {
    // Store the original formatted value when the input is focused
    setOriginalFormattedValue(cpaInput);
  };

  return (
    <>
      <Row className="goal-setting-single">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <h2>
            Step 1: Edit your Current CPA to include non-digital advertising
            channels, if necessary
          </h2>
        </Col>
        <Col className="gss-caption-wrapper" lg={{ span: 8 }} xs={{ span: 24 }}>
          <div className="gss-caption">
            <span>CPA</span>
          </div>
        </Col>
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <div className="gss-input">
            <InputNumber
              min={0}
              max={999999999}
              precision={2}
              value={cpaInput}
              // formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={parseInput}
              onChange={cpa_val_change}
              controls={false}
              // onPressEnter={handlePressEnter}
              onBlur={handleBlur}
              style={{ minWidth: "150px", padding: "0 10px" }}
              prefix="$"
            />
          </div>
        </Col>
      </Row>
      <Row className="goal-setting-single">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <h2>Step 2: Enter your monthly Net New Customers or Ad Spend goal</h2>
        </Col>
        <Col className="gss-caption-wrapper" lg={{ span: 8 }} xs={{ span: 24 }}>
          <div className="gss-caption">
            <span>Net New Customers</span>
          </div>
        </Col>
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <div className="gss-input">
            <InputNumber
              min={0}
              max={999999999}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              precision={0}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={calculatedNetVal}
              controls={false}
              style={{ minWidth: "150px", padding: "0 10px" }}
              onChange={calculateVal}
            />
          </div>
        </Col>
      </Row>
      <Row className="goal-setting-single">
        <Col className="gss-caption-wrapper" lg={{ span: 8 }} xs={{ span: 24 }}>
          <div className="gss-caption">
            <span>Ad Spend (non-Remarketing)</span>
          </div>
        </Col>
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <div className="gss-input">
            <InputNumber
              min={0}
              max={999999999}
              value={adSpendVal}
              precision={2}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              controls={false}
              onBlur={handleAdSpendBlur}
              style={{ minWidth: "150px", padding: "0 10px" }}
              onChange={calculateNetVal}
              prefix="$"
            />
          </div>
        </Col>
      </Row>
      <Row className="goal-setting-single gss-percent">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <h2>Step 3: Enter your Profit Margin</h2>
        </Col>
        <Col className="gss-caption-wrapper" lg={{ span: 8 }} xs={{ span: 24 }}>
          <div className="gss-caption">
            <span>Profit Margin</span>
          </div>
        </Col>
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <div className="gss-input">
            <InputNumber
              min={0}
              max={100}
              precision={2}
              value={profitMarginVal}
              controls={false}
              onChange={profit_margin_change}
              style={{ minWidth: "150px", padding: "0 10px" }}
              prefix="%"
            />
          </div>
        </Col>
      </Row>
      <Row className="goal-setting-single gss-percent">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <h2>Step 4: Enter your MER Target Goal</h2>
          <p>{wellbalanced != "" ? insight_text : ""}</p>
        </Col>
        <Col className="gss-caption-wrapper" lg={{ span: 8 }} xs={{ span: 24 }}>
          <div className="gss-caption">
            <span>MER Goal</span>
          </div>
        </Col>
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <div className="gss-input">
            <InputNumber
              min={0}
              max={999999999}
              precision={2}
              value={merVal}
              controls={false}
              onChange={mer_goal_change}
              style={{ minWidth: "150px", padding: "0 10px" }}
              prefix="%"
            />
          </div>
        </Col>
      </Row>
      <Row className="gss-action-btns">
        <Col span={24}>
          <Button htmlType="button" onClick={on_apply}>
            Apply
          </Button>
          <Button type="secondary" onClick={on_reset} htmlType="button">
            Reset
          </Button>
        </Col>
      </Row>
      {/* <Row className="goal-setting-table"> */}
      <Table
        id="ntable"
        className="gst-table"
        bordered
        loading={isLoading}
        dataSource={dataSource}
        columns={cols}
        locale={customLocale}
      />
      <div className="report-v2-panel-footer">
        {insights != undefined && insights.length > 0 ? (
          <div className="insights">
            <h4>Insights</h4>
            <ul>{listItems}</ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CLVGoal;
