import axios from "axios";
import { amazonProfileApi } from "../../../Api/datasources/amazon/amazonProfile";
import { amazonRegion } from "../../../Config/datasources/amazon/region";
import { amazonSponsorAdsConfig } from "../../../Config/datasources/amazon/sponsorAds";
const amazonSponsorAdsApiCall = async (props) => {
  const { region, queryCode } = props;

  // *Get url from amazon according to region
  let url = await amazonSponsorAdsConfig.amazonTokenAccess(
    amazonRegion[`${region}`],
    queryCode
  );


  // *Get tokens from amazon
  const { data: tokens } = await axios.post(url);

  // *Get user profile from amazon
  const { data } = await amazonProfileApi({
    client_id: amazonSponsorAdsConfig.amazonClientId,
    access_token: tokens.access_token,
  });

  let profile;
  if (Array.isArray(data.data.data)) {
    profile = data.data.data;
  }

  return {
    tokens,
    profile,
    // userProfile,
  };
};

export default amazonSponsorAdsApiCall;
