import { Button, Modal, Form, Input, Select, Upload, notification, Spin, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { BiMessageDetail } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { saveFeedback } from "../../Redux/Actions/Thunk/userThunk";
import Config from "../../Config";

const { Option } = Select;
const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 5,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 15,
		},
	}
};

const Feedback = () => {
	const dispatch= useDispatch();
	const [visible, setVisible] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [form] = Form.useForm();
	const formData = new FormData();
	const [loading, setLoading] = useState(false);
	const [filterData, setFilterData] = useState("");

	/*const {
		reportLayouts: {
		  filtered_data: filtered_data = {}
		} = {},
	} = useSelector((state) => state);*/

	const updateFilterData = () =>{
		let storage_data = localStorage.getItem("report_filter");
		storage_data = JSON.parse(storage_data);
		delete storage_data.Client;
		delete storage_data.account_id;
		delete storage_data.ad_spend_status;
		delete storage_data.additional_persist_label;
		delete storage_data.app_date;
		delete storage_data.authentication;
		delete storage_data.c_f_date;
		delete storage_data.c_f_month;
		delete storage_data.c_t_date;
		delete storage_data.c_t_month;
		delete storage_data.compare_type;
		delete storage_data.dateFormat;
		delete storage_data.component_type;
		delete storage_data.date_month_compareto;
		delete storage_data.date_month_option;
		delete storage_data.date_range_compareto;
		delete storage_data.date_range_option;
		delete storage_data.date_range_type;
		delete storage_data.f_date;
		delete storage_data.f_month;
		delete storage_data.from_created_date;
		delete storage_data.from_month;
		delete storage_data.from_month_compare;
		delete storage_data.include_amazon_data;
		delete storage_data.month;
		delete storage_data.month_range_option;
		delete storage_data.offset;
		delete storage_data.persist_label;
		delete storage_data.service_id;
		delete storage_data.t_date;
		delete storage_data.t_month;
		delete storage_data.to_created_date;
		delete storage_data.to_month;
		delete storage_data.to_month_compare;
		delete storage_data.undefined;
		delete storage_data.compare_prev_year_30days;
		delete storage_data.dateRange;
		delete storage_data.dateSRange;
		delete storage_data.is_30days;
		delete storage_data.month_range_compareto;
		delete storage_data.selectedAdditionalVal;
		delete storage_data.selectedVal;
		let filter_string = ""
		for(const key in storage_data){
			if(storage_data[key]=="-1"){
				storage_data[key] = "All";
			}
			filter_string+= key.replaceAll("_"," ").toUpperCase() + " : " + storage_data[key]+"<br />";
		}
		setFilterData(filter_string);
		//console.log("FD",filtered_data);
	}

	const {
		API_BI: { baseURL },
	} = Config;

	const showModal = () => {
		updateFilterData();
		setVisible(true);
	};

	const exceptions_props = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onChange(info){
			formData.append("file", info.fileList[0].originFileObj);
		},
		beforeUpload(data_file) {
			let isValidType = false;
			if(data_file.type === 'image/jpg' || data_file.type === 'image/jpeg' || data_file.type === 'image/png' || data_file.type === 'application/pdf'){
				isValidType = true;
			}
			if (!isValidType) {
				form.setFields([
					{
						name: "exceptions_file",
						errors: ["Invalid file type! File type must be jpg / png / pdf."],
					},
				]);
			}

			const isLt2M = data_file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				form.setFields([
					{
						name: "exceptions_file",
						errors: ["File size must be smaller than 2MB!"],
					},
				]);
			}

			if (isValidType && isLt2M) {
				form.setFields([
					{
						name: "exceptions_file",
						errors: null,
					},
				]);
				return false;
			}

			//return (isValidType && isLt2M) || Upload.LIST_IGNORE;
			return false;
		},
	};
	// const context = useContext(appContext);

	// useEffect(() => {
	// 	console.log("PS Context", context.value);
	// },[]);
	
	const handleOk = () => {
		setConfirmLoading(true);
		setTimeout(() => {
			setVisible(false);
			setConfirmLoading(false);
		}, 2000);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}

		return e?.fileList;
	};

	/*const onFinish1 = (form_data) => {
		setConfirmLoading(true);
		form_data.full_url = window.location.href;
		dispatch(saveFeedback(JSON.stringify(form_data)));
		setTimeout(() => {
			// setVisible(false);
			setConfirmLoading(false);
		}, 4000);
	}*/

	const onFinish = async (form_data) => {
		setLoading(true);
		const { image, ...otherValues } = form_data;
		formData.delete('subject');
		formData.append('subject', form_data.subject);
		formData.delete('issue');
		formData.append('issue', form_data.issue);
		formData.delete('message');
		formData.append('message', form_data.message);
		formData.delete('full_url');
		formData.append('full_url', window.location.href);
		formData.delete('report_filtered_data');
		formData.append('report_filtered_data', form_data.report_filtered_data);
		let uploadUrl = baseURL+"api/v1/save-feedback";
		const token = localStorage.getItem("token");
		
		// const response = await fetch('https://www.reddit.com/r/all/top.json?limit=10');
		// const data = await response.json();
		// const posts = data.data.children.map(child => child.data);
		// console.log(posts.map(post => post.title));

		const feedback_response = await fetch(uploadUrl, {
		  method: 'POST',
		  body: formData,
		  headers:{
			"Authorization":token
		  }
		})
		let data = await feedback_response.json();
		const response = {};
		response.data = data;
		if(response?.data?.status=="success"){
			notification["success"]({
				message: "Success",
				description: "Feedback sent successfully."
			});
			setVisible(false);
			form.resetFields();
			setLoading(false);
		}else if(response?.data?.status=="error"){
			let validation_message = "";
			let validation = response?.data?.validation;
			Object.keys(validation).forEach(function(key) {
				validation_message+=validation[key];
			})
			notification["error"]({
				message: "Validation Error",
				description: (<>
				Please fix following:<br />{validation_message}
				</>)
			});
			setTimeout(() => {
				setConfirmLoading(false);
			}, 1000);
			setLoading(false);
		}
	}
	const onFinishFailed = (error) => {
		setLoading(false);
	}

	return (
		<>
			<Tooltip placement="bottom" title="Give Us Feedback">
				<Button onClick={showModal}>
					<BiMessageDetail />
				</Button>
			</Tooltip>
			<Modal
				title="Give Us Feedback"
				visible={visible}
				okButtonProps={{ style: { display: 'none' } }}
				onOk={handleOk}
				okText="Submit"
				confirmLoading={confirmLoading}
				onCancel={handleCancel}
				width={700}
				cancelButtonProps={{ style: { display: 'none' } }}
				className="feedback-modal"
			>
				<Spin spinning={loading} size="large">
				<Form {...formItemLayout} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" form={form} initialValues={{ 'report_filtered_data': filterData }}>
					<Form.Item name="subject" label="Subject" rules={[{ required: true, message: 'Please enter subject.' }]}>
						<Input placeholder="Subject" id="feedback_subject" />
					</Form.Item>
					<Form.Item name="issue" label="Issue" rules={[{ required: true, message: 'Please select issue type.' }]}>
						<Select placeholder="Please select" allowClear>
							<Option value="Incorrect data">Incorrect data</Option>
							<Option value="Report display issue">Report display issue</Option>
							<Option value="Bug">Bug</Option>
							<Option value="Alert/Notification">Alert/Notification</Option>
							<Option value="Suggestion">Suggestion</Option>
							<Option value="Other">Other</Option>
						</Select>
					</Form.Item>
					<Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please enter message.' }]}>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						name="upload"
						label="Upload"
						valuePropName="fileList"
						getValueFromEvent={normFile}
					>
						<Upload {...exceptions_props} maxCount={1} name="image" listType="picture">
							<Button icon={<UploadOutlined />}>Click to upload</Button>
						</Upload>
					</Form.Item>
					<Form.Item name="report_filtered_data" label="Filtered Data" style={{'display':'none'}}>
						<Input placeholder="" id="report_filtered_data" />
					</Form.Item>
					<Form.Item label="" wrapperCol={{offset:5, span:15}}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
					</Form.Item>
				</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default Feedback;
