import { Table } from 'antd';
import isEqual from "lodash/isEqual";
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react'

import { FormattedNumberCell } from '../../../Utils/helpers/formatting';

const DataTableSummary = ({config, columnData, blockId}) => {
  const [totalRow, setTotalRow] = useState({});

  const checkBoxRowSelection = config["selectRowSelection"];

  if (config.hasOwnProperty("show_total_only")) {
    var show_total_only = config["show_total_only"];
  }

  const {
    dataTable: {
      table: {
        [blockId]: {
          [blockId]: {
            total_all: totalAll = [],
            total_all_data: totalFilteredData = []
          } = {},
          // isLoading,
        } = {},
      } = {},
    } = {}
  } = useSelector((state) => state);

  useEffect(() => {
    const totalData = { ...totalFilteredData };

    const newObj = {};
    Object.keys(totalData).forEach((data) => {
      newObj[data] = totalData[data];
    });

    if (!isEqual(newObj, totalRow)) {
      setTotalRow(newObj);
    }
  }, [totalFilteredData, totalRow]);
  
  return (
    <>
      {totalFilteredData &&
      Object.keys(totalFilteredData).length > 1 ? (
        <> 
          {show_total_only ? (
            <Table.Summary.Row>
              <>
                <Table.Summary.Cell
                    key={Math.random()}
                  ></Table.Summary.Cell>
              </>
              {columnData.map((column, columnIndex) => {
                const isFirstColumn = columnIndex === 0;
                if (column?.children) {
                  // Handle grouped columns
                  return (
                    <React.Fragment key={columnIndex}>
                      {isFirstColumn ? (
                        <Table.Summary.Cell index={columnIndex}>
                          TOTAL
                        </Table.Summary.Cell>
                      ) : (
                        column.children.map(
                          (childColumn, childIndex) => (
                            <Table.Summary.Cell key={childIndex}>
                              <FormattedNumberCell
                                value={
                                  totalRow[childColumn.dataIndex]
                                }
                                format={childColumn.format}
                                metric={childColumn.dataIndex}
                              />
                            </Table.Summary.Cell>
                          )
                        )
                      )}
                    </React.Fragment>
                  );
                } else {
                  return (
                    <>
                      <Table.Summary.Cell key={columnIndex}>
                        {isFirstColumn ? (
                          "TOTAL"
                        ) : (
                          <FormattedNumberCell
                            value={totalRow[column.dataIndex]}
                            format={column.format}
                            metric={column.dataIndex}
                          />
                        )}
                      </Table.Summary.Cell>
                    </>
                  );
                }
              })}
            </Table.Summary.Row>
          ) : (
            <>
              <Table.Summary.Row>
                {checkBoxRowSelection === "true" ? (
                  <Table.Summary.Cell
                    key={Math.random()}
                  ></Table.Summary.Cell>
                ) : (
                  ""
                )}
                {columnData.map((column, columnIndex) => {
                  const isFirstColumn = columnIndex === 0;
                  if (column?.children) {
                    // Handle grouped columns
                    return (
                      <React.Fragment key={columnIndex}>
                        {isFirstColumn ? (
                          <Table.Summary.Cell index={columnIndex}>
                            TOTAL ALL
                          </Table.Summary.Cell>
                        ) : (
                          column.children.map(
                            (childColumn, childIndex) => (
                              <Table.Summary.Cell key={childIndex}>
                                <FormattedNumberCell
                                  value={
                                    totalAll[childColumn.dataIndex]
                                  }
                                  format={childColumn.format}
                                  metric={childColumn.dataIndex}
                                />
                              </Table.Summary.Cell>
                            )
                          )
                        )}
                      </React.Fragment>
                    );
                  } else {
                    // Handle individual columns
                    return (
                      <>
                        <Table.Summary.Cell
                          key={columnIndex}
                          className={column.dataIndex + "total_all"}
                        >
                          {isFirstColumn ? (
                            "TOTAL ALL"
                          ) : (
                            <FormattedNumberCell
                              value={totalAll[column.dataIndex]}
                              format={column.format}
                              metric={column.dataIndex}
                            />
                          )}
                        </Table.Summary.Cell>
                      </>
                    );
                  }
                })}
              </Table.Summary.Row>
              <Table.Summary.Row>
                <>
                  {checkBoxRowSelection === "true" ? (
                    <Table.Summary.Cell
                      key={Math.random()}
                    ></Table.Summary.Cell>
                  ) : (
                    ""
                  )}
                </>
                {columnData.map((column, columnIndex) => {
                  const isFirstColumn = columnIndex === 0;
                  if (column?.children) {
                    // Handle grouped columns
                    return (
                      <React.Fragment key={columnIndex}>
                        {isFirstColumn ? (
                          <Table.Summary.Cell
                            index={columnIndex}
                            className={
                              column.dataIndex +
                              columnIndex +
                              "total_filter"
                            }
                          >
                            TOTAL FILTERED
                          </Table.Summary.Cell>
                        ) : (
                          column.children.map(
                            (childColumn, childIndex) => (
                              <Table.Summary.Cell key={childIndex}>
                                <FormattedNumberCell
                                  value={
                                    totalRow[childColumn.dataIndex]
                                  }
                                  format={childColumn.format}
                                  metric={childColumn.dataIndex}
                                />
                              </Table.Summary.Cell>
                            )
                          )
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <>
                        <Table.Summary.Cell
                          key={columnIndex}
                          className={
                            column.dataIndex +
                            columnIndex +
                            "total_filter"
                          }
                        >
                          {isFirstColumn ? (
                            "TOTAL FILTERED"
                          ) : (
                            <FormattedNumberCell
                              value={totalRow[column.dataIndex]}
                              format={column.format}
                              metric={column.dataIndex}
                            />
                          )}
                        </Table.Summary.Cell>
                      </>
                    );
                  }
                })}
              </Table.Summary.Row>
            </>
          )}
        </>
      ) : null}
    </>
  )
}

export default DataTableSummary;
