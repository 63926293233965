import { useEffect, useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Input, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteGoogleConsoleDataSourcesThunk } from "../../../Redux/Actions/Thunk/datasources/ds_configs/GoogleConsoleThunk";

const GoogleConsoleTable = () => {
  const [datas, setDatas] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const dispatch = useDispatch();
  const {
    account_mccs: { auth_params = [] },
  } = useSelector((state) => state.googleConsoleMcc);

  const handleVisibleModal = () => {
    dispatch(
      deleteGoogleConsoleDataSourcesThunk({
        id: currentId,
      })
    );
    setVisibleModal(!visibleModal);
  };

  const handleOnClickOkay = (data) => {
    setVisibleModal(true);
    setCurrentId(data.id);
  };

  useEffect(() => {
    setDatas(auth_params);
  }, [auth_params.length]);

  const columns = [
    {
      title: "Account",
      dataIndex: "account",
      // ellipsis: false,
      key: "account",
      render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      ellipsis: false,
      render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
    },
    {
      title: "Updated At",
      render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,

      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div
            style={{ display: "flex", gap: "5px", justifyContent: "center" }}
          >
            <Link
              to={`/data-source/google-console-configuration/edit/${record.id}`}
            >
              <EditOutlined
                style={{
                  height: "20px",
                  width: "20px",
                  backgroundColor: "rgb(141,195,64)",
                  borderRadius: "50%",
                  color: "white",
                  textAlign: "center",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              />
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Do you want to delete this account?"
        visible={visibleModal}
        onOk={handleVisibleModal}
        onCancel={() => setVisibleModal(false)}
        okText="Yes"
        cancelText="No"
      ></Modal>

      <Input
        placeholder="User"
        onChange={(e) => {
          const filteredData = auth_params.filter((item) => {
            return item.user.toLowerCase().includes(e.target.value.toLowerCase());
          });
          setDatas(filteredData);
        }}
      />
      <Table columns={columns} dataSource={datas} />
    </>
  );
};

export default GoogleConsoleTable;
