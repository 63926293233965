import React from "react";

const CustomColumnFilter = ({
  column,
  changeColumnVisibility,
  absoluteChangeColumnVisibility,
  metricsChangeColumnVisibility,
  modalChangeColumnVisibility,
  config,
  columnsTabsToHide,
  columnShowHideModal,
}) => {
  const hasChangeColumns = config["change_columns"] !== undefined;
  const hasAbsoluteChangeColumns =
    config["absolute_change_columns"] !== undefined;
  const hasMetricsChangeColumns =
    config["organic_metrics_columns"] !== undefined;
  const hasModalColumnChanges = columnShowHideModal;

  const isChangeColumnHidden =
    hasChangeColumns &&
    config["change_columns"].includes(column.key) &&
    !changeColumnVisibility[column.key];

  const isAbsoluteChangeColumnHidden =
    hasAbsoluteChangeColumns &&
    config["absolute_change_columns"].includes(column.key) &&
    !absoluteChangeColumnVisibility[column.key];

  const isMetricsChangeColumnHidden =
    hasMetricsChangeColumns &&
    config["organic_metrics_columns"].includes(column.key) &&
    !metricsChangeColumnVisibility[column.key];

  const isTabChangeColumnHidden = columnsTabsToHide?.includes(column.key);
  const isModalChangeColumnHidden =
    hasModalColumnChanges &&
    columnShowHideModal.includes(column.key) &&
    !modalChangeColumnVisibility[column.key];

  const isVisibleByDefault =
    !isChangeColumnHidden &&
    !isAbsoluteChangeColumnHidden &&
    !isMetricsChangeColumnHidden &&
    !isTabChangeColumnHidden &&
    !isModalChangeColumnHidden;

  return isVisibleByDefault;
};

export default CustomColumnFilter;
