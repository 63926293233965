export const initialState = {
    defaults: []
};

export const ACTIONS_TYPES = Object.freeze({
    SET_DEFAULTS: "SET_DEFAULTS",
});

export function inputControlReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS_TYPES.SET_DEFAULTS:
            return {
                ...state,
                defaults: { ...state.defaults, ...action.payload },
            }
        default:
            return state;
    }
}
