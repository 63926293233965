export const initialState = {
  sliderData: {},
  isLoading: false,
  htmlData: {},
};

export const ACTIONS_TYPES = Object.freeze({
  GET_SLIDER_DATA: "GET_SLIDER_DATA",
  FETCH_SLIDER_DATA: "FETCH_SLIDER_DATA",
  GET_HTML: "GET_HTML",
  FETCH_HTML: "FETCH_HTML",
  RESET_SLIDER_DATA: "RESET_SLIDER_DATA",
});

export function accountReportReducer(state = initialState, action) {
  const { key, payload } = action;
  switch (action.type) {
    case ACTIONS_TYPES.GET_SLIDER_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_SLIDER_DATA:
      return {
        ...state,
        isLoading: false,
        sliderData: { ...state.sliderData, ...action.payload },
      };
    case ACTIONS_TYPES.GET_HTML:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_HTML:
      return {
        ...state,
        isLoading: false,
        htmlData: { ...state.htmlData, ...action.payload },
      };
    case ACTIONS_TYPES.RESET_SLIDER_DATA:
      return {
        ...state,
        sliderData: {
          ...state.sliderData,
          [payload]: {},
        },
      };
    default:
      return state;
  }
}
