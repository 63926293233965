import { Select, Form } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { formDataAppend, titleCase } from "../../Utils/helpers";
import { Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setDefaults } from "../../Redux/Actions/inputControlAction";

const { Option } = Select;

const onChange = (value) => {};

const onSearch = (value) => {};
const SelectStatic = (props) => {
  const location = useLocation();
  const [allData] = useState([props?.block?.options][0]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params_data = Object.fromEntries(urlSearchParams.entries());
  const [selectedValues, setSelectedValues] = useState(
    props.form.getFieldValue(props.block.id) || []
  );
  const {
    inputcontrols: { defaults: defaults = [] },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    let defaultValue = props?.block?.default;
    if (urlSearchParams.get(props?.block?.id) != null) {
      defaultValue = params_data[props?.block?.id];
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    } else {
      dispatch(setDefaults({ [props?.block?.id]: defaultValue }));
    }
  }, [window.location.search]);

  useEffect(() => {
    if (defaults.hasOwnProperty(props?.block?.id)) {
      props.form.setFieldsValue({
        [props?.block?.id]: defaults[props?.block?.id],
      });
    }
  }, [defaults]);

  const allFields = ["-1", "All"];
  const handleSelect = (items) => {
    if (items.length === 0 && props?.block?.default === "-1") {
      items = ["-1"];
      setSelectedValues("-1");
    }
    if (items.length === 0 && props?.block?.default === "All") {
      items = ["All"];
      setSelectedValues("All");
    }
    const latestValue = items[items.length - 1];

    if (allFields.includes(latestValue)) {
      items = [latestValue];
    } else {
      const findIndexMinusOne = items.indexOf("-1");
      if (findIndexMinusOne > -1) {
        items.splice(findIndexMinusOne, 1);
      }

      const findIndexAll = items.indexOf("All");
      if (findIndexAll > -1) {
        items.splice(findIndexAll, 1);
      }
    }

    if (Array.isArray(items)) {
      setSelectedValues(items);
      props.form.setFieldsValue({ [props.block.id]: items });
    } else {
      setSelectedValues([]);
      props.form.setFieldsValue({ [props.block.id]: [] });
    }
  };

  const sort = props?.block?.hasOwnProperty("sort") ? "false" : "true";

  return (
    <>
      <div className="Report-V2-filter-box">
        <div className="Report-V2-filter-option-title">
          <span>{props?.block?.label}</span>
        </div>
        <Form.Item name={props?.block.id}>
          <Select
            dropdownClassName="input-dropdown-menu"
            key={props?.block?.label}
            name={props?.block?.label}
            dropdownStyle={{
              width: "5%",
            }}
            mode={props?.block?.mode_type}
            showSearch
            maxTagCount={1}
            value={selectedValues}
            placeholder="Select"
            optionFilterProp="children"
            onChange={
              props?.block?.mode_type == "multiple" ? handleSelect : onChange
            }
            // onChange={onChange}
            // onSearch={onSearchform}
            //listHeight={128}
            // open={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {sort != "false"
              ? Object.entries(allData)
                  .map((data, i) => <Option key={data[0]}>{data[1]}</Option>)
                  .sort((a, b) => {
                    if (a.key === "-1" || a.key == "All") {
                      return -1;
                    }
                    if (a.props.children.toLowerCase().includes("other")) {
                      return 10000;
                    }
                    return a.props.children.localeCompare(b.props.children);
                  })
              : Object.entries(allData).map((data, i) => (
                  <Option key={data[0]}>{data[1]}</Option>
                ))}
          </Select>
        </Form.Item>
      </div>
    </>
  );
};

export default SelectStatic;
