import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import { amazonOrganicRankingReducer } from "../Reducers/datasources/amazonOrganicRankingReducer";
import { ColumnChartReducer } from "./ColumnChartReducer";
import { InternalReportReducer } from "./InternalReportReducer";
import { LineChartReducer } from "./LineChartReducer";
import { accountDataSourcesReducer } from "./accountDataSourcesReducer";
import { accountReducer } from "./accountReducer";
import { accountReportReducer } from "./accountReportReducer";
import { barGraphReducer } from "./barGraphReducer";
import { dataTableReducer } from "./dataTableReducer";
import { accountDataSourcesCredentialsReducer } from "./datasources/DataSourceCredentails";
import { dataSourceModelReducer } from "./datasources/DataSourceModelReducer";
import { googleAdsReducer } from "./datasources/GoogleAdsReducer";
import { googleAnalyticsReducer } from "./datasources/GoogleAnalyticsReducer";
import { amazonFullfillmentTypeReducer } from "./datasources/amazonFullFillmentTypeReducer";
import { amazonMarketingReducer } from "./datasources/amazonMarketingServiceReducer";
import { amazonOrganicRatingReducer } from "./datasources/amazonOrganicRatingReducer";
import { amazonSellingPartnerReducer } from "./datasources/amazonSellingPartnerReducer";
import { amazonSponsorAdsReducer } from "./datasources/amazonSponserAdsReducer";
import { dsResetHistoricalDataReducer } from "./datasources/ds_config/dsResetHistoricalDataReducer";
import { googleConsoleMCCReducer } from "./datasources/ds_config/googleConsoleReducer";
import { googleConsoleReducer } from "./datasources/googleConsoleClientReducer";
import { emailMarketingGoogleSheetReducer } from "./datasources/googleSheets/EmailMarketingGoogleSheetReducer";
import { organicSearchGoogleSheet } from "./datasources/googleSheets/OrganicSearchGoogleSheetReducer";
import { organicSearchOpportunityReducer } from "./datasources/googleSheets/OrganicSearchOpportunityReducer";
import { paidOpportunityReducer } from "./datasources/googleSheets/PaidOpportunityReducer";
import { loginReducer } from "./loginReducer";
import { lppReducer } from "./lppReducer";
import { normalTableReducer } from "./normalTableReducer";
import { pieReducer } from "./pieReducer";
import { reportLayoutReducer } from "./reportLayoutReducer";
import { reportReducer } from "./reportReducer";
import { roleReducer } from "./roleReducer";
import { serviceSettingsReducer } from "./serviceSettingsReducer";
import { trendReducer } from "./trendReducer";
import { userReducer } from "./userReducer";
import { googleFeedProductStatusesReducer } from "./datasources/googleFeedProductStatusReducer";
import { pinterestMCCReducer } from "./datasources/ds_config/pinterestMccReducer";
import { googlePinterestClientReducer } from "./datasources/pinterestReducer";
import { tiktokReducer } from "./datasources/tiktokReducer";
import { amazonBusinessReportReducer } from "./datasources/amazonBusinessReport";
import { bingAdsMCCReducer } from "./datasources/ds_config/dsBingAdsReducer";
import { facebookAdsInsightsMCCReducer } from "./datasources/ds_config/dsFacebookAdsInsightsReducer";
import { espKlaviyoReducer } from "./datasources/ESPKlaviyoReducer";
import { facebookAdsInsightsClientReducer } from "./datasources/facebookAdsInsightsClientReducer";
import { donutReducer } from "./donutReducer";
import { AreaChartReducer } from "./AreaChartReducer";
import { channelPerformanceReducer } from "./channelPerformanceReducer";
import { tableSliderReducer } from "./tableSliderReducer";
import { ScatterReducer } from "./ScatterReducer";
import { inputControlReducer } from "./inputControlReducer";
import { FacebookTikTokReducer } from "./facebookTikTokReducer";
import { mmmReducer } from "./mmmReducer";
import { ColumnLineReducer } from "./columnLineReducer";
import tabReducer from "./tabReducer";

export default combineReducers({
  landingPage: lppReducer,
  login: loginReducer,
  users: userReducer,
  roles: roleReducer,
  accounts: accountReducer,
  keyMetrics: reportReducer,
  trend: trendReducer,
  graph: barGraphReducer,
  sessionData: ColumnChartReducer,
  lineChartData: LineChartReducer,
  internalReports: InternalReportReducer,
  reportLayouts: reportLayoutReducer,
  normalTable: normalTableReducer,
  dataTable: dataTableReducer,
  accountReports: accountReportReducer,
  tableSlider: tableSliderReducer,
  accountDataSources: accountDataSourcesReducer,
  accountDataSourceCredentials: accountDataSourcesCredentialsReducer,
  amazonSponsorAds: amazonSponsorAdsReducer,
  amazonMarketing: amazonMarketingReducer,
  dataSourceModelData: dataSourceModelReducer,
  global: globalReducer,
  googleAds: googleAdsReducer,
  resetHistoricalData: dsResetHistoricalDataReducer,
  amazonFullfillmentType: amazonFullfillmentTypeReducer,
  googleConsoleMcc: googleConsoleMCCReducer,
  googleConsole: googleConsoleReducer,
  googleAnalytics: googleAnalyticsReducer,
  organicRating: amazonOrganicRatingReducer,
  organicRanking: amazonOrganicRankingReducer,
  amazonSellingPartner: amazonSellingPartnerReducer,
  pie: pieReducer,
  settings: serviceSettingsReducer,
  emailMarketingGoogleSheet: emailMarketingGoogleSheetReducer,
  organicSearchGoogleSheet: organicSearchGoogleSheet,
  organicSearchOpportunity: organicSearchOpportunityReducer,
  paidOpportunity: paidOpportunityReducer,
  googleFeedProductStatuses: googleFeedProductStatusesReducer,
  pinterestMcc: pinterestMCCReducer,
  googlePinterestClient: googlePinterestClientReducer,
  tiktok: tiktokReducer,
  amazonBusinessReport: amazonBusinessReportReducer,
  bingAdsMcc: bingAdsMCCReducer,
  facebookAdsInsightsMcc: facebookAdsInsightsMCCReducer,
  espKlaviyo: espKlaviyoReducer,
  facebookAdsInsightsClient: facebookAdsInsightsClientReducer,
  donut: donutReducer,
  area: AreaChartReducer,
  channel_performance: channelPerformanceReducer,
  scatter_data: ScatterReducer,
  inputcontrols: inputControlReducer,
  fb_tiktok_data: FacebookTikTokReducer,
  key_insights: mmmReducer,
  columnLineData: ColumnLineReducer,
  tabStack: tabReducer,
});
