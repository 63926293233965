import React, { useEffect } from "react";
import { Button, Result } from "antd";
import history from "../../Utils/history";

const AccessDenied = () => {
  useEffect(() => {
    history.replace("/access-denied");
  }, []);
  return (
    <>
      <Result
        status="403"
        title="403"
        subTitle="You don't have permission to access this page."
        extra={
          <Button href="/" type="primary">
            Back Home
          </Button>
        }
      />
    </>
  );
};
export default AccessDenied;
