export const initialState = {
    isLoading: false,
    scatter_data: {}
  };
  
  export const ACTIONS_TYPES = Object.freeze({
    GET_SCATTER_DATA: "GET_SCATTER_DATA",
    FETCH_SCATTER_DATA: "FETCH_SCATTER_DATA"
  });
  
  export function ScatterReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS_TYPES.GET_SCATTER_DATA:
          return {
            ...state,
            isLoading: true,
          };
          case ACTIONS_TYPES.FETCH_SCATTER_DATA:
            return {
              ...state,
              isLoading: false,
              scatter_data: { ...state.scatter_data, ...action.payload },
            };
      default:
        return state;
    }
  }
  