export const initialState = {
    isLoading: false,
    channel_performance: [],
  };
  
  export const ACTIONS_TYPES = Object.freeze({
    GET_CHANNEL_PERFORMANCE: "GET_CHANNEL_PERFORMANCE",
    FETCH_CHANNEL_PERFORMANCE: "FETCH_CHANNEL_PERFORMANCE",
    RESET_CHANNEL_PERFORMANCE: "RESET_CHANNEL_PERFORMANCE"
  });
  
  export function channelPerformanceReducer(state = initialState, action) {
    const { key, payload } = action;
    switch (action.type) {
      case ACTIONS_TYPES.GET_CHANNEL_PERFORMANCE:
        return {
          ...state,
          isLoading: true,
        };
      case ACTIONS_TYPES.FETCH_CHANNEL_PERFORMANCE:
        return {
          ...state,
          isLoading: false,
          channel_performance: { ...state.channel_performance, ...action.payload },
        };
      case ACTIONS_TYPES.RESET_CHANNEL_PERFORMANCE:
          return {
            ...state,
            channel_performance: {
              ...state.channel_performance,
              [payload]: {},
            },
          };
      default:
        return state;
    }
  }
  