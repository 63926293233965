import React, { useEffect, useState, useCallback } from "react";
import {
  Input,
  Space,
  Table,
  Button,
  Row,
  Col,
  Switch,
  message,
  Alert,
  Modal,
  Checkbox,
  Tooltip,
  Spin,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRole,
  getAllRoles,
  updateRoleStatus,
} from "../../Redux/Actions/Thunk/roleThunk";
import { Link, useParams } from "react-router-dom";
import { debounce } from "../../Utils/helpers";
const { confirm } = Modal;

const { Search } = Input;

function Role(props) {
  let { id } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [searchedRoleList, setSearchedRoleList] = useState([]);
  const [hasSearched, setHasSearched] = useState(true);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filterColumn, setFilterColumn] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const {
    roles: {
      roles: { data: roleData = [], total },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  // Fetch data
  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  useEffect(() => {
    setDataSource(roleData);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: total,
      },
    });
  }, [roleData, total]);

  useEffect(() => {
    setSearchedRoleList(dataSource);
  }, [dataSource]);

  const columns = [
    {
      title: "Name",
      dataIndex: "display_name",
      key: "1",
      sorter: true,
      render: (text, record) => (
        <Link to={`roles/edit/${record.role_id}`}>{text}</Link>
      ),
    },
    {
      title: "type",
      dataIndex: "type",
      key: "2",
    },
    {
      title: "Users",
      dataIndex: "user_count",
      key: "3",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "4",
      render: (_, record) =>
        record.status == 1 ? (
          <Switch defaultChecked onChange={() => onChange(0, record)}></Switch>
        ) : (
          <Switch onChange={() => onChange(1, record)}></Switch>
        ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "5",
      render: (_, record) => (
        <>
          <div className="panel-action-button">
            <Tooltip title="View Role">
              <Link to={`roles/view/${record.role_id}`}>
                <FaEye />
              </Link>
            </Tooltip>
            <Tooltip title="Edit Role">
              {" "}
              <Link to={`roles/edit/${record.role_id}`}>
                <FaPencilAlt />
              </Link>
            </Tooltip>
            <Tooltip title="Delete Role">
              <span>
                <AiFillDelete onClick={() => openConfirm(record)} />
              </span>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const openConfirm = (selectedRows) => {
    confirm({
      title: "Are you sure you want to delete this role?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",

      onOk() {
        dispatch(deleteRole({ role_id: selectedRows.role_id }));
        message.success("Role Deleted Successfully", [1.5]);
      },

      onCancel() {},
    });
  };

  const onChange = (status, selectedRows) => {
    dispatch(
      updateRoleStatus({ role_id: selectedRows.role_id, status: status })
    );
    // message.success("Status Updated Successfully", [1.5]);
  };

  const searchHandler = useCallback(
    debounce((enteredValue) => {
      setSearchText(enteredValue);
      if (enteredValue) {
        dispatch(getAllRoles(`page=${1}&search=${enteredValue}`));
        setSearchedRoleList(roleData);
        setHasSearched(true);
      } else {
        dispatch(getAllRoles(`page=${1}`));
        setSearchedRoleList(roleData);
        setHasSearched(true);
      }
    }, 500),
    [dispatch, roleData]
  );

  const handleChange = (pagination, filters, sorter) => {
    const limit = pagination.pageSize;
    let params = `page=${pagination?.current}`;
    setTableParams({
      pagination,
    });

    if (sorter.hasOwnProperty("column")) {
      const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
      //params += `&sort_column=${sorter.field}&sort_order=${sortOrder}`;
      dispatch(
        getAllRoles(`&sort_column=${sorter.field}&sort_order=${sortOrder}`)
      );
    }

    if (searchText) {
      dispatch(getAllRoles(`search=${searchText}`));
    }
    dispatch(getAllRoles(`page=${params}&limit=${limit}`));
  };

  const hideInactiveRoles = useCallback((e) => {
    let hideInactiveRoles = e.target.checked ? 1 : 0;
    dispatch(
      getAllRoles(`hide_inactive=${hideInactiveRoles}&search=${searchText}`)
    );
  }, []);

  return (
    <>
      <div className="panel-heading">
        <Row>
          <Col span={8} className="panel-heading-title">
            <h2>Roles</h2>
          </Col>
          <Col span={16} className="panel-heading-buttons">
            <Link to={{ pathname: `roles/add` }}>
              <Button className="user-new">New Role</Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        {/* <div className="nant-memberlist-search-field">
            <div className="row">
              <div className="col col-12 col-sm-6">
                <div className="nant-search-field text-right">
                  <Space direction="vertical">
                    <Search
                      placeholder="Enter name"
                      enterButton="Search"
                      size="large"
                      allowClear
                      onSearch={searchHandler}
                      style={{ float: 'right', width: 350 }}
                    />
                  </Space>
                </div>
              </div>
            </div>
          </div> */}
        <Row className="panel-body-input">
          <Col
            lg={{ span: 16 }}
            xs={{ span: 12 }}
            className="panel-body-input-left"
          >
            <div className="panel-body-select">
              <Search
                placeholder="Role"
                //onSearch={searchHandler}
                onChange={(e) => searchHandler(e.target.value)}
                allowClear
                style={{ float: "right", width: 250 }}
              />
            </div>
          </Col>
          <Col
            lg={{ span: 8 }}
            xs={{ span: 12 }}
            className="panel-body-input-right"
          >
            <div className="panel-body-checkbox">
              <Checkbox onChange={hideInactiveRoles}>
                {" "}
                Hide Inactive Roles
              </Checkbox>
            </div>
          </Col>
        </Row>
        {/* {loading ? <Loader /> : ""} */}
        <div className="panel-body-content">
          {hasSearched ? (
            <Table
              columns={columns}
              key={(record) => record.primaryKey}
              dataSource={searchedRoleList}
              onChange={handleChange}
              loading={isLoading}
              pagination={tableParams.pagination}
              className="roles-display-table"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Role;
