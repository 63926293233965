import axios from "axios";
import config from "../Config";

const {
  API: { baseURL },
} = config;

export default axios.create({
  baseURL,
});
