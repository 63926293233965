import { formatColumns } from "../../../Utils/colorLogic";
import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const BingSearchTermInsights = (props, text) => {
  let cellClass = "";
  let recordData = props?.record;

  let blockId = props?.extraParams?.block?.block_id;
  let accountId = props?.extraParams?.account_id;
  let eparams = props?.extraParams?.all_input_data;
  let f_month = eparams?.f_month;
  let t_month = eparams?.t_month;
  let f_month_compare = eparams?.c_f_month;
  let t_month_compare = eparams?.c_t_month;
  let search_term_type = recordData?.search_term_type;
  let channel = recordData?.channel;

  if (props?.column?.dataIndex == "search_term_type") {
    return (
      <>
        <Link
          to={
            "/reports/v2/view/bing_search_term_performance/" +
            accountId +
            "?search_term_type=" +
            search_term_type.toLowerCase() +
            "&f_month=" +
            f_month +
            "&t_month=" +
            t_month +
            "&c_f_month=" +
            f_month_compare +
            "&c_t_month=" +
            t_month_compare
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
            />
          </span>
        </Link>
      </>
    );
  } else if (props?.column?.dataIndex == "channel") {
    return (
      <>
        <Link
          to={
            "/reports/v2/view/bing_search_term_performance/" +
            accountId +
            "?channel=" +
            channel +
            "&f_month=" +
            f_month +
            "&t_month=" +
            t_month +
            "&c_f_month=" +
            f_month_compare +
            "&c_t_month=" +
            t_month_compare
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
            />
          </span>
        </Link>
      </>
    );
  } else {
    return (
      <>
        <span
          className={formatColumns(
            props?.value,
            recordData,
            props?.goalData,
            props?.column?.dataIndex
          )}
        >
          <FormattedNumberCell
            value={props?.value}
            format={props?.column?.format}
          />
        </span>
      </>
    );
  }
};

export default BingSearchTermInsights;
