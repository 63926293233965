// import { ACTIONS_TYPES } from "../Reducers/accountReducer";

export const initialState = {
    isLoading: true,
    comment: {}
  };
  
  
  
  export const ACTIONS_TYPES = {
    GET_COMMENT:"GET_COMMENT",
    GET_ANNOTATIONS_COMMENT:"GET_ANNOTATIONS_COMMENT",
  
    
  }
  
  export const getAnnotationsCommentReducer = (state = initialState, action) => {
    switch (action.type) {
      case ACTIONS_TYPES.GET_COMMENT:
        return {
          ...state,
          isLoading: true, 
        };
      case ACTIONS_TYPES.GET_COMMENT:
        return {
          ...state,
          comment: action.payload,
          isLoading: false, 
        };
      case ACTIONS_TYPES.GET_ANNOTATIONS_COMMENT:
        // const comments = action.payload;
        // const data = comments ? [...state,...comments] : [...state,comments]
        return {
          ...state,
          comment:action.payload,
          isLoading: false, 
        };
      default:
        return state;
    }
  };
  