/**
 * @fileoverview Reducer for Google Analytics Data Sources
 *
 * The reducer handles the state changes for the Google.
 */

// Define the initial state of the reducer
export const initialState = {
  account_ds: {}, // for single account data source
  account_dss: {}, // forall  account data sources . i used dss because of data sources . note "s" at the end, it is plural
  error_ds: {}, // error data sources
  isLoading: false, // loading state
};

// Define action types to be used in the reducer
export const ACTIONS_TYPES = Object.freeze({
  FACEBOOK_ADS_INSIGHT_CLIENT_DS_REQUEST:
    "FACEBOOK_ADS_INSIGHT_CLIENT_DS_REQUEST",
  GET_FACEBOOK_ADS_INSIGHT_CLIENT_DS: "GET_FACEBOOK_ADS_INSIGHT_CLIENT_DS", // Fetch Account Data Sources

  CREATE_FACEBOOK_ADS_INSIGHT_CLIENT: "CREATE_FACEBOOK_ADS_INSIGHT_CLIENT", // Create Account Data Sources
  UPDATE_FACEBOOK_ADS_INSIGHT_CLIENT: "UOPDATE_FACEBOOK_ADS_INSIGHT_CLIENT", // Update Account Data Sources
  FACEBOOK_ADS_INSIGHT_CLIENT_ERROR_DS: "FACEBOOK_ADS_INSIGHT_CLIENT_ERROR_DS", // Error Account Data Sources
  FAILED_FACEBOOK_ADS_INSIGHT_CLIENT: "FAILED_FACEBOOK_ADS_INSIGHT_CLIENT",
  RESET_FACEBOOK_ADS_INSIGHT_CLIENT: "RESET_FACEBOOK_ADS_INSIGHT_CLIENT",
});

// Define the reducer function
export function facebookAdsInsightsClientReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;

  // Switch case to handle different types of actions
  switch (type) {
    case ACTIONS_TYPES.FACEBOOK_ADS_INSIGHT_CLIENT_DS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_FACEBOOK_ADS_INSIGHT_CLIENT_DS:
      return {
        ...state,
        isLoading: false,
        account_ds: action.payload,
      };

    case ACTIONS_TYPES.FACEBOOK_ADS_INSIGHT_CLIENT_ERROR_DS:
    case ACTIONS_TYPES.FAILED_FACEBOOK_ADS_INSIGHT_CLIENT:
      return {
        ...state,
        isLoading: false,
        error_ds: payload,
      };

    case ACTIONS_TYPES.RESET_FACEBOOK_ADS_INSIGHT_CLIENT:
      return {
        ...state,
        isLoading: false,
        account_ds: {},
        account_dss: {},
        error_ds: {},
      };

    // Return the initial state if no matching action is found
    default:
      return state;
  }
}
