import decode from "jwt-decode";
import axios from "axios";
import { ApiEndpointsBI } from "../Utils/constant";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Token } from "../Utils/helpers/token";
import Config from "../Config";

const {
  SSO_LOGIN: { api_base_url, baseURL },
} = Config;


export const isLoggedIn = () => {
  
  return localStorage.getItem("token") ? true : false;
};

// Note: Needs to work here
export const checkIfTokenHasExpired = (token) => {
      if(localStorage.getItem("impersonate") !=null){
        return Promise.resolve(false);
      }
      return fetch(`${api_base_url}check-token-exp`, {
        method: 'POST',
        headers: {
          'Authorization': Token.encryptAuthParams(token)
        }
      })
      .then(response => {
        
        // Check if the response was successful (status code 2xx)
        if (!response.ok) {

            localStorage.removeItem("token");
				    localStorage.removeItem("user");
        
          return Promise.reject(true); // Reject with 'true' value to indicate an error
        }

        // Parse the JSON response
        return response.json();
      })
      .then(data => {
        const decodedToken = decode(data.user_token);
        
        return decodedToken.exp * 1000 < new Date().getTime();
      })
      .catch(error => {
        
        console.error("Error occurred during API request:", error);
        localStorage.removeItem("token");
				localStorage.removeItem("user");

        const domain = window.location.href;
        window.location.href = `https://${baseURL}login?domain=${domain}`;

        return Promise.resolve(true); // Resolve with 'false' value to indicate the token has not expired (in case of an error)
      });
};

export const getTrimmedString = (data, maxLength = 250) => {
  let trimmedString = data?.substr(0, maxLength);

  trimmedString =
    trimmedString?.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    ) + "...";

  return trimmedString;
};

//getting users IP address with location data
export const getLocationData = async () => {
  const { LOCATION_API } = ApiEndpointsBI;
  const res = await axios.get(LOCATION_API);
  let ip_addr = res.data.IPv4;
  return ip_addr;
};

//getting device fingerprint
export const getFingerPrint = async () => {
  const fp = await FingerprintJS.load();
  let fingerprint = await fp.get();
  return fingerprint.visitorId;
};
