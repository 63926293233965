export const initialState = {
  isLoading: false,
  error: null,

  //   data contains followig keys:
  // account_id: modalData.account_id,
  // account_bi_data_source_id: modalData.abds_id,
  // bi_data_source_id: modalData.bi_data_source_id,
  // error_message: modalData.error_message,

  data: {},
};
export const ACTIONS_TYPES = Object.freeze({
  DATASOURCE_MODEL_REQUEST: "DATASOURCE_MODEL_REQUEST",
  GET_DATASOURCE_MODEL: "GET_DATASOURCE_MODEL",
  CREATE_DATASOURCE_MODEL: "CREATE_DATASOURCE_MODEL",
  UPDATE_DATASOURCE_MODEL: "UPDATE_DATASOURCE_MODEL",
  DATASOURCE_MODEL_ERROR: "DATASOURCE_MODEL_ERROR",
});

export function dataSourceModelReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.ACCOUNT_CREDENTIALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS_TYPES.GET_DATASOURCE_MODEL:
    case ACTIONS_TYPES.UPDATE_DATASOURCE_MODEL:
    case ACTIONS_TYPES.CREATE_DATASOURCE_MODEL:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case ACTIONS_TYPES.DATASOURCE_MODEL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
