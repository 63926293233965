import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const LandingPageInsights = (props, text) => {
  let cellClass = "";
  let recordData = props?.record;
  let blockId = props?.extraParams?.block?.block_id;
  let accountId = props?.extraParams?.account_id;
  let eparams = props?.extraParams?.all_input_data;
  let f_month = eparams?.f_month;
  let t_month = eparams?.t_month;
  let f_month_compare = eparams?.c_f_month;
  let t_month_compare = eparams?.c_t_month;
  let source_medium = eparams?.source_medium;

  if (props?.goalData.hasOwnProperty("acos_goal")) {
    var acos_goal = props?.goalData?.acos_goal;
  }

  if (props?.goalData.hasOwnProperty("cvr_avg")) {
    var cvr_avg = props?.goalData?.cvr_avg;
  }

  if (props?.goalData.hasOwnProperty("revenue_goal")) {
    var revenue_goal = props?.goalData?.revenue_goal;
  }

  if (props?.goalData.hasOwnProperty("cpc_avg")) {
    var cpc_avg = props?.goalData?.cpc_avg;
  }

  if (props?.goalData.hasOwnProperty("roas_goal")) {
    var roas_goal = props?.goalData?.roas_goal;
  }

  if (props?.goalData.hasOwnProperty("analytics_cvr_avg")) {
    var analytics_cvr_avg = props?.goalData?.analytics_cvr_avg;
  }

  if (props?.goalData.hasOwnProperty("analytics_revenue_avg")) {
    var analytics_revenue_avg = props?.goalData.analytics_revenue_avg;
  }

  if (props?.goalData.hasOwnProperty("analytics_sessions_avg")) {
    var analytics_sessions_avg = props?.goalData?.analytics_sessions_avg;
  }

  //roas
  if (props?.column?.dataIndex == "adwords_roas") {
    if (roas_goal > 0) {
      if (recordData?.adwords_roas > roas_goal * 1.5) {
        cellClass = "green-column"; //green_cell_fill
      } else if (
        recordData?.adwords_roas <= roas_goal * 1.5 &&
        recordData?.adwords_roas > roas_goal
      ) {
        cellClass = "light-green-column"; //light_green_cell_fill
      } else if (
        recordData?.adwords_roas <= roas_goal &&
        recordData?.adwords_roas > roas_goal * 0.5
      ) {
        cellClass = "light-yellow-column"; //light_yellow_cell_fill
      } else if (
        recordData?.adwords_roas <= roas_goal * 0.5 &&
        recordData?.adwords_roas > 0
      ) {
        cellClass = "light-orange-column"; //light_orange_cell_fill
      } else {
        cellClass = "light-red-column"; //light_red_cell_fill
      }
    }
  }

  //adwords_cvr
  if (props?.column?.dataIndex == "adwords_cvr") {
    if (recordData?.adwords_cvr > cvr_avg * 1.5) {
      cellClass = "green-column"; // /green_cell_fill
    } else if (
      recordData?.adwords_cvr <= cvr_avg * 1.5 &&
      recordData?.adwords_cvr > cvr_avg
    ) {
      cellClass = "light-green-column"; //light_green_cell_fill
    } else if (
      recordData?.adwords_cvr <= cvr_avg &&
      recordData?.adwords_cvr > cvr_avg * 0.5
    ) {
      cellClass = "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      recordData?.adwords_cvr <= cvr_avg * 0.5 &&
      recordData?.adwords_cvr >= 0
    ) {
      cellClass = "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  // var cpc_avg = 100;
  //cpc
  if (props?.column?.dataIndex == "adwords_cpc") {
    if (recordData?.adwords_cpc > cpc_avg * 1.5) {
      cellClass = "light-red-column"; //light_red_cell_fill
    } else if (
      recordData?.adwords_cpc <= cpc_avg * 1.5 &&
      recordData?.adwords_cpc > cpc_avg
    ) {
      cellClass = "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      recordData?.adwords_cpc <= cpc_avg &&
      recordData?.adwords_cpc > cpc_avg * 0.5
    ) {
      cellClass = "light-green-column"; //light_green_cell_fill
    } else if (
      recordData?.adwords_cpc <= cpc_avg * 0.5 &&
      recordData?.adwords_cpc > 0
    ) {
      cellClass = "green-column"; //green_cell_fill
    } else {
      //nothing
    }
  }

  //analytics cvr
  if (props?.column?.dataIndex == "analytics_cvr") {
    if (recordData?.analytics_cvr > analytics_cvr_avg * 1.5) {
      cellClass = "green-column"; //green_cell_fill
    } else if (
      recordData?.analytics_cvr <= analytics_cvr_avg * 1.5 &&
      recordData?.analytics_cvr > analytics_cvr_avg
    ) {
      cellClass = "light-green-column"; //light_green_cell_fill
    } else if (
      recordData?.analytics_cvr <= analytics_cvr_avg &&
      recordData?.analytics_cvr > analytics_cvr_avg * 0.5
    ) {
      cellClass = "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      recordData?.analytics_cvr <= analytics_cvr_avg * 0.5 &&
      recordData?.analytics_cvr >= 0
    ) {
      cellClass = "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  //analaytics revenue
  if (props?.column?.dataIndex == "analytics_revenue") {
    if (recordData?.analytics_revenue > analytics_revenue_avg * 1.5) {
      cellClass = "green-column"; //green_cell_fill
    } else if (
      recordData?.analytics_revenue <= analytics_revenue_avg * 1.5 &&
      recordData?.analytics_revenue > analytics_revenue_avg
    ) {
      cellClass = "light-green-column"; //light_green_cell_fill
    } else if (
      recordData?.analytics_revenue <= analytics_revenue_avg &&
      recordData?.analytics_revenue > analytics_revenue_avg * 0.5
    ) {
      cellClass = "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      recordData?.analytics_revenue <= analytics_revenue_avg * 0.5 &&
      recordData?.analytics_revenue >= 0
    ) {
      cellClass = "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  //analaytics sessions
  if (props?.column?.dataIndex == "analytics_sessions") {
    if (recordData?.analytics_sessions > analytics_sessions_avg * 1.5) {
      cellClass = "green-column"; //green_cell_fill
    } else if (
      recordData?.analytics_sessions <= analytics_sessions_avg * 1.5 &&
      recordData?.analytics_sessions > analytics_sessions_avg
    ) {
      cellClass = "light-green-column"; //light_green_cell_fill
    } else if (
      recordData?.analytics_sessions <= analytics_sessions_avg &&
      recordData?.analytics_sessions > analytics_sessions_avg * 0.5
    ) {
      cellClass = "light-yellow-column"; //light_yellow_cell_fill
    } else if (
      recordData?.analytics_sessions <= analytics_sessions_avg * 0.5 &&
      recordData?.analytics_sessions >= 0
    ) {
      cellClass = "light-red-column"; //light_red_cell_fill
    } else {
      //nothing
    }
  }

  if (blockId == 537) {
    if (props?.column?.dataIndex == "page_type") {
      return (
        <>
          <Link
            to={
              "/reports/v2/view/landing_page_performance/" +
              accountId +
              "?f_month=" +
              f_month +
              "&t_month=" +
              t_month +
              "&c_f_month=" +
              f_month_compare +
              "&c_t_month=" +
              t_month_compare +
              "&source_medium=" +
              source_medium +
              "&page_type=" +
              props?.value
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={cellClass}>
              <FormattedNumberCell
                value={props?.value}
                format={props?.column?.format}
                metric={props?.column?.dataIndex}
              />
            </span>
          </Link>
        </>
      );
    } else {
      return (
        <>
          <span className={cellClass}>
            <FormattedNumberCell
              value={props?.value}
              format={props?.column?.format}
              metric={props?.column?.dataIndex}
            />
          </span>
        </>
      );
    }
  } else {
    return (
      <>
        <span className={cellClass}>
          <FormattedNumberCell
            value={props.value}
            format={props.column.format}
            metric={props?.column?.dataIndex}
          />
        </span>
      </>
    );
  }
};

export default LandingPageInsights;
