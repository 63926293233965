import { useState, useRef } from "react";
import { DatePicker, Select, Switch, Form } from "antd";
import moment, { months } from "moment";
import { capitalizeFirstLetter } from "../../../src/Utils/helpers";
import React, { useEffect } from "react";

const { Option } = Select;
// const presetRangesList = {
//   Today: [moment(), moment()],
//   "Last 7 Days": [moment().subtract(7, "days"), moment()],
//   "Last 30 Days": [moment().subtract(30, "days"), moment()],
//   "This Month": [moment().startOf("month"), moment().endOf("month")],
//   "Last Month": [
//     moment().subtract(1, "month").startOf("month"),
//     moment().subtract(1, "month").endOf("month"),
//   ],
// };

// const additionalPresetRangesList = [
//   {
//     label: "Previous Period",
//     value: [moment(), moment()],
//   },
//   {
//     label: "Same Period Last Year",
//     value: [
//       moment().subtract(1, "month").startOf("month"),
//       moment().subtract(1, "month").endOf("month"),
//     ],
//   },
//   {
//     label: "Previous Month",
//     value: [moment().subtract(1, "month"), moment().subtract(1, "month")],
//   },
// ];
const InputDate = (props) => {
	const buttonRef = useRef(null);
	const selectRef = useRef(null);
	const [calendarOpen, setCalendarOpen] = useState(false);
	const [showAdditionalRanges, setShowAdditionalRanges] = useState(true);
	const pickerType = props?.block?.mode_type;
	const presetRanges = props?.block?.presetRanges;
	const additionalPresetRanges = props?.block?.additionalPresetRanges;

	const dateFormat = props?.block?.format;
	const hideToggleSwitch = props?.block?.hideToggleSwitch;
	const month_range = props?.block?.month_range;
	const compare_with = props?.block?.compare_with;
	const hideText = props?.block?.hideText;
	const showAdditionalOptions = props?.block?.showAdditionalOptions;

	const defaultRangeOption =
		props?.block?.default_date_range_option != undefined
			? props?.block?.default_date_range_option
			: "Last Month";
	const defaultCRangeOption =
		props?.block?.default_compare_date_range_option != undefined
			? props?.block?.default_compare_date_range_option
			: "Same Period Last Year";

	var presetRangesList = {};

	if (presetRanges == undefined) {
		var presetRangesList = {
			Today: [moment(), moment()],
			"Last 7 Days": [moment().subtract(7, "days"), moment()],
			"Last 30 Days": [moment().subtract(30, "days"), moment()],
			"This Month": [moment().startOf("month"), moment().endOf("month")],
			"Last Month": [
				moment().subtract(1, "month").startOf("month"),
				moment().subtract(1, "month").endOf("month"),
			],
		};

		var additionalPresetRangesList = [
			{
				label: "Previous Period",
				value: [moment(), moment()],
			},
			{
				label: "Same Period Last Year",
				value: [
					moment().subtract(1, "month").startOf("month"),
					moment().subtract(1, "month").endOf("month"),
				],
			},
			{
				label: "Previous Month",
				value: [moment().subtract(1, "month"), moment().subtract(1, "month")],
			},
		];
	}

	const customFormat = "MMM DD, YYYY";

	if (presetRanges != undefined && presetRanges.includes("Yesterday")) {
		presetRangesList.yesterday = [moment().subtract(1, "day"), moment()];
	}

	if (presetRanges != undefined && presetRanges.includes("Today")) {
		presetRangesList.today = [moment(), moment()];
	}

	if (presetRanges != undefined && presetRanges.includes("Last 7 Days")) {
		presetRangesList.last_7_days = [moment().subtract(7, "days"), moment()];
	}

	if (presetRanges != undefined && presetRanges.includes("Last 30 Days")) {
		presetRangesList.last_30_days = [
			moment().subtract(30, "days"),
			moment().endOf("month"),
		];
	}

	if (presetRanges != undefined && presetRanges.includes("This Month")) {
		presetRangesList.this_month = [
			moment().startOf("month"),
			moment().subtract(1, "day"),
		];
	}

	if (presetRanges != undefined && presetRanges.includes("Last Month")) {
		presetRangesList.last_month = [
			moment().subtract(1, "month").startOf("month"),
			moment().subtract(1, "month").endOf("month"),
		];
	}

	if (presetRanges != undefined) {
		var defaultDateRange = [
			presetRangesList[defaultRangeOption][0],
			presetRangesList[defaultRangeOption][1],
		];
	} else {
		var defaultDateRange = [
			moment().subtract(1, "month").startOf("month"),
			moment().subtract(1, "month").endOf("month"),
		];
	}

	const [dateRange, setDateRange] = useState(defaultDateRange);

	var additionalPresetRangesList = [];
	if (
		additionalPresetRanges != undefined &&
		additionalPresetRanges.includes("Same Period Last Year")
	) {
		var same_period_obj = {
			label: "Same Period Last Year",
			value: [
				moment(dateRange[0]).subtract(1, "year"),
				moment(dateRange[1]).subtract(1, "year"),
			],
		};

		additionalPresetRangesList.push(same_period_obj);
	}

	if (
		additionalPresetRanges != undefined &&
		additionalPresetRanges.includes("Same 30 Days Last Year")
	) {
		var same_30_obj = {
			label: "Same 30 Days Last Year",
			value: [
				moment(dateRange[0]).subtract(1, "year"),
				moment(dateRange[1]).subtract(1, "year"),
			],
		};
		additionalPresetRangesList.push(same_30_obj);
	}

	if (
		additionalPresetRanges != undefined &&
		additionalPresetRanges.includes("Previous Month")
	) {
		var previous_month = {
			label: "Previous Month",
			value: [
				moment(dateRange[0]).subtract(1, "month"),
				moment(dateRange[1]).subtract(1, "month"),
			],
		};
		additionalPresetRangesList.push(previous_month);
	}

	const [dateSRange, setSDateRange] = useState([
		moment(dateRange[0]).subtract("1", "year"),
		moment(dateRange[1]).subtract("1", "year"),
	]);

	useEffect(() => {
		if (selectedAdditionalVal.current == "Same Period Last Year") {
			setSDateRange([
				moment(dateRange[0]).subtract("1", "year"),
				moment(dateRange[1]).subtract("1", "year"),
			]);
			additionalDateValue.current = [
				moment(dateRange[0]).subtract("1", "year"),
				moment(dateRange[1]).subtract("1", "year"),
			];
		} else if (selectedAdditionalVal.current == "Same 30 Days Last Year") {
			setSDateRange([
				moment(dateRange[0]).subtract("1", "year"),
				moment(dateRange[1]).subtract("1", "year"),
			]);
			additionalDateValue.current = [
				moment(dateRange[0]).subtract("1", "year"),
				moment(dateRange[1]).subtract("1", "year"),
			];
		} else if (selectedAdditionalVal.current == "Previous Month") {
			setSDateRange([
				moment(dateRange[0]).subtract("1", "month"),
				moment(dateRange[1]).subtract("1", "month"),
			]);
			additionalDateValue.current = [
				moment(dateRange[0]).subtract("1", "month"),
				moment(dateRange[1]).subtract("1", "month"),
			];
		} else {
			// setSDateRange([moment(dateRange[0]).subtract("1", "year"), moment(dateRange[1]).subtract("1", "year")]);
		}
	}, [dateRange]);

	const selectedVal = useRef(capitalizeFirstLetter(defaultRangeOption));
	const selectedAdditionalVal = useRef(defaultCRangeOption);
	const dateValue = useRef(null);
	const additionalDateValue = useRef(null);

	const handlePresetRangeSelect = (value) => {
		setDateRange(presetRangesList[value]);
		dateValue.current = presetRangesList[value];

		selectedVal.current = value;
	};
	const handleCalendarOpenChange = (open) => {
		if (!open) {
			setCalendarOpen(open);
		}
	};

	const handleToggleCalendar = () => {
		setCalendarOpen(!calendarOpen);

		// dateValue.current =
		// selectedVal.current = "Custom";
	};
	useEffect(() => {
		if (calendarOpen) {
			const buttonPosition = buttonRef.current.getBoundingClientRect();
			const dropdown = document.getElementById("mainCalendarDropdown");

			const top = buttonPosition.top + buttonPosition.height;
			const left = buttonPosition.left;

			dropdown.style.position = "absolute";
			dropdown.style.top = `${top}px`;
			dropdown.style.left = `${left}px`;
		}
	}, [calendarOpen]);
	const getPopupContainer = () => {
		const popupContainer = document.createElement("div");
		popupContainer.id = "mainCalendarDropdown";
		document.body.appendChild(popupContainer);
		return popupContainer;
	};

	//COMPARE DATE RANGE PICKER
	const sbuttonRef = useRef(null);
	const [scalendarOpen, setSCalendarOpen] = useState(false);
	const [activeIndex, setActiveIndex] = useState(null);
	const handleSCalendarOpenChange = (open) => {
		if (!open) {
			setSCalendarOpen(open);
		}
	};

	const handleSToggleCalendar = () => {
		setSCalendarOpen(!scalendarOpen);
	};
	useEffect(() => {
		if (scalendarOpen) {
			const sbuttonPosition = sbuttonRef.current.getBoundingClientRect();
			const cdropdown = document.getElementById("compCalendarDropdown");

			const stop = sbuttonPosition.top + sbuttonPosition.height;
			const sleft = sbuttonPosition.left;

			cdropdown.style.position = "absolute";
			cdropdown.style.top = `${stop}px`;
			cdropdown.style.left = `${sleft}px`;
		}
	}, [scalendarOpen]);
	const handleAdditionalRangesSwitchChange = (checked) => {
		setShowAdditionalRanges(checked);
	};

	const getSPopupContainer = () => {
		const popupSContainer = document.createElement("div");
		popupSContainer.id = "compCalendarDropdown";
		document.body.appendChild(popupSContainer);
		return popupSContainer;
	};

	const setAdditionalDateRange = (range, index) => {
		selectRef.current.blur();
		setActiveIndex(index);
		setSDateRange(range.value);
		selectedAdditionalVal.current = range.label;
		additionalDateValue.current = range.value;
	};

	const handleAdditionalRange = (value) => {
		additionalDateValue.current = value;
		selectedAdditionalVal.current = "Custom";
	};

	const handleDateChange = (value) => {
		dateValue.current = value;
		selectedVal.current = "Custom";
	};

	return (
		<div className="Report-V2-filter-box Report-V2-filter-box-date ">
			<div className="Report-V2-filter-option-title">
				<span>{props?.block?.label}</span>
			</div>
			<div className="Report-V2-filter-box-date-wrapper">
				<div className="main-daterange">
					<Form.Item
						name="dateRange"
						initialValue={{ dateRange, selectedVal, dateValue, dateFormat }}
					></Form.Item>
					<DatePicker.RangePicker
						value={dateRange}
						onCalendarChange={(value) => setDateRange(value)}
						onChange={handleDateChange}
						open={calendarOpen}
						onOpenChange={handleCalendarOpenChange}
						format={customFormat}
						allowClear={false}
						inputReadOnly={true}
						suffixIcon={null}
						getPopupContainer={getPopupContainer}
						picker={pickerType}
					/>
				</div>

				{showAdditionalOptions != "false" ? (
					<div className="compare-daterange">
						<Form.Item
							name="dateSRange"
							initialValue={{
								dateSRange,
								selectedAdditionalVal,
								additionalDateValue,
							}}
						></Form.Item>
						<DatePicker.RangePicker
							className="compare-daterange"
							value={dateSRange}
							onCalendarChange={(value) => setSDateRange(value)}
							onChange={handleAdditionalRange}
							open={scalendarOpen}
							onOpenChange={handleSCalendarOpenChange}
							format={customFormat}
							allowClear={false}
							inputReadOnly={true}
							suffixIcon={null}
							getPopupContainer={getSPopupContainer}
							picker={pickerType}
						/>
					</div>
				) : (
					""
				)}

				<Select
					dropdownClassName="input-dropdown-menu"
					ref={selectRef}
					defaultValue="Today"
					onChange={handlePresetRangeSelect}
					dropdownRender={(menu) => (
						<div className="daterange-picker-addition">
							{menu}
							<button
								ref={buttonRef}
								onClick={handleToggleCalendar}
								className="daterange-custom-button"
							>
								{month_range ? month_range : "Custom"}
							</button>
							{hideToggleSwitch == "true" ? (
								hideText == "true" ? (
									""
								) : (
									<span>Compared to:</span>
								)
							) : (
								<div className="daterange-compare-toggle">
									<Switch
										size="small"
										checked={showAdditionalRanges}
										onChange={handleAdditionalRangesSwitchChange}
									/>
									<span>Compare</span>
								</div>
							)}
							{showAdditionalRanges && showAdditionalOptions != "false" && (
								<>
									<div className="additonal-preset-range-list">
										{additionalPresetRangesList.map((range, index) => (
											<div
												className={`additonal-preset-range ${
													activeIndex === index ? "apr-active" : ""
												}`}
												key={range.label}
												onClick={() => setAdditionalDateRange(range, index)}
											>
												{range.label}
											</div>
										))}
									</div>
									<button
										ref={sbuttonRef}
										onClick={handleSToggleCalendar}
										className="daterange-custom-button"
									>
										{compare_with ? compare_with : "Custom"}
									</button>
								</>
							)}
						</div>
					)}
				>
					{Object.keys(presetRangesList).map((range) => (
						<Option key={range} value={range}>
							{capitalizeFirstLetter(range.replaceAll("_", " "))}
						</Option>
					))}
				</Select>
			</div>
		</div>
	);
};

export default InputDate;
