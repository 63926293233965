import { EditOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PInterestTable = () => {
  const [datas, setDatas] = useState([]);
  const {
    account_dss: { auth_params = [] },
  } = useSelector((state) => state.pinterestMcc);

  useEffect(() => {
    setDatas(auth_params);
  }, [auth_params.length]);

  const columns = [
    {
      title: "Account",
      dataIndex: "user",
      key: "email",
      render: (text) => text,
    },
    {
      title: "Access Token",
      // dataIndex: "access_token",
      // key: "access_token",
      elepsis: false,
      // render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
    },
    // {
    //   title: "Refresh Token",
    //   dataIndex: "refreshToken",
    //   key: "refreshToken",
    // },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Expires On",
      key: "expires_on",
      render: (_, record) => {
        let dateAfterTwoMonths = new Date(record.updated_at);
        dateAfterTwoMonths.setMonth(dateAfterTwoMonths.getMonth() + 2);

        return <div>{dateAfterTwoMonths.toUTCString()} </div>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={`/data-source/pinterest-ads-insights/edit/${record.id}`}>
              <EditOutlined
                style={{
                  height: "20px",
                  width: "20px",
                  backgroundColor: "rgb(141,195,64)",
                  borderRadius: "50%",
                  color: "white",
                  textAlign: "center",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Input
        onChange={(e) => {
          const { value } = e.target;
          const filteredData = auth_params.filter((item) => {
            return item.user.toLowerCase().includes(value.toLowerCase());
          });
          setDatas(filteredData);
        }}
        placeholder="Account"
      />
      <Table columns={columns} dataSource={datas} />
    </>
  );
};

export default PInterestTable;
