import { FormattedNumberCell } from "../../../Utils/helpers/formatting";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Config from "../../../Config";
import HtmlParser from "html-react-parser";

const AnnotationsReport = (props, text) => {
  // console.log('this is the column name for notes',props);
  let cellClass=""
  let account_id = props?.record?.account_id
  let reportName = props?.record?.report_name
  let reportNameU = reportName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  let report_url = props?.record?.report_id
  let input_control = props?.record?.input_controls
  // console.log('tgus us te modified report name', reportNameU)
  if (props?.column?.dataIndex == "notes") {
    return(
      <>
      <span>{HtmlParser(props.value)}</span>
      </>
    )
  }else if (props?.column?.dataIndex == "report_name"){
  return(
    <>
    <Link
            to={
              "/reports/v2/view/" + reportName + "/" + account_id + "?" + input_control
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FormattedNumberCell
                value={reportNameU}
                format={props?.column?.format}
              />
            </span>
          </Link>
    </>
  )
  }
  return(
    <><span className={cellClass}>
    <FormattedNumberCell
      value={props?.value}
      format={props?.column?.format}
    />
  </span></>
    
  )
};

export default AnnotationsReport;