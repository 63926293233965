export const initialState = {
    donutChartData: {},
    isLoading: false,
};

export const ACTIONS_TYPES = Object.freeze({
    GET_DONUT_CHART_DATA: "GET_DONUT_CHART_DATA",
    FETCH_DONUT_CHART_DATA: "FETCH_DONUT_CHART_DATA",
});

export function donutReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS_TYPES.FETCH_DONUT_CHART_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case ACTIONS_TYPES.GET_DONUT_CHART_DATA:
            return {
                ...state,
                isLoading: false,
                donutChartData: { ...state.graph, ...action.payload },
            };
        default:
            return state;
    }
}
