import { ACTIONS_TYPES } from "../../../Reducers/datasources/ds_config/googleConsoleReducer";

export function getGoogleConsoleMCCAction(payload) {
  return {
    type: ACTIONS_TYPES.GOOGLE_CONSOLE_MCC,
    payload,
  };
}

export function getAllGoogleConsoleMCCAction(payload) {
    return {
      type: ACTIONS_TYPES.GOOGLE_CONSOLE_ALL_MCC,
      payload,
    };
  }

export function failedGoogleConsoleMCCAction(payload) {
  return {
    type: ACTIONS_TYPES.FAILED_GOOGLE_CONSOLE_MCC,
    payload,
  };
}
