import { ACTIONS_TYPES } from "../Reducers/InternalReportReducer";

export function getInternalReports(payload) {
  return {
    type: ACTIONS_TYPES.GET_INTERNAL_REPORTS,
    payload,
  };
}

export function fetchInternalReports(payload) {
  return {
    type: ACTIONS_TYPES.FETCH_INTERNAL_REPORTS,
    payload,
  };
}
