import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import { format_data } from "../../Utils/helpers";
import { formDataAppend } from "../../Utils/helpers";
import { getDataFromAPI } from "../../Redux/Actions/Thunk/normalTableThunk";
import { Spin } from "antd";

const NormalTableWithColGroup = (props) => {
  let eparams = props?.all_input_data;
  let f_date = eparams?.f_month;
  let t_date = eparams?.t_month;
  let f_date_compare = eparams?.c_f_month;
  let t_date_compare = eparams?.c_t_month;
  let device = eparams?.device_key;
  let date_range_option = eparams?.date_range_option;
  let date_range_compare_to = eparams?.date_range_compareto;
  const {
    normalTable: {
      api_data: {
        [props?.block?.block_id]: {
          data: tableList = [],
          subscribed_accounts: subscribedAccounts = [],
        } = {},
      },
      isLoading,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    var data = props?.all_input_data;
    var formData = formDataAppend(data);
    dispatch(getDataFromAPI(formData, props));
  }, []);

  let hasColGroups = JSON.parse(props?.block?.config).hasColGroups;
  let colGroupNames = JSON.parse(props?.block?.config).colGroupNames;
  let headColors = {
    Total: "#c9f1cb",
    "Google Ads": "#a8d1f3",
    "Bing Ads": "#ffcc80",
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Row gutter={[16, 24]}>
          {tableList && tableList.length === 0 ? (
            <Col span={24}>
              <div className="no-data">
                <p>No data</p>
              </div>
            </Col>
          ) : (
            tableList?.map((table, tableIndex) => {
              return (
                <Col key={tableIndex} span={24}>
                  <div className="">
                    <table id="ntable" aria-label="">
                      <thead>
                        {hasColGroups ? (
                          <tr>
                            <th
                              className="ntable-first"
                              style={{
                                display: "flex",
                              }}
                            >
                              &nbsp;
                            </th>
                            {colGroupNames.map((colGroupName) => (
                              <th
                                key={colGroupName}
                                colSpan={3}
                                style={{
                                  backgroundColor:
                                    colGroupNames.length > 1
                                      ? headColors[colGroupName]
                                      : "None",
                                  textAlign: "center",
                                }}
                              >
                                {colGroupName}
                              </th>
                            ))}
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <th
                            className="ntable-first"
                            style={{
                              display: "flex",
                            }}
                          >
                            &nbsp;
                          </th>
                          {colGroupNames.map((colGroupName) => (
                            <React.Fragment key={colGroupName}>
                              <th
                                style={{
                                  backgroundColor:
                                    colGroupNames.length > 1
                                      ? headColors[colGroupName]
                                      : "#c9f1cb",
                                  textAlign: "center",
                                }}
                              >
                                CURRENT
                              </th>
                              <th
                                style={{
                                  backgroundColor:
                                    colGroupNames.length > 1
                                      ? headColors[colGroupName]
                                      : "#ffcc80",
                                  textAlign: "center",
                                }}
                              >
                                PREVIOUS
                              </th>
                              <th
                                style={{
                                  backgroundColor:
                                    colGroupNames.length > 1
                                      ? headColors[colGroupName]
                                      : "None",
                                  textAlign: "center",
                                }}
                              >
                                CHANGE
                              </th>
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {table?.length > 0 ? (
                          table.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.metric}</td>
                              {colGroupNames.map((colGroupName) => (
                                <React.Fragment key={colGroupName}>
                                  <td style={{ textAlign: "center" }}>
                                    {item?.format === "amount"
                                      ? format_data(
                                          item[
                                            colGroupNames.length > 1
                                              ? colGroupName
                                                  .split(" ")[0]
                                                  .toLowerCase() + "_curr"
                                              : "curr"
                                          ],
                                          item?.format,
                                          item?.round_off,
                                          item?.metric
                                            .toLowerCase()
                                            .replaceAll(" ", "_")
                                        )
                                      : item?.format === "percentage"
                                      ? format_data(
                                          item[
                                            colGroupNames.length > 1
                                              ? colGroupName
                                                  .split(" ")[0]
                                                  .toLowerCase() + "_curr"
                                              : "curr"
                                          ],
                                          item?.format,
                                          item?.round_off,
                                          item?.metric
                                            .toLowerCase()
                                            .replace(" ", "_")
                                        )
                                      : item?.format === "number"
                                      ? format_data(
                                          item[
                                            colGroupNames.length > 1
                                              ? colGroupName
                                                  .split(" ")[0]
                                                  .toLowerCase() + "_curr"
                                              : "curr"
                                          ],
                                          item?.format,
                                          item?.round_off,
                                          item?.metric
                                        )
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item?.format === "amount"
                                      ? format_data(
                                          item[
                                            colGroupNames.length > 1
                                              ? colGroupName
                                                  .split(" ")[0]
                                                  .toLowerCase() + "_prev"
                                              : "prev"
                                          ],
                                          item?.format,
                                          item?.round_off,
                                          item?.metric
                                            .toLowerCase()
                                            .replaceAll(" ", "_")
                                        )
                                      : item?.format === "percentage"
                                      ? format_data(
                                          item[
                                            colGroupNames.length > 1
                                              ? colGroupName
                                                  .split(" ")[0]
                                                  .toLowerCase() + "_prev"
                                              : "prev"
                                          ],
                                          item?.format,
                                          item?.round_off,
                                          item?.metric
                                            .toLowerCase()
                                            .replace(" ", "_")
                                        )
                                      : item?.format === "number"
                                      ? format_data(
                                          item[
                                            colGroupNames.length > 1
                                              ? colGroupName
                                                  .split(" ")[0]
                                                  .toLowerCase() + "_prev"
                                              : "prev"
                                          ],
                                          item?.format,
                                          item?.round_off,
                                          item?.metric
                                        )
                                      : ""}
                                  </td>
                                  <td
                                    className="arrow-column"
                                    style={{ textAlign: "center" }}
                                  >
                                    {item[
                                      colGroupNames.length > 1
                                        ? colGroupName
                                            .split(" ")[0]
                                            .toLowerCase() + "_change"
                                        : "change"
                                    ]
                                      ? format_data(
                                          item[
                                            colGroupNames.length > 1
                                              ? colGroupName
                                                  .split(" ")[0]
                                                  .toLowerCase() + "_change"
                                              : "change"
                                          ],
                                          "percentage",
                                          undefined,
                                          item?.metric,
                                          true
                                        )
                                      : "0.00%"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={colGroupNames.length * 3}
                              style={{ textAlign: "center" }}
                            >
                              No data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
              );
            })
          )}
        </Row>
        {subscribedAccounts &&
        subscribedAccounts.length > 0 &&
        Array.isArray(subscribedAccounts) ? (
          <div style={{ marginTop: "3%" }}>
            Accounts Represented:
            <span>
              {" "}
              {subscribedAccounts.map((acnts, index) => (
                <span key={acnts.account_id}>
                  <a
                    href={
                      "/reports/v2/view/holistic_performance/" +
                      acnts?.salesforce_id +
                      "?f_month=" +
                      f_date +
                      "&t_month=" +
                      t_date +
                      "&date_range_option=" +
                      date_range_option +
                      "&date_range_compareto=" +
                      date_range_compare_to +
                      "&c_f_month=" +
                      f_date_compare +
                      "&c_t_month=" +
                      t_date_compare
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {acnts.account_name}
                  </a>
                  {index < subscribedAccounts.length - 1 && ", "}
                </span>
              ))}
            </span>
          </div>
        ) : (
          ""
        )}
      </Spin>
    </>
  );
};

export default NormalTableWithColGroup;
