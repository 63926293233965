/**
 * This file is created for the different functionality of string method.
 */

export class StringHelper {
  /**
   *
   * This is function similar like a format string method in python.
   * @param {string} fullString
   * @param  {string[]} options
   *
   * eg: StringHelper.format("Hi, My Name is {0} {1}","John","Cena")
   * output: Hi,My Name is John Cena.
   */

  static format(fullString, ...options) {
    // Note: arguments is only valid in normal function not in arrow function .
    const num = arguments.length;
    let oStr = fullString
    for (let i = 1; i < num; i++) {
      let pattern = "\\{" + (i - 1) + "\\}";
      let re = new RegExp(pattern, "g");
      oStr = oStr.replace(re, arguments[i]);
    }
    return oStr;
  }
}
