    import HighchartsReact from "highcharts-react-official";
    import Highcharts from "highcharts";
    import { useEffect } from "react";
    import { formDataAppend } from "../../Utils/helpers";
    import { getOrganicSeoData } from "../../Redux/Actions/Thunk/barGraphThunk";
    import { useDispatch, useSelector } from "react-redux";
    import { Spin } from "antd";
    import { useRef } from "react";
    import { useState } from "react";

    const TimelineChart = (props) => {

        const {filterData} = props;
        const chartRef = useRef(null);
        const [relevancePercentage, setRelevancePercentage] = useState(0);
        let chart;

        const {
            graph:{
                graph:{
                    [props?.block?.block_id]: {
                        data: relevance = []
                    } = {},
                },
                isLoading,
            } = {},
        } = useSelector((state) => state );
        const dispatch = useDispatch();

        useEffect(() => {
            let data = props?.all_input_data;
            let formData = formDataAppend(data);
            dispatch(getOrganicSeoData(formData, props));
         

        }, [filterData]);
        
        Highcharts.SVGRenderer.prototype.symbols.cross = function(x, y, w, h) {
          return ['M', x, y, 'L', x, y + h, 'L', x, y, 'L', x, y + h, 'z'];
        };


        Highcharts.SVGRenderer.prototype.symbols.bigcross = function (x, y, w, h) {

 

          const chartWidth = 1;
        
          const chartHeight = 200;
        
         
        
          // Calculate scaling factors based on the chart dimensions
        
          const xOffsetScale = chartWidth / 400; // Assuming a reference width of 400 units
        
          const yOffsetScale = chartHeight / 300; // Assuming a reference height of 300 units
        
         
        
          // Adjust the symbol based on the scaling factors
        
          const xOffset = 145 * xOffsetScale;
        
          const yOffset = -90 * yOffsetScale;
        
         
        
          // Calculate the coordinates for the symbol
        
          const x1 = 330 * xOffsetScale + x + xOffset;
        
          const y1 = 90 * yOffsetScale + y + yOffset;
        
          const x2 = 330 * xOffsetScale + x + xOffset;
        
          const y2 = 115 * yOffsetScale + y + yOffset;
        
          const x3 = 330 * xOffsetScale + x + xOffset;
        
          const y3 = 170 * yOffsetScale + y + yOffset;
        
         
        
          return [
        
            'M', x1, y1, 'L', x2, y2,
        
            'M', x3, y1, 'L', x3, y3
        
          ];
        
        };
    
        Highcharts.Pointer.prototype.reset = function() {  
            return undefined;
        };
    
        function getTimeLinePoint(relevance_percantage) {
    
          return (relevance_percantage <= 10) ? 0 :
            (relevance_percantage <= 20) ? 1 :
            (relevance_percantage <= 30) ? 2 :
            (relevance_percantage <= 40) ? 3 : 4;
        }

        useEffect(() => {
          chart = chartRef.current.chart;
          chart.tooltip.refresh(chart.series[0].points[getTimeLinePoint(relevance[0]?.relevance_differential)]); 
        }, [relevance]);
    
        const options = {
          chart: {
            type: 'line',
            height: 300
          },
          legend: {
            enabled: false
          },
          animation: false,
          xAxis: {
            categories: ['0 - 10%', '11 - 20%', '<span style="color:#fff;font-weight:bold;">21 - 30%</span><br> <span style="color:#fff;font-weight:bold;">HEALTHY RANGE</span>', '31 - 40%', '41 - 50%'],
            lineWidth:0,
            height: "120"
          },
          yAxis: {
            visible: false
          },
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          plotOptions: {
            series: {
              lineWidth: 0,
              dataLabels: {
                color: '#838DBA'
              },
              point: {
            events: {
              mouseOver: function () {
                this.series.chart.tooltip.refresh(this.series[0].points[getTimeLinePoint(relevance[0]?.relevance_differential)]);
              }
            }
          },
              states: {
                hover: {
                  enabled: false
                }
              }
            },
          },
          colors: [
            '#BCBEC0'
          ],
          series: [{
            data: [{
              y: 0,
              id: 'point0'
            }, {
              y: 0,
              id: 'point1'
            }, {
              y: 0,
              color: '#01A2D6',
              id: 'point2',
              marker:{
                symbol: 'bigcross',
              }
            }, {
              y: 0,
              id: 'point3'
            }, {
              y: 0,
              id: 'point4'
            }],
            marker: {
              symbol: 'cross',
              lineColor: null,
              lineWidth: 150        
            }
          }],
          tooltip: {
            enabled: true,
            headerFormat: '',
            useHTML: true,
            backgroundColor: '#FFF',
            style: {
                width: '120px',
                textAlign: 'center'
              },
            headerFormat:`<span style="font-size:35px;margin-top:10px;">${relevance[0]?.relevance_differential.toFixed(2)}%</span>`,
            pointFormat: `<span><br> your relevance differential</span>`
          },
          exporting: {
              enabled: false
          },
          credits: {
          enabled: false
        }
        };
    


        return(
            <>
                <Spin spinning={isLoading}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartRef}
                    ></HighchartsReact>
                </Spin>
            </>
        )
    }


    export default TimelineChart;