export const initialState = {
  roles: {},
  isLoading: false,
  role_details: {},
  reports: [],
  role_status_update_response: {},
  change_role_type_response: {},
  set_default_dashboard_response: {},
  set_report_status_response: {},
  update_salesforce_permission_response: {},
  active_users: [],
  add_users_role_response: {}
};

export const ACTIONS_TYPES = Object.freeze({
  ROLE_REQUEST: "ROLE_REQUEST",
  FETCH_ROLE: "FETCH_ROLE",
  GET_ACTIVE_REPORTS: "GET_ACTIVE_REPORTS",
  UPDATE_ROLE_STATUS: "UPDATE_ROLE_STATUS",
  AFTER_UPDATE_ROLE_STATUS: "AFTER_UPDATE_ROLE_STATUS",
  FETCH_ROLE_DETAILS: "FETCH_ROLE_DETAILS",
  DELETE_ROLE: "DELETE_ROLE",
  TOGGLE_ROLE_STATUS: "TOGGLE_ROLE_STATUS",
  CHANGE_ROLE_TYPE: "CHANGE_ROLE_TYPE",
  SET_DEFAULT_DASHBOARD: "SET_DEFAULT_DASHBOARD",
  SET_REPORT_STATUS: "SET_REPORT_STATUS",
  DELETE_ROLE: "DELETE_ROLE",
  UPDATE_SALESFORCE_PERMISSION: "UPDATE_SALESFORCE_PERMISSION",
  GET_ACTIVE_USERS: "GET_ACTIVE_USERS",
  ADD_USERS_ROLE: "ADD_USERS_ROLE"
});

export function roleReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPES.ROLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS_TYPES.FETCH_ROLE:
      return {
        ...state,
        isLoading: false,
        roles: action.payload,
      };
    case ACTIONS_TYPES.GET_ACTIVE_REPORTS:
      return {
        ...state,
        isLoading: false,
        reports: action.payload,
      };
    case ACTIONS_TYPES.UPDATE_ROLE_STATUS:
      return {
        ...state,
        isLoading: true,
        roles: action.payload,
      };
    case ACTIONS_TYPES.AFTER_UPDATE_ROLE_STATUS:
      return {
        ...state,
        isLoading: false,
        roles: action.payload,
      };
    case ACTIONS_TYPES.FETCH_ROLE_DETAILS:
      return {
        ...state,
        isLoading: false,
        role_details: action.payload,
      };
    case ACTIONS_TYPES.DELETE_ROLE:
      return {
        ...state,
        isLoading: false
      };
    case ACTIONS_TYPES.TOGGLE_ROLE_STATUS:
      return {
        ...state,
        isLoading: false,
        role_status_update_response: action.payload,
      };
    case ACTIONS_TYPES.CHANGE_ROLE_TYPE:
      return {
        ...state,
        isLoading: false,
        change_role_type_response: action.payload,
      };
    case ACTIONS_TYPES.SET_DEFAULT_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        set_default_dashboard_response: action.payload,
      };
    case ACTIONS_TYPES.SET_REPORT_STATUS:
      return {
        ...state,
        isLoading: false,
        set_report_status_response: action.payload,
      };
    case ACTIONS_TYPES.UPDATE_SALESFORCE_PERMISSION:
      return {
        ...state,
        isLoading: false,
        update_salesforce_permission_response: action.payload,
      };
    case ACTIONS_TYPES.GET_ACTIVE_USERS:
      return {
        ...state,
        isLoading: false,
        active_users: action.payload,
      };
    case ACTIONS_TYPES.ADD_USERS_ROLE:
      return {
        ...state,
        isLoading: false,
        add_users_role_response: action.payload,
      };
    default:
      return state;
  }
}
