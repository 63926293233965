import { listOfAllDataSources } from "../../../Config/datasources/allDatasources";
import { dispatchActionsDataSources } from "../../../services/actions/dispatchAction";
import * as dataSourceRedirectHandler from "../../../services/utils/dataSource";
import errorHandler from "../../../Utils/Request/errorHandler";

export const handleRedirect = async (history, dispatch) => {
  // setLoading(true);

  // convert the localstorage data from base64 strign to object
  const parsedDataSource = dataSourceRedirectHandler.deserializeObject(history);
  const {
    dataSourceMetaData: { dataSourceName },
  } = parsedDataSource;

  // *Find the current data source from the list of all data sources
  const currentDataSource = listOfAllDataSources[dataSourceName];
  // *If the current data source is not available then redirect to the accounts page
  if (!currentDataSource) {
    errorHandler({
      response: {
        data: {
          message: "This service is not Available .",
        },
        status: 400,
      },
    });

    history.push("/accounts");
  }
  // call the third party api
  const response = await dataSourceRedirectHandler.callThirdPartyApi(
    parsedDataSource,
    currentDataSource,
    history
  );

  // now dispatch the action to call backend apis
  dispatchActionsDataSources(
    listOfAllDataSources[dataSourceName],
    dispatch,
    response
  );
  return parsedDataSource;
};
