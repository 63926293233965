import { ACTIONS_TYPES } from "../Reducers/donutReducer";

export function fetchDonutChart(payload) {
    return {
        type: ACTIONS_TYPES.FETCH_DONUT_CHART_DATA,
        payload,
    };
}

export function getDonutChart(payload) {
    return {
        type: ACTIONS_TYPES.GET_DONUT_CHART_DATA,
        payload,
    };
}
