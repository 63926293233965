import * as loginAuthApi from "../../../Api/loginAuthApi";
import * as loginAuthAction from "../loginAuthAction";
// import { closeLoader, showLoader } from "../loaderAction";
import { notification } from "antd";
import { LOGIN_REQUEST, LOGOUT, MFA_SET, MFA_SET_FALSE, IMPERSONATE, STOP_IMPERSONATE  } from "../types";
import { Token } from "../../../Utils/helpers/token";
// import { toast } from "react-toastify";
import decode from "jwt-decode";
import { isLoggedIn } from "../../../Utils";

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const loginSuccess = (accessToken) => {
  // const email = props.email;
  // const password = props.password;
  return async (dispatch) => {

    const decryptAuthToken = Token.decryptAuthParams(accessToken)
    const decodedToken = decode(decryptAuthToken);

    try {
      dispatch({
        type: LOGIN_REQUEST,
      });
      // const response = await loginAuthApi.loginSuccess(email, password);

      // let loginInfo = {};
      if (decodedToken) {

        // loginInfo = response?.data?.user;
        localStorage.setItem("token", decryptAuthToken);
        localStorage.setItem("user", JSON.stringify(decodedToken.user_data));
        const type = "success";
        const message = "Success";
        const description = "Successfully Logged in.";
        // openNotificationWithIcon(type, message, description);
        isLoggedIn();
        return dispatch(loginAuthAction.loginSuccess(decodedToken.user_data));
      }
    } catch (error) {
      const errorMessage = error;
      // let msg = "";
      // if (errorMessage.message === "Request failed with status code 400") {
      //   msg = "Invalid email or password";
      // } else {
      //   msg = "Something went wrong";
      // }
      const type = "error";
      const message = error.response.data.error;
      const description = "Username or password didn't match";
      // openNotificationWithIcon(type, message, description);
      return dispatch(loginAuthAction.loginFailure(errorMessage));
    } finally {
      //dispatch(closeLoader());
    }
  };
};

export const isLogin = () => {
  if (window.localStorage.getItem("token")) {
    return true;
  }

  return false;
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  dispatch({
    type: LOGOUT,
  });
  const type = "success";
  const message = "Success";
  const description = "Successfully Logout.";
  openNotificationWithIcon(type, message, description);
};

export const checkDBMFAToken = (props) => {
  const user_id = props.user_id;
  const ip_address = props.ip_address;
  const fingerprint = props.fingerprint;
  return async (dispatch) => {
    try {
      const response = await loginAuthApi.checkDBMFAToken(
        user_id,
        ip_address,
        fingerprint
      );
      let deviceInfo = {};
      if (response?.status === 200) {
        if (response?.data?.status == "success") {
          let mfa_token = response?.data?.mfa_token;
          localStorage.setItem("mfa_token", mfa_token);
          dispatch({
            type: MFA_SET,
          });
        } else {
          dispatch({
            type: MFA_SET_FALSE,
          });
          const response1 = await loginAuthApi.saveMFAToken(
            user_id,
            ip_address,
            fingerprint
          );
        }
      }
    } catch (error) {
      /*const errorMessage = error;
      const type = "error";
      const message = error.response.data.error;
      const description = "Username or password didn't match";
      openNotificationWithIcon(type, message, description);
      return dispatch(loginAuthAction.loginFailure(errorMessage));*/
    } finally {
      //dispatch(closeLoader());
    }
  };
};

//function to verify user provided token
export const mfaCheck = (props) => {
  const user_id = props.user_id;
  const ip_address = props.ip_address;
  const fingerprint = props.fingerprint;
  const verification_token = props.verification_token;
  return async (dispatch) => {
    try {
      const response = await loginAuthApi.checkMFAToken(
        user_id,
        ip_address,
        fingerprint,
        verification_token
      );
      let deviceInfo = {};
      if (response?.status === 200) {
        if (response?.data?.status == "success") {
          let mfa_token = response?.data?.mfa_token;
          localStorage.setItem("mfa_token", mfa_token);
          dispatch({
            type: MFA_SET,
          });
        } else {
          const type = "error";
          const message = "Error";
          const description = "Invalid token. Please try again.";
          openNotificationWithIcon(type, message, description);
        }
      }
    } catch (error) {
      /*const errorMessage = error;
      const type = "error";
      const message = error.response.data.error;
      const description = "Username or password didn't match";
      openNotificationWithIcon(type, message, description);
      return dispatch(loginAuthAction.loginFailure(errorMessage));*/
    } finally {
      //dispatch(closeLoader());
    }
  };
};

//function to impersonate customer user
export const impersonate = (props) => {
  const user_id = props.id;
  return async(dispatch) => {
    try {
      const response = await loginAuthApi.impersonate(user_id);
      if(response.status==200){
        localStorage.setItem("impersonate",localStorage.getItem('token'));
        localStorage.setItem("impersonate_user",localStorage.getItem('user'));
        localStorage.setItem("token",response.data.token);
        localStorage.setItem("user",JSON.stringify(response.data.user));
        dispatch({type:IMPERSONATE});
      }else{
        const type = "error";
        const message = "Error";
        const description = "Unable to impersonate the user.";
        openNotificationWithIcon(type, message, description);
      }
    } catch (error) {
      const type = "error";
      const message = "Error";
      const description = "Unable to impersonate the user.";
      openNotificationWithIcon(type, message, description);
    }
  }
}

//function to impersonate customer user
export const stopImpersonate = () => {
  return async(dispatch) => {
    try {
      dispatch({type:STOP_IMPERSONATE})
    } catch (error) {
      const type = "error";
      const message = "Error";
      const description = "Unable to stop impersonation.";
      openNotificationWithIcon(type, message, description);
    }
  }
}