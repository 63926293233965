import {
  Button,
  Modal,
  Form,
  Typography,
  Select,
  Upload,
  Table,
  message,
} from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaArrowAltCircleLeft } from "react-icons/fa";
import API_BI from "../../Utils/axios_bi";
import config from "../../Config";
import { ACTIONS_TYPES } from "../../Redux/Reducers/accountReducer";

const {
  API_BI: { baseURL },
} = config;

const { Option } = Select;
const { Text } = Typography;
const columns = [
  {
    title: "Report Type",
    dataIndex: "report_type",
    key: "report_type",
  },
  {
    title: "Data File",
    dataIndex: "report_file",
    key: "report_file",
    render: (text, record) => {
      var download_query_string =
        "?csv_file=search_term/" +
        record.report_type.replaceAll(" ", "_").toLowerCase() +
        "/" +
        text;
      return (
        <a href={baseURL + "api/v1/download-csv" + download_query_string}>
          {text}
        </a>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

const UploadSearchTermData = (props) => {
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportType, setReportType] = useState("");
  const [searchTermDataUplpadHistory, setSearchTermDataUplpadHistory] =
    useState([]);
  const [exceptionDisplayStyle, setExceptionDisplayStyle] = useState("none");

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const file_props = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    beforeUpload(data_file) {
      const isValidType =
        data_file.type === "text/csv" ||
        data_file.type === "text/tab-separated-values";
      if (!isValidType) {
        form.setFields([
          {
            name: "data_file",
            errors: ["Invalid file type! File type must be csv or tsv."],
          },
        ]);
      }

      const isLt100M = data_file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        form.setFields([
          {
            name: "data_file",
            errors: ["File size must be smaller than 100MB!"],
          },
        ]);
      }

      if (isValidType && isLt100M) {
        form.setFields([
          {
            name: "data_file",
            errors: null,
          },
        ]);
        return false;
      }

      return (isValidType && isLt100M) || Upload.LIST_IGNORE;
    },
  };

  const exceptions_props = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    beforeUpload(data_file) {
      const isValidType =
        data_file.type === "text/csv" || data_file.name.endsWith(".csv");
      if (!isValidType) {
        form.setFields([
          {
            name: "exceptions_file",
            errors: ["Invalid file type! File type must be CSV."],
          },
        ]);
        return Upload.LIST_IGNORE;
      }

      const isLt100M = data_file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        form.setFields([
          {
            name: "exceptions_file",
            errors: ["File size must be smaller than 100MB!"],
          },
        ]);
        return Upload.LIST_IGNORE;
      }

      form.setFields([
        {
          name: "exceptions_file",
          errors: null,
        },
      ]);
      return true;
    },
  };

  const onReportTypeChange = useCallback((report_type) => {
    setReportType(report_type);
    if (
      report_type == "ASC Top 50 Term" ||
      report_type == "Google Top 50 Term" ||
      report_type == "Bing Top 50 Term" ||
      report_type == "AMS Top 50 Term"
    ) {
      setExceptionDisplayStyle("flex");
    } else {
      setExceptionDisplayStyle("none");
    }
  });

  const downloadUrl = baseURL + "api/v1/search-term-download-template";

  const onFinish = (values) => {
    const data = new FormData();
    data.append("data_file", values.data_file[0].originFileObj);
    if (values.exceptions_file != undefined) {
      data.append("exceptions_file", values.exceptions_file[0].originFileObj);
    }
    data.append("report_type", reportType);
    data.append("account_id", props.account_id);

    dispatch({
      type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
    });

    fetch(baseURL + "api/v1/upload-search-term-data", {
      method: "POST",
      body: data,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          message.success(json.message);
        });
      } else {
        response.json().then((json) => {
          message.error(json.message);
        });
      }
      form.resetFields();
      dispatch({
        type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
      });
    });
  };

  const showModal = () => {
    setVisible(true);
  };

  const viewImportHistory = () => {
    setIsModalOpen(true);
    setVisible(false);
    var params = {};
    if (props.account_id) {
      params = props;
    }
    dispatch({
      type: ACTIONS_TYPES.SF_IMPORT_REQUEST,
    });
    API_BI.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    API_BI.post(baseURL + "api/v1/search-term-upload-history", params).then(
      (response) => {
        if (response.status == 200) {
          setSearchTermDataUplpadHistory(response.data?.data);
        }
        dispatch({
          type: ACTIONS_TYPES.SF_IMPORT_COMPLETE,
        });
      }
    );
  };

  const handleOk = () => {
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  };

  const handleImportOk = () => {
    setIsModalOpen(false);
  };

  const handleImportCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const isLoading = useSelector((state) => state.accounts.isLoading);

  return (
    <>
      <Button className="account-import" onClick={showModal}>
        Upload
      </Button>

      <Modal
        className="import-modals upload-search-term"
        title="UPLOAD SEARCH TERM DATA"
        visible={visible}
        onOk={handleOk}
        cancelText="Close"
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        width={700}
      >
        <Form
          form={form}
          name="upload_search_term_data"
          onFinish={onFinish}
          autoComplete="off"
          disabled={isLoading}
        >
          <Form.Item
            label="Report Type"
            name="report_type"
            initialValue={reportType}
            rules={[
              {
                required: true,
                message: "Please select report type",
              },
            ]}
          >
            <Select onChange={onReportTypeChange}>
              <Option value="">Please select</Option>
              {props.account_id == "0015000000UaW0RAAV" ? (
                <Option value="AllHeart Goals">AllHeart Goals</Option>
              ) : (
                ""
              )}
              <Option value="Amazon Asins">Amazon ASINs</Option>
              <Option value="Amazon Coverage Brand">
                Amazon Coverage - Brand
              </Option>
              <Option value="Amazon Coverage Search Term">
                Amazon Coverage - Search Term
              </Option>
              <Option value="AMS Search Term">AMS Search Term Type</Option>
              <Option value="AMS Top 50 Term">AMS Top 50 Term</Option>
              <Option value="ASC Search Term">ASC Search Term Type</Option>
              <Option value="ASC Top 50 Term">ASC Top 50 Term</Option>
              <Option value="Bing Search Term">
                Bing Ads Search Term Type
              </Option>
              <Option value="Bing Top 50 Term">Bing Ads Top 50 Term</Option>
              <Option value="CLV">CLV</Option>
              <Option value="Google Search Term">
                Google Ads Search Term Type
              </Option>
              <Option value="Google Top 50 Term">Google Ads Top 50 Term</Option>
              <Option value="Query Categories">Organic Query Category</Option>
              <Option value="Organic Search Content Implementation">
                Organic Search Content Implementation
              </Option>
              <Option value="Top 50 Landing Pages">Top 50 Landing Pages</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="data_file"
            label="Upload File: "
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Please select file to upload",
              },
            ]}
          >
            <Upload {...file_props} maxCount={1}>
              <Button icon={<UploadOutlined />}>Choose a File</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="exceptions_file"
            label="Upload Exceptions: "
            valuePropName="fileList"
            getValueFromEvent={normFile}
            style={{ display: exceptionDisplayStyle }}
          >
            <Upload {...exceptions_props} maxCount={1}>
              <Button icon={<UploadOutlined />}>Choose a File</Button>
            </Upload>
          </Form.Item>
          <Text>
            <b>Note:</b> Reports using the data will be updated tomorrow
          </Text>
          <Form.Item
            label=""
            getValueFromEvent={normFile}
            className="import-modals-form-buttons"
          >
            <Button htmlType="submit" icon={<UploadOutlined />}>
              Upload and Save
            </Button>
            <Button icon={<DownloadOutlined />} href={downloadUrl}>
              Download Template
            </Button>
            <Button icon={<FaEye />} onClick={viewImportHistory}>
              View Upload History
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="history-modal"
        title={
          <>
            <Button
              className="modal-back-btn"
              icon={<FaArrowAltCircleLeft />}
              onClick={() => {
                setVisible(true);
                setIsModalOpen(false);
              }}
            >
              Go Back
            </Button>
            SEARCH TERM UPLOAD HISTORY
          </>
        }
        visible={isModalOpen}
        onOk={handleImportOk}
        cancelText="Close"
        onCancel={handleImportCancel}
        okButtonProps={{ style: { display: "none" } }}
        width={1200}
      >
        <Table
          columns={columns}
          dataSource={searchTermDataUplpadHistory}
          loading={isLoading}
        />
      </Modal>
    </>
  );
};

export default UploadSearchTermData;
