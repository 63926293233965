import { DataSourcesApiEndPoints } from "../../Utils/constant";

import API_BI from "../../Utils/axios_bi";

const {
  tiktok: {
    getTiktokClientUrl,
    postTiktokClientUrl,
    tiktokCredentialsUrl,
    tiktokProfileUrl,
  },
} = DataSourcesApiEndPoints;

/**
 * API function to get Google Console client data
 */
export const getTiktokApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(getTiktokClientUrl, props);
};

/**gi
 * API function to create Google Console client data
 * @param {object} props - The data to be posted
 * @returns {Promise<response>}
 */
export const createTiktokApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(postTiktokClientUrl, props);
};

/**
 * API function to update Google Console Auth data
 * @param {object} props - The data to be updated
 * @returns {Promise<response>}
 */
export const updateTiktokApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.put(postTiktokClientUrl, props);
};

export const tiktokCredentialsAPI = async () => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.get(tiktokCredentialsUrl, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const tiktokProfileApi = async (props) => {
  const token = localStorage.getItem("token");
  API_BI.defaults.headers.common["Authorization"] = token;
  return await API_BI.post(tiktokProfileUrl, props);
};
